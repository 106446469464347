import React, { useState, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { Route, Redirect, useHistory } from "react-router-dom";
import moment from "moment";

import Main from "../../containers";
import FormNotification from "../Notifications/FormNotification";

import { getFirmActiveSubscriptionAction } from "../../redux/actions/Billing";
import { getUserDetails } from "../../utils/functions";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { firm_onboarding } = getUserDetails();

  const { subscriptionData } = useSelector((state) => state.billing);
  const { onboardingStep } = useSelector((state) => state.auth);

  const [showNotification, setShowNotification] = useState(false);
  const [duration, setDuration] = useState("");

  useEffect(() => {
    if (!subscriptionData?.subscriptionType) {
      dispatch(getFirmActiveSubscriptionAction()).then(() => shouldShowTrial());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (subscriptionData?.subscriptionType) {
      shouldShowTrial();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionData]);

  const shouldShowTrial = () => {
    if (subscriptionData?.subscriptionType === "trial") {
      var now = moment(new Date());
      var end = moment(new Date(subscriptionData?.endDate));
      var days = end.diff(now, "days");
      var hours = end.diff(now, "hours");

      setDuration(days > 0 ? `${days} days` : `${hours} hour(s)`);

      setShowNotification(true);
    }
  };

  const currentStep =
    firm_onboarding > onboardingStep ? firm_onboarding : onboardingStep;

  const notification = {
    type: "notify",
    content: (
      <>
        <span
          className="onboarding"
          onClick={() => history.push("/get-started")}
        >
          Onboarding ({currentStep}/7)
        </span>
        <span className="separator">|</span>
        Your free trial ends in {duration}. To gain complete access,
        <span
          className="action"
          onClick={() => history.push("/settings/billing/upgrade")}
        >
          upgrade your account
        </span>
      </>
    ),
  };

  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("oneroute-user") ? (
          <Main authenticated>
            {showNotification && (
              <FormNotification
                topBarNotification={notification?.content}
                type={notification?.type}
              />
            )}
            <Component {...props} />
          </Main>
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

const mapStateToProps = ({ auth }) => auth;

export default connect(mapStateToProps)(PrivateRoute);
