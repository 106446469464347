import React from "react";

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="9"
    height="6"
    fill="none"
    viewBox="0 0 9 6"
  >
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M7.667 1L2.88 5.167 1 3.404"
    ></path>
  </svg>
);
