import React, { Component } from "react";
import classNames from "classnames";

import { More } from "../../assets/vectors";

class OptionsDropdown extends Component {
  state = {
    isActive: false,
  };

  openOptionsDropdown = () => {
    this.setState(
      {
        isActive: true,
      },
      () => document.addEventListener("click", this.closeOptionsDropdown)
    );
  };

  closeOptionsDropdown = (e) => {
    if (
      !e ||
      (this.optionsDropdownRef && !this.optionsDropdownRef.contains(e.target))
    ) {
      this.setState(
        {
          isActive: false,
        },
        () => document.removeEventListener("click", this.closeOptionsDropdown)
      );
    }
  };

  componentDidMount() {
    window.addEventListener("click", this.closeOptionsDropdown);
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.closeOptionsDropdown);
  }

  render() {
    const { isActive } = this.state;
    const { dropdownPlaceholder, options, onClick, disabled, vertical } =
      this.props;

    return (
      <div
        onClick={(e) => e.stopPropagation()}
        className={classNames("options-dropdown-container", { disabled })}
      >
        <span
          className={classNames("icon", { vertical })}
          onClick={this.openOptionsDropdown}
        >
          {dropdownPlaceholder ? dropdownPlaceholder : <More />}
        </span>
        {isActive && (
          <div
            className="options-menu"
            ref={(el) => (this.optionsDropdownRef = el)}
          >
            <ul>
              {options.map(({ label, value }, index) => (
                <li
                  key={`options-dropdown-${index}`}
                  onClick={() => onClick(value)}
                >
                  {label}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  }
}

export default OptionsDropdown;
