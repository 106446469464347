import React from "react";

export default () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="#39206A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
        d="M.625.621h7.5v7.5h-7.5v-7.5zM.625 11.871h7.5v7.5h-7.5v-7.5zM11.875.621h7.5v7.5h-7.5v-7.5zM11.875 11.871h7.5v7.5h-7.5v-7.5z"
      ></path>
    </svg>
)