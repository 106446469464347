import React from "react";

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      stroke="#C6C1D0"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.25"
      d="M8.333 18.125v0a1.74 1.74 0 003.338 0M10 2.5V.625M10 2.5v0a6.25 6.25 0 016.25 6.25c0 5.872 1.25 6.875 1.25 6.875h-15s1.25-1.597 1.25-6.875v0A6.25 6.25 0 0110 2.5v0z"
    ></path>
  </svg>
);
