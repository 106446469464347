import React from "react";

export default () => (
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.00002 0.583008C6.38304 0.583008 6.76231 0.65845 7.11618 0.805026C7.47005 0.951602 7.79158 1.16644 8.06242 1.43728C8.33325 1.70812 8.54809 2.02965 8.69467 2.38351C8.84125 2.73738 8.91669 3.11665 8.91669 3.49967V5.83301C8.91669 6.60656 8.6094 7.34842 8.06242 7.8954C7.51544 8.44238 6.77357 8.74967 6.00002 8.74967C5.22647 8.74967 4.48461 8.44238 3.93763 7.8954C3.39065 7.34842 3.08335 6.60656 3.08335 5.83301V3.49967C3.08335 2.72613 3.39065 1.98426 3.93763 1.43728C4.48461 0.890299 5.22647 0.583008 6.00002 0.583008ZM0.782104 6.41634H1.95752C2.09886 7.38746 2.58511 8.27524 3.32732 8.91725C4.06954 9.55926 5.01808 9.91258 5.99944 9.91258C6.98079 9.91258 7.92934 9.55926 8.67155 8.91725C9.41376 8.27524 9.90002 7.38746 10.0414 6.41634H11.2174C11.0847 7.59976 10.5539 8.70298 9.7119 9.54507C8.86991 10.3872 7.76675 10.9181 6.58335 11.0509V13.4163H5.41669V11.0509C4.23318 10.9183 3.1299 10.3873 2.28779 9.54524C1.44569 8.70313 0.914762 7.59985 0.782104 6.41634Z"
      fill="#757F8A"
    />
  </svg>
);
