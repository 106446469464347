import { combineReducers } from "redux";
import { routerReducer as router } from "react-router-redux";

import AccountSettings from "./AccountSettings";
import Agents from "./Agents";
import Auth from "./Auth";
import AutoResponses from "./AutoResponses";
import Billing from "./Billing";
import Categories from "./Categories";
import Channels from "./Channels";
import Conversations from "./Conversations";
import Mentions from "./Mentions";
import Csat from "./Csat";
import CustomFields from "./CustomFields";
import Customers from "./Customers";
import Broadcast from "./Broadcast";
import SavedResponses from "./SavedResponses";
import TemplateMessage from "./TemplateMessage";
import Teams from "./Teams";
import Tags from "./Tags";
import Groups from "./Groups";
import Insights from "./Insights";
import Integrations from "./Integrations";
import AutoAssignment from "./AutoAssignment";
import WorkingHours from "./WorkingHours";
import Signature from "./Signature";
import Widget from "./Widget";

export default combineReducers({
  router,
  accountSettings: AccountSettings,
  agents: Agents,
  auth: Auth,
  autoResponses: AutoResponses,
  billing: Billing,
  categories: Categories,
  channels: Channels,
  conversations: Conversations,
  mentions: Mentions,
  csat: Csat,
  customFields: CustomFields,
  customers: Customers,
  broadcast: Broadcast,
  savedResponses: SavedResponses,
  templateMessage: TemplateMessage,
  tags: Tags,
  teams: Teams,
  groups: Groups,
  insights: Insights,
  integrations: Integrations,
  autoAssigns: AutoAssignment,
  workingHours: WorkingHours,
  signature: Signature,
  widget: Widget,
});
