import { getRequest, postRequest, deleteRequest } from "../../api";

import {
  CREATE_BROADCAST_LOADER,
  CREATE_BROADCAST_RESPONSE,
  CREATE_BROADCAST_RESET,
  GET_BROADCAST_LOADER,
  GET_BROADCAST_RESPONSE,
  GET_BROADCAST_RESET,
  DELETE_BROADCAST_LOADER,
  DELETE_BROADCAST_RESPONSE,
  DELETE_BROADCAST_RESET,
} from "../constants";

import { getRequestError } from "../../utils/functions";

export const createBroadcastLoader = (isCreatingBroadcast) => ({
  type: CREATE_BROADCAST_LOADER,
  isCreatingBroadcast,
});

export const createBroadcastResponse = (
  createBroadcastOutcome,
  createBroadcastMessage
) => ({
  type: CREATE_BROADCAST_RESPONSE,
  createBroadcastOutcome,
  createBroadcastMessage,
});

export const createBroadcastReset = () => ({
  type: CREATE_BROADCAST_RESET,
});

export const getBroadcastLoader = (isLoadingBroadcast) => ({
  type: GET_BROADCAST_LOADER,
  isLoadingBroadcast,
});

export const getBroadcastResponse = (
  broadcastOutcome,
  broadcastData,
  broadcastMeta,
  broadcastMessage
) => ({
  type: GET_BROADCAST_RESPONSE,
  broadcastOutcome,
  broadcastData,
  broadcastMeta,
  broadcastMessage,
});

export const getBroadcastReset = () => ({
  type: GET_BROADCAST_RESET,
});

export const deleteBroadcastLoader = (isDeletingBroadcast) => ({
  type: DELETE_BROADCAST_LOADER,
  isDeletingBroadcast,
});

export const deleteBroadcastResponse = (
  deleteBroadcastOutcome,
  deleteBroadcastMessage
) => ({
  type: DELETE_BROADCAST_RESPONSE,
  deleteBroadcastOutcome,
  deleteBroadcastMessage,
});

export const deleteBroadcastReset = () => ({
  type: DELETE_BROADCAST_RESET,
});

export const createBroadcastAction = (data, success) => {
  return async (dispatch) => {
    dispatch(createBroadcastReset());
    dispatch(createBroadcastLoader(true));

    try {
      const res = await postRequest({
        url: "/broadcast",
        data,
        token: true,
      });

      dispatch(createBroadcastResponse("success", res?.data?.message));
      dispatch(createBroadcastLoader(false));
      success && success();
    } catch (error) {
      const message = getRequestError(error);

      dispatch(createBroadcastResponse("error", message));
      dispatch(createBroadcastLoader(false));
    }
  };
};

export const getBroadcastAction = (params = {}) => {
  return async (dispatch) => {
    dispatch(getBroadcastReset());
    dispatch(getBroadcastLoader(true));

    try {
      const res = await getRequest({
        url: `/broadcast${params?.id ? params?.id : ""}`,
        params: params?.params,
        token: true,
      });

      const { data, currentPage, count, totalPage } = res.data;

      dispatch(
        getBroadcastResponse(
          "success",
          data,
          { currentPage, count, totalPage },
          ""
        )
      );
      dispatch(getBroadcastLoader(false));
    } catch (error) {
      const message = getRequestError(error);

      dispatch(getBroadcastResponse("error", [], {}, message));
      dispatch(getBroadcastLoader(false));
    }
  };
};

export const getBroadcastEstimateAction = (data) => {
  return async (dispatch) => {
    dispatch(getBroadcastLoader(true));

    try {
      const res = await postRequest({
        url: "/broadcast/estimate",
        data,
        token: true,
      });

      dispatch(getBroadcastLoader(true));
      return res?.data;
    } catch (error) {
      const message = getRequestError(error);

      dispatch(createBroadcastResponse("error", message));
      dispatch(getBroadcastLoader(true));
    }
  };
};

export const deleteBroadcastAction = (id) => {
  return async (dispatch) => {
    dispatch(deleteBroadcastReset());
    dispatch(deleteBroadcastLoader(true));

    try {
      const res = await deleteRequest({
        url: `/broadcast/${id}`,
        token: true,
      });

      dispatch(deleteBroadcastResponse("success", ""));
      dispatch(deleteBroadcastLoader(false));
      return res?.data;
    } catch (error) {
      const message = getRequestError(error);

      dispatch(deleteBroadcastResponse("error", message));
      dispatch(deleteBroadcastLoader(false));
    }
  };
};
