import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { getRequest } from "../../../api";

import { getConversationsAction } from "../../../redux/actions/Conversations";

import { Select } from "../../../components/FormElements";
import LottieAnimation from "../../../components/LottieWeb";
import { AgentInfo, PerformanceOverview, Conversations, Tickets } from ".";

import { Location } from "../../../assets/vectors";
import { getRequestError } from "../../../utils/functions";

const statusOptions = [
  {
    label: "Active",
    value: "",
  },
];

class AgentInsightsDetails extends Component {
  state = {
    currentTab: "in-progress",
    isLoadingAgentInsights: true,
    agentInsightDetails: {},
  };

  getAgentInsights = async (id) => {
    this.setState({
      isLoadingAgentInsights: true,
    });

    try {
      const res = await getRequest({
        url: `/insights/agents/${id}`,
        token: true,
      });

      this.setState({
        isLoadingAgentInsights: false,
        agentInsightDetails: res.data.data,
      });
    } catch (error) {
      const message = getRequestError(error);
      console.log(message);

      this.setState({
        isLoadingAgentInsights: false,
      });
    }
  };

  getAssignedConversations = (id) => {
    const { getConversationsAction } = this.props;
    const { query } = this.state;

    getConversationsAction(
      {
        ...query,
        agent_id: id,
        status: "in-progress",
      },
      "assignedConversations"
    );
  };

  getQueueConversations = (id) => {
    const { getConversationsAction } = this.props;
    const { query } = this.state;

    getConversationsAction(
      {
        ...query,
        agent_id: id,
        status: "open",
      },
      "queueConversations"
    );
  };

  getResolvedConversations = (id) => {
    const { getConversationsAction } = this.props;
    const { query } = this.state;

    getConversationsAction(
      {
        ...query,
        agent_id: id,
        status: "closed",
      },
      "resolvedConversations"
    );
  };

  switchTab = (currentTab) => {
    const { id } = this.props.match.params;
    this.setState({
      currentTab,
    });

    currentTab === "in-progress" && this.getAssignedConversations(id);
    currentTab === "open" && this.getQueueConversations(id);
    currentTab === "closed" && this.getResolvedConversations(id);
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    this.getAgentInsights(id);
    this.getAssignedConversations(id);
  }

  render() {
    const { currentTab, isLoadingAgentInsights, agentInsightDetails } =
      this.state;
    const {
      firstName,
      lastName,
      email,
      location,
      performanceOverview,
      conversations,
    } = agentInsightDetails;

    return (
      <div className="insights-details-page insights-page-container">
        {isLoadingAgentInsights ? (
          <div className="loader-container text-center">
            <LottieAnimation
              path={require("../../../assets/jsons/loader.json")}
              autoplay
            />
          </div>
        ) : (
          <>
            <div className="insights-details-header">
              <div className="header-bg">
                <img
                  src={require("../../../assets/images/auth-bg.png").default}
                  alt=""
                />
                <img
                  src={require("../../../assets/images/auth-bg.png").default}
                  alt=""
                />
                <img
                  src={require("../../../assets/images/auth-bg.png").default}
                  alt=""
                />
                <img
                  src={require("../../../assets/images/auth-bg.png").default}
                  alt=""
                />
                <img
                  src={require("../../../assets/images/auth-bg.png").default}
                  alt=""
                />
                <img
                  src={require("../../../assets/images/auth-bg.png").default}
                  alt=""
                />
              </div>
              <div>
                {/* <div className="agent-avatar">
              <img src={require("../../../assets/images/avatar.png").default} alt="" />
            </div> */}
                <div>
                  <div className="name">
                    {firstName} {lastName}
                  </div>
                  <div className="status">
                    <span className="icon"></span>
                    <span className="text">{email}</span>
                  </div>
                  <div className="location">
                    <span className="icon">
                      <Location />
                    </span>
                    <span className="text">{location || "N/A"}</span>
                  </div>
                </div>
              </div>
              <div>
                <Select options={statusOptions} />
              </div>
            </div>
            <div className="insights-details-content">
              <AgentInfo agentInsightDetails={agentInsightDetails} />
              <div className="performance-conversations">
                <PerformanceOverview
                  performanceOverview={performanceOverview}
                />
                <Conversations conversations={conversations} />
              </div>
            </div>
            <div className="insights-details-content">
              <Tickets currentTab={currentTab} switchTab={this.switchTab} />
            </div>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ auth, conversations }) => ({
  ...auth,
  ...conversations,
});

export default withRouter(
  connect(mapStateToProps, { getConversationsAction })(AgentInsightsDetails)
);
