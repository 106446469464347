import {
    CREATE_TAGS_LOADER,
    CREATE_TAGS_RESPONSE,
    CREATE_TAGS_RESET,
    UPDATE_TAGS_LOADER,
    UPDATE_TAGS_RESPONSE,
    UPDATE_TAGS_RESET,
    GET_TAGS_LOADER,
    GET_TAGS_RESPONSE,
    GET_TAGS_RESET,
    DELETE_TAGS_LOADER,
    DELETE_TAGS_RESPONSE,
    DELETE_TAGS_RESET,
  } from "../constants";
  
  const INIT_STATE = {
    tagsData: [],
    tagsMeta: {
      limit: 10,
      offset: 0,
      total: 0
    },
    isCreatingTag: false,
    isUpdatingTag: false,
    isDeletingTag: false,
    isLoadingTags: true,
    createTagsOutcome: "",
    createTagsMessage: "",
    updateTagsOutcome: "",
    updateTagsMessage: "",
    deleteTagsOutcome: "",
    deleteTagsMessage: "",
    tagsOutcome: "",
    tagsMessage: "",
  };
  
  const Tags = (state = INIT_STATE, action) => {
    const {
      type,
      createTagsOutcome,
      createTagsMessage,
      updateTagsOutcome,
      updateTagsMessage,
      deleteTagsOutcome,
      deleteTagsMessage,
      tagsOutcome,
      tagsMessage,
      tagsData,
      tagsMeta,
      isCreatingTag,
      isUpdatingTag,
      isDeletingTag,
      isLoadingTags
    } = action;
  
    switch (type) {
      case CREATE_TAGS_LOADER: {
        return {
          ...state,
          isCreatingTag
        };
      }
  
      case CREATE_TAGS_RESPONSE: {
        return {
          ...state,
          createTagsOutcome,
          createTagsMessage
        };
      }
  
      case CREATE_TAGS_RESET: {
        return {
          ...state,
          createTagsOutcome: "",
          createTagsMessage: ""
        };
      }
      
      case UPDATE_TAGS_LOADER: {
        return {
          ...state,
          isUpdatingTag
        };
      }
  
      case UPDATE_TAGS_RESPONSE: {
        return {
          ...state,
          updateTagsOutcome,
          updateTagsMessage
        };
      }
  
      case UPDATE_TAGS_RESET: {
        return {
          ...state,
          updateTagsOutcome: "",
          updateTagsMessage: ""
        };
      }
  
      case DELETE_TAGS_LOADER: {
        return {
          ...state,
          isDeletingTag
        };
      }
  
      case DELETE_TAGS_RESPONSE: {
        return {
          ...state,
          deleteTagsOutcome,
          deleteTagsMessage
        };
      }
  
      case DELETE_TAGS_RESET: {
        return {
          ...state,
          deleteTagsOutcome: "",
          deleteTagsMessage: ""
        };
      }
  
      case GET_TAGS_LOADER: {
        return {
          ...state,
          isLoadingTags
        };
      }
  
      case GET_TAGS_RESPONSE: {
        return {
          ...state,
          tagsOutcome,
          tagsData,
          tagsMeta, 
          tagsMessage
        };
      }
  
      case GET_TAGS_RESET: {
        return INIT_STATE;
      }
  
      default:
        return state;
    }
  };
  
  export default Tags;