import React, { useState, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import * as moment from "moment";
import DatePicker from "react-datepicker";

import { getCustomFieldsAction } from "../../../redux/actions/CustomFields";
import { updateCustomersAction } from "../../../redux/actions/Customers";
import {
  addToGroupAction,
  removefromGroupAction,
} from "../../../redux/actions/Groups";

import { Pencil, Close, Calendar } from "../../../assets/vectors";
import {
  TextField,
  TextArea,
  FileInput,
} from "../../../components/FormElements";

import { customersPermissions } from "../../../utils/permissions";
import { getArrayDifference } from "../../../utils/functions";

import GroupForm from "../../Settings/Groups/GroupForm";

const CustomerInfo = ({
  customerDetails,
  customFormFieldsData,
  updateCustomFormData,
  getCustomerDetails,
  handleChange,
  handleCFChange,
  handleFileUpload,
  newFileUploaded,
  isUploadingFile,
}) => {
  const dispatch = useDispatch();

  const defaultCustomerGroups =
    customerDetails?.Groups?.map((x) => ({
      label: x?.name,
      value: x?.id,
    })) || [];

  const { groupsData } = useSelector((state) => state.groups);
  const { isUpdatingCustomer } = useSelector((state) => state.customers);
  const { customFieldsData } = useSelector((state) => state.customFields);

  const [isEditActive, showEdit] = useState(false);
  const [isNewGroupFormActive, setIsNewGroupFormActive] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState(
    defaultCustomerGroups || []
  );

  const {
    id,
    name,
    email,
    phone,
    twitterUsername,
    facebookMessengerId,
    instagramUsername,
    whatsappPhoneNumber,
    Groups,
    custom_fields,
  } = customerDetails;

  useEffect(() => {
    if (customFieldsData?.length < 1) {
      dispatch(getCustomFieldsAction("customer"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (customFormFieldsData === null && custom_fields) {
      updateCustomFormData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customFormFieldsData]);

  const updateCustomerDetails = () => {
    const groupChanges = getArrayDifference(
      selectedGroups,
      defaultCustomerGroups
    );

    if (
      groupChanges?.length > 0 ||
      selectedGroups?.length !== defaultCustomerGroups?.length
    ) {
      dispatch(
        addToGroupAction({
          groups: selectedGroups?.map((x) => x?.value),
          customers: [id],
        })
      );
    }

    const payload = {
      email: customerDetails?.email,
      facebookMessengerId: customerDetails?.facebookMessengerId,
      instagramUsername: customerDetails?.instagramUsername,
      name: customerDetails?.name,
      phone: customerDetails?.phone,
      twitterUsername: customerDetails?.twitterUsername,
      whatsappPhoneNumber: customerDetails?.whatsappPhoneNumber,
      custom_fields: customFormFieldsData,
    };

    dispatch(
      updateCustomersAction(payload, id, () => {
        getCustomerDetails(id);
        showEdit(false);
      })
    );
  };

  const removeCustomerFromGroup = (groupId) => {
    dispatch(
      removefromGroupAction(
        {
          id: customerDetails?.id,
          data: {
            groups: [groupId],
          },
        },
        () => getCustomerDetails(id)
      )
    );
  };

  const groupOptions = groupsData?.map((x) => ({
    label: x?.name,
    value: x?.id,
  }));

  return (
    <div className="agent-info">
      <span className="insights-section-title">Customer Details</span>
      <div className="insights-card">
        <div className="actions-container">
          {isEditActive && !isUpdatingCustomer && (
            <div className="edit-info" onClick={() => showEdit(false)}>
              <span className="icon">
                <Close />
              </span>
              <span className="text">Cancel</span>
            </div>
          )}
          {(customersPermissions.update || customersPermissions.modifyAll) && (
            <div
              className="edit-info"
              onClick={
                isUpdatingCustomer
                  ? () => {}
                  : () =>
                      isEditActive ? updateCustomerDetails() : showEdit(true)
              }
            >
              <span className="icon">
                <Pencil />
              </span>
              <span className="text">
                {isUpdatingCustomer
                  ? "Saving update..."
                  : isEditActive
                  ? "Save"
                  : "Edit"}
              </span>
            </div>
          )}
        </div>
        <div className="agent-info-item">
          <span className="title">Name:</span>
          <span className="value">
            {isEditActive ? (
              <TextField
                value={name || ""}
                name="name"
                onChange={handleChange}
              />
            ) : (
              name
            )}
          </span>
        </div>
        <div className="agent-info-item">
          <span className="title">Active channels:</span>
          <span className="value">
            {" "}
            <div className="customer-channels">
              <img
                src={
                  require("../../../assets/images/icons/whatsapp-icon.png")
                    .default
                }
                alt=""
                className={`${whatsappPhoneNumber ? "" : "disabled"}`}
              />
              <img
                src={
                  require("../../../assets/images/icons/twitter-icon.png")
                    .default
                }
                alt=""
                className={`${twitterUsername ? "" : "disabled"}`}
              />
              <img
                src={
                  require("../../../assets/images/icons/instagram-icon.png")
                    .default
                }
                alt=""
                className={`${instagramUsername ? "" : "disabled"}`}
              />
              <img
                src={
                  require("../../../assets/images/icons/email-icon.png").default
                }
                alt=""
                className={`${email ? "" : "disabled"}`}
              />
              <img
                src={
                  require("../../../assets/images/icons/sms-icon.png").default
                }
                alt=""
                className={`${phone ? "" : "disabled"}`}
              />
            </div>
          </span>
        </div>
        <div className="agent-info-item">
          <span className="title">Email:</span>
          <span className="value">
            {isEditActive ? (
              <TextField
                type="email"
                value={email || ""}
                name="email"
                onChange={handleChange}
              />
            ) : (
              email
            )}
          </span>
        </div>
        <div className="agent-info-item">
          <span className="title">Phone:</span>
          <span className="value">
            {isEditActive ? (
              <TextField
                value={phone || ""}
                name="phone"
                onChange={handleChange}
              />
            ) : (
              phone || "N/A"
            )}
          </span>
        </div>
        <div className="agent-info-item">
          <span className="title">Twitter:</span>
          <span className="value">
            {isEditActive ? (
              <TextField
                value={twitterUsername || ""}
                name="twitterUsername"
                onChange={handleChange}
              />
            ) : (
              twitterUsername || "N/A"
            )}
          </span>
        </div>
        <div className="agent-info-item">
          <span className="title">Instagram:</span>
          <span className="value">
            {isEditActive ? (
              <TextField
                value={instagramUsername || ""}
                name="instagramUsername"
                onChange={handleChange}
              />
            ) : (
              instagramUsername || "N/A"
            )}
          </span>
        </div>
        <div className="agent-info-item">
          <span className="title">Whatsapp:</span>
          <span className="value">
            {isEditActive ? (
              <TextField
                value={whatsappPhoneNumber || ""}
                name="whatsappPhoneNumber"
                onChange={handleChange}
              />
            ) : (
              whatsappPhoneNumber || "N/A"
            )}
          </span>
        </div>
        <div className="agent-info-item">
          <span className="title">Facebook Messenger ID:</span>
          <span className="value">
            {isEditActive ? (
              <TextField
                value={facebookMessengerId || ""}
                name="facebookMessengerId"
                onChange={handleChange}
              />
            ) : (
              facebookMessengerId || "N/A"
            )}
          </span>
        </div>
        <div className="agent-info-item">
          <span className="title">Group(s):</span>
          <div className="value groups-container">
            {isEditActive ? (
              <div className="input-container">
                <Select
                  classNamePrefix="select_container"
                  menuPlacement="top"
                  defaultValue={selectedGroups}
                  value={selectedGroups}
                  options={[
                    ...(groupOptions || []),
                    { label: "+ Add new group", value: "add_new_group" },
                  ]}
                  onChange={(selections) => {
                    if (selections?.find((x) => x?.value === "add_new_group")) {
                      setIsNewGroupFormActive(true);
                    } else {
                      setSelectedGroups(selections);
                    }
                  }}
                  isClearable={false}
                  isMulti
                />
              </div>
            ) : (
              Groups?.map((x, i) => (
                <div className="group" key={i}>
                  {x?.name}
                  {(customersPermissions.update ||
                    customersPermissions.modifyAll) && (
                    <span onClick={() => removeCustomerFromGroup(x?.id)}>
                      x
                    </span>
                  )}
                </div>
              )) || "N/A"
            )}
          </div>
        </div>

        {Array.isArray(customFieldsData) &&
          customFieldsData?.map(({ name, slug, type, options }, i) => {
            var multiSelectValues = [];
            if (
              type === "multi_select" &&
              typeof customFormFieldsData?.[slug] === "string"
            ) {
              multiSelectValues = customFormFieldsData?.[slug]
                ?.split(",")
                ?.map((x) => ({
                  label: x,
                  value: x,
                }));
            }

            return (
              <React.Fragment key={i}>
                {(type === "single_line_text" || type === "number") && (
                  <div className="agent-info-item">
                    <span className="title">{name}:</span>
                    <span className="value">
                      {isEditActive ? (
                        <TextField
                          name={slug}
                          value={customFormFieldsData?.[slug] || ""}
                          onChange={handleCFChange}
                        />
                      ) : (
                        custom_fields?.[slug] || "N/A"
                      )}
                    </span>
                  </div>
                )}

                {type === "multi_line_text" && (
                  <div className="agent-info-item">
                    <span className="title">{name}:</span>
                    <span className="value">
                      {isEditActive ? (
                        <TextArea
                          name={slug}
                          value={customFormFieldsData?.[slug] || ""}
                          onChange={handleCFChange}
                          rows="3"
                          noAction
                        />
                      ) : (
                        custom_fields?.[slug] || "N/A"
                      )}
                    </span>
                  </div>
                )}

                {type === "attachment" && (
                  <div className="agent-info-item">
                    <span className="title">{name}:</span>
                    <span className="value">
                      {isEditActive && isUploadingFile === null ? (
                        <FileInput
                          icon={
                            <span className="secondary-button small">
                              Upload File
                            </span>
                          }
                          onFileLoad={handleFileUpload}
                          onFileError={() => {}}
                        />
                      ) : isUploadingFile ? (
                        "Uploading... Please wait"
                      ) : newFileUploaded ? (
                        "Upload successful!"
                      ) : (
                        <span className="ellipse">
                          {custom_fields?.[slug] || "N/A"}
                        </span>
                      )}
                    </span>
                  </div>
                )}

                {type === "single_select" && (
                  <div className="agent-info-item">
                    <span className="title">{name}:</span>
                    <span className="value groups-container">
                      {isEditActive ? (
                        <div className="input-container">
                          <Select
                            classNamePrefix="select_container"
                            menuPlacement="top"
                            value={customFormFieldsData?.[slug]}
                            options={options || []}
                            onChange={(selection) =>
                              handleCFChange({
                                target: { name: slug, value: selection },
                              })
                            }
                          />
                        </div>
                      ) : custom_fields?.[slug]?.value ? (
                        custom_fields?.[slug]?.value
                      ) : (
                        custom_fields?.[slug] || "N/A"
                      )}
                    </span>
                  </div>
                )}

                {type === "multi_select" && (
                  <div className="agent-info-item">
                    <span className="title">{name}:</span>
                    <span className="value groups-container">
                      {isEditActive ? (
                        <div className="input-container">
                          <Select
                            classNamePrefix="select_container"
                            menuPlacement="top"
                            value={
                              Array.isArray(customFormFieldsData?.[slug])
                                ? customFormFieldsData?.[slug]
                                : multiSelectValues
                            }
                            options={options || []}
                            onChange={(selections) =>
                              handleCFChange({
                                target: { name: slug, value: selections },
                              })
                            }
                            isMulti
                          />
                        </div>
                      ) : typeof customFormFieldsData?.[slug] === "string" ? (
                        custom_fields?.[slug]
                      ) : (
                        custom_fields?.[slug]
                          ?.map((x) => x?.value)
                          ?.toString() || "N/A"
                      )}
                    </span>
                  </div>
                )}

                {type === "date" && (
                  <div className="agent-info-item">
                    <span className="title">{name}:</span>
                    <span className="value groups-container">
                      {isEditActive ? (
                        <div className="input-container">
                          <div className="date_range_picker">
                            <Calendar />
                            <DatePicker
                              className="date_range_input"
                              selected={
                                customFormFieldsData?.[slug]
                                  ? new Date(customFormFieldsData?.[slug])
                                  : new Date()
                              }
                              onChange={(date) =>
                                handleCFChange({
                                  target: { name: slug, value: date },
                                })
                              }
                              value={moment(
                                customFormFieldsData?.[slug]
                                  ? new Date(customFormFieldsData?.[slug])
                                  : new Date()
                              )?.format("DD MMM, YYYY")}
                              maxDate={new Date()}
                            />
                          </div>
                        </div>
                      ) : (
                        moment(custom_fields?.[slug]).format("DD MMM, YYYY") ||
                        "N/A"
                      )}
                    </span>
                  </div>
                )}
              </React.Fragment>
            );
          })}
      </div>

      {isNewGroupFormActive && (
        <GroupForm toggleGroupForm={() => setIsNewGroupFormActive(false)} />
      )}
    </div>
  );
};

const mapStateToProps = ({ customers }) => customers;

export default connect(mapStateToProps, {})(CustomerInfo);
