import React from "react";

export default () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="5"
      height="8"
      fill="none"
      viewBox="0 0 5 8"
    >
      <path
        fill="#686C73"
        d="M1.925 4.25l2.844 2.544a.655.655 0 010 1 .857.857 0 01-1.117 0L.218 4.72a.617.617 0 010-.942L3.652.707a.857.857 0 011.117 0 .655.655 0 010 .999L1.925 4.25z"
      ></path>
    </svg>
)