import {
  getRequest,
  postRequest,
  patchRequest,
  deleteRequest,
} from "../../api";

import {
  CREATE_TEAM_LOADER,
  CREATE_TEAM_RESPONSE,
  CREATE_TEAM_RESET,
  UPDATE_TEAM_LOADER,
  UPDATE_TEAM_RESPONSE,
  UPDATE_TEAM_RESET,
  GET_TEAM_LOADER,
  GET_TEAM_RESPONSE,
  GET_TEAM_RESET,
  DELETE_TEAM_LOADER,
  DELETE_TEAM_RESPONSE,
  DELETE_TEAM_RESET,
} from "../constants";

import { getRequestError } from "../../utils/functions";

export const createTeamLoader = (isCreatingTeam) => ({
  type: CREATE_TEAM_LOADER,
  isCreatingTeam,
});

export const createTeamResponse = (createTeamOutcome, createTeamMessage) => ({
  type: CREATE_TEAM_RESPONSE,
  createTeamOutcome,
  createTeamMessage,
});

export const createTeamReset = () => ({
  type: CREATE_TEAM_RESET,
});

export const updateTeamLoader = (isUpdatingTeam) => ({
  type: UPDATE_TEAM_LOADER,
  isUpdatingTeam,
});

export const updateTeamResponse = (updateTeamOutcome, updateTeamMessage) => ({
  type: UPDATE_TEAM_RESPONSE,
  updateTeamOutcome,
  updateTeamMessage,
});

export const updateTeamReset = () => ({
  type: UPDATE_TEAM_RESET,
});

export const getTeamLoader = (isLoadingTeam) => ({
  type: GET_TEAM_LOADER,
  isLoadingTeam,
});

export const getTeamResponse = (
  teamsOutcome,
  teamsData,
  teamsMeta,
  teamsMessage
) => ({
  type: GET_TEAM_RESPONSE,
  teamsOutcome,
  teamsData,
  teamsMeta,
  teamsMessage,
});

export const getTeamReset = () => ({
  type: GET_TEAM_RESET,
});

export const deleteTeamLoader = (isDeletingTeam) => ({
  type: DELETE_TEAM_LOADER,
  isDeletingTeam,
});

export const deleteTeamResponse = (deleteTeamOutcome, deleteTeamMessage) => ({
  type: DELETE_TEAM_RESPONSE,
  deleteTeamOutcome,
  deleteTeamMessage,
});

export const deleteTeamReset = () => ({
  type: DELETE_TEAM_RESET,
});

export const getTeamAction = (params = {}) => {
  return async (dispatch) => {
    dispatch(getTeamReset());
    dispatch(getTeamLoader(true));

    try {
      const res = await getRequest({
        url: "/team",
        params,
        token: true,
      });

      const { data, offset, limit, total } = res.data;

      dispatch(
        getTeamResponse(
          "success",
          data.length ? data : [],
          { offset, limit, total },
          ""
        )
      );
      dispatch(getTeamLoader(false));
    } catch (error) {
      const message = getRequestError(error);

      dispatch(getTeamResponse("error", [], {}, message));
      dispatch(getTeamLoader(false));
    }
  };
};

export const createTeamAction = (data) => {
  return async (dispatch) => {
    dispatch(createTeamReset());
    dispatch(createTeamLoader(true));

    try {
      const res = await postRequest({
        url: "/team",
        data,
        token: true,
      });

      dispatch(createTeamResponse("success", ""));
      dispatch(createTeamLoader(false));

      return res?.data?.data;
    } catch (error) {
      const message = getRequestError(error);

      dispatch(createTeamResponse("error", message));
      dispatch(createTeamLoader(false));
    }
  };
};

export const updateTeamAction = (data, id) => {
  return async (dispatch) => {
    dispatch(updateTeamReset());
    dispatch(updateTeamLoader(true));

    try {
      await patchRequest({
        url: `/team/${id}`,
        data,
        token: true,
      });

      dispatch(updateTeamResponse("success", ""));
      dispatch(updateTeamLoader(false));
    } catch (error) {
      const message = getRequestError(error);

      dispatch(updateTeamResponse("error", message));
      dispatch(updateTeamLoader(false));
    }
  };
};

export const addToTeamAction = (data, id, type) => {
  return async (dispatch) => {
    dispatch(updateTeamReset());
    dispatch(updateTeamLoader(true));

    try {
      await postRequest({
        url: `/team/${id}/${type}`,
        data,
        token: true,
      });

      dispatch(updateTeamResponse("success", ""));
      dispatch(updateTeamLoader(false));
    } catch (error) {
      const message = getRequestError(error);

      dispatch(updateTeamResponse("error", message));
      dispatch(updateTeamLoader(false));
    }
  };
};

export const removeFromTeamAction = (data, id, type) => {
  return async (dispatch) => {
    dispatch(updateTeamReset());
    dispatch(updateTeamLoader(true));

    try {
      await deleteRequest({
        url: `/team/${id}/${type}`,
        data,
        token: true,
      });

      dispatch(updateTeamResponse("success", ""));
      dispatch(updateTeamLoader(false));
    } catch (error) {
      const message = getRequestError(error);

      dispatch(updateTeamResponse("error", message));
      dispatch(updateTeamLoader(false));
    }
  };
};

export const deleteTeamAction = (id) => {
  return async (dispatch) => {
    dispatch(deleteTeamReset());
    dispatch(deleteTeamLoader(true));

    try {
      await deleteRequest({
        url: `/team/${id}`,
        token: true,
      });

      dispatch(deleteTeamResponse("success", ""));
      dispatch(deleteTeamLoader(false));
    } catch (error) {
      const message = getRequestError(error);

      dispatch(deleteTeamResponse("error", message));
      dispatch(deleteTeamLoader(false));
    }
  };
};
