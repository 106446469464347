import React, { useState, useEffect } from "react";
import classNames from "classnames";

import { AngleRight } from "../../assets/vectors";

const Pagination = ({ meta, paginateTableAction }) => {
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [paginationList, setPaginationList] = useState([]);

  useEffect(() => {
    const { currentPage, totalPage } = meta || {};

    if (currentPage && totalPage) {
      const pageNumbers = Array(totalPage)
        .fill(0)
        .map((x, i) => i + 1);

      const paginationBase = Math.floor((currentPage - 1) / 5) * 5;
      const paginationStart = paginationBase + 1;
      const paginationEnd = paginationBase + 5;

      const paginationList = pageNumbers.filter(
        (page) => page >= paginationStart && page <= paginationEnd
      );

      setTotalPages(totalPage);
      setCurrentPage(currentPage);
      setPaginationList(paginationList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meta]);

  const paginateTable = (page) => {
    if (page <= totalPages && page > 0) {
      paginateTableAction(page);
    }
  };

  return (
    <>
      {totalPages ? (
        <div className="pagination-container">
          <div className="total-results">
            Showing page {currentPage} of {totalPages}
          </div>

          <div className="pagination-actions">
            <span
              className={classNames("previous", {
                disabled: currentPage === 1,
              })}
              onClick={() => paginateTable(currentPage - 1)}
            >
              <AngleRight />
            </span>
            {paginationList.map((p, idx) => (
              <span
                className={classNames("", {
                  current: currentPage === p,
                })}
                key={`paginate-${idx}`}
                onClick={() => paginateTable(p)}
              >
                {p}
              </span>
            ))}
            <span
              className={classNames("next", {
                disabled: currentPage === totalPages,
              })}
              onClick={() => paginateTable(currentPage + 1)}
            >
              <AngleRight />
            </span>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Pagination;
