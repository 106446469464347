import React from "react";

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      stroke="#757F8A"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M18.334 2.5H1.667l6.667 7.883v5.45l3.333 1.667v-7.117L18.334 2.5z"
    ></path>
  </svg>
);
