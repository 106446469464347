import React from "react";

export default () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <circle cx="9" cy="9" r="9" fill="url(#instagramPaint0_linear)"></circle>
      <path
        fill="#fff"
        d="M9.003 4.008c-2.048 0-3.992-.182-4.735 1.724-.306.787-.262 1.81-.262 3.273 0 1.285-.041 2.492.262 3.273.741 1.907 2.701 1.724 4.733 1.724 1.96 0 3.982.204 4.734-1.724.307-.795.262-1.802.262-3.273 0-1.952.108-3.212-.839-4.158-.958-.959-2.255-.839-4.158-.839h.003zm-.448.9c4.27-.006 4.814-.48 4.514 6.115-.107 2.332-1.883 2.076-4.066 2.076-3.98 0-4.095-.114-4.095-4.096 0-4.029.316-4.092 3.647-4.095zm3.115.83a.6.6 0 100 1.2.6.6 0 000-1.2zm-2.667.701a2.566 2.566 0 100 5.132 2.566 2.566 0 000-5.132zm0 .9c2.201 0 2.204 3.332 0 3.332-2.202 0-2.205-3.331 0-3.331z"
      ></path>
      <defs>
        <linearGradient
          id="instagramPaint0_linear"
          x1="1.209"
          x2="17.887"
          y1="16.827"
          y2="2.39"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FD5"></stop>
          <stop offset="0.5" stopColor="#FF543E"></stop>
          <stop offset="1" stopColor="#C837AB"></stop>
        </linearGradient>
      </defs>
    </svg>
)