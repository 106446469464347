import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  getAllChannelsAction,
  getFirmChannelsAction,
  deleteChannelsAction,
} from "../../../redux/actions/Channels";
import { getPlansDataAction } from "../../../redux/actions/Billing";
import { getUserProfileAction } from "../../../redux/actions/Auth";

import TwitterForm from "./SetupForms/TwitterForm";
import EmailProForm from "./SetupForms/EmailProForm";
import WhatsappForm from "./SetupForms/WhatsappForm";
import FacebookForm from "./SetupForms/FacebookForm";
import InstagramForm from "./SetupForms/InstagramForm";
import LiveChatForm from "./SetupForms/LiveChatForm";
import SmsForm from "./SetupForms/SmsForm";
import WebhookUrlForm from "./WebhookUrlForm";

import OptionsDropdown from "../../../components/OptionsDropdown";

import { ChevronDownW, Layers } from "../../../assets/vectors";
import {
  getUserDetails,
  capitalize,
  getChannelIdentifierIcon,
} from "../../../utils/functions";
import { channelsPermissions } from "../../../utils/permissions";

const comingSoonChannels = [
  "https://s3.eu-west-3.amazonaws.com/oneroute.asb.ng/telegram.png",
  "https://s3.eu-west-3.amazonaws.com/oneroute.asb.ng/wechat.png",
];

class Channels extends Component {
  constructor(props) {
    super(props);

    const { firm_id } = getUserDetails();

    this.state = {
      firm_id,
      isLoadingFirmPlan: true,
      firmPlan: {},
      auth_channel: null,
      selected_email_account: null,
      showWebhookUrlForm: false,
      selectedChannel: {},
    };
  }

  componentDidMount() {
    const { firm_id } = getUserDetails();
    this.setState({
      firm_id,
    });
    this.props.getAllChannelsAction();
    this.props.getFirmChannelsAction();
    this.props.getUserProfileAction().then((res) => {
      if (res?.success === true) {
        const { plan } = res?.data;
        if (plan) {
          this.getFirmPlanInfo(plan);
        }
      }
    });

    // For Channels External Auth Callback Redirect
    const { id } = this.props?.match?.params;
    if (id) {
      this.setState({
        auth_channel: parseInt(id),
      });
    }
  }

  getFirmPlanInfo = (plan) => {
    this.props.getPlansDataAction().then((res) => {
      const firmPlan = res?.find((x) => x?.name === plan);

      this.setState({
        firmPlan,
        isLoadingFirmPlan: false,
      });
    });
  };

  handleCanConnectChannel = (medium) => {
    const { firmPlan } = this.state;

    let services = firmPlan?.services;
    let channel = medium.toLowerCase();

    let response = false;
    if (channel === "whatsapp" && services?.whatsapp === true) {
      response = true;
    }
    if (channel === "facebook messenger") {
      response = true;
    }
    if (channel === "twitter" && services?.twitter === true) {
      response = true;
    }
    if (channel === "instagram" && services?.instagram === true) {
      response = true;
    }
    if (channel === "sms" && services?.sms === true) {
      response = true;
    }
    if (channel === "email" && services?.email === true) {
      response = true;
    }
    if (channel === "livechat" && services?.livechat === true) {
      response = true;
    }

    return response;
  };

  handleChannelAuth = (medium) => {
    switch (medium.toLowerCase()) {
      case "whatsapp":
        return this.handleExternalAuth(1);
      case "facebook messenger":
        return this.handleExternalAuth(2);
      case "twitter":
        return this.handleExternalAuth(3);
      case "instagram":
        return this.handleExternalAuth(4);
      case "email":
        return this.handleExternalAuth(5);
      case "livechat":
        return this.handleExternalAuth(6);
      case "sms":
        return this.handleExternalAuth(7);
      default:
        return;
    }
  };

  handleExternalAuth = (auth_channel) => {
    this.setState({
      auth_channel,
      selected_email_account: null,
    });
  };

  handleSelect = (value, item) => {
    const { id } = item;

    switch (value) {
      case "manage":
        return this.handleManageEmailChannel(5, item);
      case "deactivate":
        return this.handleChannelDeactivation(id);
      case "webhook":
        return this.setState({
          showWebhookUrlForm: true,
          selectedChannel: item,
        });
      default:
        return "";
    }
  };

  handleManageEmailChannel = (auth_channel, item) => {
    this.setState({
      auth_channel,
      selected_email_account: item,
    });
  };

  handleChannelDeactivation = (id) => {
    this.props.deleteChannelsAction(id);
  };

  otherChannelOptions = () => {
    var options = [];

    if (channelsPermissions?.update || channelsPermissions?.modifyAll) {
      options = [
        ...options,
        { label: "Add/Edit Webhook URL", value: "webhook" },
      ];
    }

    if (channelsPermissions?.delete) {
      options = [...options, { label: "Deactivate", value: "deactivate" }];
    }

    return options;
  };

  emailChannelOptions = () => {
    var options = [];

    if (channelsPermissions?.update || channelsPermissions?.modifyAll) {
      options = [
        ...options,
        { label: "Add/Edit Webhook URL", value: "webhook" },
        { label: "Manage Configuration", value: "manage" },
      ];
    }

    if (channelsPermissions?.delete) {
      options = [...options, { label: "Deactivate", value: "deactivate" }];
    }

    return options;
  };

  closeForm = () => {
    const { history } = this.props;
    this.setState(
      {
        auth_channel: null,
      },
      () => {
        history.push("/settings/channels");
      }
    );
  };

  render() {
    const { isLoadingChannels, allChannelsData, firmChannelsData } = this.props;
    const {
      isLoadingFirmPlan,
      firmPlan,
      auth_channel,
      selected_email_account,
      showWebhookUrlForm,
      selectedChannel,
    } = this.state;

    const availableChannelsData = allChannelsData?.filter(
      (x) => x.status === "active"
    );
    // ?.filter((x) => x.name !== "twitter");

    const connectedChannelsData = firmChannelsData?.filter(
      (x) => x.status === "ACTIVE" || x.status === "PENDING"
    );

    const whatsappChannels =
      connectedChannelsData?.filter((x) => x.medium === "WHATSAPP") || [];
    const facebookChannels =
      connectedChannelsData?.filter((x) => x.medium === "FACEBOOK") || [];
    const twitterChannels =
      connectedChannelsData?.filter((x) => x.medium === "TWITTER") || [];
    const instagramChannels =
      connectedChannelsData?.filter((x) => x.medium === "INSTAGRAM") || [];
    const emailChannels =
      connectedChannelsData?.filter((x) => x.medium === "EMAIL") || [];
    const liveChatChannels =
      connectedChannelsData?.filter((x) => x.medium === "WIDGET") || [];
    const smsChannels =
      connectedChannelsData?.filter((x) => x.medium?.includes("SMS")) || [];

    const connectedChannels = [
      { medium: "WHATSAPP", data: whatsappChannels },
      { medium: "FACEBOOK MESSENGER", data: facebookChannels },
      { medium: "INSTAGRAM", data: instagramChannels },
      { medium: "TWITTER", data: twitterChannels },
      { medium: "EMAIL", data: emailChannels },
      { medium: "LIVECHAT", data: liveChatChannels },
      { medium: "SMS", data: smsChannels },
    ];

    return (
      <div className="settings-page-container">
        <div className="page-header">
          <span className="title">Channels</span>
        </div>
        <div className="integrations-container">
          {isLoadingChannels || isLoadingFirmPlan ? (
            <div className="no-channels">
              <p>Loading...</p>
            </div>
          ) : (
            <>
              {(channelsPermissions.create ||
                channelsPermissions.modifyAll) && (
                <div className="integration-section">
                  <div className="integration-section-title">
                    Available Channels
                  </div>
                  <div className="integration-section-content">
                    {availableChannelsData?.map(({ name, imageUrl }, index) => (
                      <div
                        key={`all-integration-${index}`}
                        className="integration-item channel-item"
                        onClick={() =>
                          this.handleCanConnectChannel(name) &&
                          this.handleChannelAuth(name)
                        }
                      >
                        <img
                          src={
                            name === "livechat"
                              ? getChannelIdentifierIcon("LIVECHAT")
                              : imageUrl
                          }
                          alt=""
                        />
                        <div className="info">
                          <div className="title">{name}</div>
                          <span
                            className={`primary-link blue noline${
                              this.handleCanConnectChannel(name)
                                ? ""
                                : " disabled"
                            }`}
                          >
                            {this.handleCanConnectChannel(name)
                              ? "+ Add an account"
                              : "Upgrade your plan"}
                          </span>
                        </div>
                      </div>
                    ))}
                    <div className="integration-item channel-item coming-soon">
                      {comingSoonChannels?.map((item, i) => (
                        <img key={i} src={item} alt="" />
                      ))}
                      <div className="info">
                        <div className="title">
                          Additional channel support coming soon
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="integration-section">
                <div className="integration-section-title">Active Channels</div>
                {connectedChannelsData?.length > 0 ? (
                  <div className="integration-section-content d-block">
                    {connectedChannels?.map(({ medium, data }, index) => (
                      <div
                        key={`my-integration-${index}`}
                        className="active-channel-item"
                      >
                        {data?.length > 0 && (
                          <div className="channel-title">
                            <div className="left">
                              <img
                                src={getChannelIdentifierIcon(medium)}
                                alt=""
                              />
                              <div className="title">{capitalize(medium)}</div>
                            </div>
                            {(channelsPermissions.create ||
                              channelsPermissions.modifyAll) && (
                              <span
                                className="primary-link blue noline"
                                onClick={() =>
                                  this.handleCanConnectChannel(medium) &&
                                  this.handleChannelAuth(medium)
                                }
                              >
                                + Add an account
                              </span>
                            )}
                          </div>
                        )}
                        {data?.map((item, i) => (
                          <div key={i} className="channel-info">
                            <div className="list-item">
                              <h6>
                                {item?.name || item?.phone}
                                {item?.env !== "LIVE" && ` (${item?.env})`}
                              </h6>
                              <div className="right">
                                {item?.medium === "EMAIL" ? (
                                  <p>{item?.credentials?.forwarding?.email}</p>
                                ) : (
                                  <p>{item?.identifier || item?.phone}</p>
                                )}
                                <OptionsDropdown
                                  dropdownPlaceholder={
                                    <div
                                      className="status"
                                      onClick={this.toggleOptionsDropdown}
                                    >
                                      Actions
                                      <ChevronDownW />
                                    </div>
                                  }
                                  options={
                                    item?.medium === "EMAIL"
                                      ? this.emailChannelOptions()
                                      : this.otherChannelOptions()
                                  }
                                  onClick={(value) =>
                                    this.handleSelect(value, item)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="no-channels">
                    <Layers />
                    <p>
                      You have not connected any channels yet, please select
                      from the gallery of available channels above and click on
                      the “Add account” button
                    </p>
                  </div>
                )}
              </div>
            </>
          )}
        </div>

        {auth_channel === 1 && (
          <WhatsappForm firmPlan={firmPlan} close={this.closeForm} />
        )}

        {auth_channel === 2 && <FacebookForm close={this.closeForm} />}

        {auth_channel === 3 && <TwitterForm close={this.closeForm} />}

        {auth_channel === 4 && <InstagramForm close={this.closeForm} />}

        {auth_channel === 5 && (
          <EmailProForm
            close={this.closeForm}
            selected_account={selected_email_account}
          />
        )}

        {auth_channel === 6 && <LiveChatForm close={this.closeForm} />}

        {auth_channel === 7 && <SmsForm close={this.closeForm} />}

        {showWebhookUrlForm === true && (
          <WebhookUrlForm
            close={() =>
              this.setState({
                showWebhookUrlForm: false,
              })
            }
            selectedChannel={selectedChannel}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ channels, billing }) => ({
  ...channels,
  ...billing,
});

export default withRouter(
  connect(mapStateToProps, {
    getAllChannelsAction,
    getFirmChannelsAction,
    deleteChannelsAction,
    getPlansDataAction,
    getUserProfileAction,
  })(Channels)
);
