import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { deleteTeamAction } from "../../../redux/actions/Teams";

import { Button } from "../../../components/FormElements";
import { Trash } from "../../../assets/vectors";
import Modal from "../../../components/Modal";

const DeleteTeamModal = ({ toggleForm, teamData }) => {
  const dispatch = useDispatch();

  const { isDeletingTeam } = useSelector((state) => state.teams);

  const deleteTeam = () => {
    dispatch(deleteTeamAction(teamData?.id));
  };

  return (
    <Modal closeModal={() => toggleForm()}>
      <div className="modal-content confirmation-modal">
        <div className="modal-icon red">
          <Trash />
        </div>
        <div className="modal-title">
          Are you sure you want to delete this team?
        </div>
        <div className="modal-text">This action cannot be reversed</div>
        <div className="modal-action">
          <Button
            className="secondary-button red"
            type="button"
            onClick={deleteTeam}
            disabled={isDeletingTeam}
          >
            {isDeletingTeam ? "Processing..." : "Delete Team"}
          </Button>
          <Button
            className="plain-button black"
            type="button"
            onClick={toggleForm}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteTeamModal;
