/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import * as moment from "moment";

import { getRequest, patchRequest } from "../../api";

import GlobalSearch from "../../components/GlobalSearch";
import Table from "../../components/Table";
import OverviewCard from "../../components/OverviewCard";
import { Button } from "../../components/FormElements";

import { getConversationsAction } from "../../redux/actions/Conversations";

import { getFirmChannelsAction } from "../../redux/actions/Channels";

import {
  getUserDetails,
  getRequestError,
  getTimeOfDay,
  getChannelInfo,
} from "../../utils/functions";
import {
  Chat,
  Spinner,
  Resolved,
  Tickets,
  GetStarted,
} from "../../assets/vectors";

let hidden = null;
let visibilityChange = null;
if (typeof document.hidden !== "undefined") {
  hidden = "hidden";
  visibilityChange = "visibilitychange";
} else if (typeof document.msHidden !== "undefined") {
  hidden = "msHidden";
  visibilityChange = "msvisibilitychange";
} else if (typeof document.webkitHidden !== "undefined") {
  hidden = "webkitHidden";
  visibilityChange = "webkitvisibilitychange";
}
console.log(hidden);

class Home extends Component {
  state = {
    isSearchActive: false,
    currentTab: "in-progress",
    conversationStats: {},
    conversationResults: [],
  };

  switchTab = (currentTab) => {
    this.setState(
      {
        currentTab,
      },
      () => {
        currentTab === "all"
          ? this.getAllConversations()
          : currentTab === "in-progress"
          ? this.getAssignedConversations()
          : this.getQueueConversations();
      }
    );
  };

  toggleIsSearchActive = (value) => {
    this.setState({
      isSearchActive: value,
    });
  };

  getStats = async () => {
    try {
      const res = await getRequest({
        url: "/stats/conversations",
        token: true,
      });

      this.setState({
        conversationStats: res.data.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

  acceptConversation = async (id, customer_id, channel_id) => {
    if (id) {
      try {
        await patchRequest({
          url: `conversations/${id}/assign`,
          token: true,
        });

        this.getAssignedConversations(true);
        this.getQueueConversations(true);

        this.props.history.push(`/conversations/${customer_id}/${channel_id}`);
      } catch (error) {
        const message = getRequestError(error);
        console.log(message, error);
      }
    }
  };

  getAssignedConversations = (noReset) => {
    const { getConversationsAction } = this.props;
    const { query } = this.state;
    const { id } = getUserDetails();

    getConversationsAction(
      {
        ...query,
        agent_id: id,
        status: "open",
      },
      "openConversations",
      noReset
    );
    getConversationsAction(
      {
        ...query,
        agent_id: id,
        status: "in-progress",
      },
      "inProgressConversations",
      noReset
    );
    getConversationsAction(
      {
        ...query,
        agent_id: id,
        status: "awaiting-customer-response",
      },
      "awaitingResponseConversations",
      noReset
    );
  };

  getQueueConversations = (noReset) => {
    const { getConversationsAction } = this.props;
    const { query } = this.state;

    getConversationsAction(
      {
        ...query,
        status: "in-queue",
      },
      "queueConversations",
      noReset
    );
  };

  getAllConversations = (noReset) => {
    const { getConversationsAction } = this.props;
    const { query } = this.state;

    getConversationsAction(
      {
        ...query,
        status: null,
      },
      "allConversations",
      noReset
    );
  };

  componentDidMount() {
    document.addEventListener(
      visibilityChange,
      this.handleVisibilityChange,
      false
    );

    this.getStats();
    this.getAssignedConversations();
    this.props.getFirmChannelsAction();
  }

  render() {
    const { isSearchActive, currentTab, conversationStats } = this.state;
    const {
      isLoadingConversations,
      openConversations,
      inProgressConversations,
      awaitingResponseConversations,
      queueConversations,
      allConversations,
      firmChannelsData,
    } = this.props;

    const assignedConversations = [
      ...(openConversations?.data || []),
      ...(inProgressConversations?.data || []),
      ...(awaitingResponseConversations?.data || []),
    ];

    const sortedAssigned =
      assignedConversations?.sort(function (a, b) {
        return new Date(b.updatedAt) - new Date(a.updatedAt);
      }) || [];
    const sortedAll =
      allConversations?.data?.sort(function (a, b) {
        return new Date(b.updatedAt) - new Date(a.updatedAt);
      }) || [];
    const sortedQueue =
      queueConversations?.data?.sort(function (a, b) {
        return new Date(b.updatedAt) - new Date(a.updatedAt);
      }) || [];

    const conversations =
      currentTab === "all"
        ? sortedAll || []
        : currentTab === "in-progress"
        ? sortedAssigned || []
        : sortedQueue || [];

    const columns = [
      {
        id: "",
        label: "",
        render: ({ customer }) => {
          const { name, phone, facebookMessengerId } = customer;
          return (
            <div className="customer-info">
              <span className="name">
                {name || phone || facebookMessengerId}
              </span>
            </div>
          );
        },
      },
      {
        id: "",
        label: "",
        render: ({ channel }) => (
          <img
            src={getChannelInfo(channel?.medium).icon}
            alt=""
            className="customer-channel"
          />
        ),
      },
      {
        id: "",
        label: "",
        render: ({ channel, message }) => {
          return (
            <span className="ellipsis preview-message">
              {message.length
                ? channel?.medium === "EMAIL" ||
                  message[0].contentType === "TEMPLATE"
                  ? message[0]?.clean_content || "Re-reply"
                  : message[0].contentType === "TEXT"
                  ? message[0].content
                  : `-- ${message[0].contentType} --`
                : ""}
            </span>
          );
        },
      },
      {
        id: "",
        label: "",
        render: ({ updatedAt }) => (
          <span className="message-date">
            {moment(updatedAt).format("HH:mm")} |{" "}
            {moment(updatedAt).format("DD MMMM, YYYY")}
          </span>
        ),
      },
      {
        id: "",
        label: "",
        render: ({ id, customer_id, channel }) =>
          currentTab === "all" || currentTab === "in-progress" ? (
            <Link
              className="primary-link semi-bold blue"
              to={`/conversations/${customer_id}/${channel?.id}`}
            >
              Open Message
            </Link>
          ) : (
            <a
              className="primary-link semi-bold blue"
              onClick={() =>
                this.acceptConversation(id, customer_id, channel?.id)
              }
            >
              Accept Conversation
            </a>
          ),
      },
    ];

    const { firstName, roleName } = getUserDetails();

    return (
      <div className="home-container">
        <div className="title">
          Good {getTimeOfDay()}, {firstName}
        </div>
        <div className="search-section">
          <GlobalSearch
            isSearchActive={isSearchActive}
            toggleIsSearchActive={this.toggleIsSearchActive}
            searchAgentInConversations={false}
            closeOnClick={true}
          />
        </div>
        <span className="home-section-title">Overview</span>
        <div className="overview-section">
          <OverviewCard
            icon={<Chat />}
            title="Open conversations"
            value={conversationStats.open}
          />
          <OverviewCard
            icon={<Spinner />}
            title="Conversations in-progress"
            value={conversationStats.inProgress}
          />
          <OverviewCard
            icon={<Resolved />}
            title="Resolved conversations"
            value={conversationStats.closed}
          />
          <OverviewCard
            icon={<Tickets />}
            title="All conversations"
            value={conversationStats.total}
          />
        </div>
        <span className="home-section-title">Conversations</span>
        <div className="table-container">
          <div className="table-tabs">
            <div
              className={classNames("table-tab-item", {
                active: currentTab === "in-progress",
              })}
              onClick={() => this.switchTab("in-progress")}
            >
              Assigned to me{" "}
              {assignedConversations?.length && (
                <span className="table-tab-item-badge">
                  {assignedConversations?.length}
                </span>
              )}
            </div>
            <div
              className={classNames("table-tab-item", {
                active: currentTab === "open",
              })}
              onClick={() => this.switchTab("open")}
            >
              Queue{" "}
              {queueConversations?.data && (
                <span className="table-tab-item-badge">
                  {queueConversations?.data?.length}
                </span>
              )}
            </div>
            {roleName === "manager" && (
              <div
                className={classNames("table-tab-item", {
                  active: currentTab === "all",
                })}
                onClick={() => this.switchTab("all")}
              >
                All Conversations{" "}
                {allConversations?.data && (
                  <span className="table-tab-item-badge">
                    {allConversations?.data?.length}
                  </span>
                )}
              </div>
            )}
          </div>

          {firmChannelsData?.length !== 0 && !isLoadingConversations && (
            <Table
              loading={isLoadingConversations}
              columns={columns}
              rows={conversations}
              editRow={this.toggleAgentForm}
              deleteRow={this.toggleConfirmationModal}
              paginateTable={this.paginateTable}
              noHeader
            />
          )}

          {firmChannelsData?.length === 0 && !isLoadingConversations && (
            <div className="get_started_block">
              <GetStarted />
              <p>You are yet to set up your channels and add your agents.</p>
              <Button
                onClick={() => this.props.history.push(`/get-started`)}
                className="get_started_block_button"
              >
                Go back to set up
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth, conversations, channels }) => ({
  ...auth,
  ...conversations,
  ...channels,
});

export default withRouter(
  connect(mapStateToProps, {
    getConversationsAction,
    getFirmChannelsAction,
  })(Home)
);
