import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as moment from "moment";
import DatePicker from "react-datepicker";
import { Line, Pie } from "react-chartjs-2";

import { Calendar, ArrowUp, Info } from "../../../assets/vectors";

import { getCustomersInsightsAction } from "../../../redux/actions/Insights";

var daysFromNow = moment().subtract(7, "days");
daysFromNow = daysFromNow.format("YYYY-MM-DD");
const today = moment().format("YYYY-MM-DD");

const CustomersInsights = () => {
  const dispatch = useDispatch();
  const {
    isLoadingCustomersInsights,
    totalStatsData,
    newCustomersData,
    customersByChannelData,
  } = useSelector((state) => state.insights);

  const [startDate, setStartDate] = useState(daysFromNow);
  const [endDate, setEndDate] = useState(today);
  const [showPercentage, setShowPercentage] = useState(false);
  const [params, setParams] = useState({});

  const statsInfoData = [
    {
      label: "Total Customers",
      lastRange: totalStatsData?.lastRange,
      total: totalStatsData?.total,
      change: totalStatsData?.change,
    },
    {
      label: "Most used channel",
      lastRange: " -- --",
      total: customersByChannelData?.popularChannel?.channel || "- - -",
      change: `${customersByChannelData?.popularChannel?.percentage || "- -"}%`,
      time: "time",
    },
  ];

  useEffect(() => {
    if (params?.toDate) {
      dispatch(
        getCustomersInsightsAction({
          url: "all",
          params,
          section: "totalStatsData",
        })
      );
      dispatch(
        getCustomersInsightsAction({
          url: "breakdown",
          params,
          section: "newCustomersData",
        })
      );
      dispatch(
        getCustomersInsightsAction({
          url: "channel",
          params,
          section: "customersByChannelData",
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const datePickerDisplayValue = (sDate, eDate) => {
    return `${
      moment(sDate).format("MMM DD, YYYY") +
      " - " +
      `${
        eDate
          ? moment(eDate).format("MMM DD, YYYY")
          : moment(sDate).format("MMM DD, YYYY")
      }`
    }`;
  };

  const dateRangeIsNotSameDay = (sDate, eDate) => {
    if (eDate && moment(sDate).format("x") !== moment(eDate).format("x")) {
      return true;
    } else return true;
  };

  useEffect(() => {
    if (endDate && dateRangeIsNotSameDay(startDate, endDate)) {
      setParams({
        fromDate: moment(startDate).format("YYYY-MM-DD"),
        toDate: moment(endDate).format("YYYY-MM-DD"),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDate]);

  const newCustomersChartData = {
    labels: newCustomersData?.breakdown?.map((x) => x?.day) || [],
    datasets: [
      {
        label: "",
        lineTension: 0,
        fill: false,
        borderColor: "#AEDEFF",
        pointBorderColor: "#18A0FB",
        pointBackgroundColor: "#18A0FB",
        pointBorderWidth: 1,
        pointRadius: 4,
        pointHoverBackgroundColor: "#AEDEFF",
        pointHoverBorderColor: "#AEDEFF",
        pointHoverBorderWidth: 1,
        pointHoverRadius: 4,
        pointHitRadius: 4,
        data: newCustomersData?.breakdown?.map((x) => x?.count) || [],
      },
    ],
    options: {
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false,
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              padding: 15,
              maxTicksLimit: 8,
            },
            gridLines: {
              drawBorder: false,
            },
          },
        ],
      },
    },
  };

  const customersByChannelChartData = {
    labels: customersByChannelData?.breakdown?.map((x) => x?.channel) || [],
    datasets: [
      {
        data:
          customersByChannelData?.breakdown?.map((x) =>
            showPercentage ? x?.percentage : x?.count
          ) || [],
        backgroundColor: [
          "#4CAF50",
          "#18A0FB",
          "#F97272",
          "#4BA3E7",
          "#F686CE",
        ],
        borderWidth: 2,
      },
    ],
    options: {
      maintainAspectRatio: false,
      legend: {
        position: "chartArea",
        align: "center",
        labels: {
          padding: 25,
          boxWidth: 14,
          boxHeight: 14,
        },
      },
    },
  };

  const datePickerConfig = {
    className: "date_range_input",
    dateFormat: "yyyy-MM-dd",
    // minDate: new Date(moment().subtract(120, "days")),
    maxDate: new Date(),
    selectsRange: "selectsRange",
    shouldCloseOnSelect: false,
  };

  return (
    <div className="insights_page_container_new">
      <h5 className="page_title">Customers</h5>

      <div className="filter_container">
        <div className="date_range_picker">
          <Calendar />
          <DatePicker
            onChange={([start, end]) => {
              setStartDate(start);
              setEndDate(end);
            }}
            startDate={startDate}
            endDate={endDate}
            value={datePickerDisplayValue(startDate, endDate)}
            {...datePickerConfig}
          />
        </div>
        {/* <Button className="primary-button small" onClick={() => {}}>
          <span className="icon">
            <Notes />
          </span>
          <span>Export CSV</span>
        </Button> */}
      </div>

      {isLoadingCustomersInsights && !totalStatsData?.from ? (
        <div className="loading">Loading...</div>
      ) : (
        <>
          <div className="stats_container">
            {statsInfoData?.map((item, i) => (
              <div className="stat_item" key={i}>
                <h6 className="title_with_icon">
                  {item?.label}
                  <Info />
                </h6>
                <h5 className="value">{item?.total}</h5>
                <div
                  className={`changes ${item?.change >= 0 ? "increment" : ""}`}
                >
                  {!item?.time && <ArrowUp />}
                  {!item?.time && <p>{item?.change}%</p>}
                  <span>
                    {item?.time
                      ? `${item?.change} of Customer`
                      : "vs Last period"}
                  </span>
                </div>
              </div>
            ))}
          </div>

          <div className="new_conversation_and_channels_charts no_grid">
            <div className="left">
              <div className="stat_item">
                <h6 className="title_with_icon">
                  New Customers
                  <Info />
                </h6>
                <div
                  className={`changes ${
                    newCustomersData?.change >= 0 ? "increment" : ""
                  }`}
                >
                  <h5 className="value">{newCustomersData?.thisRange}</h5>
                  <ArrowUp />
                  <p>{newCustomersData?.change}%</p>
                  <span>vs Last period</span>
                </div>
              </div>
              <div className="chart">
                <Line
                  data={newCustomersChartData}
                  options={newCustomersChartData.options}
                />
              </div>
            </div>
          </div>

          <div className="conversations_status">
            <div className="d_flex d_block">
              <p className="title_with_icon">
                New Customers by channel <Info />
              </p>
              <div className="percentage_toggle">
                <div
                  className={`switch_toggler ${showPercentage ? "active" : ""}`}
                  onClick={() => setShowPercentage(!showPercentage)}
                >
                  <div className="side"></div>
                  <div className="side"></div>
                </div>
                <p>Show Percentages</p>
              </div>
            </div>

            <div className="chart">
              <Pie
                data={customersByChannelChartData}
                options={customersByChannelChartData.options}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CustomersInsights;
