import React from "react";

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    fill="none"
    viewBox="0 0 18 18"
  >
    <circle cx="9" cy="9" r="9" fill="#7359ED"></circle>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="0.926"
      d="M12.867 5.427h-7.6c-.35 0-.634.314-.634.701v5.612c0 .387.283.701.633.701h7.601c.35 0 .633-.314.633-.701V6.128c0-.387-.283-.701-.633-.701z"
    ></path>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="0.926"
      d="M13.367 5.69L10.026 9.39c-.237.262-.528.405-.828.405-.299 0-.59-.143-.827-.405L5.029 5.69"
    ></path>
  </svg>
);
