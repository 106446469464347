import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getFirmChannelsAction } from "../../../redux/actions/Channels";
import { getMessageLogsAnalyticsAction } from "../../../redux/actions/Insights";

import { MessageLogs } from ".";
import { formatNumber } from "../../../utils/functions";

const LogsInsights = () => {
  const dispatch = useDispatch();
  const { firmChannelsData } = useSelector((state) => state.channels);

  const [isFetching, setIsFetching] = useState(true);
  const [analytics, setAnalytics] = useState([]);

  useEffect(() => {
    dispatch(getFirmChannelsAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsFetching(true);

    dispatch(getMessageLogsAnalyticsAction()).then((res) => {
      setAnalytics(res?.data);
      setIsFetching(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const activeChannelsData = firmChannelsData?.filter(
    (x) => x.status === "ACTIVE" || x.status === "PENDING"
  );

  return (
    <div className="insights-page-container">
      <div className="page-header">
        <span className="title">Logs Insights</span>
      </div>

      {isFetching ? (
        <div className="message-logs-analytics-loader">Loading...</div>
      ) : (
        <div className="message-logs-analytics">
          {analytics?.map((item, i) => (
            <div key={i} className="info">
              <h6>{item?.key}</h6>
              <p>
                {formatNumber(item?.value)} <span>({item?.percentage}%)</span>
              </p>
            </div>
          ))}
        </div>
      )}

      <div className="agents-performance-container">
        <MessageLogs analytics={analytics} channelsData={activeChannelsData} />
      </div>
    </div>
  );
};

export default LogsInsights;
