import React from "react";

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    fill="none"
    viewBox="0 0 30 30"
  >
    <path
      stroke="#F7CB45"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M15 2.5l3.863 7.825 8.637 1.263-6.25 6.087 1.475 8.6L15 22.212l-7.725 4.063 1.475-8.6-6.25-6.088 8.637-1.262L15 2.5z"
    ></path>
  </svg>
);
