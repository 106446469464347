import React from "react";

export default ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      stroke={color || "#8F99A6"}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M.946 2.195V7.41a2.5 2.5 0 00.732 1.768l9.692 9.691a.625.625 0 001.055-.319l1.02-5.105 5.106-1.02a.625.625 0 00.319-1.056L9.178 1.678A2.5 2.5 0 007.411.945H2.196a1.25 1.25 0 00-1.25 1.25v0z"
    ></path>
    <path
      stroke={color || "#8F99A6"}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M5.32 6.57a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5z"
    ></path>
  </svg>
);
