import React, { useState, useEffect } from "react";
import * as moment from "moment";
import Select from "react-select";
import AsyncSelect from "react-select/async";

import Table from "../../../components/Table";

import { Sort } from "../../../assets/vectors";
import { formatNumber } from "../../../utils/functions";

const AllUsersRecord = ({
  isFetching,
  records,
  firmChannelsData,
  actions,
  customersData,
  searchCustomer,
  params,
  setParams,
}) => {
  const [tableData, setTableData] = useState([]);

  const [status, setStatus] = useState(null);
  const [channel, setChannel] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [trigger, setTrigger] = useState(null);
  const [lastAction, setLastAction] = useState(null);

  useEffect(() => {
    setTableData(records);
  }, [records]);

  const openConvo = (customer_id, channel_id) => {
    window.open(`/conversations/${customer_id}/${channel_id}`);
  };

  const getCustomerId = (medium, customer) => {
    if (medium === "SMS") return customer?.phone;
    if (medium === "WHATSAPP") return customer?.phone;
    if (medium === "EMAIL") return customer?.email;
    if (medium === "FACEBOOK") return customer?.facebookMessengerId;
    if (medium === "INSTAGRAM") return customer?.instagramUsername;
    if (medium === "TWITTER") return customer?.twitterUsername;
  };

  const paginateTable = (pageNumber) => {
    setParams({ ...params, "page[number]": pageNumber });
  };

  const statusOptions = [
    { value: "active", label: "Active" },
    { value: "ended", label: "Ended" },
  ];

  const activeChannelsData = firmChannelsData?.filter(
    (x) => x.status === "ACTIVE" || x.status === "PENDING"
  );

  const channelOptions =
    activeChannelsData?.map((x) => ({
      label: x?.name,
      value: x?.id,
    })) || [];

  const actionOptions =
    actions?.map((x) => ({
      label: x,
      value: x,
    })) || [];

  const customerOptions = customersData?.map((x) => ({
    label: x?.name || x?.phone,
    value: x?.id,
  }));

  const columns = [
    {
      id: "",
      label: "Channel",
      thClassName: "",
      render: ({ Channel }) => <p>{Channel?.medium}</p>,
    },
    {
      id: "",
      label: "Customer Name",
      thClassName: "",
      render: ({ Customer }) => (
        <p className="ellipsis">{Customer?.name || Customer?.phone}</p>
      ),
    },
    {
      id: "",
      label: "Customer ID",
      thClassName: "",
      render: ({ Channel, Customer }) => (
        <p className="ellipsis">{getCustomerId(Channel?.medium, Customer)}</p>
      ),
    },
    {
      id: "",
      label: "Status",
      thClassName: "",
      render: ({ status }) => (
        <p>
          <span className={`status ${status}`}>{status}</span>
        </p>
      ),
    },
    {
      id: "",
      label: "Start date",
      thClassName: "",
      render: ({ createdAt }) => (
        <p>
          {createdAt ? moment(createdAt).format("DD-MM-YY | hh:mm") : "- - -"}
        </p>
      ),
    },
    {
      id: "",
      label: "Last activity",
      thClassName: "",
      render: ({ updatedAt }) => (
        <p>
          {updatedAt ? moment(updatedAt).format("DD-MM-YY | hh:mm") : "- - -"}
        </p>
      ),
    },
    // {
    //   id: "",
    //   label: "Duration",
    //   thClassName: "",
    //   render: ({ createdAt, updatedAt }) => {
    //     var x = new moment(updatedAt);
    //     var y = new moment(createdAt);
    //     var diff = moment?.duration(x.diff(y))?.as("seconds");

    //     return <p className="ellipsis">{secondsToHms(diff) || "0s"}</p>;
    //   },
    // },
    {
      id: "",
      label: "Last action",
      thClassName: "",
      render: ({ currentWidgetId }) => (
        <p className="ellipsis">{currentWidgetId}</p>
      ),
    },
    {
      id: "",
      label: "Conversation",
      thClassName: "text-right",
      render: ({ customer_id, channel_id }) => (
        <p
          className="text-right link"
          onClick={() => openConvo(customer_id, channel_id)}
        >
          View conversation
        </p>
      ),
    },
  ];

  return (
    <div className="all-users-container bot-sessions-container">
      <div className="insights-section-title">
        <span>Bot sessions ({formatNumber(records?.count)})</span>

        <div className="filters">
          <AsyncSelect
            className="select_customer"
            classNamePrefix="select_container"
            defaultValue={customer}
            onChange={(selected) => {
              setCustomer(selected);
              setParams({ ...params, customer_id: selected?.value });
            }}
            cacheOptions
            placeholder="Customer..."
            defaultOptions={customerOptions}
            loadOptions={searchCustomer}
            isClearable
          />

          <Select
            classNamePrefix="select_container"
            value={status}
            options={statusOptions}
            onChange={(selected) => {
              setStatus(selected);
              setParams({ ...params, status: selected?.value });
            }}
            placeholder="Status..."
            isDisabled={isFetching}
            isClearable
          />

          <Select
            classNamePrefix="select_container"
            value={channel}
            options={channelOptions}
            onChange={(selected) => {
              setChannel(selected);
              setParams({ ...params, channel_id: selected?.value });
            }}
            placeholder="Channel..."
            isDisabled={isFetching}
            isClearable
          />

          <Select
            classNamePrefix="select_container"
            value={trigger}
            options={actionOptions}
            onChange={(selected) => {
              setTrigger(selected);
              setParams({ ...params, trigger: selected?.value });
            }}
            placeholder="Trigger..."
            isDisabled={isFetching}
            isClearable
          />

          <Select
            classNamePrefix="select_container"
            value={lastAction}
            options={actionOptions}
            onChange={(selected) => {
              setLastAction(selected);
              setParams({ ...params, lastAction: selected?.value });
            }}
            placeholder="Last action..."
            isDisabled={isFetching}
            isClearable
          />

          <div
            className="icon"
            onClick={() =>
              setParams({
                ...params,
                sortBy: params?.sortBy === "DESC" ? "ASC" : "DESC",
              })
            }
          >
            <p>
              Sort:{" "}
              <span>{params?.sortBy === "DESC" ? "Newest" : "Oldest"}</span>
            </p>
            <Sort />
          </div>
        </div>
      </div>

      <div className="insights-table">
        <Table
          className="borderless"
          loading={isFetching}
          columns={columns}
          rows={tableData?.data || []}
          meta={{
            currentPage: tableData?.currentPage,
            totalPage: tableData?.totalPage,
          }}
          paginateTable={paginateTable}
        />
      </div>
    </div>
  );
};

export default AllUsersRecord;
