import React, { Component, Suspense } from "react";
import ErrorBoundary from "../../containers/ErrorBoundary";

import lottie from "./Lottie";

import { isClient } from "../../utils/functions";

class LottieAnimation extends Component {
  onMouseOver = (action) => {
    action && this.animation[action]();
  };

  onMouseOut = (action) => {
    action && this.animation[action]();
  };

  componentDidMount() {
    const { autoplay, path } = this.props;

    const animationOptions = {
      container: this.animationWrapper,
      renderer: "svg",
      loop: true,
      autoplay,
      animationData: path,
    };

    if (isClient()) {
      this.animation = lottie.loadAnimation(animationOptions);

      if (autoplay) {
        this.playAnimation = setTimeout(() => {
          this.animation.play();
        }, 500);
      }
    }
  }

  componentWillUnmount() {
    this.animation && this.animation.destroy();
    clearTimeout(this.playAnimation);
  }

  render() {
    const { mouseOver, mouseOut } = this.props;

    return (
      <>
        <ErrorBoundary>
          <Suspense fallback={<div>...</div>}>
            <div
              className="animation-wrapper"
              ref={(el) => (this.animationWrapper = el)}
              onMouseEnter={() => this.onMouseOver(mouseOver)}
              onMouseLeave={() => this.onMouseOut(mouseOut)}
            />
          </Suspense>
        </ErrorBoundary>
      </>
    );
  }
}

export default LottieAnimation;
