import React, { useState } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";

import GlobalSearch from "../../components/GlobalSearch";

import {
  Queue,
  SquareAvatar,
  AngleLeft,
  Conversations,
} from "../../assets/vectors";

import { getUserDetails } from "../../utils/functions";

const MentionSections = ({
  selectedSection,
  selectSection,
  unreadCount,
  isSearchActive,
  toggleIsSearchActive,
}) => {
  const { roleName } = getUserDetails();

  const { openMentions, queueMentions, allMentions } = useSelector(
    (state) => state.mentions
  );

  const [hideDisplay, setHideDisplay] = useState(false);

  const sectionItems = [
    {
      label: "Assigned to me",
      icon: <SquareAvatar />,
      unread: unreadCount["open"],
      messages: openMentions?.count,
    },
    {
      label: "Queue",
      icon: <Queue />,
      unread: unreadCount["in-queue"],
      messages: queueMentions?.count,
    },
  ];

  if (roleName === "manager") {
    sectionItems.push({
      label: "All Activity",
      icon: <Conversations />,
      unread: unreadCount["all"],
      messages: allMentions?.count,
    });
  }

  return (
    <div
      className={classNames("secondary-menu conversation-sections", {
        hideDisplay,
        searchActive: isSearchActive,
      })}
    >
      {!isSearchActive && (
        <div
          className={classNames("toggle-display", { active: hideDisplay })}
          onClick={() => setHideDisplay(!hideDisplay)}
        >
          <AngleLeft />
        </div>
      )}
      <div className="title">Activity</div>

      <div className="secondary-menu-items conversation-section-items">
        <GlobalSearch
          isSearchActive={isSearchActive}
          toggleIsSearchActive={toggleIsSearchActive}
          searchInMentions={true}
        />
        {!isSearchActive &&
          sectionItems?.map(
            ({ label, icon, unread, messages, noStroke }, index) => (
              <React.Fragment key={`conversation-section-${index}`}>
                <div
                  className={classNames("item", {
                    active: selectedSection === label,
                  })}
                  onClick={() => selectSection(label)}
                >
                  <div>
                    <span className={classNames("icon", { noStroke })}>
                      {icon}
                    </span>
                    <span className="text">{label}</span>
                  </div>
                  <div className="counts">
                    {!!unread && unread > 0 && (
                      <span className="unread-count">{unread}</span>
                    )}
                    {messages && (
                      <span className="message-count">{messages}</span>
                    )}
                  </div>
                </div>
              </React.Fragment>
            )
          )}
      </div>
    </div>
  );
};

export default MentionSections;
