import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Button, TextField } from "../../components/FormElements";
import FormNotification from "../../components/Notifications/FormNotification";

import { confirmEmailAction } from "../../redux/actions/Auth";

import { AuthSuccess } from "../../assets/vectors";

const VerifyForm = ({ setActiveForm, token }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { isSigningUp, signUpOutcome, signUpMessage } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (token) submitForm();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const submitForm = (e) => {
    if (e) e.preventDefault();

    dispatch(
      confirmEmailAction({
        token,
      })
    ).then((res) => {
      if (res?.success === true) {
        setActiveForm("sign-up");
        history.push("/sign-up?st=2");
      }
    });
  };

  return (
    <div className="verification_page_container">
      <form className="form">
        <AuthSuccess />

        <p className="title">Verify your email</p>

        <p className="sub_title">
          We just sent a 6-digit code to <span>wolem51@gmail.com</span>. Enter
          the code continue
        </p>

        <TextField
          name="token"
          type="text"
          className="mb-20"
          defaultValue={token}
          readOnly={true}
        />

        <Button disabled={isSigningUp} className="primary-button full small">
          Continue
        </Button>

        {signUpOutcome === "error" && (
          <>
            <br />
            <FormNotification type="error" message={signUpMessage} />
          </>
        )}
      </form>
    </div>
  );
};

export default VerifyForm;
