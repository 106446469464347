import {
    CREATE_CATEGORIES_LOADER,
    CREATE_CATEGORIES_RESPONSE,
    CREATE_CATEGORIES_RESET,
    UPDATE_CATEGORIES_LOADER,
    UPDATE_CATEGORIES_RESPONSE,
    UPDATE_CATEGORIES_RESET,
    GET_CATEGORIES_LOADER,
    GET_CATEGORIES_RESPONSE,
    GET_CATEGORIES_RESET,
    DELETE_CATEGORIES_LOADER,
    DELETE_CATEGORIES_RESPONSE,
    DELETE_CATEGORIES_RESET,
  } from "../constants";
  
  const INIT_STATE = {
    categoriesData: [],
    categoriesMeta: {
      limit: 10,
      offset: 0,
      total: 0
    },
    isCreatingCategory: false,
    isUpdatingCategory: false,
    isDeletingCategory: false,
    isLoadingCategories: true,
    createCategoriesOutcome: "",
    createCategoriesMessage: "",
    updateCategoriesOutcome: "",
    updateCategoriesMessage: "",
    deleteCategoriesOutcome: "",
    deleteCategoriesMessage: "",
    categoriesOutcome: "",
    categoriesMessage: "",
  };
  
  const Categories = (state = INIT_STATE, action) => {
    const {
      type,
      createCategoriesOutcome,
      createCategoriesMessage,
      updateCategoriesOutcome,
      updateCategoriesMessage,
      deleteCategoriesOutcome,
      deleteCategoriesMessage,
      categoriesOutcome,
      categoriesMessage,
      categoriesData,
      categoriesMeta,
      isCreatingCategory,
      isUpdatingCategory,
      isDeletingCategory,
      isLoadingCategories
    } = action;
  
    switch (type) {
      case CREATE_CATEGORIES_LOADER: {
        return {
          ...state,
          isCreatingCategory
        };
      }
  
      case CREATE_CATEGORIES_RESPONSE: {
        return {
          ...state,
          createCategoriesOutcome,
          createCategoriesMessage
        };
      }
  
      case CREATE_CATEGORIES_RESET: {
        return {
          ...state,
          createCategoriesOutcome: "",
          createCategoriesMessage: ""
        };
      }
      
      case UPDATE_CATEGORIES_LOADER: {
        return {
          ...state,
          isUpdatingCategory
        };
      }
  
      case UPDATE_CATEGORIES_RESPONSE: {
        return {
          ...state,
          updateCategoriesOutcome,
          updateCategoriesMessage
        };
      }
  
      case UPDATE_CATEGORIES_RESET: {
        return {
          ...state,
          updateCategoriesOutcome: "",
          updateCategoriesMessage: ""
        };
      }
  
      case DELETE_CATEGORIES_LOADER: {
        return {
          ...state,
          isDeletingCategory
        };
      }
  
      case DELETE_CATEGORIES_RESPONSE: {
        return {
          ...state,
          deleteCategoriesOutcome,
          deleteCategoriesMessage
        };
      }
  
      case DELETE_CATEGORIES_RESET: {
        return {
          ...state,
          deleteCategoriesOutcome: "",
          deleteCategoriesMessage: ""
        };
      }
  
      case GET_CATEGORIES_LOADER: {
        return {
          ...state,
          isLoadingCategories
        };
      }
  
      case GET_CATEGORIES_RESPONSE: {
        return {
          ...state,
          categoriesOutcome,
          categoriesData,
          categoriesMeta, 
          categoriesMessage
        };
      }
  
      case GET_CATEGORIES_RESET: {
        return INIT_STATE;
      }
  
      default:
        return state;
    }
  };
  
  export default Categories;