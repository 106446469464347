import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getChatbotInsightsAction,
  getChatbotInsightsOptionsAction,
} from "../../../redux/actions/Insights";
import { getFirmChannelsAction } from "../../../redux/actions/Channels";
import { getCustomersAction } from "../../../redux/actions/Customers";

import { AllUsersRecord } from ".";

const ChatbotInsights = () => {
  const dispatch = useDispatch();
  const { isLoadingChatbotInsights, chatbotInsightsData } = useSelector(
    (state) => state.insights
  );
  const { firmChannelsData } = useSelector((state) => state.channels);
  const { customersData } = useSelector((state) => state.customers);

  const [params, setParams] = useState({
    sortBy: "DESC",
  });
  const [actionOptions, setActionOptions] = useState([]);

  useEffect(() => {
    dispatch(getFirmChannelsAction());
    dispatch(getCustomersAction());
    dispatch(getChatbotInsightsOptionsAction()).then((res) =>
      setActionOptions(res?.data)
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    filterPerformaceData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const filterPerformaceData = () => {
    dispatch(getChatbotInsightsAction(params));
  };

  const searchCustomer = (inputValue, callback) => {
    dispatch(getCustomersAction({ searchQuery: inputValue })).then((res) => {
      const options = res?.map((x) => ({
        label: x?.name || x?.phone,
        value: x?.id,
      }));
      callback(options);
    });
  };

  return (
    <div className="insights_page_container_new chatbot_insights_page_container">
      <div className="page-header">
        <span className="title">Chatbot</span>
      </div>

      <AllUsersRecord
        isFetching={isLoadingChatbotInsights}
        records={chatbotInsightsData}
        firmChannelsData={firmChannelsData}
        actions={actionOptions}
        customersData={customersData}
        searchCustomer={searchCustomer}
        params={params}
        setParams={setParams}
      />
    </div>
  );
};

export default ChatbotInsights;
