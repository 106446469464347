import React from "react";

export default () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="#474F58"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
        d="M6.853 16.583l-3.728 2.792v-3.75h-1.25a1.25 1.25 0 01-1.25-1.25v-12.5a1.25 1.25 0 011.25-1.25h16.25a1.25 1.25 0 011.25 1.25v6.25"
      ></path>
      <path
        stroke="#474F58"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
        d="M9.373 14.375a5 5 0 1010 0 5 5 0 00-10 0zM14.373 16.875v-5M14.373 11.875l-1.875 1.875M14.373 11.875l1.875 1.875"
      ></path>
    </svg>
)