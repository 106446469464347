import React from "react";

import { Queue } from "../../assets/vectors";

export const emptyStates = [
  {
    type: "Queue",
    icon: <Queue />,
    text: "Accept a conversation from the queue on the left to get started.",
  },
  {
    type: "Assigned to me",
    // icon: <SquareAvatar />,
    icon: <Queue />,
    text:
      "Select a conversation from the list on the left to continue the conversation.",
  },
  {
    type: "Mentions me",
    // icon: <At />,
    icon: <Queue />,
    text:
      "Select a conversation from the list on the left to continue the conversation.",
  },
  {
    type: "All conversations",
    // icon: <Conversations />,
    icon: <Queue />,
    text:
      "Select a conversation from the list on the left to continue the conversation.",
  },
];
