import React from "react";

export default () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke="#757F8A"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10.5 3H13a1 1 0 011 1v10.5a1 1 0 01-1 1H3a1 1 0 01-1-1V4a1 1 0 011-1h2.5a2.5 2.5 0 115 0v0z"
      ></path>
      <path
        stroke="#757F8A"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8 2.5A.25.25 0 118 3a.25.25 0 010-.5M4.5 7H8M10 7h1.5M11.5 9.5H8M6 9.5H4.5M4.5 12H8M10 12h1.5"
      ></path>
    </svg>
)