import React from "react";

export default () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke="#757F8A"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M14.833.5H1.167a.667.667 0 00-.667.667v13.666c0 .368.298.667.667.667h13.666a.667.667 0 00.667-.667V1.167A.667.667 0 0014.833.5z"
      ></path>
      <path
        stroke="#757F8A"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10 12V8a1 1 0 011-1h1M10 10h1.5M8 12V7M5.5 10H6v1a1 1 0 11-2 0V8a1 1 0 011-1h1"
      ></path>
    </svg>
)