import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

import Main from "../../containers";

const AdminRoute = ({ component: Component, ...rest }) => {
  const user = localStorage.getItem("oneroute-user");

  return (
    <Route
      {...rest}
      render={props =>
        user ? (
          JSON.parse(user).userdetail.role === "admin" ? (
            <Main authenticated>
              <Component {...props} />
            </Main>
          ) : (
            <Redirect to={{ pathname: "/", state: { from: props.location } }} />
          )
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

const mapStateToProps = ({ auth }) => auth;

export default connect(mapStateToProps)(AdminRoute);
