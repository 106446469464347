import React from "react";

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      stroke="#757F8A"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.25"
      d="M10.87 11.78l-3.093.443.442-3.095 7.955-7.955a1.875 1.875 0 012.652 2.652L10.87 11.78zM15.29 2.058l2.652 2.651"
    ></path>
    <path
      stroke="#757F8A"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.25"
      d="M15.625 11.875v6.25a1.25 1.25 0 01-1.25 1.25h-12.5a1.25 1.25 0 01-1.25-1.25v-12.5a1.25 1.25 0 011.25-1.25h6.25"
    ></path>
  </svg>
);
