import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";

import { postSendReplyAction } from "../../../redux/actions/Mentions";

import ChatMessage from "./ChatMessage";

import {
  ChatInputNew,
  TextField,
  Button,
} from "../../../components/FormElements";
import Modal from "../../../components/Modal";
import useCloseElement from "../../../components/CustomHooks/useCloseElement";

import {
  Search,
  SquareAvatar,
  CircularCheck,
  Return2,
  Avatar,
  AngleDown,
} from "../../../assets/vectors";

import { conversationsPermissions } from "../../../utils/permissions";

import { getInitials, getUserDetails } from "../../../utils/functions";

const Thread = ({
  selectedMention,
  mentionReplies,
  closeMention,
  selectedSection,
  returnToQueue,
  assignMention,
  getMentionReplies,
}) => {
  const assigneeRef = useRef(null);

  const chatContentRef = useRef();
  const chatFormRef = useRef();

  const dispatch = useDispatch();
  const { isLoadingMentions } = useSelector((state) => state.mentions);
  const { agentsData } = useSelector((state) => state.agents);

  const [replies, setReplies] = useState([]);
  const [status, setStatus] = useState("");
  const [isAssigneeOptionsActive, setIsAssigneeOptionsActive] = useState(false);
  const [filteredAgents, setFilteredAgents] = useState(
    agentsData?.filter((x) => x?.roleName === "agent")
  );
  const [isResolvedModalActive, setIsResolvedModalActive] = useState(false);

  useEffect(() => {
    setReplies(mentionReplies);
  }, [mentionReplies]);

  const getFormHeight = () => {
    return chatFormRef
      ? chatFormRef.current
        ? chatFormRef.current.clientHeight
        : 172
      : 172;
  };

  const handleChange = ({ target }, callBack) => {
    setStatus(target.value);
    callBack && callBack();
  };

  const getAgentName = (agentId) => {
    const agent = agentsData.find(({ id }) => agentId === id);
    const { firstName, lastName } = agent || {};

    return agent ? `${firstName} ${lastName}` : "";
  };

  const filterAssignees = (val) => {
    const agents = agentsData?.filter(
      ({ firstName, lastName, roleName }) =>
        roleName === "agent" &&
        (firstName?.toLowerCase().includes(val) ||
          lastName?.toLowerCase().includes(val))
    );

    setFilteredAgents(agents);
  };

  useCloseElement(assigneeRef, () => setIsAssigneeOptionsActive(false));

  const sendReply = (retry, retryMessage) => {
    const { id, businessUsername } = selectedMention;

    setReplies([
      ...replies,
      {
        text: status,
        senderName: businessUsername,
        senderUsername: businessUsername,
        createdAt: new Date(),
      },
    ]);

    var payload = {
      reply: retry === true ? retryMessage?.text : status,
    };

    setStatus("");

    dispatch(postSendReplyAction({ id, payload })).then((res) => {
      if (res?.success === true) {
        setTimeout(() => getMentionReplies(true), 4000);
      }
    });
  };

  const canUserUpdateConversation =
    conversationsPermissions.update || conversationsPermissions.modifyAll;

  const currentUser = getUserDetails();

  const formHeight = getFormHeight();

  const { id, medium, customerName, customerUsername, agent_id } =
    selectedMention || {};

  return (
    <div className="chats mentions-thread">
      <div className="top-section">
        <div className="chat-header">
          <div className="sender">
            <span className="avatar">
              {getInitials(customerName || customerUsername)}
            </span>
            <span className="name">{customerName || customerUsername}</span>
          </div>
        </div>

        <div className="chat-actions">
          <div>
            {canUserUpdateConversation && (
              <>
                <div
                  className={classNames("select-assignee", {
                    disabled: currentUser.roleName === "agent",
                  })}
                  onClick={() => setIsAssigneeOptionsActive(true)}
                >
                  <SquareAvatar />
                  <span className="colon">:</span>
                  <span className="current-assignee">
                    {status === "in-queue"
                      ? "Unassigned"
                      : getAgentName(agent_id)}
                  </span>
                  <AngleDown />
                </div>
                {isAssigneeOptionsActive && (
                  <div className="assignee-options" ref={assigneeRef}>
                    <div className="search-assignees">
                      <TextField
                        placeholder="Search"
                        leftIcon={<Search />}
                        onChange={(e) => filterAssignees(e.target.value)}
                      />
                    </div>
                    <div className="return-queue">
                      <Return2 />
                      <span onClick={() => returnToQueue(id)}>
                        Return to Queue
                      </span>
                    </div>
                    <span className="title">Assign to teammate:</span>
                    <div className="assignee-list-items">
                      {filteredAgents?.map(
                        ({ firstName, lastName, ...rest }, index) => (
                          <div
                            key={`assignee-item-${index}`}
                            className="assignee-item"
                            onClick={() => assignMention(id, rest.id)}
                          >
                            <span className="icon">
                              <Avatar />
                            </span>
                            <span className="name">
                              {firstName} {lastName}
                            </span>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
          <div>
            {canUserUpdateConversation && selectedSection !== "Resolved" && (
              <>
                <span
                  className="icon"
                  onClick={() => setIsResolvedModalActive(true)}
                  title="Close Conversation"
                >
                  <CircularCheck />
                </span>
              </>
            )}
          </div>
        </div>
        <div
          className="chat-content"
          ref={chatContentRef}
          style={{ height: `calc(100vh - ${100 + formHeight}px)` }}
        >
          <div className="primary-mention-chat-message">
            {selectedMention?.textOfStatusReplied && (
              <ChatMessage
                mention={selectedMention}
                textOfStatusReplied={true}
                isReply={true}
              />
            )}
            <ChatMessage mention={selectedMention} />
          </div>

          {replies?.map((reply, index) => {
            return (
              <ChatMessage
                key={`chat-message-${index}`}
                isReply={true}
                mention={reply}
                businessUsername={selectedMention?.businessUsername}
                customerUsername={selectedMention?.customerUsername}
                resendMessage={sendReply}
              />
            );
          })}
        </div>
      </div>
      <div id="chat-form" className="chat-form" ref={chatFormRef}>
        <ChatInputNew
          placeholder={`Reply to ${customerName || customerUsername}`}
          onChange={(e) => handleChange(e)}
          sendMessage={sendReply}
          reopenOnly={false}
          reopenConversation={() => {}}
          disabled={
            agent_id !== currentUser.id ||
            selectedSection === "Queue" ||
            !canUserUpdateConversation
          }
          Channel={{
            medium: medium,
            name: `@${selectedMention?.businessUsername}`,
          }}
          value={status}
          selectedConversation={selectedMention}
          isMention={true}
        />
        <div className="help">
          Hit Enter to send | Hold <strong>Shift and press Enter</strong> to go
          to next line
        </div>
      </div>

      {isResolvedModalActive && (
        <Modal closeModal={() => setIsResolvedModalActive(false)} small>
          <div className="modal-content confirmation-modal">
            <div className="modal-title">Close this mention?</div>
            <div className="modal-text">
              Proceed to mark this mention as resolved?
            </div>
            <div className="modal-action">
              <Button
                className="secondary-button small"
                disabled={isLoadingMentions}
                onClick={() => closeMention(id)}
              >
                Close
              </Button>
              <Button
                className="plain-button black"
                onClick={() => setIsResolvedModalActive(false)}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Thread;
