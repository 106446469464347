import mixpanel from "mixpanel-browser";
import {
  getRequest,
  postRequest,
  convoPostRequest,
  patchRequest,
} from "../../api";

import {
  GET_CONVERSATIONS_COUNT_LOADER,
  GET_CONVERSATIONS_COUNT_RESPONSE,
  GET_CONVERSATIONS_LOADER,
  GET_MORE_CONVERSATIONS_LOADER,
  GET_CONVERSATIONS_RESPONSE,
  GET_CONVERSATIONS_RESET,
  SET_MESSAGE_SENDING_STATUS,
  GET_CONVERSATION_TEMPLATES_LOADER,
  GET_CONVERSATION_TEMPLATES_RESPONSE,
  GET_CONVERSATION_TEMPLATES_RESET,
  CREATE_CONVERSATIONS_UNREAD_COUNT,
  GET_CONVERSATIONS_UNREAD_COUNT,
  GET_CONVERSATIONS_UNREAD_MSGS,
  GET_SEARCH_LOADER,
  GET_SEARCH_RESPONSE,
  ASSIGN_CONVERSATION_RESPONSE,
  CREATE_CONVERSATIONS_UNREAD_MSGS,
} from "../constants";
import { getRequestError } from "../../utils/functions";

export const getConversationsCountLoader = (isLoadingConversationsCount) => ({
  type: GET_CONVERSATIONS_COUNT_LOADER,
  isLoadingConversationsCount,
});

export const getConversationsCountResponse = (
  conversationsCountOutcome,
  conversationsCountData,
  conversationsCountMessage
) => ({
  type: GET_CONVERSATIONS_COUNT_RESPONSE,
  conversationsCountOutcome,
  conversationsCountData,
  conversationsCountMessage,
});

export const getConversationsLoader = (isLoadingConversations) => ({
  type: GET_CONVERSATIONS_LOADER,
  isLoadingConversations,
});

export const getMoreConversationsLoader = (isLoadingMoreConversations) => ({
  type: GET_MORE_CONVERSATIONS_LOADER,
  isLoadingMoreConversations,
});

export const getConversationsResponse = (
  conversationsOutcome,
  conversationsData,
  conversationsMessage,
  conversationSection,
  paginate
) => ({
  type: GET_CONVERSATIONS_RESPONSE,
  conversationsOutcome,
  conversationsData,
  conversationsMessage,
  conversationSection,
  paginate,
});

export const getConversationsReset = () => ({
  type: GET_CONVERSATIONS_RESET,
});

export const getConversationTemplatesLoader = (
  isLoadingConversationTemplates
) => ({
  type: GET_CONVERSATION_TEMPLATES_LOADER,
  isLoadingConversationTemplates,
});

export const getConversationTemplatesResponse = (
  conversationTemplatesOutcome,
  conversationTemplatesData,
  conversationTemplatesMessage
) => ({
  type: GET_CONVERSATION_TEMPLATES_RESPONSE,
  conversationTemplatesOutcome,
  conversationTemplatesData,
  conversationTemplatesMessage,
});

export const getConversationTemplatesReset = () => ({
  type: GET_CONVERSATION_TEMPLATES_RESET,
});

export const getConversationsCountAction = (params = {}) => {
  return async (dispatch) => {
    dispatch(getConversationsCountLoader(true));

    try {
      const res = await getRequest({
        url: "/conversations/count",
        params,
        token: true,
      });

      dispatch(getConversationsCountResponse("success", res.data, ""));
      dispatch(getConversationsCountLoader(false));
    } catch (error) {
      const message = getRequestError(error);

      dispatch(getConversationsCountResponse("error", {}, message));
      dispatch(getConversationsCountLoader(false));
    }
  };
};

export const getConversationsAction = (
  params = {},
  conversationSection,
  noReset,
  paginate
) => {
  return async (dispatch) => {
    if (!noReset) {
      dispatch(getConversationsLoader(true));
    }

    if (paginate) {
      dispatch(getMoreConversationsLoader(true));
    }

    try {
      const res = await getRequest({
        url: "/conversations",
        params,
        token: true,
      });

      dispatch(
        getConversationsResponse(
          "success",
          res.data,
          "",
          conversationSection,
          paginate
        )
      );

      dispatch(getConversationsLoader(false));
      dispatch(getMoreConversationsLoader(false));
    } catch (error) {
      const message = getRequestError(error);

      dispatch(
        getConversationsResponse(
          "error",
          [],
          message,
          conversationSection,
          paginate
        )
      );
      dispatch(getConversationsLoader(false));
      dispatch(getMoreConversationsLoader(false));
    }
  };
};

export const createConversationsUnreadCount = (conversationsUnreadCount) => ({
  type: CREATE_CONVERSATIONS_UNREAD_COUNT,
  conversationsUnreadCount,
});

export const createConversationsUnreadMsgs = (conversationsUnreadMsgs) => ({
  type: CREATE_CONVERSATIONS_UNREAD_MSGS,
  conversationsUnreadMsgs,
});

export const getConversationsUnreadCount = () => ({
  type: GET_CONVERSATIONS_UNREAD_COUNT,
});

export const getConversationsUnreadMsgs = () => ({
  type: GET_CONVERSATIONS_UNREAD_MSGS,
});

export const setMessageSendingStatus = (
  messageSendingStatus,
  messageSendingResponse
) => ({
  type: SET_MESSAGE_SENDING_STATUS,
  messageSendingStatus,
  messageSendingResponse,
});

export const getSearchLoader = (isSearching) => ({
  type: GET_SEARCH_LOADER,
  isSearching,
});

export const getSearchResponse = (
  searchOutcome,
  searchData,
  searchMessage
) => ({
  type: GET_SEARCH_RESPONSE,
  searchOutcome,
  searchData,
  searchMessage,
});

export const assignConversationResponse = (
  assignConversationOutcome,
  assignConversationMessage
) => ({
  type: ASSIGN_CONVERSATION_RESPONSE,
  assignConversationOutcome,
  assignConversationMessage,
});

export const getConversationTemplatesAction = (params = {}) => {
  return async (dispatch) => {
    dispatch(getConversationTemplatesLoader(true));

    try {
      const res = await getRequest({
        url: `/channels/${params?.id}/templates`,
        token: true,
      });

      dispatch(getConversationTemplatesResponse("success", res.data.body, ""));
      dispatch(getConversationTemplatesLoader(false));
    } catch (error) {
      const message = getRequestError(error);

      dispatch(getConversationTemplatesResponse("error", [], message));
      dispatch(getConversationTemplatesLoader(false));
    }
  };
};

export const getConversationAuditTrailsAction = (params = {}) => {
  return async () => {
    try {
      const res = await getRequest({
        url: `/conversations/${params?.customer_id}/customer/trail?channel_id=${params?.channel_id}`,
        token: true,
      });

      if (res.data.success === true) return res.data.data;
      else return [];
    } catch (error) {
      console.log(error);
    }
  };
};

export const postValidateChannelNumberOrIdAction = (data) => {
  return async (dispatch) => {
    try {
      const res = await postRequest({
        url: `/channels/${data?.channel}/validate`,
        data: data?.body,
        token: true,
      });
      return res.data;
    } catch (error) {
      const message = getRequestError(error);
      console.log(message);
    }
  };
};

export const getConversationSearchAction = (data) => {
  return async (dispatch) => {
    dispatch(getSearchLoader(true));

    try {
      const res = await getRequest({
        url: data?.url,
        params: data?.params,
        token: true,
      });

      dispatch(getSearchResponse("success", res?.data, ""));
      dispatch(getSearchLoader(false));
      return res?.data;
    } catch (error) {
      const message = getRequestError(error);

      dispatch(getSearchResponse("error", [], message));
      dispatch(getSearchLoader(false));
    }
  };
};

export const postSendMessage = (data = {}) => {
  return async (dispatch) => {
    dispatch(setMessageSendingStatus("sending"));

    try {
      const res = await convoPostRequest({
        url: data?.url,
        token: true,
        data: data?.data,
      });
      if (res?.status === 200) {
        dispatch(
          setMessageSendingStatus(res?.data?.success === true && "sent", null)
        );
        mixpanel.track("Responded to a Message", {
          channel: data?.channel,
        });
      }
      return res;
    } catch (error) {
      const message = getRequestError(error);
      dispatch(setMessageSendingStatus("failed", message));
    }
  };
};

export const postChangeConversationChannel = (data = {}) => {
  return async (dispatch) => {
    dispatch(getConversationsLoader(true));

    try {
      const res = await patchRequest({
        url: `/conversations/${data?.id}`,
        token: true,
        data: data?.payload,
      });
      if (res?.status === 200) {
        dispatch(getConversationsLoader(false));
      }
    } catch (error) {
      const message = getRequestError(error);
      console.log(message, "error message");
      dispatch(getConversationsLoader(false));
    }
  };
};

export const patchMarkConversationAsRead = (data = {}) => {
  return async (dispatch) => {
    dispatch(getConversationsLoader(true));

    try {
      const res = await patchRequest({
        url: `/conversations/${data?.id}`,
        token: true,
        data: { read: true },
      });
      if (res?.status === 200) {
        dispatch(getConversationsLoader(false));
      }
    } catch (error) {
      const message = getRequestError(error);
      console.log(message, "error message");
      dispatch(getConversationsLoader(false));
    }
  };
};

export const resetAssignConversationResponse = () => {
  return async (dispatch) => {
    dispatch(assignConversationResponse("", ""));
  };
};

export const patchAssignConversationsToAgent = (data = {}) => {
  return async (dispatch) => {
    dispatch(getConversationsLoader(true));

    try {
      const res = await patchRequest({
        url: `/conversations/bulk/assign`,
        data,
        token: true,
      });
      if (res?.status === 200) {
        dispatch(getConversationsLoader(false));
        dispatch(assignConversationResponse("success", ""));
      }
      return res;
    } catch (error) {
      const message = getRequestError(error);

      dispatch(assignConversationResponse("error", message));
      dispatch(getConversationsLoader(false));
    }
  };
};

export const getOrderInfoAction = (data = {}) => {
  return async () => {
    try {
      const res = await getRequest({
        url: data?.url,
        token: true,
        params: data?.params,
      });

      return res?.data?.data;
    } catch (error) {
      const message = getRequestError(error);

      return message || "";
    }
  };
};
