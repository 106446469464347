import {
  CREATE_BROADCAST_LOADER,
  CREATE_BROADCAST_RESPONSE,
  CREATE_BROADCAST_RESET,
  GET_BROADCAST_LOADER,
  GET_BROADCAST_RESPONSE,
  GET_BROADCAST_RESET,
  DELETE_BROADCAST_LOADER,
  DELETE_BROADCAST_RESPONSE,
  DELETE_BROADCAST_RESET,
} from "../constants";

const INIT_STATE = {
  broadcastData: [],
  broadcastMeta: {
    limit: 10,
    offset: 0,
    total: 0,
  },
  isCreatingBroadcast: false,
  isUpdatingBroadcast: false,
  isDeletingBroadcast: false,
  isLoadingBroadcast: true,
  createBroadcastOutcome: "",
  createBroadcastMessage: "",
  updateBroadcastOutcome: "",
  updateBroadcastMessage: "",
  deleteBroadcastOutcome: "",
  deleteBroadcastMessage: "",
  broadcastOutcome: "",
  broadcastMessage: "",
};

const Broadcast = (state = INIT_STATE, action) => {
  const {
    type,
    createBroadcastOutcome,
    createBroadcastMessage,
    deleteBroadcastOutcome,
    deleteBroadcastMessage,
    broadcastOutcome,
    broadcastMessage,
    broadcastData,
    broadcastMeta,
    isCreatingBroadcast,
    isDeletingBroadcast,
    isLoadingBroadcast,
  } = action;

  switch (type) {
    case CREATE_BROADCAST_LOADER: {
      return {
        ...state,
        isCreatingBroadcast,
      };
    }

    case CREATE_BROADCAST_RESPONSE: {
      return {
        ...state,
        createBroadcastOutcome,
        createBroadcastMessage,
      };
    }

    case CREATE_BROADCAST_RESET: {
      return {
        ...state,
        createBroadcastOutcome: "",
        createBroadcastMessage: "",
      };
    }

    case DELETE_BROADCAST_LOADER: {
      return {
        ...state,
        isDeletingBroadcast,
      };
    }

    case DELETE_BROADCAST_RESPONSE: {
      return {
        ...state,
        deleteBroadcastOutcome,
        deleteBroadcastMessage,
      };
    }

    case DELETE_BROADCAST_RESET: {
      return {
        ...state,
        deleteBroadcastOutcome: "",
        deleteBroadcastMessage: "",
      };
    }

    case GET_BROADCAST_LOADER: {
      return {
        ...state,
        isLoadingBroadcast,
      };
    }

    case GET_BROADCAST_RESPONSE: {
      return {
        ...state,
        broadcastOutcome,
        broadcastData,
        broadcastMeta,
        broadcastMessage,
      };
    }

    case GET_BROADCAST_RESET: {
      return INIT_STATE;
    }

    default:
      return state;
  }
};

export default Broadcast;
