import React from "react";

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      stroke="#757F8A"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12.5 10.5V5a4.5 4.5 0 10-9 0v7.5a3 3 0 006 0V5a1.5 1.5 0 00-3 0v6.5"
    ></path>
  </svg>
);
