import { useEffect } from "react";

const useCloseElementHook = (ref, action) => {
  useEffect(() => {
    function handleCloseElement(event) {
      if (ref?.current && !ref?.current?.contains(event.target)) {
        action();
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleCloseElement);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleCloseElement);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  return ref;
};

export default useCloseElementHook;
