import React from "react";

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      fill="#474F58"
      d="M.753 1.442v13.117a1.125 1.125 0 001.657.992l12.242-6.56a1.125 1.125 0 000-1.982L2.41.45a1.125 1.125 0 00-1.657.992z"
    ></path>
  </svg>
);
