import {
  WORKING_HOURS_LOADER,
  WORKING_HOURS_SUBMITTING,
  WORKING_HOURS_RESPONSE,
  WORKING_HOUR_RESPONSE,
} from "../constants";

const INIT_STATE = {
  isLoadingWorkingHours: false,
  isSubmittingWorkingHours: false,
  workingHoursOutcome: "",
  workingHoursData: null,
  workingHoursMessage: "",
  workingHourOutcome: "",
  workingHourData: null,
  workingHourMessage: "",
};

const WorkingHours = (state = INIT_STATE, action) => {
  const {
    type,
    isLoadingWorkingHours,
    isSubmittingWorkingHours,
    workingHoursOutcome,
    workingHoursData,
    workingHoursMessage,
    workingHourOutcome,
    workingHourData,
    workingHourMessage,
  } = action;

  switch (type) {
    case WORKING_HOURS_LOADER: {
      return {
        ...state,
        isLoadingWorkingHours,
      };
    }

    case WORKING_HOURS_SUBMITTING: {
      return {
        ...state,
        isSubmittingWorkingHours,
      };
    }

    case WORKING_HOURS_RESPONSE: {
      return {
        ...state,
        workingHoursOutcome,
        workingHoursData,
        workingHoursMessage,
      };
    }

    case WORKING_HOUR_RESPONSE: {
      return {
        ...state,
        workingHourOutcome,
        workingHourData,
        workingHourMessage,
      };
    }

    default:
      return state;
  }
};

export default WorkingHours;
