import React from "react";

import { Button } from "../../../components/FormElements";
import Modal from "../../../components/Modal";

const TemplateMessagePreview = ({ close, templateMessage }) => {
  return (
    <Modal closeModal={close}>
      <div className="modal-content template-message-preview-modal">
        <div className="modal-name">Preview template message</div>
        <div className="modal-form saved-response-form">
          <div className="form-row mb-25">
            <div className="item">
              <p>Name</p>
              <h6>{templateMessage?.name}</h6>
            </div>
            <div className="item">
              <p>Status</p>
              <h6>{templateMessage?.status}</h6>
            </div>
          </div>
          <div className="form-row mb-25">
            <div className="item">
              <p>Language</p>
              <h6>{templateMessage?.language}</h6>
            </div>
            <div className="item">
              <p>Category</p>
              <h6>{templateMessage?.category}</h6>
            </div>
          </div>
          <div className="item">
            <p>Content</p>
            <h6>{templateMessage?.components?.[0]?.text}</h6>
          </div>
          <div className="modal-form-action">
            <Button type="button" className="secondary-button" onClick={close}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TemplateMessagePreview;
