import React from "react";

import {
  accountSettingsPermissions,
  agentsPermissions,
  autoRespondersPermissions,
  billingPermissions,
  channelsPermissions,
  csatPermissions,
  groupsPermissions,
  integrationsPermissions,
  officeHoursPermissions,
  savedResponsesPermissions,
  tagsPermissions,
  templatesPermissions,
} from "../../utils/permissions";

import {
  Avatar,
  // ProfileSettings,
  CustomerCare,
  Teams,
  SavedResponses,
  TemplateMessages,
  Tag,
  People,
  Billing,
  Integrations,
  AutoResponses,
  Clock,
  OmniChannel,
  Channels,
  Pencil,
} from "../../assets/vectors";

export const settingsMenu = [
  {
    label: "Account Settings",
    icon: <Avatar />,
    value: "/settings/account-settings",
    show: accountSettingsPermissions.read ? true : false,
  },
  {
    label: "Users",
    icon: <CustomerCare />,
    value: "/settings/users",
    show: agentsPermissions.read ? true : false,
  },
  {
    label: "Teams",
    icon: <Teams />,
    value: "/settings/teams",
    show: agentsPermissions.read ? true : false,
  },
  {
    label: "CSAT",
    icon: <AutoResponses />,
    value: "/settings/csat",
    show: csatPermissions.read ? true : false,
  },
  {
    label: "Saved Responses",
    icon: <SavedResponses />,
    value: "/settings/saved-responses",
    show: savedResponsesPermissions.read ? true : false,
  },
  {
    label: "Template Messages",
    icon: <TemplateMessages />,
    value: "/settings/template-messages",
    show: templatesPermissions.read ? true : false,
  },
  {
    label: "Tags",
    icon: <Tag />,
    value: "/settings/tags",
    show: tagsPermissions.read ? true : false,
  },
  {
    label: "Groups",
    icon: <People />,
    value: "/settings/groups",
    show: groupsPermissions.read ? true : false,
  },
  {
    label: "Billing",
    icon: <Billing />,
    value: "/settings/billing",
    show: billingPermissions.read ? true : false,
  },
  {
    label: "Integrations",
    icon: <Integrations />,
    value: "/settings/integrations",
    show: integrationsPermissions.read ? true : false,
  },
  {
    label: "Auto Responses",
    icon: <AutoResponses />,
    value: "/settings/auto-responses",
    show: autoRespondersPermissions.read ? true : false,
  },
  {
    label: "Omnichannel Widgets",
    icon: <OmniChannel />,
    value: "/settings/omnichannel-widgets",
    show: accountSettingsPermissions.read ? true : false,
  },
  {
    label: "Custom Fields",
    icon: <TemplateMessages />,
    value: "/settings/custom-fields",
    show: officeHoursPermissions.read ? true : false,
  },
  {
    label: "Auto Assignment",
    icon: <Integrations />,
    value: "/settings/auto-assignment",
    show: officeHoursPermissions.read ? true : false,
  },
  {
    label: "Working Hours",
    icon: <Clock />,
    value: "/settings/working-hours",
    show: officeHoursPermissions.read ? true : false,
  },
  {
    label: "Channels",
    icon: <Channels />,
    value: "/settings/channels",
    show: channelsPermissions.read ? true : false,
  },
  {
    label: "Email Signatures",
    icon: <Pencil />,
    value: "/settings/signatures",
    show: true,
  },
];
