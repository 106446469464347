import React, { Component, createRef } from "react";
import classNames from "classnames";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as shallowequal from "shallowequal";
import * as moment from "moment";
import * as _ from "lodash";
import mixpanel from "mixpanel-browser";
import { default as ReactSelect } from "react-select";
import striptags from "striptags";

import { postRequest, patchRequest, deleteRequest } from "../../../api";

import {
  getConversationTemplatesAction,
  getConversationAuditTrailsAction,
  postSendMessage,
  setMessageSendingStatus,
} from "../../../redux/actions/Conversations";
import { getFirmChannelsAction } from "../../../redux/actions/Channels";

import {
  Select,
  ChatInputNew,
  TextField,
  Button,
  FileInput,
} from "../../../components/FormElements";
import Modal from "../../../components/Modal";
import Toaster from "../../../components/Toaster";
import { ChatMessage, ChatDateFilter } from ".";

import GlobalSearch from "../../../components/GlobalSearch";
import FormNotifications from "../../../components/Notifications";

import ConversationHistory from "./ConversationHistory";
import TagForm from "../../Settings/Tags/TagForm";
import AuditTrail from "./AuditTrail";
import EmailChatForm from "./EmailChatForm";
import OrderDetails from "./OrderDetails";

import {
  Search,
  SquareAvatar,
  Tag,
  CircularCheck,
  Collapse,
  Return,
  Return2,
  Avatar,
  AngleDown,
  Close,
  CircularPlus,
  LayersWithNoSlash,
  ChevronDownW,
} from "../../../assets/vectors";
import {
  getInitials,
  getUserDetails,
  ObjIsEmpty,
  getRequestError,
} from "../../../utils/functions";
import {
  APP_SENT_FIRST_CONVO_REPLY,
  APP_UNSENT_MSGS,
} from "../../../utils/constants";
import {
  conversationsPermissions,
  templatesPermissions,
} from "../../../utils/permissions";

const hasFirmSentFirstConvoReply =
  JSON.parse(window.localStorage.getItem(APP_SENT_FIRST_CONVO_REPLY)) || null;
class Chats extends Component {
  constructor(props) {
    super(props);

    const { selectedConversation } = props;
    const { Messages } = selectedConversation || {};

    const user = getUserDetails();
    const { firstName, phone } = user;

    this.state = {
      messages: Messages,
      formHeight: 172,
      formTop: 0,
      media: {},
      rawData: {},
      message: {
        imageUrl: "",
        videoUrl: "",
        documentUrl: "",
        sender: {
          authUser: true,
          name: firstName,
          phone,
          lastActivity: new Date().toISOString(),
        },
        to: [],
        email: "",
        subject: "",
        content: "",
        contentType: "TEXT",
      },
      selectedOrderMsgId: null,
      isOrderModalOpen: false,
      isConfirmationModalOpen: false,
      isTemplateModalOpen: false,
      selectedTemplate: "",
      selectedTemplateValue: null,
      selectedTemplateHeader: {},
      templateHeaderMedia: null,
      selectedTemplateItems: [],
      isSubmittingTemplateMessage: false,
      isUploadingTemplateMedia: false,
      sendingStatus: [],
      isSearchActive: false,
      showTagsContainer: false,
      isTagFormActive: false,
      isAuditTrailModalOpen: false,
      auditTrailData: [],
      isEmailFormActive: false,
      emailCcs: [],
      newEmailCc: "",
      emailBccs: [],
      newEmailBcc: "",
      highlightedMessageId: null,
      isLoadingPreviousMessages: false,
      isLoadingRecentMessages: false,
    };

    this.chatContentRef = createRef();
    this.chatFormRef = createRef();

    this.groupContainerRef = createRef();
  }

  loadMoreChatsUp = () => {
    const {
      selectedConversation,
      getConversationMessagesUp,
      isLoadingConversationDetails,
    } = this.props;

    const { isLoadingPreviousMessages } = this.state;
    const { Customer, Messages, isLastPageOfPrevMessages } =
      selectedConversation;
    const scrollTop = this.chatContentRef.current?.scrollTop;

    if (
      scrollTop <= 0 &&
      !isLastPageOfPrevMessages &&
      Messages?.length &&
      !isLoadingConversationDetails &&
      !isLoadingPreviousMessages
    ) {
      this.setState({ isLoadingPreviousMessages: true });
      getConversationMessagesUp(Customer?.id).then(() => {
        this.setState({ isLoadingPreviousMessages: false });
        this.scrollToMessageTop(Messages?.[Messages?.length - 1]);
      });
    }
  };

  loadMoreChatsDown = () => {
    const {
      selectedConversation,
      getConversationMessagesDown,
      isLoadingConversationDetails,
    } = this.props;

    const { isLoadingRecentMessages } = this.state;
    const { Customer, Messages, isLastPageOfReceMessages } =
      selectedConversation;
    const scrollTop = this.chatContentRef?.current?.scrollTop + 5 || 0;
    const scrollHeight = this.chatContentRef?.current?.scrollHeight || 0;
    const clientHeight = this.chatContentRef?.current?.clientHeight || 0;

    const scrollPosition = scrollHeight - clientHeight;

    if (
      scrollPosition <= scrollTop &&
      !isLastPageOfReceMessages &&
      Messages?.length &&
      !isLoadingConversationDetails &&
      !isLoadingRecentMessages
    ) {
      this.setState({ isLoadingRecentMessages: true });
      getConversationMessagesDown(Customer?.id).then(() => {
        this.setState({ isLoadingRecentMessages: false });
        this.scrollToMessageEnd(Messages[0]);
      });
    }
  };

  toggleIsSearchActive = (value) => {
    this.setState(
      {
        isSearchActive: value,
      },
      () => this.scrollToBottom()
    );
  };

  handleChange = ({ target }, callBack) => {
    const { message } = this.state;

    const messageTextArea = document.querySelector("#messageTextArea");
    if (messageTextArea?.style) {
      messageTextArea.style.height = "inherit";
      messageTextArea.style.height = `${Math.min(
        messageTextArea.scrollHeight,
        310
      )}px`;
    }

    this.setState(
      {
        message: {
          ...message,
          sender: {
            ...message.sender,
            lastActivity: new Date().toISOString(),
          },
          content: target.value,
        },
      },
      () => {
        this.setState({
          formHeight: this.getFormHeight(),
        });
        this.checkForExcessWhiteSpacing();

        callBack && callBack();
      }
    );
  };

  checkForExcessWhiteSpacing = () => {
    const { message } = this.state;

    const regex = /\s{5,}/g;
    const result = regex.test(message?.content);

    this.setState({
      excessSpacingError: result,
    });
  };

  handleFileLoad = (
    uploadData,
    { file, rawData, mime, extension, fileName }
  ) => {
    const { message } = this.state;

    this.setState({
      media: { ...uploadData, file, extension },
      rawData: { rawData, mime, fileName },
      message: {
        ...message,
        contentType: "",
        sender: {
          ...message.sender,
          lastActivity: new Date().toISOString(),
        },
      },
    });
  };

  handleFileError = (message) => {
    this.openToaster("error", message);
  };

  removeFile = () => {
    this.setState(
      {
        media: {},
        rawData: {},
      },
      () => this.setFormSpecs()
    );
  };

  openToaster = (status, content) => {
    this.setState({
      toaster: {
        open: "open",
        status,
        content,
      },
    });
  };

  closeToaster = () => {
    this.setState({
      toaster: {
        ...this.state.toaster,
        open: "closed",
      },
    });
  };

  handleUploadTemplateHeaderMedia = async (uploadData, { file }) => {
    if (file) {
      var fileData = new FormData();
      fileData.append("media", file);
      let payload = {};
      payload = fileData;

      this.setState({
        isUploadingTemplateMedia: true,
      });

      try {
        const res = await postRequest({
          url: "/utils/upload",
          data: payload,
          token: true,
        });
        if (res?.status === 200) {
          this.setState({
            templateHeaderMedia: res?.data?.data,
            isUploadingTemplateMedia: false,
          });
        }
      } catch (error) {
        const message = getRequestError(error);
        console.log(message);

        this.setState({
          isUploadingTemplateMedia: false,
        });
      }
    }
  };

  getCleanMessage = (msg) => {
    const pattern = /<body[^>]*>([^<]*(?:(?!<\/?body)<[^<]*)*)<\/body\s*>/i;
    const array_matches = msg?.match(pattern);
    const bodyContent = array_matches ? array_matches[1] : msg;
    const stripTags = striptags(bodyContent);
    const removeExtraSpaces = stripTags.replace(/\s{2,}/g, "");
    const removeAllEntities = removeExtraSpaces.replace(/(&.+;)/gi, "");
    return removeAllEntities.replaceAll("'", "");
  };

  saveFirmHasSentFirstReply = (channel) => {
    const { firm_id } = getUserDetails();

    const data = {
      firm_id,
      channel: channel ? channel : "WHATSAPP",
      value: true,
    };

    localStorage.setItem(APP_SENT_FIRST_CONVO_REPLY, JSON.stringify(data));
  };

  sendMessage = async (retry, retryMessage, failedMsgToBeResent) => {
    if (!this.state.excessSpacingError) {
      const currentUser = getUserDetails();
      const { messages, message, media, rawData, emailCcs, emailBccs } =
        this.state;
      const {
        getConversation,
        getInProgressConversations,
        selectedSection,
        selectedConversation,
        getConversationsCountAction,
      } = this.props;
      const { id, customer, Customer, Channel } = selectedConversation;
      const customerPhone = customer?.phone || null;

      const messageToBeSent = failedMsgToBeResent
        ? failedMsgToBeResent
        : {
            ...message,
            ...media,
            ...rawData,
          };

      var formData = new FormData();
      formData.append("message", messageToBeSent?.content);
      formData.append("media", messageToBeSent?.file);
      formData.append("contentType", messageToBeSent?.contentType);

      if (retry === true) {
        var retryMessageIndex = messages.findIndex(
          (x) => x.content === retryMessage.content
        );
        messages[retryMessageIndex] = retryMessage;

        this.setState({
          messages,
          message: retryMessage,
          media: retryMessage?.file
            ? {
                file: retryMessage?.file,
                contentType: retryMessage?.contentType,
              }
            : {},
        });
      } else {
        if (
          messageToBeSent?.contentType === "TEXT" &&
          messageToBeSent?.content?.trim()?.length < 1
        ) {
          return;
        } else {
          this.setState(
            {
              messages: [
                {
                  ...messageToBeSent,
                  id: new Date()?.toString(),
                  dlr: [
                    {
                      status: "sending",
                      timestamp: new Date(),
                    },
                  ],
                },
                ...messages,
              ],
              message: {
                ...message,
                content: "",
              },
            },
            () => {
              this.resetFormData();
              this.scrollToBottom();
            }
          );
        }
      }

      let requestUrl = `/conversations/${selectedConversation?.id}`;
      let payload = {};
      if (Channel.medium === "WHATSAPP") {
        delete media?.file;
        payload = {
          message: messageToBeSent,
          phone: customerPhone,
        };
      }
      if (Channel.medium === "FACEBOOK") {
        if (ObjIsEmpty(media)) {
          payload = {
            message: messageToBeSent?.content,
            tag: this.check24Hours() ? true : false,
          };
        } else {
          payload = formData;
        }
      }
      if (Channel.medium === "TWITTER") {
        if (ObjIsEmpty(media)) {
          payload = {
            message: messageToBeSent?.content,
          };
        } else {
          payload = formData;
        }
      }
      if (Channel.medium === "INSTAGRAM") {
        if (ObjIsEmpty(media)) {
          payload = {
            message: messageToBeSent?.content,
          };
        } else {
          payload = formData;
        }
      }
      if (Channel.medium === "EMAIL") {
        const subject = messageToBeSent?.subject;

        if (ObjIsEmpty(media)) {
          payload = {
            message: messageToBeSent?.content,
            cc: emailCcs,
            bcc: emailBccs,
            subject: subject,
          };
        } else {
          formData.append("subject", subject);
          formData.append("cc", JSON.stringify(emailCcs));
          formData.append("bcc", JSON.stringify(emailBccs));
          payload = formData;
        }

        this.setState({
          isEmailFormActive: false,
        });
      }
      if (Channel.medium === "WIDGET") {
        if (ObjIsEmpty(media)) {
          payload = {
            message: messageToBeSent?.content,
          };
        } else {
          payload = formData;
        }
      }
      if (Channel.medium === "SMS-TW") {
        requestUrl =
          messages?.length > 0
            ? requestUrl
            : `/conversations/new-conversation/sms-tw/${selectedConversation?.Channel?.id}`;
        payload = {
          to: customerPhone,
          message: {
            content: messageToBeSent?.content,
            contentType: messageToBeSent?.contentType,
          },
        };
      }

      const data = {
        url: requestUrl,
        data: payload,
        formData: messageToBeSent?.file && formData,
        channel: Channel.medium,
      };

      if (data?.data?.message || data?.data instanceof FormData) {
        const previewMessage =
          Channel.medium !== "EMAIL"
            ? messageToBeSent
            : { content: this.getCleanMessage(messageToBeSent?.content) };

        this.props.postSendMessage(data).then((res) => {
          const indexOfMsgJustSent = this.state.messages?.findIndex(
            (x) => x?.content === messageToBeSent?.content
          );

          if (res?.status === 200 || res?.status === 201) {
            const newMessageId = res?.data?.data?.message?.id;

            if (indexOfMsgJustSent >= 0) {
              const allMessages = this.state.messages;
              allMessages[indexOfMsgJustSent].id =
                newMessageId || new Date().getTime();
              allMessages[indexOfMsgJustSent].dlr = [
                ...allMessages[indexOfMsgJustSent].dlr,
                {
                  status: "sent",
                  timestamp: new Date(),
                },
              ];
              this.setState({
                messages: allMessages,
              });
            }

            this.updateConvoListPreview(id, {
              ...previewMessage,
              id: newMessageId,
              dlr: [
                {
                  status: "sent",
                  timestamp: new Date(),
                },
              ],
            });

            if (failedMsgToBeResent) {
              this.deleteSavedUnsentMsg(id, messageToBeSent);
            }

            if (selectedSection !== "In-progress") {
              getConversation(Customer?.id, Channel?.id, true);
              getInProgressConversations(true);
            }
            if (selectedConversation?.agent_id !== currentUser?.id) {
              this.convoAgentIdChanged();
            }
            getConversationsCountAction();

            !hasFirmSentFirstConvoReply?.firm_id !== currentUser?.firm_id &&
              this.saveFirmHasSentFirstReply(Channel.medium);
          } else {
            var allMessages = this.state.messages;
            const failedDlr = { status: "failed", timestamp: new Date() };

            if (indexOfMsgJustSent >= 0) {
              allMessages[indexOfMsgJustSent].dlr = [failedDlr];

              this.setState({
                messages: allMessages,
              });
            }

            if (!res) {
              if (failedMsgToBeResent) {
                const unsentMsg = allMessages[indexOfMsgJustSent];

                allMessages = allMessages?.filter((item) => item !== unsentMsg);

                this.setState({
                  messages: allMessages,
                });
              }

              this.updateSavedUnsentMsgs(selectedConversation?.id, {
                ...messageToBeSent,
                dlr: [failedDlr],
                createdAt: new Date(),
              });
            }
          }
        });
      }
    }
  };

  sendTemplateMessage = async (retry, retryIndex) => {
    const currentUser = getUserDetails();
    const {
      messages,
      message,
      selectedTemplateItems,
      templateData,
      selectedTemplateHeader,
      templateHeaderMedia,
    } = this.state;
    const {
      selectedConversation,
      getConversationsCountAction,
      getInProgressConversations,
    } = this.props;
    const { id, customer, Channel } = selectedConversation;
    const { phone } = customer || {};

    const sendingStatus = JSON.parse(JSON.stringify(this.state.sendingStatus));
    const currentIndex = this.state.messages.length;

    if (retry) {
      const oldSendingStatus = sendingStatus.find(
        ({ index }) => index === retryIndex
      );
      oldSendingStatus.status = "sending";
    } else {
      sendingStatus.push({ index: currentIndex, status: "sending" });
    }

    this.setState({
      sendingStatus,
    });

    try {
      const { content } = message;
      const { name, namespace, language, category } = templateData;

      var components = [
        {
          type: "body",
          parameters: selectedTemplateItems?.map((x) => ({
            type: "text",
            text: x,
          })),
        },
      ];

      var formattedMessage = { contentType: "TEXT", content };

      if (selectedTemplateHeader?.format) {
        components = [
          ...components,
          {
            type: "header",
            parameters: [
              {
                type: selectedTemplateHeader?.format?.toLowerCase(),
                [selectedTemplateHeader?.format?.toLowerCase()]: {
                  link: templateHeaderMedia,
                },
              },
            ],
          },
        ];

        formattedMessage = {
          contentType: selectedTemplateHeader?.format,
          media: templateHeaderMedia,
          content,
        };
      }

      const res = await postRequest({
        url: "conversations/channel-messages",
        token: true,
        data: {
          from: Channel?.name,
          to: phone,
          formatted: JSON.stringify(formattedMessage),
          template: {
            name,
            namespace,
            category,
            language,
            components,
            structure: templateData?.structure || null,
          },
        },
      });

      this.props.setMessageSendingStatus("sent", "");

      if (res?.data?.data?.isCreated === true) {
        mixpanel.track("Created a New Message", {
          channel: Channel?.name,
        });

        !hasFirmSentFirstConvoReply?.firm_id !== currentUser?.firm_id &&
          this.saveFirmHasSentFirstReply();
      }

      const newConvoId = id || res?.data?.data?.conversation?.id;
      const newConvoCustomerId =
        id || res?.data?.data?.conversation?.customer_id;
      const newConvoChannelId = id || res?.data?.data?.conversation?.channel_id;

      const newMessage = res?.data?.data?.message;

      if (messages?.length < 1) {
        getInProgressConversations(true);
        this.props.history.push(
          `/conversations/${newConvoCustomerId}/${newConvoChannelId}`
        );
      }

      this.setState({
        messages: [newMessage, ...messages],
        sendingStatus,
        isSubmittingTemplateMessage: false,
      });

      this.updateConvoListPreview(newConvoId, {
        ...message,
        id: newMessage?.id,
        dlr: [
          {
            status: "sent",
            timestamp: new Date(),
          },
        ],
      });
      getConversationsCountAction();
      this.resetFormData();
      this.scrollToBottom();
      this.closeTemplateModal();

      if (messages?.length < 1) {
        getInProgressConversations(true);
        this.props.history.push(
          `/conversations/${newConvoCustomerId}/${newConvoChannelId}`
        );
      }
    } catch (error) {
      const errorResponse = error?.response?.data?.message;

      this.props.setMessageSendingStatus("failed", errorResponse);

      this.setState({
        sendingStatus,
        isSubmittingTemplateMessage: false,
      });
    }
  };

  getSavedUnsentMsgs = (convoId) => {
    const allUnsentMsgs =
      JSON.parse(window.localStorage.getItem(APP_UNSENT_MSGS)) || [];

    return allUnsentMsgs?.find((x) => x.id === convoId)?.messages || [];
  };

  updateSavedUnsentMsgs = (convoId, messageToBeSent) => {
    var allUnsentMsgs =
      JSON.parse(window.localStorage.getItem(APP_UNSENT_MSGS)) || [];

    const indexOfUnsentMsgsForThisConvo = allUnsentMsgs?.findIndex(
      (x) => x?.id === convoId
    );

    if (indexOfUnsentMsgsForThisConvo >= 0) {
      allUnsentMsgs[indexOfUnsentMsgsForThisConvo].messages = [
        ...allUnsentMsgs[indexOfUnsentMsgsForThisConvo].messages,
        messageToBeSent,
      ];
    } else {
      allUnsentMsgs = [
        ...allUnsentMsgs,
        { id: convoId, messages: [messageToBeSent] },
      ];
    }

    window.localStorage.setItem(APP_UNSENT_MSGS, JSON.stringify(allUnsentMsgs));
  };

  deleteSavedUnsentMsg = (convoId, msg) => {
    var allUnsentMsgs =
      JSON.parse(window.localStorage.getItem(APP_UNSENT_MSGS)) || [];

    var unsentConvoMsg = allUnsentMsgs?.find((x) => x.id === convoId) || null;

    if (unsentConvoMsg) {
      const restOfUnsentConvoMsgs =
        unsentConvoMsg?.messages?.filter((x) => x.content !== msg.content) ||
        [];

      const indexOfUnsentMsgsForThisConvo = allUnsentMsgs?.findIndex(
        (x) => x?.id === convoId
      );

      if (indexOfUnsentMsgsForThisConvo >= 0) {
        if (restOfUnsentConvoMsgs?.length > 0) {
          allUnsentMsgs[indexOfUnsentMsgsForThisConvo].messages =
            restOfUnsentConvoMsgs;
        } else {
          allUnsentMsgs = allUnsentMsgs.filter(
            (item) => item !== unsentConvoMsg
          );
        }
      }

      this.setState({
        messages: this.state.messages,
      });

      window.localStorage.setItem(
        APP_UNSENT_MSGS,
        JSON.stringify(allUnsentMsgs)
      );
    }
  };

  convoAgentIdChanged = () => {
    this.setState({
      showReassignmentMsg: true,
    });
    setTimeout(() => {
      this.setState({
        showReassignmentMsg: false,
      });
    }, 5000);
    clearTimeout();
  };

  updateConvoListPreview = (id, message) => {
    const { updatePreview } = this.props;

    updatePreview({
      conversationId: id,
      preview: message,
    });
  };

  resetFormData = () => {
    const user = getUserDetails();

    this.setState(
      {
        media: {},
        rawData: {},
        message: {
          imageUrl: "",
          videoUrl: "",
          documentUrl: "",
          sender: {
            authUser: true,
            name: user.firstName,
            phone: user.phone,
            lastActivity: new Date().toISOString(),
          },
          content: "",
          contentType: "TEXT",
        },
        inputValue: null,
      },
      () => this.setFormSpecs()
    );
  };

  selectOrder = (msgId) => {
    this.setState(
      {
        selectedOrderMsgId: msgId,
      },
      () => {
        this.toggleOrderModal();
      }
    );
  };

  toggleOrderModal = () => {
    const { isOrderModalOpen } = this.state;

    this.setState({
      isOrderModalOpen: !isOrderModalOpen,
    });
  };

  getFormHeight = () => {
    return this.chatFormRef
      ? this.chatFormRef.current
        ? this.chatFormRef.current.clientHeight
        : 172
      : 172;
  };

  getFormTop = () => {
    return this.chatFormRef
      ? this.chatFormRef.current
        ? this.chatFormRef.current.getBoundingClientRect().top
        : 0
      : 0;
  };

  scrollToBottom = () => {
    const chatContainer = this.chatContentRef.current;
    if (chatContainer) {
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
  };

  scrollToMessageTop = (message) => {
    if (message) {
      document.getElementById(message.id)?.scrollIntoView({
        block: "nearest",
        inline: "start",
      });
    }
  };

  scrollToMessageEnd = (message) => {
    if (message) {
      document.getElementById(message.id)?.scrollIntoView({
        block: "nearest",
        inline: "end",
      });
    }
  };

  setFormSpecs = () => {
    this.setState({
      formHeight: this.getFormHeight(),
      formTop: this.getFormTop(),
    });
  };

  openTemplateModal = () => {
    this.props.setMessageSendingStatus("", "");

    this.setState({
      selectedTemplate: "",
      selectedTemplateItems: [],
      isTemplateModalOpen: true,
    });
  };

  closeTemplateModal = () => {
    this.setState({
      isTemplateModalOpen: false,
    });
  };

  openConfirmationModal = () => {
    this.setState({
      isConfirmationModalOpen: true,
    });
  };

  closeConfirmationModal = () => {
    this.setState({
      isConfirmationModalOpen: false,
    });
  };

  getAuditTrails = () => {
    const { getConversationAuditTrailsAction, selectedConversation } =
      this.props;
    const { Customer, channel_id } = selectedConversation;

    getConversationAuditTrailsAction({
      customer_id: Customer?.id,
      channel_id,
    }).then((res) =>
      this.setState({
        auditTrailData: res,
      })
    );
  };

  toggleAuditTrailModal = () => {
    this.setState({
      isAuditTrailModalOpen: !this.state.isAuditTrailModalOpen,
    });
  };

  openFilePreview = (type, file) => {
    this.setState({
      isFilePreviewOpen: true,
      filePreviewType: type,
      filePreviewContent: file,
    });
  };

  closeFilePreview = () => {
    this.setState({
      isFilePreviewOpen: false,
      filePreviewType: "",
      filePreviewContent: "",
    });
  };

  openAssigneeOptions = () => {
    this.setState(
      {
        filteredAgents: this.props.allUsers,
        isAssigneeOptionsActive: true,
      },
      () => document.addEventListener("click", this.closeAssigneeOptions)
    );
  };

  closeAssigneeOptions = (e) => {
    if (
      !e ||
      (this.assigneeOptionsRef && !this.assigneeOptionsRef.contains(e.target))
    ) {
      this.setState(
        {
          isAssigneeOptionsActive: false,
        },
        () => document.removeEventListener("click", this.closeAssigneeOptions)
      );
    }
  };

  handleConversationStatusChange = async ({ target }) => {
    const { value } = target;
    const {
      selectedConversation,
      getConversation,
      getOpenConversations,
      getInProgressConversations,
      getAwaitingResponseConversations,
      getConversationsCountAction,
    } = this.props;
    const { id, Customer, Channel } = selectedConversation;

    if (id) {
      try {
        await patchRequest({
          url: `/conversations/${id}`,
          data: {
            status: value,
          },
          token: true,
        });
        getConversation(Customer?.id, Channel?.id, true);
        if (value === "open") getOpenConversations(true);
        if (value === "in-progress") getInProgressConversations(true);
        if (value === "awaiting-customer-response")
          getAwaitingResponseConversations(true);
        getConversationsCountAction();
      } catch (error) {
        console.log(error);
      }
    }
  };

  openTagOptions = () => {
    this.setState(
      {
        isTagOptionsActive: true,
      },
      () => document.addEventListener("click", this.closeTagOptions)
    );
  };

  toggleTagForm = () => {
    this.setState({
      isTagFormActive: !this.state.isTagFormActive,
    });
  };

  closeTagOptions = (e) => {
    if (!e || (this.tagOptionsRef && !this.tagOptionsRef.contains(e.target))) {
      this.setState(
        {
          isTagOptionsActive: false,
        },
        () => document.removeEventListener("click", this.closeTagOptions)
      );
    }
  };

  toggleEmailForm = () => {
    const { message, isEmailFormActive } = this.state;

    this.setState({
      message: {
        ...message,
        subject: this.getMostRecentEmailSubject(),
      },
      isEmailFormActive: !isEmailFormActive,
    });
  };

  getMostRecentEmailSubject = () => {
    const { messages } = this.state;

    const messageLength = messages?.length - 1;
    const subject = messages?.[messageLength]?.subject;

    return subject || "Re-Reply";
  };

  handleSubjectChange = (e) => {
    const { message } = this.state;

    this.setState({
      message: {
        ...message,
        subject: e?.target?.value,
      },
    });
  };

  handleEmailFormContentChange = (content) => {
    const { message } = this.state;

    this.setState({
      message: {
        ...message,
        content,
      },
    });
  };

  handleEmailCcOnChange = ({ target }) => {
    this.setState({
      newEmailCc: target?.value,
    });
  };

  handleSetDefaultCcs = (defaultCcs) => {
    this.setState({
      emailCcs: defaultCcs,
    });
  };

  handleAddEmailToCcs = (e) => {
    const { emailCcs, newEmailCc } = this.state;

    if (e.key === "Enter" || e.key === "," || e.key === "Tab") {
      this.setState({
        emailCcs: [...emailCcs, newEmailCc],
        newEmailCc: "",
      });
    }
  };

  handleRemoveFromCcs = (emailToRemove) => {
    const { emailCcs } = this.state;

    if (emailCcs?.includes(emailToRemove)) {
      var ccs = emailCcs;
      ccs = emailCcs.filter((e) => e !== emailToRemove);

      this.setState({
        emailCcs: ccs,
      });
    }
  };

  handleEmailBccOnChange = ({ target }) => {
    this.setState({
      newEmailBcc: target?.value,
    });
  };

  handleSetDefaultBccs = (defaultBccs) => {
    this.setState({
      emailBccs: defaultBccs,
    });
  };

  handleAddEmailToBccs = (e) => {
    const { emailBccs, newEmailBcc } = this.state;

    if (e.key === "Enter" || e.key === "," || e.key === "Tab") {
      this.setState({
        emailBccs: [...emailBccs, newEmailBcc],
        newEmailBcc: "",
      });
    }
  };

  handleRemoveFromBccs = (emailToRemove) => {
    const { emailBccs } = this.state;

    if (emailBccs?.includes(emailToRemove)) {
      var ccs = emailBccs;
      ccs = emailBccs.filter((e) => e !== emailToRemove);

      this.setState({
        emailBccs: ccs,
      });
    }
  };

  handleTemplateSelection = (selection) => {
    const { conversationTemplatesData } = this.props;
    const templateData = conversationTemplatesData?.find(({ name, id }) =>
      id ? id === selection.value : name === selection.label
    );

    const selectedTemplateHeader = templateData?.components?.find(
      (x) => x?.type === "HEADER"
    );

    const selectedTemplateBody = templateData?.components?.find(
      (x) => x?.type === "BODY"
    );
    let selectedTemplate = templateData?.components
      ? selectedTemplateBody?.text
      : templateData?.structure?.body;
    let selectedTemplateItems = selectedTemplate?.split("{{");
    selectedTemplate =
      selectedTemplateItems
        ?.map((_, index) =>
          _.replace(
            `${index}}}`,
            `<span key=${index} id="preview-item-${index}">{{${index}}}</span>`
          )
        )
        .join("") || [];
    selectedTemplateItems = selectedTemplateItems || [];
    selectedTemplateItems.shift();

    this.props.setMessageSendingStatus("", "");

    this.setState({
      templateHeaderMedia: null,
      templateData,
      selectedTemplate,
      selectedTemplateValue: selection,
      selectedTemplateHeader,
      selectedTemplateItems: selectedTemplateItems?.map((_) => ""),
    });
  };

  handleTemplatePreview = ({ target }, index) => {
    const selectedTemplateItems = JSON.parse(
      JSON.stringify(this.state.selectedTemplateItems)
    );
    selectedTemplateItems[index] = target.value;

    const regex = /\s{5,}/g;
    const result = regex.test(target?.value);

    this.setState(
      {
        selectedTemplateItems,
        excessTemplateSpacingError: result,
      },
      () => {
        const previewItem = document.getElementById(
          `preview-item-${index + 1}`
        );
        previewItem.innerHTML = `<strong>{{${target.value}}}</strong>`;
      }
    );
  };

  validateTemplateForm = () => {
    const {
      selectedTemplateItems,
      selectedTemplateHeader,
      templateHeaderMedia,
    } = this.state;

    var value = true;
    if (!selectedTemplateItems.every((val) => !!val)) value = false;
    if (selectedTemplateHeader?.format && !templateHeaderMedia) value = false;

    return value;
  };

  submitTemplateForm = () => {
    this.setState({
      isSubmittingTemplateMessage: true,
    });

    const content = document
      .getElementById("preview-container")
      .innerText.replace(/[{{}}]/g, "");

    this.handleChange({ target: { value: content } }, this.sendTemplateMessage);
  };

  markAsRead = async () => {
    const { selectedConversation } = this.props;
    const { id, read } = selectedConversation;

    if (!read && id) {
      try {
        await patchRequest({
          url: `/conversations/${id}`,
          data: {
            read: true,
          },
          token: true,
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  filterAssignees = (val) => {
    const filteredAgents = JSON.parse(
      JSON.stringify(this.props.allUsers)
    ).filter(
      ({ firstName, lastName }) =>
        firstName?.toLowerCase()?.includes(val?.toLowerCase()) ||
        lastName?.toLowerCase()?.includes(val?.toLowerCase())
    );

    this.setState({
      filteredAgents,
    });
  };

  checkAssignedTags = (tagId) => {
    return this.props.selectedConversation.Tags.some(({ id }) => id === tagId);
  };

  assignTag = async (tag_id) => {
    const { selectedConversation, getConversation } = this.props;
    const { id, Customer, Channel } = selectedConversation;

    this.setState({
      isAssigningTag: true,
    });

    try {
      await postRequest({
        url: `/conversation-tags`,
        data: {
          tag_id,
          conversation_id: id,
        },
        token: true,
      });

      this.setState(
        {
          isAssigningTag: false,
          isTagOptionsActive: false,
        },
        () => document.removeEventListener("click", this.closeTagOptions)
      );
      getConversation(Customer?.id, Channel?.id, true);
    } catch (error) {
      console.log(error);

      this.setState({
        isAssigningTag: false,
      });
    }
  };

  removeTag = async (tagId) => {
    const { selectedConversation, getConversation } = this.props;
    const { Customer, Channel } = selectedConversation;

    this.setState({
      isAssigningTag: true,
    });

    try {
      await deleteRequest({
        url: `/conversation-tags/${tagId}`,
        token: true,
      });

      this.setState({
        isAssigningTag: false,
      });
      getConversation(Customer?.id, Channel?.id, true);
    } catch (error) {
      console.log(error);

      this.setState({
        isAssigningTag: false,
      });
    }
  };

  openConversation = (customer_id, channel_id, msgRowId) => {
    this.props.closeConvoHistory();

    this.props.history.push(
      `/conversations/${customer_id}/${channel_id}?msgRowId=${msgRowId}`
    );
  };

  getAgentName = (agentId) => {
    const agent = this.props.allUsers?.find(({ id }) => agentId === id);
    const { firstName, lastName } = agent || {};

    return agent ? `${firstName} ${lastName}` : "";
  };

  goToDate = (id) => {
    const element = document.getElementById(id);

    if (element) {
      this.chatContentRef.current.scrollTop = element.offsetTop;
    }
  };

  goToFirstConversation = () => {
    this.chatContentRef.current.scrollTop = 0;
  };

  toggleTagsContainer = () => {
    const { showTagsContainer } = this.state;
    this.setState({
      showTagsContainer: !showTagsContainer,
    });
  };

  toggleMetaDataTags = () => {
    const { showMetaDataTags } = this.state;
    this.setState({
      showMetaDataTags: !showMetaDataTags,
    });
  };

  check24Hours = () => {
    var lastCustomerMessage = this.state.messages?.filter(
      (message) => message?.sender?.authUser === false
    );
    lastCustomerMessage = lastCustomerMessage[0];

    const timeDifference = lastCustomerMessage
      ? moment().diff(moment(lastCustomerMessage?.createdAt), "hour")
      : 24;

    const result = timeDifference >= 24;
    // console.log(timeDifference, "timeDifference");
    // console.log(result, "result");

    return result;
  };

  getMsgsInViewPort = () => {
    const allMessageEl = document
      ?.getElementById("chat-content-container")
      ?.querySelectorAll(".grouped-messages-date");

    var convoIds = [];
    allMessageEl?.forEach((item) => {
      const { top, bottom } = item?.getBoundingClientRect();
      const innerHeight = 300;

      const value = top >= 135 && bottom <= innerHeight;

      if (value === true) {
        convoIds = [...convoIds, item?.dataset?.convoid];

        const lastConvoId = convoIds[convoIds?.length - 1];
        this.fetchMetaData(lastConvoId);
      } else {
        convoIds = convoIds?.filter((x) => x !== item?.dataset?.convoid);
      }
    });
  };

  fetchMetaData = (idToFetch) => {
    const { getMessagesMetaData } = this.props;

    if (
      idToFetch &&
      idToFetch !== this.state.fetchedMetaDataId &&
      idToFetch !== this.props.metadataId
    ) {
      this.setState(
        {
          fetchedMetaDataId: idToFetch,
        },
        () => getMessagesMetaData(idToFetch)
      );
    }
  };

  componentDidMount() {
    const { getConversationTemplatesAction, selectedConversation } = this.props;
    const { id, Channel, Messages } = selectedConversation || {};

    this.scrollToBottom();
    this.setState({
      messages: Messages,
      formTop: this.getFormTop(),
    });
    window.addEventListener("resize", this.setFormSpecs);

    if (id) {
      this.markAsRead();
      this.getAuditTrails();
    }

    this.props.getFirmChannelsAction();
    if (
      Channel?.medium === "WHATSAPP" &&
      (templatesPermissions.read || templatesPermissions.viewAll)
    ) {
      getConversationTemplatesAction({ id: Channel?.id });
    }
  }

  componentDidUpdate(prevProps) {
    const { location, selectedConversation, newDlr } = this.props;
    const { Messages } = selectedConversation || {};

    if (!shallowequal(prevProps.selectedConversation, selectedConversation)) {
      this.setState({
        messages: Messages,
      });
    }

    if (prevProps.location.search !== location.search) {
      let message_id = this.props.location.search;
      message_id = message_id.replace("?msgRowId=", "") || null;

      this.setState({
        highlightedMessageId: message_id,
      });
    }

    if (prevProps.newDlr !== newDlr) {
      const msgId = newDlr?.message_id;

      const indexOfMsgId = this.state?.messages?.findIndex(
        (x) => x?.id === msgId
      );

      if (indexOfMsgId >= 0) {
        const allMessages = this.state.messages;
        allMessages[indexOfMsgId].dlr = [newDlr?.dlr];
        this.setState({
          messages: allMessages,
        });
      }
    }

    document
      ?.getElementById("chat-content-container")
      ?.addEventListener("scroll", this.loadMoreChatsUp);
    document
      ?.getElementById("chat-content-container")
      ?.addEventListener("scroll", this.loadMoreChatsDown);
    document
      ?.getElementById("chat-content-container")
      ?.addEventListener("scroll", this.getMsgsInViewPort);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setFormSpecs);

    // document
    //   .getElementById("chat-content-container")
    //   .removeEventListener("scroll", this.loadMoreChatsUp);
  }

  render() {
    const {
      filteredAgents,
      messages,
      message,
      formTop,
      media,
      rawData,
      selectedOrderMsgId,
      isOrderModalOpen,
      isTemplateModalOpen,
      selectedTemplate,
      selectedTemplateValue,
      selectedTemplateHeader,
      selectedTemplateItems,
      isSubmittingTemplateMessage,
      isUploadingTemplateMedia,
      templateHeaderMedia,
      isConfirmationModalOpen,
      isAssigneeOptionsActive,
      isSearchActive,
      isTagOptionsActive,
      isAuditTrailModalOpen,
      auditTrailData,
      conversationHistoryPhone,
      toaster,
      isFilePreviewOpen,
      filePreviewType,
      filePreviewContent,
      showTagsContainer,
      isTagFormActive,
      isEmailFormActive,
      emailCcs,
      newEmailCc,
      emailBccs,
      newEmailBcc,
      highlightedMessageId,
      showMetaDataTags,
      showReassignmentMsg,
      excessSpacingError,
      excessTemplateSpacingError,
      isLoadingPreviousMessages,
      isLoadingRecentMessages,
    } = this.state;
    const {
      selectedConversation,
      returnToQueue,
      reassignConversation,
      closeConversation,
      isClosingConversation,
      isLoadingConversationTemplates,
      isLoadingAgents,
      conversationTemplatesData,
      selectedSection,
      tagsData,
      messageSendingStatus,
      messageSendingResponse,
      showConvoHistory,
      openConvoHistory,
      firmChannelsData,
      isCustomerChatDisabled,
    } = this.props;
    let {
      id,
      Customer,
      customer,
      status,
      Tags,
      agent,
      agent_id,
      Channel,
      isLastPageOfPrevMessages,
      isLastPageOfReceMessages,
      metadata,
    } = selectedConversation || {};
    customer = customer || {};

    const checkAuthFirstChild = (index) => {
      return (
        (messages[index - 1] ? !messages[index - 1]?.sender?.authUser : true) &&
        messages[index]?.sender?.authUser
      );
    };

    const checkFirstChild = (index) => {
      return (
        messages[index - 1] &&
        messages[index - 1]?.sender?.authUser &&
        !messages[index]?.sender?.authUser
      );
    };

    const checkLastChild = (index) => {
      return (
        messages[index + 1] &&
        messages[index + 1]?.sender?.authUser &&
        !messages[index]?.sender?.authUser
      );
    };

    const getLastAuthMessage = () => {
      const authMessages = messages?.filter((x) => x?.sender?.authUser);
      const res = authMessages?.[0] || null;

      return res;
    };

    const getQuotedMessage = (msgId) => {
      let quotedMessage = null;
      if (msgId) {
        quotedMessage = messages?.find((x) => x?.id === msgId);
      }

      return quotedMessage;
    };

    const formHeight = this.getFormHeight();

    const conversationStatusOptions = [
      { label: "Open", value: "open" },
      { label: "In-progress", value: "in-progress" },
      { label: "Awaiting Response", value: "awaiting-customer-response" },
    ];

    let templateOptions = conversationTemplatesData
      ?.filter((x) => x?.status?.toLowerCase() === "approved")
      ?.filter((x) => x.category !== "AUTHENTICATION");
    templateOptions = templateOptions?.map(({ namespace, id, name }) => ({
      label: name,
      value: id || name || namespace,
    }));

    const allMessages = [
      ...this.getSavedUnsentMsgs(selectedConversation?.id),
      ...messages,
    ];

    const groupedMessages = _.chain(
      allMessages?.map((msg) => ({
        ...msg,
        formattedDate: moment(msg?.createdAt).format("MMM DD, YYYY"),
      }))
    )
      .reverse()
      .groupBy("formattedDate")
      .map((value, key) => ({
        groupDate: key,
        groupId: moment(key, "MMM DD, YYYY").format("MMM-DD-YYYY"),
        groupMessages: value,
      }))
      .value();

    const chatMessageRefs = messages?.reduce((acc, value) => {
      acc[value.id] = React.createRef();
      return acc;
    }, {});

    const goToSpecificChatMessage = (id) => {
      if (chatMessageRefs[id]?.current) {
        chatMessageRefs[id].current.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "start",
        });

        setTimeout(() => {
          this.setState({
            highlightedMessageId: null,
          });
        }, 3000);
        clearTimeout();
      }
    };

    if (highlightedMessageId) {
      setTimeout(() => {
        goToSpecificChatMessage(highlightedMessageId);
      }, 100);
      clearTimeout();
    }

    const templateMediaUploadAcceptAttribute = () => {
      var accept = "";
      if (selectedTemplateHeader?.format === "IMAGE") accept = "image/*";
      if (selectedTemplateHeader?.format === "DOCUMENT") accept = ".pdf";
      if (selectedTemplateHeader?.format === "VIDEO") accept = "video/*";

      return accept;
    };

    const isAMetaChannel = () => {
      var value = false;

      if (Channel?.medium === "INSTAGRAM") value = true;
      if (Channel?.medium === "WHATSAPP") value = true;
      if (Channel?.medium === "FACEBOOK") value = true;

      return value;
    };

    const canUserUpdateConversation =
      conversationsPermissions.update || conversationsPermissions.modifyAll;

    return (
      <div
        className={classNames("chats", {
          showConversationHistory: showConvoHistory,
          search_active: isSearchActive,
        })}
      >
        {isSearchActive ? (
          <GlobalSearch
            isSearchActive={isSearchActive}
            toggleIsSearchActive={this.toggleIsSearchActive}
            defaultSearchParams={true}
            inChatSearch={Customer?.id}
            closeOnClick={true}
          />
        ) : (
          <>
            <div className="chat-header">
              <div className="sender">
                <span className="avatar">
                  {Customer?.name
                    ? getInitials(Customer?.name)
                    : customer?.name
                    ? getInitials(customer?.name)
                    : ""}
                </span>
                <span className="name">
                  {Customer?.name ||
                    customer?.name ||
                    customer?.facebookMessengerId}{" "}
                  {(Customer?.phone || customer?.phone) &&
                    `(${Customer?.phone || customer?.phone})`}
                </span>
              </div>
              <div
                className="icon"
                onClick={() => this.toggleIsSearchActive(true)}
              >
                <Search />
              </div>
            </div>
            {showConvoHistory ? (
              <>
                <h6 className="history-title">Conversation History</h6>
                <ConversationHistory
                  customer_id={Customer?.id || customer?.phone}
                  channel_id={Channel?.id}
                  phone={conversationHistoryPhone}
                  openConversation={this.openConversation}
                />
              </>
            ) : (
              <>
                <div className="chat-actions">
                  <div>
                    <div
                      className={classNames("select-assignee", {
                        disabled: !canUserUpdateConversation,
                      })}
                      onClick={this.openAssigneeOptions}
                    >
                      <SquareAvatar />
                      <span className="colon">:</span>
                      <span className="current-assignee">
                        {status === "in-queue"
                          ? "Unassigned"
                          : agent
                          ? agent
                          : this.getAgentName(agent_id)}
                      </span>
                      <AngleDown />
                    </div>
                    {isAssigneeOptionsActive && (
                      <div
                        className="assignee-options"
                        ref={(el) => (this.assigneeOptionsRef = el)}
                      >
                        <div className="search-assignees">
                          <TextField
                            placeholder="Search"
                            leftIcon={<Search />}
                            onChange={(e) =>
                              this.filterAssignees(e.target.value)
                            }
                          />
                        </div>
                        <div className="return-queue">
                          <Return2 />
                          <span
                            onClick={() => returnToQueue(selectedConversation)}
                          >
                            Return to Queue
                          </span>
                        </div>
                        <span className="title">Assign to teammate:</span>
                        {isLoadingAgents && (
                          <span className="loading-users">
                            Loading users... Please wait
                          </span>
                        )}
                        <div className="assignee-list-items">
                          {filteredAgents?.map(
                            ({ firstName, lastName, ...rest }, index) => (
                              <div
                                key={`assignee-item-${index}`}
                                className="assignee-item"
                                onClick={() =>
                                  reassignConversation(id, rest.id)
                                }
                              >
                                <span className="icon">
                                  <Avatar />
                                </span>
                                <span className="name">
                                  {firstName} {lastName}
                                </span>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <div>
                    {canUserUpdateConversation && (
                      <>
                        <Select
                          fixedPlaceholder={
                            conversationStatusOptions?.find(
                              (x) => x?.value === status
                            )?.label
                          }
                          options={conversationStatusOptions}
                          onChange={(e) =>
                            this.handleConversationStatusChange(e)
                          }
                          className="template-options"
                        />
                        <div
                          className={`icon ${
                            showTagsContainer ? "active" : ""
                          }`}
                          title="Tag"
                        >
                          <div onClick={this.toggleTagsContainer}>
                            <Tag />
                          </div>
                          <div
                            className={`chat-tags-container ${
                              showTagsContainer ? "show" : ""
                            }`}
                          >
                            {(Tags || []).map(
                              (
                                { ConversationTag, name, colorCode },
                                tagIndex
                              ) => (
                                <div
                                  key={`tag-${tagIndex}`}
                                  className="chat-tag-item"
                                >
                                  <span className="tag-icon small">
                                    <span
                                      className="tag-bg"
                                      style={{ background: `${colorCode}` }}
                                    ></span>
                                    <Tag color={colorCode} />
                                  </span>
                                  <span
                                    className="tag-name"
                                    style={{ color: `${colorCode}` }}
                                  >
                                    {name}
                                  </span>
                                  <div
                                    className="tag-remove"
                                    onClick={() =>
                                      this.removeTag(ConversationTag.id)
                                    }
                                  >
                                    <Close />
                                  </div>
                                </div>
                              )
                            )}
                            <div
                              className="chat-tag-item add-tag"
                              onClick={this.openTagOptions}
                            >
                              <span className="icon">
                                <CircularPlus />
                              </span>
                              Add tag
                            </div>
                          </div>
                          {isTagOptionsActive && (
                            <div
                              className="add-tags-container"
                              ref={(el) => (this.tagOptionsRef = el)}
                            >
                              <div
                                className="add-tag-item"
                                onClick={() => this.toggleTagForm()}
                              >
                                <span className="name">Create New Tag</span>
                              </div>
                              {tagsData.map(
                                ({ id, name, colorCode }, index) => (
                                  <div
                                    key={`add-tag-${index}`}
                                    className="add-tag-item"
                                    onClick={() => this.assignTag(id)}
                                  >
                                    <span
                                      className="color-bg"
                                      style={{ background: colorCode }}
                                    ></span>
                                    <span className="name">{name}</span>
                                  </div>
                                )
                              )}
                            </div>
                          )}
                        </div>
                        {status !== "in-queue" && (
                          <span
                            className="icon"
                            onClick={this.openConfirmationModal}
                            title={
                              selectedSection === "Resolved"
                                ? "Reopen Conversation"
                                : "Close Conversation"
                            }
                          >
                            {selectedSection === "Resolved" ? (
                              <Return />
                            ) : (
                              <CircularCheck />
                            )}
                          </span>
                        )}
                      </>
                    )}
                    <span
                      className="icon"
                      onClick={this.toggleAuditTrailModal}
                      title="Audit Trail"
                    >
                      <LayersWithNoSlash />
                    </span>
                    <span className="icon" onClick={openConvoHistory}>
                      <Collapse />
                    </span>
                  </div>
                </div>
                {selectedConversation?.Messages?.length > 0 && (
                  <div className="chat-metadata">
                    <div className="left">
                      <p className="started">
                        Started on:{" "}
                        {moment(metadata?.createdAt).format("MMM DD, YYYY")}
                      </p>
                      <p className="status">{metadata?.status}</p>
                    </div>

                    <div className="right">
                      {/* <div
                        className="audit"
                        onClick={this.toggleAuditTrailModal}
                        title="Audit Trail"
                      >
                        <LayersWithNoSlash />
                      </div> */}

                      <p className="tags" onClick={this.toggleMetaDataTags}>
                        Tags <ChevronDownW />
                      </p>
                      {showMetaDataTags && (
                        <div className="tags-container">
                          {metadata?.Tags?.map(({ name, colorCode }, i) => (
                            <p key={i}>
                              <span
                                className="color"
                                style={{ background: colorCode }}
                              ></span>{" "}
                              {name}
                            </p>
                          ))}
                        </div>
                      )}
                      <div className="agent">
                        <div className="initials">
                          {getInitials(metadata?.agent)}
                        </div>
                        <p className="name">{metadata?.agent}</p>
                      </div>
                    </div>
                  </div>
                )}
                <div
                  id="chat-content-container"
                  className="chat-content"
                  ref={this.chatContentRef}
                  style={{ height: `calc(100vh - ${135 + formHeight}px)` }}
                >
                  {isLoadingPreviousMessages ? (
                    <div className="scroll-to-load-previous">
                      Loading previous messages...
                    </div>
                  ) : (
                    !isLastPageOfPrevMessages &&
                    messages?.length > 0 && (
                      <div className="scroll-to-load-previous">
                        Scroll up to load previous messages
                      </div>
                    )
                  )}
                  {groupedMessages.map(
                    ({ groupDate, groupId, groupMessages }, index) => (
                      <ChatDateFilter
                        key={`grouped-message-${index}`}
                        groupDate={groupDate}
                        formTop={formTop}
                        id={groupId}
                        conversation_id={
                          groupMessages[groupMessages?.length - 1]
                            ?.conversation_id
                        }
                        goToDate={this.goToDate}
                        goToFirstConversation={this.goToFirstConversation}
                      >
                        {groupMessages.map((msg, index) => {
                          return (
                            <ChatMessage
                              key={`chat-message-${index}`}
                              chatMessageRef={chatMessageRefs[msg.id]}
                              highlightedMessageId={highlightedMessageId}
                              getMsgsInViewPort={() =>
                                this.getMsgsInViewPort(
                                  chatMessageRefs[msg.id]?.current
                                )
                              }
                              message={msg}
                              {...msg}
                              customer={customer}
                              Channel={Channel}
                              // newDlr={newDlr}
                              authFirstChild={checkAuthFirstChild(index)}
                              firstChild={checkFirstChild(index)}
                              lastChild={checkLastChild(index)}
                              formTop={formTop}
                              sendMessage={this.sendMessage}
                              openFilePreview={this.openFilePreview}
                              index={index}
                              quoted_message={getQuotedMessage(
                                msg?.quoted_message_id
                              )}
                              selectOrder={this.selectOrder}
                              messageSendingStatus={
                                msg?.id ? null : messageSendingStatus
                              }
                              messageSendingResponse={
                                msg?.id ? null : messageSendingResponse
                              }
                              lastAuthMessage={getLastAuthMessage()}
                              retryFailedSavedMsg={() =>
                                this.sendMessage(false, null, msg)
                              }
                              deleteFailedSavedMsg={() =>
                                this.deleteSavedUnsentMsg(id, msg)
                              }
                            />
                          );
                        })}
                      </ChatDateFilter>
                    )
                  )}
                  {isLoadingRecentMessages ? (
                    <div className="scroll-to-load-previous">
                      Loading recent messages...
                    </div>
                  ) : (
                    !isLastPageOfReceMessages &&
                    messages?.length > 0 && (
                      <div className="scroll-to-load-previous">
                        Scroll down to load recent messages
                      </div>
                    )
                  )}
                  {showReassignmentMsg && (
                    <div className="now_assigned">
                      You are now assigned to this conversation!
                    </div>
                  )}
                </div>
                {!isCustomerChatDisabled && (
                  <div
                    id="chat-form"
                    className="chat-form"
                    ref={this.chatFormRef}
                  >
                    {excessSpacingError && (
                      <div className="excess_spacing_error">
                        Error! You cannot have more than 4 consecutive spaces in
                        your message.
                      </div>
                    )}
                    <ChatInputNew
                      placeholder={`Send a message to ${
                        customer.name ||
                        customer.phone ||
                        customer.facebookMessengerId
                      }`}
                      onChange={(e) => this.handleChange(e)}
                      sendMessage={this.sendMessage}
                      onFileLoad={this.handleFileLoad}
                      onFileError={this.handleFileError}
                      removeFile={this.removeFile}
                      media={media}
                      rawData={rawData}
                      openTemplateModal={this.openTemplateModal}
                      openEmailModal={this.toggleEmailForm}
                      templateOnly={
                        selectedSection !== "Queue" &&
                        Channel?.medium === "WHATSAPP"
                          ? !messages?.length || this.check24Hours()
                          : false
                      }
                      reopenOnly={selectedSection === "Resolved"}
                      reopenConversation={this.openConfirmationModal}
                      disabled={
                        selectedSection === "Queue" ||
                        !canUserUpdateConversation
                      }
                      Channel={Channel}
                      channelsData={firmChannelsData}
                      isAMetaChannel={isAMetaChannel()}
                      openFilePreview={this.openFilePreview}
                      value={message?.content}
                      selectedConversation={selectedConversation}
                    />
                    <div className="help">
                      Hit Enter to send | Hold{" "}
                      <strong>Shift and press Enter</strong> to go to next line
                    </div>
                  </div>
                )}

                {isConfirmationModalOpen && (
                  <Modal closeModal={this.closeConfirmationModal} small>
                    <div className="modal-content confirmation-modal">
                      <div className="modal-title">
                        {selectedSection === "Resolved"
                          ? "Open this conversation?"
                          : "Close this conversation?"}
                      </div>
                      <div className="modal-text">
                        {selectedSection === "Resolved"
                          ? ""
                          : "You won't be able to send or receive messages in this conversation until you reopen it."}
                      </div>
                      <div className="modal-action">
                        <Button
                          className="secondary-button small"
                          disabled={isClosingConversation}
                          onClick={() =>
                            closeConversation(id, Customer?.id, Channel?.id)
                          }
                        >
                          {selectedSection === "Resolved"
                            ? isClosingConversation
                              ? "Opening... Please wait"
                              : "Open"
                            : isClosingConversation
                            ? "Closing... Please wait"
                            : "Close"}
                        </Button>
                        <Button
                          className="plain-button black"
                          onClick={this.closeConfirmationModal}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </Modal>
                )}

                {isTemplateModalOpen && (
                  <Modal closeModal={this.closeTemplateModal}>
                    <div className="modal-content">
                      <div className="modal-title">Send a template message</div>
                      <div className="modal-form template-message-form">
                        <div className="mb-30">
                          <ReactSelect
                            disabled={isLoadingConversationTemplates}
                            placeholder="---Please select---"
                            value={selectedTemplateValue}
                            options={templateOptions}
                            onChange={(selection) => {
                              this.handleTemplateSelection(selection);
                            }}
                            classNamePrefix="template-options"
                          />
                        </div>
                        <div className="template-message-preview">
                          <span className="title">Preview:</span>
                          <div
                            id="preview-container"
                            className="content"
                            dangerouslySetInnerHTML={{
                              __html: selectedTemplate,
                            }}
                          ></div>
                        </div>
                        {selectedTemplateHeader?.format && (
                          <>
                            {isUploadingTemplateMedia ? (
                              <div className="fw-semi-bold mb-20">
                                Uploading{" "}
                                {selectedTemplateHeader?.format?.toLowerCase()}
                                ...
                              </div>
                            ) : (
                              <>
                                {templateHeaderMedia ? (
                                  <div className="fw-semi-bold mb-20">
                                    Template{" "}
                                    {selectedTemplateHeader?.format?.toLowerCase()}{" "}
                                    upload successful!
                                  </div>
                                ) : (
                                  <FileInput
                                    icon={
                                      <span className="secondary-button small mb-10">
                                        Upload{" "}
                                        {selectedTemplateHeader?.format?.toLowerCase()}
                                      </span>
                                    }
                                    accept={templateMediaUploadAcceptAttribute()}
                                    onFileLoad={
                                      this.handleUploadTemplateHeaderMedia
                                    }
                                    onFileError={() => {}}
                                  />
                                )}
                              </>
                            )}
                          </>
                        )}
                        {selectedTemplateItems?.length > 0 && (
                          <div className="form-row mb-30">
                            {selectedTemplateItems?.map((value, index) => (
                              <TextField
                                key={index}
                                label={`{{${index + 1}}}`}
                                value={value}
                                onChange={(e) =>
                                  this.handleTemplatePreview(e, index)
                                }
                              />
                            ))}
                          </div>
                        )}
                        {excessTemplateSpacingError && (
                          <div className="excess_template_spacing_error">
                            Error! You cannot have more than 4 consecutive
                            spaces in your template message.
                          </div>
                        )}

                        <div className="modal-form-action">
                          <Button
                            className="secondary-button"
                            onClick={this.closeTemplateModal}
                          >
                            Cancel
                          </Button>
                          <Button
                            className="primary-button"
                            disabled={
                              !this.validateTemplateForm() ||
                              selectedTemplate?.length < 1 ||
                              excessTemplateSpacingError ||
                              isSubmittingTemplateMessage
                            }
                            onClick={this.submitTemplateForm}
                          >
                            Send Message
                          </Button>
                        </div>
                        {messageSendingStatus === "failed" &&
                          !isSubmittingTemplateMessage && (
                            <>
                              <br />
                              <FormNotifications
                                type="error"
                                message={messageSendingResponse}
                              />
                            </>
                          )}
                      </div>
                    </div>
                  </Modal>
                )}

                {isFilePreviewOpen && (
                  <Modal closeModal={this.closeFilePreview}>
                    <div className="modal-content">
                      <div className="modal-title file-preview-title">
                        <span>File Preview</span>
                        <a
                          href={filePreviewContent}
                          className="primary-link"
                          // eslint-disable-next-line react/jsx-no-target-blank
                          target="_blank"
                          rel="noreferrer"
                          download
                        >
                          Click here to download
                        </a>
                      </div>
                      <div className="modal-form template-message-form">
                        {filePreviewType === "IMAGE" && (
                          <span className="message-media">
                            <img src={filePreviewContent} alt="" />
                          </span>
                        )}
                        {filePreviewType === "VIDEO" && (
                          <video controls>
                            <source src={filePreviewContent}></source>
                          </video>
                        )}
                      </div>
                    </div>
                  </Modal>
                )}

                {isTagFormActive && (
                  <TagForm
                    toggleTagForm={this.toggleTagForm}
                    assignTag={this.assignTag}
                  />
                )}

                {isEmailFormActive && (
                  <Modal large>
                    <EmailChatForm
                      closeModal={this.toggleEmailForm}
                      selectedConversation={selectedConversation}
                      subject={message.subject}
                      content={message?.content}
                      onSubjectChange={this.handleSubjectChange}
                      onEmailContentChange={this.handleEmailFormContentChange}
                      sendMessage={this.sendMessage}
                      handleSetDefaultCcs={this.handleSetDefaultCcs}
                      addEmailToCcs={this.handleAddEmailToCcs}
                      onCcChange={this.handleEmailCcOnChange}
                      removeFromCcs={this.handleRemoveFromCcs}
                      emailCcs={emailCcs}
                      newEmailCc={newEmailCc}
                      handleSetDefaultBccs={this.handleSetDefaultBccs}
                      addEmailToBccs={this.handleAddEmailToBccs}
                      onBccChange={this.handleEmailBccOnChange}
                      removeFromBccs={this.handleRemoveFromBccs}
                      emailBccs={emailBccs}
                      newEmailBcc={newEmailBcc}
                      onFileLoad={this.handleFileLoad}
                      onFileError={this.handleFileError}
                      removeFile={this.removeFile}
                      media={media}
                      rawData={rawData}
                      openFilePreview={this.openFilePreview}
                    />
                  </Modal>
                )}

                {isAuditTrailModalOpen && (
                  <AuditTrail
                    close={this.toggleAuditTrailModal}
                    auditTrailData={auditTrailData}
                  />
                )}

                {isOrderModalOpen && (
                  <OrderDetails
                    close={this.toggleOrderModal}
                    customer={
                      customer.name ||
                      customer.phone ||
                      customer.facebookMessengerId
                    }
                    msgId={selectedOrderMsgId}
                  />
                )}
              </>
            )}
          </>
        )}

        <Toaster {...toaster} closeToaster={this.closeToaster} />
      </div>
    );
  }
}

const mapStateToProps = ({
  auth,
  conversations: {
    isLoadingConversationTemplates,
    conversationTemplatesData,
    messageSendingStatus,
    messageSendingResponse,
  },
  agents: { isLoadingAgents },
  channels: { isLoadingChannels, firmChannelsData },
  tags,
}) => ({
  ...auth,
  isLoadingConversationTemplates,
  conversationTemplatesData,
  messageSendingStatus,
  messageSendingResponse,
  isLoadingAgents,
  isLoadingChannels,
  firmChannelsData,
  ...tags,
});

export default withRouter(
  connect(mapStateToProps, {
    setMessageSendingStatus,
    postSendMessage,
    getFirmChannelsAction,
    getConversationTemplatesAction,
    getConversationAuditTrailsAction,
  })(Chats)
);
