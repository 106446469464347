import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import * as moment from "moment";

import Modal from "../../../components/Modal";

import { getTicketsAction } from "../../../redux/actions/Integrations";

const TicketDetails = ({ toggleTicketDetails, ticketDetails }) => {
  const dispatch = useDispatch();

  const [ticketInfo, setTicketInfo] = useState(ticketDetails);

  const {
    id,
    title,
    key,
    type,
    assigneeName,
    remoteLink,
    createdAt,
    updatedAt,
    description,
    description_text,
    labels,
  } = ticketInfo;

  useEffect(() => {
    dispatch(
      getTicketsAction({
        url: `/integrations/ticket/${id}`,
      })
    ).then((res) => {
      setTicketInfo({ ...res });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal closeModal={() => toggleTicketDetails()}>
      <div className="modal-content new-ticket-container">
        <div className="modal-title">
          <span>Ticket Details</span>
        </div>
        <div className="ticket-details-container">
          <div className="ticket-details-item">
            <div className="title">Title</div>
            <div className="value">{title}</div>
          </div>
          <div className="ticket-details-item">
            <div className="title">Type</div>
            <div className="value">{type === "zoho" ? type : "freshDesk"}</div>
          </div>
          <div className="ticket-details-item">
            <div className="title">Key</div>
            <div className="value">{key}</div>
          </div>
          <div className="ticket-details-item">
            <div className="title">Description</div>
            <div className="value">
              {(type === "zoho" ? description : description_text) || "N/A"}
            </div>
          </div>
          {type !== "zoho" && (
            <div className="ticket-details-item">
              <div className="title">Ticket Type</div>
              <div className="value">{type}</div>
            </div>
          )}
          {type === "zoho" && (
            <div className="ticket-details-item">
              <div className="title">Assignee Name</div>
              <div className="value">{assigneeName}</div>
            </div>
          )}
          {type === "zoho" && (
            <div className="ticket-details-item">
              <div className="title">Labels</div>
              <div className="value">
                {labels?.map((x) => `${x}, `) || "N/A"}
              </div>
            </div>
          )}
          <div className="ticket-details-item">
            <div className="title">Date Created</div>
            <div className="value">
              {moment(createdAt).format("DD MMMM, YYYY")}
            </div>
          </div>
          <div className="ticket-details-item">
            <div className="title">Date Updated</div>
            <div className="value">
              {moment(updatedAt).format("DD MMMM, YYYY")}
            </div>
          </div>
          <div className="ticket-details-item">
            <div className="title">Link to Ticket</div>
            <div className="value">
              <a href={remoteLink} target="_blank" rel="noopener noreferrer">
                View Ticket
              </a>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TicketDetails;
