import React from "react";

export default () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="24" height="24" rx="12" fill="#4BA3E7" />
    <path
      d="M12 6C8.68653 6 6 8.68445 6 11.9964C6 15.3083 8.68654 17.9927 12 17.9927C13.0448 17.9927 14.3279 17.7303 15.2434 17.2356L17.4746 17.9725C17.6183 18.02 17.7765 17.9822 17.8834 17.875C17.9902 17.7678 18.0274 17.6094 17.9794 17.4658L17.2381 15.2487C17.7764 14.3107 18 13.0838 18 11.9964C18 8.68445 15.3135 6 12 6Z"
      fill="white"
    />
  </svg>
);
