import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyDvH32g2DDesrl3ulQa5O6fGs718jxHx1Y",
  authDomain: "one-route-b8490.firebaseapp.com",
  projectId: "one-route-b8490",
  storageBucket: "one-route-b8490.appspot.com",
  messagingSenderId: "113672316221",
  appId: "1:113672316221:web:6eb20c44dc8d5dd3d95154",
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const fetchToken = (setNotificationToken) => {
  return getToken(messaging, {
    vapidKey:
      "BNX1I-n0BJpnzhGIiqfbUhqWBH87m_TkVeCflaKTDPLFgjqrgkdh6iIcvFzdLX3AShYHPqHjnz8jeyM_wDB1BIE",
  })
    .then((currentToken) => {
      if (currentToken) {
        setNotificationToken(currentToken);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log("No Token available!");
        setNotificationToken(null);
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // catch error while creating client token
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
