import React from "react";

const OverviewCard = ({ icon, title, value }) => (
  <div className="overview-card">
    <div className="icon">{icon}</div>
    <div>
      <span className="title">{title}</span>
      <span className="value">{value}</span>
    </div>
  </div>
);

export default OverviewCard;
