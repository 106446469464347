import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import * as moment from "moment";
import DatePicker from "react-datepicker";
import Papa from "papaparse";
import Select from "react-select";

import { Button } from "../../../components/FormElements";

import { Calendar } from "../../../assets/vectors";

import { getInitials, secondsToHms } from "../../../utils/functions";

import { getAgentsInsightsAction } from "../../../redux/actions/Insights";
import { getTeamAction } from "../../../redux/actions/Teams";

var daysFromNow = moment().subtract(7, "days");
daysFromNow = daysFromNow.format("YYYY-MM-DD");
const today = moment().format("YYYY-MM-DD");

const AgentsInsights = () => {
  const dispatch = useDispatch();
  const { isLoadingAgentsInsights, agentsInsightsData } = useSelector(
    (state) => state.insights
  );
  const { teamsData } = useSelector((state) => state.teams);

  const [startDate, setStartDate] = useState(daysFromNow);
  const [endDate, setEndDate] = useState(today);
  const [params, setParams] = useState({});
  const [selectedTeam, setSelectedTeam] = useState(null);

  useEffect(() => {
    dispatch(getTeamAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (params?.toDate) {
      dispatch(getAgentsInsightsAction({ url: "all", params }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const datePickerDisplayValue = (sDate, eDate) => {
    return `${
      moment(sDate).format("MMM DD, YYYY") +
      " - " +
      `${
        eDate
          ? moment(eDate).format("MMM DD, YYYY")
          : moment(sDate).format("MMM DD, YYYY")
      }`
    }`;
  };

  const dateRangeIsNotSameDay = (sDate, eDate) => {
    if (eDate && moment(sDate).format("x") !== moment(eDate).format("x")) {
      return true;
    } else return true;
  };

  useEffect(() => {
    if (endDate && dateRangeIsNotSameDay(startDate, endDate)) {
      setParams({
        fromDate: moment(startDate).format("YYYY-MM-DD"),
        toDate: moment(endDate).format("YYYY-MM-DD"),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDate]);

  const exportJsonToCsv = () => {
    const data = Papa.unparse(agentsInsightsData?.breakdown);
    let csvContent = "data:text/csv;charset=utf-8," + data;

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute(
      "download",
      `Agent_Performance_Report(${params?.fromDate}_${params?.toDate}).csv`
    );
    document.body.appendChild(link);

    link.click();
    // window.open(encodedUri);
  };

  const tableHeadColumns = [
    "Agent",
    "Conversations",
    "Pending Conversations",
    "Closed Conversations",
    "Avg. Response time",
    "Avg. Resolution time",
    "Avg. CSAT score",
  ];

  const datePickerConfig = {
    className: "date_range_input",
    dateFormat: "yyyy-MM-dd",
    // minDate: new Date(moment().subtract(120, "days")),
    maxDate: new Date(),
    selectsRange: "selectsRange",
    shouldCloseOnSelect: false,
  };

  const teamOptions = teamsData?.map((x) => ({
    label: x?.name,
    value: x?.id,
  }));

  const fromDateUrlSearch = moment(startDate).format("YYYY-MM-DD");
  const toDateUrlSearch = moment(endDate).format("YYYY-MM-DD");

  console.log(agentsInsightsData?.breakdown, "agentsInsightsData?.breakdown");

  return (
    <div className="insights_page_container_new">
      <h5 className="page_title">Agents</h5>

      <div className="filter_container">
        <div className="date_range_picker">
          <Calendar />
          <DatePicker
            onChange={([start, end]) => {
              setStartDate(start);
              setEndDate(end);
            }}
            startDate={startDate}
            endDate={endDate}
            value={datePickerDisplayValue(startDate, endDate)}
            {...datePickerConfig}
          />
        </div>
        <Select
          classNamePrefix="select_container"
          value={selectedTeam}
          options={teamOptions}
          onChange={(selected) => {
            setSelectedTeam(selected);
            setParams({ ...params, team_id: selected?.value });
          }}
          placeholder="Filter by team..."
          isDisabled={isLoadingAgentsInsights}
          isClearable
        />
      </div>

      {isLoadingAgentsInsights && !agentsInsightsData?.from ? (
        <div className="loading">Loading...</div>
      ) : (
        <>
          <div className="agent_list_container">
            <div className="d_flex">
              <div className="overview">
                <p>Most active agent (by conversation)</p>
                <h6>
                  {agentsInsightsData?.breakdown?.[0]?.conversations > 0
                    ? agentsInsightsData?.breakdown?.[0]?.firstName +
                      " " +
                      agentsInsightsData?.breakdown?.[0]?.lastName
                    : "- - - - - -"}
                </h6>
              </div>

              {agentsInsightsData?.breakdown?.length > 0 && (
                <Button className="primary-button" onClick={exportJsonToCsv}>
                  Export
                </Button>
              )}
            </div>

            <div className="table_container">
              <table className="table">
                <thead>
                  <tr>
                    {tableHeadColumns?.map((item, i) => (
                      <td key={i}>{item}</td>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {agentsInsightsData?.breakdown?.map((item, i) => (
                    <tr key={i}>
                      <td className="d_flex">
                        <div className="agent">
                          {getInitials(`${item?.firstName} ${item?.lastName}`)}
                        </div>
                        {item?.firstName + " " + item?.lastName}
                      </td>
                      <td className="link">
                        <Link
                          to={`/conversations?agent_id=${item?.id}&fromDate=${fromDateUrlSearch}&toDate=${toDateUrlSearch}`}
                        >
                          {item?.conversations}
                        </Link>
                      </td>
                      <td className="link">
                        <Link
                          to={`/conversations?agent_id=${item?.id}&status=!closed&fromDate=${fromDateUrlSearch}&toDate=${toDateUrlSearch}`}
                        >
                          {item?.pendingconversations}
                        </Link>
                      </td>
                      <td className="link">
                        <Link
                          to={`/conversations?agent_id=${item?.id}&status=closed&fromDate=${fromDateUrlSearch}&toDate=${toDateUrlSearch}`}
                        >
                          {item?.closedconversations}
                        </Link>
                      </td>
                      <td>
                        {secondsToHms(item?.averageResponseTime) || "-- --"}
                      </td>
                      <td>
                        {secondsToHms(item?.averageresolutiontime) || "-- --"}
                      </td>
                      <td className="link">
                        <Link
                          to={`/conversations?agent=${
                            item?.firstName + " " + item?.lastName
                          }&csat=true&fromDate=${fromDateUrlSearch}&toDate=${toDateUrlSearch}`}
                        >
                          {item?.csatscore || "-- --"}
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AgentsInsights;
