import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { usePaystackPayment } from "react-paystack";

import { Button, TextField } from "../../../components/FormElements";
import Modal from "../../../components/Modal";

import { postFirmSubscribeToPlanAction } from "../../../redux/actions/Billing";

import { Close, OnerouteLogo } from "../../../assets/vectors";
import { formatNumber, getUserDetails } from "../../../utils/functions";
import {
  APP_EVENT_UPGRADED_ACCOUNT,
  APP_EVENT_PURCHASED_CREDIT,
} from "../../../utils/constants";

const UpgradeInfoModal = ({ close, planInfo, openToast }) => {
  const dispatch = useDispatch();

  const [amount, setAmount] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const user = getUserDetails();

  const paystackConfig = {
    reference: amount
      ? `topup.${user?.firm_id}.${new Date().getTime()}`
      : `plan.${user?.firm_id}.${new Date().getTime()}`,
    email: user?.email,
    amount: amount ? amount * 100 : planInfo?.amountToPay * 100,
    currency: planInfo?.currency,
    publicKey: process.env.REACT_APP_PAYSTACK_KEY,
    metadata: amount
      ? null
      : {
          firm_id: user?.firm_id,
          plan_id: planInfo?.id,
          plan_code: planInfo?.planCode,
        },
  };

  const initializePayment = usePaystackPayment(paystackConfig);

  const onPaymentSuccess = (res) => {
    if (res?.message === "Approved") {
      setIsSubmitting(true);

      window.dataLayer.push({
        event: amount ? APP_EVENT_PURCHASED_CREDIT : APP_EVENT_UPGRADED_ACCOUNT,
        agent_name: `${user?.firstName} ${user?.lastName}`,
        firm_name: user?.firm_name,
        email: user?.email,
        plan: planInfo?.planCode,
        amount: amount,
        currency: "NGN",
      });

      initializeSubscription(res?.trxref);
    }
  };

  const onPaymentClose = () => {
    console.log("closed");
    close();
  };

  const initializeSubscription = (transactionRef) => {
    const payload = {
      firm_id: user?.firm_id,
      plan_id: planInfo?.id,
      transactionRef,
      amount: amount ? amount : planInfo?.amountToPay,
    };

    dispatch(postFirmSubscribeToPlanAction(payload)).then((res) => {
      if (res?.success === true) {
        window.location.assign("/settings/billing");
      } else {
        openToast("error", res?.message);
        setIsSubmitting(false);
      }
    });
  };

  return (
    <Modal>
      <div className="modal-content upgrade-info-modal">
        <div className="top">
          <OnerouteLogo />

          <span className="close" onClick={close}>
            <Close />
          </span>
        </div>

        {!planInfo?.payg ? (
          <>
            <p className="plan">{planInfo?.name}</p>
            <p className="cost">
              {planInfo?.currencySymbol}
              {formatNumber(planInfo?.amountToPay)}
            </p>
            <p className="billed">You will be billed {planInfo?.cycle}</p>
          </>
        ) : (
          <>
            <p className="plan">Pay as you go plan</p>
            <TextField
              label="Enter amount of credits you want to purchase"
              name="amount"
              type="number"
              placeholder="5000"
              onChange={(e) => setAmount(e?.target?.value)}
            />
            <p className="minimum">
              Minimum amount <span>₦5,000</span>
            </p>
          </>
        )}

        <Button
          className="primary-button"
          onClick={() => initializePayment(onPaymentSuccess, onPaymentClose)}
          disabled={
            isSubmitting || (planInfo?.amountToPay === "1" && amount < 5000)
          }
        >
          {isSubmitting ? "Loading..." : "Proceed to checkout"}
        </Button>
      </div>
    </Modal>
  );
};

export default UpgradeInfoModal;
