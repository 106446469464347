import {
  getRequest,
  postRequest,
  patchRequest,
  deleteRequest,
} from "../../api";

import {
  CREATE_GROUPS_LOADER,
  CREATE_GROUPS_RESPONSE,
  CREATE_GROUPS_RESET,
  UPDATE_GROUPS_LOADER,
  UPDATE_GROUPS_RESPONSE,
  UPDATE_GROUPS_RESET,
  GET_GROUPS_LOADER,
  GET_GROUPS_RESPONSE,
  GET_GROUPS_RESET,
  DELETE_GROUPS_LOADER,
  DELETE_GROUPS_RESPONSE,
  DELETE_GROUPS_RESET,
} from "../constants";

import { getRequestError } from "../../utils/functions";

export const createGroupsLoader = (isCreatingGroup) => ({
  type: CREATE_GROUPS_LOADER,
  isCreatingGroup,
});

export const createGroupsResponse = (
  createGroupsOutcome,
  createGroupsMessage
) => ({
  type: CREATE_GROUPS_RESPONSE,
  createGroupsOutcome,
  createGroupsMessage,
});

export const createGroupsReset = () => ({
  type: CREATE_GROUPS_RESET,
});

export const updateGroupsLoader = (isUpdatingGroup) => ({
  type: UPDATE_GROUPS_LOADER,
  isUpdatingGroup,
});

export const updateGroupsResponse = (
  updateGroupsOutcome,
  updateGroupsMessage
) => ({
  type: UPDATE_GROUPS_RESPONSE,
  updateGroupsOutcome,
  updateGroupsMessage,
});

export const updateGroupsReset = () => ({
  type: UPDATE_GROUPS_RESET,
});

export const getGroupsLoader = (isLoadingGroups) => ({
  type: GET_GROUPS_LOADER,
  isLoadingGroups,
});

export const getGroupsResponse = (
  groupsOutcome,
  groupsData,
  groupsMeta,
  groupsMessage
) => ({
  type: GET_GROUPS_RESPONSE,
  groupsOutcome,
  groupsData,
  groupsMeta,
  groupsMessage,
});

export const getGroupsReset = () => ({
  type: GET_GROUPS_RESET,
});

export const deleteGroupsLoader = (isDeletingGroup) => ({
  type: DELETE_GROUPS_LOADER,
  isDeletingGroup,
});

export const deleteGroupsResponse = (
  deleteGroupsOutcome,
  deleteGroupsMessage
) => ({
  type: DELETE_GROUPS_RESPONSE,
  deleteGroupsOutcome,
  deleteGroupsMessage,
});

export const deleteGroupsReset = () => ({
  type: DELETE_GROUPS_RESET,
});

export const createGroupsAction = (data, success) => {
  return async (dispatch) => {
    dispatch(createGroupsReset());
    dispatch(createGroupsLoader(true));

    try {
      const res = await postRequest({
        url: "/customers/groups",
        data,
        token: true,
      });

      dispatch(createGroupsResponse("success", ""));
      dispatch(createGroupsLoader(false));

      success && success();
      return res?.data?.data;
    } catch (error) {
      const message = getRequestError(error);

      dispatch(createGroupsResponse("error", message));
      dispatch(createGroupsLoader(false));
    }
  };
};

export const addToGroupAction = (data, success) => {
  return async (dispatch) => {
    dispatch(createGroupsReset());
    dispatch(createGroupsLoader(true));

    try {
      const res = await postRequest({
        url: "/customers/groups/add",
        data,
        token: true,
      });

      dispatch(createGroupsResponse("success", res?.data?.message));
      dispatch(createGroupsLoader(false));

      success && success();
    } catch (error) {
      const message = getRequestError(error);

      dispatch(createGroupsResponse("error", message));
      dispatch(createGroupsLoader(false));
    }
  };
};

export const removefromGroupAction = (data, success) => {
  return async (dispatch) => {
    dispatch(createGroupsReset());
    dispatch(createGroupsLoader(true));

    try {
      const res = await postRequest({
        url: `/customers/${data?.id}/group/remove`,
        data: data?.data,
        token: true,
      });

      dispatch(createGroupsResponse("success", res?.data?.message));
      dispatch(createGroupsLoader(false));

      success && success();
    } catch (error) {
      const message = getRequestError(error);

      dispatch(createGroupsResponse("error", message));
      dispatch(createGroupsLoader(false));
    }
  };
};

export const updateGroupsAction = (data, id, success) => {
  return async (dispatch) => {
    dispatch(updateGroupsReset());
    dispatch(updateGroupsLoader(true));

    try {
      await patchRequest({
        url: `/customers/groups/${id}`,
        data,
        token: true,
      });

      dispatch(updateGroupsResponse("success", ""));
      dispatch(updateGroupsLoader(false));

      success && success();
    } catch (error) {
      const message = getRequestError(error);

      dispatch(updateGroupsResponse("error", message));
      dispatch(updateGroupsLoader(false));
    }
  };
};

export const getGroupsAction = (params = {}) => {
  return async (dispatch) => {
    dispatch(getGroupsReset());
    dispatch(getGroupsLoader(true));

    try {
      const res = await getRequest({
        url: "/customers/groups",
        params,
        token: true,
      });

      const { data, offset, limit, total } = res.data;

      dispatch(
        getGroupsResponse(
          "success",
          data.length ? data : [],
          { offset, limit, total },
          ""
        )
      );
      dispatch(getGroupsLoader(false));
    } catch (error) {
      const message = getRequestError(error);

      dispatch(getGroupsResponse("error", [], {}, message));
      dispatch(getGroupsLoader(false));
    }
  };
};

export const deleteGroupsAction = (id) => {
  return async (dispatch) => {
    dispatch(deleteGroupsReset());
    dispatch(deleteGroupsLoader(true));

    try {
      await deleteRequest({
        url: `/customers/groups/${id}`,
        token: true,
      });

      dispatch(deleteGroupsResponse("success", ""));
      dispatch(deleteGroupsLoader(false));
    } catch (error) {
      const message = getRequestError(error);

      dispatch(deleteGroupsResponse("error", message));
      dispatch(deleteGroupsLoader(false));
    }
  };
};
