import React from "react";

export default () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="#474F58"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
        d="M16.875.625H3.125c-.69 0-1.25.56-1.25 1.25v16.25c0 .69.56 1.25 1.25 1.25h13.75c.69 0 1.25-.56 1.25-1.25V1.875c0-.69-.56-1.25-1.25-1.25z"
      ></path>
      <path
        stroke="#474F58"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
        d="M10 10.994a3.425 3.425 0 100-6.85 3.425 3.425 0 000 6.85zM15.625 15.856a6.358 6.358 0 00-11.25 0"
      ></path>
    </svg>
)