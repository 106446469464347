import React from "react";

export default () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#responseTimeClip0)">
      <path
        d="M20.4913 22V22C20.3188 22.7541 19.6354 23.2797 18.8623 23.253H5.13833V23.253C4.36522 23.2797 3.68189 22.7541 3.50933 22L1.64533 11.847C0.579327 6.038 5.51333 0.75 12.0003 0.75C18.4873 0.75 23.4213 6.038 22.3553 11.85L20.4913 22Z"
        stroke="#F08B34"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 15.75C14.0711 15.75 15.75 14.0711 15.75 12C15.75 9.92893 14.0711 8.25 12 8.25C9.92893 8.25 8.25 9.92893 8.25 12C8.25 14.0711 9.92893 15.75 12 15.75Z"
        stroke="#F08B34"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 8.25V12"
        stroke="#F08B34"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 4.875V4.875C12.2071 4.875 12.375 5.04289 12.375 5.25C12.375 5.45711 12.2071 5.625 12 5.625C11.7929 5.625 11.625 5.45711 11.625 5.25V5.25C11.625 5.04289 11.7929 4.875 12 4.875C12 4.875 12 4.875 12 4.875"
        stroke="#F08B34"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.96214 6.96205V6.96205C7.10849 6.81551 7.34593 6.81537 7.49247 6.96172C7.639 7.10808 7.63915 7.34551 7.4928 7.49205C7.34644 7.63859 7.109 7.63874 6.96247 7.49238C6.96236 7.49227 6.96225 7.49216 6.96214 7.49205V7.49205C6.81578 7.34629 6.81529 7.10949 6.96105 6.96314C6.96141 6.96277 6.96177 6.96241 6.96214 6.96205"
        stroke="#F08B34"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.25 11.625V11.625C5.45711 11.625 5.625 11.7929 5.625 12C5.625 12.2071 5.45711 12.375 5.25 12.375C5.04289 12.375 4.875 12.2071 4.875 12V12C4.875 11.7929 5.04289 11.625 5.25 11.625"
        stroke="#F08B34"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.96214 16.5079V16.5079C7.10849 16.3614 7.34593 16.3613 7.49247 16.5076C7.639 16.654 7.63915 16.8914 7.4928 17.0379C7.34644 17.1845 7.109 17.1846 6.96247 17.0383C6.96236 17.0382 6.96225 17.0381 6.96214 17.0379V17.0379C6.81578 16.8922 6.81529 16.6554 6.96105 16.509C6.96141 16.5087 6.96177 16.5083 6.96214 16.5079"
        stroke="#F08B34"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 18.375V18.375C12.2071 18.375 12.375 18.5429 12.375 18.75C12.375 18.9571 12.2071 19.125 12 19.125C11.7929 19.125 11.625 18.9571 11.625 18.75V18.75C11.625 18.5429 11.7929 18.375 12 18.375C12 18.375 12 18.375 12 18.375"
        stroke="#F08B34"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.509 16.5079V16.5079C16.6554 16.3614 16.8928 16.3613 17.0393 16.5076C17.1859 16.654 17.186 16.8914 17.0397 17.0379C16.8933 17.1845 16.6559 17.1846 16.5093 17.0383C16.5092 17.0382 16.5091 17.0381 16.509 17.0379V17.0379C16.3627 16.8922 16.3622 16.6554 16.5079 16.509C16.5083 16.5087 16.5086 16.5083 16.509 16.5079"
        stroke="#F08B34"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.75 11.625V11.625C18.9571 11.625 19.125 11.7929 19.125 12C19.125 12.2071 18.9571 12.375 18.75 12.375C18.5429 12.375 18.375 12.2071 18.375 12V12C18.375 11.7929 18.5429 11.625 18.75 11.625"
        stroke="#F08B34"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.509 6.96205V6.96205C16.6554 6.81551 16.8928 6.81537 17.0393 6.96172C17.1859 7.10808 17.186 7.34551 17.0397 7.49205C16.8933 7.63859 16.6559 7.63874 16.5093 7.49238C16.5092 7.49227 16.5091 7.49216 16.509 7.49205V7.49205C16.3627 7.34629 16.3622 7.10949 16.5079 6.96314C16.5083 6.96277 16.5086 6.96241 16.509 6.96205"
        stroke="#F08B34"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="responseTimeClip0">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
