import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import DatePicker from "react-datepicker";
import { Line, Pie, Bar } from "react-chartjs-2";
import Select from "react-select";

import { Calendar, ArrowUp, Info } from "../../../assets/vectors";

import {
  getConversationsInsightsAction,
  getConversationTagsInsightsAction,
} from "../../../redux/actions/Insights";
import { getFirmChannelsAction } from "../../../redux/actions/Channels";

import { secondsToHms } from "../../../utils/functions";

var daysFromNow = moment().subtract(7, "days");
daysFromNow = daysFromNow.format("YYYY-MM-DD");
const today = moment().format("YYYY-MM-DD");

const ConversationInsights = () => {
  const dispatch = useDispatch();
  const {
    isLoadingConversationsInsights,
    allConversations,
    openedConversations,
    closedConversations,
    convosInProgress,
    convosRepliedTo,
    awaitingResponse,
    busiestPeriod,
    csatRating,
    newConvosData,
    channelConvosData,
    dailyStatsConvoData,
    newVsRepliedConvosData,
    convosStatusData,
    // convosTagsData,
    responsesData,
    resolutionData,
    csatData,
  } = useSelector((state) => state.insights);
  const { firmChannelsData } = useSelector((state) => state.channels);

  const [convosTagsData, setConvosTagsData] = useState({});
  // const [isLoadingInitially, setIsLoadingInitially] = useState(false);
  const [showInfo, setShowInfo] = useState({});
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [generalParams, setGeneralParams] = useState({});
  const [generalStartDate, setGeneralStartDate] = useState(daysFromNow);
  const [generalEndDate, setGeneralEndDate] = useState(today);
  const [newConvoParams, setNewConvoParams] = useState({});
  const [newConvoStartDate, setNewConvoStartDate] = useState(daysFromNow);
  const [newConvoEndDate, setNewConvoEndDate] = useState(today);
  const [newConvoChannelParams, setNewConvoChannelParams] = useState({});
  const [newConvoChannelStartDate, setNewConvoChannelStartDate] =
    useState(daysFromNow);
  const [newConvoChannelEndDate, setNewConvoChannelEndDate] = useState(today);
  const [dailyStatsParams, setDailyStatsParams] = useState({});
  const [dailyStatsConvoStartDate, setDailyStatsConvoStartDate] =
    useState(daysFromNow);
  const [dailyStatsConvoEndDate, setDailyStatsConvoEndDate] = useState(today);
  const [newVsRepliedParams, setNewVsRepliedParams] = useState({});
  const [newVsRepliedStartDate, setNewVsRepliedStartDate] =
    useState(daysFromNow);
  const [newVsRepliedEndDate, setNewVsRepliedEndDate] = useState(today);
  const [convoStatusParams, setConvoStatusParams] = useState({});
  const [convoStatusStartDate, setConvoStatusStartDate] = useState(daysFromNow);
  const [convoStatusEndDate, setConvoStatusEndDate] = useState(today);
  const [convoTagsParams, setConvoTagsParams] = useState({});
  const [convoTagsStartDate, setConvoTagsStartDate] = useState(daysFromNow);
  const [convoTagsEndDate, setConvoTagsEndDate] = useState(today);
  const [responsesParams, setResponsesParams] = useState({});
  const [responsesStartDate, setResponsesStartDate] = useState(daysFromNow);
  const [responsesEndDate, setResponsesEndDate] = useState(today);
  const [resolutionParams, setResolutionParams] = useState({});
  const [resolutionStartDate, setResolutionStartDate] = useState(daysFromNow);
  const [resolutionEndDate, setResolutionEndDate] = useState(today);
  const [csatParams, setCsatParams] = useState({});
  const [csatStartDate, setCsatStartDate] = useState(daysFromNow);
  const [csatEndDate, setCsatEndDate] = useState(today);
  const [showPercentage, setShowPercentage] = useState(false);

  useEffect(() => {
    dispatch(getFirmChannelsAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // API Calls
  useEffect(() => {
    if (generalParams?.toDate) {
      dispatch(
        getConversationsInsightsAction({
          url: "all",
          params: generalParams,
          section: "allConversations",
        })
      );
      dispatch(
        getConversationsInsightsAction({
          url: "all",
          params: { ...generalParams, status: "open" },
          section: "openedConversations",
        })
      );
      dispatch(
        getConversationsInsightsAction({
          url: "all",
          params: { ...generalParams, status: "closed" },
          section: "closedConversations",
        })
      );
      dispatch(
        getConversationsInsightsAction({
          url: "all",
          params: { ...generalParams, status: "in-progress" },
          section: "convosInProgress",
        })
      );
      dispatch(
        getConversationsInsightsAction({
          url: "replied",
          params: generalParams,
          section: "convosRepliedTo",
        })
      );
      dispatch(
        getConversationsInsightsAction({
          url: "awaiting-response",
          params: generalParams,
          section: "awaitingResponse",
        })
      );
      dispatch(
        getConversationsInsightsAction({
          url: "busiest-period",
          params: generalParams,
          section: "busiestPeriod",
        })
      );
      dispatch(
        getConversationsInsightsAction({
          url: "csat",
          params: generalParams,
          section: "csatRating",
        })
      );

      getNewConvosData(generalParams);
      getConvoByChannelData(generalParams);
      getCsatData(generalParams);
      getConvoRepliedToData(generalParams);
      getConvoStatusData(generalParams);
      getConvoTagsData(generalParams);
      getResponseTimeData(generalParams);
      getResolutionTimeData(generalParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generalParams]);

  const getNewConvosData = (params) => {
    dispatch(
      getConversationsInsightsAction({
        url: "all/breakdown",
        params,
        load: false,
        section: "newConvosData",
      })
    );
  };
  useEffect(() => {
    if (newConvoParams?.toDate) getNewConvosData(newConvoParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newConvoParams]);

  const getConvoByChannelData = (params) => {
    dispatch(
      getConversationsInsightsAction({
        url: "all/channel",
        params,
        load: false,
        section: "channelConvosData",
      })
    );
  };
  useEffect(() => {
    if (newConvoChannelParams?.toDate)
      getConvoByChannelData(newConvoChannelParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newConvoChannelParams]);

  const getDailyStatsConvoData = (params) => {
    dispatch(
      getConversationsInsightsAction({
        url: "all/breakdown/channel",
        params,
        load: false,
        section: "dailyStatsConvoData",
      })
    );
  };
  useEffect(() => {
    if (dailyStatsParams?.toDate) getDailyStatsConvoData(dailyStatsParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dailyStatsParams]);

  const getConvoRepliedToData = (params) => {
    dispatch(
      getConversationsInsightsAction({
        url: "all/breakdown/replied",
        params,
        load: false,
        section: "newVsRepliedConvosData",
      })
    );
  };
  useEffect(() => {
    if (newVsRepliedParams?.toDate) getConvoRepliedToData(newVsRepliedParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newVsRepliedParams]);

  const getConvoStatusData = (params) => {
    dispatch(
      getConversationsInsightsAction({
        url: "all/status",
        params,
        load: false,
        section: "convosStatusData",
      })
    );
  };
  useEffect(() => {
    if (convoStatusParams?.toDate) getConvoStatusData(convoStatusParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [convoStatusParams]);

  const getConvoTagsData = (params) => {
    dispatch(getConversationTagsInsightsAction({ params })).then((res) =>
      setConvosTagsData(res)
    );
  };
  useEffect(() => {
    if (convoTagsParams?.toDate) getConvoTagsData(convoTagsParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [convoTagsParams]);

  const getResponseTimeData = (params) => {
    dispatch(
      getConversationsInsightsAction({
        url: "conversations/response-time",
        params,
        load: false,
        section: "responsesData",
      })
    );
  };
  useEffect(() => {
    if (responsesParams?.toDate) getResponseTimeData(responsesParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responsesParams]);

  const getResolutionTimeData = (params) => {
    dispatch(
      getConversationsInsightsAction({
        url: "conversations/resolution-time",
        params,
        load: false,
        section: "resolutionData",
      })
    );
  };
  useEffect(() => {
    if (resolutionParams?.toDate) getResolutionTimeData(resolutionParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resolutionParams]);

  const getCsatData = (params) => {
    dispatch(
      getConversationsInsightsAction({
        url: "csat/breakdown",
        params,
        load: false,
        section: "csatData",
      })
    );
  };
  useEffect(() => {
    if (csatParams?.toDate) getCsatData(csatParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [csatParams]);

  const overviewStatsAnalytics = [
    {
      label: "All Conversations",
      info: "New conversations created in the specified period",
      lastRange: allConversations?.lastRange,
      thisRange: allConversations?.thisRange,
      change: allConversations?.change,
      time: null,
    },
    {
      label: "Opened Conversations",
      info: "Assigned conversations that have not been responded to",
      lastRange: openedConversations?.lastRange,
      thisRange: openedConversations?.thisRange,
      change: openedConversations?.change,
      time: null,
    },
    {
      label: "Closed Conversations",
      info: "Conversations resolved in the period",
      lastRange: closedConversations?.lastRange,
      thisRange: closedConversations?.thisRange,
      change: closedConversations?.change,
      time: null,
    },
    {
      label: "Conversations in progress",
      info: "Assigned conversations that are ongoing",
      lastRange: convosInProgress?.lastRange,
      thisRange: convosInProgress?.thisRange,
      change: convosInProgress?.change,
      time: null,
    },
    {
      label: "Conversations replied to",
      info: "Conversations that have been replied to",
      lastRange: convosRepliedTo?.lastRange,
      thisRange: convosRepliedTo?.thisRange,
      change: convosRepliedTo?.change,
      time: null,
    },
    {
      label: "Awaiting Response",
      info: "Assigned conversations that are awaiting a response from the customer",
      lastRange: awaitingResponse?.lastRange,
      thisRange: awaitingResponse?.thisRange,
      change: awaitingResponse?.change,
      time: null,
    },
    {
      label: "Busiest Period",
      info: "The day and time with the highest number of newly created conversations",
      lastRange:
        moment(busiestPeriod?.busiestPeriod?.time).format(
          "DD MMM, YYYY | LT"
        ) || " -- --",
      thisRange:
        moment(busiestPeriod?.busiestPeriod?.time).format("dddd") || "- - -",
      change: "",
      time: "time",
    },
    {
      label: "Average CSAT Rating",
      info: "The average rating from customers after conversations have been resolved",
      lastRange: csatRating?.lastRange,
      thisRange: csatRating?.thisRange,
      change: csatRating?.change,
      time: "time",
    },
  ];

  const datePickerDisplayValue = (sDate, eDate) => {
    return `${
      moment(sDate).format("MMM DD, YYYY") +
      " - " +
      `${
        eDate
          ? moment(eDate).format("MMM DD, YYYY")
          : moment(sDate).format("MMM DD, YYYY")
      }`
    }`;
  };

  const dateRangeIsNotSameDay = (sDate, eDate) => {
    if (eDate && moment(sDate).format("x") !== moment(eDate).format("x")) {
      return true;
    } else return true;
  };

  useEffect(() => {
    if (
      generalEndDate &&
      dateRangeIsNotSameDay(generalStartDate, generalEndDate)
    ) {
      setGeneralParams({
        fromDate: moment(generalStartDate).format("YYYY-MM-DD"),
        toDate: moment(generalEndDate).format("YYYY-MM-DD"),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generalEndDate]);

  useEffect(() => {
    if (
      newConvoEndDate &&
      dateRangeIsNotSameDay(newConvoStartDate, newConvoEndDate)
    ) {
      setNewConvoParams({
        fromDate: moment(newConvoStartDate).format("YYYY-MM-DD"),
        toDate: moment(newConvoEndDate).format("YYYY-MM-DD"),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newConvoEndDate]);

  useEffect(() => {
    if (
      newConvoChannelEndDate &&
      dateRangeIsNotSameDay(newConvoChannelStartDate, newConvoChannelEndDate)
    ) {
      setNewConvoChannelParams({
        fromDate: moment(newConvoChannelStartDate).format("YYYY-MM-DD"),
        toDate: moment(newConvoChannelEndDate).format("YYYY-MM-DD"),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newConvoChannelEndDate]);

  useEffect(() => {
    if (
      dailyStatsConvoEndDate &&
      dateRangeIsNotSameDay(newVsRepliedStartDate, dailyStatsConvoEndDate)
    ) {
      setDailyStatsParams({
        fromDate: moment(dailyStatsConvoStartDate).format("YYYY-MM-DD"),
        toDate: moment(dailyStatsConvoEndDate).format("YYYY-MM-DD"),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dailyStatsConvoEndDate]);

  useEffect(() => {
    if (
      newVsRepliedEndDate &&
      dateRangeIsNotSameDay(newVsRepliedStartDate, newVsRepliedEndDate)
    ) {
      setNewVsRepliedParams({
        fromDate: moment(newVsRepliedStartDate).format("YYYY-MM-DD"),
        toDate: moment(newVsRepliedEndDate).format("YYYY-MM-DD"),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newVsRepliedEndDate]);

  useEffect(() => {
    if (
      convoStatusEndDate &&
      dateRangeIsNotSameDay(convoStatusStartDate, convoStatusEndDate)
    ) {
      setConvoStatusParams({
        fromDate: moment(convoStatusStartDate).format("YYYY-MM-DD"),
        toDate: moment(convoStatusEndDate).format("YYYY-MM-DD"),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [convoStatusEndDate]);

  useEffect(() => {
    if (
      convoTagsEndDate &&
      dateRangeIsNotSameDay(convoTagsStartDate, convoTagsEndDate)
    ) {
      setConvoTagsParams({
        fromDate: moment(convoTagsStartDate).format("YYYY-MM-DD"),
        toDate: moment(convoTagsEndDate).format("YYYY-MM-DD"),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [convoTagsEndDate]);

  useEffect(() => {
    if (
      responsesEndDate &&
      dateRangeIsNotSameDay(responsesStartDate, responsesEndDate)
    ) {
      setResponsesParams({
        fromDate: moment(responsesStartDate).format("YYYY-MM-DD"),
        toDate: moment(responsesEndDate).format("YYYY-MM-DD"),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responsesEndDate]);

  useEffect(() => {
    if (
      resolutionEndDate &&
      dateRangeIsNotSameDay(resolutionStartDate, resolutionEndDate)
    ) {
      setResolutionParams({
        fromDate: moment(resolutionStartDate).format("YYYY-MM-DD"),
        toDate: moment(resolutionEndDate).format("YYYY-MM-DD"),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resolutionEndDate]);

  useEffect(() => {
    if (csatEndDate && dateRangeIsNotSameDay(csatStartDate, csatEndDate)) {
      setCsatParams({
        fromDate: moment(csatStartDate).format("YYYY-MM-DD"),
        toDate: moment(csatEndDate).format("YYYY-MM-DD"),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [csatEndDate]);

  const newConversationsChartData = {
    labels: newConvosData?.breakdown?.map((x) => x?.day) || [],
    datasets: [
      {
        label: "",
        lineTension: 0,
        fill: false,
        borderColor: "#AEDEFF",
        pointBorderColor: "#18A0FB",
        pointBackgroundColor: "#18A0FB",
        pointBorderWidth: 1,
        pointRadius: 4,
        pointHoverBackgroundColor: "#AEDEFF",
        pointHoverBorderColor: "#AEDEFF",
        pointHoverBorderWidth: 1,
        pointHoverRadius: 4,
        pointHitRadius: 4,
        data: newConvosData?.breakdown?.map((x) => x?.count) || [],
      },
    ],
    options: {
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false,
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              padding: 15,
              maxTicksLimit: 8,
            },
            gridLines: {
              drawBorder: false,
            },
          },
        ],
      },
    },
  };

  const channelsChartData = {
    labels: channelConvosData?.breakdown?.map((x) => x?.medium) || [],
    datasets: [
      {
        data:
          channelConvosData?.breakdown?.map((x) =>
            showPercentage ? x?.percentage : x?.count
          ) || [],
        backgroundColor: [
          "#4CAF50",
          "#18A0FB",
          "#F97272",
          "#4BA3E7",
          "#F686CE",
        ],
        borderWidth: 2,
      },
    ],
    options: {
      maintainAspectRatio: false,
      legend: {
        position: "bottom",
        align: "center",
        labels: {
          padding: 15,
          boxWidth: 12,
          boxHeight: 12,
        },
      },
    },
  };

  const dailyStatsConvoChartData = {
    labels: dailyStatsConvoData?.breakdown?.map((x) => x?.day) || [],
    datasets: [
      {
        fill: false,
        label: "Email",
        data: dailyStatsConvoData?.breakdown?.map((x) => x?.email) || [],
        borderColor: "#F7BE1F",
      },
      {
        fill: false,
        label: "Facebook",
        data: dailyStatsConvoData?.breakdown?.map((x) => x?.facebook) || [],
        borderColor: "#18A0FB",
      },
      {
        fill: false,
        label: "Instagram",
        data: dailyStatsConvoData?.breakdown?.map((x) => x?.instagram) || [],
        borderColor: "#F97272",
      },
      {
        fill: false,
        label: "SMS",
        data: dailyStatsConvoData?.breakdown?.map((x) => x?.sms) || [],
        borderColor: "#E15435",
      },
      {
        fill: false,
        label: "Twitter",
        data: dailyStatsConvoData?.breakdown?.map((x) => x?.twitter) || [],
        borderColor: "#F686CE",
      },
      {
        fill: false,
        label: "Whatsapp",
        data: dailyStatsConvoData?.breakdown?.map((x) => x?.whatsapp) || [],
        borderColor: "#4CAF50",
      },
      {
        fill: false,
        label: "Widget",
        data: dailyStatsConvoData?.breakdown?.map((x) => x?.widget) || [],
        borderColor: "#35D7E1",
      },
    ],
    options: {
      maintainAspectRatio: false,
      legend: {
        align: "center",
        labels: {
          boxWidth: 14,
          boxHeight: 14,
        },
      },
      scales: {
        yAxes: [
          {
            stacked: true,
            ticks: {
              padding: 15,
              maxTicksLimit: 8,
              beginAtZero: true,
            },
          },
        ],
        xAxes: [
          {
            stacked: true,
            gridLines: {
              display: false,
            },
          },
        ],
      },
    },
  };

  const csatChartData = {
    labels: ["Unsatisfactory", "Fair", "Good", "Very good", "Excellent"],
    datasets: [
      {
        data:
          csatData?.breakdown?.map((x) =>
            showPercentage ? x?.percentage : x?.count
          ) || [],
        backgroundColor: [
          "#FF4E11",
          "#FF8E15",
          "#FAB733",
          "#ACB334",
          "#69B34C",
        ],
        borderWidth: 2,
      },
    ],
    options: {
      maintainAspectRatio: false,
      legend: {
        position: "chartArea",
        align: "center",
        labels: {
          padding: 25,
          boxWidth: 14,
          boxHeight: 14,
        },
      },
    },
  };

  const newVsRepliedConversationsChartData = {
    labels: newVsRepliedConvosData?.breakdown?.map((x) => x?.day) || [],
    datasets: [
      {
        label: "New Conversations",
        data: newVsRepliedConvosData?.breakdown?.map((x) => x?.total) || [],
        backgroundColor: "#63BFFD",
      },
      {
        label: "Conversations replied to",
        data: newVsRepliedConvosData?.breakdown?.map((x) => x?.replied) || [],
        backgroundColor: "#F97272",
      },
    ],
    options: {
      maintainAspectRatio: false,
      legend: {
        align: "center",
        labels: {
          boxWidth: 14,
          boxHeight: 14,
        },
      },
      scales: {
        yAxes: [
          {
            ticks: {
              padding: 15,
              maxTicksLimit: 8,
              beginAtZero: true,
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              display: false,
            },
          },
        ],
      },
    },
  };

  const conversationStatusChartData = {
    labels: convosStatusData?.breakdown?.map((x) => x?.status) || [],
    datasets: [
      {
        data:
          convosStatusData?.breakdown?.map((x) =>
            showPercentage ? x?.percentage : x?.count
          ) || [],
        backgroundColor: [
          "#F7BE1F",
          "#26A8FF",
          "#0FD275",
          "#35D7E1",
          "#E135BB",
          "#E15435",
        ],
        borderWidth: 2,
      },
    ],
    options: {
      maintainAspectRatio: false,
      legend: {
        position: "chartArea",
        align: "center",
        labels: {
          padding: 25,
          boxWidth: 14,
          boxHeight: 14,
        },
      },
    },
  };

  const conversationTagsChartData = {
    labels: convosTagsData?.tags?.map((x) => x?.name) || [],
    datasets: [
      {
        data:
          convosTagsData?.tags?.map((x) =>
            showPercentage ? x?.percentage : x?.conversationsCount
          ) || [],
        backgroundColor: convosTagsData?.tags?.map((x) => x?.colorCode) || [],
        borderWidth: 2,
      },
    ],
    options: {
      maintainAspectRatio: false,
      legend: {
        position: "chartArea",
        align: "center",
        labels: {
          padding: 25,
          boxWidth: 14,
          boxHeight: 14,
        },
      },
    },
  };

  const responsesChartData = {
    labels: responsesData?.thisRange?.daysBreakdown?.map((x) => x?.day) || [],
    datasets: [
      {
        label: "Current period",
        data:
          responsesData?.thisRange?.daysBreakdown?.map((x) => x?.count) || [],
        backgroundColor: "#63BFFD",
      },
      {
        label: "Last period",
        data:
          responsesData?.lastRange?.daysBreakdown?.map((x) => x?.count) || [],
        backgroundColor: "#7997AB",
      },
    ],
    options: {
      maintainAspectRatio: false,
      legend: {
        align: "center",
        labels: {
          boxWidth: 14,
          boxHeight: 14,
        },
      },
      scales: {
        yAxes: [
          {
            ticks: {
              padding: 15,
              maxTicksLimit: 8,
              beginAtZero: true,
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              display: false,
            },
          },
        ],
      },
    },
  };

  const resolutionChartData = {
    labels: resolutionData?.thisRange?.daysBreakdown?.map((x) => x?.day) || [],
    datasets: [
      {
        label: "Current period",
        data:
          resolutionData?.thisRange?.daysBreakdown?.map((x) => x?.count) || [],
        backgroundColor: "#63BFFD",
      },
      {
        label: "Last period",
        data:
          resolutionData?.lastRange?.daysBreakdown?.map((x) => x?.count) || [],
        backgroundColor: "#7997AB",
      },
    ],
    options: {
      maintainAspectRatio: false,
      legend: {
        align: "center",
        labels: {
          boxWidth: 14,
          boxHeight: 14,
        },
      },
      scales: {
        yAxes: [
          {
            ticks: {
              padding: 15,
              maxTicksLimit: 8,
              beginAtZero: true,
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              display: false,
            },
          },
        ],
      },
    },
  };

  const responsesAndResolution = [
    {
      title: "Responses",
      first_time: {
        label: "First Response Time",
        info: "For all conversations, the average time it takes to get the first response",
        index: "res_first_time",
        value:
          secondsToHms(responsesData?.thisRange?.firstResponseTime) || "- - -",
        change: responsesData?.change?.firstResponseTime,
      },
      average_time: {
        label: "Avg. Response Time",
        info: "For all conversations, the average time it takes to get responses during a conversation",
        index: "res_avg_time",
        value:
          secondsToHms(responsesData?.thisRange?.averageResponseTime) ||
          "- - -",
        change: responsesData?.change?.averageResponseTime,
      },
      time_breakdown: {
        label: "Avg. Response Time breakdown",
        info: "Distribution of average response times by timeframe",
        index: "res_time_breakdown",
        breakdown: responsesData?.groupedResponseTimes?.percentage,
      },
      average_time_values: {
        label: "Avg. Response Time",
        info: "A daily trend of the average response time",
        index: "res_avg_time_values",
      },
      chartData: responsesChartData,
    },
    {
      title: "Resolution",
      first_time: {
        label: "Resolution On First Contact",
        info: "Number of conversations that were resolved same day that they were created.",
        index: "reso_first_time",
        value: resolutionData?.thisRange?.resolutionOnFirstContact || "- - -",
        change: resolutionData?.change?.resolutionOnFirstContact,
      },
      average_time: {
        label: "Avg. Resolution Time",
        info: "The average time between when a conversation is created and when it is resolved.",
        index: "reso_avg_time",
        value:
          secondsToHms(resolutionData?.thisRange?.averageResolutionTime) ||
          "- - -",
        change: resolutionData?.change?.averageResponseTime,
      },
      time_breakdown: {
        label: "Avg. Resolution Time Breakdown",
        info: "Distribution of average resolution time by timeframe",
        index: "res_time_breakdown",
        breakdown: resolutionData?.groupedResolutionTimes?.percentage,
      },
      average_time_values: {
        label: "Resolution Time",
        info: "A daily trend of the average resolution time",
        index: "res_avg_time_values",
      },
      chartData: resolutionChartData,
    },
  ];

  const datePickerConfig = {
    className: "date_range_input",
    dateFormat: "yyyy-MM-dd",
    // minDate: new Date(moment().subtract(120, "days")),
    maxDate: new Date(),
    selectsRange: "selectsRange",
    shouldCloseOnSelect: false,
  };

  const channelOptions = firmChannelsData
    ?.filter((x) => x.status === "ACTIVE")
    ?.map((x) => ({
      label: `(${x?.medium}) - ${x?.name}`,
      value: x?.id,
    }));

  return (
    <div className="insights_page_container_new">
      <h5 className="page_title">Conversations</h5>

      <div className="filter_container">
        <div className="date_range_picker">
          <Calendar />
          <DatePicker
            onChange={([start, end]) => {
              setGeneralStartDate(start);
              setGeneralEndDate(end);
            }}
            startDate={generalStartDate}
            endDate={generalEndDate}
            value={datePickerDisplayValue(generalStartDate, generalEndDate)}
            {...datePickerConfig}
          />
        </div>
        <Select
          classNamePrefix="select_container"
          value={selectedChannel}
          options={channelOptions}
          onChange={(selected) => {
            setSelectedChannel(selected);
            setGeneralParams({ ...generalParams, channel_id: selected?.value });
          }}
          placeholder="Filter by channel..."
          isDisabled={isLoadingConversationsInsights}
          isClearable
        />
      </div>

      {isLoadingConversationsInsights && !allConversations?.from ? (
        <div className="loading">Loading...</div>
      ) : (
        <>
          <div className="stats_container">
            {overviewStatsAnalytics?.map((item, i) => (
              <div className="stat_item" key={i}>
                <h6 className="title_with_icon">
                  {item?.label}
                  <span
                    onMouseOver={() =>
                      setShowInfo({
                        index: i,
                      })
                    }
                    onMouseLeave={() => setShowInfo({})}
                  >
                    <Info />
                  </span>
                  {showInfo?.index === i && (
                    <div className="info_popup_container">{item.info}</div>
                  )}
                </h6>
                <h5 className="value">{item?.thisRange}</h5>
                <div
                  className={`changes ${item?.change >= 0 ? "increment" : ""}`}
                >
                  {!item?.time && <ArrowUp />}
                  {!item?.time && <p>{item?.change}%</p>}
                  <span>{item?.time ? item?.lastRange : "vs Last period"}</span>
                </div>
              </div>
            ))}
          </div>

          <div className="new_conversation_and_channels_charts">
            <div className="left">
              <div className="stat_item">
                <h6 className="title_with_icon">
                  New Conversations
                  <span
                    onMouseOver={() =>
                      setShowInfo({
                        index: "new_convo",
                      })
                    }
                    onMouseLeave={() => setShowInfo({})}
                  >
                    <Info />
                  </span>
                  {showInfo?.index === "new_convo" && (
                    <div className="info_popup_container">
                      Newly created conversations in the period
                    </div>
                  )}
                </h6>
                <div
                  className={`changes ${
                    newConvosData?.change >= 0 ? "increment" : ""
                  }`}
                >
                  <h5 className="value">{newConvosData?.thisRange}</h5>
                  {!newConvosData?.time && <ArrowUp />}
                  {!newConvosData?.time && <p>{newConvosData?.change}%</p>}
                  <span>vs Last period</span>
                </div>
              </div>
              <div className="chart">
                <Line
                  data={newConversationsChartData}
                  options={newConversationsChartData.options}
                />
              </div>
              <div className="chart_filter">
                <div className="date_range_picker">
                  <Calendar />
                  <DatePicker
                    onChange={([start, end]) => {
                      setNewConvoStartDate(start);
                      setNewConvoEndDate(end);
                    }}
                    startDate={newConvoStartDate}
                    endDate={newConvoEndDate}
                    value={datePickerDisplayValue(
                      newConvoStartDate,
                      newConvoEndDate
                    )}
                    {...datePickerConfig}
                  />
                </div>
              </div>
            </div>
            <div className="right">
              <p className="title_with_icon">
                New conversations by channel
                <span
                  onMouseOver={() =>
                    setShowInfo({
                      index: "new_convo_by_channel",
                    })
                  }
                  onMouseLeave={() => setShowInfo({})}
                >
                  <Info />
                </span>
                {showInfo?.index === "new_convo_by_channel" && (
                  <div className="info_popup_container">
                    Distribution of channels across all conversations
                  </div>
                )}
              </p>
              <div className="percentage_toggle">
                <div
                  className={`switch_toggler ${showPercentage ? "active" : ""}`}
                  onClick={() => setShowPercentage(!showPercentage)}
                >
                  <div className="side"></div>
                  <div className="side"></div>
                </div>
                <p>Show Percentages</p>
              </div>

              <div className="chart">
                <Pie
                  data={channelsChartData}
                  options={channelsChartData.options}
                />
              </div>
              <div className="chart_filter">
                <div className="date_range_picker">
                  <Calendar />
                  <DatePicker
                    onChange={([start, end]) => {
                      setNewConvoChannelStartDate(start);
                      setNewConvoChannelEndDate(end);
                    }}
                    startDate={newConvoChannelStartDate}
                    endDate={newConvoChannelEndDate}
                    value={datePickerDisplayValue(
                      newConvoChannelStartDate,
                      newConvoChannelEndDate
                    )}
                    {...datePickerConfig}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="new_conversation_vs_conversations_replied">
            <p className="title_with_icon">
              Daily Stats - New conversations by channel
              <span
                onMouseOver={() =>
                  setShowInfo({
                    index: "daily_stats_new_convo",
                  })
                }
                onMouseLeave={() => setShowInfo({})}
              >
                <Info />
              </span>
              {showInfo?.index === "daily_stats_new_convo" && (
                <div className="info_popup_container">
                  Daily distribution of channels across all conversations
                </div>
              )}
            </p>

            <div className="chart">
              <Line
                data={dailyStatsConvoChartData}
                options={dailyStatsConvoChartData.options}
              />
            </div>
            <div className="chart_filter">
              <div className="date_range_picker">
                <Calendar />
                <DatePicker
                  onChange={([start, end]) => {
                    setDailyStatsConvoStartDate(start);
                    setDailyStatsConvoEndDate(end);
                  }}
                  startDate={dailyStatsConvoStartDate}
                  endDate={dailyStatsConvoEndDate}
                  value={datePickerDisplayValue(
                    dailyStatsConvoStartDate,
                    dailyStatsConvoEndDate
                  )}
                  {...datePickerConfig}
                />
              </div>
            </div>
          </div>

          <div className="conversations_status">
            <div className="d_flex">
              <p className="title_with_icon">
                CSAT
                <span
                  onMouseOver={() =>
                    setShowInfo({
                      index: "csat",
                    })
                  }
                  onMouseLeave={() => setShowInfo({})}
                >
                  <Info />
                </span>
                {showInfo?.index === "csat" && (
                  <div className="info_popup_container">
                    Distribution of CSAT ratings in the period
                  </div>
                )}
              </p>
              <div className="percentage_toggle">
                <div
                  className={`switch_toggler ${showPercentage ? "active" : ""}`}
                  onClick={() => setShowPercentage(!showPercentage)}
                >
                  <div className="side"></div>
                  <div className="side"></div>
                </div>
                <p>Show Percentages</p>
              </div>
            </div>

            <div className="chart">
              <Pie data={csatChartData} options={csatChartData.options} />
            </div>
            <div className="chart_filter">
              <div className="date_range_picker">
                <Calendar />
                <DatePicker
                  onChange={([start, end]) => {
                    setCsatStartDate(start);
                    setCsatEndDate(end);
                  }}
                  startDate={csatStartDate}
                  endDate={csatEndDate}
                  value={datePickerDisplayValue(csatStartDate, csatEndDate)}
                  {...datePickerConfig}
                />
              </div>
            </div>
          </div>

          <div className="new_conversation_vs_conversations_replied">
            <p className="title_with_icon">
              New Conversations vs Conversations Replied To
              <span
                onMouseOver={() =>
                  setShowInfo({
                    index: "new_convo_vs_replied_to",
                  })
                }
                onMouseLeave={() => setShowInfo({})}
              >
                <Info />
              </span>
              {showInfo?.index === "new_convo_vs_replied_to" && (
                <div className="info_popup_container">
                  Total conversations vs conversations that were responded to by
                  an agent
                </div>
              )}
            </p>

            <div className="chart">
              <Bar
                data={newVsRepliedConversationsChartData}
                options={newVsRepliedConversationsChartData.options}
              />
            </div>
            <div className="chart_filter">
              <div className="date_range_picker">
                <Calendar />
                <DatePicker
                  onChange={([start, end]) => {
                    setNewVsRepliedStartDate(start);
                    setNewVsRepliedEndDate(end);
                  }}
                  startDate={newVsRepliedStartDate}
                  endDate={newVsRepliedEndDate}
                  value={datePickerDisplayValue(
                    newVsRepliedStartDate,
                    newVsRepliedEndDate
                  )}
                  {...datePickerConfig}
                />
              </div>
            </div>
          </div>

          <div className="conversations_status">
            <div className="d_flex">
              <p className="title_with_icon">
                Conversation Status
                <span
                  onMouseOver={() =>
                    setShowInfo({
                      index: "convo_status",
                    })
                  }
                  onMouseLeave={() => setShowInfo({})}
                >
                  <Info />
                </span>
                {showInfo?.index === "convo_status" && (
                  <div className="info_popup_container">
                    Distribution of conversations by status in the period
                  </div>
                )}
              </p>
              <div className="percentage_toggle">
                <div
                  className={`switch_toggler ${showPercentage ? "active" : ""}`}
                  onClick={() => setShowPercentage(!showPercentage)}
                >
                  <div className="side"></div>
                  <div className="side"></div>
                </div>
                <p>Show Percentages</p>
              </div>
            </div>

            <div className="chart">
              <Pie
                data={conversationStatusChartData}
                options={conversationStatusChartData.options}
              />
            </div>
            <div className="chart_filter">
              <div className="date_range_picker">
                <Calendar />
                <DatePicker
                  onChange={([start, end]) => {
                    setConvoStatusStartDate(start);
                    setConvoStatusEndDate(end);
                  }}
                  startDate={convoStatusStartDate}
                  endDate={convoStatusEndDate}
                  value={datePickerDisplayValue(
                    convoStatusStartDate,
                    convoStatusEndDate
                  )}
                  {...datePickerConfig}
                />
              </div>
            </div>
          </div>

          <div className="conversations_status">
            <div className="d_flex">
              <p className="title_with_icon">
                Conversation Tags
                <span
                  onMouseOver={() =>
                    setShowInfo({
                      index: "convo_tags",
                    })
                  }
                  onMouseLeave={() => setShowInfo({})}
                >
                  <Info />
                </span>
                {showInfo?.index === "convo_tags" && (
                  <div className="info_popup_container">
                    Distribution of conversations by tags in the period
                  </div>
                )}
              </p>
              <div className="percentage_toggle">
                <div
                  className={`switch_toggler ${showPercentage ? "active" : ""}`}
                  onClick={() => setShowPercentage(!showPercentage)}
                >
                  <div className="side"></div>
                  <div className="side"></div>
                </div>
                <p>Show Percentages</p>
              </div>
            </div>

            <div className="chart">
              <Pie
                data={conversationTagsChartData}
                options={conversationTagsChartData.options}
              />
            </div>
            <div className="chart_filter">
              <div className="date_range_picker">
                <Calendar />
                <DatePicker
                  onChange={([start, end]) => {
                    setConvoTagsStartDate(start);
                    setConvoTagsEndDate(end);
                  }}
                  startDate={convoTagsStartDate}
                  endDate={convoTagsEndDate}
                  value={datePickerDisplayValue(
                    convoTagsStartDate,
                    convoTagsEndDate
                  )}
                  {...datePickerConfig}
                />
              </div>
            </div>
          </div>

          <div className="responses_and_resolution">
            {responsesAndResolution?.map((item, i) => (
              <React.Fragment key={i}>
                <p className="section_title">{item?.title}</p>
                <div className="responses_container">
                  <div className="left">
                    <div className="stat_item">
                      <h6 className="title_with_icon">
                        {item?.first_time?.label}
                        <span
                          onMouseOver={() =>
                            setShowInfo({
                              index: item?.first_time?.index,
                            })
                          }
                          onMouseLeave={() => setShowInfo({})}
                        >
                          <Info />
                        </span>
                        {showInfo?.index === item?.first_time?.index && (
                          <div className="info_popup_container">
                            {item?.first_time?.info}
                          </div>
                        )}
                      </h6>
                      <h5 className="value">{item?.first_time?.value}</h5>
                      <div
                        className={`changes ${
                          item?.first_time?.change >= 0 ? "increment" : ""
                        }`}
                      >
                        <ArrowUp />
                        <p>{item?.first_time?.change}%</p>
                        <span>vs Last period</span>
                      </div>
                    </div>

                    <div className="stat_item">
                      <h6 className="title_with_icon">
                        {item?.average_time?.label}
                        <span
                          onMouseOver={() =>
                            setShowInfo({
                              index: item?.average_time?.index,
                            })
                          }
                          onMouseLeave={() => setShowInfo({})}
                        >
                          <Info />
                        </span>
                        {showInfo?.index === item?.average_time?.index && (
                          <div className="info_popup_container">
                            {item?.average_time?.info}
                          </div>
                        )}
                      </h6>
                      <h5 className="value">{item?.average_time?.value}</h5>
                      <div
                        className={`changes ${
                          item?.average_time?.change >= 0 ? "increment" : ""
                        }`}
                      >
                        <ArrowUp />
                        <p>{item?.average_time?.change}%</p>
                        <span>vs Last period</span>
                      </div>
                    </div>

                    <div className="stat_item">
                      <h6 className="title_with_icon">
                        {item?.time_breakdown?.label}
                        <span
                          onMouseOver={() =>
                            setShowInfo({
                              index: item?.time_breakdown?.index,
                            })
                          }
                          onMouseLeave={() => setShowInfo({})}
                        >
                          <Info />
                        </span>
                        {showInfo?.index === item?.time_breakdown?.index && (
                          <div className="info_popup_container">
                            {item?.time_breakdown?.info}
                          </div>
                        )}
                      </h6>
                      <div className="breakdown">
                        {item?.time_breakdown?.breakdown?.map(
                          ({ key, percentage }, i) => (
                            <div className="item" key={i}>
                              <p className="figures">
                                {key}
                                <span>
                                  {percentage !== "NaN" ? percentage : 0}%
                                </span>
                              </p>
                              <div className="progress_bar">
                                <span
                                  style={{
                                    width: `${
                                      ((percentage !== "NaN" ? percentage : 0) /
                                        100) *
                                      100
                                    }%`,
                                  }}
                                ></span>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="right">
                    <p className="title_with_icon">
                      {item?.average_time_values?.label}
                      <span
                        onMouseOver={() =>
                          setShowInfo({
                            index: item?.average_time_values?.index,
                          })
                        }
                        onMouseLeave={() => setShowInfo({})}
                      >
                        <Info />
                      </span>
                      {showInfo?.index === item?.average_time_values?.index && (
                        <div className="info_popup_container">
                          {item?.average_time_values?.info}
                        </div>
                      )}
                    </p>
                    <div className="chart">
                      <Bar
                        data={item?.chartData}
                        options={item?.chartData?.options}
                      />
                    </div>
                    <div className="chart_filter">
                      <div className="date_range_picker">
                        <Calendar />
                        {item?.title?.toLowerCase() === "responses" ? (
                          <DatePicker
                            onChange={([start, end]) => {
                              setResponsesStartDate(start);
                              setResponsesEndDate(end);
                            }}
                            startDate={responsesStartDate}
                            endDate={responsesEndDate}
                            value={datePickerDisplayValue(
                              responsesStartDate,
                              responsesEndDate
                            )}
                            {...datePickerConfig}
                          />
                        ) : (
                          <DatePicker
                            onChange={([start, end]) => {
                              setResolutionStartDate(start);
                              setResolutionEndDate(end);
                            }}
                            startDate={resolutionStartDate}
                            endDate={resolutionEndDate}
                            value={datePickerDisplayValue(
                              resolutionStartDate,
                              resolutionEndDate
                            )}
                            {...datePickerConfig}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default ConversationInsights;
