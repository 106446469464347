import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import {
  fetchConversationCsatStatus,
  submitCsatScore,
} from "../../redux/actions/Auth";

import { Button } from "../../components/FormElements";

import { OnerouteLogo } from "../../assets/vectors";

const Csat = () => {
  let { id } = useParams();
  const dispatch = useDispatch();

  const [currentView, setCurrentView] = useState(1);
  const [csatInfo, setCsatInfo] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    dispatch(fetchConversationCsatStatus(id)).then((res) => {
      if (res) {
        setCsatInfo(res || {});
      } else {
        setCsatInfo({
          response: "You have previously submitted your feedback. Thanks",
        });
        setCurrentView(2);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitForm = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const payload = {
      csatScore: formData?.rating,
      customerFeedback: formData?.feedback,
    };

    dispatch(
      submitCsatScore({
        id,
        data: payload,
      })
    ).then((res) => {
      if (res?.success === true) {
        setIsSubmitting(false);
        setCurrentView(2);
      }
    });
  };

  const ratings = [
    { label: <>&#128542;</>, text: "Unsatisfactory", value: 1 },
    { label: <>&#128577;</>, text: "Fair", value: 2 },
    { label: <>&#128528;</>, text: "Good", value: 3 },
    { label: <>&#128578;</>, text: "Very good", value: 4 },
    { label: <>&#128512;</>, text: "Excellent", value: 5 },
  ];

  return (
    <div className="csat_container">
      <div className="content">
        <div className="logo">
          {csatInfo?.logoUrl ? (
            <img src={csatInfo?.logoUrl} alt="" />
          ) : (
            <p>{csatInfo?.name}</p>
          )}
        </div>

        {currentView === 1 ? (
          <>
            <h5>{csatInfo?.question}</h5>

            <div className="ratings">
              {ratings?.map((rating, i) => (
                <div
                  key={i}
                  className={`rating${
                    formData?.rating === rating?.value ? " active" : ""
                  }`}
                  onClick={() =>
                    setFormData({ ...formData, rating: rating?.value })
                  }
                >
                  <p>{rating?.label}</p>
                  <h6>{rating?.text}</h6>
                </div>
              ))}
            </div>

            <div className="form_control">
              <label>Your personal feedback (optional)</label>
              <textarea
                rows="4"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    feedback: e?.target?.value,
                  })
                }
              />
              <Button
                disabled={!formData?.rating || isSubmitting}
                className="primary-button"
                onClick={submitForm}
              >
                {isSubmitting ? "Submitting..." : "Submit feedback"}
              </Button>
            </div>
          </>
        ) : (
          <>
            <h5>{csatInfo?.response}</h5>
          </>
        )}

        <div className="powered_by">
          <a
            href="https://oneroute.io"
            target="_blank"
            rel="noopener noreferrer"
          >
            Powered by <OnerouteLogo />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Csat;
