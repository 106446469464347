import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { Button, TextField } from "../../components/FormElements";
import FormNotification from "../../components/Notifications/FormNotification";
import Carousel from "./Carousel";

import { loginAction } from "../../redux/actions/Auth";

const LoginPage = ({ setActiveForm }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { isLoggingIn, loginOutcome, loginMessage } = useSelector(
    (state) => state.auth
  );

  const [formData, setFormData] = useState({});
  const [showFormRes, setShowFormRes] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const formIsValid = () => {
    if (formData?.email && formData?.password) {
      return true;
    }

    return false;
  };

  const submitSignUpForm = (e) => {
    e.preventDefault();
    setShowFormRes(false);

    const payload = {
      email: formData?.email,
      password: formData?.password,
    };

    const lastVisitedUrl = window.localStorage.getItem("last_visited_url");

    dispatch(loginAction(payload)).then((res) => {
      if (res) {
        if (!res?.user?.firm_id) {
          window.location.assign("/sign-up?st=2");
        } else if (res?.user?.firm_onboarding < 3) {
          window.location.assign("/get-started");
        } else if (lastVisitedUrl) {
          localStorage.removeItem("last_visited_url");
          window.location.assign(lastVisitedUrl);
        } else {
          window.location.assign("/home");
        }
      } else {
        setShowFormRes(true);
      }
    });
  };

  return (
    <div className="register_page_container">
      <div className="side left_side">
        <Carousel />
      </div>

      <div className="side form_container">
        <p className="title">Login</p>
        <p className="sub_title">Proceed to login to your account</p>

        <form className="form" onSubmit={submitSignUpForm}>
          <TextField
            label="Email address"
            name="email"
            type="email"
            className="mb-20"
            placeholder="name@company.com"
            onChange={(e) => handleChange(e)}
          />
          <TextField
            label="Password"
            name="password"
            type="password"
            className="mb-20"
            onChange={(e) => handleChange(e)}
          />
          <div
            className="forgot-password"
            onClick={() => setActiveForm("forgot-password")}
          >
            Forgot password?
          </div>

          <Button
            disabled={formIsValid() === false || isLoggingIn}
            className="primary-button full small"
          >
            Login
          </Button>

          <div className="sign-up-link">
            Don't have an account?{" "}
            <span
              className="primary-link"
              onClick={() => {
                setActiveForm("sign-up");
                history.push(`/sign-up`);
              }}
            >
              Register
            </span>
          </div>

          {showFormRes && loginOutcome === "error" && (
            <>
              <br />
              <FormNotification type="error" message={loginMessage} />
            </>
          )}
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
