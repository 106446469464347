import React, { useState } from "react";
import { usePaystackPayment } from "react-paystack";

import { Spinner } from "../../../assets/vectors";
import { Button, TextField } from "../../../components/FormElements";
import Modal from "../../../components/Modal";

import { getUserDetails } from "../../../utils/functions";
import { APP_EVENT_TOPUP_WALLET } from "../../../utils/constants";

const TopUpWallet = ({ close, getUserProfile, getWalletHistory }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [amount, setAmount] = useState("");

  const { firstName, lastName, email, firm_id, firm_name } = getUserDetails();

  const paystackConfig = {
    reference: `wallet.${firm_id}.${new Date().getTime()}`,
    email: email,
    amount: amount * 100,
    currency: "NGN",
    publicKey: process.env.REACT_APP_PAYSTACK_KEY,
  };

  const onWalletTopUpSuccess = (res) => {
    if (res?.message === "Approved") {
      getUserProfile();
      getWalletHistory();
      setCurrentStep(2);
      setIsLoading(false);

      window.dataLayer.push({
        event: APP_EVENT_TOPUP_WALLET,
        agent_name: `${firstName} ${lastName}`,
        firm_name: firm_name,
        email: email,
        amount: amount,
        currency: "NGN",
      });
    }
  };

  const onPaymentClose = () => {
    console.log("closed");
  };

  const initializePayment = usePaystackPayment(paystackConfig);

  return (
    <React.Fragment>
      {currentStep === 1 && (
        <Modal className="channel-modal">
          <div className="modal-content live-chat-container">
            <div className="modal-title">Top-up Wallet</div>
            <div className="modal-form new-conversation-form">
              <h6>Complete the form below to top-up your wallet.</h6>
              <div className="form_field">
                <TextField
                  label="Amount (NGN)"
                  type="number"
                  name="amount"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  disabled={isLoading}
                />
              </div>
              <div className="actions">
                {!isLoading ? (
                  <>
                    <Button className="secondary-button" onClick={close}>
                      Cancel
                    </Button>
                    <Button
                      className="primary-button"
                      onClick={() =>
                        initializePayment(onWalletTopUpSuccess, onPaymentClose)
                      }
                      disabled={Number(amount).toFixed(0) < 1}
                    >
                      Proceed
                    </Button>
                  </>
                ) : (
                  <div className="spinner">
                    <Spinner />
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal>
      )}

      {currentStep === 2 && (
        <Modal className="channel-modal">
          <div className="modal-content new-conversation-container">
            <div className="modal-title">Top-up Wallet</div>
            <div className="modal-form new-conversation-form">
              <div className="d-flex">
                <div className="info">
                  <ul>
                    <li>Your wallet has been topped up successfully.</li>
                  </ul>
                </div>
              </div>
              <div className="actions">
                <Button className="primary-button" onClick={close}>
                  Dismiss
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default TopUpWallet;
