import React, { useState, useEffect } from "react";
import { withRouter, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  Spinner,
  Redirect,
  LogoSolid,
  Facebook,
} from "../../../../assets/vectors";
import { Button } from "../../../../components/FormElements";
import Modal from "../../../../components/Modal";

import { getWindowBaseUrl } from "../../../../utils/functions";

import {
  getMessengerRequestAction,
  postSubscribeToMessengerPageAction,
  getAllChannelsAction,
  getFirmChannelsAction,
} from "../../../../redux/actions/Channels";

const FacebookForm = ({ history, close, dismissRoute }) => {
  const dispatch = useDispatch();
  const { isCreatingChannel } = useSelector((state) => state?.channels);
  let location = useLocation();

  const [currentStep, setCurrentStep] = useState(1);
  const [messengerPages, setMessengerPages] = useState([]);
  const [selectedPage, setSelectedPage] = useState(null);

  const hash_url = "?" + location?.hash;
  const no_hash_url = hash_url.replace("#", "");
  const access_token =
    new URLSearchParams(no_hash_url).get("access_token") || null;

  useEffect(() => {
    if (access_token) getMessengerPages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [access_token]);

  const getMessengerRequestToken = () => {
    const windowBaseUrl = getWindowBaseUrl() || "";

    dispatch(
      getMessengerRequestAction({
        url: "/channels/messenger/oauth/request_token",
        params: {
          callbackUrl: `${windowBaseUrl}/2`,
        },
      })
    ).then((res) => {
      if (res?.data?.auth_url) {
        window.location.assign(res?.data?.auth_url);
      }
    });
  };

  const getMessengerPages = () => {
    dispatch(
      getMessengerRequestAction({
        url: "/channels/messenger/pages",
        params: {
          access_token,
        },
        stop_loader: true,
      })
    ).then((res) => {
      if (res?.data?.data) {
        setMessengerPages(res?.data?.data);
        setCurrentStep(2);
      }
    });
  };

  const postSubscribeToMessengerPage = () => {
    const requestData = selectedPage;

    dispatch(
      postSubscribeToMessengerPageAction({
        url: "/channels/messenger/subscribe",
        name: "FACEBOOK MESSENGER",
        requestData,
      })
    ).then((res) => {
      if (res?.status === 200) {
        dispatch(getAllChannelsAction());
        dispatch(getFirmChannelsAction());
        setCurrentStep(3);
      }
    });
  };

  return (
    <React.Fragment>
      {currentStep === 1 && (
        <Modal className="channel-modal">
          <div className="modal-content new-conversation-container">
            <div className="modal-title">
              You are about to be redirected to Facebook
            </div>
            <div className="modal-form new-conversation-form">
              <h6>
                Clicking continue will redirect you to Facebook. From there, you
                would need to authorize OneRoute to properly connect to your
                Facebook pages.
              </h6>
              <div className="logos">
                <LogoSolid />
                <Redirect />
                <img src={Facebook} alt="" />
              </div>
              <div className="actions">
                {!isCreatingChannel ? (
                  <>
                    <Button className="secondary-button" onClick={close}>
                      Back
                    </Button>
                    <Button
                      className="primary-button"
                      onClick={getMessengerRequestToken}
                    >
                      Continue with Facebook
                    </Button>
                  </>
                ) : (
                  <div className="spinner">
                    <Spinner />
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal>
      )}

      {currentStep === 2 && (
        <Modal className="channel-modal">
          <div className="modal-content new-conversation-container">
            <div className="modal-title">Connect Facebook Page</div>
            <div className="modal-form new-conversation-form">
              <h6>
                Choose the page you would like to use to communicate with your
                customers on OneRoute.
              </h6>
              <div className="facebook_pages">
                {messengerPages?.map((item, i) => (
                  <div
                    key={i}
                    className="page"
                    onClick={() => setSelectedPage(item)}
                  >
                    <h6>{item?.name}</h6>
                    <div
                      className={`check ${
                        selectedPage?.id === item?.id ? "active" : ""
                      }`}
                    >
                      <span></span>
                    </div>
                  </div>
                ))}
              </div>
              <div className="actions">
                <Button className="secondary-button" onClick={close}>
                  Cancel
                </Button>
                <Button
                  className="primary-button"
                  onClick={postSubscribeToMessengerPage}
                  disabled={!selectedPage || isCreatingChannel}
                >
                  Continue
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}

      {currentStep === 3 && (
        <Modal className="channel-modal">
          <div className="modal-content new-conversation-container">
            <div className="modal-title">Channel setup complete</div>
            <div className="modal-form new-conversation-form">
              <div className="d-flex">
                <div className="info">
                  <ul>
                    <li>
                      You can now send and receive messages via Facebook
                      Messenger.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="actions">
                <Button
                  className="primary-button"
                  onClick={() => {
                    setCurrentStep(null);
                    history.push(
                      dismissRoute ? dismissRoute : "/settings/channels"
                    );
                  }}
                >
                  Dismiss
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default withRouter(FacebookForm);
