import {
  getRequest,
  postRequest,
  patchRequest,
  deleteRequest,
} from "../../api";

import {
  GET_SIGNATURE_LOADER,
  GET_SIGNATURE_RESPONSE,
  CREATE_SIGNATURE_LOADER,
  CREATE_SIGNATURE_RESPONSE,
  SIGNATURE_RESET,
  UPDATE_SIGNATURE_LOADER,
  UPDATE_SIGNATURE_RESPONSE,
  DELETE_SIGNATURE_LOADER,
  DELETE_SIGNATURE_RESPONSE,
} from "../constants";

import { getRequestError } from "../../utils/functions";

export const createSignatureLoader = (isCreatingSignature) => ({
  type: CREATE_SIGNATURE_LOADER,
  isCreatingSignature,
});

export const createSignatureResponse = (
  createSignatureOutcome,
  createSignatureMessage
) => ({
  type: CREATE_SIGNATURE_RESPONSE,
  createSignatureOutcome,
  createSignatureMessage,
});

export const getSignatureLoader = (isLoadingSignature) => ({
  type: GET_SIGNATURE_LOADER,
  isLoadingSignature,
});

export const getSignatureResponse = (
  signatureOutcome,
  signatureData,
  signatureMeta,
  signatureMessage
) => ({
  type: GET_SIGNATURE_RESPONSE,
  signatureOutcome,
  signatureData,
  signatureMeta,
  signatureMessage,
});

export const signatureReset = () => ({
  type: SIGNATURE_RESET,
});

export const updateSignatureLoader = (isUpdatingSignature) => ({
  type: UPDATE_SIGNATURE_LOADER,
  isUpdatingSignature,
});

export const updateSignatureResponse = (
  updateSignatureOutcome,
  updateSignatureMessage
) => ({
  type: UPDATE_SIGNATURE_RESPONSE,
  updateSignatureOutcome,
  updateSignatureMessage,
});

export const deleteSignatureLoader = (isDeletingSignature) => ({
  type: DELETE_SIGNATURE_LOADER,
  isDeletingSignature,
});

export const deleteSignatureResponse = (
  deleteSignatureOutcome,
  deleteSignatureMessage
) => ({
  type: DELETE_SIGNATURE_RESPONSE,
  deleteSignatureOutcome,
  deleteSignatureMessage,
});

export const createSignatureAction = (data) => {
  return async (dispatch) => {
    dispatch(signatureReset());
    dispatch(createSignatureLoader(true));

    try {
      const res = await postRequest({
        url: "/signature",
        data,
        token: true,
      });

      if (res?.status === 201) {
        dispatch(createSignatureResponse("success", res?.data?.message));
        dispatch(createSignatureLoader(false));
        return res?.status;
      }
    } catch (error) {
      const message = getRequestError(error);

      dispatch(createSignatureResponse("error", message));
      dispatch(createSignatureLoader(false));
    }
  };
};

export const getSignatureAction = (params = {}) => {
  return async (dispatch) => {
    dispatch(signatureReset());
    dispatch(getSignatureLoader(true));

    try {
      const res = await getRequest({
        url: "/signature",
        params,
        token: true,
      });

      const { data } = res.data;

      dispatch(getSignatureResponse("success", data || [], {}, ""));
      dispatch(getSignatureLoader(false));
    } catch (error) {
      const message = getRequestError(error);

      dispatch(getSignatureResponse("error", [], {}, message));
      dispatch(getSignatureLoader(false));
    }
  };
};

export const updateSignatureAction = (data, id) => {
  return async (dispatch) => {
    dispatch(signatureReset());
    dispatch(updateSignatureLoader(true));

    try {
      const res = await patchRequest({
        url: `/signature/${id}`,
        data,
        token: true,
      });

      if (res?.status === 200) {
        dispatch(updateSignatureResponse("success", res?.data?.message));
        dispatch(updateSignatureLoader(false));
        return res?.status;
      }
    } catch (error) {
      const message = getRequestError(error);

      dispatch(updateSignatureResponse("error", message));
      dispatch(updateSignatureLoader(false));
    }
  };
};

export const deleteSignatureAction = (id) => {
  return async (dispatch) => {
    dispatch(signatureReset());
    dispatch(deleteSignatureLoader(true));

    try {
      const res = await deleteRequest({
        url: `/signature/${id}`,
        token: true,
      });

      if (res?.status === 200) {
        dispatch(deleteSignatureResponse("success", res?.data?.message));
        dispatch(deleteSignatureLoader(false));
        return res?.status;
      }
    } catch (error) {
      const message = getRequestError(error);

      dispatch(deleteSignatureResponse("error", message));
      dispatch(deleteSignatureLoader(false));
      return message;
    }
  };
};
