import React from "react";

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      fill="#8F99A6"
      d="M14.42 6.827c.306.305.306.8 0 1.105l-5.24 5.241a.781.781 0 01-1.106 0L5.58 10.678a.78.78 0 111.104-1.105l1.943 1.943 4.689-4.69a.781.781 0 011.104 0zM20 10c0 5.527-4.473 10-10 10S0 15.527 0 10 4.473 0 10 0s10 4.473 10 10zm-1.563 0A8.433 8.433 0 0010 1.562 8.433 8.433 0 001.562 10 8.433 8.433 0 0010 18.438 8.433 8.433 0 0018.438 10z"
    ></path>
  </svg>
);
