import React from "react";

export default () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M18.125 15.625H9.375L4.375 19.375V15.625H1.875C1.54348 15.625 1.22554 15.4933 0.991117 15.2589C0.756696 15.0245 0.625 14.7065 0.625 14.375V1.875C0.625 1.54348 0.756696 1.22554 0.991117 0.991117C1.22554 0.756696 1.54348 0.625 1.875 0.625H18.125C18.4565 0.625 18.7745 0.756696 19.0089 0.991117C19.2433 1.22554 19.375 1.54348 19.375 1.875V14.375C19.375 14.7065 19.2433 15.0245 19.0089 15.2589C18.7745 15.4933 18.4565 15.625 18.125 15.625Z"
        stroke="black"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.25 7.8125C6.18819 7.8125 6.12777 7.83083 6.07638 7.86517C6.02499 7.8995 5.98494 7.94831 5.96129 8.00541C5.93764 8.06251 5.93145 8.12535 5.9435 8.18597C5.95556 8.24659 5.98533 8.30227 6.02903 8.34597C6.07273 8.38968 6.12842 8.41944 6.18903 8.4315C6.24965 8.44355 6.31249 8.43736 6.36959 8.41371C6.42669 8.39006 6.4755 8.35001 6.50983 8.29862C6.54417 8.24723 6.5625 8.18681 6.5625 8.125C6.5625 8.04212 6.52958 7.96263 6.47097 7.90403C6.41237 7.84542 6.33288 7.8125 6.25 7.8125Z"
        stroke="black"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 7.8125C9.93819 7.8125 9.87777 7.83083 9.82638 7.86517C9.77499 7.8995 9.73494 7.94831 9.71129 8.00541C9.68764 8.06251 9.68145 8.12535 9.6935 8.18597C9.70556 8.24659 9.73532 8.30227 9.77903 8.34597C9.82273 8.38968 9.87841 8.41944 9.93903 8.4315C9.99965 8.44355 10.0625 8.43736 10.1196 8.41371C10.1767 8.39006 10.2255 8.35001 10.2598 8.29862C10.2942 8.24723 10.3125 8.18681 10.3125 8.125C10.3125 8.04212 10.2796 7.96263 10.221 7.90403C10.1624 7.84542 10.0829 7.8125 10 7.8125Z"
        stroke="black"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.75 7.8125C13.6882 7.8125 13.6278 7.83083 13.5764 7.86517C13.525 7.8995 13.4849 7.94831 13.4613 8.00541C13.4376 8.06251 13.4314 8.12535 13.4435 8.18597C13.4556 8.24659 13.4853 8.30227 13.529 8.34597C13.5727 8.38968 13.6284 8.41944 13.689 8.4315C13.7497 8.44355 13.8125 8.43736 13.8696 8.41371C13.9267 8.39006 13.9755 8.35001 14.0098 8.29862C14.0442 8.24723 14.0625 8.18681 14.0625 8.125C14.0625 8.04212 14.0296 7.96263 13.971 7.90403C13.9124 7.84542 13.8329 7.8125 13.75 7.8125Z"
        stroke="black"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
