export const SUPPORTED_DOCUMENT_EXTENSIONS = [
  "pdf",
  "docx",
  "csv",
  "xls",
  "xlsx",
];
export const SUPPORTED_IMAGE_EXTENSIONS = ["png", "jpg", "jpeg", "gif"];
export const SUPPORTED_VIDEO_EXTENSIONS = ["wmv", "mov", "mp4", "mkv", "flv"];
export const ALL_SUPPORTED_EXTENSIONS = [
  ...SUPPORTED_DOCUMENT_EXTENSIONS,
  ...SUPPORTED_IMAGE_EXTENSIONS,
  ...SUPPORTED_VIDEO_EXTENSIONS,
];
export const ALL_CHANNELS = [
  {
    name: "WHATSAPP",
    icon: "https://s3.eu-west-3.amazonaws.com/oneroute.asb.ng/whatsapp-icon.png",
  },
  {
    name: "FACEBOOK",
    icon: "https://s3.eu-west-3.amazonaws.com/oneroute.asb.ng/facebook-icon.png",
  },
  {
    name: "TWITTER",
    icon: "https://s3.eu-west-3.amazonaws.com/oneroute.asb.ng/twitter-icon.png",
  },
  {
    name: "INSTAGRAM",
    icon: "https://s3.eu-west-3.amazonaws.com/oneroute.asb.ng/instagram-icon.png",
  },
  {
    name: "EMAIL",
    icon: "https://s3.eu-west-3.amazonaws.com/oneroute.asb.ng/mail-icon.png",
  },
  {
    name: "SMS",
    icon: "https://s3.eu-west-3.amazonaws.com/oneroute.asb.ng/sms-icon.png",
  },
  {
    name: "WIDGET",
    icon: "https://s3.eu-west-3.amazonaws.com/oneroute.asb.ng/oneroute-icon.png",
  },
];

export const PAGE_SIZE = 10;

export const APP_ONEROUTE_USER = "oneroute-user";
export const APP_NOTIFY_TOKEN = "or_notify_token";
export const APP_FIRM_ONBOARDING = "or_firm_onboarding";
export const APP_SENT_FIRST_CONVO_REPLY = "sent_first_convo_reply";
export const APP_CREATED_WIDGET = "created_widget";
export const APP_CREATED_FIRST_BROADCAST_MSG = "created_first_broadcast_msg";
export const APP_INVITED_TEAM_MEMBER = "invited_team_member";

export const APP_EVENT_START_TRIAL = "start_trial";
export const APP_EVENT_SENT_FIRST_MSG = "sent_first_message";
export const APP_EVENT_RECEIVED_FIRST_MSG = "received_first_message";
export const APP_EVENT_UPGRADED_ACCOUNT = "upgraded_account";
export const APP_EVENT_PURCHASED_CREDIT = "purchased_credit";
export const APP_EVENT_TOPUP_WALLET = "topup_wallet";

export const APP_UNSENT_MSGS = "unsent_msgs";

export const APP_DLR_ORDER = {
  failed: 1,
  read: 2,
  delivered: 3,
  sent: 4,
  sending: 5,
};
