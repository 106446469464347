import {
  getRequest,
  postRequest,
  patchRequest,
  deleteRequest,
} from "../../api";

import {
  CREATE_CUSTOMERS_LOADER,
  CREATE_CUSTOMERS_RESPONSE,
  CREATE_CUSTOMERS_RESET,
  UPDATE_CUSTOMERS_LOADER,
  UPDATE_CUSTOMERS_RESPONSE,
  UPDATE_CUSTOMERS_RESET,
  GET_CUSTOMERS_LOADER,
  GET_CUSTOMERS_RESPONSE,
  GET_CUSTOMERS_RESET,
  DELETE_CUSTOMERS_LOADER,
  DELETE_CUSTOMERS_RESPONSE,
  DELETE_CUSTOMERS_RESET,
  IMPORT_CUSTOMERS_LOADER,
  IMPORT_CUSTOMERS_RESPONSE,
} from "../constants";

import { getRequestError } from "../../utils/functions";

export const createCustomersLoader = (isCreatingCustomer) => ({
  type: CREATE_CUSTOMERS_LOADER,
  isCreatingCustomer,
});

export const createCustomersResponse = (
  createCustomersOutcome,
  createCustomersMessage
) => ({
  type: CREATE_CUSTOMERS_RESPONSE,
  createCustomersOutcome,
  createCustomersMessage,
});

export const createCustomersReset = () => ({
  type: CREATE_CUSTOMERS_RESET,
});

export const updateCustomersLoader = (isUpdatingCustomer) => ({
  type: UPDATE_CUSTOMERS_LOADER,
  isUpdatingCustomer,
});

export const updateCustomersResponse = (
  updateCustomersOutcome,
  updateCustomersMessage
) => ({
  type: UPDATE_CUSTOMERS_RESPONSE,
  updateCustomersOutcome,
  updateCustomersMessage,
});

export const updateCustomersReset = () => ({
  type: UPDATE_CUSTOMERS_RESET,
});

export const getCustomersLoader = (isLoadingCustomers) => ({
  type: GET_CUSTOMERS_LOADER,
  isLoadingCustomers,
});

export const getCustomersResponse = (
  customersOutcome,
  customersData,
  customersMeta,
  customersMessage
) => ({
  type: GET_CUSTOMERS_RESPONSE,
  customersOutcome,
  customersData,
  customersMeta,
  customersMessage,
});

export const getCustomersReset = () => ({
  type: GET_CUSTOMERS_RESET,
});

export const deleteCustomersLoader = (isDeletingCustomer) => ({
  type: DELETE_CUSTOMERS_LOADER,
  isDeletingCustomer,
});

export const deleteCustomersResponse = (
  deleteCustomersOutcome,
  deleteCustomersMessage
) => ({
  type: DELETE_CUSTOMERS_RESPONSE,
  deleteCustomersOutcome,
  deleteCustomersMessage,
});

export const deleteCustomersReset = () => ({
  type: DELETE_CUSTOMERS_RESET,
});

export const importCustomersLoader = (isImportingCustomers) => ({
  type: IMPORT_CUSTOMERS_LOADER,
  isImportingCustomers,
});

export const importCustomersResponse = (
  importCustomersOutcome,
  importCustomersMessage
) => ({
  type: IMPORT_CUSTOMERS_RESPONSE,
  importCustomersOutcome,
  importCustomersMessage,
});

export const createCustomersAction = (data, success) => {
  return async (dispatch) => {
    dispatch(createCustomersReset());
    dispatch(createCustomersLoader(true));

    try {
      await postRequest({
        url: "/customers",
        data,
        token: true,
      });

      dispatch(createCustomersResponse("success", ""));
      dispatch(createCustomersLoader(false));

      success && success();
    } catch (error) {
      const message = getRequestError(error);

      dispatch(createCustomersResponse("error", message));
      dispatch(createCustomersLoader(false));
    }
  };
};

export const updateCustomersAction = (data, id, success) => {
  return async (dispatch) => {
    dispatch(updateCustomersReset());
    dispatch(updateCustomersLoader(true));

    try {
      await patchRequest({
        url: `/customers/${id}`,
        data,
        token: true,
      });

      dispatch(updateCustomersResponse("success", ""));
      dispatch(updateCustomersLoader(false));

      success && success();
    } catch (error) {
      const message = getRequestError(error);

      dispatch(updateCustomersResponse("error", message));
      dispatch(updateCustomersLoader(false));
    }
  };
};

export const getCustomersAction = (params = {}) => {
  return async (dispatch) => {
    dispatch(getCustomersReset());
    dispatch(getCustomersLoader(true));

    try {
      const res = await getRequest({
        url: "/customers",
        params,
        token: true,
      });

      const { data, currentPage, count, totalPage } = res.data;

      dispatch(
        getCustomersResponse(
          "success",
          data,
          { currentPage, count, totalPage },
          ""
        )
      );
      dispatch(getCustomersLoader(false));
      return data;
    } catch (error) {
      const message = getRequestError(error);

      dispatch(getCustomersResponse("error", [], {}, message));
      dispatch(getCustomersLoader(false));
    }
  };
};

export const getCustomerAction = (params = {}) => {
  return async () => {
    try {
      const res = await getRequest({
        url: `/customers/${params?.id}`,
        params,
        token: true,
      });

      return res.data.data;
    } catch (error) {
      const message = getRequestError(error);
      console.log(message);
    }
  };
};

export const deleteCustomersAction = (id) => {
  return async (dispatch) => {
    dispatch(deleteCustomersReset());
    dispatch(deleteCustomersLoader(true));

    try {
      await deleteRequest({
        url: `/customers/${id}`,
        token: true,
      });

      dispatch(deleteCustomersResponse("success", ""));
      dispatch(deleteCustomersLoader(false));
    } catch (error) {
      const message = getRequestError(error);

      dispatch(deleteCustomersResponse("error", message));
      dispatch(deleteCustomersLoader(false));
    }
  };
};

export const importCustomersAction = (data, success) => {
  return async (dispatch) => {
    dispatch(importCustomersLoader(true));

    try {
      const res = await postRequest({
        url: "/customers/import",
        data,
        token: true,
      });

      dispatch(importCustomersLoader(false));
      return res?.data;
    } catch (error) {
      const message = getRequestError(error);

      dispatch(importCustomersResponse("error", message));
      dispatch(importCustomersLoader(false));
    }
  };
};

export const getDialCodesAction = () => {
  return async () => {
    try {
      const res = await getRequest({
        url: "utils/countries",
      });

      const { data } = res.data;
      return data || [];
    } catch (error) {
      const message = getRequestError(error);
      console.log(message, "Fetch Dail codes request error");
    }
  };
};
