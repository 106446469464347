import React, { Component } from "react";
import { connect } from "react-redux";
import * as shallowequal from "shallowequal";
import { decode } from "html-entities";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

import {
  createSavedResponsesAction,
  updateSavedResponsesAction,
} from "../../../redux/actions/SavedResponses";

import {
  createCategoriesAction,
  getCategoriesAction,
} from "../../../redux/actions/Categories";

import {
  Button,
  TextField,
  TextArea,
  Select,
} from "../../../components/FormElements";
import Modal from "../../../components/Modal";
import FormNotifications from "../../../components/Notifications";

import { patchFormValues, getFormValues } from "../../../utils/functions";

const initialFormData = {
  title: {
    value: "",
    valid: false,
  },
  content: {
    value: "",
    valid: false,
  },
  category_id: {
    value: "",
    valid: false,
  },
  formatted: {
    value: false,
    valid: true,
  },
};

class SavedResponseForm extends Component {
  constructor(props) {
    super(props);

    const { savedResponseData } = props;

    const formData = savedResponseData
      ? patchFormValues(initialFormData, {
          ...savedResponseData,
          formatted: savedResponseData?.config?.formatted || false,
        })
      : initialFormData;

    this.state = {
      formData: formData
        ? formData
        : { ...JSON.parse(JSON.stringify(initialFormData)) },
    };
  }

  handleChange = ({ target }, valid) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [target.name]: {
          value: target.value,
          valid,
        },
      },
    });
  };

  handleToggleFormatting = () => {
    const { formData } = this.state;

    this.setState({
      formData: {
        ...formData,
        content: {
          value: "",
          valid: false,
        },
        formatted: {
          value: !formData?.formatted?.value,
          valid: true,
        },
      },
    });
  };

  handleRichTextChange = (content) => {
    this.setState({
      formData: {
        ...this.state.formData,
        content: {
          value: content,
          valid: true,
        },
      },
    });
  };

  checkFormValidity = () => {
    return Object.values(this.state.formData).every(({ valid }) => valid);
  };

  submitForm = (e) => {
    e.preventDefault();
    const {
      savedResponseData,
      createSavedResponsesAction,
      updateSavedResponsesAction,
      toggleSavedResponseForm,
      getSavedResponsesAction,
    } = this.props;

    const formData = getFormValues(this.state.formData);
    savedResponseData
      ? updateSavedResponsesAction(formData, savedResponseData.id).then(
          (res) => {
            if (res === 200) {
              getSavedResponsesAction();
              toggleSavedResponseForm();
            }
          }
        )
      : createSavedResponsesAction(formData).then((res) => {
          if (res === 201) {
            getSavedResponsesAction();
            toggleSavedResponseForm();
          }
        });
  };

  componentDidUpdate(prevProps) {
    const { savedResponseData, createSavedResponsesOutcome } = this.props;

    if (
      !shallowequal(prevProps.savedResponseData, savedResponseData) &&
      Object.keys(savedResponseData).length
    ) {
      const formData = patchFormValues(initialFormData, savedResponseData);

      this.setState({
        formData,
      });
    }

    if (
      prevProps.createSavedResponsesOutcome !== createSavedResponsesOutcome &&
      createSavedResponsesOutcome === "success"
    ) {
      this.setState({
        formData: { ...initialFormData },
      });
    }
  }

  render() {
    const { formData } = this.state;
    const {
      isCreatingSavedResponse,
      isUpdatingSavedResponse,
      toggleSavedResponseForm,
      createSavedResponsesOutcome,
      createSavedResponsesMessage,
      updateSavedResponsesOutcome,
      updateSavedResponsesMessage,
      savedResponseData,
      newItemValue,
      handleNewItemChange,
      createCategoriesAction,
      getCategoriesAction,
      isCreatingCategory,
      categoriesData,
    } = this.props;

    const categoryOptions = categoriesData.map(({ id, name }) => ({
      label: name,
      value: id,
    }));

    const { title, content, category_id, formatted } = formData;

    return (
      <Modal closeModal={() => toggleSavedResponseForm()}>
        <div className="modal-content">
          <div className="modal-title saved-response-modal-title">
            {savedResponseData ? "Edit" : "New"} saved response
            <div className="toggle-switch">
              <span onClick={this.handleToggleFormatting}>
                Enable formatting
              </span>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={formatted?.value}
                  onChange={this.handleToggleFormatting}
                />
                <span className="slider round"></span>
              </label>
            </div>
          </div>
          <div className="modal-form saved-response-form">
            <form onSubmit={this.submitForm}>
              <div className="form-row mb-25">
                <TextField
                  label="Title"
                  name="title"
                  value={title.value}
                  onChange={(e, valid) => this.handleChange(e, valid)}
                />
                <Select
                  label="Category"
                  name="category_id"
                  options={categoryOptions}
                  value={category_id.value}
                  onChange={(e, valid) => this.handleChange(e, valid)}
                  allowNewItem
                  newItemValue={newItemValue}
                  handleNewItemChange={handleNewItemChange}
                  newItemAction={
                    <Button
                      type="button"
                      className="plain-button"
                      disabled={!newItemValue || isCreatingCategory}
                      onClick={() =>
                        createCategoriesAction({ name: newItemValue }, () =>
                          getCategoriesAction()
                        )
                      }
                    >
                      Save
                    </Button>
                  }
                />
              </div>
              {formatted?.value === true ? (
                <SunEditor
                  name="content"
                  placeholder="Type your response here..."
                  setOptions={{
                    fontSize: [14, 16, 20, 24, 26],
                    buttonList: [
                      [
                        "font",
                        "fontSize",
                        "bold",
                        "italic",
                        "underline",
                        "fontColor",
                        "align",
                        "list",
                        "link",
                      ],
                    ],
                  }}
                  defaultValue={decode(content.value, { level: "html5" })}
                  onChange={this.handleRichTextChange}
                />
              ) : (
                <TextArea
                  label="Content"
                  name="content"
                  className="mb-30"
                  value={content.value}
                  onChange={(e, valid) => this.handleChange(e, valid)}
                  showEmoji
                  hideGif
                  hideImageUpload
                  hideAttach
                  onFileLoad={() => {}}
                  onFileError={() => {}}
                />
              )}
              <div className="modal-form-action">
                <Button
                  type="button"
                  className="secondary-button"
                  onClick={() => toggleSavedResponseForm()}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  className="primary-button"
                  disabled={
                    !this.checkFormValidity() ||
                    isCreatingSavedResponse ||
                    isUpdatingSavedResponse
                  }
                >
                  {isCreatingSavedResponse || isUpdatingSavedResponse
                    ? "Processing..."
                    : savedResponseData
                    ? "Save Changes"
                    : "Add saved response"}
                </Button>
              </div>

              {createSavedResponsesOutcome === "error" && (
                <FormNotifications
                  type="error"
                  message={createSavedResponsesMessage}
                />
              )}
              {updateSavedResponsesOutcome === "error" && (
                <FormNotifications
                  type="error"
                  message={updateSavedResponsesMessage}
                />
              )}
            </form>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = ({ savedResponses, categories }) => ({
  ...savedResponses,
  ...categories,
});

export default connect(mapStateToProps, {
  createSavedResponsesAction,
  updateSavedResponsesAction,
  createCategoriesAction,
  getCategoriesAction,
})(SavedResponseForm);
