import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Button, TextField } from "../../../../components/FormElements";
import Modal from "../../../../components/Modal";
import FormNotifications from "../../../../components/Notifications";

import { postSubscribeIntegrationAction } from "../../../../redux/actions/Integrations";

const FreshDeskSetup = ({ close, onSetupSuccess }) => {
  const dispatch = useDispatch();
  const {
    isCreatingIntegration,
    createIntegrationOutcome,
    createIntegrationMessage,
  } = useSelector((state) => state?.integrations);

  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({});

  const postFreshDeskIntegration = () => {
    dispatch(
      postSubscribeIntegrationAction({
        url: "/integrations/freshDesk/connect",
        requestData: formData,
        completedSetupFor: "Fresh Desk",
      })
    ).then((res) => {
      if (res?.data?.success === true) {
        onSetupSuccess();
        setCurrentStep(3);
      }
    });
  };

  return (
    <React.Fragment>
      {currentStep === 1 && (
        <Modal className="channel-modal">
          <div className="modal-content new-conversation-container">
            <div className="modal-title mb-30">
              Connect Fresh Desk to your OneRoute account
            </div>
            <div className="modal-form new-conversation-form">
              <div className="form-container mb-50">
                <TextField
                  type="text"
                  label="Domain name"
                  name="domain"
                  onChange={(e) =>
                    setFormData({ ...formData, domain: e?.target?.value })
                  }
                  className="mb-20"
                  hint={
                    <span>
                      Look at the address bar of your Fresh Desk account, it
                      should look similar to this -
                      https://oneroutehelp.freshdesk.com/... - (
                      <strong>oneroutehelp</strong>) is your domain name
                    </span>
                  }
                />
                <TextField
                  type="text"
                  label="API Key"
                  name="apikey"
                  onChange={(e) =>
                    setFormData({ ...formData, apikey: e?.target?.value })
                  }
                  hint={
                    <span>
                      Trouble finding your API Key?
                      <a
                        href="https://support.freshdesk.com/en/support/solutions/articles/215517-how-to-find-your-api-key"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <strong>Click here</strong>
                      </a>
                    </span>
                  }
                />
                {!isCreatingIntegration &&
                  createIntegrationOutcome === "error" && (
                    <FormNotifications
                      type="error"
                      message={createIntegrationMessage}
                    />
                  )}
              </div>
              <div className="actions">
                <Button className="secondary-button" onClick={close}>
                  Cancel
                </Button>
                <Button
                  className="primary-button"
                  onClick={postFreshDeskIntegration}
                  disabled={
                    !formData?.domain ||
                    !formData?.apikey ||
                    isCreatingIntegration
                  }
                >
                  {isCreatingIntegration ? "Processing..." : "Connect"}
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}

      {currentStep === 3 && (
        <Modal className="channel-modal">
          <div className="modal-content new-conversation-container">
            <div className="modal-title">Integration setup complete</div>
            <div className="modal-form new-conversation-form">
              <div className="d-flex">
                <div className="info">
                  <ul>
                    <li>You can now use FreshDesk on OneRoute.</li>
                  </ul>
                </div>
              </div>
              <div className="actions">
                <Button
                  className="primary-button"
                  onClick={() => {
                    setCurrentStep(null);
                    close();
                  }}
                >
                  Dismiss
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default FreshDeskSetup;
