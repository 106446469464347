import { getUserDetails } from "./functions";

const user = getUserDetails();
const userPermissions = user?.role?.permissions;

const allAccountSettingsPermissions = userPermissions?.accountSettings;
export const accountSettingsPermissions = {
  create: allAccountSettingsPermissions?.includes("create"),
  read: allAccountSettingsPermissions?.includes("read"),
  update: allAccountSettingsPermissions?.includes("update"),
  delete: allAccountSettingsPermissions?.includes("delete"),
  viewAll: allAccountSettingsPermissions?.includes("viewAll"),
  modifyAll: allAccountSettingsPermissions?.includes("modifyAll"),
};

const allAgentsPermissions = userPermissions?.agents;
export const agentsPermissions = {
  create: allAgentsPermissions?.includes("create"),
  read: allAgentsPermissions?.includes("read"),
  update: allAgentsPermissions?.includes("update"),
  delete: allAgentsPermissions?.includes("delete"),
  viewAll: allAgentsPermissions?.includes("viewAll"),
  modifyAll: allAgentsPermissions?.includes("modifyAll"),
};

const allAutoRespondersPermissions = userPermissions?.autoResponders;
export const autoRespondersPermissions = {
  create: allAutoRespondersPermissions?.includes("create"),
  read: allAutoRespondersPermissions?.includes("read"),
  update: allAutoRespondersPermissions?.includes("update"),
  delete: allAutoRespondersPermissions?.includes("delete"),
  viewAll: allAutoRespondersPermissions?.includes("viewAll"),
  modifyAll: allAutoRespondersPermissions?.includes("modifyAll"),
};

const allBillingPermissions = userPermissions?.billing;
export const billingPermissions = {
  read: allBillingPermissions?.includes("read"),
  update: allBillingPermissions?.includes("update"),
  viewAll: allBillingPermissions?.includes("viewAll"),
  modifyAll: allBillingPermissions?.includes("modifyAll"),
};

const allBroadcastPermissions = userPermissions?.broadcast;
export const broadcastPermissions = {
  create: allBroadcastPermissions?.includes("create"),
  read: allBroadcastPermissions?.includes("read"),
  update: allBroadcastPermissions?.includes("update"),
  delete: allBroadcastPermissions?.includes("delete"),
  viewAll: allBroadcastPermissions?.includes("viewAll"),
  modifyAll: allBroadcastPermissions?.includes("modifyAll"),
};

const allChannelsPermissions = userPermissions?.channels;
export const channelsPermissions = {
  create: allChannelsPermissions?.includes("create"),
  read: allChannelsPermissions?.includes("read"),
  update: allChannelsPermissions?.includes("update"),
  delete: allChannelsPermissions?.includes("delete"),
  viewAll: allChannelsPermissions?.includes("viewAll"),
  modifyAll: allChannelsPermissions?.includes("modifyAll"),
};

const allConversationPermissions = userPermissions?.conversations;
export const conversationsPermissions = {
  create: allConversationPermissions?.includes("create"),
  read: allConversationPermissions?.includes("read"),
  update: allConversationPermissions?.includes("update"),
  delete: allConversationPermissions?.includes("delete"),
  viewAll: allConversationPermissions?.includes("viewAll"),
  modifyAll: allConversationPermissions?.includes("modifyAll"),
};

const allCsatPermissions = userPermissions?.csat;
export const csatPermissions = {
  create: allCsatPermissions?.includes("create"),
  read: allCsatPermissions?.includes("read"),
  update: allCsatPermissions?.includes("update"),
  delete: allCsatPermissions?.includes("delete"),
  viewAll: allCsatPermissions?.includes("viewAll"),
  modifyAll: allCsatPermissions?.includes("modifyAll"),
};

const allCustomersPermissions = userPermissions?.customers;
export const customersPermissions = {
  create: allCustomersPermissions?.includes("create"),
  read: allCustomersPermissions?.includes("read"),
  update: allCustomersPermissions?.includes("update"),
  delete: allCustomersPermissions?.includes("delete"),
  viewAll: allCustomersPermissions?.includes("viewAll"),
  modifyAll: allCustomersPermissions?.includes("modifyAll"),
};

const allGroupsPermissions = userPermissions?.groups;
export const groupsPermissions = {
  create: allGroupsPermissions?.includes("create"),
  read: allGroupsPermissions?.includes("read"),
  update: allGroupsPermissions?.includes("update"),
  delete: allGroupsPermissions?.includes("delete"),
  viewAll: allGroupsPermissions?.includes("viewAll"),
  modifyAll: allGroupsPermissions?.includes("modifyAll"),
};

const allInsightsPermissions = userPermissions?.insights;
export const insightsPermissions = {
  read: allInsightsPermissions?.includes("read"),
  viewAll: allInsightsPermissions?.includes("viewAll"),
  modifyAll: allInsightsPermissions?.includes("modifyAll"),
};

const allIntegrationsPermissions = userPermissions?.integrations;
export const integrationsPermissions = {
  create: allIntegrationsPermissions?.includes("create"),
  read: allIntegrationsPermissions?.includes("read"),
  update: allIntegrationsPermissions?.includes("update"),
  delete: allIntegrationsPermissions?.includes("delete"),
  viewAll: allIntegrationsPermissions?.includes("viewAll"),
  modifyAll: allIntegrationsPermissions?.includes("modifyAll"),
};

const allOfficeHoursPermissions = userPermissions?.officeHours;
export const officeHoursPermissions = {
  create: allOfficeHoursPermissions?.includes("create"),
  read: allOfficeHoursPermissions?.includes("read"),
  update: allOfficeHoursPermissions?.includes("update"),
  delete: allOfficeHoursPermissions?.includes("delete"),
  viewAll: allOfficeHoursPermissions?.includes("viewAll"),
  modifyAll: allOfficeHoursPermissions?.includes("modifyAll"),
};

const allSavedResponsesPermissions = userPermissions?.savedResponses;
export const savedResponsesPermissions = {
  create: allSavedResponsesPermissions?.includes("create"),
  read: allSavedResponsesPermissions?.includes("read"),
  update: allSavedResponsesPermissions?.includes("update"),
  delete: allSavedResponsesPermissions?.includes("delete"),
  viewAll: allSavedResponsesPermissions?.includes("viewAll"),
  modifyAll: allSavedResponsesPermissions?.includes("modifyAll"),
};

const allTagsPermissions = userPermissions?.tags;
export const tagsPermissions = {
  create: allTagsPermissions?.includes("create"),
  read: allTagsPermissions?.includes("read"),
  update: allTagsPermissions?.includes("update"),
  delete: allTagsPermissions?.includes("delete"),
  viewAll: allTagsPermissions?.includes("viewAll"),
  modifyAll: allTagsPermissions?.includes("modifyAll"),
};

const allTemplatesPermissions = userPermissions?.templates;
export const templatesPermissions = {
  create: allTemplatesPermissions?.includes("create"),
  read: allTemplatesPermissions?.includes("read"),
  update: allTemplatesPermissions?.includes("update"),
  delete: allTemplatesPermissions?.includes("delete"),
  viewAll: allTemplatesPermissions?.includes("viewAll"),
  modifyAll: allTemplatesPermissions?.includes("modifyAll"),
};

const allTicketsPermissions = userPermissions?.tickets;
export const ticketsPermissions = {
  create: allTicketsPermissions?.includes("create"),
  read: allTicketsPermissions?.includes("read"),
  update: allTicketsPermissions?.includes("update"),
  delete: allTicketsPermissions?.includes("delete"),
  viewAll: allTicketsPermissions?.includes("viewAll"),
  modifyAll: allTicketsPermissions?.includes("modifyAll"),
};
