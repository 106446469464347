import React, { Component } from "react";
import { connect } from "react-redux";
import CreatableSelect from "react-select/creatable";

import {
  Button,
  TextField,
  Select,
  TextArea,
  FileInput,
} from "../../../components/FormElements";
import Modal from "../../../components/Modal";
import FormNotifications from "../../../components/Notifications";

import {
  patchFormValues,
  getFormValues,
  getUserDetails,
  getRequestError,
} from "../../../utils/functions";
import { getRequest, postRequest, patchRequest } from "../../../api";

import { Close } from "../../../assets/vectors";

import { getTicketsAction } from "../../../redux/actions/Integrations";

const zohoInitialFormData = {
  type: {
    value: "",
    valid: false,
  },
  title: {
    value: "",
    valid: false,
  },
  description: {
    value: "",
    valid: false,
  },
  assigneeAccountId: {
    value: "",
    valid: false,
  },
  assigneeName: {
    value: "",
    valid: true,
  },
  customer_id: {
    value: "",
    valid: false,
  },
  conversation_id: {
    value: "",
    valid: false,
  },
  departmentId: {
    value: "",
    valid: false,
  },
  priority: {
    value: "High",
    valid: true,
  },
  labels: {
    value: [{ label: "Support", value: "Support" }],
    valid: true,
  },
  phone: {
    value: "",
    valid: true,
  },
  cf_customer_name: {
    value: "",
    valid: true,
  },
  cf_order_number: {
    value: "",
    valid: true,
  },
  status: {
    value: "Open",
    valid: true,
  },
};

const freshDeskInitialFormData = {
  type: {
    value: "",
    valid: false,
  },
  title: {
    value: "",
    valid: false,
  },
  description: {
    value: "",
    valid: false,
  },
  conversation_id: {
    value: "",
    valid: false,
  },
  priority: {
    value: "High",
    valid: true,
  },
  source: {
    value: "Chat",
    valid: true,
  },
  status: {
    value: "Open",
    valid: true,
  },
  ticketType: {
    value: "",
    valid: false,
  },
};

class TicketForm extends Component {
  constructor(props) {
    super(props);

    const {
      integration,
      conversation_id,
      ticketFormData,
      selectedConversation,
    } = props;
    const { firm_id } = getUserDetails();

    const initialFormData = () => {
      if (integration?.type === "zoho") return zohoInitialFormData;
      if (integration?.type === "freshDesk") return freshDeskInitialFormData;
      return {};
    };

    const formData = ticketFormData
      ? patchFormValues(initialFormData(), {
          ...ticketFormData,
          labels:
            ticketFormData?.labels?.map((x) => ({ label: x, value: x })) || [],
        })
      : initialFormData();

    this.state = {
      firm_id,
      assignees: [],
      departments: [],
      attachments: [],
      allLabels: [],
      allTicketTypes: [],
      formData: {
        ...formData,
        type: {
          value: integration?.type,
          valid: !!integration?.type,
        },
        customer_id: {
          value: selectedConversation?.customer_id,
          valid: true,
        },
        conversation_id: {
          value: conversation_id,
          valid: !!conversation_id,
        },
        cf_customer_name: {
          value: selectedConversation?.customer?.name || "",
          valid: true,
        },
        phone: {
          value: selectedConversation?.customer?.phone || "",
          valid: true,
        },
      },
      errorMessage: null,
    };
  }

  getAssignees = async () => {
    const { formData } = this.state;

    this.setState({
      isLoadingAssignees: true,
    });

    try {
      const res = await getRequest({
        url: `/integrations/${formData.type.value}/agents`,
        token: true,
      });

      this.setState({
        assignees: res.data.data,
        isLoadingAssignees: false,
      });
    } catch (error) {
      const message = getRequestError(error);
      console.log(error, message);

      this.setState({
        isLoadingAssignees: false,
      });
    }
  };

  getDepartments = async () => {
    const { formData } = this.state;

    this.setState({
      isLoadingDepartments: true,
    });

    try {
      const res = await getRequest({
        url: `/integrations/${formData.type.value}/departments`,
        token: true,
      });

      this.setState({
        departments: res.data.data,
        isLoadingDepartments: false,
      });
    } catch (error) {
      const message = getRequestError(error);
      console.log(error, message);

      this.setState({
        isLoadingDepartments: false,
      });
    }
  };

  getAllLabels = async () => {
    const { formData } = this.state;

    this.setState({
      isLoadingLabels: true,
    });

    try {
      const res = await getRequest({
        url: `/integrations/${formData.type.value}/labels`,
        token: true,
      });

      this.setState({
        allLabels: res.data.data,
        isLoadingLabels: false,
      });
    } catch (error) {
      const message = getRequestError(error);
      console.log(error, message);

      this.setState({
        isLoadingLabels: false,
      });
    }
  };

  getAllTicketTypes = async () => {
    this.setState({
      isLoadingTicketTypes: true,
    });

    try {
      const res = await getRequest({
        url: `/integrations/freshDesk/ticket-types`,
        token: true,
      });

      this.setState({
        allTicketTypes: res.data.data,
        isLoadingTicketTypes: false,
      });
    } catch (error) {
      const message = getRequestError(error);
      console.log(error, message);

      this.setState({
        isLoadingLabels: false,
      });
    }
  };

  getTicketFullInfo = async () => {
    const { ticketFormData } = this.props;

    this.props
      .getTicketsAction({
        url: `/integrations/ticket/${ticketFormData?.id}`,
      })
      .then((res) => {
        if (res?.id) {
          this.setState({
            formData: {
              ...this.state.formData,
              departmentId: {
                value: res?.departmentId,
                valid: true,
              },
              priority: {
                value: res?.priority,
                valid: true,
              },
            },
          });
        }
      });
  };

  handleChange = ({ target }, valid) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [target.name]: {
          value: target.value,
          valid,
        },
      },
    });
  };

  handleAttachmentChange = (uploadData, { file }) => {
    if (file) {
      this.setState({
        attachments: [...this.state.attachments, file],
      });
    }
  };

  handleRemoveAttachment = (key) => {
    let allAttachments = [...this.state.attachments] || [];
    allAttachments.splice(key, 1);

    this.setState({
      attachments: allAttachments,
    });
  };

  checkFormValidity = () => {
    return Object.values(this.state.formData).every(({ valid }) => valid);
  };

  submitForm = async (e) => {
    e.preventDefault();

    const { assignees, attachments } = this.state;
    const {
      integration,
      ticketFormData,
      getConversation,
      selectedConversation,
    } = this.props;

    this.setState({
      errorMessage: null,
      isCreatingTicket: true,
    });

    const formData = getFormValues(this.state.formData);

    var payload = {};

    if (attachments?.length) {
      payload = new FormData();
      payload.append("type", formData?.type);
      payload.append("title", formData?.title);
      payload.append("description", formData?.description);
      integration?.type === "zoho" &&
        payload.append("assigneeAccountId", formData?.assigneeAccountId);
      integration?.type === "zoho" && payload.append("phone", formData?.phone);
      integration?.type === "zoho" &&
        payload.append("cf_customer_name", formData?.cf_customer_name);
      integration?.type === "zoho" &&
        payload.append("cf_order_number", formData?.cf_order_number);
      integration?.type === "zoho" &&
        payload.append(
          "assigneeName",
          assignees.find(({ id }) => id === formData.assigneeAccountId)?.name ||
            ""
        );
      integration?.type === "zoho" &&
        payload.append("customer_id", formData?.customer_id);
      payload.append("conversation_id", formData?.conversation_id);
      integration?.type === "zoho" &&
        payload.append("departmentId", formData?.departmentId);
      payload.append("priority", formData?.priority);
      integration?.type === "freshDesk" &&
        payload.append("source", formData?.source);
      integration?.type === "freshDesk" &&
        payload.append("ticketType", formData?.ticketType);
      payload.append("status", formData?.status);
      integration?.type === "zoho" &&
        payload.append("labels", [...formData?.labels?.map((x) => x?.value)]);
      attachments.forEach((item) => payload.append("attachments", item));
    } else {
      payload = {
        ...formData,
        assigneeName:
          assignees.find(({ id }) => id === formData.assigneeAccountId)?.name ||
          "",
        labels: formData?.labels?.map((x) => x?.value),
      };
    }

    if (integration?.type === "zoho") {
      delete payload?.source;
      delete payload?.ticketType;
    }
    if (integration?.type === "freshDesk") {
      delete payload?.assigneeName;
      delete payload?.assigneeAccountId;
      delete payload?.customer_id;
      delete payload?.departmentId;
    }

    const requestType = ticketFormData ? patchRequest : postRequest;
    const url = ticketFormData
      ? `/integrations/ticket/${ticketFormData.id}`
      : `/integrations/ticket`;

    try {
      await requestType({
        url,
        data: payload,
        token: true,
      });

      this.setState({
        isCreatingTicket: false,
      });

      getConversation(
        selectedConversation.Customer?.id,
        selectedConversation.Channel?.id,
        true
      );
      this.props.toggleTicketForm(false);
    } catch (error) {
      const message = getRequestError(error);

      this.setState({
        isCreatingTicket: false,
        errorMessage: message,
      });
    }
  };

  componentDidMount() {
    const { integration, ticketFormData } = this.props;

    if (ticketFormData?.id) {
      this.getTicketFullInfo();
    }

    if (integration?.type === "zoho") {
      this.getDepartments();
      this.getAssignees();
      this.getAllLabels();
      this.getAllTicketTypes();
    }
    if (integration?.type === "freshDesk") {
      this.getAllTicketTypes();
    }
  }

  render() {
    const {
      formData,
      assignees,
      departments,
      attachments,
      allLabels,
      allTicketTypes,
      isCreatingTicket,
      errorMessage,
    } = this.state;
    const {
      title,
      assigneeAccountId,
      departmentId,
      description,
      priority,
      labels,
      phone,
      cf_customer_name,
      cf_order_number,
      source,
      ticketType,
      status,
    } = formData;
    const { integration, ticketFormData, toggleTicketForm } = this.props;

    const assigneesOptions = assignees?.map(({ id, name }) => ({
      label: name,
      value: id,
    }));

    const departmentOptions = departments?.map(({ id, name }) => ({
      label: name,
      value: id,
    }));

    const priorityOptions = [
      { label: "Low", value: "Low" },
      { label: "Medium", value: "Medium" },
      { label: "High", value: "High" },
      { label: "Urgent", value: "Urgent" },
    ];

    const labelOptions =
      allLabels?.map(({ name }) => ({
        label: name,
        value: name,
      })) || [];

    const statusOptions = [
      { label: "Open", value: "Open" },
      { label: "Pending", value: "Pending" },
      { label: "Resolved", value: "Resolved" },
      { label: "Closed", value: "Closed" },
    ];

    const ticketTypeOptions =
      allTicketTypes?.map((type) => ({
        label: type,
        value: type,
      })) || [];

    const sourceOptions = [
      { label: "Email", value: "Email" },
      { label: "Portal", value: "Portal" },
      { label: "Phone", value: "Phone" },
      { label: "Chat", value: "Chat" },
      { label: "FeedbackWidget", value: "FeedbackWidget" },
      { label: "OutboundEmail", value: "OutboundEmail" },
    ];

    return (
      <Modal closeModal={() => toggleTicketForm(false)}>
        <div className="modal-content new-ticket-container">
          <div className="modal-title">
            <img src={integration?.imageUrl} alt="" />
            <span>
              {ticketFormData ? "Edit" : "Create new"} {integration?.name}{" "}
              ticket
            </span>
          </div>
          <div className="modal-form new-ticket-form">
            <form onSubmit={this.submitForm}>
              <TextField
                label="Title"
                name="title"
                value={title?.value}
                onChange={(e, valid) => this.handleChange(e, valid)}
                className="mb-15"
              />
              {integration?.type === "zoho" && (
                <Select
                  label="Assignee"
                  name="assigneeAccountId"
                  options={assigneesOptions}
                  value={assigneeAccountId?.value}
                  onChange={(e, valid) => this.handleChange(e, valid)}
                  className="mb-15"
                />
              )}
              <TextArea
                label="Description"
                name="description"
                value={description?.value}
                onChange={(e, valid) => this.handleChange(e, valid)}
                className="mb-15"
                noAction
              />
              {integration?.type === "zoho" && (
                <Select
                  label="Department"
                  name="departmentId"
                  options={departmentOptions}
                  value={departmentId?.value}
                  onChange={(e, valid) => this.handleChange(e, valid)}
                  className="mb-15"
                />
              )}
              {integration?.type === "zoho" && (
                <TextField
                  label="Customer Name"
                  name="cf_customer_name"
                  value={cf_customer_name?.value}
                  onChange={(e, valid) => this.handleChange(e, valid)}
                  className="mb-15"
                />
              )}
              {integration?.type === "zoho" && (
                <TextField
                  label="Customer Phone"
                  name="phone"
                  value={phone?.value}
                  onChange={(e, valid) => this.handleChange(e, valid)}
                  className="mb-15"
                />
              )}
              {integration?.type === "zoho" && (
                <TextField
                  label="Order Number"
                  name="cf_order_number"
                  value={cf_order_number?.value}
                  onChange={(e, valid) => this.handleChange(e, valid)}
                  className="mb-15"
                />
              )}
              <Select
                label="Priority"
                name="priority"
                options={priorityOptions}
                value={priority?.value}
                onChange={(e, valid) => {
                  this.handleChange(e, valid);
                }}
                className="mb-15"
              />
              {integration?.type === "freshDesk" && (
                <Select
                  label="Source"
                  name="source"
                  options={sourceOptions}
                  value={source?.value}
                  onChange={(e, valid) => {
                    this.handleChange(e, valid);
                  }}
                  className="mb-15"
                />
              )}
              {integration?.type === "freshDesk" && (
                <Select
                  label="Ticket Type"
                  name="ticketType"
                  options={ticketTypeOptions}
                  value={ticketType?.value}
                  onChange={(e, valid) => {
                    this.handleChange(e, valid);
                  }}
                  className="mb-15"
                />
              )}
              {integration?.type === "zoho" && (
                <div className="input-container mb-15">
                  <label>Labels</label>
                  <CreatableSelect
                    classNamePrefix="select_container"
                    value={labels?.value}
                    options={[{ label: "New", value: "New" }, ...labelOptions]}
                    placeholder={"Input custom labels for this ticket"}
                    onChange={(opt) =>
                      this.handleChange(
                        { target: { name: "labels", value: opt } },
                        true
                      )
                    }
                    isClearable
                    isMulti
                  />
                </div>
              )}
              <Select
                label="Status"
                name="status"
                options={statusOptions}
                value={status?.value}
                onChange={(e, valid) => {
                  this.handleChange(e, valid);
                }}
                className="mb-20"
              />
              {!ticketFormData && (
                <div className="attachment_container">
                  <div className="d_flex">
                    <h6>Attachments</h6>

                    <FileInput
                      icon={<span className="secondary-button small">Add</span>}
                      onFileLoad={this.handleAttachmentChange}
                      onFileError={() => {}}
                    />
                  </div>

                  <div className="attachments">
                    {attachments?.map((item, i) => (
                      <p key={i}>
                        {item?.name}
                        <span onClick={() => this.handleRemoveAttachment(i)}>
                          <Close />
                        </span>
                      </p>
                    ))}
                  </div>
                </div>
              )}
              <br />
              <div className="modal-form-action">
                <div className="learn-more">
                  {/* <span className="icon">
                    <Help />
                  </span>{" "}
                  Learn more about Jira */}
                </div>
                <div>
                  <Button
                    type="button"
                    className="secondary-button small"
                    onClick={() => toggleTicketForm(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    className="primary-button small"
                    disabled={!this.checkFormValidity() || isCreatingTicket}
                  >
                    {isCreatingTicket ? "Submitting..." : "Submit"}
                  </Button>
                </div>
              </div>

              {!isCreatingTicket && errorMessage && (
                <FormNotifications type="error" message={errorMessage} />
              )}
            </form>
          </div>
        </div>
      </Modal>
    );
  }
}
export default connect(null, {
  getTicketsAction,
})(TicketForm);
