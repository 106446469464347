import React from "react";

export default () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 0C4.02851 0 0 4.02923 0 8.99999C0.00456203 10.9522 0.629508 12.8436 1.77049 14.3584C1.86959 14.4971 1.93693 14.659 1.96703 14.831C1.99713 15.0031 1.98913 15.1804 1.94369 15.3484L1.43051 17.37C1.41391 17.4347 1.41375 17.5029 1.43004 17.5677C1.44633 17.6325 1.47847 17.6913 1.52308 17.738C1.5677 17.7848 1.62314 17.8177 1.68361 17.8333C1.74407 17.8489 1.80732 17.8467 1.86671 17.8269L4.43264 17.0688C4.67195 17.0002 4.92592 17.0235 5.1511 17.1346C6.36461 17.7079 7.67532 18.0026 9 18C13.9715 18 18 13.9708 18 8.99999C18 4.02923 13.9715 0 9 0ZM5.79259 6.92307H3.86814V8.30768H5.79259V11.7692C5.79259 11.9528 5.725 12.1289 5.6047 12.2588C5.4844 12.3886 5.32124 12.4615 5.1511 12.4615H2.58517V11.0769H4.50962V9.6923H2.58517V6.23076C2.58517 6.04715 2.65276 5.87106 2.77306 5.74123C2.89336 5.6114 3.05653 5.53846 3.22666 5.53846H5.79259V6.92307ZM11.5659 12.4615H10.283V6.92307H9.64148V12.4615H8.35852V6.92307H7.71703V12.4615H6.43407V6.23076C6.43407 6.04715 6.50165 5.87106 6.62196 5.74123C6.74226 5.6114 6.90542 5.53846 7.07555 5.53846H10.9244C11.0946 5.53846 11.2577 5.6114 11.378 5.74123C11.4983 5.87106 11.5659 6.04715 11.5659 6.23076V12.4615ZM15.4148 6.92307H13.4904V8.30768H15.4148V11.7692C15.4148 11.9528 15.3472 12.1289 15.2269 12.2588C15.1066 12.3886 14.9435 12.4615 14.7733 12.4615H12.2074V11.0769H14.1319V9.6923H12.2074V6.23076C12.2074 6.04715 12.275 5.87106 12.3953 5.74123C12.5156 5.6114 12.6788 5.53846 12.8489 5.53846H15.4148V6.92307Z"
      fill="#3C7BF6"
    />
  </svg>
);
