import React from "react";

export default () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.75 7.16667L7.68175 7.17075C7.55154 7.18611 7.4303 7.24488 7.33759 7.33759C7.24488 7.4303 7.18611 7.55154 7.17075 7.68175L7.16667 7.75V11.25H1.33217C1.17818 11.2503 1.03036 11.1895 0.921201 11.0809C0.812041 10.9722 0.750464 10.8247 0.75 10.6707V1.32925C0.75 1.00958 1.00958 0.75 1.32925 0.75H10.6707C10.9904 0.75 11.25 1.01075 11.25 1.33275V7.16667H7.75ZM11.25 8.33333L8.33333 11.2482V8.33333H11.25Z"
      fill="#757F8A"
    />
  </svg>
);
