import React, { useState, useEffect } from "react";
import { withRouter, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  Spinner,
  Redirect,
  LogoSolid,
  Twitter,
} from "../../../../assets/vectors";
import { Button } from "../../../../components/FormElements";
import Modal from "../../../../components/Modal";

import { getWindowBaseUrl } from "../../../../utils/functions";

import {
  getTwitterRequestTokenAction,
  postSubscribeTwitterUserAction,
  getAllChannelsAction,
  getFirmChannelsAction,
} from "../../../../redux/actions/Channels";

const TwitterForm = ({ history, close, dismissRoute }) => {
  const dispatch = useDispatch();
  const { isCreatingChannel } = useSelector((state) => state?.channels);
  let location = useLocation();

  const [currentStep, setCurrentStep] = useState(1);

  const oneroute_tw_token_secret =
    JSON.parse(localStorage.getItem("oneroute_tw_token_secret")) || null;
  const oauth_token =
    new URLSearchParams(location.search).get("oauth_token") || null;
  const oauth_verifier = new URLSearchParams(location.search).get(
    "oauth_verifier" || null
  );

  useEffect(() => {
    if (oauth_token && oneroute_tw_token_secret) postSubscribeTwitterUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oauth_token]);

  const getTwitterRequestToken = () => {
    const windowBaseUrl = getWindowBaseUrl() || "";

    dispatch(
      getTwitterRequestTokenAction({
        url: "/channels/twitter/oauth/request_token",
        params: {
          callbackUrl: `${windowBaseUrl}/3`,
        },
      })
    ).then((res) => {
      if (res?.data?.requestTokenSecret) {
        localStorage.setItem(
          "oneroute_tw_token_secret",
          JSON.stringify(res?.data?.requestTokenSecret)
        );
        window.location.assign(
          `https://api.twitter.com/oauth/authorize?oauth_token=${res?.data?.requestToken}`
        );
      }
    });
  };

  const postSubscribeTwitterUser = () => {
    const requestData = {
      requestTokenSecret: oneroute_tw_token_secret,
      requestToken: oauth_token,
      oauth_verifier: oauth_verifier,
    };

    dispatch(
      postSubscribeTwitterUserAction({
        url: "/channels/twitter/subscribe",
        requestData,
      })
    ).then((res) => {
      if (res?.status === 200) {
        dispatch(getAllChannelsAction());
        dispatch(getFirmChannelsAction());
        setCurrentStep(2);
        localStorage.removeItem("oneroute_tw_token_secret");
      }
    });
  };

  return (
    <React.Fragment>
      {currentStep === 1 && (
        <Modal className="channel-modal">
          <div className="modal-content new-conversation-container">
            <div className="modal-title">
              You are about to be redirected to Twitter
            </div>
            <div className="modal-form new-conversation-form">
              <h6>
                Clicking continue will redirect you to Twitter. From there, you
                would need to authorize OneRoute to properly connect to your
                Twitter DM’s
              </h6>
              <div className="logos">
                <LogoSolid />
                <Redirect />
                <Twitter />
              </div>
              <div className="actions">
                {!isCreatingChannel ? (
                  <>
                    <Button className="secondary-button" onClick={close}>
                      Cancel
                    </Button>
                    <Button
                      className="primary-button"
                      onClick={getTwitterRequestToken}
                    >
                      Continue with Twitter
                    </Button>
                  </>
                ) : (
                  <div className="spinner">
                    <Spinner />
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal>
      )}

      {currentStep === 2 && (
        <Modal className="channel-modal">
          <div className="modal-content new-conversation-container">
            <div className="modal-title">Channel setup complete</div>
            <div className="modal-form new-conversation-form">
              <div className="d-flex">
                <div className="info">
                  <ul>
                    <li>You can now send and receive messages via Twitter.</li>
                  </ul>
                </div>
              </div>
              <div className="actions">
                <Button
                  className="primary-button"
                  onClick={() => {
                    setCurrentStep(null);
                    history.push(
                      dismissRoute ? dismissRoute : "/settings/channels"
                    );
                  }}
                >
                  Dismiss
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default withRouter(TwitterForm);
