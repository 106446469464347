import {
  getRequest,
  postRequest,
  patchRequest,
  deleteRequest,
} from "../../api";

import {
  GET_CSAT_LOADER,
  GET_CSAT_RESPONSE,
  CREATE_CSAT_LOADER,
  CREATE_CSAT_RESPONSE,
  CSAT_RESET,
  UPDATE_CSAT_LOADER,
  UPDATE_CSAT_RESPONSE,
  DELETE_CSAT_LOADER,
  DELETE_CSAT_RESPONSE,
} from "../constants";

import { getRequestError } from "../../utils/functions";

export const createCsatLoader = (isCreatingCsat) => ({
  type: CREATE_CSAT_LOADER,
  isCreatingCsat,
});

export const createCsatResponse = (createCsatOutcome, createCsatMessage) => ({
  type: CREATE_CSAT_RESPONSE,
  createCsatOutcome,
  createCsatMessage,
});

export const getCsatLoader = (isLoadingCsat) => ({
  type: GET_CSAT_LOADER,
  isLoadingCsat,
});

export const getCsatResponse = (
  csatOutcome,
  csatData,
  csatMeta,
  csatMessage
) => ({
  type: GET_CSAT_RESPONSE,
  csatOutcome,
  csatData,
  csatMeta,
  csatMessage,
});

export const csatReset = () => ({
  type: CSAT_RESET,
});

export const updateCsatLoader = (isUpdatingCsat) => ({
  type: UPDATE_CSAT_LOADER,
  isUpdatingCsat,
});

export const updateCsatResponse = (updateCsatOutcome, updateCsatMessage) => ({
  type: UPDATE_CSAT_RESPONSE,
  updateCsatOutcome,
  updateCsatMessage,
});

export const deleteCsatLoader = (isDeletingCsat) => ({
  type: DELETE_CSAT_LOADER,
  isDeletingCsat,
});

export const deleteCsatResponse = (deleteCsatOutcome, deleteCsatMessage) => ({
  type: DELETE_CSAT_RESPONSE,
  deleteCsatOutcome,
  deleteCsatMessage,
});

export const createCsatAction = (data) => {
  return async (dispatch) => {
    dispatch(csatReset());
    dispatch(createCsatLoader(true));

    try {
      const res = await postRequest({
        url: "/firms/csat",
        data,
        token: true,
      });

      if (res?.status === 201) {
        dispatch(createCsatResponse("success", res?.data?.message));
        dispatch(createCsatLoader(false));
        return res?.status;
      }
    } catch (error) {
      const message = getRequestError(error);

      dispatch(createCsatResponse("error", message));
      dispatch(createCsatLoader(false));
    }
  };
};

export const getCsatAction = (params = {}) => {
  return async (dispatch) => {
    dispatch(csatReset());
    dispatch(getCsatLoader(true));

    try {
      const res = await getRequest({
        url: "/firms/csat",
        params,
        token: true,
      });

      const { data } = res.data;

      dispatch(getCsatResponse("success", data || {}, {}, ""));
      dispatch(getCsatLoader(false));
    } catch (error) {
      const message = getRequestError(error);

      dispatch(getCsatResponse("error", [], {}, message));
      dispatch(getCsatLoader(false));
    }
  };
};

export const updateCsatAction = (data, id) => {
  return async (dispatch) => {
    dispatch(csatReset());
    dispatch(updateCsatLoader(true));

    try {
      const res = await patchRequest({
        url: `/firms/csat/${id}`,
        data,
        token: true,
      });

      if (res?.status === 200) {
        dispatch(updateCsatResponse("success", res?.data?.message));
        dispatch(updateCsatLoader(false));
        return res?.status;
      }
    } catch (error) {
      const message = getRequestError(error);

      dispatch(updateCsatResponse("error", message));
      dispatch(updateCsatLoader(false));
    }
  };
};

export const deleteCsatAction = (id) => {
  return async (dispatch) => {
    dispatch(csatReset());
    dispatch(deleteCsatLoader(true));

    try {
      const res = await deleteRequest({
        url: `/firms/csat/${id}`,
        token: true,
      });

      if (res?.status === 200) {
        dispatch(deleteCsatResponse("success", res?.data?.message));
        dispatch(deleteCsatLoader(false));
        return res?.status;
      }
    } catch (error) {
      const message = getRequestError(error);

      dispatch(deleteCsatResponse("error", message));
      dispatch(deleteCsatLoader(false));
    }
  };
};
