import React from "react";

export default () => (
  <svg
    width="150"
    height="150"
    viewBox="0 0 150 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle opacity="0.1" cx="75" cy="75" r="75" fill="#4BA3E7" />
    <circle
      opacity="0.2"
      cx="74.5716"
      cy="75.0002"
      r="62.1429"
      fill="#4BA3E7"
    />
    <circle cx="74.5709" cy="74.9999" r="46.2857" fill="#4BA3E7" />
    <path
      d="M63.7998 73.8267L71.4798 81.5067L85.9865 67"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="square"
    />
  </svg>
);
