import React from "react";

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      stroke="#39206A"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.25"
      d="M9.375 15.625a1.25 1.25 0 01-1.25-1.25v-6.25a1.25 1.25 0 011.25-1.25h8.75a1.25 1.25 0 011.25 1.25v6.25a1.25 1.25 0 01-1.25 1.25h-1.25v3.75l-3.75-3.75h-3.75z"
    ></path>
    <path
      stroke="#39206A"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.25"
      d="M5.625 10.625l-2.5 2.5v-3.75h-1.25a1.25 1.25 0 01-1.25-1.25v-6.25a1.25 1.25 0 011.25-1.25h8.75a1.25 1.25 0 011.25 1.25v2.5"
    ></path>
  </svg>
);
