import React from "react";

import Modal from "../../../components/Modal";

const ViewTeamModal = ({ toggleForm, teamData }) => {
  const defaultUsers = teamData?.Users?.map((x) => ({
    label: `${x?.firstName} ${x?.lastName}`,
    value: x?.id,
  }));
  const defaultChannels = teamData?.Channels?.map((x) => ({
    label: `(${x?.medium}) - ${x?.name}`,
    value: x?.id,
  }));

  return (
    <Modal closeModal={() => toggleForm()}>
      <div className="modal-content">
        <div className="modal-title">View Team</div>
        <div className="modal-form saved-response-form">
          <div className="form">
            <div className="info">
              <p>Name</p>
              <h5>{teamData?.name}</h5>
            </div>

            <div className="info">
              <p>Users</p>
              <div className="listing">
                {defaultUsers?.map((item, i) => (
                  <div key={i} className="list-item">
                    {item?.label}
                  </div>
                ))}
              </div>
            </div>

            <div className="info">
              <p>Channels</p>
              <div className="listing">
                {defaultChannels?.map((item, i) => (
                  <div key={i} className="list-item">
                    {item?.label}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ViewTeamModal;
