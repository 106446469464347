import React from "react";

export default () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.3743 14.375C19.3743 15.3639 19.081 16.3306 18.5316 17.1529C17.9822 17.9751 17.2013 18.616 16.2877 18.9944C15.3741 19.3728 14.3687 19.4719 13.3988 19.2789C12.4289 19.086 11.538 18.6098 10.8387 17.9105C10.1395 17.2113 9.66327 16.3204 9.47034 15.3505C9.27742 14.3805 9.37643 13.3752 9.75487 12.4616C10.1333 11.548 10.7742 10.7671 11.5964 10.2177C12.4187 9.66825 13.3854 9.375 14.3743 9.375"
      stroke="#474F58"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.3742 10.625L14.8158 15.1825C14.7578 15.2406 14.6889 15.2867 14.613 15.3181C14.5372 15.3495 14.4559 15.3657 14.3738 15.3657C14.2916 15.3657 14.2103 15.3495 14.1345 15.3181C14.0586 15.2867 13.9897 15.2406 13.9317 15.1825L12.5 13.75"
      stroke="#474F58"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.8525 16.5833L3.125 19.375V15.625H1.875C1.54348 15.625 1.22554 15.4933 0.991117 15.2589C0.756696 15.0245 0.625 14.7065 0.625 14.375V1.875C0.625 1.54348 0.756696 1.22554 0.991117 0.991117C1.22554 0.756696 1.54348 0.625 1.875 0.625H18.125C18.4565 0.625 18.7745 0.756696 19.0089 0.991117C19.2433 1.22554 19.375 1.54348 19.375 1.875V8.125"
      stroke="#474F58"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
