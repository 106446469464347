import React, { Component } from "react";
import { connect } from "react-redux";
import * as shallowequal from "shallowequal";

import {
  createAgentsAction,
  updateAgentsAction,
} from "../../../redux/actions/Agents";

import { Button, TextField, Select } from "../../../components/FormElements";
import Modal from "../../../components/Modal";
import FormNotifications from "../../../components/Notifications";

import { patchFormValues, getFormValues } from "../../../utils/functions";

const initialFormData = {
  firstName: {
    value: "",
    valid: false,
  },
  lastName: {
    value: "",
    valid: false,
  },
  email: {
    value: "",
    valid: false,
  },
  roleId: {
    value: "",
    valid: false,
  },
};

class UserForm extends Component {
  constructor(props) {
    super(props);

    const { userData } = props;

    const formData = patchFormValues(initialFormData, {
      ...userData,
      roleId: userData?.role_id,
    });

    this.state = {
      formData: formData ? formData : { ...initialFormData },
    };
  }

  handleChange = ({ target }, valid) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [target.name]: {
          value: target.value,
          valid,
        },
      },
    });
  };

  checkFormValidity = () => {
    return Object.values(this.state.formData).every(({ valid }) => valid);
  };

  submitForm = (e) => {
    e.preventDefault();
    const { userData, createAgentsAction, updateAgentsAction, toggleUserForm } =
      this.props;

    const formData = getFormValues(this.state.formData);
    userData
      ? updateAgentsAction(formData, userData.id, toggleUserForm)
      : createAgentsAction(formData, toggleUserForm);
  };

  componentDidUpdate(prevProps) {
    const { userData, createAgentsOutcome } = this.props;

    if (
      !shallowequal(prevProps.userData, userData) &&
      Object.keys(userData).length
    ) {
      const formData = patchFormValues(initialFormData, userData);

      this.setState({
        formData,
      });
    }

    if (
      prevProps.createAgentsOutcome !== createAgentsOutcome &&
      createAgentsOutcome === "success"
    ) {
      this.setState({
        formData: { ...initialFormData },
      });
    }
  }

  render() {
    const { formData } = this.state;
    const {
      isCreatingAgent,
      isUpdatingAgent,
      toggleUserForm,
      createAgentsOutcome,
      createAgentsMessage,
      updateAgentsOutcome,
      updateAgentsMessage,
      userData,
      rolesData,
    } = this.props;

    const { firstName, lastName, email, roleId } = formData;

    const roleOptions =
      rolesData?.map((x) => ({
        label: x?.name,
        value: x?.id,
      })) || [];

    return (
      <Modal closeModal={() => toggleUserForm()}>
        <div className="modal-content">
          <div className="modal-title">
            {userData ? "Edit" : "Add"} New User
          </div>
          <div className="modal-form agent-form">
            <form onSubmit={this.submitForm}>
              <div className="form-row mb-15">
                <TextField
                  label="First Name"
                  name="firstName"
                  value={firstName.value}
                  onChange={(e, valid) => this.handleChange(e, valid)}
                />
                <TextField
                  label="Last Name"
                  name="lastName"
                  value={lastName.value}
                  onChange={(e, valid) => this.handleChange(e, valid)}
                />
              </div>
              <TextField
                type="email"
                label="Email Address"
                name="email"
                value={email.value}
                onChange={(e, valid) => this.handleChange(e, valid)}
                className="mb-15"
              />
              <Select
                label="Role"
                name="roleId"
                options={roleOptions}
                value={roleId.value}
                onChange={(e, valid) => this.handleChange(e, valid)}
                className="mb-35"
              />
              <div className="modal-form-action">
                <Button
                  type="button"
                  className="secondary-button"
                  onClick={() => toggleUserForm()}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  className="primary-button"
                  disabled={
                    !this.checkFormValidity() ||
                    isCreatingAgent ||
                    isUpdatingAgent
                  }
                >
                  {isCreatingAgent || isUpdatingAgent
                    ? "Processing..."
                    : userData
                    ? "Save Changes"
                    : "Add User"}
                </Button>
              </div>

              {createAgentsOutcome === "error" && (
                <FormNotifications type="error" message={createAgentsMessage} />
              )}
              {updateAgentsOutcome === "error" && (
                <FormNotifications type="error" message={updateAgentsMessage} />
              )}
            </form>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = ({ agents }) => agents;

export default connect(mapStateToProps, {
  createAgentsAction,
  updateAgentsAction,
})(UserForm);
