import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Button } from "../../components/FormElements";
import Toaster from "../../components/Toaster";

import AddChannelModal from "./AddChannelModal";
import EmailProForm from "../Settings/Channels/SetupForms/EmailProForm";
import WhatsappForm from "../Settings/Channels/SetupForms/WhatsappForm";
import FacebookForm from "../Settings/Channels/SetupForms/FacebookForm";
import InstagramForm from "../Settings/Channels/SetupForms/InstagramForm";
import LiveChatForm from "../Settings/Channels/SetupForms/LiveChatForm";
import SmsForm from "../Settings/Channels/SetupForms/SmsForm";

import { setOnboardingStep } from "../../redux/actions/Auth";
import {
  getAllChannelsAction,
  getFirmChannelsAction,
} from "../../redux/actions/Channels";
import { getPlansDataAction } from "../../redux/actions/Billing";
import { patchFirmOnboardingAction } from "../../redux/actions/AccountSettings";

import {
  getChannelIdentifierIcon,
  getUserDetails,
} from "../../utils/functions";

import {
  APP_ONEROUTE_USER,
  APP_SENT_FIRST_CONVO_REPLY,
  APP_CREATED_WIDGET,
  APP_CREATED_FIRST_BROADCAST_MSG,
  APP_INVITED_TEAM_MEMBER,
  APP_EVENT_RECEIVED_FIRST_MSG,
  APP_EVENT_SENT_FIRST_MSG,
} from "../../utils/constants";
import {
  AwaitingConvo,
  Send,
  OmniChannel,
  Broadcast,
  Channels,
  CustomerCare,
  CircularCheckGreen,
} from "../../assets/vectors";

const GetStarted = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { firstName, lastName, email, plan, firm_onboarding, firm_id, id } =
    getUserDetails();

  const { firmChannelsData } = useSelector((state) => state.channels);
  const { onboardingStep } = useSelector((state) => state.auth);

  const [firmPlan, setFirmPlan] = useState(null);
  const [showAddChannelForm, setShowAddChannelForm] = useState(false);
  const [authChannel, setAuthChannel] = useState(null);
  const [toaster, setToaster] = useState({});

  useEffect(() => {
    firm_onboarding && dispatch(setOnboardingStep(firm_onboarding));

    dispatch(getPlansDataAction()).then((res) => {
      setFirmPlan(res?.find((x) => x?.planCode === plan));
    });
    dispatch(getAllChannelsAction());
    dispatch(getFirmChannelsAction());

    if (id) setAuthChannel(parseInt(id));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const whatsappChannel = firmChannelsData
    ?.filter((x) => x?.status === "ACTIVE")
    ?.find((x) => x?.medium === "WHATSAPP");
  const allChannels = firmChannelsData?.filter((x) => x?.status === "ACTIVE");

  const sentFirstConvoReply =
    JSON.parse(window.localStorage.getItem(APP_SENT_FIRST_CONVO_REPLY)) || null;

  const createdWidget =
    JSON.parse(window.localStorage.getItem(APP_CREATED_WIDGET)) || null;

  const createdFirstBroadcastMsg =
    JSON.parse(window.localStorage.getItem(APP_CREATED_FIRST_BROADCAST_MSG)) ||
    null;

  const invitedTeamMember =
    JSON.parse(window.localStorage.getItem(APP_INVITED_TEAM_MEMBER)) || null;

  useEffect(() => {
    // if (onboardingStep > 1 && allChannels?.length === 0) {
    //   saveOnboardingProgress(1);
    // }
    if (onboardingStep === 1 && allChannels?.length > 0) {
      saveOnboardingProgress(2);
    }
    if (onboardingStep === 2 && sentFirstConvoReply?.firm_id === firm_id) {
      saveOnboardingProgress(3);
      window.dataLayer.push({
        event: APP_EVENT_RECEIVED_FIRST_MSG,
        agent_name: `${firstName} ${lastName}`,
        email: email,
        channel: sentFirstConvoReply?.channel || "WHATSAPP",
      });
      window.dataLayer.push({
        event: APP_EVENT_SENT_FIRST_MSG,
        agent_name: `${firstName} ${lastName}`,
        email: email,
        channel: sentFirstConvoReply?.channel || "WHATSAPP",
      });
    }
    if (onboardingStep === 3 && createdWidget?.firm_id === firm_id) {
      saveOnboardingProgress(4);
    }
    if (onboardingStep === 4 && createdFirstBroadcastMsg?.firm_id === firm_id) {
      saveOnboardingProgress(5);
    }
    if (onboardingStep === 5 && invitedTeamMember?.firm_id === firm_id) {
      saveOnboardingProgress(6);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onboardingStep, allChannels]);

  const saveOnboardingProgress = (value) => {
    dispatch(setOnboardingStep(value));

    const loggedInUser =
      JSON.parse(window.localStorage.getItem(APP_ONEROUTE_USER)) || null;

    const onSuccess = () => {
      localStorage.setItem(
        APP_ONEROUTE_USER,
        JSON.stringify({
          token: loggedInUser?.token,
          user: {
            ...loggedInUser?.user,
            firm_onboarding: value,
          },
        })
      );
    };

    dispatch(patchFirmOnboardingAction({ step: value }, onSuccess));
  };

  const handleChannelAuth = (medium) => {
    switch (medium) {
      case "whatsapp":
        return setAuthChannel(1);
      case "facebook messenger":
        return setAuthChannel(2);
      case "instagram":
        return setAuthChannel(3);
      case "email":
        return setAuthChannel(4);
      case "livechat":
        return setAuthChannel(5);
      case "sms":
        return setAuthChannel(6);
      default:
        return;
    }
  };

  const closeForm = () => {
    setAuthChannel(null);
    history.push("/get-started");
    setShowAddChannelForm(true);
  };

  const checkChannelStatus = (medium) => {
    let isConnected = firmChannelsData?.filter(
      (x) => x?.medium === medium?.toUpperCase() && x?.status === "ACTIVE"
    );
    return isConnected?.[0]?.status ? true : false;
  };

  const areAllChannelsConnected = (name) => {
    let response = true;

    let connectedChannels = firmChannelsData?.filter(
      (x) => x?.status === "ACTIVE"
    );

    if (!connectedChannels?.find((x) => x?.medium === "WHATSAPP")) {
      response = false;
    }
    if (!connectedChannels?.find((x) => x?.medium === "FACEBOOK")) {
      response = false;
    }
    if (!connectedChannels?.find((x) => x?.medium === "INSTAGRAM")) {
      response = false;
    }
    if (!connectedChannels?.find((x) => x?.medium === "SMS")) {
      response = false;
    }
    if (!connectedChannels?.find((x) => x?.medium === "EMAIL")) {
      response = false;
    }
    if (!connectedChannels?.find((x) => x?.medium === "WIDGET")) {
      response = false;
    }

    return response;
  };

  const todos = [
    {
      id: 1,
      icon: <Channels />,
      title: <>Connect channels to manage your conversations with customers.</>,
      action: (disabled) => (
        <Button
          className="primary-button"
          onClick={() => setShowAddChannelForm(true)}
          disabled={areAllChannelsConnected()}
        >
          Connect Channels
        </Button>
      ),
    },
    {
      id: 2,
      icon: <Send />,
      title: <>Send a message to any of your connected channels.</>,
      action: whatsappChannel
        ? (disabled) => (
            <Button
              className="primary-button"
              onClick={() => {
                window.open(
                  `https://api.whatsapp.com/send?phone=${whatsappChannel?.phone}&text=Hello OneRoute`
                );
              }}
              disabled={disabled}
            >
              Send message
            </Button>
          )
        : null,
    },
    {
      id: 2,
      icon: <AwaitingConvo />,
      title: (
        <>
          Reply the message received.
          <br />
          Go to Conversations {">"} Queue {">"} Accept and then send a reply.
        </>
      ),
      action: (disabled) => (
        <Button
          className="primary-button"
          onClick={() => history.push("/conversations")}
          disabled={disabled}
        >
          Go to Conversations
        </Button>
      ),
    },
    {
      id: 3,
      icon: <OmniChannel />,
      title: (
        <>
          Add a customized Widget to your website.
          <br />
          This can be used by your customers to initiate new conversations.
        </>
      ),
      action: (disabled) => (
        <Button
          className="primary-button"
          onClick={() => history.push("/settings/omnichannel-widgets")}
          disabled={disabled}
        >
          Create Widget
        </Button>
      ),
    },
    {
      id: 4,
      icon: <Broadcast />,
      title: (
        <>
          Send a Broadcast message.
          <br />
          This allows you send messages to multiple customers at once.
        </>
      ),
      action: (disabled) => (
        <Button
          className="primary-button"
          onClick={() => history.push("/broadcasts")}
          disabled={disabled}
        >
          Send Broadcast
        </Button>
      ),
    },
    {
      id: 5,
      icon: <CustomerCare />,
      title: <>Invite your Team members.</>,
      action: (disabled) => (
        <Button
          className="primary-button"
          onClick={() => history.push("/settings/users")}
          disabled={disabled}
        >
          Invite Team
        </Button>
      ),
    },
    {
      id: 6,
      iconUrl: getChannelIdentifierIcon("LIVECHAT"),
      title: (
        <>
          Click to get a quick tour of the sidebar to get familiar and learn how
          to find your way around OneRoute.
        </>
      ),
      action: null,
    },
  ];

  return (
    <div className="get-started-container">
      <div className="content">
        <p className="title">
          Complete the following steps to get the most out of OneRoute.
        </p>

        <div className="todos">
          {todos?.map(({ id, iconUrl, icon, title, action }, i) => {
            var style = "";
            var isDone = false;
            var btnDisabled = false;

            if (id === onboardingStep) style = " active";
            if (id < onboardingStep) {
              style = " done";
              isDone = true;
              btnDisabled = true;
            }
            if (id > onboardingStep) {
              style = " disabled";
              btnDisabled = true;
            }

            return (
              <div key={i} className={`item${style}`}>
                {isDone ? (
                  <div className="check">
                    <CircularCheckGreen />
                  </div>
                ) : iconUrl ? (
                  <img src={iconUrl} alt="" />
                ) : (
                  icon
                )}

                <div className="info">
                  <p>{title}</p>

                  {action && action(btnDisabled)}
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <Toaster {...toaster} closeToaster={() => setToaster({})} />

      {showAddChannelForm && (
        <AddChannelModal
          close={() => setShowAddChannelForm(false)}
          selectChannel={handleChannelAuth}
          checkChannelStatus={checkChannelStatus}
        />
      )}

      {authChannel === 1 && (
        <WhatsappForm
          firmPlan={firmPlan}
          close={closeForm}
          dismissRoute="/get-started"
        />
      )}

      {authChannel === 2 && (
        <FacebookForm close={closeForm} dismissRoute="/get-started" />
      )}

      {authChannel === 3 && (
        <InstagramForm close={closeForm} dismissRoute="/get-started" />
      )}

      {authChannel === 4 && (
        <EmailProForm close={closeForm} dismissRoute="/get-started" />
      )}

      {authChannel === 5 && (
        <LiveChatForm close={closeForm} dismissRoute="/get-started" />
      )}

      {authChannel === 6 && (
        <SmsForm close={closeForm} dismissRoute="/get-started" />
      )}
    </div>
  );
};

export default GetStarted;
