import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getTemplateCategoriesAction,
  getTemplateMessageAction,
  deleteTemplateAction,
} from "../../../redux/actions/TemplateMessage";
import { getFirmChannelsAction } from "../../../redux/actions/Channels";

import TemplateMessageForm from "./TemplateMessageForm";
import TemplateMessagePreview from "./TemplateMessagePreview";

import { Button, Select } from "../../../components/FormElements";
import Table from "../../../components/Table";
import OptionsDropdown from "../../../components/OptionsDropdown";
import Modal from "../../../components/Modal";
import Toaster from "../../../components/Toaster";

import { CircularPlus, Filter, Trash } from "../../../assets/vectors";
import { templatesPermissions } from "../../../utils/permissions";

const TemplateMessages = () => {
  const dispatch = useDispatch();
  const {
    isLoadingTemplateCategories,
    isLoadingTemplateMessage,
    templateMessageData,
  } = useSelector((state) => state?.templateMessage);
  const { firmChannelsData } = useSelector((state) => state?.channels);

  const [isTemplateMessageFormActive, setIsTemplateMessageFormActive] =
    useState(false);
  const [isTemplatePreviewModalActive, setIsTemplatePreviewModalActive] =
    useState(false);
  const [selectedTemplateMessage, setSelectedTemplateMessage] = useState(null);
  const [templateCategoriesData, setTemplateCategoriesData] = useState([]);
  const [templateMessageInfo, setTemplateMessageInfo] = useState(true);
  const [channelId, setChannelId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [toaster, setToaster] = useState({});
  const [hasTemplateBeenFetchedOnMount, setHasTemplateBeenFetchedOnMount] =
    useState(false);

  useEffect(() => {
    dispatch(getTemplateCategoriesAction()).then((res) => {
      if (res?.success === true) {
        setTemplateCategoriesData(res?.body);
      }
    });
    dispatch(getFirmChannelsAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const connectedChannelsData = firmChannelsData?.filter(
    (x) => x.status === "ACTIVE" && x.medium === "WHATSAPP"
  );
  const channelOptions = connectedChannelsData?.map((x) => ({
    label: x?.name || x?.phone,
    value: x?.id,
  }));

  useEffect(() => {
    if (channelOptions?.length > 0 && hasTemplateBeenFetchedOnMount === false) {
      setHasTemplateBeenFetchedOnMount(true);
      dispatch(
        getTemplateMessageAction({ channelId: channelOptions?.[0]?.value })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelOptions]);

  const toggleTemplateMessageForm = () => {
    setIsTemplateMessageFormActive(!isTemplateMessageFormActive);
  };

  const handleChannelChange = ({ target }) => {
    setChannelId(target.value);
  };
  useEffect(() => {
    if (channelId) {
      dispatch(getTemplateMessageAction({ channelId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelId]);

  const deleteTemplate = () => {
    const idToUse = channelId || channelOptions?.[0]?.value;

    dispatch(
      deleteTemplateAction({
        channel_id: idToUse,
        template_name: selectedTemplateMessage?.name,
      })
    ).then((res) => {
      if (res?.success === true) {
        dispatch(getTemplateMessageAction({ channelId: idToUse }));
        setSelectedTemplateMessage(null);
        setShowDeleteModal(false);
        openToaster("success", "Template deleted successfully!");
      } else {
        openToaster("error", res?.message);
      }
    });
  };

  const openToaster = (status, content) => {
    setToaster({
      open: "open",
      status,
      content,
    });
  };

  const closeToaster = () => {
    setToaster({
      ...toaster,
      open: "closed",
    });
  };

  const moreOptions = () => {
    var options = [];

    if (templatesPermissions?.update || templatesPermissions?.modifyAll) {
      options = [
        ...options,
        {
          label: <div className="red">Delete template</div>,
          value: "delete",
        },
      ];
    }

    return options;
  };

  const optionsAction = (type, row) => {
    setSelectedTemplateMessage(row);

    if (type === "delete") {
      setShowDeleteModal(true);
    }
  };

  const columns = [
    {
      id: "name",
      label: "Name",
      render: (column) => (
        <div
          className="saved-response-message link"
          onClick={() => {
            setIsTemplatePreviewModalActive(true);
            setSelectedTemplateMessage(column);
          }}
        >
          <span>{column?.name}</span>
        </div>
      ),
    },
    {
      id: "category",
      label: "Category",
      render: ({ category }) => (
        <div className="saved-response-message">
          <span>{category}</span>
        </div>
      ),
    },
    {
      id: "text",
      label: "Preview",
      render: ({ components }) => (
        <div className="saved-response-message ellipsis">
          <span>{components?.[0]?.text}</span>
        </div>
      ),
    },
    {
      id: "status",
      label: "Status",
      render: ({ status }) => (
        <div className="saved-response-message">
          <span className="status">{status}</span>
        </div>
      ),
    },
    {
      id: "",
      label: "Actions",
      thClassName: "text-center",
      render: (row) => (
        <div className="text-center">
          <OptionsDropdown
            onClick={(val) => {
              optionsAction(val, row);
            }}
            options={moreOptions()}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="settings-page-container">
      <div className="page-header">
        <span className="title">Whatsapp Template Messages</span>
      </div>
      {templateMessageInfo && (
        <div className="settings-page-info">
          <div>
            <span className="title">What are Template Messages?</span>
            <span className="description">
              Whatsapp template messages refer to pre-approved message templates
              used for outbound notifications like OTP and appointment
              reminders.
            </span>
            <Button
              className="plain-button"
              onClick={() => setTemplateMessageInfo(false)}
            >
              Got it
            </Button>
          </div>
          <div className="image">
            <img
              src={require("../../../assets/images/documents.png").default}
              alt=""
            />
          </div>
        </div>
      )}
      <div className="table-container saved-responses-table template-messages-table">
        <div className="table-filter">
          <div className="table-search-filter">
            <Select
              leftIcon={<Filter />}
              options={[
                { label: "Filter by Channel", value: "" },
                ...(channelOptions || []),
              ]}
              className="mr-15"
              onChange={(e) => handleChannelChange(e)}
              filter
            />
          </div>
          <div className="table-other-filters">
            {(templatesPermissions.create ||
              templatesPermissions.modifyAll) && (
              <Button
                className="primary-button small"
                onClick={() => toggleTemplateMessageForm()}
              >
                <span className="icon">
                  <CircularPlus />
                </span>
                <span>New template message</span>
              </Button>
            )}
          </div>
        </div>

        <Table
          loading={isLoadingTemplateCategories || isLoadingTemplateMessage}
          columns={columns}
          rows={templateMessageData}
          noDataText="No data available. Filter by Channel above"
        />
      </div>

      {isTemplateMessageFormActive && (
        <TemplateMessageForm
          channelOptions={channelOptions}
          templateCategoriesData={templateCategoriesData}
          toggleTemplateMessageForm={toggleTemplateMessageForm}
        />
      )}

      {isTemplatePreviewModalActive && (
        <TemplateMessagePreview
          close={() => setIsTemplatePreviewModalActive(false)}
          templateMessage={selectedTemplateMessage}
        />
      )}

      {showDeleteModal && (
        <Modal closeModal={() => setShowDeleteModal(false)} small>
          <div className="modal-content confirmation-modal">
            <div className="modal-icon red">
              <Trash />
            </div>
            <div className="modal-title">
              Are you sure you want to delete this template?
            </div>
            <div className="modal-text">This action cannot be reversed</div>
            <div className="modal-action">
              <Button
                className="secondary-button red"
                type="button"
                disabled={isLoadingTemplateMessage}
                onClick={deleteTemplate}
              >
                {isLoadingTemplateMessage ? "Processing..." : "Delete template"}
              </Button>
              <Button
                className="plain-button black"
                type="button"
                onClick={() => setShowDeleteModal(false)}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      )}

      <Toaster {...toaster} closeToaster={closeToaster} />
    </div>
  );
};

export default TemplateMessages;
