import {
  GET_AUTO_RESPONSES_LOADER,
  GET_AUTO_RESPONSES_RESPONSE,
  AUTO_RESPONSES_RESET,
  CREATE_AUTO_RESPONSE_LOADER,
  CREATE_AUTO_RESPONSE_RESPONSE,
  UPDATE_AUTO_RESPONSE_LOADER,
  UPDATE_AUTO_RESPONSE_RESPONSE,
  DELETE_AUTO_RESPONSE_LOADER,
  DELETE_AUTO_RESPONSE_RESPONSE,
} from "../constants";

const INIT_STATE = {
  autoResponsesData: [],
  autoResponsesMeta: {
    limit: 10,
    offset: 0,
    total: 0,
  },
  isCreatingAutoResponse: false,
  isUpdatingAutoResponse: false,
  isDeletingAutoResponse: false,
  isLoadingAutoResponses: true,
  createAutoResponseOutcome: "",
  createAutoResponseMessage: "",
  updateAutoResponseOutcome: "",
  updateAutoResponseMessage: "",
  deleteAutoResponseOutcome: "",
  deleteAutoResponseMessage: "",
  autoResponsesOutcome: "",
  autoResponsesMessage: "",
};

const AutoResponses = (state = INIT_STATE, action) => {
  const {
    type,
    createAutoResponseOutcome,
    createAutoResponseMessage,
    updateAutoResponseOutcome,
    updateAutoResponseMessage,
    deleteAutoResponseOutcome,
    deleteAutoResponseMessage,
    autoResponsesOutcome,
    autoResponsesMessage,
    autoResponsesData,
    autoResponsesMeta,
    isCreatingAutoResponse,
    isUpdatingAutoResponse,
    isDeletingAutoResponse,
    isLoadingAutoResponses,
  } = action;

  switch (type) {
    case AUTO_RESPONSES_RESET: {
      return {
        ...state,
        createAutoResponseOutcome: "",
        createAutoResponseMessage: "",
        updateAutoResponseOutcome: "",
        updateAutoResponseMessage: "",
        deleteAutoResponseOutcome: "",
        deleteAutoResponseMessage: "",
      };
    }

    case GET_AUTO_RESPONSES_LOADER: {
      return {
        ...state,
        isLoadingAutoResponses,
      };
    }

    case GET_AUTO_RESPONSES_RESPONSE: {
      return {
        ...state,
        autoResponsesOutcome,
        autoResponsesData,
        autoResponsesMeta,
        autoResponsesMessage,
      };
    }

    case CREATE_AUTO_RESPONSE_LOADER: {
      return {
        ...state,
        isCreatingAutoResponse,
      };
    }

    case CREATE_AUTO_RESPONSE_RESPONSE: {
      return {
        ...state,
        createAutoResponseOutcome,
        createAutoResponseMessage,
      };
    }

    case UPDATE_AUTO_RESPONSE_LOADER: {
      return {
        ...state,
        isUpdatingAutoResponse,
      };
    }

    case UPDATE_AUTO_RESPONSE_RESPONSE: {
      return {
        ...state,
        updateAutoResponseOutcome,
        updateAutoResponseMessage,
      };
    }

    case DELETE_AUTO_RESPONSE_LOADER: {
      return {
        ...state,
        isDeletingAutoResponse,
      };
    }

    case DELETE_AUTO_RESPONSE_RESPONSE: {
      return {
        ...state,
        deleteAutoResponseOutcome,
        deleteAutoResponseMessage,
      };
    }

    default:
      return state;
  }
};

export default AutoResponses;
