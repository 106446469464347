import {
  CREATE_CUSTOMERS_LOADER,
  CREATE_CUSTOMERS_RESPONSE,
  CREATE_CUSTOMERS_RESET,
  UPDATE_CUSTOMERS_LOADER,
  UPDATE_CUSTOMERS_RESPONSE,
  UPDATE_CUSTOMERS_RESET,
  GET_CUSTOMERS_LOADER,
  GET_CUSTOMERS_RESPONSE,
  GET_CUSTOMERS_RESET,
  DELETE_CUSTOMERS_LOADER,
  DELETE_CUSTOMERS_RESPONSE,
  DELETE_CUSTOMERS_RESET,
  IMPORT_CUSTOMERS_LOADER,
  IMPORT_CUSTOMERS_RESPONSE,
} from "../constants";

const INIT_STATE = {
  customersData: [],
  customersMeta: {
    limit: 10,
    offset: 0,
    total: 0,
  },
  isCreatingCustomer: false,
  isUpdatingCustomer: false,
  isDeletingCustomer: false,
  isImportingCustomers: false,
  isLoadingCustomers: true,
  createCustomersOutcome: "",
  createCustomersMessage: "",
  updateCustomersOutcome: "",
  updateCustomersMessage: "",
  deleteCustomersOutcome: "",
  deleteCustomersMessage: "",
  customersOutcome: "",
  customersMessage: "",
  importCustomersOutcome: "",
  importCustomersMessage: "",
};

const Customers = (state = INIT_STATE, action) => {
  const {
    type,
    createCustomersOutcome,
    createCustomersMessage,
    updateCustomersOutcome,
    updateCustomersMessage,
    deleteCustomersOutcome,
    deleteCustomersMessage,
    importCustomersOutcome,
    importCustomersMessage,
    customersOutcome,
    customersMessage,
    customersData,
    customersMeta,
    isCreatingCustomer,
    isUpdatingCustomer,
    isDeletingCustomer,
    isImportingCustomers,
    isLoadingCustomers,
  } = action;

  switch (type) {
    case CREATE_CUSTOMERS_LOADER: {
      return {
        ...state,
        isCreatingCustomer,
      };
    }

    case CREATE_CUSTOMERS_RESPONSE: {
      return {
        ...state,
        createCustomersOutcome,
        createCustomersMessage,
      };
    }

    case CREATE_CUSTOMERS_RESET: {
      return {
        ...state,
        createCustomersOutcome: "",
        createCustomersMessage: "",
      };
    }

    case UPDATE_CUSTOMERS_LOADER: {
      return {
        ...state,
        isUpdatingCustomer,
      };
    }

    case UPDATE_CUSTOMERS_RESPONSE: {
      return {
        ...state,
        updateCustomersOutcome,
        updateCustomersMessage,
      };
    }

    case UPDATE_CUSTOMERS_RESET: {
      return {
        ...state,
        updateCustomersOutcome: "",
        updateCustomersMessage: "",
      };
    }

    case DELETE_CUSTOMERS_LOADER: {
      return {
        ...state,
        isDeletingCustomer,
      };
    }

    case DELETE_CUSTOMERS_RESPONSE: {
      return {
        ...state,
        deleteCustomersOutcome,
        deleteCustomersMessage,
      };
    }

    case DELETE_CUSTOMERS_RESET: {
      return {
        ...state,
        deleteCustomersOutcome: "",
        deleteCustomersMessage: "",
      };
    }

    case IMPORT_CUSTOMERS_LOADER: {
      return {
        ...state,
        isImportingCustomers,
      };
    }

    case IMPORT_CUSTOMERS_RESPONSE: {
      return {
        ...state,
        importCustomersOutcome,
        importCustomersMessage,
      };
    }

    case GET_CUSTOMERS_LOADER: {
      return {
        ...state,
        isLoadingCustomers,
      };
    }

    case GET_CUSTOMERS_RESPONSE: {
      return {
        ...state,
        customersOutcome,
        customersData,
        customersMeta,
        customersMessage,
      };
    }

    case GET_CUSTOMERS_RESET: {
      return INIT_STATE;
    }

    default:
      return state;
  }
};

export default Customers;
