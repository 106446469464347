import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import AsyncSelect from "react-select/async";
import DatePicker from "react-datepicker";
import * as moment from "moment";

import useCloseElement from "../CustomHooks/useCloseElement";

import { getConversationSearchAction } from "../../redux/actions/Conversations";
import { getCustomersAction } from "../../redux/actions/Customers";
import { getTagsAction } from "../../redux/actions/Tags";
import { getAgentsAction } from "../../redux/actions/Agents";
import { getGroupsAction } from "../../redux/actions/Groups";

import { getInitials } from "../../utils/functions";

import { Search, Tag, Close, Star } from "../../assets/vectors";
import { PAGE_SIZE } from "../../utils/constants";
import classNames from "classnames";

const GlobalSearch = ({
  isSearchActive,
  toggleIsSearchActive,
  defaultSearchParams,
  inChatSearch,
  closeOnClick,
  searchInMentions,
}) => {
  /**
   * Hook that closes the global search container when outside the container is clicked
   */
  const wrapperRef = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const agentUrlSearch = new URLSearchParams(search)?.get("agent_id");
  const statusUrlSearch = new URLSearchParams(search)?.get("status");
  const csatUrlSearch = new URLSearchParams(search)?.get("csat");
  const fromDateUrlSearch = new URLSearchParams(search)?.get("fromDate");
  const toDateUrlSearch = new URLSearchParams(search)?.get("toDate");

  const { isSearching, searchData } = useSelector(
    (state) => state.conversations
  );
  const { customersData } = useSelector((state) => state.customers);
  const { tagsData } = useSelector((state) => state.tags);
  const { agentsData } = useSelector((state) => state.agents);
  const { firmChannelsData } = useSelector((state) => state.channels);
  const { groupsData } = useSelector((state) => state.groups);

  const [params, setParams] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [initialSearchParams, setInitialSearchParams] = useState(
    defaultSearchParams ? true : false
  );
  const [searchConversationData, setSearchConversationData] = useState([]);
  const [isSearchOptionsActive, setIsSearchOptionsActive] = useState(false);
  const [showSearchResult, setShowSearchResult] = useState(false);
  const [selectedResult, setSelectedResult] = useState(null);

  const messageSearchValue = params?.find((x) => x?.key === "message")?.value;

  useEffect(() => {
    dispatch(getCustomersAction());
    dispatch(
      getTagsAction({
        limit: 50,
        page: 1,
      })
    );
    dispatch(getAgentsAction({ status: "confirmed" }));
    dispatch(getGroupsAction());

    var defaultFilters = [];

    if (agentUrlSearch) {
      defaultFilters = [
        ...defaultFilters,
        {
          key: "agent_id",
          label: "Agent:",
          hint: "conversations of this agent",
          type: "select",
          value: agentUrlSearch,
        },
      ];
    }
    if (statusUrlSearch) {
      defaultFilters = [
        ...defaultFilters,
        {
          key: "status",
          label: "Status:",
          hint: "conversations with this status",
          type: "select",
          value: statusUrlSearch,
        },
      ];
    }
    if (csatUrlSearch) {
      defaultFilters = [
        ...defaultFilters,
        {
          key: "csat",
          label: "CSAT:",
          hint: "conversations with a CSAT score",
          type: "select",
          value: csatUrlSearch,
        },
      ];
    }
    if (fromDateUrlSearch) {
      defaultFilters = [
        ...defaultFilters,
        {
          key: "fromDate",
          label: "From date:",
          hint: "conversations after this date",
          type: "date",
          value: fromDateUrlSearch,
        },
      ];
    }
    if (toDateUrlSearch) {
      defaultFilters = [
        ...defaultFilters,
        {
          key: "toDate",
          label: "To date:",
          hint: "conversations before this date",
          type: "date",
          value: toDateUrlSearch,
        },
      ];
    }

    if (defaultFilters?.length > 0) {
      setParams(defaultFilters);
      setInitialSearchParams(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (params?.length > 0 && initialSearchParams === true && !inChatSearch) {
      toggleIsSearchActive(true);
      handleSearch();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, initialSearchParams]);

  const handleSearch = (loadMore) => {
    if (params?.length > 0) {
      var payload = params?.map((x) => ({ [x?.key]: x?.value }));
      payload = Object.assign(
        {
          "page[size]": PAGE_SIZE,
          "page[number]": loadMore ? pageNumber : 1,
          customer_id: inChatSearch ? inChatSearch : null,
        },
        ...payload
      );

      const requestData = {
        url: searchInMentions ? "/mention/all" : "/conversations/search",
        params: payload,
      };

      setIsSearchOptionsActive(false);
      setShowSearchResult(true);
      dispatch(getConversationSearchAction(requestData)).then((res) => {
        if (loadMore) {
          setSearchConversationData([...searchConversationData, ...res?.data]);
        } else {
          setSearchConversationData(res?.data);
        }

        if (initialSearchParams === true) setInitialSearchParams(false);
      });
    }
  };

  const loadMoreResult = () => {
    setPageNumber(pageNumber + 1);
  };
  useEffect(() => {
    if (pageNumber > 1) {
      handleSearch(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  const openSearchForm = () => {
    toggleIsSearchActive(true);
    setIsSearchOptionsActive(true);
  };

  const closeSearchForm = () => {
    setParams([]);
    toggleIsSearchActive(false);
    setIsSearchOptionsActive(false);
    setShowSearchResult(false);
  };

  const closeSearchOptions = () => {
    setIsSearchOptionsActive(false);
  };
  useCloseElement(wrapperRef, closeSearchOptions);

  const handleAddToParams = (paramToAdd) => {
    setParams([...params, paramToAdd]);
    setIsSearchOptionsActive(false);
  };

  const handleSearchValueChange = (paramToEdit, index, value) => {
    var allParams = params;
    allParams[index] = {
      ...paramToEdit,
      value:
        paramToEdit?.type === "date"
          ? moment(value || new Date())
              .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
              .format()
          : value,
    };

    setPageNumber(1);
    setParams(allParams);
  };

  const getHighlightedText = (text, highlight) => {
    // Split on highlight term and include term into parts, ignore case
    const parts = text?.split(new RegExp(`(${highlight})`, "gi"));
    if (parts) {
      return (
        <>
          {parts?.map((part, i) => (
            <span
              key={i}
              style={
                part?.toLowerCase() === highlight?.toLowerCase()
                  ? {
                      background: "rgba(247, 190, 31, 0.45)",
                      margin: "0px 2px",
                    }
                  : {}
              }
            >
              {part}
            </span>
          ))}
        </>
      );
    }
  };

  const getMessagePreviewText = (medium, messages, highlight) => {
    const keyName = medium === "EMAIL" ? "clean_content" : "content";
    const filtered = messages?.filter((x) =>
      x?.[keyName]?.toLowerCase()?.includes(messageSearchValue?.toLowerCase())
    );
    if (filtered?.[0]?.[keyName] && highlight) {
      return getHighlightedText(filtered?.[0]?.[keyName], messageSearchValue);
    }
    if (!filtered?.length && highlight) {
      var value = "";
      if (
        messages?.[0]?.contentType === "TEXT" ||
        messages?.[0]?.contentType === "TEMPLATE"
      ) {
        value = messages?.[0]?.[keyName];
      } else {
        value = `-- ${messages?.[0]?.contentType} --`;
      }

      return value;
    }
    if (filtered?.[0]?.[keyName]) {
      return filtered?.[0];
    }
    return "...";
  };

  const customerOptions = customersData?.map((x) => ({
    label: x?.name || x?.phone,
    value: x?.id,
  }));
  const searchCustomer = (inputValue, callback) => {
    dispatch(getCustomersAction({ searchQuery: inputValue })).then((res) => {
      const options = res?.map((x) => ({
        label: x?.name || x?.phone,
        value: x?.id,
      }));
      callback(options);
    });
  };

  const tagOptions = tagsData?.map((x) => ({
    label: x?.name,
    value: x?.name,
  }));

  const agentOptions = agentsData?.map((x) => ({
    label: `${x?.firstName} ${x?.lastName}`,
    value: x?.id,
  }));
  const searchAgent = (inputValue, callback) => {
    dispatch(
      getAgentsAction({ status: "confirmed", searchQuery: inputValue })
    ).then((res) => {
      const options = res?.map((x) => ({
        label: `${x?.firstName} ${x?.lastName}`,
        value: `${x?.id}`,
      }));
      callback(options);
    });
  };

  const groupOptions = groupsData?.map((x) => ({
    label: x?.name,
    value: x?.id,
  }));

  const csatOptions = [
    {
      label: "Yes",
      value: "true",
    },
    {
      label: "No",
      value: "false",
    },
  ];

  const statusOptions = [
    {
      label: "Open",
      value: "open",
    },
    {
      label: "In-progress",
      value: "in-progress",
    },
    {
      label: "Closed",
      value: "closed",
    },
    {
      label: "Not Closed",
      value: "!closed",
    },
    {
      label: "In-queue",
      value: "in-queue",
    },
    {
      label: "Awaiting-agent-response",
      value: "awaiting-agent-response",
    },
    {
      label: "Awaiting-customer-response",
      value: "awaiting-customer-response",
    },
  ];

  const mediumOptions = [
    {
      label: "TWITTER",
      value: "TWITTER",
    },
    {
      label: "INSTAGRAM",
      value: "INSTAGRAM",
    },
    {
      label: "FACEBOOK",
      value: "FACEBOOK",
    },
  ];

  const channelOptions = firmChannelsData
    ?.filter((x) => x.status === "ACTIVE")
    ?.map((x) => ({
      label: `(${x?.medium}) - ${x?.name}`,
      value: x?.id,
    }));

  const optionsToShow = (key) => {
    if (key === "customer_id") {
      return customerOptions;
    }
    if (key === "tag") {
      return tagOptions;
    }
    if (key === "group_id") {
      return groupOptions;
    }
    if (key === "agent_id") {
      return agentOptions;
    }
    if (key === "csat") {
      return csatOptions;
    }
    if (key === "status") {
      return statusOptions;
    }
    if (key === "channel_id") {
      return channelOptions;
    }
    if (key === "medium") {
      return mediumOptions;
    }
  };

  const loadMoreFunctionToUse = (key) => {
    if (key === "customer_id") {
      return searchCustomer;
    }
    if (key === "agent_id") {
      return searchAgent;
    }
  };

  const conversationSearchOptions = [
    {
      key: "message",
      label: "Message:",
      hint: "conversations with this message",
      type: "input",
    },
    {
      key: "customer_id",
      label: "Customer:",
      hint: "conversations of this customer",
      type: "select",
    },
    {
      key: "tag",
      label: "Tag:",
      hint: "conversations with this tag",
      type: "select",
    },
    {
      key: "group_id",
      label: "Group:",
      hint: "conversations with customers in this group",
      type: "select",
    },
    {
      key: "agent_id",
      label: "Agent:",
      hint: "conversations of this agent",
      type: "select",
    },
    {
      key: "csat",
      label: "CSAT:",
      hint: "conversations with a CSAT score",
      type: "select",
    },
    {
      key: "status",
      label: "Status:",
      hint: "conversations with this status",
      type: "select",
    },
    {
      key: "fromDate",
      label: "From date:",
      hint: "conversations after this date",
      type: "date",
    },
    {
      key: "toDate",
      label: "To date:",
      hint: "conversations before this date",
      type: "date",
    },
    {
      key: "channel_id",
      label: "Channel:",
      hint: "conversations of this channel",
      type: "select",
    },
  ];

  const mentionSearchOptions = [
    {
      key: "medium",
      label: "Medium:",
      hint: "mentions of this medium",
      type: "select",
    },
    {
      key: "agent_id",
      label: "Agent:",
      hint: "mentions of this agent",
      type: "select",
    },
    {
      key: "status",
      label: "Status:",
      hint: "mentions with this status",
      type: "select",
    },
    {
      key: "fromDate",
      label: "From date:",
      hint: "mentions after this date",
      type: "date",
    },
    {
      key: "toDate",
      label: "To date:",
      hint: "mentions before this date",
      type: "date",
    },
  ];

  const searchOptions = searchInMentions
    ? mentionSearchOptions
    : conversationSearchOptions;

  return (
    <div
      className={`global-search-container ${isSearchActive && "search-active"}`}
      ref={wrapperRef}
    >
      <>
        <div className={`global-search ${isSearchActive ? "active" : ""}`}>
          <div className="search-container-box">
            {params?.length < 1 ? (
              <p className="placeholder_text" onClick={openSearchForm}>
                {searchInMentions
                  ? "Search mentions for agents, status, medium..."
                  : "Search conversations for keywords, tags, agents, messages..."}
              </p>
            ) : (
              <>
                {params?.map((item, index) => (
                  <div
                    key={index}
                    className="search-item"
                    onClick={closeSearchOptions}
                  >
                    <span className="padding search-type">{item?.label}</span>

                    {item?.type === "input" && (
                      <input
                        className="padding search-value"
                        defaultValue={item?.value}
                        onChange={(e) =>
                          handleSearchValueChange(item, index, e?.target?.value)
                        }
                        onKeyDown={(e) => {
                          if (e?.key === "Enter") {
                            handleSearch();
                          }
                        }}
                      />
                    )}

                    {item?.type === "select" && (
                      <AsyncSelect
                        classNamePrefix="select_container"
                        defaultValue={{
                          label: item?.value,
                          value: item?.value,
                        }}
                        onChange={(selection) => {
                          handleSearchValueChange(
                            item,
                            index,
                            selection?.value
                          );
                          handleSearch();
                        }}
                        cacheOptions
                        defaultOptions={optionsToShow(item?.key)}
                        loadOptions={loadMoreFunctionToUse(item?.key)}
                      />
                    )}

                    {item?.type === "date" && (
                      <DatePicker
                        onChange={(date) => {
                          handleSearchValueChange(item, index, date);
                          handleSearch();
                        }}
                        // selected={item?.value}
                        value={moment(item?.value).format("MMM DD, YYYY")}
                        className="date_input"
                        dateFormat="dd-MM-yyyy"
                        maxDate={new Date()}
                      />
                    )}

                    <span
                      className="padding close"
                      onClick={() =>
                        setParams(
                          params?.filter((x) => x?.label !== item?.label)
                        )
                      }
                    >
                      <Close />
                    </span>
                  </div>
                ))}

                <p className="placeholder_text" onClick={openSearchForm}>
                  Click me to add filter...
                </p>
              </>
            )}
          </div>
          <div
            className="close-search"
            onClick={isSearchActive ? closeSearchForm : () => {}}
          >
            {isSearchActive ? (
              <span>
                <Close />
              </span>
            ) : (
              <span>
                <Search />
              </span>
            )}
          </div>
        </div>
        {isSearchOptionsActive && (
          <div className="global-search-options">
            {searchOptions?.map((item, i) => (
              <div
                key={i}
                className="search-option"
                onClick={() => handleAddToParams(item)}
              >
                <span className="label">{item?.label}</span>
                <span className="hint">{item?.hint}</span>
              </div>
            ))}
          </div>
        )}
        {showSearchResult && params?.length > 0 && (
          <div className="search-results-container">
            <span className="title">Search Result</span>

            <div className="search-results">
              {isSearching && pageNumber === 1 ? (
                <div className="searching">Searching...</div>
              ) : !isSearching &&
                searchConversationData?.length < 1 &&
                params?.length > 0 ? (
                <div className="searching">
                  No {searchInMentions ? "mention" : "conversation"} record
                  found.
                </div>
              ) : (
                <>
                  {!inChatSearch &&
                    searchConversationData?.map((hit, i) => (
                      <div
                        key={i}
                        className={classNames(
                          "search-result conversation-item",
                          {
                            active: hit === selectedResult,
                          }
                        )}
                        onClick={() => {
                          setSelectedResult(hit);
                          closeOnClick && closeSearchForm();

                          history.push(
                            searchInMentions
                              ? `/activity/${hit?.id}`
                              : `/conversations/${hit?.customer_id}/${hit?.Channel?.id}?msgRowId=${hit?.Messages?.[0]?.row_id}`
                          );
                        }}
                      >
                        <div className="avatar">
                          {searchInMentions
                            ? getInitials(hit?.customerUsername, 2)
                            : hit?.customer?.name
                            ? getInitials(hit?.Customer?.name, 2)
                            : ""}
                        </div>
                        <div className="content">
                          <div className="conversation-item-header">
                            <span className="conversation-author">
                              {searchInMentions
                                ? hit?.customerUsername
                                : hit?.Customer?.name
                                ? hit?.Customer?.name
                                : hit?.customer?.name
                                ? hit?.customer?.name
                                : hit?.customer?.phone}
                            </span>
                            <span className="conversation-status">
                              {hit?.status}
                            </span>
                          </div>
                          <div className="conversation-item-preview">
                            {searchInMentions
                              ? hit?.text
                              : getMessagePreviewText(
                                  hit?.Channel?.medium,
                                  hit?.Messages,
                                  "highlight"
                                )}
                          </div>
                          <div className="conversation-item-footer">
                            {hit?.Tags?.length > 0 && (
                              <>
                                <div className="chat-tag-item">
                                  <span className="tag-icon small">
                                    <span
                                      className="tag-bg"
                                      style={{
                                        background: `${hit?.Tags?.[0]?.colorCode}`,
                                      }}
                                    ></span>
                                    <Tag color={hit?.Tags?.[0]?.colorCode} />
                                  </span>
                                  <span
                                    className="tag-name"
                                    style={{
                                      color: `${hit?.Tags?.[0]?.colorCode}`,
                                    }}
                                  >
                                    {hit?.Tags?.[0]?.name}
                                  </span>
                                </div>
                                <span className="dot"></span>
                              </>
                            )}
                            {hit.csatScore && (
                              <>
                                <span className="csat">
                                  <Star /> {hit?.csatScore}
                                </span>
                                <span className="dot"></span>
                              </>
                            )}
                            <span>
                              Via:{" "}
                              {searchInMentions
                                ? hit?.medium
                                : hit?.Channel?.medium}
                            </span>
                            <span className="dot"></span>
                            <span className="conversation-time">
                              {moment(hit?.createdAt).format(
                                "ddd, Do MMM, YYYY"
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  {inChatSearch &&
                    searchConversationData?.[0]?.Messages?.map((message, i) => (
                      <div
                        key={i}
                        className={classNames(
                          "search-result conversation-item",
                          {
                            active: message === selectedResult,
                          }
                        )}
                        onClick={() => {
                          setSelectedResult(message);
                          closeOnClick && closeSearchForm();

                          history.push(
                            `/conversations/${searchConversationData?.[0]?.customer_id}/${searchConversationData?.[0]?.Channel?.id}?msgRowId=${message?.row_id}`
                          );
                        }}
                      >
                        <div className="avatar">
                          {searchConversationData?.[0]?.Customer?.name
                            ? getInitials(
                                searchConversationData?.[0]?.Customer?.name,
                                2
                              )
                            : ""}
                        </div>
                        <div className="content">
                          <div className="conversation-item-header">
                            <span className="conversation-author">
                              {searchConversationData?.[0]?.Customer?.name
                                ? searchConversationData?.[0]?.Customer?.name
                                : searchConversationData?.[0]?.customer?.name
                                ? searchConversationData?.[0]?.customer?.name
                                : searchConversationData?.[0]?.customer?.phone}
                            </span>
                            <span className="conversation-status">
                              {searchConversationData?.[0]?.status}
                            </span>
                          </div>
                          <div className="conversation-item-preview">
                            {getHighlightedText(
                              message?.contentType === "EMAIL" || "TEMPLATE"
                                ? message?.clean_content
                                : message?.contentType !== "TEXT"
                                ? `-- ${message?.contentType} --`
                                : message?.content,
                              messageSearchValue
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  {isSearching && (
                    <div className="searching">Loading more result...</div>
                  )}
                  {!isSearching &&
                  searchData?.currentPage < searchData?.totalPage ? (
                    <div
                      className="searching load_more"
                      onClick={loadMoreResult}
                    >
                      Load more
                    </div>
                  ) : (
                    <div className="searching">This is the last page.</div>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </>
    </div>
  );
};

export default GlobalSearch;
