import {
  CREATE_CHANNELS_LOADER,
  CREATE_CHANNELS_RESPONSE,
  CREATE_CHANNELS_RESET,
  UPDATE_CHANNELS_LOADER,
  UPDATE_CHANNELS_RESPONSE,
  UPDATE_CHANNELS_RESET,
  GET_CHANNELS_LOADER,
  GET_ALL_CHANNELS_RESPONSE,
  GET_FIRM_CHANNELS_RESPONSE,
  GET_CHANNELS_RESET,
  DELETE_CHANNELS_LOADER,
  DELETE_CHANNELS_RESPONSE,
  DELETE_CHANNELS_RESET,
} from "../constants";

const INIT_STATE = {
  allChannelsData: [],
  firmChannelsData: [],
  channelsMeta: {
    limit: 10,
    offset: 0,
    total: 0,
  },
  isCreatingChannel: false,
  isUpdatingChannel: false,
  isDeletingChannel: false,
  isLoadingChannels: true,
  createChannelsOutcome: "",
  createChannelsMessage: "",
  updateChannelsOutcome: "",
  updateChannelsMessage: "",
  deleteChannelsOutcome: "",
  deleteChannelsMessage: "",
  channelsOutcome: "",
  channelsMessage: "",
};

const Channels = (state = INIT_STATE, action) => {
  const {
    type,
    createChannelsOutcome,
    createChannelsMessage,
    updateChannelsOutcome,
    updateChannelsMessage,
    deleteChannelsOutcome,
    deleteChannelsMessage,
    channelsOutcome,
    channelsMessage,
    allChannelsData,
    firmChannelsData,
    channelsMeta,
    isCreatingChannel,
    isUpdatingChannel,
    isDeletingChannel,
    isLoadingChannels,
  } = action;

  switch (type) {
    case CREATE_CHANNELS_LOADER: {
      return {
        ...state,
        isCreatingChannel,
      };
    }

    case CREATE_CHANNELS_RESPONSE: {
      return {
        ...state,
        createChannelsOutcome,
        createChannelsMessage,
      };
    }

    case CREATE_CHANNELS_RESET: {
      return {
        ...state,
        createChannelsOutcome: "",
        createChannelsMessage: "",
      };
    }

    case UPDATE_CHANNELS_LOADER: {
      return {
        ...state,
        isUpdatingChannel,
      };
    }

    case UPDATE_CHANNELS_RESPONSE: {
      return {
        ...state,
        updateChannelsOutcome,
        updateChannelsMessage,
      };
    }

    case UPDATE_CHANNELS_RESET: {
      return {
        ...state,
        updateChannelsOutcome: "",
        updateChannelsMessage: "",
      };
    }

    case DELETE_CHANNELS_LOADER: {
      return {
        ...state,
        isDeletingChannel,
      };
    }

    case DELETE_CHANNELS_RESPONSE: {
      return {
        ...state,
        deleteChannelsOutcome,
        deleteChannelsMessage,
      };
    }

    case DELETE_CHANNELS_RESET: {
      return {
        ...state,
        deleteChannelsOutcome: "",
        deleteChannelsMessage: "",
      };
    }

    case GET_CHANNELS_LOADER: {
      return {
        ...state,
        isLoadingChannels,
      };
    }

    case GET_ALL_CHANNELS_RESPONSE: {
      return {
        ...state,
        channelsOutcome,
        allChannelsData,
        channelsMeta,
        channelsMessage,
      };
    }

    case GET_FIRM_CHANNELS_RESPONSE: {
      return {
        ...state,
        channelsOutcome,
        firmChannelsData,
        channelsMeta,
        channelsMessage,
      };
    }

    case GET_CHANNELS_RESET: {
      return INIT_STATE;
    }

    default:
      return state;
  }
};

export default Channels;
