import React from "react";

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      stroke="#757F8A"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M.933 2.495A1 1 0 012.25 1.111L14.925 7.55a.5.5 0 010 .891L2.249 14.89a1 1 0 01-1.316-1.384l3.372-5.51-3.372-5.5zM15.198 7.995H4.302"
    ></path>
  </svg>
);
