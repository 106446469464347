import React from "react";

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      stroke="#474F58"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.25"
      d="M18.125 6.875h-10l-3.75 3.75v-3.75h-2.5a1.25 1.25 0 01-1.25-1.25v-3.75a1.25 1.25 0 011.25-1.25h16.25a1.25 1.25 0 011.25 1.25v3.75a1.25 1.25 0 01-1.25 1.25z"
    ></path>
    <path
      stroke="#474F58"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.25"
      d="M1.875 9.375a1.25 1.25 0 00-1.25 1.25v3.75a1.25 1.25 0 001.25 1.25h10l3.75 3.75v-3.75h2.5a1.25 1.25 0 001.25-1.25v-3.75a1.25 1.25 0 00-1.25-1.25h-8.75"
    ></path>
  </svg>
);
