import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as moment from "moment";

import { Button } from "../../../components/FormElements";
import WidgetForm from "./WidgetForm";

import Modal from "../../../components/Modal";
import Table from "../../../components/Table";
import Toaster from "../../../components/Toaster";
import OptionsDropdown from "../../../components/OptionsDropdown";
import SettingsEmptyState from "../../../components/SettingsEmptyState";

import {
  getWidgetsAction,
  deleteWidgetAction,
} from "../../../redux/actions/Widget";

import { accountSettingsPermissions } from "../../../utils/permissions";
import { getUserDetails } from "../../../utils/functions";
import { APP_CREATED_WIDGET } from "../../../utils/constants";

import { CircularPlus, Trash } from "../../../assets/vectors";

const OmniChannelWidget = () => {
  const dispatch = useDispatch();
  const { isLoadingWidgets, isSubmittingWidget, widgetsData } = useSelector(
    (state) => state.widget
  );

  const [selectedWidget, setSelectedWidget] = useState(null);
  const [showAddNewWidget, setShowAddNewWidget] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [toaster, setToaster] = useState({});

  useEffect(() => {
    dispatch(getWidgetsAction());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { firm_id } = getUserDetails();
    const hasFirmCreatedWidget =
      JSON.parse(window.localStorage.getItem(APP_CREATED_WIDGET)) || null;

    if (widgetsData?.length > 0 && !hasFirmCreatedWidget?.firm_id !== firm_id) {
      const data = {
        firm_id,
        value: true,
      };

      localStorage.setItem(APP_CREATED_WIDGET, JSON.stringify(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetsData]);

  const openToaster = (status, content) => {
    setToaster({
      open: "open",
      status,
      content,
    });
  };

  const closeToaster = () => {
    setToaster({
      ...toaster,
      open: "closed",
    });
  };

  const deleteWidget = () => {
    dispatch(
      deleteWidgetAction({
        id: selectedWidget?.id,
      })
    ).then((res) => {
      if (res?.success === true) {
        dispatch(getWidgetsAction());
        setSelectedWidget(null);
        setShowDeleteModal(false);
        openToaster("success", "Widget deleted successfully!");
      }
    });
  };

  const moreOptions = (item) => {
    var options = [];

    if (
      accountSettingsPermissions?.update ||
      accountSettingsPermissions?.modifyAll
    ) {
      options = [
        ...options,
        {
          label: <div>Edit widget</div>,
          value: "edit",
        },
        {
          label: <div className="red">Delete widget</div>,
          value: "delete",
        },
      ];
    }

    return options;
  };

  const optionsAction = (type, row) => {
    setSelectedWidget(row);

    if (type === "edit") {
      setShowAddNewWidget(true);
    }
    if (type === "delete") {
      setShowDeleteModal(true);
    }
  };

  const columns = [
    {
      id: "headText",
      label: "Head Text",
      render: (widget) => <span>{widget?.headText}</span>,
    },
    {
      id: "channels",
      label: "Channels",
      render: ({ channels }) => <span>{channels?.length}</span>,
    },
    {
      id: "updatedAt",
      label: "Last Updated",
      render: ({ updatedAt }) => (
        <span>{moment(updatedAt).format("DD/MM/YYYY")}</span>
      ),
    },
    {
      id: "",
      label: "Actions",
      thClassName: "text-center",
      render: (row) => (
        <div className="text-center">
          <OptionsDropdown
            onClick={(val) => {
              optionsAction(val, row);
            }}
            options={moreOptions()}
          />
        </div>
      ),
    },
  ];

  return (
    <>
      {!showAddNewWidget ? (
        <div className="settings-page-container working-hours-container">
          <div className="page-header">
            <span className="title">Omnichannel Widgets</span>
          </div>

          <div className="table-container saved-responses-table">
            {!isLoadingWidgets && !widgetsData?.length ? (
              <SettingsEmptyState
                title="You have no Widgets yet"
                description="When you create a Widget, it'll show up here."
                action={
                  <>
                    {(accountSettingsPermissions?.update ||
                      accountSettingsPermissions?.modifyAll) && (
                      <Button
                        className="primary-button"
                        onClick={() => {
                          setSelectedWidget(null);
                          setShowAddNewWidget(true);
                        }}
                      >
                        <span className="icon">
                          <CircularPlus />
                        </span>
                        <span>New Widget</span>
                      </Button>
                    )}
                  </>
                }
              />
            ) : (
              <>
                <div className="table-filter">
                  <div className="table-search-filter"></div>
                  <div className="table-other-filters">
                    {(accountSettingsPermissions?.update ||
                      accountSettingsPermissions?.modifyAll) && (
                      <Button
                        className="primary-button small"
                        onClick={() => {
                          setSelectedWidget(null);
                          setShowAddNewWidget(true);
                        }}
                      >
                        <span className="icon">
                          <CircularPlus />
                        </span>
                        <span>New Widget</span>
                      </Button>
                    )}
                  </div>
                </div>

                <Table
                  loading={isLoadingWidgets}
                  columns={columns}
                  rows={widgetsData}
                />
              </>
            )}
          </div>

          {showDeleteModal && (
            <Modal closeModal={() => setShowDeleteModal(false)} small>
              <div className="modal-content confirmation-modal">
                <div className="modal-icon red">
                  <Trash />
                </div>
                <div className="modal-title">
                  Are you sure you want to delete this widget?
                </div>
                <div className="modal-text">This action cannot be reversed</div>
                <div className="modal-action">
                  <Button
                    className="secondary-button red"
                    type="button"
                    disabled={isSubmittingWidget}
                    onClick={deleteWidget}
                  >
                    {isSubmittingWidget ? "Processing..." : "Delete widget"}
                  </Button>
                  <Button
                    className="plain-button black"
                    type="button"
                    onClick={() => setShowDeleteModal(false)}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </Modal>
          )}

          <Toaster {...toaster} closeToaster={closeToaster} />
        </div>
      ) : (
        <WidgetForm
          closeForm={() => setShowAddNewWidget(false)}
          widgetData={selectedWidget}
          openToaster={openToaster}
        />
      )}
    </>
  );
};

export default OmniChannelWidget;
