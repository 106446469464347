import mixpanel from "mixpanel-browser";
import {
  getRequest,
  postRequest,
  patchRequest,
  deleteRequest,
} from "../../api";

import {
  GET_ROLES_RESPONSE,
  CREATE_AGENTS_LOADER,
  CREATE_AGENTS_RESPONSE,
  CREATE_AGENTS_RESET,
  CONFIRM_AGENTS_LOADER,
  CONFIRM_AGENTS_RESPONSE,
  CONFIRM_AGENTS_RESET,
  UPDATE_AGENTS_LOADER,
  UPDATE_AGENTS_RESPONSE,
  UPDATE_AGENTS_RESET,
  GET_AGENTS_LOADER,
  GET_AGENTS_RESPONSE,
  DELETE_AGENTS_LOADER,
  DELETE_AGENTS_RESPONSE,
  DELETE_AGENTS_RESET,
} from "../constants";

import { getRequestError } from "../../utils/functions";

export const getRolesResponse = (
  rolesOutcome,
  rolesData,
  rolesMeta,
  rolesMessage
) => ({
  type: GET_ROLES_RESPONSE,
  rolesOutcome,
  rolesData,
  rolesMeta,
  rolesMessage,
});

export const createAgentsLoader = (isCreatingAgent) => ({
  type: CREATE_AGENTS_LOADER,
  isCreatingAgent,
});

export const createAgentsResponse = (
  createAgentsOutcome,
  createAgentsMessage
) => ({
  type: CREATE_AGENTS_RESPONSE,
  createAgentsOutcome,
  createAgentsMessage,
});

export const createAgentsReset = () => ({
  type: CREATE_AGENTS_RESET,
});

export const confirmAgentsLoader = (isConfirmingAgent) => ({
  type: CONFIRM_AGENTS_LOADER,
  isConfirmingAgent,
});

export const confirmAgentsResponse = (
  confirmAgentsOutcome,
  confirmAgentsMessage
) => ({
  type: CONFIRM_AGENTS_RESPONSE,
  confirmAgentsOutcome,
  confirmAgentsMessage,
});

export const confirmAgentsReset = () => ({
  type: CONFIRM_AGENTS_RESET,
});

export const updateAgentsLoader = (isUpdatingAgent) => ({
  type: UPDATE_AGENTS_LOADER,
  isUpdatingAgent,
});

export const updateAgentsResponse = (
  updateAgentsOutcome,
  updateAgentsMessage
) => ({
  type: UPDATE_AGENTS_RESPONSE,
  updateAgentsOutcome,
  updateAgentsMessage,
});

export const updateAgentsReset = () => ({
  type: UPDATE_AGENTS_RESET,
});

export const getAgentsLoader = (isLoadingAgents) => ({
  type: GET_AGENTS_LOADER,
  isLoadingAgents,
});

export const getAgentsResponse = (
  agentsOutcome,
  agentsData,
  agentsMeta,
  agentsMessage
) => ({
  type: GET_AGENTS_RESPONSE,
  agentsOutcome,
  agentsData,
  agentsMeta,
  agentsMessage,
});

export const deleteAgentsLoader = (isDeletingAgent) => ({
  type: DELETE_AGENTS_LOADER,
  isDeletingAgent,
});

export const deleteAgentsResponse = (
  deleteAgentsOutcome,
  deleteAgentsMessage
) => ({
  type: DELETE_AGENTS_RESPONSE,
  deleteAgentsOutcome,
  deleteAgentsMessage,
});

export const deleteAgentsReset = () => ({
  type: DELETE_AGENTS_RESET,
});

export const getRolesAction = (params = {}) => {
  return async (dispatch) => {
    try {
      const res = await getRequest({
        url: "/auth/roles",
        params,
        token: true,
      });

      const { data } = res.data;

      dispatch(getRolesResponse("success", data, {}, ""));
      return data;
    } catch (error) {
      const message = getRequestError(error);

      dispatch(getRolesResponse("error", [], {}, message));
    }
  };
};

export const createAgentsAction = (data, success) => {
  return async (dispatch) => {
    dispatch(createAgentsReset());
    dispatch(createAgentsLoader(true));

    try {
      const res = await postRequest({
        url: "/auth/invite-agents",
        data,
        token: true,
      });

      dispatch(createAgentsResponse("success", ""));
      dispatch(createAgentsLoader(false));

      mixpanel.track("Invite Team Member");

      success && success();
      success && success();
      return res;
    } catch (error) {
      const message = getRequestError(error);

      dispatch(createAgentsResponse("error", message));
      dispatch(createAgentsLoader(false));
    }
  };
};

export const confirmAgentAction = (data) => {
  return async (dispatch) => {
    dispatch(confirmAgentsReset());
    dispatch(confirmAgentsLoader(true));

    try {
      await postRequest({
        url: "/auth/confirm",
        data,
      });

      dispatch(confirmAgentsResponse("success", ""));
      dispatch(confirmAgentsLoader(false));
    } catch (error) {
      const message = getRequestError(error);

      dispatch(confirmAgentsResponse("error", message));
      dispatch(confirmAgentsLoader(false));
    }
  };
};

export const updateAgentsAction = (data, id, success) => {
  return async (dispatch) => {
    dispatch(updateAgentsReset());
    dispatch(updateAgentsLoader(true));

    try {
      await patchRequest({
        url: `/users/${id}`,
        data,
        token: true,
      });

      dispatch(updateAgentsResponse("success", ""));
      dispatch(updateAgentsLoader(false));

      success && success();
      success && success();
    } catch (error) {
      const message = getRequestError(error);

      dispatch(updateAgentsResponse("error", message));
      dispatch(updateAgentsLoader(false));
    }
  };
};

export const getAgentsAction = (params = {}) => {
  return async (dispatch) => {
    dispatch(getAgentsLoader(true));

    try {
      const res = await getRequest({
        url: "/users",
        params,
        token: true,
      });

      const { data, offset, limit, total } = res.data;

      dispatch(
        getAgentsResponse("success", data, { offset, limit, total }, "")
      );
      dispatch(getAgentsLoader(false));
      return data;
    } catch (error) {
      const message = getRequestError(error);

      dispatch(getAgentsResponse("error", [], {}, message));
      dispatch(getAgentsLoader(false));
    }
  };
};

export const deleteAgentsAction = (id) => {
  return async (dispatch) => {
    dispatch(deleteAgentsReset());
    dispatch(deleteAgentsLoader(true));

    try {
      await deleteRequest({
        url: `/auth/remove-agents/${id}`,
        token: true,
      });

      dispatch(deleteAgentsResponse("success", ""));
      dispatch(deleteAgentsLoader(false));
    } catch (error) {
      const message = getRequestError(error);

      dispatch(deleteAgentsResponse("error", message));
      dispatch(deleteAgentsLoader(false));
    }
  };
};
