/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import { Link, NavLink } from "react-router-dom";

import { logoutAction } from "../../redux/actions/Auth";
import { getConversationsUnreadCount } from "../../redux/actions/Conversations";

import { OnerouteWIcon } from "../../assets/vectors";

import { mainMenu, footerMenu } from "./data";
import Avatar from "../Avatar";
import { getUserDetails, getInitials } from "../../utils/functions";

class Sidebar extends Component {
  state = {
    menuLabel: null,
    topPosition: null,
    bottomPosition: null,
    profileMenu: false,
  };

  setMenuLabel = ({ target }, menuLabel) => {
    const topPosition = target.getBoundingClientRect().top + 15;

    this.setState({
      menuLabel,
      topPosition,
    });
  };

  openProfileMenu = ({ target }) => {
    // const bottomPosition = target.getBoundingClientRect().bottom - 235 - 15;
    const bottomPosition = target.getBoundingClientRect().bottom - 105 - 15;

    this.setState(
      {
        bottomPosition,
        profileMenu: true,
      },
      () => document.addEventListener("click", this.closeProfileMenu)
    );
  };

  closeProfileMenu = (e) => {
    if (
      !e ||
      (this.profileMenuRef && !this.profileMenuRef.contains(e.target))
    ) {
      this.setState(
        {
          bottomPosition: null,
          profileMenu: false,
        },
        () => document.removeEventListener("click", this.closeProfileMenu)
      );
    }
  };

  logout = () => {
    this.closeProfileMenu();
    this.props.logoutAction();
  };

  componentDidMount() {
    document
      .getElementById("sidebar")
      .addEventListener("scroll", this.setMenuLabel);
  }

  componentWillUnmount() {
    document
      .getElementById("sidebar")
      .removeEventListener("scroll", this.setMenuLabel);
  }

  render() {
    const { menuLabel, topPosition, bottomPosition, profileMenu } = this.state;
    const { firstName, lastName } = getUserDetails();

    const { conversationsUnreadCount } = this.props;
    const unreadCount =
      conversationsUnreadCount["open"] +
        conversationsUnreadCount["in-progress"] +
        conversationsUnreadCount["awaiting-customer-response"] +
        conversationsUnreadCount["in-queue"] || 0;

    return (
      <div className="sidebar">
        <div id="sidebar" className="sidebar-container no-scrollbar">
          <div>
            <div className="main-menu">
              <div className="menu-item">
                <Link to="/get-started">
                  <OnerouteWIcon />
                </Link>
              </div>
              {mainMenu.map(({ label, value, icon, badge, show }, index) => (
                <React.Fragment key={`main-menu-${index}`}>
                  {show && (
                    <div className="menu-item">
                      <NavLink
                        to={value}
                        onMouseOver={(e) => this.setMenuLabel(e, label)}
                        onMouseOut={(e) => this.setMenuLabel(e)}
                        className={classNames("", { dead: value === "#" })}
                      >
                        {icon}
                        {badge && unreadCount > 0 && (
                          <span className="badge">{unreadCount}</span>
                        )}
                      </NavLink>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
          <div className="footer-menu">
            {footerMenu.map(({ label, value, icon, profile }, index) => (
              <div key={`footer-menu-${index}`} className="menu-item">
                {profile ? (
                  <a onClick={this.openProfileMenu}>
                    <Avatar text={getInitials(`${firstName} ${lastName}`)} />
                  </a>
                ) : (
                  <NavLink
                    to={value}
                    onMouseOver={(e) => this.setMenuLabel(e, label)}
                    onMouseOut={(e) => this.setMenuLabel(e)}
                    className={classNames("", { dead: value === "#" })}
                  >
                    {icon}
                  </NavLink>
                )}
              </div>
            ))}
          </div>
        </div>
        {menuLabel && (
          <div className="menu-label" style={{ top: `${topPosition}px` }}>
            {menuLabel}
          </div>
        )}

        {profileMenu && (
          <div
            ref={(el) => (this.profileMenuRef = el)}
            className="menu-label profile-menu"
            style={{ top: `${bottomPosition}px` }}
          >
            <div className="profile-menu-header">
              {/* <div className="profile-picture">
                <img src={require("../../assets/images/avatar.png")} alt="" />
                <span className="status-color active"></span>
              </div> */}
              <div>
                <span className="name">
                  {firstName} {lastName}
                </span>
                <span className="status">Active</span>
              </div>
            </div>
            {/* <div className="profile-menu-status-container">
              <span className="title">Set status</span>
              <Radio
                label="Active"
                name="user-status"
                className="space-between mb-10"
                onChange={() => {}}
                leftLabel
              />
              <Radio
                label="Busy"
                name="user-status"
                className="space-between mb-10"
                onChange={() => {}}
                leftLabel
              />
              <Radio
                label="Away"
                name="user-status"
                className="space-between mb-10"
                onChange={() => {}}
                leftLabel
              />
            </div> */}
            <div className="logout">
              <span onClick={this.logout}>Logout</span>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ auth, conversations }) => ({
  ...auth,
  ...conversations,
});

export default connect(mapStateToProps, {
  logoutAction,
  getConversationsUnreadCount,
})(Sidebar);
