import {
  GET_CUSTOM_FIELDS_LOADER,
  GET_CUSTOM_FIELDS_RESPONSE,
  CUSTOM_FIELDS_RESET,
  CREATE_CUSTOM_FIELDS_LOADER,
  CREATE_CUSTOM_FIELDS_RESPONSE,
  UPDATE_CUSTOM_FIELDS_LOADER,
  UPDATE_CUSTOM_FIELDS_RESPONSE,
  DELETE_CUSTOM_FIELDS_LOADER,
  DELETE_CUSTOM_FIELDS_RESPONSE,
} from "../constants";

const INIT_STATE = {
  customFieldsData: [],
  customFieldsMeta: {
    limit: 10,
    offset: 0,
    total: 0,
  },
  isCreatingCustomFields: false,
  isUpdatingCustomFields: false,
  isDeletingCustomFields: false,
  isLoadingCustomFields: true,
  createCustomFieldsOutcome: "",
  createCustomFieldsMessage: "",
  updateCustomFieldsOutcome: "",
  updateCustomFieldsMessage: "",
  deleteCustomFieldsOutcome: "",
  deleteCustomFieldsMessage: "",
  customFieldsOutcome: "",
  customFieldsMessage: "",
};

const CustomFields = (state = INIT_STATE, action) => {
  const {
    type,
    createCustomFieldsOutcome,
    createCustomFieldsMessage,
    updateCustomFieldsOutcome,
    updateCustomFieldsMessage,
    deleteCustomFieldsOutcome,
    deleteCustomFieldsMessage,
    customFieldsOutcome,
    customFieldsMessage,
    customFieldsData,
    customFieldsMeta,
    isCreatingCustomFields,
    isUpdatingCustomFields,
    isDeletingCustomFields,
    isLoadingCustomFields,
  } = action;

  switch (type) {
    case CUSTOM_FIELDS_RESET: {
      return {
        ...state,
        createCustomFieldsOutcome: "",
        createCustomFieldsMessage: "",
        updateCustomFieldsOutcome: "",
        updateCustomFieldsMessage: "",
        deleteCustomFieldsOutcome: "",
        deleteCustomFieldsMessage: "",
      };
    }

    case GET_CUSTOM_FIELDS_LOADER: {
      return {
        ...state,
        isLoadingCustomFields,
      };
    }

    case GET_CUSTOM_FIELDS_RESPONSE: {
      return {
        ...state,
        customFieldsOutcome,
        customFieldsData,
        customFieldsMeta,
        customFieldsMessage,
      };
    }

    case CREATE_CUSTOM_FIELDS_LOADER: {
      return {
        ...state,
        isCreatingCustomFields,
      };
    }

    case CREATE_CUSTOM_FIELDS_RESPONSE: {
      return {
        ...state,
        createCustomFieldsOutcome,
        createCustomFieldsMessage,
      };
    }

    case UPDATE_CUSTOM_FIELDS_LOADER: {
      return {
        ...state,
        isUpdatingCustomFields,
      };
    }

    case UPDATE_CUSTOM_FIELDS_RESPONSE: {
      return {
        ...state,
        updateCustomFieldsOutcome,
        updateCustomFieldsMessage,
      };
    }

    case DELETE_CUSTOM_FIELDS_LOADER: {
      return {
        ...state,
        isDeletingCustomFields,
      };
    }

    case DELETE_CUSTOM_FIELDS_RESPONSE: {
      return {
        ...state,
        deleteCustomFieldsOutcome,
        deleteCustomFieldsMessage,
      };
    }

    default:
      return state;
  }
};

export default CustomFields;
