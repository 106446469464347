import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import mixpanel from "mixpanel-browser";

import Thread from "./Thread";
import LottieAnimation from "../../../components/LottieWeb";

import { Queue } from "../../../assets/vectors";

import { emptyStates } from "../data";

const ChatContainer = ({
  selectedSection,
  selectedMention,
  mentionReplies,
  closeMention,
  getMentionReplies,
  updateUnreadCount,
  updateMessageUnreadCount,
  getOpenMentions,
  getQueueMentions,
  returnToQueue,
  assignMention,
}) => {
  const { id } = useParams();

  const { isLoadingMention } = useSelector((state) => state.mentions);
  const { firmChannelsData } = useSelector((state) => state.channels);

  const emptyStateContent =
    emptyStates.find(({ type }) => type === selectedSection) || {};

  // New Message Action Listener - Starts Here
  const newMessageElement =
    document.querySelector(".oneroute_widget") ||
    document.querySelector("#or-root");

  useEffect(() => {
    const timer = setInterval(() => {
      var newMessageValue =
        newMessageElement.getAttribute("data-newappmessage");
      newMessageValue = JSON.parse(decodeURIComponent(newMessageValue));
      if (newMessageValue?.conversation) {
        mixpanel.track("Received New Message", {
          channel: newMessageValue?.conversation?.Channel?.medium,
        });
        newMessageAction(newMessageValue?.conversation);
      }
      if (newMessageValue?.id) {
        newMessageAction(newMessageValue);
      }
    }, 1000);

    // clearing interval
    return () => clearInterval(timer);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  // New Message Action Listener - Ends Here

  const newMessageAction = async (data) => {
    newMessageElement.setAttribute("data-newappmessage", "false");

    if (data?.status === "open") {
      getOpenMentions(true);
      getQueueMentions(true);
    }
    if (data?.status === "in-queue") getQueueMentions(true);

    if (id === data?.id) getMentionReplies(true);
    if (id !== data?.id) {
      updateUnreadCount(data?.status);
      updateMessageUnreadCount(data?.id);
    }
  };

  const channelsData = firmChannelsData?.filter((x) => x?.status === "ACTIVE");

  return (
    <div className="chat-container">
      {isLoadingMention ? (
        <div className="loader-container text-center">
          <LottieAnimation
            path={require("../../../assets/jsons/loader.json")}
            autoplay
          />
        </div>
      ) : selectedMention?.id ? (
        <>
          <Thread
            selectedMention={selectedMention}
            mentionReplies={mentionReplies}
            closeMention={closeMention}
            selectedSection={selectedSection}
            returnToQueue={returnToQueue}
            assignMention={assignMention}
            getMentionReplies={getMentionReplies}
          />
        </>
      ) : (
        <div className="empty-state">
          <div className="icon">
            {channelsData?.length === 0 ? <Queue /> : emptyStateContent.icon}
          </div>
          <span>
            {channelsData?.length === 0
              ? "Add channels to start having conversations"
              : emptyStateContent.text}
          </span>
          <br />
          {channelsData?.length === 0 && (
            <Link to="/get-started" className="get_started_block_button">
              Go back to set up
            </Link>
          )}
        </div>
      )}
    </div>
  );
};

export default ChatContainer;
