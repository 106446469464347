import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

import {
  createTeamAction,
  updateTeamAction,
} from "../../../redux/actions/Teams";

import { Button, TextField } from "../../../components/FormElements";
import Modal from "../../../components/Modal";
import FormNotifications from "../../../components/Notifications";

const CreateTeamForm = ({
  toggleForm,
  teamData,
  usersOptions,
  channelOptions,
}) => {
  const dispatch = useDispatch();

  const {
    createTeamOutcome,
    createTeamMessage,
    updateTeamOutcome,
    updateTeamMessage,
    isCreatingTeam,
    isUpdatingTeam,
  } = useSelector((state) => state.teams);

  const defaultUsers = teamData?.Users?.map((x) => ({
    label: `${x?.firstName} ${x?.lastName}`,
    value: x?.id,
  }));
  const defaultChannels = teamData?.Channels?.map((x) => ({
    label: `(${x?.medium}) - ${x?.name}`,
    value: x?.id,
  }));

  const [teamName, setTeamName] = useState(teamData?.name || "");
  const [selectedUsers, setSelectedUsers] = useState(defaultUsers || []);
  const [selectedChannels, setSelectedChannels] = useState(
    defaultChannels || []
  );

  const isFormValid =
    !isCreatingTeam &&
    !isUpdatingTeam &&
    teamName &&
    selectedUsers?.length > 0 &&
    selectedChannels?.length > 0;

  const submitForm = (e) => {
    e.preventDefault();

    const payload = {
      name: teamName,
      users: selectedUsers?.map((x) => x?.value),
      channels: selectedChannels?.map((x) => x?.value),
    };

    teamData
      ? dispatch(updateTeamAction(payload, teamData?.id))
      : dispatch(createTeamAction(payload));
  };

  return (
    <Modal closeModal={() => toggleForm()}>
      <div className="modal-content">
        <div className="modal-title">{teamData ? "Edit" : "New"} Team</div>
        <div className="modal-form saved-response-form">
          <form onSubmit={submitForm}>
            <TextField
              label="Name"
              name="name"
              value={teamName}
              onChange={(e) => setTeamName(e?.target?.value)}
              className="mb-25"
            />
            <div className="input-container mb-25">
              <label>Users</label>
              <Select
                classNamePrefix="select_container"
                value={selectedUsers}
                options={usersOptions}
                onChange={(selections) => setSelectedUsers(selections)}
                isDisabled={isCreatingTeam || isUpdatingTeam}
                isClearable
                isMulti
              />
            </div>
            <div className="input-container mb-50">
              <label>Channels</label>
              <Select
                classNamePrefix="select_container"
                value={selectedChannels}
                options={channelOptions}
                onChange={(selections) => setSelectedChannels(selections)}
                isDisabled={isCreatingTeam || isUpdatingTeam}
                isClearable
                isMulti
              />
            </div>
            <div className="modal-form-action">
              <Button
                type="button"
                className="secondary-button"
                onClick={() => toggleForm()}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="primary-button"
                disabled={!isFormValid}
              >
                {isCreatingTeam || isUpdatingTeam
                  ? "Processing..."
                  : teamData
                  ? "Save Changes"
                  : "Add Team"}
              </Button>
            </div>

            {createTeamOutcome && (
              <FormNotifications
                type={createTeamOutcome}
                message={createTeamMessage}
              />
            )}
            {updateTeamOutcome && (
              <FormNotifications
                type={updateTeamOutcome}
                message={updateTeamMessage}
              />
            )}
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default CreateTeamForm;
