import React from "react";

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <g
      stroke="#474F58"
      strokeLinecap="round"
      strokeLinejoin="round"
      clipPath="url(#send2Clip0)"
    >
      <path d="M1.84 10.42a1.109 1.109 0 01-.588-2.05L13.574.667a1.109 1.109 0 011.677 1.147l-2.4 12.782a1.109 1.109 0 01-1.977.46L7.4 10.42H1.84z"></path>
      <path d="M7.401 10.42H5.734v3.447a1.109 1.109 0 001.734.916l2.118-1.444-2.185-2.92zM7.398 10.42L14.785.692"></path>
    </g>
    <defs>
      <clipPath id="send2Clip0">
        <path fill="#fff" d="M0 0H16V16H0z"></path>
      </clipPath>
    </defs>
  </svg>
);
