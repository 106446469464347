import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  getIntegrationsAction,
  getFirmIntegrationsAction,
} from "../../../redux/actions/Integrations";

import { TextField } from "../../../components/FormElements";

import { Search } from "../../../assets/vectors";

import { integrationsPermissions } from "../../../utils/permissions";

const Integrations = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { isLoadingIntegrations, integrationsData } = useSelector(
    (state) => state.integrations
  );

  const [firmIntegrations, setFirmIntegrations] = useState([]);
  const [query, setQuery] = useState({});

  useEffect(() => {
    dispatch(getIntegrationsAction());
    dispatch(getFirmIntegrationsAction()).then((res) =>
      setFirmIntegrations(res)
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFilter = (name, value) => {
    setQuery({
      ...query,
      [name]: value,
    });
  };
  useEffect(() => {
    if (query?.q) {
      dispatch(getIntegrationsAction(query));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const activeFirmIntegrations =
    firmIntegrations?.filter((x) => x?.status === "active") || [];

  return (
    <div className="settings-page-container">
      <div className="page-header">
        <span className="title">Integrations</span>
      </div>
      <div className="integrations-container">
        <div className="search-integrations">
          <TextField
            placeholder="Search by name or category (e.g. productivity, communication"
            leftIcon={<Search />}
            onChange={(e) => handleFilter("q", e.target.value)}
          />
        </div>
        {isLoadingIntegrations ? (
          <div className="integration-section">
            <div className="integration-section-title text-center">
              Loading...
            </div>
            <br />
            <br />
            <br />
            <br />
          </div>
        ) : (
          <>
            <div className="integration-section">
              <div className="integration-section-title">My Integrations</div>
              <div className="integration-section-content">
                {activeFirmIntegrations.map(({ type, config }, index) => (
                  <div
                    key={`my-integration-${index}`}
                    className="integration-item"
                    onClick={() =>
                      history.push(`/settings/integrations/${type}`)
                    }
                  >
                    <div className="logo">
                      <img src={config?.imageUrl} alt="" />
                    </div>
                    <div className="title text-capitalize">{config?.name}</div>
                    <div className="type">{config?.genre}</div>
                    <div className="description">{config?.description}</div>
                  </div>
                ))}
              </div>
            </div>
            {(integrationsPermissions.create ||
              integrationsPermissions.modifyAll) && (
              <div className="integration-section">
                <div className="integration-section-title">
                  All Integrations
                </div>
                <div className="integration-section-content">
                  {integrationsData.map(
                    ({ imageUrl, name, type, genre, description }, index) => (
                      <div
                        key={`my-integration-${index}`}
                        className="integration-item"
                        onClick={() =>
                          history.push(`/settings/integrations/${type}`)
                        }
                      >
                        <div className="logo">
                          <img src={imageUrl} alt="" />
                        </div>
                        <div className="title text-capitalize">{name}</div>
                        <div className="type">{genre}</div>
                        <div className="description">{description}</div>
                      </div>
                    )
                  )}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Integrations;
