import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { Spinner, Redirect, LogoSolid } from "../../../../assets/vectors";
import { Button } from "../../../../components/FormElements";
import Modal from "../../../../components/Modal";
import FormNotifications from "../../../../components/Notifications";

import { getWindowBaseUrl } from "../../../../utils/functions";

import {
  getIntegrationRequestAction,
  postSubscribeIntegrationAction,
} from "../../../../redux/actions/Integrations";

const ZohoDeskSetup = ({ close, onSetupSuccess }) => {
  const dispatch = useDispatch();
  const {
    isCreatingIntegration,
    createIntegrationOutcome,
    createIntegrationMessage,
  } = useSelector((state) => state?.integrations);
  let location = useLocation();

  const [currentStep, setCurrentStep] = useState(1);
  const [accessToken, setAccessToken] = useState(null);
  const [zohoOrganizations, setZohoOrganizations] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState(null);

  const windowBaseUrl = getWindowBaseUrl() || "";

  const oauth_code = new URLSearchParams(location.search).get("code") || null;

  useEffect(() => {
    if (currentStep === 1 && oauth_code) postSubscribeZohoDeskUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oauth_code]);

  const getZohoDeskRequestToken = () => {
    dispatch(
      getIntegrationRequestAction({
        url: "/integrations/zoho/oauth/request_token",
        params: {
          redirectUrl: `${windowBaseUrl}`,
        },
      })
    ).then((res) => {
      if (res?.data?.auth_url) {
        window.location.assign(res?.data?.auth_url);
      }
    });
  };

  const postSubscribeZohoDeskUser = () => {
    const requestData = {
      code: oauth_code,
      redirectUrl: `${windowBaseUrl}`,
    };

    dispatch(
      postSubscribeIntegrationAction({
        url: "/integrations/zoho/connect",
        requestData,
      })
    ).then((res) => {
      if (res?.data?.success === true) {
        setAccessToken(res?.data?.data?.credentials?.access_token);
      }
    });
  };

  useEffect(() => {
    if (accessToken) getZohoIntegrationOrganization();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  const getZohoIntegrationOrganization = () => {
    dispatch(
      getIntegrationRequestAction({
        url: "/integrations/zoho/organization",
        params: {
          token: accessToken,
        },
      })
    ).then((res) => {
      if (res?.data?.success === true) {
        setZohoOrganizations(res?.data?.data);
        setCurrentStep(2);
      }
    });
  };

  const postZohoIntegrationOrganization = () => {
    const requestData = {
      orgId: selectedOrganization?.id,
    };

    dispatch(
      postSubscribeIntegrationAction({
        url: "/integrations/zoho/organization",
        requestData,
        completedSetupFor: "Zoho Desk",
      })
    ).then((res) => {
      if (res?.data?.success === true) {
        onSetupSuccess();
        setCurrentStep(3);
      }
    });
  };

  return (
    <React.Fragment>
      {currentStep === 1 && (
        <Modal className="channel-modal">
          <div className="modal-content new-conversation-container">
            <div className="modal-title">
              You are about to be redirected to ZohoDesk
            </div>
            <div className="modal-form new-conversation-form">
              <h6>
                Clicking continue will redirect you to ZohoDesk. From there, you
                would need to authorize OneRoute to properly connect to your
                ZohoDesk account
              </h6>
              <div className="logos">
                <LogoSolid />
                <Redirect />
                <img
                  src="https://zohowebstatic.com/sites/default/files/ogimage/desk-logo.png"
                  alt="Zoho Desk Logo"
                />
              </div>
              <div className="actions">
                {!isCreatingIntegration ? (
                  <>
                    <Button className="secondary-button" onClick={close}>
                      Cancel
                    </Button>
                    <Button
                      className="primary-button"
                      onClick={getZohoDeskRequestToken}
                    >
                      Continue with ZohoDesk
                    </Button>
                  </>
                ) : (
                  <div className="spinner">
                    <Spinner />
                  </div>
                )}
              </div>
              {!isCreatingIntegration && createIntegrationOutcome && (
                <FormNotifications
                  type={createIntegrationOutcome}
                  message={createIntegrationMessage}
                />
              )}
            </div>
          </div>
        </Modal>
      )}

      {currentStep === 2 && (
        <Modal className="channel-modal">
          <div className="modal-content new-conversation-container">
            <div className="modal-title">
              Connect Zoho Organization to your OneRoute account
            </div>
            <div className="modal-form new-conversation-form">
              <h6>
                Choose the organization you would like to use to support your
                customers on OneRoute.
              </h6>
              <div className="facebook_pages">
                {zohoOrganizations?.map((item, i) => (
                  <div
                    key={i}
                    className="page"
                    onClick={() => {
                      setSelectedOrganization(item);
                    }}
                  >
                    <h6>{item?.companyName}</h6>
                    <div
                      className={`check ${
                        selectedOrganization?.id === item?.id ? "active" : ""
                      }`}
                    >
                      <span></span>
                    </div>
                  </div>
                ))}
                {!isCreatingIntegration &&
                  createIntegrationOutcome === "error" && (
                    <FormNotifications
                      type="error"
                      message={createIntegrationMessage}
                    />
                  )}
              </div>
              <div className="actions">
                <Button className="secondary-button" onClick={close}>
                  Cancel
                </Button>
                <Button
                  className="primary-button"
                  onClick={postZohoIntegrationOrganization}
                  disabled={!selectedOrganization || isCreatingIntegration}
                >
                  {isCreatingIntegration ? "Processing..." : "Continue"}
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}

      {currentStep === 3 && (
        <Modal className="channel-modal">
          <div className="modal-content new-conversation-container">
            <div className="modal-title">Integration setup complete</div>
            <div className="modal-form new-conversation-form">
              <div className="d-flex">
                <div className="info">
                  <ul>
                    <li>You can now use ZohoDesk on OneRoute.</li>
                  </ul>
                </div>
              </div>
              <div className="actions">
                <Button
                  className="primary-button"
                  onClick={() => {
                    setCurrentStep(null);
                    close();
                  }}
                >
                  Dismiss
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default ZohoDeskSetup;
