import {
    CREATE_SAVED_RESPONSES_LOADER,
    CREATE_SAVED_RESPONSES_RESPONSE,
    CREATE_SAVED_RESPONSES_RESET,
    UPDATE_SAVED_RESPONSES_LOADER,
    UPDATE_SAVED_RESPONSES_RESPONSE,
    UPDATE_SAVED_RESPONSES_RESET,
    GET_SAVED_RESPONSES_LOADER,
    GET_SAVED_RESPONSES_RESPONSE,
    GET_SAVED_RESPONSES_RESET,
    DELETE_SAVED_RESPONSES_LOADER,
    DELETE_SAVED_RESPONSES_RESPONSE,
    DELETE_SAVED_RESPONSES_RESET,
  } from "../constants";
  
  const INIT_STATE = {
    savedResponsesData: [],
    savedResponsesMeta: {
      limit: 10,
      offset: 0,
      total: 0
    },
    isCreatingSavedResponse: false,
    isUpdatingSavedResponse: false,
    isDeletingSavedResponse: false,
    isLoadingSavedResponses: true,
    createSavedResponsesOutcome: "",
    createSavedResponsesMessage: "",
    updateSavedResponsesOutcome: "",
    updateSavedResponsesMessage: "",
    deleteSavedResponsesOutcome: "",
    deleteSavedResponsesMessage: "",
    savedResponsesOutcome: "",
    savedResponsesMessage: "",
  };
  
  const SavedResponses = (state = INIT_STATE, action) => {
    const {
      type,
      createSavedResponsesOutcome,
      createSavedResponsesMessage,
      updateSavedResponsesOutcome,
      updateSavedResponsesMessage,
      deleteSavedResponsesOutcome,
      deleteSavedResponsesMessage,
      savedResponsesOutcome,
      savedResponsesMessage,
      savedResponsesData,
      savedResponsesMeta,
      isCreatingSavedResponse,
      isUpdatingSavedResponse,
      isDeletingSavedResponse,
      isLoadingSavedResponses
    } = action;
  
    switch (type) {
      case CREATE_SAVED_RESPONSES_LOADER: {
        return {
          ...state,
          isCreatingSavedResponse
        };
      }
  
      case CREATE_SAVED_RESPONSES_RESPONSE: {
        return {
          ...state,
          createSavedResponsesOutcome,
          createSavedResponsesMessage
        };
      }
  
      case CREATE_SAVED_RESPONSES_RESET: {
        return {
          ...state,
          createSavedResponsesOutcome: "",
          createSavedResponsesMessage: ""
        };
      }
      
      case UPDATE_SAVED_RESPONSES_LOADER: {
        return {
          ...state,
          isUpdatingSavedResponse
        };
      }
  
      case UPDATE_SAVED_RESPONSES_RESPONSE: {
        return {
          ...state,
          updateSavedResponsesOutcome,
          updateSavedResponsesMessage
        };
      }
  
      case UPDATE_SAVED_RESPONSES_RESET: {
        return {
          ...state,
          updateSavedResponsesOutcome: "",
          updateSavedResponsesMessage: ""
        };
      }
  
      case DELETE_SAVED_RESPONSES_LOADER: {
        return {
          ...state,
          isDeletingSavedResponse
        };
      }
  
      case DELETE_SAVED_RESPONSES_RESPONSE: {
        return {
          ...state,
          deleteSavedResponsesOutcome,
          deleteSavedResponsesMessage
        };
      }
  
      case DELETE_SAVED_RESPONSES_RESET: {
        return {
          ...state,
          deleteSavedResponsesOutcome: "",
          deleteSavedResponsesMessage: ""
        };
      }
  
      case GET_SAVED_RESPONSES_LOADER: {
        return {
          ...state,
          isLoadingSavedResponses
        };
      }
  
      case GET_SAVED_RESPONSES_RESPONSE: {
        return {
          ...state,
          savedResponsesOutcome,
          savedResponsesData,
          savedResponsesMeta, 
          savedResponsesMessage
        };
      }
  
      case GET_SAVED_RESPONSES_RESET: {
        return INIT_STATE;
      }
  
      default:
        return state;
    }
  };
  
  export default SavedResponses;