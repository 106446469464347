import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

import {
  createCustomFieldsAction,
  updateCustomFieldsAction,
} from "../../../redux/actions/CustomFields";

import { Button, TextField } from "../../../components/FormElements";
import Modal from "../../../components/Modal";
import FormNotifications from "../../../components/Notifications";
import { CircularPlus, Close } from "../../../assets/vectors";

const CustomFieldForm = ({ fieldData, toggleForm, getFieldsAction }) => {
  const dispatch = useDispatch();

  const {
    createCustomFieldsOutcome,
    createCustomFieldsMessage,
    updateCustomFieldsOutcome,
    updateCustomFieldsMessage,
    isCreatingCustomFields,
    isUpdatingCustomFields,
  } = useSelector((state) => state.customFields);

  const [formData, setFormData] = useState({
    name: fieldData?.name,
    type: null,
    options: fieldData?.options?.map((x) => x["value"]) || null,
  });

  const typeOptions = [
    { label: "Single line text", value: "single_line_text" },
    { label: "Multi line text", value: "multi_line_text" },
    { label: "Number", value: "number" },
    { label: "Attachment", value: "attachment" },
    { label: "Single select", value: "single_select" },
    { label: "Multi select", value: "multi_select" },
    { label: "Date", value: "date" },
  ];

  useEffect(() => {
    const selectedType =
      typeOptions?.filter((x) => x?.value === fieldData?.type)?.[0] || null;

    setFormData({
      ...formData,
      type: selectedType,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = ({ target }) => {
    setFormData({
      ...formData,
      [target.name]: target.value,
    });
  };

  const handleUpdateOptions = ({ target }, index) => {
    const newOptions = formData?.options;
    newOptions[index] = target.value;

    setFormData({
      ...formData,
      options: newOptions,
    });
  };

  const addNewOptionInput = () => {
    setFormData({
      ...formData,
      options: formData?.options ? [...formData?.options, ""] : [""],
    });
  };

  const removeOption = (value) => {
    setFormData({
      ...formData,
      options: formData?.options?.filter((x) => x !== value),
    });
  };

  const checkFormValidity = () => {
    let value = true;
    if (
      !formData?.name ||
      !formData?.type?.value ||
      isCreatingCustomFields ||
      isUpdatingCustomFields
    ) {
      return false;
    }

    return value;
  };

  const submitForm = (e) => {
    e.preventDefault();

    var payload = {
      name: formData?.name,
      type: formData?.type?.value,
    };
    if (formData?.options) {
      payload = {
        ...payload,
        options:
          formData?.options?.map((x) => ({
            label: x,
            value: x,
          })) || null,
      };
    }

    if (fieldData) {
      dispatch(
        updateCustomFieldsAction(payload, "customer", fieldData?.id)
      ).then((res) => {
        if (res === 200) {
          toggleForm();
          getFieldsAction();
        }
      });
    } else {
      dispatch(createCustomFieldsAction([payload], "customer")).then((res) => {
        if (res === 200) {
          toggleForm();
          getFieldsAction();
        }
      });
    }
  };

  return (
    <Modal closeModal={() => toggleForm()}>
      <div className="modal-content">
        <div className="modal-title">{fieldData ? "Edit" : "New"} Field</div>
        <div className="modal-form saved-response-form">
          <form onSubmit={submitForm}>
            <TextField
              label="Field Name"
              name="name"
              value={formData?.name}
              onChange={(e, valid) => handleChange(e, valid)}
              className="mb-25"
            />

            <div className="input-container mb-25">
              <label>Field Type</label>
              <Select
                classNamePrefix="select_container"
                value={formData?.type}
                options={typeOptions}
                onChange={(selection) =>
                  setFormData({ ...formData, type: selection })
                }
                isDisabled={isCreatingCustomFields || isUpdatingCustomFields}
              />
            </div>

            {formData?.type?.value?.includes("select") ? (
              <div className="all-options">
                <p>Define options</p>

                {formData?.options?.map((option, index) => (
                  <div className="option" key={index}>
                    <TextField
                      name={index}
                      value={formData?.options?.[index]}
                      placeholder={`Option ${index + 1}`}
                      onChange={(event) => handleUpdateOptions(event, index)}
                    />

                    <span onClick={() => removeOption(option)}>
                      <Close />
                    </span>
                  </div>
                ))}

                <div className="add-option" onClick={addNewOptionInput}>
                  Add option <CircularPlus />
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="modal-form-action">
              <Button
                type="button"
                className="secondary-button"
                onClick={() => toggleForm()}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="primary-button"
                disabled={!checkFormValidity()}
              >
                {isCreatingCustomFields || isUpdatingCustomFields
                  ? "Processing..."
                  : fieldData
                  ? "Save Changes"
                  : "Add Field"}
              </Button>
            </div>

            {createCustomFieldsOutcome && (
              <FormNotifications
                type={createCustomFieldsOutcome}
                message={createCustomFieldsMessage}
              />
            )}
            {updateCustomFieldsOutcome && (
              <FormNotifications
                type={updateCustomFieldsOutcome}
                message={updateCustomFieldsMessage}
              />
            )}
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default CustomFieldForm;
