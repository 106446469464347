import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import * as _ from "lodash";
import { decode } from "html-entities";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

import { Button, TextField, FileInput } from "../../../components/FormElements";
import AudioPlayer from "../../../components/AudioPlayer";
import useCloseElement from "../../../components/CustomHooks/useCloseElement";

import {
  Close,
  File,
  Attachment,
  Search,
  AngleDown,
} from "../../../assets/vectors";
import { getExtensionType, getUserDetails } from "../../../utils/functions";

const EmailChatForm = ({
  closeModal,
  selectedConversation,
  subject,
  content,
  onSubjectChange,
  onEmailContentChange,
  sendMessage,
  handleSetDefaultCcs,
  addEmailToCcs,
  onCcChange,
  removeFromCcs,
  emailCcs,
  newEmailCc,
  handleSetDefaultBccs,
  addEmailToBccs,
  onBccChange,
  removeFromBccs,
  emailBccs,
  newEmailBcc,
  onFileLoad,
  onFileError,
  removeFile,
  media,
  rawData,
  openFilePreview,
}) => {
  const { savedResponsesData } = useSelector((state) => state.savedResponses);
  const { signatureData } = useSelector((state) => state.signature);
  const [isSavedResponsesActive, setIsSavedResponsesActive] = useState(false);
  const [filteredResponses, setFilteredResponses] =
    useState(savedResponsesData);
  const [isSignaturesActive, setIsSignaturesActive] = useState(false);
  const [filteredSignatures, setFilteredSignatures] = useState(signatureData);
  const [isCcVisible, setCcVisibility] = useState(false);
  const [isBccVisible, setBccVisibility] = useState(false);

  const editor = useRef(null);

  useEffect(() => {
    const messageLength = selectedConversation?.Messages?.length - 1;
    const mostRecentCc =
      selectedConversation?.Messages?.[messageLength]?.sender?.cc;
    const mostRecentBcc =
      selectedConversation?.Messages?.[messageLength]?.sender?.bcc;

    if (mostRecentCc?.length > 0) {
      handleSetDefaultCcs(mostRecentCc);
      setCcVisibility(true);
    }
    if (mostRecentBcc?.length > 0) {
      handleSetDefaultBccs(mostRecentBcc);
      setBccVisibility(true);
    }

    appendDefaultSignature();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSunEditorInstance = (sunEditor) => {
    editor.current = sunEditor;
  };

  const toggleSavedResponses = () => {
    setIsSavedResponsesActive(!isSavedResponsesActive);
  };

  const searchSavedResponses = (value) => {
    const filteredResponses = savedResponsesData?.filter(({ title }) =>
      title.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredResponses(filteredResponses);
  };

  const selectSavedResponse = (response, formatted) => {
    const content = formatted ? decode(response, { level: "html5" }) : response;
    setIsSavedResponsesActive(false);
    editor.current.insertHTML(content);
  };

  const toggleSignatures = () => {
    setIsSignaturesActive(!isSignaturesActive);
  };

  const searchSignatures = (value) => {
    const filteredSignatures = signatureData?.filter(({ name }) =>
      name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredSignatures(filteredSignatures);
  };

  const appendDefaultSignature = () => {
    const activeSign = signatureData?.filter((x) => x?.active === true)?.[0]
      ?.content;
    if (activeSign) selectSignature(activeSign);
  };

  const selectSignature = (selected) => {
    const user = getUserDetails();
    const { firstName, lastName, firm_name } = user;

    const signatureStart = "<p><br><br>--<br></p>";
    const decodedHtml = decode(selected, { level: "html5" });

    var formatted = signatureStart + decodedHtml;
    formatted = formatted.replace("{{agentName}}", `${firstName} ${lastName}`);
    formatted = formatted.replace("{{companyName}}", `${firm_name || ""}`);

    setIsSignaturesActive(false);
    editor.current.insertHTML(formatted);
  };

  return (
    <div className="email_chat_modal_content">
      <div className="header">
        <h6>SEND EMAIL</h6>
        <span className="close" onClick={() => closeModal()}>
          <Close />
        </span>
      </div>
      <div className="content">
        <div className="send_to">
          <span>Send to:</span>
          <div className="emails">
            {selectedConversation?.customer?.email && (
              <div className="email">
                <p>{selectedConversation?.customer?.email}</p>
              </div>
            )}
          </div>
          <span className="toggle_cc">
            <span onClick={() => setCcVisibility(!isCcVisible)}>Cc</span>
            {" / "}
            <span onClick={() => setBccVisibility(!isBccVisible)}>Bcc</span>
          </span>
        </div>
        {isCcVisible && (
          <div className="send_to">
            <span>Cc:</span>
            <div className="emails">
              {emailCcs?.map((item, i) => (
                <div key={i} className="email">
                  <p>{item}</p>
                  <div
                    className="remove_cc"
                    onClick={() => removeFromCcs(item)}
                  >
                    <Close />
                  </div>
                </div>
              ))}
              <TextField
                type="email"
                name="ccEmail"
                value={newEmailCc}
                placeholder="example@email.com"
                onChange={onCcChange}
                onKeyDown={addEmailToCcs}
                autoComplete="new-password"
              />
            </div>
          </div>
        )}
        {isBccVisible && (
          <div className="send_to">
            <span>Bcc:</span>
            <div className="emails">
              {emailBccs?.map((item, i) => (
                <div key={i} className="email">
                  <p>{item}</p>
                  <div
                    className="remove_cc"
                    onClick={() => removeFromBccs(item)}
                  >
                    <Close />
                  </div>
                </div>
              ))}
              <TextField
                type="email"
                name="bccEmail"
                value={newEmailBcc}
                placeholder="example@email.com"
                onChange={onBccChange}
                onKeyDown={addEmailToBccs}
                autoComplete="new-password"
              />
            </div>
          </div>
        )}
        <div className="d_flex">
          <div className="subject">
            <span>Subject:</span>
            <TextField
              type="text"
              name="subject"
              value={subject}
              placeholder="Type here..."
              onChange={onSubjectChange}
            />
          </div>
          <div className="subject">
            <span onClick={toggleSavedResponses} style={{ cursor: "pointer" }}>
              Select a Saved Response <AngleDown />
            </span>
            {isSavedResponsesActive && (
              <SavedResponses
                searchSavedResponses={searchSavedResponses}
                filteredResponses={filteredResponses}
                selectSavedResponse={selectSavedResponse}
                toggleSavedResponses={toggleSavedResponses}
              />
            )}
          </div>
          <div className="subject">
            <span onClick={toggleSignatures} style={{ cursor: "pointer" }}>
              Select a Signature <AngleDown />
            </span>
            {isSignaturesActive && (
              <Signatures
                filteredSignatures={filteredSignatures}
                selectSignature={selectSignature}
                searchSignatures={searchSignatures}
                toggleSignatures={toggleSignatures}
              />
            )}
          </div>
        </div>
        <SunEditor
          getSunEditorInstance={getSunEditorInstance}
          name="message"
          placeholder="Type your message here..."
          setOptions={{
            fontSize: [14, 16, 20, 24, 26],
            buttonList: [
              ["undo", "redo", "font", "fontSize"],
              ["fontColor", "hiliteColor", "bold", "italic", "underline"],
              ["align", "list", "link", "codeView"],
            ],
          }}
          defaultValue={content}
          onChange={onEmailContentChange}
        />
        <FileInput
          icon={
            <div className="attach">
              Attach <Attachment />
            </div>
          }
          onFileLoad={onFileLoad}
          onFileError={onFileError}
        />
        {media && !!Object.keys(media).length && (
          <div className="attachment_media">
            <span className="close" onClick={removeFile}>
              <Close />
            </span>
            {media.contentType === "IMAGE" && (
              <div
                className="message_media"
                onClick={() =>
                  openFilePreview(media.contentType, rawData.rawData)
                }
              >
                <img src={rawData.rawData} alt="" />
              </div>
            )}
            {media.contentType === "VIDEO" && (
              <span
                className="message_media"
                onClick={() =>
                  openFilePreview(media.contentType, rawData.rawData)
                }
              >
                <video controls={false}>
                  <source src={rawData.rawData} type={rawData.mime}></source>
                </video>
              </span>
            )}
            {media.contentType === "DOCUMENT" &&
              (rawData?.fileName &&
              getExtensionType(rawData?.fileName) === "mp3" ? (
                <div className="message-audio">
                  <AudioPlayer src={rawData.rawData} />
                </div>
              ) : (
                <div className="message-file">
                  <span className="icon">
                    <File />
                  </span>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a target="_blank">{rawData.fileName}</a>
                </div>
              ))}
          </div>
        )}
      </div>
      <div className="action">
        <Button className="primary-button" onClick={sendMessage}>
          Send email
        </Button>
      </div>
    </div>
  );
};

const SavedResponses = ({
  filteredResponses,
  selectSavedResponse,
  searchSavedResponses,
  toggleSavedResponses,
}) => {
  const responses = _.chain(
    filteredResponses?.map((resp) => ({
      ...resp,
      category: resp.Category.name,
    }))
  )
    .groupBy("category")
    .map((value, key) => ({ category: key, data: value }))
    .value();

  const savedResponsesRef = useRef(null);
  useCloseElement(savedResponsesRef, toggleSavedResponses);

  return (
    <div className="chatinput-container">
      <div
        className="saved-responses-container"
        ref={savedResponsesRef}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="title">Saved Responses</div>
        <div className="search">
          <TextField
            leftIcon={<Search />}
            placeholder="Search"
            onChange={(e) => searchSavedResponses(e.target.value)}
          />
        </div>
        {responses.map(({ category, data }, index) => (
          <div
            key={`responses-section-${index}`}
            className="saved-responses-section"
          >
            <div className="responses-category">
              {category}{" "}
              <span className="icon">
                <AngleDown />
              </span>
            </div>
            {data.map(({ title, content, config }, index2) => (
              <div
                key={`response-item-${index2}`}
                className="responses-item"
                onClick={() => selectSavedResponse(content, config?.formatted)}
              >
                <span className="item-title">{title}</span>
                {config?.formatted ? (
                  <span
                    className="item-value"
                    dangerouslySetInnerHTML={{
                      __html: decode(content, { level: "html5" }),
                    }}
                  ></span>
                ) : (
                  <span className="item-value">{content}</span>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

const Signatures = ({
  filteredSignatures,
  selectSignature,
  searchSignatures,
  toggleSignatures,
}) => {
  const savedResponsesRef = useRef(null);
  useCloseElement(savedResponsesRef, toggleSignatures);

  return (
    <div className="chatinput-container">
      <div
        className="saved-responses-container"
        ref={savedResponsesRef}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="title">Signatures</div>
        <div className="search">
          <TextField
            leftIcon={<Search />}
            placeholder="Search"
            onChange={(e) => searchSignatures(e.target.value)}
          />
        </div>
        {filteredSignatures.map(({ name, content }, index) => (
          <div
            key={`responses-section-${index}`}
            className="saved-responses-section"
          >
            <div
              className="responses-item"
              onClick={() => selectSignature(content)}
            >
              <span className="item-title">{name}</span>
              <span
                className="item-value"
                dangerouslySetInnerHTML={{
                  __html: decode(content, { level: "html5" }),
                }}
              ></span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EmailChatForm;
