import React, { useEffect } from "react";
import { toPng } from "html-to-image";
import { jsPDF } from "jspdf";
import * as moment from "moment";

import "./Invoice-Pdf-Generator.css";

const WalletPdfGenerator = ({ transactionToDownload }) => {
  const { transactionRef, action, quantity, updatedAt } =
    transactionToDownload || {};

  const downloadPdf = () => {
    toPng(document.getElementById("transactionDivToPrint"), {
      quality: 1,
    }).then(function (dataUrl) {
      var link = document.createElement("a");
      link.download = "my-image-name.jpeg";
      const pdf = new jsPDF();
      const imgProps = pdf.getImageProperties(dataUrl);
      const pdfWidth = 210;
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(dataUrl, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("OneRoute Transaction.pdf");
    });
  };

  useEffect(() => {
    if (transactionToDownload) downloadPdf();
  }, [transactionToDownload]);

  return (
    <div>
      <div id="transactionDivToPrint" className="pdf_generator_container">
        <main className="main">
          <div className="content">
            <p>Here's a detailed breakdown of your Wallet Transaction.</p>

            <div className="invoice">
              <h6>Transaction Ref - {transactionRef}</h6>

              <div className="list-div">
                <p>
                  Action
                  <span>{action}</span>
                </p>
                <p>
                  Amount
                  <span>NGN {quantity}</span>
                </p>
                <p>
                  Date
                  <span>{moment(updatedAt).format("DD-MM-YYYY HH:MM")}</span>
                </p>
              </div>
            </div>
          </div>

          <div className="footer">
            <p>1a Mojidi Street, Ikeja, Lagos Nigeria.</p>
            <p>
              Oneroute.io is a property of Route communications Limited, a
              company duly registered with CAC with RC Number: 0101010.
              Oneroute.io provides messeging services under a Cooperative
              License with a registration number 15845. Both Route
              communications and ASB Technology Solutions Ltd are legal entities
              in Nigeria.
            </p>
          </div>
        </main>
      </div>
    </div>
  );
};

export default WalletPdfGenerator;
