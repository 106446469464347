import React, { Component } from "react";
import classNames from "classnames";

import { postRequest } from "../../api";
import { getRequestError } from "../../utils/functions";

class FileUploader extends Component {
  constructor() {
    super();

    this.state = {
      loader: false,
      type: ""
    };
  }

  onDragOver = e => {
    e.stopPropagation();
    e.preventDefault();

    this.setState({ dragOver: true });
  };

  onDragLeave = () => {
    this.setState({ dragOver: false });
  };

  onDrop = e => {
    e.stopPropagation();
    e.preventDefault();

    const { files } = e.dataTransfer;

    if (this.state.loader || !files) {
      return;
    }

    this.uploadFile(files[0]);
    this.setState({ dragOver: false });
  };

  async uploadFile(file) {
    const {
      url,
      index,
      onImageLoading,
      onImageError,
      onImageDone
    } = this.props;

    const fileName = file.name;
    const fileSize = file.size / 1024;
    let last_dot = fileName.lastIndexOf(".");
    let fileType = fileName.slice(last_dot + 1);

    onImageLoading({
      index,
      fileName,
      fileSize,
      fileType,
      status: "uploading"
    });

    const data = new FormData();
    data.append("image", file);

    try {
      await postRequest({
        url,
        data,
        token: true
      });

      onImageDone(index);

      this.setState({
        loader: false
      });
    } catch (error) {
      const message = getRequestError(error);

      this.setState({
        loader: false
      });

      onImageError(index, message);
    }
  }

  render() {
    const { dragOver } = this.state;
    const { ...rest } = this.props;

    return (
      <div>
        <label
          className="drag-drop"
          onDragOver={this.onDragOver}
          onDragLeave={this.onDragLeave}
          onDrop={this.onDrop}
        >
          <input
            type="file"
            onChange={event => this.uploadFile(event.target.files[0])}
            {...rest}
          />
          <div
            className={classNames("uploader", {
              "drag-active": dragOver
            })}
          >
            <span className="drop-file">
              <span>Add files</span> or drop files here
            </span>
          </div>
        </label>
      </div>
    );
  }
}

export default FileUploader;
