import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as moment from "moment";

import { getTeamAction } from "../../../redux/actions/Teams";
import { getAgentsAction } from "../../../redux/actions/Agents";
import { getFirmChannelsAction } from "../../../redux/actions/Channels";

import { Button } from "../../../components/FormElements";
import Table from "../../../components/Table";
import OptionsDropdown from "../../../components/OptionsDropdown";

import { CircularPlus, Expand, Pencil, Trash } from "../../../assets/vectors";
import { agentsPermissions } from "../../../utils/permissions";

import CreateTeamForm from "./CreateTeamForm";
import ViewTeamModal from "./ViewTeamModal";
import AddUsersForm from "./AddUsersForm";
import AddChannelsForm from "./AddChannelsForm";
import DeleteTeamModal from "./DeleteTeamModal";
import SettingsEmptyState from "../../../components/SettingsEmptyState";

const Teams = () => {
  const dispatch = useDispatch();
  const {
    isLoadingTeam,
    teamsData,
    createTeamOutcome,
    updateTeamOutcome,
    deleteTeamOutcome,
  } = useSelector((state) => state.teams);
  const { agentsData } = useSelector((state) => state.agents);
  const { firmChannelsData } = useSelector((state) => state.channels);

  const [isViewTeamModalActive, setIsViewTeamModalActive] = useState(false);
  const [isCreateTeamFormActive, setIsCreateTeamFormActive] = useState(false);
  const [isAddUsersFormActive, setIsAddUsersFormActive] = useState(false);
  const [isAddChannelsFormActive, setIsAddChannelsFormActive] = useState(false);
  const [isDeleteModalActive, setIsDeleteModalActive] = useState(false);
  const [teamsInfo, setTeamsInfo] = useState(true);
  const [selectedTeam, setSelectedTeam] = useState(null);

  useEffect(() => {
    dispatch(getTeamAction());
    dispatch(getAgentsAction({ status: "confirmed" }));
    dispatch(getFirmChannelsAction());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (createTeamOutcome === "success" || updateTeamOutcome === "success") {
      dispatch(getTeamAction());
      setIsViewTeamModalActive(false);
      setIsCreateTeamFormActive(false);
      setIsAddUsersFormActive(false);
      setIsAddChannelsFormActive(false);
      setSelectedTeam(null);
    }

    if (deleteTeamOutcome === "success") {
      dispatch(getTeamAction());
      setSelectedTeam(null);
      setIsDeleteModalActive(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createTeamOutcome, updateTeamOutcome, deleteTeamOutcome]);

  const moreOptions = () => {
    var options = [
      {
        label: (
          <div>
            <span className="icon">
              <Expand />
            </span>{" "}
            <span className="text">View team</span>
          </div>
        ),
        value: "view",
      },
    ];

    if (agentsPermissions?.update || agentsPermissions?.modifyAll) {
      options = [
        ...options,
        {
          label: (
            <div>
              <span className="icon">
                <Pencil />
              </span>{" "}
              <span className="text">Edit team</span>
            </div>
          ),
          value: "edit",
        },
      ];
    }
    if (agentsPermissions?.delete) {
      options = [
        ...options,
        {
          label: (
            <div className="red">
              <span className="icon">
                <Trash />
              </span>{" "}
              <span className="text">Delete team</span>
            </div>
          ),
          value: "delete",
        },
      ];
    }

    return options;
  };

  const optionsAction = (type, row) => {
    setSelectedTeam(row);

    if (type === "view") {
      setIsViewTeamModalActive(true);
    }
    if (type === "edit") {
      setIsCreateTeamFormActive(true);
    }
    if (type === "delete") {
      setIsDeleteModalActive(true);
    }
  };

  const columns = [
    {
      id: "name",
      label: "Name",
      render: (team) => (
        <span
          className="primary-link blue"
          onClick={() => {
            setSelectedTeam(team);
            setIsViewTeamModalActive(true);
          }}
        >
          {team?.name}
        </span>
      ),
    },
    {
      id: "users",
      label: "Users",
      render: ({ Users }) => <span>{Users?.length}</span>,
    },
    {
      id: "channels",
      label: "Channels",
      render: ({ Channels }) => <span>{Channels?.length}</span>,
    },
    {
      id: "updatedAt",
      label: "Last Updated",
      render: ({ updatedAt }) => (
        <span>{moment(updatedAt).format("DD/MM/YYYY")}</span>
      ),
    },
    {
      id: "",
      label: "Actions",
      thClassName: "text-center",
      render: (row) => (
        <div className="text-center">
          <OptionsDropdown
            onClick={(val) => {
              optionsAction(val, row);
            }}
            options={moreOptions()}
          />
        </div>
      ),
    },
  ];

  const channelOptions = firmChannelsData
    ?.filter((x) => x.status === "ACTIVE")
    ?.map((x) => ({
      label: `(${x?.medium}) - ${x?.name}`,
      value: x?.id,
    }));

  const usersOptions = agentsData?.map((x) => ({
    label: `${x?.firstName} ${x?.lastName}`,
    value: x?.id,
  }));

  return (
    <div className="settings-page-container teams-page-container">
      <div className="page-header">
        <span className="title">Teams</span>
      </div>

      {teamsInfo && (
        <div className="settings-page-info">
          <div>
            <span className="title">What is Teams?</span>
            <span className="description">
              Teams is a group of users that have access to pre-set channels.
            </span>
            <Button
              className="plain-button"
              onClick={() => setTeamsInfo(false)}
            >
              Got it
            </Button>
          </div>
          <div className="image">
            <img
              src={require("../../../assets/images/documents.png").default}
              alt=""
            />
          </div>
        </div>
      )}

      <div className="table-container saved-responses-table">
        {!isLoadingTeam && !teamsData?.length ? (
          <SettingsEmptyState
            title="You have no Teams yet"
            description="When you create a Team, it'll show up here."
            action={
              <>
                {(agentsPermissions?.create ||
                  agentsPermissions?.modifyAll) && (
                  <Button
                    className="primary-button"
                    onClick={() => {
                      setSelectedTeam(null);
                      setIsCreateTeamFormActive(true);
                    }}
                  >
                    <span className="icon">
                      <CircularPlus />
                    </span>
                    <span>New Team</span>
                  </Button>
                )}
              </>
            }
          />
        ) : (
          <>
            <div className="table-filter">
              <div className="table-search-filter"></div>
              <div className="table-other-filters">
                {(agentsPermissions?.create ||
                  agentsPermissions?.modifyAll) && (
                  <Button
                    className="primary-button small"
                    onClick={() => {
                      setSelectedTeam(null);
                      setIsCreateTeamFormActive(true);
                    }}
                  >
                    <span className="icon">
                      <CircularPlus />
                    </span>
                    <span>New Team</span>
                  </Button>
                )}
              </div>
            </div>

            <Table loading={isLoadingTeam} columns={columns} rows={teamsData} />
          </>
        )}
      </div>

      {isCreateTeamFormActive && (
        <CreateTeamForm
          toggleForm={() => setIsCreateTeamFormActive(false)}
          teamData={selectedTeam}
          usersOptions={usersOptions}
          channelOptions={channelOptions}
        />
      )}

      {isViewTeamModalActive && (
        <ViewTeamModal
          toggleForm={() => setIsViewTeamModalActive(false)}
          teamData={selectedTeam}
        />
      )}

      {isAddUsersFormActive && (
        <AddUsersForm
          toggleForm={() => setIsAddUsersFormActive(false)}
          teamData={selectedTeam}
          usersOptions={usersOptions}
        />
      )}

      {isAddChannelsFormActive && (
        <AddChannelsForm
          toggleForm={() => setIsAddChannelsFormActive(false)}
          teamData={selectedTeam}
          channelOptions={channelOptions}
        />
      )}

      {isDeleteModalActive && (
        <DeleteTeamModal
          toggleForm={() => setIsDeleteModalActive(false)}
          teamData={selectedTeam}
        />
      )}
    </div>
  );
};

export default Teams;
