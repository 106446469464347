import React from "react";

export default () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <g
        stroke="#757F8A"
        strokeLinecap="round"
        strokeLinejoin="round"
        clipPath="url(#imagesClip0)"
      >
        <path d="M14.5.497h-11a1 1 0 00-1 1v11a1 1 0 001 1h11a1 1 0 001-1v-11a1 1 0 00-1-1z"></path>
        <path d="M13.5 15.497h-12a1 1 0 01-1-1v-12M5.75 4.497a.25.25 0 100 0"></path>
        <path d="M13.5 10.497l-2.58-3.89a.5.5 0 00-.825-.007l-1.33 1.9-.825-.66a.5.5 0 00-.728.114l-1.697 2.543H13.5z"></path>
      </g>
      <defs>
        <clipPath id="imagesClip0">
          <path fill="#fff" d="M0 0H16V16H0z"></path>
        </clipPath>
      </defs>
    </svg>
)