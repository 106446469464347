import React from "react";
import { withRouter, useParams } from "react-router-dom";

import { AngleLeft } from "../../../assets/vectors";
import Dialog360Config from "./SetupForms/Dialog360Config";

const ChannelSetupForm = ({ history }) => {
  let { id } = useParams();

  const renderInstance = () => {
    const goBack = () => history.push("/settings/channels");

    switch (id) {
      case "1":
        return <Dialog360Config goBack={goBack} />;

      default:
        return <Dialog360Config goBack={goBack} />;
    }
  };

  return (
    <div className="settings-page-container integration-details-page">
      <div
        className="back channels-back"
        onClick={() => history.push("/settings/channels")}
      >
        <span className="icon">
          <AngleLeft />
        </span>
        <span className="text">Back to Channels</span>
      </div>

      {renderInstance()}
    </div>
  );
};

export default withRouter(ChannelSetupForm);
