import React, { useState, useCallback } from "react";
import { ConnectButton } from "360dialog-connect-button";
import { useDispatch } from "react-redux";

import { postSubscribeWhatsappChannel } from "../../../../redux/actions/Channels";

import {
  Redirect,
  LogoSolid,
  WhatsApp,
  Spinner,
} from "../../../../assets/vectors";
import { Button } from "../../../../components/FormElements";

import { getWindowBaseUrl } from "../../../../utils/functions";

const Dialog360Config = ({ goBack }) => {
  const dispatch = useDispatch();

  const [currentStep, setCurrentStep] = useState(1);
  const [isConfiguring, setIsConfiguring] = useState(false);

  const verify360dialogAuth = useCallback((channels) => {
    const isArrayNotEmpty = Array.isArray(channels) && channels.length;

    if (isArrayNotEmpty) {
      dispatch(
        postSubscribeWhatsappChannel({
          url: "/channels/360dialog/onboard",
          payload: {
            channels,
          },
        })
      ).then((res) => {
        if (res?.data?.success === true) {
          setCurrentStep(2);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const partnerId = "y8a0kLPA";

  const windowBaseUrl = getWindowBaseUrl() || "";
  const redirect_url = windowBaseUrl;

  const handleCallback = (callbackObject) => {
    setIsConfiguring(true);
    verify360dialogAuth(callbackObject.channels);

    if (callbackObject.revokedChannels) {
      console.log("revoked channel IDs: " + callbackObject.revokedChannels);
    }
  };

  return (
    <>
      {currentStep === 1 && (
        <div className="channel-modal">
          <div className="modal-content new-conversation-container">
            <div className="modal-title">
              You are about to be redirected to 360Dialog
            </div>
            <div className="modal-form new-conversation-form">
              <h6>
                Clicking continue will redirect you to 360Dialog. From there,
                you would be able to create and/or connect your 360Dialog
                account.
              </h6>
              <div className="logos">
                <LogoSolid />
                <Redirect />
                <WhatsApp />
              </div>
              <div className="actions">
                {!isConfiguring ? (
                  <>
                    <Button className="secondary-button" onClick={goBack}>
                      Cancel
                    </Button>
                    <ConnectButton
                      className="primary-button"
                      partnerId={partnerId}
                      callback={handleCallback}
                      queryParameters={{ redirect_url }}
                    />
                  </>
                ) : (
                  <div className="spinner">
                    <Spinner />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {currentStep === 2 && (
        <div className="channel-modal">
          <div className="modal-content new-conversation-container">
            <div className="modal-title">Channel setup complete</div>
            <div className="modal-form new-conversation-form">
              <div className="d-flex">
                <div className="info">
                  <ul>
                    <li>You can now send and receive messages via WhatsApp.</li>
                  </ul>
                </div>
              </div>
              <div className="actions">
                <Button className="primary-button" onClick={goBack}>
                  Dismiss
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Dialog360Config;
