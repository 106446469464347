import mixpanel from "mixpanel-browser";
import {
  getRequest,
  postRequest,
  patchRequest,
  deleteRequest,
} from "../../api";

import {
  CREATE_CHANNELS_LOADER,
  CREATE_CHANNELS_RESPONSE,
  CREATE_CHANNELS_RESET,
  UPDATE_CHANNELS_LOADER,
  UPDATE_CHANNELS_RESPONSE,
  UPDATE_CHANNELS_RESET,
  GET_CHANNELS_LOADER,
  GET_ALL_CHANNELS_RESPONSE,
  GET_FIRM_CHANNELS_RESPONSE,
  GET_CHANNELS_RESET,
  DELETE_CHANNELS_LOADER,
  DELETE_CHANNELS_RESPONSE,
  DELETE_CHANNELS_RESET,
} from "../constants";

import { getRequestError } from "../../utils/functions";

export const createChannelsLoader = (isCreatingChannel) => ({
  type: CREATE_CHANNELS_LOADER,
  isCreatingChannel,
});

export const createChannelsResponse = (
  createChannelsOutcome,
  createChannelsMessage
) => ({
  type: CREATE_CHANNELS_RESPONSE,
  createChannelsOutcome,
  createChannelsMessage,
});

export const createChannelsReset = () => ({
  type: CREATE_CHANNELS_RESET,
});

export const updateChannelsLoader = (isUpdatingChannel) => ({
  type: UPDATE_CHANNELS_LOADER,
  isUpdatingChannel,
});

export const updateChannelsResponse = (
  updateChannelsOutcome,
  updateChannelsMessage
) => ({
  type: UPDATE_CHANNELS_RESPONSE,
  updateChannelsOutcome,
  updateChannelsMessage,
});

export const updateChannelsReset = () => ({
  type: UPDATE_CHANNELS_RESET,
});

export const getChannelsLoader = (isLoadingChannels) => ({
  type: GET_CHANNELS_LOADER,
  isLoadingChannels,
});

export const getAllChannelsResponse = (
  channelsOutcome,
  allChannelsData,
  channelsMeta,
  channelsMessage
) => ({
  type: GET_ALL_CHANNELS_RESPONSE,
  channelsOutcome,
  allChannelsData,
  channelsMeta,
  channelsMessage,
});

export const getFirmChannelsResponse = (
  channelsOutcome,
  firmChannelsData,
  channelsMeta,
  channelsMessage
) => ({
  type: GET_FIRM_CHANNELS_RESPONSE,
  channelsOutcome,
  firmChannelsData,
  channelsMeta,
  channelsMessage,
});

export const getChannelsReset = () => ({
  type: GET_CHANNELS_RESET,
});

export const deleteChannelsLoader = (isDeletingChannel) => ({
  type: DELETE_CHANNELS_LOADER,
  isDeletingChannel,
});

export const deleteChannelsResponse = (
  deleteChannelsOutcome,
  deleteChannelsMessage
) => ({
  type: DELETE_CHANNELS_RESPONSE,
  deleteChannelsOutcome,
  deleteChannelsMessage,
});

export const deleteChannelsReset = () => ({
  type: DELETE_CHANNELS_RESET,
});

export const getTwitterRequestTokenAction = (data) => {
  return async (dispatch) => {
    dispatch(createChannelsReset());
    dispatch(createChannelsLoader(true));

    try {
      const res = await getRequest({
        url: data?.url,
        token: true,
        params: data?.params,
      });
      return res;
    } catch (error) {
      const message = getRequestError(error);

      dispatch(createChannelsResponse("error", message));
      dispatch(createChannelsLoader(false));
    }
  };
};

export const postSubscribeTwitterUserAction = (data) => {
  return async (dispatch) => {
    dispatch(createChannelsReset());
    dispatch(createChannelsLoader(true));

    try {
      const res = await postRequest({
        url: data?.url,
        data: data?.requestData,
        token: true,
      });

      mixpanel.track("Connect Channel", {
        channel: "TWITTER",
      });

      dispatch(createChannelsResponse("success", ""));
      dispatch(createChannelsLoader(false));
      return res;
    } catch (error) {
      const message = getRequestError(error);

      dispatch(createChannelsResponse("error", message));
      dispatch(createChannelsLoader(false));
    }
  };
};

export const getEmailRequestTokenAction = (data) => {
  return async (dispatch) => {
    dispatch(createChannelsReset());
    dispatch(createChannelsLoader(true));

    try {
      const res = await getRequest({
        url: data?.url,
        token: true,
        params: data?.params,
      });
      return res;
    } catch (error) {
      const message = getRequestError(error);

      dispatch(createChannelsResponse("error", message));
      dispatch(createChannelsLoader(false));
    }
  };
};

export const postSubscribeEmailUserAction = (data) => {
  return async (dispatch) => {
    dispatch(createChannelsReset());
    dispatch(createChannelsLoader(true));

    try {
      const res = await postRequest({
        url: data?.url,
        data: data?.requestData,
        token: true,
      });

      dispatch(createChannelsResponse("success", ""));
      dispatch(createChannelsLoader(false));
      return res;
    } catch (error) {
      const message = getRequestError(error);

      dispatch(createChannelsResponse("error", message));
      dispatch(createChannelsLoader(false));
    }
  };
};

export const postEmailChannelSetup = (data) => {
  return async (dispatch) => {
    dispatch(createChannelsReset());
    dispatch(createChannelsLoader(true));

    try {
      const res = await postRequest({
        url: data?.url,
        data: data?.requestData,
        token: true,
      });

      dispatch(createChannelsLoader(false));
      mixpanel.track("Connect Channel", {
        channel: "EMAIL",
      });

      return res;
    } catch (error) {
      const message = getRequestError(error);

      dispatch(createChannelsResponse("error", message));
      dispatch(createChannelsLoader(false));
    }
  };
};

export const getEmailChannelSetup = (data) => {
  return async (dispatch) => {
    dispatch(createChannelsLoader(true));

    try {
      const res = await getRequest({
        url: data?.url,
        token: true,
      });
      dispatch(createChannelsLoader(false));

      return res;
    } catch (error) {
      const message = getRequestError(error);

      dispatch(createChannelsLoader(false));
      dispatch(createChannelsResponse("error", message));
    }
  };
};

export const postSubscribeWhatsappChannel = (data) => {
  return async (dispatch) => {
    dispatch(createChannelsReset());
    dispatch(createChannelsLoader(true));

    try {
      const res = await postRequest({
        url: data?.url,
        data: data?.payload,
        token: true,
      });

      dispatch(createChannelsResponse("success", ""));
      dispatch(createChannelsLoader(false));
      mixpanel.track("Connect Channel", {
        channel: "WHATSAPP",
      });
      return res;
    } catch (error) {
      const message = getRequestError(error);

      dispatch(createChannelsResponse("error", message));
      dispatch(createChannelsLoader(false));
    }
  };
};

export const getMessengerRequestAction = (data) => {
  return async (dispatch) => {
    dispatch(createChannelsReset());
    dispatch(createChannelsLoader(true));

    try {
      const res = await getRequest({
        url: data?.url,
        token: true,
        params: data?.params,
      });
      if (data?.stop_loader) dispatch(createChannelsLoader(false));
      return res;
    } catch (error) {
      const message = getRequestError(error);

      dispatch(createChannelsResponse("error", message));
      dispatch(createChannelsLoader(false));
    }
  };
};

export const postSubscribeToMessengerPageAction = (data) => {
  return async (dispatch) => {
    dispatch(createChannelsReset());
    dispatch(createChannelsLoader(true));

    try {
      const res = await postRequest({
        url: data?.url,
        data: data?.requestData,
        token: true,
      });

      dispatch(createChannelsResponse("success", ""));
      dispatch(createChannelsLoader(false));
      mixpanel.track("Connect Channel", {
        channel: data?.name,
      });
      return res;
    } catch (error) {
      const message = getRequestError(error);

      dispatch(createChannelsResponse("error", message));
      dispatch(createChannelsLoader(false));
      return message;
    }
  };
};

export const postSubscribeLiveChatAction = (data) => {
  return async (dispatch) => {
    dispatch(createChannelsReset());
    dispatch(createChannelsLoader(true));

    try {
      const res = await postRequest({
        url: data?.url,
        data: data?.requestData,
        token: true,
      });

      dispatch(createChannelsResponse("success", ""));
      dispatch(createChannelsLoader(false));
      mixpanel.track("Connect Channel", {
        channel: data?.name,
      });
      return res;
    } catch (error) {
      const message = getRequestError(error);

      dispatch(createChannelsResponse("error", message));
      dispatch(createChannelsLoader(false));
    }
  };
};

export const patchUpdateChannelWebhookUrlAction = (data) => {
  return async (dispatch) => {
    dispatch(createChannelsReset());
    dispatch(createChannelsLoader(true));

    try {
      const res = await patchRequest({
        url: data?.url,
        data: data?.requestData,
        token: true,
      });

      dispatch(createChannelsResponse("success", ""));
      dispatch(createChannelsLoader(false));
      mixpanel.track("Update Channel WebhookUrl", {
        channel: data?.name,
      });
      return res;
    } catch (error) {
      const message = getRequestError(error);

      dispatch(createChannelsResponse("error", message));
      dispatch(createChannelsLoader(false));
    }
  };
};

export const getAllChannelsAction = (params = {}) => {
  return async (dispatch) => {
    dispatch(getChannelsReset());
    dispatch(getChannelsLoader(true));

    try {
      const res = await getRequest({
        url: "/channels/all",
        params,
        token: true,
      });

      const { data, offset, limit, total } = res.data;

      dispatch(
        getAllChannelsResponse("success", data, { offset, limit, total }, "")
      );
      dispatch(getChannelsLoader(false));
    } catch (error) {
      const message = getRequestError(error);

      dispatch(getAllChannelsResponse("error", [], {}, message));
      dispatch(getChannelsLoader(false));
    }
  };
};

export const getFirmChannelsAction = (params = {}) => {
  return async (dispatch) => {
    dispatch(getChannelsReset());
    dispatch(getChannelsLoader(true));

    try {
      const res = await getRequest({
        url: "/channels",
        params,
        token: true,
      });

      const { data, offset, limit, total } = res.data;

      dispatch(
        getFirmChannelsResponse("success", data, { offset, limit, total }, "")
      );
      dispatch(getChannelsLoader(false));
    } catch (error) {
      const message = getRequestError(error);

      dispatch(getFirmChannelsResponse("error", [], {}, message));
      dispatch(getChannelsLoader(false));
    }
  };
};

export const createChannelsAction = (data) => {
  return async (dispatch) => {
    dispatch(createChannelsReset());
    dispatch(createChannelsLoader(true));

    try {
      await postRequest({
        url: data?.url,
        data,
        token: true,
      });

      dispatch(createChannelsResponse("success", ""));
      dispatch(createChannelsLoader(false));
    } catch (error) {
      const message = getRequestError(error);

      dispatch(createChannelsResponse("error", message));
      dispatch(createChannelsLoader(false));
    }
  };
};

export const updateChannelsAction = (data, id) => {
  return async (dispatch) => {
    dispatch(updateChannelsReset());
    dispatch(updateChannelsLoader(true));

    try {
      await patchRequest({
        url: `/channels/${id}`,
        data,
        token: true,
      });

      dispatch(updateChannelsResponse("success", ""));
      dispatch(updateChannelsLoader(false));
    } catch (error) {
      const message = getRequestError(error);

      dispatch(updateChannelsResponse("error", message));
      dispatch(updateChannelsLoader(false));
    }
  };
};

export const deleteChannelsAction = (id) => {
  return async (dispatch) => {
    dispatch(deleteChannelsReset());
    dispatch(deleteChannelsLoader(true));

    try {
      await deleteRequest({
        url: `/channels/${id}`,
        token: true,
      });

      dispatch(deleteChannelsResponse("success", ""));
      dispatch(deleteChannelsLoader(false));
      dispatch(getAllChannelsAction());
      dispatch(getFirmChannelsAction());
    } catch (error) {
      const message = getRequestError(error);

      dispatch(deleteChannelsResponse("error", message));
      dispatch(deleteChannelsLoader(false));
    }
  };
};
