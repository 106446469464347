import React from "react";

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      stroke="#474F58"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.25"
      d="M16.875.625H3.125a2.5 2.5 0 00-2.5 2.5v10a2.5 2.5 0 002.5 2.5h1.25v3.125a.625.625 0 001.067.442l3.308-3.567h2.5l3.308 3.566a.625.625 0 001.067-.443v-3.123h1.25a2.5 2.5 0 002.5-2.5v-10a2.5 2.5 0 00-2.5-2.5v0z"
    ></path>
    <path
      stroke="#474F58"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.25"
      d="M8.594 9.063H6.25v2.344M13.386 9.52a3.483 3.483 0 01-6.712-.457M11.667 6.562h2.344V4.218M6.875 6.104a3.482 3.482 0 016.713.458"
    ></path>
  </svg>
);
