import React from "react";

export default () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke="#474F58"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
        d="M14.34 1.66a2.263 2.263 0 00-3.216.021l-9.022 9.023L1 15l4.296-1.102 9.023-9.022a2.264 2.264 0 00.021-3.216v0zM10.865 1.94l3.195 3.194M9.563 3.243l3.194 3.195M2.104 10.704L5.3 13.896"
      ></path>
    </svg>
)