import React from "react";

export default () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10" cy="10" r="10" fill="#35C272" />
    <path
      d="M14.7314 6.62053C14.6312 6.53019 14.5142 6.4604 14.387 6.41513C14.2598 6.36986 14.125 6.35001 13.9901 6.35671C13.8553 6.36341 13.7231 6.39652 13.6012 6.45416C13.4792 6.5118 13.3698 6.59284 13.2793 6.69264L8.70813 11.7264L6.38261 9.6759C6.19611 9.51125 5.95171 9.42714 5.70315 9.44207C5.45459 9.457 5.22224 9.56976 5.05722 9.75553C4.89219 9.9413 4.80801 10.1849 4.82319 10.4327C4.83837 10.6804 4.95167 10.9122 5.13816 11.0768L8.25541 13.8253C8.35142 13.9099 8.4639 13.9738 8.58581 14.0131C8.70772 14.0525 8.83643 14.0663 8.9639 14.0537C9.09899 14.0473 9.23145 14.0143 9.35369 13.9568C9.47594 13.8992 9.58556 13.8181 9.67627 13.7182L14.805 8.06886C14.9878 7.8671 15.0828 7.60116 15.0689 7.32949C15.0551 7.05782 14.9337 6.80265 14.7313 6.62004"
      fill="white"
    />
  </svg>
);
