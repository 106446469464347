import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Toaster from "../../../components/Toaster";

import {
  getAutoResponsesAction,
  deleteAutoResponseAction,
} from "../../../redux/actions/AutoResponses";
import { getFirmChannelsAction } from "../../../redux/actions/Channels";

import { Button } from "../../../components/FormElements";
import Table from "../../../components/Table";
import Modal from "../../../components/Modal";
import OptionsDropdown from "../../../components/OptionsDropdown";

import { CircularPlus, Pencil, Trash } from "../../../assets/vectors";
import { autoRespondersPermissions } from "../../../utils/permissions";

import AutoResponseForm from "./AutoResponseForm";
import SettingsEmptyState from "../../../components/SettingsEmptyState";

const AutoResponses = () => {
  const dispatch = useDispatch();
  const {
    autoResponsesData,
    isLoadingAutoResponses,
    isDeletingAutoResponse,
    createAutoResponseMessage,
    updateAutoResponseMessage,
    deleteAutoResponseMessage,
  } = useSelector((state) => state.autoResponses);
  const { firmChannelsData } = useSelector((state) => state.channels);

  const [selectedAutoResponse, setSelectedAutoResponse] = useState(null);
  const [isAutoResponseFormActive, setIsAutoResponseFormActive] =
    useState(false);
  const [isConfirmationModalActive, setIsConfirmationModalActive] =
    useState(false);
  const [autoResponsesInfo, setAutoResponsesInfo] = useState(true);
  const [toaster, setToaster] = useState({});

  useEffect(() => {
    dispatch(getAutoResponsesAction());
    dispatch(getFirmChannelsAction());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (createAutoResponseMessage)
      openToaster("success", createAutoResponseMessage);
    if (updateAutoResponseMessage)
      openToaster("success", updateAutoResponseMessage);
    if (deleteAutoResponseMessage)
      openToaster("success", deleteAutoResponseMessage);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    createAutoResponseMessage,
    updateAutoResponseMessage,
    deleteAutoResponseMessage,
  ]);

  const openToaster = (status, content) => {
    setToaster({
      open: "open",
      status,
      content,
    });
  };

  const removeInfo = () => {
    setAutoResponsesInfo(false);
  };

  const toggleAutoResponseForm = () => {
    setSelectedAutoResponse(null);
    setIsAutoResponseFormActive(!isAutoResponseFormActive);
  };

  const toggleConfirmationModal = () => {
    setSelectedAutoResponse(null);
    setIsConfirmationModalActive(!isConfirmationModalActive);
  };

  const optionsAction = (type, row) => {
    setSelectedAutoResponse(row);

    if (type === "edit") {
      setIsAutoResponseFormActive(true);
    } else if (type === "delete") {
      setIsConfirmationModalActive(true);
    }
  };

  const deleteAutoResponse = () => {
    dispatch(deleteAutoResponseAction(selectedAutoResponse?.id)).then((res) => {
      if (res === 200) {
        toggleConfirmationModal();
        dispatch(getAutoResponsesAction());
      }
    });
  };

  const moreOptions = () => {
    var options = [];

    if (
      autoRespondersPermissions?.update ||
      autoRespondersPermissions?.modifyAll
    ) {
      options = [
        ...options,
        {
          label: (
            <div>
              <span className="icon">
                <Pencil />
              </span>{" "}
              <span className="text">Edit</span>
            </div>
          ),
          value: "edit",
        },
      ];
    }

    if (autoRespondersPermissions?.delete) {
      options = [
        ...options,
        {
          label: (
            <div className="red">
              <span className="icon">
                <Trash />
              </span>{" "}
              <span className="text">Delete</span>
            </div>
          ),
          value: "delete",
        },
      ];
    }

    return options;
  };

  const columns = [
    {
      id: "message",
      label: "Message",
      render: ({ message }) => (
        <div className="saved-response-message">
          <span dangerouslySetInnerHTML={{ __html: message || "" }}></span>
        </div>
      ),
    },
    {
      id: "channels",
      label: "Channels",
      render: ({ Channels }) => <span>{Channels?.length}</span>,
    },
    {
      id: "status",
      label: "Status",
      render: ({ active }) => <span>{active ? "Active" : ""}</span>,
    },
    {
      id: "",
      label: "Actions",
      thClassName: "text-center",
      render: (row) => (
        <div className="text-center">
          {row?.message && (
            <OptionsDropdown
              onClick={(val) => {
                optionsAction(val, row);
              }}
              options={moreOptions()}
            />
          )}
        </div>
      ),
    },
  ];

  return (
    <div className="settings-page-container">
      <div className="page-header">
        <span className="title">Auto Responses</span>
      </div>

      {autoResponsesInfo && (
        <div className="settings-page-info">
          <div>
            <span className="title">What are Auto Responses?</span>
            <span className="description">
              Autoresponders are messages automagically sent to your customers
              when they contact you.
            </span>
            <Button className="plain-button" onClick={removeInfo}>
              Got it
            </Button>
          </div>
          <div className="image">
            <img
              src={require("../../../assets/images/documents.png").default}
              alt=""
            />
          </div>
        </div>
      )}

      <div className="table-container saved-responses-table">
        {!isLoadingAutoResponses && autoResponsesData?.length < 1 ? (
          <SettingsEmptyState
            title="You have no auto responses yet"
            description="When you create a auto response, it'll show up here."
            action={
              <>
                {(autoRespondersPermissions.create ||
                  autoRespondersPermissions.modifyAll) && (
                  <Button
                    className="primary-button"
                    onClick={toggleAutoResponseForm}
                  >
                    <span className="icon">
                      <CircularPlus />
                    </span>
                    <span>New auto response</span>
                  </Button>
                )}
              </>
            }
          />
        ) : (
          <>
            <div className="table-filter">
              <div className="table-search-filter"></div>
              <div className="table-other-filters">
                {(autoRespondersPermissions.create ||
                  autoRespondersPermissions.modifyAll) && (
                  <Button
                    className="primary-button small"
                    onClick={toggleAutoResponseForm}
                  >
                    <span className="icon">
                      <CircularPlus />
                    </span>
                    <span>New auto response</span>
                  </Button>
                )}
              </div>
            </div>

            <Table
              loading={isLoadingAutoResponses}
              columns={columns}
              rows={autoResponsesData}
            />
          </>
        )}
      </div>

      {isAutoResponseFormActive && (
        <AutoResponseForm
          firmChannelsData={firmChannelsData}
          autoResponseData={selectedAutoResponse}
          toggleAutoResponseForm={toggleAutoResponseForm}
          getAutoResponsesAction={() => dispatch(getAutoResponsesAction())}
        />
      )}

      {isConfirmationModalActive && (
        <Modal closeModal={toggleConfirmationModal} small>
          <div className="modal-content confirmation-modal">
            <div className="modal-icon red">
              <Trash />
            </div>
            <div className="modal-title">
              Are you sure you want to delete this auto response?
            </div>
            <div className="modal-text">This action cannot be reversed</div>
            <div className="modal-action">
              <Button
                className="secondary-button red"
                type="button"
                onClick={deleteAutoResponse}
                disabled={isDeletingAutoResponse}
              >
                {isDeletingAutoResponse
                  ? "Processing..."
                  : "Delete Auto Response"}
              </Button>
              <Button
                className="plain-button black"
                type="button"
                onClick={toggleConfirmationModal}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      )}

      <Toaster
        {...toaster}
        closeToaster={() =>
          setToaster({
            ...toaster,
            open: "closed",
          })
        }
      />
    </div>
  );
};

export default AutoResponses;
