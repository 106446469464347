import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactSelect from "react-select";
// import moment from "moment";

// import { Button, TextArea } from "../../../components/FormElements";

import { getCustomerAction } from "../../../redux/actions/Customers";

// import { getChannelIdentifierIcon } from "../../../utils/functions";
import {
  ChevronDownW,
  ConvoHistory,
  Edit,
  CircularCheck,
  Close,
  // CallHistory,
  // Send,
} from "../../../assets/vectors";
import { updateCustomersAction } from "../../../redux/actions/Customers";
import {
  addToGroupAction,
  removefromGroupAction,
} from "../../../redux/actions/Groups";
import { customersPermissions } from "../../../utils/permissions";
import { getInitials } from "../../../utils/functions";

const CustomerInfo = ({ selectedConversation, openConvoHistory }) => {
  const dispatch = useDispatch();

  const { groupsData } = useSelector((state) => state.groups);

  const [customer, setCustomer] = useState(selectedConversation.Customer);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [newGroup, setNewGroup] = useState(null);
  // const [note, setNote] = useState("");
  const [allowNameUpdate, setAllowNameUpdate] = useState(false);
  const [newName, setNewName] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [isUpdatingName, setIsUpdatingName] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    getCustomerInfo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCustomerInfo = () => {
    setIsFetching(true);

    dispatch(getCustomerAction({ id: customer?.id })).then((res) => {
      setCustomer(res);
      setSelectedGroups(
        res?.Groups?.map((x) => ({
          label: x?.name,
          value: x?.id,
        }))
      );
      setIsFetching(false);
    });
  };

  const updateCustomerGroups = (newGroup) => {
    setIsSubmitting(true);
    setNewGroup(newGroup);

    const updatedGroups = [...selectedGroups, newGroup];

    dispatch(
      addToGroupAction(
        {
          groups: updatedGroups?.map((x) => x?.value),
          customers: [customer?.id],
        },
        () => {
          setSelectedGroups(updatedGroups);
          setNewGroup(null);
          setIsSubmitting(false);
        }
      )
    );
  };

  const removeCustomerFromGroup = (groupId) => {
    setIsSubmitting(true);

    const updatedGroups =
      selectedGroups?.filter((x) => x?.value !== groupId) || [];

    dispatch(
      removefromGroupAction(
        {
          id: customer?.id,
          data: {
            groups: [groupId],
          },
        },
        () => {
          setSelectedGroups(updatedGroups);
          setIsSubmitting(false);
        }
      )
    );
  };

  const updateCustomerName = () => {
    if (newName?.length > 2) {
      setAllowNameUpdate(false);
      setIsUpdatingName(true);

      const payload = { name: newName };

      dispatch(updateCustomersAction(payload, customer.id)).then(() => {
        setIsUpdatingName(false);
        getCustomerInfo();
      });
    }
  };

  const getChannels = () => {
    const all = [];

    if (customer?.phone) all.push("Phone");
    if (customer?.whatsappPhoneNumber) all.push("WhatsApp");
    if (customer?.email) all.push("Email");
    if (customer?.facebookMessengerId) all.push("Facebook");
    if (customer?.instagramUserId) all.push("Instagram");
    if (customer?.twitterUsername) all.push("Twitter");

    return all;
  };

  const infos = [
    { label: "Phone number", value: customer?.phone || "- - -" },
    { label: "Email address", value: customer?.email || "- - -" },
    {
      label: "Channels",
      value:
        (
          <>
            {getChannels()?.map((item, i) => (
              <React.Fragment key={i}>{item}, </React.Fragment>
            ))}
          </>
        ) || "- - -",
    },
  ];

  const groupOptions = groupsData?.map((x) => ({
    label: x?.name,
    value: x?.id,
  }));

  const actions = [
    {
      name: "Conversation history",
      icon: <ConvoHistory />,
      onClick: openConvoHistory,
    },
    // { name: "Call history", icon: <CallHistory />, onClick: () => {} },
  ];

  // const notesHistory = [
  //   {
  //     name: "Tolulope Adesina",
  //     content: "Transaction error. To be rectified between 2-3 working days.",
  //     createdAt: new Date(),
  //   },
  //   {
  //     name: "Black Panther",
  //     content: "Hello @Tolulope, any head way on rectifying this?",
  //     createdAt: new Date(),
  //   },
  // ];

  return (
    <div className="customer-info-container">
      <div className="title_header">Contact details</div>

      <div className="content">
        <a
          href={`/customers/${customer?.id}`}
          className="initials_and_name"
          target="_blank"
          rel="noreferrer"
        >
          <p className="initials">
            {getInitials(customer?.name || customer?.phone)}
          </p>
        </a>

        {!allowNameUpdate ? (
          <div className="customer_name">
            <p className="name">{customer?.name || customer?.phone}</p>

            <div className="icon" onClick={() => setAllowNameUpdate(true)}>
              <Edit />
            </div>
          </div>
        ) : (
          <div className="form-group">
            <input
              type="text"
              placeholder="Customer name"
              onChange={(e) => setNewName(e.target.value)}
              disabled={isUpdatingName}
            />

            <div className="icon send" onClick={updateCustomerName}>
              {!isUpdatingName && <CircularCheck />}
            </div>

            <div
              className="icon cancel"
              onClick={() => setAllowNameUpdate(false)}
            >
              {!isUpdatingName && <Close />}
            </div>
          </div>
        )}
        {isUpdatingName && (
          <div className="please_wait">Updating... Please wait</div>
        )}

        {/* <div className="icon_actions">
          <div className="icon">
            <img src={getChannelIdentifierIcon("PHONE")} alt="PHONE" />
          </div>
        </div> */}

        <div className="infos">
          {infos?.map((item, i) => (
            <div key={i} className="item">
              <span className="label">{item?.label}</span>
              <span className="value">{item?.value}</span>
            </div>
          ))}
        </div>

        <div className="groups">
          <label>Groups</label>
          <ReactSelect
            disabled={isSubmitting}
            placeholder="---Select---"
            value={newGroup}
            options={groupOptions}
            onChange={(selection) => updateCustomerGroups(selection)}
            classNamePrefix="select_options"
          />

          {isFetching ? (
            <div className="please_wait">Loading... Please wait</div>
          ) : isSubmitting ? (
            <div className="please_wait">Updating... Please wait</div>
          ) : (
            <div className="selected_groups">
              {selectedGroups?.map((item, i) => (
                <div key={i} className="item">
                  {item?.label}

                  {(customersPermissions.update ||
                    customersPermissions.modifyAll) && (
                    <span onClick={() => removeCustomerFromGroup(item?.value)}>
                      x
                    </span>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="actions">
          {actions?.map((item, i) => (
            <div key={i} className="item" onClick={item?.onClick}>
              <div className="left">
                {item?.icon}
                <span>{item?.name}</span>
              </div>

              <div className="chevron">
                <ChevronDownW />
              </div>
            </div>
          ))}
        </div>

        {/* <div className="notes">
          <label>Notes</label>
          <div className="textarea_container">
            <TextArea
              name="note"
              value={note}
              onChange={(e) => setNote(e?.target?.value)}
              noAction
            />

            <Button
              className="primary-button"
              onClick={() => {}}
              disabled={note?.length > 2 ? false : true}
            >
              Save <Send />
            </Button>
          </div>

          <div className="notes_history">
            {notesHistory?.map((item, i) => (
              <div key={i} className="item">
                <div className="initials">{getInitials(item?.name, 2)}</div>

                <div className="info">
                  <h6>
                    {item?.name}{" "}
                    <span>{moment(item?.createdAt).format("DD MMM")}</span>
                  </h6>
                  <p>{item?.content}</p>
                </div>
              </div>
            ))}
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default CustomerInfo;
