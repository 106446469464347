import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Spinner } from "../../../assets/vectors";
import { Button, TextField } from "../../../components/FormElements";
import Modal from "../../../components/Modal";

import {
  patchUpdateChannelWebhookUrlAction,
  getAllChannelsAction,
  getFirmChannelsAction,
} from "../../../redux/actions/Channels";

const WebhookUrlForm = ({ history, close, selectedChannel }) => {
  const dispatch = useDispatch();
  const { isCreatingChannel } = useSelector((state) => state?.channels);

  const [currentStep, setCurrentStep] = useState(1);
  const [url, setUrl] = useState(
    selectedChannel?.webhookUrl || selectedChannel?.credentials?.webhookUrl
  );

  const updateWebhookUrl = () => {
    dispatch(
      patchUpdateChannelWebhookUrlAction({
        url: `/channels/${selectedChannel?.id}/webhook`,
        requestData: {
          url,
        },
      })
    ).then((res) => {
      if (res?.status === 200) {
        dispatch(getAllChannelsAction());
        dispatch(getFirmChannelsAction());
        setCurrentStep(2);
      }
    });
  };

  return (
    <React.Fragment>
      {currentStep === 1 && (
        <Modal className="channel-modal">
          <div className="modal-content live-chat-container">
            <div className="modal-title">Add/Edit Webhook URL</div>
            <div className="modal-form new-conversation-form">
              <h6>
                Enter your Webhook URL below to get real-time notifications on
                this channel.
              </h6>
              <div className="form_field">
                <TextField
                  label="Webhook URL"
                  type="text"
                  name="url"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  disabled={isCreatingChannel}
                />
              </div>
              <div className="actions">
                {!isCreatingChannel ? (
                  <>
                    <Button className="secondary-button" onClick={close}>
                      Cancel
                    </Button>
                    <Button
                      className="primary-button"
                      onClick={updateWebhookUrl}
                      disabled={url?.length < 2}
                    >
                      Save changes
                    </Button>
                  </>
                ) : (
                  <div className="spinner">
                    <Spinner />
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal>
      )}

      {currentStep === 2 && (
        <Modal className="channel-modal">
          <div className="modal-content new-conversation-container">
            <div className="modal-title">Webhook URL updated</div>
            <div className="modal-form new-conversation-form">
              <div className="d-flex">
                <div className="info">
                  <ul>
                    <li>
                      You can now receive real-time notifications on this
                      channel.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="actions">
                <Button
                  className="primary-button"
                  onClick={() => {
                    setCurrentStep(null);
                    close();
                  }}
                >
                  Dismiss
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default withRouter(WebhookUrlForm);
