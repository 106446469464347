import React from "react";

export default () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.24996 0.75H7.74996L8.91663 1.91667H11.25C11.4047 1.91667 11.553 1.97812 11.6624 2.08752C11.7718 2.19692 11.8333 2.34529 11.8333 2.5V10.6667C11.8333 10.8214 11.7718 10.9697 11.6624 11.0791C11.553 11.1885 11.4047 11.25 11.25 11.25H0.749959C0.59525 11.25 0.446877 11.1885 0.33748 11.0791C0.228084 10.9697 0.166626 10.8214 0.166626 10.6667V2.5C0.166626 2.34529 0.228084 2.19692 0.33748 2.08752C0.446877 1.97812 0.59525 1.91667 0.749959 1.91667H3.08329L4.24996 0.75ZM5.99996 10.0833C6.92822 10.0833 7.81846 9.71458 8.47483 9.05821C9.13121 8.40183 9.49996 7.51159 9.49996 6.58333C9.49996 5.65508 9.13121 4.76484 8.47483 4.10846C7.81846 3.45208 6.92822 3.08333 5.99996 3.08333C5.0717 3.08333 4.18146 3.45208 3.52509 4.10846C2.86871 4.76484 2.49996 5.65508 2.49996 6.58333C2.49996 7.51159 2.86871 8.40183 3.52509 9.05821C4.18146 9.71458 5.0717 10.0833 5.99996 10.0833ZM5.99996 8.91667C5.38112 8.91667 4.78763 8.67083 4.35004 8.23325C3.91246 7.79566 3.66663 7.20217 3.66663 6.58333C3.66663 5.96449 3.91246 5.371 4.35004 4.93342C4.78763 4.49583 5.38112 4.25 5.99996 4.25C6.6188 4.25 7.21229 4.49583 7.64988 4.93342C8.08746 5.371 8.33329 5.96449 8.33329 6.58333C8.33329 7.20217 8.08746 7.79566 7.64988 8.23325C7.21229 8.67083 6.6188 8.91667 5.99996 8.91667Z"
      fill="#757F8A"
    />
  </svg>
);
