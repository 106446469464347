import React from "react";

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      stroke="#C6C1D0"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.25"
      d="M.625 19.375H18.75M2.29 15.625h2.293v0c.23 0 .417.187.417.417v3.333H1.874v-3.333 0c0-.23.187-.417.417-.417v0zM8.54 8.125h2.293v0c.23 0 .417.187.417.417v10.833H8.124V8.542v0c0-.23.187-.417.417-.417v0zM14.79 11.875h2.293v0c.23 0 .417.187.417.417v7.083h-3.125v-7.083 0c0-.23.186-.416.416-.417v0zM5.733 4.267L2.392 7.608M11.925 5.267L8.033 4.225M16.991 2.408l-2.983 2.334M6.874 5a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5zM18.125 3.125a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5zM13.125 6.875a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5zM1.874 10a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5z"
    ></path>
  </svg>
);
