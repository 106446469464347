/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import * as moment from "moment";

import {
  getFirmIntegrationsAction,
  getTicketsAction,
  getCustomerGroupsAction,
} from "../../../redux/actions/Integrations";

import { Button } from "../../../components/FormElements";
import { ticketsPermissions } from "../../../utils/permissions";

import TicketDetails from "./TicketDetails";

class Integrations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      integrationsData: [],
      ticketsData: [],
      ticketDetails: null,
    };
  }

  toggleTicketDetails = (ticketDetails) => {
    this.setState({
      ticketDetails,
    });
  };

  componentDidMount() {
    this.props.getFirmIntegrationsAction().then((res) =>
      this.setState({
        integrationsData: res,
      })
    );
  }

  render() {
    const { ticketDetails, integrationsData } = this.state;
    const {
      toggleTicketForm,
      patchTicketFormData,
      selectedConversation,
      isLoadingConversationDetails,
    } = this.props;

    const { FirmTickets, csatScore, customerFeedback } =
      selectedConversation || {};

    return (
      <div className="integrations-container">
        <div className="integration-container-item">
          <div className="title_header">Integrations</div>

          <div className="integrations-content">
            <div className="integration-item-content">
              {!isLoadingConversationDetails &&
                integrationsData?.map((item, i) => (
                  <div key={i} className="integration-item-container">
                    <div className="header">
                      <div className="info">
                        <img src={item?.config?.imageUrl} alt="" />
                        <p>{item?.config?.name}</p>
                      </div>
                      {/* <a
                      href={item?.credentials?.api_domain}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Open in {item?.config?.name}
                    </a> */}
                    </div>

                    {(ticketsPermissions.read || ticketsPermissions.viewAll) &&
                      FirmTickets?.filter((x) => x?.type === item?.type)?.map(
                        (ticket, index) => (
                          <div key={index} className="content">
                            <div
                              key={`ticket-item-${index}`}
                              className="ticket-item"
                            >
                              <span className="ticket-title">
                                <a
                                  href={item?.remoteLink}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {ticket?.title}
                                </a>
                              </span>
                              <span className="ticket-date">
                                {moment(ticket?.updatedAt).format("DD/MM/YYYY")}
                              </span>
                            </div>
                            <div className="ticket-actions">
                              <a
                                className="ticket-link"
                                onClick={() => this.toggleTicketDetails(ticket)}
                              >
                                View
                              </a>
                              {(ticketsPermissions.update ||
                                ticketsPermissions.modifyAll) && (
                                <a
                                  className="ticket-link"
                                  onClick={() => {
                                    patchTicketFormData(ticket);
                                    toggleTicketForm(item?.config);
                                  }}
                                >
                                  Edit
                                </a>
                              )}
                            </div>
                          </div>
                        )
                      )}

                    {(ticketsPermissions.create ||
                      ticketsPermissions.modifyAll) && (
                      <Button
                        type="button"
                        className="create-ticket"
                        onClick={() => {
                          patchTicketFormData(null);
                          toggleTicketForm(item?.config);
                        }}
                      >
                        Create Ticket
                      </Button>
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>

        {csatScore && (
          <div className="integration-container-item">
            <div className="title_header">CSAT</div>

            <div className="integrations-content">
              <div className="integration-item-content">
                <div className="csat-content">
                  <div className="info">
                    <p>Score</p>
                    <h6>{csatScore}/5</h6>
                  </div>
                  {customerFeedback && (
                    <div className="info">
                      <p>Feedback</p>
                      <h6>{customerFeedback}</h6>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        {ticketDetails && (
          <TicketDetails
            ticketDetails={ticketDetails}
            toggleTicketDetails={this.toggleTicketDetails}
          />
        )}
      </div>
    );
  }
}

export default connect(null, {
  getFirmIntegrationsAction,
  getTicketsAction,
  getCustomerGroupsAction,
})(Integrations);
