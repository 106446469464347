import React, { Suspense, lazy, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { io } from "socket.io-client";
import mixpanel from "mixpanel-browser";

import { patchRequest } from "./api";
import { getUserDetails } from "./utils/functions";
import {
  conversationsPermissions,
  broadcastPermissions,
  customersPermissions,
  insightsPermissions,
} from "./utils/permissions";
import {
  createConversationsUnreadCount,
  createConversationsUnreadMsgs,
} from "./redux/actions/Conversations";

import { PublicRoute, PrivateRoute } from "./components/Hoc";

import { fetchToken, onMessageListener } from "./firebase";

import "./styles/style.sass";
import { APP_NOTIFY_TOKEN, APP_ONEROUTE_USER } from "./utils/constants";

const Auth = lazy(() => import("./containers/Auth"));
const Home = lazy(() => import("./containers/Home"));
const GetStarted = lazy(() => import("./containers/GetStarted"));
const Conversations = lazy(() => import("./containers/Conversations"));
const Mentions = lazy(() => import("./containers/Mentions"));
const Csat = lazy(() => import("./containers/Csat"));
const Broadcasts = lazy(() => import("./containers/Broadcasts"));
const BroadcastInfo = lazy(() =>
  import("./containers/Broadcasts/BroadcastInfo")
);
const Customers = lazy(() => import("./containers/Customers"));
const CustomerDetails = lazy(() =>
  import("./containers/Customers/CustomerDetails")
);
const Insights = lazy(() => import("./containers/Insights"));
const Settings = lazy(() => import("./containers/Settings"));
const AgentInsightsDetails = lazy(() =>
  import("./containers/Insights/AgentInsightsDetails")
);

const userAuthToken =
  JSON.parse(window.localStorage.getItem(APP_ONEROUTE_USER))?.token || "";

const defaltNotifyToken =
  JSON.parse(window.localStorage.getItem(APP_NOTIFY_TOKEN)) || null;

var socket = io(process.env.REACT_APP_SOCKET_URL, {
  auth: {
    token: userAuthToken,
  },
  reconnectionDelay: 1000,
  reconnection: true,
  transports: ["websocket"],
  upgrade: false,
  forceNew: true,
});

const App = () => {
  const dispatch = useDispatch();
  const { conversationsUnreadCount, conversationsUnreadMsgs } = useSelector(
    (state) => state.conversations
  );

  const [fetchedToken, setFetchedToken] = useState(null);
  const [notificationToken, setNotificationToken] = useState(defaltNotifyToken);

  var msgUnreadCount = useRef();
  var unreadMessages = useRef();

  onMessageListener()
    .then(() => {})
    .catch((err) => console.log("failed: ", err));

  useEffect(() => {
    if (notificationToken) {
      console.log("Notification permission enabled 👍🏻");
    } else {
      fetchToken(setFetchedToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const user = getUserDetails();

    if (!notificationToken && fetchedToken && user.id) {
      saveNotificationToken(user?.id);
    }

    if (!notificationToken && !fetchedToken) {
      console.log("Need notification permission ❗️");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedToken]);

  const saveNotificationToken = async (userId) => {
    await patchRequest({
      url: `users/${userId}/user-configs`,
      token: true,
      data: {
        webPushTokens: fetchedToken,
      },
    })
      .then((res) => {
        if (res?.data?.success) {
          setNotificationToken(fetchedToken);
          localStorage.setItem(APP_NOTIFY_TOKEN, JSON.stringify(fetchedToken));

          console.log("Notification permission enabled 👍🏻");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // MixPanel Starts Here
  useEffect(() => {
    mixpanel.init("3aae5910a49fe4c3e612812fbf2ad861");
  }, []);
  // MixPanel Ends Here

  useEffect(() => {
    msgUnreadCount.current = conversationsUnreadCount;
  }, [conversationsUnreadCount]);

  useEffect(() => {
    unreadMessages.current = conversationsUnreadMsgs;
  }, [conversationsUnreadMsgs]);

  // WebSocket.io Script Starts Here
  useEffect(() => {
    const { id, firm_id } = getUserDetails();
    msgUnreadCount.current = conversationsUnreadCount;
    unreadMessages.current = conversationsUnreadMsgs;

    // eslint-disable-next-line no-undef
    var element = document.querySelector("#or-root");

    socket.on("newMessage", (data) => {
      if (
        data?.conversation?.firm_id === firm_id &&
        (data?.conversation?.agent_id === id ||
          !data?.conversation?.agent_id) &&
        data?.message?.sender?.authUser === false
      ) {
        console.log(".");
        element.setAttribute(
          "data-newappmessage",
          encodeURIComponent(JSON.stringify(data))
        );

        const convoId =
          window.location.pathname?.replace("/conversations/", "") || null;

        if (convoId?.includes(data?.conversation?.customer_id) === false) {
          const status = data?.conversation?.status;

          msgUnreadCount.current = {
            ...msgUnreadCount.current,
            [status]: msgUnreadCount.current[status] + 1,
          };
          unreadMessages.current = [
            ...unreadMessages.current,
            {
              id: data?.conversation?.id,
              status: data?.conversation?.status,
            },
          ];

          dispatch(createConversationsUnreadCount(msgUnreadCount.current));
          dispatch(createConversationsUnreadMsgs(unreadMessages.current));
        }
      }
    });

    setInterval(() => {
      socket.emit("ping-alive", "hello wolrd");
    }, 58000); // PING SERVER EVERY 58 seconds

    socket.on("assignedConversation", (data) => {
      if (data?.firm_id === firm_id) {
        element.setAttribute(
          "data-newappmessage",
          encodeURIComponent(JSON.stringify(data))
        );
      }
    });

    socket.on("newDeliveryReport", (data) => {
      element.setAttribute(
        "data-newappmessage",
        encodeURIComponent(JSON.stringify({ ...data, type: "dlr" }))
      );
    });

    socket.on("disconnect", (reason) => {
      console.log("REASON:", reason);
      mixpanel.track("WebSocket Disconnection", {
        reason: reason,
      });

      if (reason === "io server disconnect") {
        socket.connect();
      }
    });

    socket.on("error", (error) => {
      console.log("ERROR:", error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // TODO: clean up socket later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // WebSocket.io Script Ends Here

  return (
    <BrowserRouter>
      <Suspense fallback={<div>...</div>}>
        <Switch>
          <Redirect exact path="/" to="/home" />
          <PublicRoute path="/login" component={Auth} />
          <PublicRoute path="/register" component={Auth} />
          <PublicRoute path="/sign-up" component={Auth} />
          <PublicRoute path="/verify/:token" component={Auth} />
          <PublicRoute path="/auth/confirmation/:token" component={Auth} />
          <PublicRoute path="/auth/reset-password/:token" component={Auth} />
          <PublicRoute path="/feedback/:id" component={Csat} />

          <PrivateRoute exact path="/get-started" component={GetStarted} />
          <PrivateRoute exact path="/get-started/:id" component={GetStarted} />
          <PrivateRoute exact path="/home" component={Home} />
          {conversationsPermissions.read && (
            <PrivateRoute
              exact
              path="/conversations"
              component={Conversations}
            />
          )}
          {conversationsPermissions.read && (
            <PrivateRoute
              path="/conversations/:id/:channel"
              component={Conversations}
            />
          )}
          {conversationsPermissions.read && (
            <PrivateRoute exact path="/activity" component={Mentions} />
          )}
          {conversationsPermissions.read && (
            <PrivateRoute path="/activity/:mentionId" component={Mentions} />
          )}
          {broadcastPermissions.read && (
            <PrivateRoute exact path="/broadcasts" component={Broadcasts} />
          )}
          {broadcastPermissions.read && (
            <PrivateRoute path="/broadcasts/:id" component={BroadcastInfo} />
          )}
          {customersPermissions.read && (
            <PrivateRoute exact path="/customers" component={Customers} />
          )}
          {customersPermissions.read && (
            <PrivateRoute path="/customers/:id" component={CustomerDetails} />
          )}
          {insightsPermissions.read && (
            <PrivateRoute path="/insights" component={Insights} />
          )}
          {insightsPermissions.read && (
            <PrivateRoute
              path="/insights-details/agents/:id"
              component={AgentInsightsDetails}
            />
          )}
          <PrivateRoute path="/settings" component={Settings} />
          <Route path="*" render={() => <Redirect to="/home" />} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
