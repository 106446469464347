import React from "react";

export default () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30.375 6.75L24.75 13.5L21.375 10.125"
      stroke="#8F99A6"
      strokeWidth="2.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.625 18H3.375C2.77826 18 2.20597 17.7629 1.78401 17.341C1.36205 16.919 1.125 16.3467 1.125 15.75V4.5C1.125 3.90326 1.36205 3.33097 1.78401 2.90901C2.20597 2.48705 2.77826 2.25 3.375 2.25H32.625C33.2217 2.25 33.794 2.48705 34.216 2.90901C34.6379 3.33097 34.875 3.90326 34.875 4.5V15.75C34.875 16.3467 34.6379 16.919 34.216 17.341C33.794 17.7629 33.2217 18 32.625 18H19.125"
      stroke="#8F99A6"
      strokeWidth="2.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.87535 33.75L3.72935 29.085C3.52138 28.8565 3.36082 28.589 3.25696 28.298C3.15311 28.007 3.10804 27.6982 3.12435 27.3897C3.14066 27.0811 3.21804 26.7789 3.352 26.5005C3.48595 26.222 3.67383 25.9729 3.90474 25.7676C4.13565 25.5623 4.405 25.4049 4.6972 25.3044C4.98939 25.2039 5.29861 25.1624 5.60696 25.1823C5.9153 25.2022 6.21663 25.2831 6.49349 25.4203C6.77035 25.5575 7.01724 25.7482 7.21985 25.9815L10.1254 29.25V15.75C10.1254 15.1533 10.3624 14.581 10.7844 14.159C11.2063 13.7371 11.7786 13.5 12.3754 13.5C12.9721 13.5 13.5444 13.7371 13.9663 14.159C14.3883 14.581 14.6254 15.1533 14.6254 15.75V25.875H16.8754C18.6656 25.875 20.3825 26.5862 21.6483 27.852C22.9142 29.1179 23.6254 30.8348 23.6254 32.625V33.75"
      stroke="#8F99A6"
      strokeWidth="2.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
