import React from "react";
import { useSelector } from "react-redux";
import * as moment from "moment";

// import MediaCarousel from "./MediaCarousel";
import { Button } from "../../../components/FormElements";

import { ArrowUp } from "../../../assets/vectors";

import {
  getInitials,
  formatNumber,
  capitalize,
} from "../../../utils/functions";

const ChatMessage = ({
  mention,
  businessUsername,
  customerUsername,
  textOfStatusReplied,
  isReply,
  resendMessage,
}) => {
  const { mentionReplySendingStatus, mentionReplySendingResponse } =
    useSelector((state) => state.mentions);

  const highlightUsername = (text) => {
    var mainText = text;

    var usernameArray = [
      mention?.businessUsername,
      mention?.customerUsername,
      mention?.toUsername,
      mention?.senderUsername,
      businessUsername,
      customerUsername,
    ];
    usernameArray = usernameArray?.filter((x) => x !== undefined);

    usernameArray.forEach((str) => {
      mainText =
        mainText.replaceAll(`@${str}`, `<strong>@${str}</strong>`) || text;
    });

    return mainText;
  };

  const customerName = mention.customerName || mention.customerUsername;
  const senderName = mention.senderName || mention.senderUsername;

  return (
    <div className="mention-chat-message">
      <div className="sender">
        <div className="left">
          {textOfStatusReplied ? (
            <div className="avatar">
              {mention?.businessUsername
                ? getInitials(mention?.businessUsername)
                : ""}
            </div>
          ) : (
            <div className="avatar">
              {customerName || senderName
                ? getInitials(customerName || senderName)
                : ""}
            </div>
          )}

          {textOfStatusReplied ? (
            <div className="name">
              <h6>{mention?.businessUsername}</h6>
              <p>@{mention?.businessUsername}</p>
            </div>
          ) : (
            <div className="name">
              <h6>{customerName || senderName}</h6>
              <p>@{mention?.customerUsername || mention?.senderUsername}</p>
            </div>
          )}
        </div>

        {!mentionReplySendingStatus && (
          <div className="time">{moment(mention?.createdAt).fromNow()}</div>
        )}
      </div>

      <div className="message-content">
        <p
          dangerouslySetInnerHTML={{
            __html: highlightUsername(
              textOfStatusReplied ? mention?.textOfStatusReplied : mention?.text
            ),
          }}
        ></p>

        {/* {media && (
          <MediaCarousel
            media={[
              "https://images.pexels.com/photos/674010/pexels-photo-674010.jpeg",
              "https://images.pexels.com/photos/326055/pexels-photo-326055.jpeg",
              "https://images.pexels.com/photos/1133957/pexels-photo-1133957.jpeg",
            ]}
          />
        )} */}
      </div>

      {!isReply && (
        <div className="stats">
          {mention?.statusRepliedRetweets >= 0 ? (
            <p>
              <span>{formatNumber(mention?.statusRepliedRetweets || 0)}</span>
              Retweets
            </p>
          ) : (
            ""
          )}
          <p>
            <span>{formatNumber(mention?.statusRepliedLikes || 0)}</span>Likes
          </p>

          <Button
            className="link-button"
            onClick={() => {
              window.open(mention?.externalLink);
            }}
          >
            View on <span>{capitalize(mention?.medium)}</span>
            <ArrowUp />
          </Button>
        </div>
      )}

      {isReply && !mention?.id && (
        <div className="message-footer">
          {mentionReplySendingStatus === "sending" && (
            <span>Sending reply...</span>
          )}
          {!mentionReplySendingStatus && <span>Reply sent ✓</span>}
          {mentionReplySendingStatus === "failed" && (
            <div className="failed-action">
              {mentionReplySendingResponse}{" "}
              <span onClick={() => resendMessage(true, mention)}>
                Try again
              </span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ChatMessage;
