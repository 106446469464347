import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

import {
  createCsatAction,
  updateCsatAction,
} from "../../../redux/actions/Csat";

import { Button, TextField } from "../../../components/FormElements";
import Modal from "../../../components/Modal";
import FormNotifications from "../../../components/Notifications";

const CsatForm = ({ csatData, toggleCsatForm, getCsatAction }) => {
  const dispatch = useDispatch();

  const defaultChannels = csatData?.Channels?.map((x) => ({
    label: `(${x?.medium}) - ${x?.name}`,
    value: x?.id,
  }));

  const {
    createCsatOutcome,
    createCsatMessage,
    updateCsatOutcome,
    updateCsatMessage,
    isCreatingCsat,
    isUpdatingCsat,
  } = useSelector((state) => state.csat);
  const { firmChannelsData } = useSelector((state) => state.channels);

  const [formData, setFormData] = useState({
    question: csatData?.question,
    response: csatData?.response,
    channels: defaultChannels || [],
  });

  const handleChange = ({ target }) => {
    setFormData({
      ...formData,
      [target.name]: target.value,
    });
  };

  const checkFormValidity = () => {
    let value = true;
    if (
      !formData?.question ||
      !formData?.response ||
      formData?.channels?.length < 1 ||
      isCreatingCsat ||
      isUpdatingCsat
    ) {
      return false;
    }

    return value;
  };

  const submitForm = (e) => {
    e.preventDefault();

    const payload = {
      question: formData?.question,
      response: formData?.response,
      channels: formData?.channels?.map((x) => x?.value),
    };

    if (csatData) {
      dispatch(updateCsatAction(payload, csatData?.id)).then((res) => {
        if (res === 200) {
          toggleCsatForm();
          getCsatAction();
        }
      });
    } else {
      dispatch(createCsatAction(payload)).then((res) => {
        if (res === 201) {
          toggleCsatForm();
          getCsatAction();
        }
      });
    }
  };

  const channelOptions = firmChannelsData
    ?.filter((x) => x.status === "ACTIVE")
    ?.map((x) => ({
      label: `(${x?.medium}) - ${x?.name}`,
      value: x?.id,
    }));

  return (
    <Modal closeModal={() => toggleCsatForm()}>
      <div className="modal-content">
        <div className="modal-title">{csatData ? "Edit" : "New"} CSAT</div>
        <div className="modal-form saved-response-form">
          <form onSubmit={submitForm}>
            <div className="input-container mb-25">
              <label>Channels</label>
              <Select
                classNamePrefix="select_container"
                value={formData?.channels}
                options={channelOptions}
                onChange={(selections) =>
                  setFormData({ ...formData, channels: selections })
                }
                isDisabled={isCreatingCsat || isUpdatingCsat}
                isClearable
                isMulti
              />
            </div>
            <TextField
              label="Question"
              name="question"
              value={formData?.question}
              onChange={(e, valid) => handleChange(e, valid)}
              className="mb-25"
            />
            <TextField
              label="Message on submission"
              name="response"
              value={formData?.response}
              onChange={(e, valid) => handleChange(e, valid)}
              className="mb-25"
            />
            <div className="modal-form-action">
              <Button
                type="button"
                className="secondary-button"
                onClick={() => toggleCsatForm()}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="primary-button"
                disabled={!checkFormValidity()}
              >
                {isCreatingCsat || isUpdatingCsat
                  ? "Processing..."
                  : csatData
                  ? "Save Changes"
                  : "Add CSAT"}
              </Button>
            </div>

            {createCsatOutcome && (
              <FormNotifications
                type={createCsatOutcome}
                message={createCsatMessage}
              />
            )}
            {updateCsatOutcome && (
              <FormNotifications
                type={updateCsatOutcome}
                message={updateCsatMessage}
              />
            )}
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default CsatForm;
