import axios from "axios";

export const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {},
});

export const getUserDetails = () => {
  return JSON.parse(localStorage.getItem("oneroute-user")) || null;
};

export const getNewlyRegisteredUserDetails = () => {
  return JSON.parse(localStorage.getItem("oneroute-new-user")) || null;
};

export const getRequest = async ({ url, params, token }) => {
  const currentUser = getUserDetails();
  const newlyRegisteredUser = getNewlyRegisteredUserDetails();

  const userToUse = currentUser ? currentUser : newlyRegisteredUser;

  var request = {
    url,
    method: "get",
    params,
  };

  if (token && userToUse && userToUse.token) {
    request["headers"] = { Authorization: `Token token=${userToUse.token}` };
  }

  const requestResponse = await API(request);

  return requestResponse;
};

export const postRequest = async ({ url, params, data, token, formData }) => {
  const currentUser = getUserDetails();
  const newlyRegisteredUser = getNewlyRegisteredUserDetails();

  const userToUse = currentUser ? currentUser : newlyRegisteredUser;

  var request = {
    url,
    method: "post",
    params,
    data,
  };

  if (token && userToUse && userToUse.token) {
    request["headers"] = { Authorization: `Token token=${userToUse.token}` };
  }

  if (formData) {
    request.headers = {
      ...request.headers,
      "Content-Type": "multipart/form-data",
    };
  }

  const requestResponse = await API(request);

  return requestResponse;
};

export const convoPostRequest = async ({ url, params, data, token }) => {
  const currentUser = getUserDetails();
  const newlyRegisteredUser = getNewlyRegisteredUserDetails();

  const userToUse = currentUser ? currentUser : newlyRegisteredUser;

  var request = {
    url,
    method: "post",
    params,
    data,
  };

  if (token && userToUse && userToUse.token) {
    request["headers"] = { Authorization: `Token token=${userToUse.token}` };
  }

  const requestResponse = await API(request);

  return requestResponse;
};

export const patchRequest = async ({ url, params, data, token }) => {
  const currentUser = getUserDetails();

  var request = {
    url,
    method: "patch",
    params,
    data,
  };

  if (token && currentUser && currentUser.token) {
    request["headers"] = { Authorization: `Token token=${currentUser.token}` };
  }

  const requestResponse = await API(request);

  return requestResponse;
};

export const putRequest = async ({ url, params, data, token }) => {
  const currentUser = getUserDetails();

  var request = {
    url,
    method: "put",
    params,
    data,
  };

  if (token && currentUser && currentUser.token) {
    request["headers"] = { Authorization: `Token token=${currentUser.token}` };
  }

  const requestResponse = await API(request);

  return requestResponse;
};

export const deleteRequest = async ({ url, params, data, token }) => {
  const currentUser = getUserDetails();

  var request = {
    url,
    method: "delete",
    params,
    data,
  };

  if (token && currentUser && currentUser.token) {
    request["headers"] = { Authorization: `Token token=${currentUser.token}` };
  }

  const requestResponse = await API(request);

  return requestResponse;
};
