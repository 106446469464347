import React from "react";
import classNames from "classnames";

const Modal = ({
  className,
  children,
  closeModal,
  minHeight,
  small,
  xsmall,
  large,
  halfScreen,
  fullScreen,
}) => (
  <div
    className={classNames("modal", {
      "channel-modal": className === "channel-modal",
      minHeight,
      small,
      xsmall,
      large,
      halfScreen,
      fullScreen,
    })}
  >
    <div className="modal-overlay" onClick={closeModal}></div>
    <div className="modal-container" onClick={() => false}>
      {children}
    </div>
  </div>
);

export default Modal;
