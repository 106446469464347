import React, { Component } from "react";
import classNames from "classnames";
import { connect } from "react-redux";

import {
  getRolesAction,
  getAgentsAction,
  createAgentsAction,
  deleteAgentsAction,
} from "../../../redux/actions/Agents";

import { Button, TextField, Select } from "../../../components/FormElements";
import Table from "../../../components/Table";
import Modal from "../../../components/Modal";
import OptionsDropdown from "../../../components/OptionsDropdown";

import {
  Filter,
  CircularPlus,
  Search,
  Pencil,
  Trash,
  Send2,
} from "../../../assets/vectors";
import { getRequestError, getUserDetails } from "../../../utils/functions";
import { agentsPermissions } from "../../../utils/permissions";
import UserForm from "./UserForm";
import { getRequest } from "../../../api";
import Toaster from "../../../components/Toaster";
import { APP_INVITED_TEAM_MEMBER } from "../../../utils/constants";

class Users extends Component {
  state = {
    currentTab: "confirmed",
    isUserFormActive: false,
    selectedUser: null,
    isConfirmationModalActive: false,
    searchValue: "",
    query: {
      offset: 0,
      limit: 20,
      status: "confirmed",
    },
    agentInfo: true,
  };

  removeAgentInfo = () => {
    this.setState({
      agentInfo: false,
    });
  };

  switchTab = (currentTab) => {
    this.setState(
      {
        currentTab,
        query: {
          ...this.state.query,
          status: currentTab,
        },
      },
      () => this.props.getAgentsAction(this.state.query)
    );
  };

  toggleUserForm = (selectedUser) => {
    this.setState({
      isUserFormActive: !this.state.isUserFormActive,
      selectedUser,
    });
  };

  optionsAction = (type, row) => {
    if (type === "edit") {
      this.toggleUserForm(row);
    } else if (type === "delete") {
      this.toggleConfirmationModal(row);
    } else if (type === "resend-invite") {
      this.resendInvite(row);
    }
  };

  handleChange = ({ target }, valid) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [target.name]: {
          value: target.value,
          valid,
        },
      },
    });
  };

  resendInvite = async ({ id }) => {
    this.setState({
      isResendingInvite: true,
    });

    try {
      const res = await getRequest({
        url: `auth/resend-invite/${id}`,
        token: true,
      });
      console.log(res);

      this.openToaster("success", "Invitation resent successfully!");

      this.setState({
        isResendingInvite: true,
      });

      document.body.click();
    } catch (error) {
      console.log(error);

      const message = getRequestError(error);

      this.openToaster("error", message);

      this.setState({
        isResendingInvite: true,
      });
    }
  };

  toggleConfirmationModal = (selectedForDeletion) => {
    this.setState({
      selectedForDeletion,
      isConfirmationModalActive: !this.state.isConfirmationModalActive,
    });
  };

  deleteAgent = () => {
    const { selectedForDeletion } = this.state;
    const { deleteAgentsAction } = this.props;

    deleteAgentsAction(selectedForDeletion.id);
  };

  filterTable = (type, value) => {
    this.setState(
      {
        query: {
          ...this.state.query,
          [type]: value ? value : null,
        },
      },
      () => this.props.getAgentsAction(this.state.query)
    );
  };

  paginateTable = (number) => {
    const { query } = this.state;
    const { limit } = query;

    this.setState(
      {
        query: {
          ...query,
          offset: number * limit,
        },
      },
      () => this.props.getAgentsAction(this.state.query)
    );
  };

  openToaster = (status, content) => {
    this.setState({
      toaster: {
        open: "open",
        status,
        content,
      },
    });
  };

  closeToaster = () => {
    this.setState({
      toaster: {
        ...this.state.toaster,
        open: "closed",
      },
    });
  };

  moreOptions = () => {
    var options = [];

    if (agentsPermissions?.update || agentsPermissions?.modifyAll) {
      options = [
        ...options,
        {
          label: (
            <div>
              <span className="icon">
                <Pencil />
              </span>{" "}
              <span className="text">Edit</span>
            </div>
          ),
          value: "edit",
        },
      ];
    }

    if (agentsPermissions?.delete || agentsPermissions?.modifyAll) {
      options = [
        ...options,
        {
          label: (
            <div className="red">
              <span className="icon">
                <Trash />
              </span>{" "}
              <span className="text">Delete</span>
            </div>
          ),
          value: "delete",
        },
      ];
    }

    return options;
  };

  moreOptionsPending = () => {
    var options = [];

    if (agentsPermissions?.update || agentsPermissions?.modifyAll) {
      options = [
        ...options,
        {
          label: (
            <div>
              <span className="icon">
                <Send2 />
              </span>{" "}
              <span className="text">Resend Invite</span>
            </div>
          ),
          value: "resend-invite",
        },
      ];
    }

    if (agentsPermissions?.delete || agentsPermissions?.modifyAll) {
      options = [
        ...options,
        {
          label: (
            <div className="red">
              <span className="icon">
                <Trash />
              </span>{" "}
              <span className="text">Delete</span>
            </div>
          ),
          value: "delete",
        },
      ];
    }

    return options;
  };

  componentDidMount() {
    this.props.getRolesAction();

    const agent_id = this.props.location?.state?.agent_id;
    if (agent_id) {
      this.setState({
        searchValue: agent_id,
      });
      this.filterTable("searchQuery", agent_id);
    } else {
      this.props.getAgentsAction(this.state.query);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      agentsData,
      createAgentsOutcome,
      updateAgentsOutcome,
      deleteAgentsOutcome,
      getAgentsAction,
    } = this.props;

    if (
      prevProps.createAgentsOutcome !== createAgentsOutcome &&
      createAgentsOutcome === "success"
    ) {
      this.openToaster("success", "Invitation sent successfully!");
      this.switchTab("pending");

      this.setState({
        isUserFormActive: false,
      });
    }

    if (
      prevProps.updateAgentsOutcome !== updateAgentsOutcome &&
      updateAgentsOutcome === "success"
    ) {
      getAgentsAction(this.state.query);

      this.setState({
        isUserFormActive: false,
      });
    }

    if (
      prevProps.deleteAgentsOutcome !== deleteAgentsOutcome &&
      deleteAgentsOutcome === "success"
    ) {
      getAgentsAction(this.state.query);

      this.setState({
        isConfirmationModalActive: false,
      });
    }

    if (prevProps.agentsData !== agentsData) {
      const { firm_id } = getUserDetails();
      const hasFirmInvitedTeamMember =
        JSON.parse(window.localStorage.getItem(APP_INVITED_TEAM_MEMBER)) ||
        null;

      if (
        agentsData?.length > 0 &&
        !hasFirmInvitedTeamMember?.firm_id !== firm_id
      ) {
        const data = {
          firm_id,
          value: true,
        };

        localStorage.setItem(APP_INVITED_TEAM_MEMBER, JSON.stringify(data));
      }
    }
  }

  render() {
    const {
      currentTab,
      isUserFormActive,
      isConfirmationModalActive,
      selectedUser,
      searchValue,
      agentInfo,
      toaster,
    } = this.state;
    const {
      isLoadingAgents,
      isDeletingAgent,
      agentsData,
      agentsMeta,
      rolesData,
    } = this.props;

    const roleOptions =
      rolesData?.map((x) => ({
        label: x?.name,
        value: x?.name,
      })) || [];

    const columns = [
      {
        id: "name",
        label: "Name",
        render: ({ firstName, lastName, status }) => (
          <>
            {firstName} {lastName}
            {status === "pending" && (
              <span className="pending-status">Pending</span>
            )}
          </>
        ),
      },
      {
        id: "email",
        label: "Email",
        render: ({ email, status }) => (
          <span
            className={classNames("text-lowercase", {
              pending: status === "pending",
            })}
          >
            {email}
          </span>
        ),
      },
      {
        id: "roleName",
        label: "Role",
      },
      {
        id: "",
        label: "Actions",
        thClassName: "text-center",
        render: (row) => (
          <div className="text-center">
            <OptionsDropdown
              onClick={(val) => {
                this.optionsAction(val, row);
              }}
              options={
                row.status === "pending"
                  ? this.moreOptionsPending()
                  : this.moreOptions()
              }
            />
          </div>
        ),
      },
    ];

    return (
      <div className="settings-page-container">
        <div className="page-header">
          <span className="title">Users</span>
        </div>
        {agentInfo && (
          <div className="settings-page-info">
            <div>
              <span className="title">Who are Users?</span>
              <span className="description">
                Users are members of your firm that handle conversations and
                respond to customers on behalf of your organisation.
              </span>
              <Button className="plain-button" onClick={this.removeAgentInfo}>
                Got it
              </Button>
            </div>
            <div className="image">
              <img
                src={require("../../../assets/images/agent-girl.png").default}
                alt=""
              />
            </div>
          </div>
        )}
        <div className="table-container agents-table">
          <div className="table-filter">
            <div className="table-search-filter">
              <TextField
                placeholder="Search users"
                defaultValue={searchValue}
                leftIcon={<Search />}
                onChange={(e) =>
                  this.filterTable("searchQuery", e.target.value)
                }
              />
            </div>
            <div className="table-other-filters">
              <Select
                leftIcon={<Filter />}
                options={[
                  { label: "Filter by Role", value: "" },
                  ...roleOptions,
                ]}
                onChange={(e) => this.filterTable("roleName", e.target.value)}
                className="mr-15"
                filter
              />
              {(agentsPermissions.create || agentsPermissions.modifyAll) && (
                <Button
                  className="primary-button"
                  onClick={() => this.toggleUserForm()}
                >
                  <span className="icon">
                    <CircularPlus />
                  </span>
                  <span>Add User</span>
                </Button>
              )}
            </div>
          </div>

          <div className="table-tabs">
            <div
              className={classNames("table-tab-item", {
                active: currentTab === "confirmed",
              })}
              onClick={() => this.switchTab("confirmed")}
            >
              Active Users
            </div>
            <div
              className={classNames("table-tab-item", {
                active: currentTab === "pending",
              })}
              onClick={() => this.switchTab("pending")}
            >
              Pending Invites
            </div>
          </div>

          <Table
            loading={isLoadingAgents}
            columns={columns}
            rows={agentsData}
            meta={agentsMeta}
            editRow={this.toggleUserForm}
            deleteRow={this.toggleConfirmationModal}
            paginateTable={this.paginateTable}
          />
        </div>

        {isUserFormActive && (
          <UserForm
            userData={selectedUser}
            toggleUserForm={this.toggleUserForm}
          />
        )}

        {isConfirmationModalActive && (
          <Modal closeModal={this.toggleConfirmationModal} small>
            <div className="modal-content confirmation-modal">
              <div className="modal-icon red">
                <Trash />
              </div>
              <div className="modal-title">
                Are you sure you want to delete this agent?
              </div>
              <div className="modal-text">This action cannot be reversed</div>
              <div className="modal-action">
                <Button
                  className="secondary-button red"
                  type="button"
                  onClick={this.deleteAgent}
                  disabled={isDeletingAgent}
                >
                  {isDeletingAgent ? "Processing..." : "Delete Agent"}
                </Button>
                <Button
                  className="plain-button black"
                  type="button"
                  onClick={this.toggleConfirmationModal}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Modal>
        )}

        <Toaster {...toaster} closeToaster={this.closeToaster} />
      </div>
    );
  }
}

const mapStateToProps = ({ agents }) => agents;

export default connect(mapStateToProps, {
  getRolesAction,
  getAgentsAction,
  createAgentsAction,
  deleteAgentsAction,
})(Users);
