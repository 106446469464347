import React from "react";

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    fill="none"
    viewBox="0 0 12 12"
  >
    <path
      stroke="#8F99A6"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M9.5 2h-7a1 1 0 00-1 1v7a1 1 0 001 1h7a1 1 0 001-1V3a1 1 0 00-1-1zM8 1v2M4 1v2M1.5 5h9"
    ></path>
  </svg>
);
