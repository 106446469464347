import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { Copy } from "../../../../assets/vectors";

import { accountSettingsPermissions } from "../../../../utils/permissions";

import {
  getFirmConfigAction,
  patchFirmConfigAction,
} from "../../../../redux/actions/AccountSettings";

const ApiConfiguration = ({
  isLoadingAccountSettings,
  accountSettingsData,
  isSubmittingAccountSettings,
  openToaster,
  copyCode,
}) => {
  const dispatch = useDispatch();

  const [webhookUrlForm, setWebhookUrlForm] = useState({
    open: false,
    value: "",
  });

  const submitWebhookUrlForm = () => {
    if (webhookUrlForm?.value) {
      dispatch(
        patchFirmConfigAction({ webhookUrl: webhookUrlForm?.value })
      ).then((res) => {
        if (res?.success === true) {
          dispatch(getFirmConfigAction());
          setWebhookUrlForm({ open: false, value: "" });
          openToaster("success", res?.message);
        }
      });
    }
  };

  return (
    <div className="nav_content">
      <div className="tab_content">
        <h6 className="header">
          {isLoadingAccountSettings ? "Loading..." : "API Configuration"}
        </h6>

        {!isLoadingAccountSettings && (
          <div className="content">
            <div className="item">
              <p className="label">API Key</p>
              <div className="input_container">
                <p>{accountSettingsData?.apiKey}</p>
                <span
                  className="copy_btn"
                  onClick={() => copyCode(accountSettingsData?.apiKey)}
                >
                  <Copy />
                </span>
              </div>
            </div>

            <div className="item">
              <p className="label">Firm ID</p>
              <div className="input_container">
                <p>{accountSettingsData?.firm_id}</p>
                <span
                  className="copy_btn"
                  onClick={() => copyCode(accountSettingsData?.firm_id)}
                >
                  <Copy />
                </span>
              </div>
            </div>

            <div className="item">
              <p className="label">USSD</p>
              <div className="input_container">
                <p>{accountSettingsData?.ussd}</p>
                <span
                  className="copy_btn"
                  onClick={() => copyCode(accountSettingsData?.ussd)}
                >
                  <Copy />
                </span>
              </div>
            </div>

            <div className="item">
              <p className="label">Webhook URL</p>
              <div className="input_container">
                <p>{accountSettingsData?.webhookUrl}</p>
                <span
                  className="copy_btn"
                  onClick={() => copyCode(accountSettingsData?.webhookUrl)}
                >
                  <Copy />
                </span>
                {(accountSettingsPermissions?.create ||
                  accountSettingsPermissions?.update ||
                  accountSettingsPermissions?.modifyAll) && (
                  <p
                    className="update_text"
                    onClick={() =>
                      setWebhookUrlForm({
                        open: !webhookUrlForm?.open,
                        value: "",
                      })
                    }
                  >
                    {webhookUrlForm?.open ? "Close" : "Update"}
                  </p>
                )}
              </div>
            </div>
            {webhookUrlForm?.open && (
              <div className="item new_config_container">
                <p className="label">New Webhook URL:</p>
                <div className="input_container">
                  <input
                    className="input"
                    onChange={(e) =>
                      setWebhookUrlForm({
                        ...webhookUrlForm,
                        value: e?.target?.value,
                      })
                    }
                  />
                  <button
                    className="copy_btn"
                    onClick={() => submitWebhookUrlForm()}
                    disabled={isSubmittingAccountSettings}
                  >
                    {isSubmittingAccountSettings ? "Submitting..." : "Submit"}
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      <div className="helper_text">
        <p>Need help with your integration?</p>
        <a
          href="https://docs.oneroute.io/getting-started"
          target="_blank"
          rel="noopener noreferrer"
        >
          Check out our API documentation
        </a>
      </div>
    </div>
  );
};

export default ApiConfiguration;
