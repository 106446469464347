import {
  GET_SIGNATURE_LOADER,
  GET_SIGNATURE_RESPONSE,
  SIGNATURE_RESET,
  CREATE_SIGNATURE_LOADER,
  CREATE_SIGNATURE_RESPONSE,
  UPDATE_SIGNATURE_LOADER,
  UPDATE_SIGNATURE_RESPONSE,
  DELETE_SIGNATURE_LOADER,
  DELETE_SIGNATURE_RESPONSE,
} from "../constants";

const INIT_STATE = {
  signatureData: [],
  signatureMeta: {
    limit: 10,
    offset: 0,
    total: 0,
  },
  isCreatingSignature: false,
  isUpdatingSignature: false,
  isDeletingSignature: false,
  isLoadingSignature: true,
  createSignatureOutcome: "",
  createSignatureMessage: "",
  updateSignatureOutcome: "",
  updateSignatureMessage: "",
  deleteSignatureOutcome: "",
  deleteSignatureMessage: "",
  signatureOutcome: "",
  signatureMessage: "",
};

const Signature = (state = INIT_STATE, action) => {
  const {
    type,
    createSignatureOutcome,
    createSignatureMessage,
    updateSignatureOutcome,
    updateSignatureMessage,
    deleteSignatureOutcome,
    deleteSignatureMessage,
    signatureOutcome,
    signatureMessage,
    signatureData,
    signatureMeta,
    isCreatingSignature,
    isUpdatingSignature,
    isDeletingSignature,
    isLoadingSignature,
  } = action;

  switch (type) {
    case SIGNATURE_RESET: {
      return {
        ...state,
        createSignatureOutcome: "",
        createSignatureMessage: "",
        updateSignatureOutcome: "",
        updateSignatureMessage: "",
        deleteSignatureOutcome: "",
        deleteSignatureMessage: "",
      };
    }

    case GET_SIGNATURE_LOADER: {
      return {
        ...state,
        isLoadingSignature,
      };
    }

    case GET_SIGNATURE_RESPONSE: {
      return {
        ...state,
        signatureOutcome,
        signatureData,
        signatureMeta,
        signatureMessage,
      };
    }

    case CREATE_SIGNATURE_LOADER: {
      return {
        ...state,
        isCreatingSignature,
      };
    }

    case CREATE_SIGNATURE_RESPONSE: {
      return {
        ...state,
        createSignatureOutcome,
        createSignatureMessage,
      };
    }

    case UPDATE_SIGNATURE_LOADER: {
      return {
        ...state,
        isUpdatingSignature,
      };
    }

    case UPDATE_SIGNATURE_RESPONSE: {
      return {
        ...state,
        updateSignatureOutcome,
        updateSignatureMessage,
      };
    }

    case DELETE_SIGNATURE_LOADER: {
      return {
        ...state,
        isDeletingSignature,
      };
    }

    case DELETE_SIGNATURE_RESPONSE: {
      return {
        ...state,
        deleteSignatureOutcome,
        deleteSignatureMessage,
      };
    }

    default:
      return state;
  }
};

export default Signature;
