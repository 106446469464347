import React from "react";
import { useSelector } from "react-redux";

import { Button } from "../../components/FormElements";
import Modal from "../../components/Modal";

import { ChevronDownW } from "../../assets/vectors";
import { getChannelIdentifierIcon } from "../../utils/functions";

const AddChannelModal = ({ close, selectChannel, checkChannelStatus }) => {
  const { allChannelsData } = useSelector((state) => state?.channels);
  const channelOptions = allChannelsData?.filter((x) => x?.name !== "twitter");

  const isConnected = (name) => {
    return checkChannelStatus(
      name === "livechat"
        ? "widget"
        : name === "facebook messenger"
        ? "facebook"
        : name
    );
  };

  return (
    <React.Fragment>
      <Modal className="channel-modal">
        <div className="modal-content add-channel-container">
          <div className="modal-title">Add a channel</div>
          <div className="modal-sub-title">
            Please select the channel you would like to add
          </div>

          <div className="channel_listing">
            {channelOptions?.map((item, i) => (
              <div
                key={i}
                className="item"
                onClick={
                  !isConnected(item?.name)
                    ? () => {
                        close();
                        selectChannel(item?.name);
                      }
                    : () => {}
                }
              >
                <div className="info">
                  <img
                    src={
                      item?.name === "livechat"
                        ? getChannelIdentifierIcon("LIVECHAT")
                        : item?.imageUrl
                    }
                    className="channel_icon"
                    alt=""
                  />
                  <h6>{item?.name}</h6>
                </div>
                <div className="info">
                  {isConnected(item?.name) && <p>Connected</p>}
                  <ChevronDownW />
                </div>
              </div>
            ))}
          </div>

          <div className="actions">
            <Button className="secondary-button" onClick={close}>
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default AddChannelModal;
