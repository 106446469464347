import React from "react";

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      fill="#5E22D2"
      d="M8 0C3.589 0 0 3.589 0 8v7.5a.5.5 0 00.5.5H8c4.411 0 8-3.589 8-8s-3.589-8-8-8z"
    ></path>
    <path
      fill="#fff"
      d="M11.278 4.821a.337.337 0 00-.365-.074L3.518 7.773a.336.336 0 00.067.642l3.469.63.63 3.468a.336.336 0 00.642.067l3.025-7.394a.336.336 0 00-.073-.365z"
    ></path>
  </svg>
);
