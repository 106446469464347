import {
  getRequest,
  postRequest,
  patchRequest,
  deleteRequest
} from "../../api";

import {
  CREATE_CATEGORIES_LOADER,
  CREATE_CATEGORIES_RESPONSE,
  CREATE_CATEGORIES_RESET,
  UPDATE_CATEGORIES_LOADER,
  UPDATE_CATEGORIES_RESPONSE,
  UPDATE_CATEGORIES_RESET,
  GET_CATEGORIES_LOADER,
  GET_CATEGORIES_RESPONSE,
  GET_CATEGORIES_RESET,
  DELETE_CATEGORIES_LOADER,
  DELETE_CATEGORIES_RESPONSE,
  DELETE_CATEGORIES_RESET
} from "../constants";

import { getRequestError } from "../../utils/functions";

export const createCategoriesLoader = isCreatingCategory => ({
  type: CREATE_CATEGORIES_LOADER,
  isCreatingCategory
});

export const createCategoriesResponse = (
  createCategoriesOutcome,
  createCategoriesMessage
) => ({
  type: CREATE_CATEGORIES_RESPONSE,
  createCategoriesOutcome,
  createCategoriesMessage
});

export const createCategoriesReset = () => ({
  type: CREATE_CATEGORIES_RESET
});

export const updateCategoriesLoader = isUpdatingCategory => ({
  type: UPDATE_CATEGORIES_LOADER,
  isUpdatingCategory
});

export const updateCategoriesResponse = (
  updateCategoriesOutcome,
  updateCategoriesMessage
) => ({
  type: UPDATE_CATEGORIES_RESPONSE,
  updateCategoriesOutcome,
  updateCategoriesMessage
});

export const updateCategoriesReset = () => ({
  type: UPDATE_CATEGORIES_RESET
});

export const getCategoriesLoader = isLoadingCategories => ({
  type: GET_CATEGORIES_LOADER,
  isLoadingCategories
});

export const getCategoriesResponse = (
  categoriesOutcome,
  categoriesData,
  categoriesMeta,
  categoriesMessage
) => ({
  type: GET_CATEGORIES_RESPONSE,
  categoriesOutcome,
  categoriesData,
  categoriesMeta,
  categoriesMessage
});

export const getCategoriesReset = () => ({
  type: GET_CATEGORIES_RESET
});

export const deleteCategoriesLoader = isDeletingCategory => ({
  type: DELETE_CATEGORIES_LOADER,
  isDeletingCategory
});

export const deleteCategoriesResponse = (
  deleteCategoriesOutcome,
  deleteCategoriesMessage
) => ({
  type: DELETE_CATEGORIES_RESPONSE,
  deleteCategoriesOutcome,
  deleteCategoriesMessage
});

export const deleteCategoriesReset = () => ({
  type: DELETE_CATEGORIES_RESET
});

export const createCategoriesAction = (data, success, error) => {
  return async dispatch => {
    dispatch(createCategoriesReset());
    dispatch(createCategoriesLoader(true));

    try {
      await postRequest({
        url: "/firms/categories",
        data,
        token: true
      });

      success && success();
      dispatch(createCategoriesResponse("success", ""));
      dispatch(createCategoriesLoader(false));
    } catch (err) {
      const message = getRequestError(err);

      error && error(message);
      dispatch(createCategoriesResponse("error", message));
      dispatch(createCategoriesLoader(false));
    }
  };
};

export const updateCategoriesAction = (data, id, success, error) => {
  return async dispatch => {
    dispatch(updateCategoriesReset());
    dispatch(updateCategoriesLoader(true));

    try {
      await patchRequest({
        url: `/firms/categories/${id}`,
        data,
        token: true
      });

      success && success();
      dispatch(updateCategoriesResponse("success", ""));
      dispatch(updateCategoriesLoader(false));
    } catch (err) {
      const message = getRequestError(err);

      error && error(message);
      dispatch(updateCategoriesResponse("error", message));
      dispatch(updateCategoriesLoader(false));
    }
  };
};

export const getCategoriesAction = (params = {}) => {
  return async dispatch => {
    dispatch(getCategoriesReset());
    dispatch(getCategoriesLoader(true));

    try {
      const res = await getRequest({
        url: "/firms/categories",
        params,
        token: true
      });

      const { data, offset, limit, total } = res.data;

      dispatch(
        getCategoriesResponse("success", data.length ? data : [], { offset, limit, total }, "")
      );
      dispatch(getCategoriesLoader(false));
    } catch (error) {
      const message = getRequestError(error);

      dispatch(getCategoriesResponse("error", [], {}, message));
      dispatch(getCategoriesLoader(false));
    }
  };
};

export const deleteCategoriesAction = (id, success, error) => {
  return async dispatch => {
    dispatch(deleteCategoriesReset());
    dispatch(deleteCategoriesLoader(true));

    try {
      await deleteRequest({
        url: `/firms/categories/${id}`,
        token: true
      });

      success && success();
      dispatch(deleteCategoriesResponse("success", ""));
      dispatch(deleteCategoriesLoader(false));
    } catch (err) {
      const message = getRequestError(err);

      error && error(message);
      dispatch(deleteCategoriesResponse("error", message));
      dispatch(deleteCategoriesLoader(false));
    }
  };
};
