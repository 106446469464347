import React, { Component } from "react";

import Toaster from "../../../components/Toaster";
import { TextField, Button, FileInput } from "../../../components/FormElements";

import { PositionResizeImage } from ".";

class ProfileSettings extends Component {
  state = {
    imageEditor: false,
  };

  handleChange = ({ target }, valid) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [target.name]: {
          value: target.value,
          valid,
        },
      },
    });
  };

  openToaster = (status, content) => {
    this.setState({
      toaster: {
        open: "open",
        status,
        content,
      },
    });
  };

  closeToaster = () => {
    this.setState({
      toaster: {
        ...this.state.toaster,
        open: "closed",
      },
    });
  };

  toggleImageEditor = () => {
    this.setState({
      imageEditor: !this.state.imageEditor,
    });
  };

  updateAvatar = (avatar) => {
    this.setState({
      avatar,
    });
  };

  componentDidMount() {
    // this.props.getAgentsAction(this.state.query);
  }

  render() {
    const { toaster, imageEditor, avatar } = this.state;

    return (
      <div className="settings-page-container">
        <div className="page-header">
          <span className="title">Profile Settings</span>
        </div>
        <div className="settings-page-info mb-20">
          <div className="full-width">
            <span className="mini-title">Personal Details</span>
            <div className="profile-avatar mb-30">
              <img
                src={
                  avatar || require("../../../assets/svgs/avatar.svg").default
                }
                alt=""
              />
              <FileInput
                icon={<span className="upload-link">Upload Image</span>}
                onFileLoad={this.toggleImageEditor}
                onFileError={() => {}}
              />
            </div>
            <div className="form-row mb-15">
              <TextField label="First Name" onChange={() => {}} />
              <TextField label="Last Name" onChange={() => {}} />
            </div>
            <div className="form-row mb-15">
              <TextField
                label="Email Address"
                type="email"
                onChange={() => {}}
              />
              <TextField label="Phone Number" onChange={() => {}} />
            </div>
            <Button className="primary-button small">Save changes</Button>
          </div>
        </div>
        <div className="settings-page-info">
          <div className="full-width">
            <span className="mini-title">Change Password</span>
            <div className="form-row mb-15">
              <TextField
                label="Enter current password"
                type="password"
                onChange={() => {}}
              />
            </div>
            <div className="form-row mb-15">
              <TextField
                label="Enter new password"
                type="password"
                onChange={() => {}}
              />
            </div>
            <div className="form-row mb-15">
              <TextField
                label="Confirm new password"
                type="password"
                onChange={() => {}}
              />
            </div>
            <Button className="primary-button small">Change password</Button>
          </div>
        </div>

        <Toaster {...toaster} closeToaster={this.closeToaster} />

        {imageEditor && (
          <PositionResizeImage
            toggleImageEditor={this.toggleImageEditor}
            updateAvatar={this.updateAvatar}
          />
        )}
      </div>
    );
  }
}

export default ProfileSettings;
