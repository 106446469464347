import React from "react";

const PositionResizeImage = ({ toggleImageEditor, updateAvatar }) => {
  // const [croppie, setCroppie] = useState(null);

  // useEffect(() => {
  //   const el = document.getElementById("avatar-resize");

  //   if (!croppie) {
  //     const croppieEl = new Croppie(el, {
  //       enableExif: true,
  //       viewport: {
  //         width: 200,
  //         height: 200,
  //         type: "circle"
  //       },
  //       boundary: {
  //         width: 300,
  //         height: 300
  //       }
  //     });

  //     croppieEl.bind({
  //       url: require("../../../assets/images/auth-image.png"),
  //       orientation: 4
  //     });

  //     setCroppie(croppieEl);
  //   }
  // });

  // const applyEdit = () => {
  //   //on button click
  //   croppie.result("base64").then(function(base64) {
  //     // do something with cropped base64
  //     updateAvatar(base64);
  //     toggleImageEditor();
  //   });
  // };

  return <div></div>;
};

export default PositionResizeImage;
