import React from "react";
import classNames from "classnames";
import { NavLink} from "react-router-dom";

import { insightsMenu } from "./data";

const InsightsMenu = () => {
  return (
    <div className="secondary-menu page-menu">
      <div className="title">Insights</div>
      <div className="secondary-menu-items">
        {insightsMenu.map(({ label, icon, value }, index) => (
          <NavLink
            key={`settings-menu-${index}`}
            className={classNames("item", {
            //   active: selectedSection === label
            })}
            to={value}
          >
            <div>
              <span className="icon">{icon}</span>
              <span className="text">{label}</span>
            </div>
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default InsightsMenu;
