import React from "react";

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    fill="none"
    viewBox="0 0 30 30"
  >
    <g
      stroke="#4BA3E7"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.773"
      clipPath="url(#rankingPeopleClip0)"
    >
      <path d="M10.568 21.205H3.477a1.773 1.773 0 00-1.773 1.772v5.319h8.864v-7.091zM26.523 21.205h-7.091v7.09h8.863v-5.318a1.773 1.773 0 00-1.773-1.772z"></path>
      <path d="M17.66 14.114H12.34a1.772 1.772 0 00-1.773 1.772v12.41h8.864v-12.41a1.773 1.773 0 00-1.773-1.772zM15 7.91a3.102 3.102 0 100-6.205 3.102 3.102 0 000 6.204zM18.839 10.568a5.044 5.044 0 00-7.682 0M24.457 15.887a3.102 3.102 0 100-6.205 3.102 3.102 0 000 6.205zM28.296 18.546a5.035 5.035 0 00-5.319-1.55M5.543 15.887a3.102 3.102 0 100-6.205 3.102 3.102 0 000 6.205zM1.704 18.546a5.064 5.064 0 013.841-1.773c.499 0 .995.075 1.471.222"></path>
    </g>
    <defs>
      <clipPath id="rankingPeopleClip0">
        <path
          fill="#fff"
          d="M0 0H28.364V28.364H0z"
          transform="translate(.818 .818)"
        ></path>
      </clipPath>
    </defs>
  </svg>
);
