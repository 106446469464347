import React from "react";

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      stroke="#C6C1D0"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.25"
      d="M8.788 2.032a1.63 1.63 0 002.424 0L12 1.167a1.633 1.633 0 012.842 1.177l-.06 1.167a1.631 1.631 0 001.71 1.712l1.166-.06a1.632 1.632 0 011.175 2.842l-.868.783a1.634 1.634 0 000 2.425l.868.783a1.633 1.633 0 01-1.177 2.841l-1.167-.059a1.63 1.63 0 00-1.713 1.714l.059 1.166A1.632 1.632 0 0112 18.833l-.784-.867a1.631 1.631 0 00-2.424 0l-.787.867a1.633 1.633 0 01-2.838-1.173l.06-1.167a1.632 1.632 0 00-1.714-1.713l-1.166.06A1.632 1.632 0 011.167 12l.867-.783a1.633 1.633 0 000-2.424l-.867-.788A1.632 1.632 0 012.34 5.167l1.166.059a1.632 1.632 0 001.715-1.717l-.055-1.167a1.632 1.632 0 012.838-1.175l.783.865z"
    ></path>
    <path
      stroke="#C6C1D0"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.25"
      d="M10 13.75a3.75 3.75 0 100-7.5 3.75 3.75 0 000 7.5z"
    ></path>
  </svg>
);
