import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as moment from "moment";

import { getRequest, postRequest } from "../../../api";

import { getConversationsAction } from "../../../redux/actions/Conversations";
import { getCustomerAction } from "../../../redux/actions/Customers";
import { getGroupsAction } from "../../../redux/actions/Groups";

import LottieAnimation from "../../../components/LottieWeb";
import Avatar from "../../../components/Avatar";
import { CustomerInfo, Conversations } from ".";

import { Location } from "../../../assets/vectors";
import { getInitials, getRequestError } from "../../../utils/functions";
import { conversationsPermissions } from "../../../utils/permissions";

class CustomerDetails extends Component {
  state = {
    currentTab: "in-progress",
    isLoadingCustomerDetails: true,
    customerDetails: {},
    customFormFieldsData: null,
    isUploadingFile: null,
    newFileUploaded: null,
    allAgents: [],
    allUsers: [],
  };

  handleChange = ({ target }) => {
    this.setState({
      customerDetails: {
        ...this.state.customerDetails,
        [target.name]: target.value,
      },
    });
  };

  handleCustomFieldChange = ({ target }) => {
    this.setState({
      customFormFieldsData: {
        ...this.state.customFormFieldsData,
        [target.name]: target.value,
      },
    });
  };

  updateCustomFormData = () => {
    this.setState({
      customFormFieldsData: this.state.customerDetails.custom_fields,
    });
  };

  getCustomerDetails = (id) => {
    this.setState({
      isLoadingCustomerDetails: true,
    });

    this.props.getCustomerAction({ id }).then((res) => {
      this.setState({
        isLoadingCustomerDetails: false,
        customerDetails: res,
      });
    });
  };

  getAllUsers = async () => {
    try {
      const res = await getRequest({
        url: "users",
        token: true,
      });

      this.setState({
        allUsers: res.data.data,
        allAgents: res.data.data.filter(({ roleName }) => roleName === "agent"),
      });
    } catch (error) {
      console.log(error);
    }
  };

  handleFileUpload = async (uploadData, { file }) => {
    const { customFieldsData } = this.props;

    const fileFieldName = customFieldsData?.filter(
      (x) => x?.type === "attachment"
    )?.[0]?.slug;

    if (file) {
      var fileData = new FormData();
      fileData.append("media", file);
      let payload = {};
      payload = fileData;

      this.setState({
        isUploadingFile: true,
      });

      try {
        const res = await postRequest({
          url: "/utils/upload",
          data: payload,
          token: true,
        });
        if (res?.status === 200) {
          this.setState({
            isUploadingFile: false,
            newFileUploaded: true,
            customFormFieldsData: {
              ...this.state.customFormFieldsData,
              [fileFieldName]: res?.data?.data,
            },
          });
        }
      } catch (error) {
        const message = getRequestError(error);
        console.log(message);

        this.setState({
          isUploadingFile: false,
        });
      }
    }
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    this.getCustomerDetails(id);
    this.getAllUsers();
    this.props.getGroupsAction();
  }

  render() {
    const {
      isLoadingCustomerDetails,
      customerDetails,
      customFormFieldsData,
      newFileUploaded,
      isUploadingFile,
      allAgents,
      allUsers,
    } = this.state;
    const { id } = this.props.match.params;
    const { name, lastSeenAt, location } = customerDetails;

    return (
      <div className="insights-details-page insights-page-container customer-details-page">
        {isLoadingCustomerDetails ? (
          <div className="loader-container text-center">
            <LottieAnimation
              path={require("../../../assets/jsons/loader.json")}
              autoplay
            />
          </div>
        ) : (
          <>
            <div className="insights-details-header">
              <div className="header-bg">
                <img
                  src={require("../../../assets/images/auth-bg.png").default}
                  alt=""
                />
                <img
                  src={require("../../../assets/images/auth-bg.png").default}
                  alt=""
                />
                <img
                  src={require("../../../assets/images/auth-bg.png").default}
                  alt=""
                />
                <img
                  src={require("../../../assets/images/auth-bg.png").default}
                  alt=""
                />
                <img
                  src={require("../../../assets/images/auth-bg.png").default}
                  alt=""
                />
                <img
                  src={require("../../../assets/images/auth-bg.png").default}
                  alt=""
                />
              </div>
              <div>
                <div className="customer-avatar">
                  <Avatar text={getInitials(`${name}`)} solid />
                </div>
                <div>
                  <div className="name">{name}</div>
                  <div className="location">
                    <span className="icon">
                      <Location />
                    </span>
                    <span className="text">{location || "N/A"}</span>
                  </div>
                  <div className="status">
                    <span className="text">
                      Last seen{" "}
                      {lastSeenAt ? moment(lastSeenAt).fromNow() : "N/A"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="insights-details-content customer-details-content">
              <CustomerInfo
                customerDetails={customerDetails}
                customFormFieldsData={customFormFieldsData}
                updateCustomFormData={this.updateCustomFormData}
                getCustomerDetails={this.getCustomerDetails}
                handleChange={this.handleChange}
                handleCFChange={this.handleCustomFieldChange}
                handleFileUpload={this.handleFileUpload}
                newFileUploaded={newFileUploaded}
                isUploadingFile={isUploadingFile}
              />
              {(conversationsPermissions.read ||
                conversationsPermissions.viewAll) && (
                <div className="customer-conversations">
                  <Conversations
                    customerId={id}
                    getCustomerDetails={this.getCustomerDetails}
                    allAgents={allAgents}
                    allUsers={allUsers}
                  />
                </div>
              )}
            </div>
            <br />
            <br />
            <br />
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ auth, conversations, groups, customFields }) => ({
  ...auth,
  ...conversations,
  ...groups,
  ...customFields,
});

export default withRouter(
  connect(mapStateToProps, {
    getConversationsAction,
    getCustomerAction,
    getGroupsAction,
  })(CustomerDetails)
);
