import React, { Component } from "react";
import { connect } from "react-redux";

import { resetPasswordAction } from "../../redux/actions/Auth";

import Carousel from "./Carousel";
import { TextField, Button } from "../../components/FormElements";
import FormNotifications from "../../components/Notifications";

import { getFormValues } from "../../utils/functions";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        password: {
          value: "",
          valid: false,
        },
        confirmPassword: {
          value: "",
          valid: false,
        },
      },
      token: props.token,
    };
  }

  onChange = ({ target }, valid) => {
    const { name, value } = target;

    this.setState({
      formData: {
        ...this.state.formData,
        [name]: { value, valid },
      },
    });
  };

  validateForm = () => {
    return Object.values(this.state.formData).every((val) => !!val);
  };

  resetPassword = (e) => {
    e.preventDefault();
    const { token } = this.state;
    const { confirmPassword, ...rest } = this.state.formData;

    const formData = getFormValues(rest);

    this.props.resetPasswordAction({ ...formData, token });
  };

  render() {
    const {
      isResetPasswordLoading,
      setActiveForm,
      resetPasswordOutcome,
      resetPasswordMessage,
    } = this.props;

    return (
      <div className="register_page_container">
        <div className="side left_side">
          <Carousel />
        </div>

        <div className="side form_container">
          <div className="title">Reset Password</div>
          <div className="description">Enter your new password below.</div>

          <form className="form" onSubmit={this.resetPassword}>
            <TextField
              className="mb-25"
              type="password"
              name="password"
              label="Password"
              onChange={(e, valid) => this.onChange(e, valid)}
            />
            <TextField
              className="mb-25"
              type="password"
              name="confirmPassword"
              label="Confirm Password"
              onChange={(e, valid) => this.onChange(e, valid)}
            />

            <div className="login-actions mb-25">
              <span
                className="primary-link"
                onClick={() => setActiveForm("login")}
              >
                Back to Login
              </span>
            </div>
            <div className="mb-25">
              <Button
                disabled={!this.validateForm() || isResetPasswordLoading}
                className="primary-button full small"
              >
                Reset Password
              </Button>
            </div>
            <div className="sign-up-link">
              Don’t have an account yet?{" "}
              <span
                className="primary-link bold"
                onClick={() => window.location.assign("/sign-up")}
              >
                Register
              </span>
            </div>

            {resetPasswordOutcome === "success" && (
              <>
                <br />
                <FormNotifications
                  type="success"
                  message={resetPasswordMessage}
                />
              </>
            )}
            {resetPasswordOutcome === "error" && (
              <>
                <br />
                <FormNotifications
                  type="error"
                  message={resetPasswordMessage}
                />
              </>
            )}
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => auth;

export default connect(mapStateToProps, { resetPasswordAction })(
  ForgotPassword
);
