import React, { Component, createRef } from "react";
import classNames from "classnames";
import * as moment from "moment";
import { decode } from "html-entities";

import AudioPlayer from "../../../components/AudioPlayer";

import { AngleDown, File, Cart } from "../../../assets/vectors";

import {
  getInitials,
  getExtensionType,
  getFileName,
  // urlify,
  capitalize,
  formatNumber,
} from "../../../utils/functions";
import EmailMessage from "./EmailMessage";
import { APP_DLR_ORDER } from "../../../utils/constants";

class ChatMessage extends Component {
  state = {
    messageOptions: false,
  };

  messageContainerRef = createRef();

  openMessageOptions = () => {
    this.setState(
      {
        messageOptions: true,
      },
      () => document.addEventListener("click", this.closeMessageOptions)
    );
  };

  closeMessageOptions = () => {
    this.setState(
      {
        messageOptions: false,
      },
      () => document.removeEventListener("click", this.closeMessageOptions)
    );
  };

  getMessageContainerTop = () => {
    return this.messageContainerRef
      ? this.messageContainerRef.current
        ? this.messageContainerRef.current.getBoundingClientRect().top
        : 0
      : 0;
  };

  getOrderSubTotal = (orderContent) => {
    const productItems = orderContent?.product_items;

    if (productItems) {
      let array = productItems || [];
      let sum = 0;

      array.map((x) => (sum += x?.item_price * x?.quantity));

      return `${productItems?.[0]?.currency} ${formatNumber(sum, 2)}`;
    } else {
      return 0;
    }
  };

  textReplacer = (matched) => {
    let withProtocol = matched;

    if (!withProtocol.startsWith("http")) {
      withProtocol = "http://" + matched;
    }

    const newStr = `<a class="message-link" href="${withProtocol}" target="_blank">${matched}</a>`;

    return newStr;
  };

  getMessageContent = (message) => {
    var content = "";
    content = decode(message, { level: "html5" });
    // eslint-disable-next-line no-useless-escape
    const linkRegex = /(https?\:\/\/)?(www\.)?[^\s]+\.[^\s]+/g;

    const modifiedStr = content?.replace(linkRegex, this.textReplacer);

    return modifiedStr;
  };

  getDlrIcon = (status) => {
    switch (status) {
      case "sending":
        return "https://res.cloudinary.com/dqhhhjr74/image/upload/v1699031532/or-mrkt/sending_jfoz4x.png";
      case "sent":
        return require("../../../assets/images/icons/check.png").default;
      case "delivered":
        return require("../../../assets/images/icons/read.png").default;
      case "read":
        return require("../../../assets/images/icons/blue-read.png").default;
      default:
        return require("../../../assets/images/icons/error.png").default;
    }
  };

  messageStatusIcon = (msgDlr) => {
    return (
      <span className="msg-dlr">
        <img
          src={this.getDlrIcon(msgDlr?.status)}
          className={msgDlr?.status}
          alt=""
        />
      </span>
    );
  };

  render() {
    const { messageOptions } = this.state;
    const {
      id,
      row_id,
      chatMessageRef,
      highlightedMessageId,
      message,
      attachments,
      Channel,
      customer,
      // newDlr,
      sender,
      content,
      contentType,
      template,
      imageUrl,
      videoUrl,
      documentUrl,
      audioUrl,
      rawData,
      fileName,
      createdAt,
      authFirstChild,
      firstChild,
      lastChild,
      formTop,
      sendMessage,
      openFilePreview,
      quoted_message,
      selectOrder,
      messageSendingStatus,
      messageSendingResponse,
      lastAuthMessage,
      retryFailedSavedMsg,
      deleteFailedSavedMsg,
    } = this.props;
    const { authUser, name } = sender || {};
    const topDifference = formTop - this.getMessageContainerTop();
    const status = "";

    const isLastAuthMessage = lastAuthMessage?.content === content;

    const orderContent =
      (contentType === "ORDER" && JSON.parse(content)) || null;

    const contactContent =
      (contentType === "CONTACTS" && JSON.parse(content)) || null;

    const locationContent =
      (contentType === "LOCATION" && JSON.parse(content)) || null;

    const quotedOrderContent =
      (quoted_message?.content?.includes(`"catalog_id":`) &&
        JSON.parse(quoted_message?.content)) ||
      null;

    const order = APP_DLR_ORDER;
    const allMsgDlr = message?.dlr?.length
      ? message?.dlr
      : [{ status: "sent", timestamp: new Date() }];
    allMsgDlr?.sort(
      (a, b) =>
        (order[a.status] || Number.MAX_VALUE) -
        (order[b.status] || Number.MAX_VALUE)
    );

    const msgDlr = allMsgDlr?.[0] || null;

    const failedDlrReason = msgDlr?.error?.title || msgDlr?.error?.description;

    const OrderMessageView = ({ msgId, order }) => {
      return (
        <div className="message-order">
          <h6>
            <Cart /> {order?.product_items?.length} Item(s)
          </h6>
          <p>{this.getOrderSubTotal(order)} (estimated total)</p>

          <button onClick={() => selectOrder(msgId)}>
            View received items
          </button>

          <hr />
        </div>
      );
    };

    const ContactMessageView = ({ msg }) => {
      return (
        <div className="message-contact">
          {msg?.map((item, i) => (
            <div key={i} className="item">
              <h6>{item?.name?.formatted_name}</h6>
              <p>
                {item?.phones?.map((num, i) => (
                  <span key={i}>{num?.phone}</span>
                ))}
              </p>

              <hr />
            </div>
          ))}
        </div>
      );
    };

    const LocationMessageView = ({ msg }) => {
      const openLocation = () => {
        window.open(
          `https://www.google.com/maps/search/?api=1&query=${msg?.latitude}%2C${msg?.longitude}`
        );
      };

      return (
        <div className="message-contact">
          <img
            src="https://img.freepik.com/premium-vector/map-with-red-pin-location_78072-1058.jpg"
            alt="Map marker"
            onClick={openLocation}
          />
          <h6>Ajah Market</h6>

          <hr />
        </div>
      );
    };

    const circleSlashImg =
      require("../../../assets/images/icons/circle-slash.png").default;

    return (
      <>
        <div
          id={id}
          ref={chatMessageRef}
          className={classNames(`chat-message-container ${status}`, {
            authUser,
            authFirstChild,
            firstChild,
            lastChild,
            highlighted:
              parseInt(highlightedMessageId) === row_id ? true : false,
          })}
        >
          {!authUser && (
            <div className="avatar">{name ? getInitials(name) : ""}</div>
          )}
          <div
            className={classNames("message-container", {
              messageOptions,
              emailMessageContainer: Channel?.medium === "EMAIL",
            })}
            ref={this.messageContainerRef}
          >
            <div className="message-options" onClick={this.openMessageOptions}>
              <AngleDown />
            </div>
            {messageOptions && (
              <div
                className="message-options-menu"
                style={{
                  top: topDifference > 200 ? "50px" : "calc(-100% - 60px)",
                }}
              >
                <span>Copy message</span>
                {/* <span>Forward message</span>
                <span>Mark as to-do item</span>
                <span>Float on top</span> */}
              </div>
            )}
            <div className="message-content">
              {contentType === "TEMPLATE" && content?.includes(`"content":`) ? (
                <span
                  className="message-content-itself"
                  dangerouslySetInnerHTML={{
                    __html: decode(JSON.parse(content)?.content),
                  }}
                ></span>
              ) : contentType === "TEMPLATE" &&
                !content &&
                template?.includes(`"formatted":`) ? (
                <>
                  {decode(JSON.parse(template)?.formatted?.includes("OTP")) ? (
                    <span className="auth-message">
                      <img src={circleSlashImg} alt="Hidden" /> This is an
                      authenticated message
                    </span>
                  ) : (
                    <span
                      className="message-content-itself"
                      dangerouslySetInnerHTML={{
                        __html: decode(JSON.parse(template)?.formatted),
                      }}
                    ></span>
                  )}
                </>
              ) : (
                <>
                  {quoted_message && (
                    <div className="quoted-message-content">
                      <div className="inner-content">
                        {quotedOrderContent ? (
                          <OrderMessageView
                            msgId={quoted_message?.id}
                            order={quotedOrderContent}
                          />
                        ) : (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: quoted_message.content,
                            }}
                          ></span>
                        )}
                        {quoted_message.contentType === "IMAGE" && (
                          <span
                            className="message-media"
                            onClick={() =>
                              openFilePreview(
                                quoted_message.contentType,
                                quoted_message.imageUrl
                              )
                            }
                          >
                            <img src={quoted_message.imageUrl} alt="" />
                          </span>
                        )}
                        {quoted_message.contentType === "VIDEO" && (
                          <span
                            className="message-media"
                            onClick={() =>
                              openFilePreview(
                                quoted_message.contentType,
                                quoted_message.videoUrl
                              )
                            }
                          >
                            <video controls={false}>
                              <source src={quoted_message.videoUrl}></source>
                            </video>
                          </span>
                        )}
                        {quoted_message.contentType === "DOCUMENT" &&
                          (getExtensionType(quoted_message.documentUrl) ===
                          "mp3" ? (
                            <div className="message-audio">
                              <AudioPlayer src={quoted_message.documentUrl} />
                            </div>
                          ) : (
                            <div className="message-file">
                              <span className="icon">
                                <File />
                              </span>
                              <a
                                href={quoted_message.documentUrl || "#"}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {quoted_message.documentUrl}
                              </a>
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                  {Channel?.medium === "EMAIL" ? (
                    <EmailMessage
                      attachments={attachments}
                      openFilePreview={openFilePreview}
                      customer={customer}
                      sender={sender}
                      subject={message?.subject}
                      content={content}
                      createdAt={createdAt}
                    />
                  ) : contentType === "ORDER" && orderContent ? (
                    <OrderMessageView msgId={id} order={orderContent} />
                  ) : contentType === "CONTACTS" && contactContent ? (
                    <ContactMessageView msg={contactContent} />
                  ) : contentType === "LOCATION" && locationContent ? (
                    <LocationMessageView msg={locationContent} />
                  ) : (
                    <span
                      className="message-content-itself"
                      dangerouslySetInnerHTML={{
                        __html: this.getMessageContent(content),
                      }}
                    ></span>
                  )}
                  {(contentType === "IMAGE" || contentType === "STICKER") &&
                    Channel?.medium !== "EMAIL" && (
                      <span
                        className="message-media"
                        onClick={() =>
                          openFilePreview(contentType, imageUrl || rawData)
                        }
                      >
                        <img src={imageUrl || rawData} alt="" />
                      </span>
                    )}
                  {contentType === "VIDEO" && Channel?.medium !== "EMAIL" && (
                    <span
                      className="message-media"
                      onClick={() =>
                        openFilePreview(contentType, videoUrl || rawData)
                      }
                    >
                      <video controls={false}>
                        <source src={videoUrl || rawData}></source>
                      </video>
                    </span>
                  )}
                  {contentType === "DOCUMENT" &&
                    Channel?.medium !== "EMAIL" && (
                      <div className="message-file">
                        <span className="icon">
                          <File />
                        </span>
                        <a
                          href={documentUrl || "#"}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {documentUrl ? getFileName(documentUrl) : fileName}
                        </a>
                      </div>
                    )}
                  {(contentType === "VOICE" || contentType === "AUDIO") &&
                    Channel?.medium !== "EMAIL" && (
                      <div className="message-audio">
                        <AudioPlayer src={audioUrl || rawData} />
                      </div>
                    )}
                </>
              )}
            </div>
            <div className="message-footer">
              {Channel?.medium !== "EMAIL" && (
                <div className="text-capitalize">
                  <span>{`Via ${capitalize(Channel?.medium)}`}</span>
                </div>
              )}
              <div>
                {Channel?.medium !== "EMAIL" && (
                  <span>{moment(createdAt).format("HH:mm")}</span>
                )}
                {isLastAuthMessage && messageSendingStatus === "sending" ? (
                  <span>Sending...</span>
                ) : isLastAuthMessage && messageSendingStatus === "sent" ? (
                  this.messageStatusIcon("sent")
                ) : (
                  authUser && msgDlr && this.messageStatusIcon(msgDlr)
                )}
              </div>
            </div>
            {failedDlrReason && (
              <div className="failed_dlr_reason">Reason: {failedDlrReason}</div>
            )}
            {!message.id && (
              <div className="message-unsent-actions">
                <p className="retry" onClick={retryFailedSavedMsg}>
                  Retry
                </p>
                <p className="delete" onClick={deleteFailedSavedMsg}>
                  Delete
                </p>
              </div>
            )}
          </div>
          {authUser && (
            <div className="avatar" title={name}>
              {name ? getInitials(name) : ""}
            </div>
          )}
        </div>
        {isLastAuthMessage && messageSendingStatus === "failed" && (
          <div className="failed-action">
            {messageSendingResponse}{" "}
            <span onClick={() => sendMessage(true, message)}>Try again</span>
          </div>
        )}
      </>
    );
  }
}

export default ChatMessage;
