import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

import { addToTeamAction } from "../../../redux/actions/Teams";

import { Button } from "../../../components/FormElements";
import Modal from "../../../components/Modal";
import FormNotifications from "../../../components/Notifications";

const AddChannelsForm = ({ toggleForm, teamData, channelOptions }) => {
  const dispatch = useDispatch();

  const { updateTeamOutcome, updateTeamMessage, isUpdatingTeam } = useSelector(
    (state) => state.teams
  );

  const [selectedChannels, setSelectedChannels] = useState([]);

  const isFormValid = !isUpdatingTeam && selectedChannels?.length > 0;

  const submitForm = (e) => {
    e.preventDefault();

    const payload = {
      channels: selectedChannels?.map((x) => x?.value),
    };

    dispatch(addToTeamAction(payload, teamData?.id, "channels"));
  };

  let options = channelOptions;

  let teamChannels = teamData?.Channels?.map((x) => x?.id);
  options = channelOptions?.filter((x) => !teamChannels?.includes(x?.value));

  return (
    <Modal closeModal={() => toggleForm()}>
      <div className="modal-content">
        <div className="modal-title">Add Channels to Team</div>
        <div className="modal-form saved-response-form">
          <form onSubmit={submitForm}>
            <div className="input-container mb-50">
              <label>Channels</label>
              <Select
                classNamePrefix="select_container"
                value={selectedChannels}
                options={options}
                onChange={(selections) => setSelectedChannels(selections)}
                isClearable
                isMulti
              />
            </div>
            <div className="modal-form-action">
              <Button
                type="button"
                className="secondary-button"
                onClick={() => toggleForm()}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="primary-button"
                disabled={!isFormValid}
              >
                Add Channels
              </Button>
            </div>

            {updateTeamOutcome && (
              <FormNotifications
                type={updateTeamOutcome}
                message={updateTeamMessage}
              />
            )}
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default AddChannelsForm;
