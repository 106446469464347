import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Toaster from "../../../components/Toaster";

import AccountInformation from "./sections/AccountInformation";
import ApiConfiguration from "./sections/ApiConfiguration";
import Notifications from "./sections/Notifications";

import {
  getFirmConfigAction,
  getFirmAccountInfoAction,
} from "../../../redux/actions/AccountSettings";

const AccountSettings = () => {
  const dispatch = useDispatch();
  const {
    isLoadingAccountSettings,
    accountSettingsData,
    isSubmittingAccountSettings,
  } = useSelector((state) => state.accountSettings);

  const [activeTab, setActiveTab] = useState(1);
  const [toaster, setToaster] = useState({});

  useEffect(() => {
    dispatch(getFirmConfigAction());
    dispatch(getFirmAccountInfoAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const copyCode = (textToCopy) => {
    navigator.clipboard.writeText(textToCopy);
    openToaster("success", "Copied to clipboard!");
  };

  const openToaster = (status, content) => {
    setToaster({
      open: "open",
      status,
      content,
    });
  };

  const closeToaster = () => {
    setToaster({
      ...toaster,
      open: "closed",
    });
  };

  const tabs = [
    { id: 1, label: "Account Information" },
    { id: 2, label: "API Keys & Webhooks" },
    { id: 3, label: "Notifications" },
  ];

  return (
    <div className="settings-page-container account-settings-container">
      <div className="page-header">
        <span className="title">Account Settings</span>
      </div>
      <div className="settings-page-info">
        <div className="nav_tabs">
          {tabs?.map((tab, i) => (
            <p
              key={i}
              className={`tab${activeTab === tab?.id ? " active" : ""}`}
              onClick={() => setActiveTab(tab?.id)}
            >
              {tab?.label}
            </p>
          ))}
        </div>
        {activeTab === 1 && <AccountInformation openToaster={openToaster} />}
        {activeTab === 2 && (
          <ApiConfiguration
            isLoadingAccountSettings={isLoadingAccountSettings}
            accountSettingsData={accountSettingsData}
            isSubmittingAccountSettings={isSubmittingAccountSettings}
            openToaster={openToaster}
            copyCode={copyCode}
          />
        )}
        {activeTab === 3 && (
          <Notifications
            isLoadingAccountSettings={isLoadingAccountSettings}
            accountSettingsData={accountSettingsData}
            isSubmittingAccountSettings={isSubmittingAccountSettings}
            openToaster={openToaster}
          />
        )}
      </div>

      <Toaster {...toaster} closeToaster={closeToaster} />
    </div>
  );
};

export default AccountSettings;
