import React from "react";

export default () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="#474F58"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
        d="M.75 3.792h2.433M.75 9.875h2.433M.75 14.742h2.433M14.742.75H3.183c-.671 0-1.216.545-1.216 1.217v15.816c0 .672.545 1.217 1.216 1.217h11.559c.672 0 1.216-.545 1.216-1.217V1.967c0-.672-.544-1.217-1.216-1.217z"
      ></path>
      <path
        stroke="#474F58"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
        d="M11.7 4.4H7.442c-.672 0-1.216.545-1.216 1.217v1.825c0 .672.544 1.216 1.216 1.216H11.7c.672 0 1.216-.544 1.216-1.216V5.617c0-.672-.544-1.217-1.216-1.217zM15.958 1.967v10.95h1.825A1.216 1.216 0 0019 11.7V3.183a1.217 1.217 0 00-1.217-1.216h-1.825zM15.958 5.617H19M15.958 9.267H19"
      ></path>
    </svg>
)