import React from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as moment from "moment";

import Table from "../../../components/Table";

const Tickets = ({
  isLoadingConversations,
  assignedConversations,
  queueConversations,
  resolvedConversations,
  currentTab,
  switchTab,
  conversationsInsightsData,
}) => {
  const conversationsData =
    currentTab === "in-progress"
      ? assignedConversations?.data || []
      : currentTab === "open"
      ? queueConversations?.data || []
      : resolvedConversations?.data || [];

  const columns = [
    {
      id: "",
      label: "",
      render: ({ customer }) => {
        const { name, phone } = customer;
        return (
          <div className="customer-info">
            <span className="name">{name || phone}</span>
          </div>
        );
      },
    },
    {
      id: "",
      label: "",
      render: () => <span className="message-tag">Support</span>,
    },
    {
      id: "",
      label: "",
      render: ({ Messages }) => {
        const sortedMessages = Messages.sort((a, b) => {
          const dateA = new Date(a.createdAt);
          const dateB = new Date(b.createdAt);
          return dateA - dateB;
        });

        return (
          <span className="ellipsis preview-message">
            {Messages.length ? sortedMessages[Messages.length - 1].content : ""}
          </span>
        );
      },
    },
    {
      id: "",
      label: "",
      render: ({ updatedAt }) => (
        <span className="message-date">
          {moment(updatedAt).format("HH:mm")} |{" "}
          {moment(updatedAt).format("DD MMMM, YYYY")}
        </span>
      ),
    },
    {
      id: "",
      label: "",
      render: ({ id }) => (
        <Link
          className="primary-link semi-bold blue"
          to={`/conversations/${id}`}
        >
          Open Message
        </Link>
      ),
    },
  ];
  return (
    <div className="tickets-table">
      <span className="insights-section-title">Tickets</span>
      <div className="table-container">
        <div className="table-tabs">
          <div
            className={classNames("table-tab-item", {
              active: currentTab === "in-progress",
            })}
            onClick={() => switchTab("in-progress")}
          >
            In-progress
            {/* <span className="count">5</span> */}
          </div>
          <div
            className={classNames("table-tab-item", {
              active: currentTab === "open",
            })}
            onClick={() => switchTab("open")}
          >
            Open Tickets
            {/* <span className="count">3</span> */}
          </div>
          <div
            className={classNames("table-tab-item", {
              active: currentTab === "closed",
            })}
            onClick={() => switchTab("closed")}
          >
            Closed Tickets
            {/* <span className="count">44</span> */}
          </div>
        </div>

        <Table
          loading={isLoadingConversations}
          columns={columns}
          rows={conversationsData}
          noHeader
        />
      </div>
    </div>
  );
};

const mapStateToProps = ({ conversations, insights }) => ({
  ...conversations,
  ...insights,
});

export default connect(mapStateToProps, {})(Tickets);
