import React, { Component } from "react";
import { connect } from "react-redux";
import * as shallowequal from "shallowequal";

import {
  getGroupsAction,
  createGroupsAction,
  updateGroupsAction,
} from "../../../redux/actions/Groups";

import { Button, TextField } from "../../../components/FormElements";
import Modal from "../../../components/Modal";
import FormNotifications from "../../../components/Notifications";

import { patchFormValues, getFormValues } from "../../../utils/functions";

const initialFormData = {
  name: {
    value: "",
    valid: false,
  },
};

class GroupForm extends Component {
  constructor(props) {
    super(props);

    const { groupData } = props;

    const formData = patchFormValues(initialFormData, groupData);

    this.state = {
      formData: formData
        ? formData
        : { ...JSON.parse(JSON.stringify(initialFormData)) },
    };
  }

  handleChange = ({ target }, valid) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [target.name]: {
          value: target.value,
          valid,
        },
      },
    });
  };

  checkFormValidity = () => {
    return Object.values(this.state.formData).every(({ valid }) => valid);
  };

  submitForm = (e) => {
    e.preventDefault();
    const {
      newlyCreatedGroup,
      groupData,
      createGroupsAction,
      updateGroupsAction,
      toggleGroupForm,
    } = this.props;

    const formData = getFormValues(this.state.formData);
    groupData
      ? updateGroupsAction(formData, groupData.id, toggleGroupForm)
      : createGroupsAction(formData, toggleGroupForm).then((res) => {
          newlyCreatedGroup && newlyCreatedGroup(res);
          this.props.getGroupsAction({
            offset: 0,
            limit: 20,
          });
        });
  };

  componentDidUpdate(prevProps) {
    const { groupData, createGroupsOutcome } = this.props;

    if (
      !shallowequal(prevProps.groupData, groupData) &&
      Object.keys(groupData).length
    ) {
      const formData = patchFormValues(initialFormData, groupData);

      this.setState({
        formData,
      });
    }

    if (
      prevProps.createGroupsOutcome !== createGroupsOutcome &&
      createGroupsOutcome === "success"
    ) {
      this.setState({
        formData: { ...initialFormData },
      });
    }
  }

  render() {
    const { formData } = this.state;
    const {
      isCreatingGroup,
      isUpdatingGroup,
      toggleGroupForm,
      createGroupsOutcome,
      createGroupsMessage,
      updateGroupsOutcome,
      updateGroupsMessage,
      groupData,
    } = this.props;

    const { name } = formData;

    return (
      <Modal xsmall>
        <div className="modal-content">
          <div className="modal-title">{groupData ? "Edit" : "New"} Group</div>
          <div className="modal-form tag-form">
            <form onSubmit={this.submitForm}>
              <TextField
                label="Name"
                name="name"
                value={name.value}
                onChange={(e, valid) => this.handleChange(e, valid)}
                className="mb-25"
              />
              <div className="modal-form-action">
                <Button
                  type="button"
                  className="secondary-button"
                  onClick={() => toggleGroupForm()}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  className="primary-button"
                  disabled={
                    !this.checkFormValidity() ||
                    isCreatingGroup ||
                    isUpdatingGroup
                  }
                >
                  {isCreatingGroup || isUpdatingGroup
                    ? "Processing..."
                    : groupData
                    ? "Save Changes"
                    : "Add group"}
                </Button>
              </div>

              {createGroupsOutcome === "error" && (
                <FormNotifications type="error" message={createGroupsMessage} />
              )}
              {updateGroupsOutcome === "error" && (
                <FormNotifications type="error" message={updateGroupsMessage} />
              )}
            </form>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = ({ groups }) => groups;

export default connect(mapStateToProps, {
  getGroupsAction,
  createGroupsAction,
  updateGroupsAction,
})(GroupForm);
