import React, { Suspense, lazy } from "react";
import { withRouter, Switch, Route, Redirect } from "react-router-dom";

const ConversationInsights = lazy(() => import("./ConversationInsights"));
const AgentsInsights = lazy(() => import("./AgentsInsights"));
const CustomersInsights = lazy(() => import("./CustomersInsights"));
const ChatbotInsights = lazy(() => import("./ChatbotInsights"));
const LogsInsights = lazy(() => import("./LogsInsights"));
const InsightsMenu = lazy(() => import("./InsightsMenu"));

const Insights = ({ match }) => {
  return (
    <>
      <Suspense fallback={<div>...</div>}>
        <div className="page-container">
          <InsightsMenu />
          <div className="page-content">
            <Switch>
              <Redirect
                exact
                path={`${match.url}/`}
                to={`${match.url}/conversations`}
              />
              <Route
                exact
                path={`${match.url}/conversations`}
                component={ConversationInsights}
              />
              <Route
                exact
                path={`${match.url}/agents`}
                component={AgentsInsights}
              />
              <Route
                exact
                path={`${match.url}/customers`}
                component={CustomersInsights}
              />
              <Route
                exact
                path={`${match.url}/chatbot`}
                component={ChatbotInsights}
              />
              <Route
                exact
                path={`${match.url}/logs`}
                component={LogsInsights}
              />
            </Switch>
          </div>
        </div>
      </Suspense>
    </>
  );
};

export default withRouter(Insights);
