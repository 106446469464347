import React from "react";

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <g
      stroke="#C6C1D0"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.25"
      clipPath="url(#peopleclip0)"
    >
      <path d="M10 9.375a4.375 4.375 0 100-8.75 4.375 4.375 0 000 8.75z"></path>
      <path d="M6.242 2.792v0A7.26 7.26 0 0011.458 5v0a7.25 7.25 0 002.857-.583M1.875 19.375v.001a8.13 8.13 0 015.624-7.734l2.5 2.108 2.5-2.108h0a8.128 8.128 0 015.626 7.733"></path>
      <path d="M14.712 12.755v0a8.09 8.09 0 00-2.212-1.113L10 13.75l3.75 2.5.962-3.495zM10 13.75l-2.5-2.108h0a8.105 8.105 0 00-2.21 1.113l.96 3.495 3.75-2.5zM10 19.375V13.75M12.5 18.75H15"></path>
    </g>
    <defs>
      <clipPath id="peopleclip0">
        <path fill="#fff" d="M0 0H20V20H0z"></path>
      </clipPath>
    </defs>
  </svg>
);
