import mixpanel from "mixpanel-browser";
import {
  getRequest,
  postRequest,
  patchRequest,
  deleteRequest,
} from "../../api";

import {
  GET_BILLING_LOADER,
  GET_BILLING_SUBMITTER,
  GET_INVOICES_RESPONSE,
  GET_WALLET_HISTORY_RESPONSE,
  GET_CARDS_RESPONSE,
  GET_SUBSCRIPTIONS_RESPONSE,
  GET_BILLING_RESPONSE,
  GET_PLANS_RESPONSE,
  GET_FIRM_PLAN_RESPONSE,
} from "../constants";

import { getRequestError } from "../../utils/functions";

export const getBillingLoader = (isLoadingBilling) => ({
  type: GET_BILLING_LOADER,
  isLoadingBilling,
});

export const getBillingSubmitter = (isSubmittingBilling) => ({
  type: GET_BILLING_SUBMITTER,
  isSubmittingBilling,
});

export const getSubcriptionsResponse = (
  subscriptionOutcome,
  subscriptionData,
  subscriptionMessage
) => ({
  type: GET_SUBSCRIPTIONS_RESPONSE,
  subscriptionOutcome,
  subscriptionData,
  subscriptionMessage,
});

export const getCardsResponse = (cardsOutcome, cardsData, cardsMessage) => ({
  type: GET_CARDS_RESPONSE,
  cardsOutcome,
  cardsData,
  cardsMessage,
});

export const getInvoicesResponse = (
  invoicesOutcome,
  invoicesData,
  invoicesMessage
) => ({
  type: GET_INVOICES_RESPONSE,
  invoicesOutcome,
  invoicesData,
  invoicesMessage,
});

export const getWalletHistoryResponse = (
  walletHistoryOutcome,
  walletHistoryData,
  walletHistoryMessage
) => ({
  type: GET_WALLET_HISTORY_RESPONSE,
  walletHistoryOutcome,
  walletHistoryData,
  walletHistoryMessage,
});

export const getBillingResponse = (
  billingOutcome,
  billingData,
  billingMessage
) => ({
  type: GET_BILLING_RESPONSE,
  billingOutcome,
  billingData,
  billingMessage,
});

export const getPlansResponse = (plansOutcome, plansData, plansMessage) => ({
  type: GET_PLANS_RESPONSE,
  plansOutcome,
  plansData,
  plansMessage,
});

export const getFirmPlanResponse = (
  firmPlanOutcome,
  firmPlanData,
  firmPlanMessage
) => ({
  type: GET_FIRM_PLAN_RESPONSE,
  firmPlanOutcome,
  firmPlanData,
  firmPlanMessage,
});

export const getFirmActiveSubscriptionAction = (params = {}) => {
  return async (dispatch) => {
    dispatch(getBillingLoader(true));

    try {
      const res = await getRequest({
        url: "/plans/subscriptions/insight",
        token: true,
      });

      dispatch(getSubcriptionsResponse("success", res.data.data || {}, ""));
      dispatch(getBillingLoader(false));
    } catch (error) {
      const message = getRequestError(error);

      dispatch(getSubcriptionsResponse("error", {}, message));
      dispatch(getBillingLoader(false));
    }
  };
};

export const getFirmCardsAction = (params = {}) => {
  return async (dispatch) => {
    dispatch(getBillingLoader(true));

    try {
      const res = await getRequest({
        url: "/payment/cards",
        token: true,
      });

      dispatch(getCardsResponse("success", res.data.data || [], ""));
      dispatch(getBillingLoader(false));
    } catch (error) {
      const message = getRequestError(error);

      dispatch(getCardsResponse("error", [], message));
      dispatch(getBillingLoader(false));
    }
  };
};

export const getFirmInvoicesAction = (params = {}) => {
  return async (dispatch) => {
    dispatch(getBillingLoader(true));

    try {
      const res = await getRequest({
        url: "/invoice",
        params,
        token: true,
      });

      dispatch(getInvoicesResponse("success", res.data || {}, ""));
      dispatch(getBillingLoader(false));
    } catch (error) {
      const message = getRequestError(error);

      dispatch(getInvoicesResponse("error", [], message));
      dispatch(getBillingLoader(false));
    }
  };
};

export const getFirmWalletHistoryAction = (params = {}) => {
  return async (dispatch) => {
    dispatch(getBillingLoader(true));

    try {
      const res = await getRequest({
        url: "/units/logs",
        params,
        token: true,
      });

      dispatch(getWalletHistoryResponse("success", res.data || {}, ""));
      dispatch(getBillingLoader(false));
    } catch (error) {
      const message = getRequestError(error);

      dispatch(getWalletHistoryResponse("error", [], message));
      dispatch(getBillingLoader(false));
    }
  };
};

export const getPlansDataAction = (params = {}) => {
  return async (dispatch) => {
    dispatch(getBillingLoader(true));

    try {
      const res = await getRequest({
        url: "/plans",
        token: true,
      });

      dispatch(getPlansResponse("success", res.data.data || [], ""));
      dispatch(getBillingLoader(false));
      return res?.data?.data;
    } catch (error) {
      const message = getRequestError(error);

      dispatch(getPlansResponse("error", [], message));
      dispatch(getBillingLoader(false));
    }
  };
};

export const getFirmPlanAction = (params = {}) => {
  return async (dispatch) => {
    dispatch(getBillingLoader(true));

    try {
      const res = await getRequest({
        url: `/plans/${params?.firm_id}`,
        token: true,
      });

      dispatch(getFirmPlanResponse("success", res?.data?.data, ""));
      dispatch(getBillingLoader(false));
    } catch (error) {
      const message = getRequestError(error);

      dispatch(getPlansResponse("error", [], message));
      dispatch(getBillingLoader(false));
    }
  };
};

export const getFirmFreeWhatsAppConversationsCount = (params = {}) => {
  return async (dispatch) => {
    dispatch(getBillingLoader(true));

    try {
      const res = await getRequest({
        url: "/messagelogs/new-conversation/count",
        token: true,
      });

      return res?.data?.data;
    } catch (error) {
      const message = getRequestError(error);
      console.log(message);
    }
  };
};

export const postFirmPlanCostingAction = (data) => {
  return async (dispatch) => {
    dispatch(getBillingLoader(true));

    try {
      const res = await postRequest({
        url: "/firms/upgrade/cost",
        data,
        token: true,
      });

      dispatch(getBillingLoader(false));
      return res?.data?.data;
    } catch (error) {
      const message = getRequestError(error);

      dispatch(getPlansResponse("error", [], message));
      dispatch(getBillingLoader(false));
    }
  };
};

export const postFirmSubscribeToPlanAction = (data) => {
  return async (dispatch) => {
    dispatch(getBillingLoader(true));

    try {
      const res = await postRequest({
        url: "/payment/initialize-subscription",
        data,
        token: true,
      });

      dispatch(getBillingLoader(false));
      mixpanel.track("Initialize New Plan");
      return res?.data;
    } catch (error) {
      const message = getRequestError(error);

      dispatch(getBillingLoader(false));
      return { message };
    }
  };
};

export const postPayFromWalletAction = (id) => {
  return async () => {
    try {
      const res = await postRequest({
        url: `/invoice/${id}/wallet`,
        token: true,
      });

      return res?.data;
    } catch (error) {
      const message = getRequestError(error);
      return message;
    }
  };
};

export const patchActivateCardAction = (data) => {
  return async (dispatch) => {
    dispatch(getBillingLoader(true));

    try {
      const res = await patchRequest({
        url: `/payment/cards/${data?.id}`,
        data: { activate: true },
        token: true,
      });

      return res?.data?.data;
    } catch (error) {
      const message = getRequestError(error);

      dispatch(getPlansResponse("error", [], message));
      dispatch(getBillingLoader(false));
    }
  };
};

export const deleteCardAction = (data) => {
  return async (dispatch) => {
    dispatch(getBillingLoader(true));

    try {
      const res = await deleteRequest({
        url: `/payment/cards/${data?.id}`,
        token: true,
      });

      return res?.data;
    } catch (error) {
      const message = getRequestError(error);

      dispatch(getPlansResponse("error", [], message));
      dispatch(getBillingLoader(false));
    }
  };
};
