import React from "react";
import mimeTypes from "mime-types";

import { uploadData } from "../../utils/functions";
import { ALL_SUPPORTED_EXTENSIONS } from "../../utils/constants";

let File = (
  { className, onFileLoad, onFileError, disabled, icon, accept },
  ref
) => {
  const handleFile = (event) => {
    event.preventDefault();

    const reader = new FileReader();
    const file = event.target.files[0];
    const extension = file?.name?.split(".")?.pop()?.toLowerCase() || "";

    if (
      ALL_SUPPORTED_EXTENSIONS.includes(extension) &&
      file?.size / 1000000 <= 50
    ) {
      reader.onload = function (upload) {
        onFileLoad(
          uploadData({ file, rawData: upload.target.result, extension }),
          {
            file,
            rawData: upload.target.result,
            mime: mimeTypes.contentType(extension),
            extension,
            fileName: file.name,
          }
        );
      };

      reader.readAsDataURL(event.target.files[0]);
    } else {
      const message =
        file?.size / 1000000 > 50
          ? "File size too large. Maximum size is 50mb"
          : "Unsupported file extension.";

      onFileError(message);
    }
  };

  return (
    <label className="file-input-container">
      {icon}
      <input
        ref={ref}
        className={className}
        type="file"
        onChange={!disabled ? handleFile : () => {}}
        accept={accept}
      />
    </label>
  );
};

export default React.forwardRef(File);
