import React from "react";

export default () => (
  <svg
    width="10"
    height="14"
    viewBox="0 0 10 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.99967 0.333496C2.42301 0.333496 0.333008 2.4235 0.333008 5.00016C0.333008 8.50016 4.99967 13.6668 4.99967 13.6668C4.99967 13.6668 9.66634 8.50016 9.66634 5.00016C9.66634 2.4235 7.57634 0.333496 4.99967 0.333496ZM4.99967 6.66683C4.07967 6.66683 3.33301 5.92016 3.33301 5.00016C3.33301 4.08016 4.07967 3.3335 4.99967 3.3335C5.91967 3.3335 6.66634 4.08016 6.66634 5.00016C6.66634 5.92016 5.91967 6.66683 4.99967 6.66683Z"
      fill="#474F58"
    />
  </svg>
);
