import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { FileInput } from "../../../../components/FormElements";

import {
  getFirmAccountInfoAction,
  patchFirmAccountInfoAction,
} from "../../../../redux/actions/AccountSettings";

import { postRequest } from "../../../../api";
import { getRequestError } from "../../../../utils/functions";
import { accountSettingsPermissions } from "../../../../utils/permissions";

import { Close, Pencil, Spinner } from "../../../../assets/vectors";

const AccountInformation = ({ openToaster }) => {
  const dispatch = useDispatch();
  const {
    isLoadingAccountSettings,
    isSubmittingAccountSettings,
    accountInfoData,
  } = useSelector((state) => state.accountSettings);

  const [accountInfoForm, setAccountInfoForm] = useState({
    open: false,
    value: "",
  });
  const [isUploading, setIsUploading] = useState(false);
  const [uploadedImg, setUploadedImg] = useState(null);

  const submitAccountInfoForm = () => {
    if (accountInfoForm?.value) {
      dispatch(
        patchFirmAccountInfoAction({ name: accountInfoForm?.value })
      ).then((res) => {
        if (res?.success === true) {
          dispatch(getFirmAccountInfoAction());
          setAccountInfoForm({ open: false, value: "" });
          openToaster("success", res?.message);
        }
      });
    }
  };

  const handleLogoChange = async (uploadData, { file }) => {
    if (file) {
      var fileData = new FormData();
      fileData.append("media", file);
      let payload = {};
      payload = fileData;

      setIsUploading(true);

      try {
        const res = await postRequest({
          url: "/utils/upload",
          data: payload,
          token: true,
        });
        if (res?.status === 200) {
          setUploadedImg(res?.data?.data);
          openToaster("success", res?.data?.message);
          setIsUploading(false);
        }
      } catch (error) {
        const message = getRequestError(error);
        openToaster("error", message);
        setIsUploading(false);
      }
    }
  };
  useEffect(() => {
    if (uploadedImg) {
      dispatch(patchFirmAccountInfoAction({ logoUrl: uploadedImg })).then(
        (res) => {
          if (res?.success === true) {
            dispatch(getFirmAccountInfoAction());
            openToaster("success", res?.message);
          }
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedImg]);

  return (
    <div className="nav_content">
      <div className="tab_content">
        <h6 className="header">
          {isLoadingAccountSettings ? "Loading..." : "Account Information"}
        </h6>

        {!isLoadingAccountSettings && (
          <div className="content">
            <div className="item">
              <p className="label">Account Name</p>
              <div className="input_container">
                <p>{accountInfoData?.name || ""}</p>
                {(accountSettingsPermissions?.create ||
                  accountSettingsPermissions?.update ||
                  accountSettingsPermissions?.modifyAll) && (
                  <span
                    className="copy_btn"
                    onClick={() =>
                      setAccountInfoForm({
                        ...accountInfoForm,
                        open: !accountInfoForm?.open,
                      })
                    }
                  >
                    {accountInfoForm?.open ? <Close /> : <Pencil />}
                  </span>
                )}
              </div>
            </div>
            {accountInfoForm?.open && (
              <div className="item new_config_container">
                <p className="label">Edit Account Name:</p>
                <div className="input_container">
                  <input
                    className="input"
                    onChange={(e) =>
                      setAccountInfoForm({
                        ...accountInfoForm,
                        name: e?.target?.value,
                      })
                    }
                  />
                  <button
                    className="copy_btn"
                    onClick={() => submitAccountInfoForm()}
                    disabled={isSubmittingAccountSettings}
                  >
                    {isSubmittingAccountSettings ? "Submitting..." : "Submit"}
                  </button>
                </div>
              </div>
            )}

            <div className="item">
              <p className="label">Logo</p>
              <div className="input_container logo_container">
                <img src={accountInfoData?.logoUrl || ""} alt="" />
                {(accountSettingsPermissions?.create ||
                  accountSettingsPermissions?.update ||
                  accountSettingsPermissions?.modifyAll) && (
                  <FileInput
                    icon={
                      <span className="copy_btn">
                        {isUploading ? <Spinner /> : <Pencil />}
                      </span>
                    }
                    onFileLoad={handleLogoChange}
                    onFileError={() => {}}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AccountInformation;
