import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

import { addToTeamAction } from "../../../redux/actions/Teams";

import { Button } from "../../../components/FormElements";
import Modal from "../../../components/Modal";
import FormNotifications from "../../../components/Notifications";

const AddUsersForm = ({ toggleForm, teamData, usersOptions }) => {
  const dispatch = useDispatch();

  const { updateTeamOutcome, updateTeamMessage, isUpdatingTeam } = useSelector(
    (state) => state.teams
  );

  const [selectedUsers, setSelectedUsers] = useState([]);

  const isFormValid = !isUpdatingTeam && selectedUsers?.length > 0;

  const submitForm = (e) => {
    e.preventDefault();

    const payload = {
      users: selectedUsers?.map((x) => x?.value),
    };

    dispatch(addToTeamAction(payload, teamData?.id, "users"));
  };

  let options = usersOptions;

  let teamUsers = teamData?.Users?.map((x) => x?.id);
  options = usersOptions?.filter((x) => !teamUsers?.includes(x?.value));

  return (
    <Modal closeModal={() => toggleForm()}>
      <div className="modal-content">
        <div className="modal-title">Add Users to Team</div>
        <div className="modal-form saved-response-form">
          <form onSubmit={submitForm}>
            <div className="input-container mb-50">
              <label>Users</label>
              <Select
                classNamePrefix="select_container"
                value={selectedUsers}
                options={options || []}
                onChange={(selections) => setSelectedUsers(selections)}
                isClearable
                isMulti
              />
            </div>
            <div className="modal-form-action">
              <Button
                type="button"
                className="secondary-button"
                onClick={() => toggleForm()}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="primary-button"
                disabled={!isFormValid}
              >
                Add Users
              </Button>
            </div>

            {updateTeamOutcome && (
              <FormNotifications
                type={updateTeamOutcome}
                message={updateTeamMessage}
              />
            )}
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default AddUsersForm;
