import React from "react";

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      stroke="#4BA3E7"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M5 11.25h6.25A3.75 3.75 0 0015 7.5V5.623"
    ></path>
    <path
      stroke="#4BA3E7"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M8.125 8.123L5 11.248l3.125 3.125"
    ></path>
    <path
      stroke="#4BA3E7"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M16.25 1.248H3.75a2.5 2.5 0 00-2.5 2.5v12.5a2.5 2.5 0 002.5 2.5h12.5a2.5 2.5 0 002.5-2.5v-12.5a2.5 2.5 0 00-2.5-2.5z"
    ></path>
  </svg>
);
