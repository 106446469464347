import React, { Component } from "react";
import * as moment from "moment";

import { getRequest } from "../../../api";

import { Calendar, Expand } from "../../../assets/vectors";

import { getRequestError } from "../../../utils/functions";

class ConversationHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoadingHistory: false,
      conversations: [],
    };
  }

  getConversationHistory = async () => {
    const { customerId, customer_id, channel_id } = this.props;

    this.setState({
      isLoadingHistory: true,
    });

    try {
      const res = await getRequest({
        url: customerId
          ? `conversations/search?customer_id=${customerId}`
          : `conversations/search?customer_id=${customer_id}&channel_id=${channel_id}`,
        token: true,
      });

      this.setState({
        conversations: res.data.data,
        isLoadingHistory: false,
      });
    } catch (error) {
      this.setState({
        isLoadingHistory: false,
      });

      const message = getRequestError(error);
      console.log(error, message);
    }
  };

  componentDidMount() {
    this.getConversationHistory();
  }

  render() {
    const { isLoadingHistory, conversations } = this.state;
    const { openConversation } = this.props;

    return (
      <div className="conversation-history-container">
        {isLoadingHistory ? (
          <div className="loading_history">
            Loading conversation history... Please wait
          </div>
        ) : (
          <>
            {conversations.map((conversation, index) => {
              const {
                customer_id,
                Channel,
                Messages = [],
                status,
                createdAt,
              } = conversation;

              return (
                <div
                  key={`history-item-${index}`}
                  className="history-item"
                  onClick={() =>
                    openConversation(
                      customer_id,
                      Channel?.id,
                      Messages?.[0]?.row_id
                    )
                  }
                >
                  <div>
                    <div className="message-preview">
                      {Messages?.length ? Messages?.[0]?.clean_content : ""}
                    </div>
                    <div className="message-length">{status}</div>
                  </div>
                  <div className="message-action">
                    {/* <div className="message-id"># 105</div> */}
                    <div className="message-date">
                      <span className="icon">
                        <Calendar />
                      </span>
                      <span className="text">
                        {moment(createdAt).format("DD/MM/YY")}
                      </span>
                    </div>
                    <div className="message-open">
                      <Expand />
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
    );
  }
}

export default ConversationHistory;
