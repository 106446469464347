import React from "react";
import { connect } from "react-redux";
import { Line } from "react-chartjs-2";
import * as moment from "moment";

const Conversations = ({ conversations, isLoadingAgentInsights }) => {
  const conversationsData = (conversations || []).map(({ value }) => value);
  const conversationsLabel = (conversations || []).map(({ date }) =>
    moment(date).format("MMM DD")
  );

  const data = {
    labels: conversationsLabel,
    datasets: [
      {
        label: "",
        lineTension: 0,
        fill: false,
        borderColor: "#AEDEFF",
        pointBorderColor: "#18A0FB",
        pointBackgroundColor: "#18A0FB",
        pointBorderWidth: 1,
        pointRadius: 4,
        pointHoverBackgroundColor: "#AEDEFF",
        pointHoverBorderColor: "#AEDEFF",
        pointHoverBorderWidth: 1,
        pointHoverRadius: 4,
        pointHitRadius: 4,
        data: conversationsData
      }
    ],
    options: {
      maintainAspectRatio: false,
      title: {
        display: false
      },
      legend: {
        display: false
      },
      layout: {
        padding: {
          right: 10
        }
      },
      scales: {
        xAxes: [
          {
            display: true,
            gridLines: {
              display: false,
              drawBorder: false
            }
          }
        ],
        yAxes: [
          {
            ticks: {
              padding: 15,
              maxTicksLimit: 6
            },
            gridLines: {
              display: true,
              drawBorder: false
            }
          }
        ]
      }
    }
  };

  return (
    <div>
      <span className="insights-section-title">Conversations</span>
      <div className="insights-card resolution-time-container">
        <div className="insights-chart-container">
          <div className="insights-chart-header">
            <div className="average-resolution-time">
              <span className="title">Conversations</span>
            </div>
            <div className="date-filter"></div>
          </div>

          <div className="insights-chart">
            {!isLoadingAgentInsights && (
              <Line height={225} data={data} options={data.options} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ insights }) => insights;

export default connect(mapStateToProps, {})(Conversations);
