import React from "react";
import classNames from "classnames";
import { NavLink } from "react-router-dom";

import { settingsMenu } from "./data";

const SettingsMenu = () => {
  return (
    <div className="secondary-menu page-menu">
      <div className="title">Settings</div>
      <div className="secondary-menu-items">
        {settingsMenu.map(({ label, icon, value, show }, index) => (
          <React.Fragment key={`settings-menu-${index}`}>
            {show && (
              <NavLink
                className={classNames("item", {
                  dead: value === "#",
                })}
                to={value}
              >
                <div>
                  <span className="icon">{icon}</span>
                  <span className="text">{label}</span>
                </div>
              </NavLink>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default SettingsMenu;
