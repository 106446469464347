import React from "react";

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    fill="none"
    viewBox="0 0 18 18"
  >
    <path
      stroke="#474F58"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M9 6v6M6 9h6M9 16.5a7.5 7.5 0 100-15 7.5 7.5 0 000 15z"
    ></path>
  </svg>
);
