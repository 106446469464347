import React, { Suspense, lazy, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import * as moment from "moment";
import Select from "react-select";

import Pagination from "../../components/Pagination/Pagination";

import { getBroadcastAction } from "../../redux/actions/Broadcast";

import { getMessageLogsAction } from "../../redux/actions/Insights";

const CreateBroadcast = lazy(() => import("./CreateBroadcast"));

const BroadcastInfo = () => {
  const location = useLocation();
  const { id } = useParams();
  const dispatch = useDispatch();

  const { isLoadingBroadcast, broadcastData } = useSelector(
    (state) => state.broadcast
  );
  const { messageLogsData } = useSelector((state) => state.insights);

  const [isLoadingFilter, setIsLoadingFilter] = useState(false);
  const [query, setQuery] = useState({
    broadcast_id: id,
    "page[size]": 25,
    "page[number]": 1,
  });
  const [status, setStatus] = useState(null);

  useEffect(() => {
    if (!location?.pathname?.includes("/new")) {
      dispatch(getBroadcastAction({ id: `/${id}` }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const paginateRecords = (pageNumber) => {
    if (pageNumber) {
      setQuery({
        ...query,
        "page[number]": pageNumber,
      });
    }
  };
  useEffect(() => {
    if (!location?.pathname?.includes("/new")) {
      setIsLoadingFilter(true);

      dispatch(getMessageLogsAction(query)).then(() =>
        setIsLoadingFilter(false)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const handleStatusChange = (selections) => {
    setStatus(selections);

    setQuery({
      ...query,
      status: selections?.map((x) => x.value),
    });
  };

  const statusOptions = [
    {
      label: "Undelivered",
      value: "undelivered",
    },
    {
      label: "Delivered",
      value: "delivered",
    },
    {
      label: "Rejected",
      value: "rejected",
    },
    {
      label: "Undeliverable",
      value: "undeliverable",
    },
    {
      label: "Expired",
      value: "expired",
    },
    {
      label: "Pending",
      value: "pending",
    },
  ];

  return (
    <>
      <Suspense fallback={<div>...</div>}>
        {location?.pathname?.includes("/new") ? (
          <CreateBroadcast />
        ) : (
          <div className="page-content full">
            <div className="broadcast-page-container settings-page-container">
              {isLoadingBroadcast ? (
                <div className="loading-broadcast">
                  <p>Loading...</p>
                </div>
              ) : (
                <>
                  <div className="page-header">
                    <span className="title">
                      Broadcast - {broadcastData?.name}
                    </span>
                  </div>

                  <div className="settings-page-info overview off-white-bg">
                    <div className="input-container">
                      <label>Name</label>
                      <p>{broadcastData?.name}</p>
                    </div>
                    <div className="input-container">
                      <label>Channel</label>
                      <p>
                        {broadcastData?.Channel?.medium +
                          " - " +
                          broadcastData?.Channel?.name}
                      </p>
                    </div>
                    <div className="input-container">
                      <label>Message</label>
                      <p>{broadcastData?.message}</p>
                    </div>
                    <div className="input-container">
                      <label>Recipients</label>
                      <p>{broadcastData?.recipients}</p>
                    </div>
                  </div>

                  <div className="settings-page-info overview summary mt-30">
                    <div className="input-container">
                      <label>Status</label>
                      <p className={`status ${broadcastData?.status}`}>
                        {broadcastData?.status}
                      </p>
                    </div>
                    <div className="input-container">
                      <label>Delivered</label>
                      <p>{broadcastData?.delivered}</p>
                    </div>
                    {/* <div className="input-container">
                      <label>Opened</label>
                      <p>{broadcastData?.opened}</p>
                    </div>
                    <div className="input-container">
                      <label>Converted</label>
                      <p>{broadcastData?.converted}</p>
                    </div> */}
                    <div className="input-container">
                      <label>Pending</label>
                      <p>{broadcastData?.pending}</p>
                    </div>
                    <div className="input-container">
                      <label>Undelivered</label>
                      <p>{broadcastData?.undelivered}</p>
                    </div>
                    <div className="input-container">
                      <label>Rejected</label>
                      <p>{broadcastData?.rejected}</p>
                    </div>
                  </div>

                  <div className="filter_container">
                    <Select
                      classNamePrefix="select_container"
                      value={status}
                      options={statusOptions}
                      onChange={(selections) => handleStatusChange(selections)}
                      placeholder="Filter by status..."
                      isDisabled={isLoadingBroadcast}
                      isClearable
                      isMulti
                    />
                  </div>

                  <div className="message-logs-container">
                    <div className="header grid-view">
                      <p>ID</p>
                      <p>Recipient</p>
                      <p>Status</p>
                      <p>Sent from</p>
                      <p>Date sent</p>
                      <p>Date delivered</p>
                    </div>
                    <div className="content">
                      {isLoadingFilter ? (
                        <p className="loading_table_data">Loading...</p>
                      ) : (
                        <>
                          {messageLogsData?.data?.map((item, i) => (
                            <div key={i} className="message grid-view">
                              <div className="info id">{item?.id}</div>
                              <div className="info">{item?.to}</div>
                              <div className="info">
                                <span className={`status ${item?.status}`}>
                                  {item?.status}
                                </span>
                              </div>
                              <div className="info">{item?.from}</div>
                              <div className="info">
                                {moment(item?.sendAt || item?.createdAt).format(
                                  "DD-MM-YYYY | HH:MM"
                                )}
                              </div>
                              <div className="info">
                                {item?.delivered_time
                                  ? moment(item?.delivered_time).format(
                                      "DD-MM-YYYY | HH:MM"
                                    )
                                  : "- - -"}
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                  </div>

                  <Pagination
                    meta={{
                      currentPage: messageLogsData?.currentPage,
                      totalPage: messageLogsData?.totalPage,
                    }}
                    paginateTableAction={paginateRecords}
                  />

                  <br />
                  <br />
                </>
              )}
            </div>
          </div>
        )}
      </Suspense>
    </>
  );
};

export default BroadcastInfo;
