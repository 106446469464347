import { deleteRequest, getRequest, postRequest } from "../../api";

import {
  GET_TEMPLATE_CATEGORIES_LOADER,
  GET_TEMPLATE_MESSAGE_LOADER,
  GET_TEMPLATE_MESSAGE_RESPONSE,
  GET_TEMPLATE_MESSAGE_RESET,
  CREATE_TEMPLATE_MESSAGE_LOADER,
  CREATE_TEMPLATE_MESSAGE_RESPONSE,
  CREATE_TEMPLATE_MESSAGE_RESET,
  UPDATE_TEMPLATE_MESSAGE_LOADER,
  UPDATE_TEMPLATE_MESSAGE_RESPONSE,
  UPDATE_TEMPLATE_MESSAGE_RESET,
  DELETE_TEMPLATE_MESSAGE_LOADER,
  DELETE_TEMPLATE_MESSAGE_RESPONSE,
  DELETE_TEMPLATE_MESSAGE_RESET,
} from "../constants";

import { getRequestError } from "../../utils/functions";

export const createTemplateMessageLoader = (isCreatingTemplateMessage) => ({
  type: CREATE_TEMPLATE_MESSAGE_LOADER,
  isCreatingTemplateMessage,
});

export const createTemplateMessageResponse = (
  createTemplateMessageOutcome,
  createTemplateMessageMessage
) => ({
  type: CREATE_TEMPLATE_MESSAGE_RESPONSE,
  createTemplateMessageOutcome,
  createTemplateMessageMessage,
});

export const createTemplateMessageReset = () => ({
  type: CREATE_TEMPLATE_MESSAGE_RESET,
});

export const updateTemplateMessageLoader = (isUpdatingTemplateMessage) => ({
  type: UPDATE_TEMPLATE_MESSAGE_LOADER,
  isUpdatingTemplateMessage,
});

export const updateTemplateMessageResponse = (
  updateTemplateMessageOutcome,
  updateTemplateMessageMessage
) => ({
  type: UPDATE_TEMPLATE_MESSAGE_RESPONSE,
  updateTemplateMessageOutcome,
  updateTemplateMessageMessage,
});

export const updateTemplateMessageReset = () => ({
  type: UPDATE_TEMPLATE_MESSAGE_RESET,
});

export const getTemplateCategoriesLoader = (isLoadingTemplateCategories) => ({
  type: GET_TEMPLATE_CATEGORIES_LOADER,
  isLoadingTemplateCategories,
});

export const getTemplateMessageLoader = (isLoadingTemplateMessage) => ({
  type: GET_TEMPLATE_MESSAGE_LOADER,
  isLoadingTemplateMessage,
});

export const getTemplateMessageResponse = (
  templateMessageOutcome,
  templateMessageData,
  templateMessageMeta,
  templateMessageMessage
) => ({
  type: GET_TEMPLATE_MESSAGE_RESPONSE,
  templateMessageOutcome,
  templateMessageData,
  templateMessageMeta,
  templateMessageMessage,
});

export const getTemplateMessageReset = () => ({
  type: GET_TEMPLATE_MESSAGE_RESET,
});

export const deleteTemplateMessageLoader = (isDeletingTemplateMessage) => ({
  type: DELETE_TEMPLATE_MESSAGE_LOADER,
  isDeletingTemplateMessage,
});

export const deleteTemplateMessageResponse = (
  deleteTemplateMessageOutcome,
  deleteTemplateMessageMessage
) => ({
  type: DELETE_TEMPLATE_MESSAGE_RESPONSE,
  deleteTemplateMessageOutcome,
  deleteTemplateMessageMessage,
});

export const deleteTemplateMessageReset = () => ({
  type: DELETE_TEMPLATE_MESSAGE_RESET,
});

export const createTemplateMessageAction = (data, channelId, success) => {
  return async (dispatch) => {
    dispatch(createTemplateMessageReset());
    dispatch(createTemplateMessageLoader(true));

    try {
      await postRequest({
        url: `/channels/${channelId}/templates`,
        data,
        token: true,
      });

      dispatch(createTemplateMessageResponse("success", ""));
      dispatch(createTemplateMessageLoader(false));

      success && success();
    } catch (error) {
      const message = getRequestError(error);

      dispatch(createTemplateMessageResponse("error", message));
      dispatch(createTemplateMessageLoader(false));
    }
  };
};

export const getTemplateCategoriesAction = () => {
  return async (dispatch) => {
    try {
      dispatch(getTemplateCategoriesLoader(true));

      const res = await getRequest({
        url: "/channels/templates/params",
        token: true,
      });
      const { data } = res;
      dispatch(getTemplateCategoriesLoader(false));

      return data;
    } catch (error) {
      dispatch(getTemplateCategoriesLoader(false));
      const message = getRequestError(error);
      console.log(message);
    }
  };
};

export const getTemplateMessageAction = (params = {}) => {
  return async (dispatch) => {
    dispatch(getTemplateMessageReset());
    dispatch(getTemplateMessageLoader(true));

    try {
      const res = await getRequest({
        url: `/channels/${params?.channelId}/templates`,
        params: params?.query,
        token: true,
      });

      const { body } = res?.data;

      dispatch(getTemplateMessageResponse("success", body, {}, ""));
      dispatch(getTemplateMessageLoader(false));
    } catch (error) {
      const message = getRequestError(error);

      dispatch(getTemplateMessageResponse("error", [], {}, message));
      dispatch(getTemplateMessageLoader(false));
    }
  };
};

export const deleteTemplateAction = (data) => {
  return async (dispatch) => {
    dispatch(getTemplateMessageLoader(true));

    try {
      const res = await deleteRequest({
        url: `/channels/${data?.channel_id}/template/${data?.template_name}`,
        token: true,
      });

      dispatch(getTemplateMessageLoader(false));
      return res?.data;
    } catch (error) {
      dispatch(getTemplateMessageLoader(false));

      const message = getRequestError(error);
      return {
        success: false,
        type: "error",
        message: message,
      };
    }
  };
};
