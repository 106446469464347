import React, { useState, useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  Copy,
  Redirect,
  LogoSolid,
  WhatsApp,
  CircularCheckGreen,
  AngleDown,
  Broadcast,
} from "../../../../assets/vectors";
import { Button, TextField } from "../../../../components/FormElements";
import Modal from "../../../../components/Modal";
import Toaster from "../../../../components/Toaster";

import {
  postSubscribeWhatsappChannel,
  getAllChannelsAction,
  getFirmChannelsAction,
} from "../../../../redux/actions/Channels";

const WhatsappForm = ({ close, dismissRoute }) => {
  const dispatch = useDispatch();
  const { isCreatingChannel, createChannelsMessage, createChannelsOutcome } =
    useSelector((state) => state?.channels);
  const history = useHistory();

  const [currentStep, setCurrentStep] = useState(2);
  const [provider, setProvider] = useState(null);
  const [nextStepOne, setNextStepOne] = useState(true);
  const [nextStepTwo, setNextStepTwo] = useState(false);
  const [phone, setPhone] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [phoneId, setPhoneId] = useState("");
  const [waba_id, setWabaId] = useState("");
  const [app_id, setAppId] = useState("");
  const [version, setVersion] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [generatedURL, setGeneratedURL] = useState(null);
  const [toaster, setToaster] = useState({});

  useEffect(() => {
    setErrorMessage(null);

    if (currentStep === 4 && provider === 1) {
      history.push("/settings/channels/setup/1");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  useEffect(() => {
    if (createChannelsOutcome === "error") {
      setErrorMessage(createChannelsMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createChannelsOutcome]);

  const getChannelsData = () => {
    dispatch(getAllChannelsAction());
    dispatch(getFirmChannelsAction());
  };

  const proceedToNextStep = (res) => {
    getChannelsData();
    setGeneratedURL(res?.data?.data?.credentials?.webhookUrl);
    setNextStepOne(false);
    setNextStepTwo(true);
    setCurrentStep(5);
  };

  const verifyInfobipAuth = () => {
    if (phone) {
      setErrorMessage(null);
      dispatch(
        postSubscribeWhatsappChannel({
          url: "/channels/infobip/subscribe",
          payload: {
            phone,
          },
        })
      ).then((res) => {
        if (res?.data?.success === true) {
          proceedToNextStep(res);
        }
      });
    }
  };

  const verifyWhatsAppCloudAuth = () => {
    if (accessToken && phoneId && waba_id && app_id && version) {
      setErrorMessage(null);
      dispatch(
        postSubscribeWhatsappChannel({
          url: "/channels/whatsapp/subscribe",
          payload: {
            accessToken,
            phoneId,
            waba_id,
            app_id,
            version,
            env: "LIVE",
          },
        })
      ).then((res) => {
        if (res?.data?.success === true) {
          proceedToNextStep(res);
        }
      });
    }
  };

  const prerequisiteInfo = () => {
    if (provider) {
      return (
        <>
          Click continue to create a(n) {providers?.[provider - 1]?.name}{" "}
          account or connect an existing one
          <br />
          <br />
          {providers?.[provider - 1]?.id === 1 ? (
            ""
          ) : (
            <>
              Don’t have a(n) {providers?.[provider - 1]?.name} account?{" "}
              <a
                href={providers?.[provider - 1]?.link || ""}
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                Create a(n) {providers?.[provider - 1]?.name} account here
              </a>
              .
              <br />
              Please note that connecting {providers?.[provider - 1]?.name} will
              require you to contact support. Only select this option if you
              have been instructed/guided by the support team.
            </>
          )}
        </>
      );
    } else {
      return "";
    }
  };

  const clickToCopy = (toCopy) => {
    navigator.clipboard.writeText(toCopy || "");
    openToaster("success", "Copied!");
  };

  const openToaster = (status, content) => {
    setToaster({
      open: "open",
      status,
      content,
    });
  };

  const closeToaster = () => {
    setToaster({
      ...toaster,
      open: "closed",
    });
  };

  const providers = [
    {
      id: 1,
      image:
        "https://s3.eu-west-3.amazonaws.com/oneroute.asb.ng/360dialog-logo.svg",
      name: "360Dialog",
      link: "https://hub.360dialog.com/lp/whatsapp/y8a0kLPA",
      recommended: true,
    },
    {
      id: 2,
      image:
        "https://s3.eu-west-3.amazonaws.com/oneroute.asb.ng/infobip-logo.svg",
      name: "Infobip",
      link: "https://www.infobip.com/contact",
    },
    // {
    //   id: 3,
    //   image:
    //     "https://s3.eu-west-3.amazonaws.com/oneroute.asb.ng/twilo-logo.svg",
    //   name: "Twilio",
    //   link: "https://www.twilio.com/try-twilio",
    // },
    // {
    //   id: 4,
    //   image:
    //     "https://play-lh.googleusercontent.com/bYtqbOcTYOlgc6gqZ2rwb8lptHuwlNE75zYJu6Bn076-hTmvd96HH-6v7S0YUAAJXoJN",
    //   name: "WhatsApp Cloud",
    //   link: "https://developers.facebook.com/docs/whatsapp/cloud-api/",
    // },
  ];

  const todos = [
    {
      link: "https://business.facebook.com/overview",
      image: require("../../../../assets/images/facebook-manager.png").default,
      label: "A Meta Business Manager Account",
    },
    {
      link: "https://airtable.com/shrCqb6TaWOa0es1K",
      image: require("../../../../assets/images/icons/phone.svg").default,
      label: (
        <>
          A phone number that is not registered to WhatsApp but can receive an
          SMS or Phone call.
          <br />
          Don't have a Phone number? Click to request one.
        </>
      ),
    },
    {
      link: "https://docs.google.com/document/d/1af15_2AtCGfQ5Mg7clzX2voSWQ8N_SVEW04PnC3_4ww/edit?usp=sharing",
      image: require("../../../../assets/images/icons/whatsapp-icon.png")
        .default,
      label: (
        <>
          If you wish to migrate from WhatsApp Business or a different WhatsApp
          provider, please see migration instructions.
        </>
      ),
    },
  ];

  const verifyToken = "LBcu1Fmx914MqqkzJ4W1W1H9A1";

  return (
    <React.Fragment>
      {currentStep === 2 && (
        <Modal className="channel-modal">
          <div className="modal_content whatsapp_setup_container step_one">
            <div className="header">
              <div className="logos">
                <LogoSolid />
                <Redirect />
                <WhatsApp />
              </div>
              <h6 className="modal-title">Set up WhatsApp channel</h6>
            </div>
            <div className="content">
              <div className="provider_selection">
                <p>
                  To set up your WhatsApp channel, you need to have the
                  following:
                </p>
                <div className="todos">
                  {todos?.map(({ link, image, label }, i) => (
                    <a
                      key={i}
                      href={link}
                      className="todo"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={image} alt="" />
                      <p>{label}</p>
                    </a>
                  ))}
                </div>
              </div>
            </div>
            <div className="actions">
              <Button className="secondary-button" onClick={close}>
                Cancel
              </Button>
              <Button
                className="primary-button"
                onClick={() => setCurrentStep(3)}
              >
                I already have these
              </Button>
            </div>
          </div>
        </Modal>
      )}

      {currentStep === 3 && (
        <Modal className="channel-modal">
          <div className="modal_content whatsapp_setup_container step_one">
            <div className="header">
              <div className="logos">
                <LogoSolid />
                <Redirect />
                <WhatsApp />
              </div>
              <h6 className="modal-title">Set up WhatsApp channel</h6>
            </div>
            <div className="content">
              <div className="provider_selection">
                <p>Choose a provider</p>
                <div className="providers">
                  {providers?.map(({ id, image, name, recommended }, i) => (
                    <div
                      key={i}
                      className={`item ${provider === id ? "selected" : ""}`}
                      onClick={() => setProvider(id)}
                    >
                      <img src={image} className="provider_image" alt="" />
                      <h6 className="provider_name">{name}</h6>
                      {recommended && (
                        <p className="recommended">
                          <Broadcast />
                          Recommended
                        </p>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              {provider && <p className="no_api_key">{prerequisiteInfo()}</p>}
            </div>
            <div className="actions">
              <Button
                className="secondary-button"
                onClick={() => setCurrentStep(2)}
              >
                Back
              </Button>
              <Button
                className="primary-button"
                onClick={() => setCurrentStep(4)}
                disabled={!provider}
              >
                Continue
              </Button>
            </div>
          </div>
        </Modal>
      )}

      {(currentStep === 4 || currentStep === 5) && (
        <Modal className="channel-modal">
          <div className="modal_content whatsapp_setup_container step_two">
            <div className="header">
              <h6 className="modal-title">
                Connect via {providers?.[provider - 1]?.name}
              </h6>
              <p className="text-link" onClick={() => setCurrentStep(3)}>
                Connect using another provider
              </p>
            </div>
            <div className="setup_form">
              <div
                className={`step ${currentStep === 4 && "active"} ${
                  nextStepOne && "open"
                }`}
              >
                <div
                  className="header"
                  onClick={() =>
                    currentStep > 3 && setNextStepOne(!nextStepOne)
                  }
                >
                  <h6>
                    Set up Whatsapp channel{" "}
                    {currentStep > 4 && <CircularCheckGreen />}
                  </h6>
                  <AngleDown />
                </div>

                {nextStepOne && (
                  <div className="content">
                    <p>
                      Fill in the details below to activate Whatsapp as a
                      channel
                    </p>

                    {provider === 2 ? (
                      <>
                        <div className="form_field">
                          <TextField
                            label="Phone number"
                            type="text"
                            name="phone"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            disabled={isCreatingChannel}
                          />
                          <span>Enter your Whatsapp phone number here</span>
                        </div>

                        <div className="actions">
                          {currentStep < 5 ? (
                            <Button
                              className="tertiary-button"
                              onClick={() => verifyInfobipAuth()}
                              disabled={isCreatingChannel}
                            >
                              Verify
                            </Button>
                          ) : (
                            <p className="success">
                              <CircularCheckGreen /> Verification Successful
                            </p>
                          )}
                        </div>
                        {errorMessage && (
                          <div className="error">{errorMessage}</div>
                        )}
                      </>
                    ) : (
                      provider === 4 && (
                        <>
                          <div className="form_field">
                            <TextField
                              label="Access Token"
                              type="text"
                              name="accessToken"
                              value={accessToken}
                              onChange={(e) => setAccessToken(e.target.value)}
                              disabled={isCreatingChannel}
                            />
                            <span>Enter the Access Token here</span>
                          </div>
                          <div className="form_field">
                            <TextField
                              label="Phone ID"
                              type="text"
                              name="phoneId"
                              value={phoneId}
                              onChange={(e) => setPhoneId(e.target.value)}
                              disabled={isCreatingChannel}
                            />
                            <span>Enter the Phone ID here</span>
                          </div>
                          <div className="form_field">
                            <TextField
                              label="WhatsApp Business Account ID"
                              type="text"
                              name="waba_id"
                              value={waba_id}
                              onChange={(e) => setWabaId(e.target.value)}
                              disabled={isCreatingChannel}
                            />
                            <span>
                              Enter the WhatsApp Business Account ID here
                            </span>
                          </div>
                          <div className="form_field">
                            <TextField
                              label="Application ID"
                              type="text"
                              name="app_id"
                              value={app_id}
                              onChange={(e) => setAppId(e.target.value)}
                              disabled={isCreatingChannel}
                            />
                            <span>Enter your Application ID here</span>
                          </div>
                          <div className="form_field">
                            <TextField
                              label="API Version"
                              type="text"
                              name="version"
                              value={version}
                              onChange={(e) => setVersion(e.target.value)}
                              disabled={isCreatingChannel}
                            />
                            <span>Enter your API Version here</span>
                          </div>

                          <div className="actions">
                            {currentStep < 5 ? (
                              <Button
                                className="tertiary-button"
                                onClick={() => verifyWhatsAppCloudAuth()}
                                disabled={isCreatingChannel}
                              >
                                Verify
                              </Button>
                            ) : (
                              <p className="success">
                                <CircularCheckGreen /> Verification Successful
                              </p>
                            )}
                          </div>
                          {errorMessage && (
                            <div className="error">{errorMessage}</div>
                          )}
                        </>
                      )
                    )}

                    <p className="no_api_key">
                      Don't have the necessary credentials? Create{" "}
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a
                        href={providers?.[provider - 1]?.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {providers?.[provider - 1]?.name} account here
                      </a>
                    </p>
                  </div>
                )}
              </div>

              {provider !== 1 && (
                <div
                  className={`step ${currentStep === 5 && "active"} ${
                    nextStepTwo && "open"
                  }`}
                >
                  <div
                    className="header"
                    onClick={() =>
                      currentStep > 4 && setNextStepTwo(!nextStepTwo)
                    }
                  >
                    <h6>
                      Set up sending messages{" "}
                      {currentStep > 5 && <CircularCheckGreen />}
                    </h6>
                    <AngleDown />
                  </div>

                  <br />

                  {nextStepTwo && (
                    <div className="content">
                      <p>
                        Copy the link below and paste on your Whatsapp provider
                        account
                      </p>

                      <div className="copy_div">
                        <h6>Callback URL</h6>

                        <div className="inner">
                          <p>{generatedURL}</p>
                          <Button
                            className="tertiary-button"
                            onClick={() => clickToCopy(generatedURL)}
                          >
                            <Copy />
                          </Button>
                        </div>
                      </div>

                      {provider === 4 && (
                        <div className="copy_div">
                          <h6>Verify Token</h6>

                          <div className="inner">
                            <p>{verifyToken}</p>
                            <Button
                              className="tertiary-button"
                              onClick={() => clickToCopy(verifyToken)}
                            >
                              <Copy />
                            </Button>
                          </div>
                        </div>
                      )}

                      <br />
                      <div className="actions">
                        <Button
                          className="tertiary-button"
                          onClick={() => setCurrentStep(6)}
                        >
                          Done
                        </Button>
                      </div>
                      <br />
                      <br />
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="actions">
              <Button
                className="secondary-button"
                onClick={currentStep === 4 ? () => setCurrentStep(3) : close}
              >
                {currentStep === 4 ? "Back" : "Close"}
              </Button>
            </div>
          </div>
        </Modal>
      )}

      {currentStep === 6 && (
        <Modal className="channel-modal">
          <div className="modal_content whatsapp_setup_container step_four">
            <h6>Setup Successful!</h6>
            <p className="center">
              {dismissRoute
                ? `You can add more channel and activate
                account in @settings`
                : `Send a Whatsapp message to ${phone || "this number"} to start
              conversations with customers on Whatsapp.`}
            </p>
            <div className="actions">
              {dismissRoute ? (
                ""
              ) : (
                <Button
                  className="tertiary-button"
                  onClick={() =>
                    history.push(dismissRoute ? dismissRoute : "/conversations")
                  }
                >
                  Jump to conversations
                </Button>
              )}
              <Button
                className="primary-button"
                onClick={() =>
                  dismissRoute ? history.push(dismissRoute) : () => close()
                }
              >
                {dismissRoute ? "Continue" : "Close"}
              </Button>
            </div>
          </div>
        </Modal>
      )}

      <Toaster {...toaster} closeToaster={closeToaster} />
    </React.Fragment>
  );
};

export default withRouter(WhatsappForm);
