import {
  getRequest,
  postRequest,
  patchRequest,
  deleteRequest,
} from "../../api";

import {
  WIDGETS_LOADER,
  WIDGETS_SUBMITTING,
  WIDGETS_RESPONSE,
  WIDGET_RESPONSE,
} from "../constants";
import { getRequestError } from "../../utils/functions";

export const isLoadingWidgets = (isLoadingWidgets) => ({
  type: WIDGETS_LOADER,
  isLoadingWidgets,
});

export const isSubmittingWidget = (isSubmittingWidget) => ({
  type: WIDGETS_SUBMITTING,
  isSubmittingWidget,
});

export const widgetsResponse = (
  widgetsOutcome,
  widgetsData,
  widgetsMessage
) => ({
  type: WIDGETS_RESPONSE,
  widgetsOutcome,
  widgetsData,
  widgetsMessage,
});

export const widgetResponse = (widgetOutcome, widgetData, widgetMessage) => ({
  type: WIDGET_RESPONSE,
  widgetOutcome,
  widgetData,
  widgetMessage,
});

export const getWidgetsAction = (params = {}) => {
  return async (dispatch) => {
    dispatch(isLoadingWidgets(true));

    try {
      const res = await getRequest({
        url: "/firms/widget",
        params: params?.params,
        token: true,
      });
      dispatch(widgetsResponse("success", res.data?.data, ""));
      dispatch(isLoadingWidgets(false));
    } catch (error) {
      dispatch(isLoadingWidgets(false));

      const message = getRequestError(error);
      dispatch(widgetsResponse("error", null, message));
    }
  };
};

export const postWidgetAction = (data) => {
  return async (dispatch) => {
    dispatch(isSubmittingWidget(true));

    try {
      const res = await postRequest({
        url: "/firms/widget",
        data,
        token: true,
      });

      dispatch(isSubmittingWidget(false));
      return res?.data;
    } catch (error) {
      dispatch(isSubmittingWidget(false));

      const message = getRequestError(error);
      dispatch(widgetResponse("error", null, message));
    }
  };
};

export const patchWidgetAction = (data) => {
  return async (dispatch) => {
    dispatch(isSubmittingWidget(true));

    try {
      const res = await patchRequest({
        url: `/firms/widget/${data?.id}`,
        data: data?.data,
        token: true,
      });

      dispatch(isSubmittingWidget(false));
      return res?.data;
    } catch (error) {
      dispatch(isSubmittingWidget(false));

      const message = getRequestError(error);
      dispatch(widgetResponse("error", null, message));
    }
  };
};

export const deleteWidgetAction = (data) => {
  return async (dispatch) => {
    dispatch(isSubmittingWidget(true));

    try {
      const res = await deleteRequest({
        url: `/firms/widget/${data?.id}`,
        token: true,
      });

      dispatch(isSubmittingWidget(false));
      return res?.data;
    } catch (error) {
      dispatch(isSubmittingWidget(false));

      const message = getRequestError(error);
      dispatch(widgetResponse("error", null, message));
    }
  };
};
