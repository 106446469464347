import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Papa from "papaparse";
import * as moment from "moment";
import Select from "react-select";
import mixpanel from "mixpanel-browser";

import {
  getCustomersAction,
  importCustomersAction,
} from "../../redux/actions/Customers";

import { CircularCheckGreen } from "../../assets/vectors";
import { Button } from "../../components/FormElements";
import Modal from "../../components/Modal";
import FormNotifications from "../../components/Notifications";

import GroupForm from "../Settings/Groups/GroupForm";

const ImportCustomers = ({ close }) => {
  const dispatch = useDispatch();

  const {
    isImportingCustomers,
    importCustomersOutcome,
    importCustomersMessage,
  } = useSelector((state) => state.customers);
  const { groupsData } = useSelector((state) => state.groups);
  const { customFieldsData } = useSelector((state) => state.customFields);

  const [currentStep, setCurrentStep] = useState(1);
  const [file, setFile] = useState(null);
  const [generatedJson, setGeneratedJson] = useState(null);
  const [generatedCsv, setGeneratedCsv] = useState(null);
  const [groups, setGroups] = useState([]);
  const [isNewGroupFormActive, setIsNewGroupFormActive] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const customFieldDefaultValues = (type) => {
    if (type === "single_line_text") {
      return "Single line text.";
    } else if (type === "multi_line_text") {
      return `Multi line text /n goes here.`;
    } else if (type === "number") {
      return "0123456789";
    } else if (type === "attachment") {
      return "https://s3.eu-west-3.amazonaws.com/oneroute.dev.asb.ng/1669203613976-placeholder-img.png";
    } else if (type === "single_select") {
      return "A selection";
    } else if (type === "multi_select") {
      return "One, Two, Three";
    } else if (type === "date") {
      return `${moment().format("DD MMM, YYYY")}`;
    }

    return "";
  };

  useEffect(() => {
    const defaultFieldsData = {
      countryCode: "234",
      email: "johndoe@gmail.com",
      name: "John Doe",
      phone: "9012234567",
    };
    setGeneratedCsv([defaultFieldsData]);

    var cFields =
      (Array.isArray(customFieldsData) &&
        customFieldsData?.map((x) => ({
          key: x?.slug,
          value: customFieldDefaultValues(x?.type),
        }))) ||
      [];

    var cFieldsResult = {};
    for (var i = 0; i < cFields.length; i++) {
      cFieldsResult[cFields[i].key] = cFields[i].value;
    }

    cFieldsResult = {
      ...defaultFieldsData,
      ...cFieldsResult,
    };
    setGeneratedCsv([cFieldsResult]);
  }, [customFieldsData]);

  const downloadGeneratedCSV = () => {
    var csv = Papa.unparse(generatedCsv);

    var csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    var csvURL = window.URL.createObjectURL(csvData);

    var downloadLink = document.createElement("a");
    downloadLink.href = csvURL;
    downloadLink.setAttribute("download", "Import-customer-sample.csv");
    downloadLink.click();
  };

  const validateUpload = () => {
    dispatch(
      importCustomersAction({
        customers: generatedJson,
        groups: groups?.map((x) => x?.value),
      })
    ).then((res) => {
      if (res?.message) {
        mixpanel.track("Import Customers", {
          no_of_customers: res?.message,
        });
      }
      setSuccessMessage(res?.message);
      setErrorMessage(res?.error);
      dispatch(
        getCustomersAction({
          "page[size]": 25,
          "page[number]": 1,
        })
      );
    });
  };

  const handleUploadAndConvertToJson = () => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        setGeneratedJson(results.data);
        setCurrentStep(3);
      },
    });
  };

  const updateNewGroupSelections = (group) => {
    setGroups([...groups, { label: group?.name, value: group?.id }]);
  };

  const importSteps = [
    { id: 1, name: "Download template" },
    { id: 2, name: "Upload CSV" },
    { id: 3, name: "Import" },
  ];

  const groupOptions = groupsData?.map((x) => ({
    label: x?.name,
    value: x?.id,
  }));

  return (
    <>
      <Modal>
        <div className="email_setup_modal import_contact_modal">
          <div className="navigation">
            {importSteps?.map(({ id, name }) => (
              <div
                key={id}
                className={`nav-item ${currentStep === id ? "active" : ""} ${
                  currentStep > id ? "done" : ""
                }`}
              >
                <span>{currentStep > id ? <CircularCheckGreen /> : id}</span>
                {name}
              </div>
            ))}
          </div>

          {currentStep === 1 && (
            <div className="modal_content email_setup_container step_one">
              <div className="header">
                <h5>Download CSV template</h5>
                <p>
                  Download the sample template to see the required format for
                  importing customers into OneRoute.
                </p>

                <br />
                <Button
                  className="primary-button"
                  onClick={downloadGeneratedCSV}
                >
                  Download CSV
                </Button>
              </div>

              <div className="actions">
                <p className="secondary-button" onClick={close}>
                  Cancel
                </p>
                <Button
                  className="primary-button"
                  onClick={() => setCurrentStep(2)}
                >
                  Next step
                </Button>
              </div>
            </div>
          )}

          {currentStep === 2 && (
            <div className="modal_content email_setup_container step_two">
              <div className="header">
                <h5>Upload CSV</h5>
                <p>
                  Upload a .csv file containing the customers you wish to
                  import. Please note that CSV must match the template provided.
                </p>
              </div>

              <div className="content">
                <div className="d-flex">
                  <div className="input-container">
                    <input
                      className="input"
                      type="file"
                      name="file"
                      accept=".csv"
                      onChange={(e) => setFile(e.target.files[0])}
                    />
                  </div>
                </div>
              </div>

              <div className="actions">
                <p className="secondary-button" onClick={close}>
                  Cancel
                </p>
                <Button
                  className="primary-button"
                  disabled={!file}
                  onClick={handleUploadAndConvertToJson}
                >
                  Upload CSV
                </Button>
              </div>
            </div>
          )}

          {currentStep === 3 && (
            <div className="modal_content email_setup_container step_three">
              <div className="input-container mb-50">
                <label>Add Newly Imported Customers to Groups (Optional)</label>
                <Select
                  classNamePrefix="select_container"
                  value={groups}
                  options={[
                    ...(groupOptions || []),
                    { label: "+ Add new group", value: "add_new_group" },
                  ]}
                  onChange={(selections) => {
                    if (selections?.find((x) => x?.value === "add_new_group")) {
                      setIsNewGroupFormActive(true);
                    } else {
                      setGroups(selections);
                    }
                  }}
                  isClearable
                  isMulti
                />
              </div>

              <div className="actions">
                <p className="secondary-button" onClick={close}>
                  {successMessage || errorMessage ? "Finish" : "Cancel"}
                </p>
                <Button
                  className="primary-button"
                  disabled={
                    isImportingCustomers || successMessage || errorMessage
                  }
                  onClick={() => validateUpload()}
                >
                  {isImportingCustomers ? "Processing..." : "Import customers"}
                </Button>
              </div>

              {successMessage && (
                <>
                  <br />
                  <FormNotifications type="success" message={successMessage} />
                </>
              )}

              {errorMessage && (
                <FormNotifications type="error" message={errorMessage} />
              )}

              {importCustomersOutcome && (
                <>
                  <br />
                  <FormNotifications
                    type="error"
                    message={importCustomersMessage}
                  />
                </>
              )}
            </div>
          )}
        </div>
      </Modal>

      {isNewGroupFormActive && (
        <GroupForm
          toggleGroupForm={() => setIsNewGroupFormActive(false)}
          newlyCreatedGroup={(group) => updateNewGroupSelections(group)}
        />
      )}
    </>
  );
};

export default ImportCustomers;
