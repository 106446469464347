import {
  GET_ROLES_RESPONSE,
  CREATE_AGENTS_LOADER,
  CREATE_AGENTS_RESPONSE,
  CREATE_AGENTS_RESET,
  CONFIRM_AGENTS_LOADER,
  CONFIRM_AGENTS_RESPONSE,
  CONFIRM_AGENTS_RESET,
  UPDATE_AGENTS_LOADER,
  UPDATE_AGENTS_RESPONSE,
  UPDATE_AGENTS_RESET,
  GET_AGENTS_LOADER,
  GET_AGENTS_RESPONSE,
  DELETE_AGENTS_LOADER,
  DELETE_AGENTS_RESPONSE,
  DELETE_AGENTS_RESET,
} from "../constants";

const INIT_STATE = {
  rolesData: [],
  rolesMeta: {},
  agentsData: [],
  agentsMeta: {
    limit: 10,
    offset: 0,
    total: 0,
  },
  isCreatingAgent: false,
  isConfirmingAgent: false,
  isUpdatingAgent: false,
  isDeletingAgent: false,
  isLoadingAgents: true,
  rolesOutcome: "",
  rolesMessage: "",
  createAgentsOutcome: "",
  createAgentsMessage: "",
  confirmAgentsOutcome: "",
  confirmAgentsMessage: "",
  updateAgentsOutcome: "",
  updateAgentsMessage: "",
  deleteAgentsOutcome: "",
  deleteAgentsMessage: "",
  agentsOutcome: "",
  agentsMessage: "",
};

const Agents = (state = INIT_STATE, action) => {
  const {
    type,
    rolesData,
    rolesMeta,
    rolesOutcome,
    rolesMessage,
    createAgentsOutcome,
    createAgentsMessage,
    confirmAgentsOutcome,
    confirmAgentsMessage,
    updateAgentsOutcome,
    updateAgentsMessage,
    deleteAgentsOutcome,
    deleteAgentsMessage,
    agentsOutcome,
    agentsMessage,
    agentsData,
    agentsMeta,
    isCreatingAgent,
    isConfirmingAgent,
    isUpdatingAgent,
    isDeletingAgent,
    isLoadingAgents,
  } = action;

  switch (type) {
    case GET_ROLES_RESPONSE: {
      return {
        ...state,
        rolesOutcome,
        rolesData,
        rolesMeta,
        rolesMessage,
      };
    }

    case CREATE_AGENTS_LOADER: {
      return {
        ...state,
        isCreatingAgent,
      };
    }

    case CREATE_AGENTS_RESPONSE: {
      return {
        ...state,
        createAgentsOutcome,
        createAgentsMessage,
      };
    }

    case CREATE_AGENTS_RESET: {
      return {
        ...state,
        createAgentsOutcome: "",
        createAgentsMessage: "",
      };
    }
    case CONFIRM_AGENTS_LOADER: {
      return {
        ...state,
        isConfirmingAgent,
      };
    }

    case CONFIRM_AGENTS_RESPONSE: {
      return {
        ...state,
        confirmAgentsOutcome,
        confirmAgentsMessage,
      };
    }

    case CONFIRM_AGENTS_RESET: {
      return {
        ...state,
        confirmAgentsOutcome: "",
        confirmAgentsMessage: "",
      };
    }

    case UPDATE_AGENTS_LOADER: {
      return {
        ...state,
        isUpdatingAgent,
      };
    }

    case UPDATE_AGENTS_RESPONSE: {
      return {
        ...state,
        updateAgentsOutcome,
        updateAgentsMessage,
      };
    }

    case UPDATE_AGENTS_RESET: {
      return {
        ...state,
        updateAgentsOutcome: "",
        updateAgentsMessage: "",
      };
    }

    case DELETE_AGENTS_LOADER: {
      return {
        ...state,
        isDeletingAgent,
      };
    }

    case DELETE_AGENTS_RESPONSE: {
      return {
        ...state,
        deleteAgentsOutcome,
        deleteAgentsMessage,
      };
    }

    case DELETE_AGENTS_RESET: {
      return {
        ...state,
        deleteAgentsOutcome: "",
        deleteAgentsMessage: "",
      };
    }

    case GET_AGENTS_LOADER: {
      return {
        ...state,
        isLoadingAgents,
      };
    }

    case GET_AGENTS_RESPONSE: {
      return {
        ...state,
        agentsOutcome,
        agentsData,
        agentsMeta,
        agentsMessage,
      };
    }

    default:
      return state;
  }
};

export default Agents;
