import React from "react";
import classNames from "classnames";

import { Check, Close } from "../../assets/vectors";

const FormNotification = ({ topBarNotification, type, message }) => (
  <div
    className={classNames("form-notification-container", {
      "topbar-notification": topBarNotification,
      success: type === "success",
      error: type === "error",
      notify: type === "notify",
    })}
  >
    {topBarNotification ? (
      topBarNotification
    ) : (
      <>
        <span className="icon">
          {type === "success" ? <Check /> : <Close />}
        </span>
        <span className="message">{message}</span>
      </>
    )}
  </div>
);

export default FormNotification;
