import { getRequest, patchRequest } from "../../api";

import {
  AUTO_ASSIGNS_LOADER,
  AUTO_ASSIGNS_SUBMITTING,
  AUTO_ASSIGNS_RESPONSE,
  AUTO_ASSIGN_RESPONSE,
} from "../constants";
import { getRequestError } from "../../utils/functions";

export const isLoadingAutoAssigns = (isLoadingAutoAssigns) => ({
  type: AUTO_ASSIGNS_LOADER,
  isLoadingAutoAssigns,
});

export const isSubmittingAutoAssigns = (isSubmittingAutoAssigns) => ({
  type: AUTO_ASSIGNS_SUBMITTING,
  isSubmittingAutoAssigns,
});

export const autoAssignsResponse = (
  autoAssignsOutcome,
  autoAssignsData,
  autoAssignsMessage
) => ({
  type: AUTO_ASSIGNS_RESPONSE,
  autoAssignsOutcome,
  autoAssignsData,
  autoAssignsMessage,
});

export const autoAssignResponse = (
  autoAssignOutcome,
  autoAssignData,
  autoAssignMessage
) => ({
  type: AUTO_ASSIGN_RESPONSE,
  autoAssignOutcome,
  autoAssignData,
  autoAssignMessage,
});

export const getAutoAssignsAction = () => {
  return async (dispatch) => {
    dispatch(isLoadingAutoAssigns(true));

    try {
      const res = await getRequest({
        url: "/firms/auto-assign",
        token: true,
      });
      dispatch(autoAssignsResponse("success", res.data?.data, ""));
      dispatch(isLoadingAutoAssigns(false));
    } catch (error) {
      dispatch(isLoadingAutoAssigns(false));

      const message = getRequestError(error);
      dispatch(autoAssignsResponse("error", null, message));
    }
  };
};

export const patchAutoAssignsAction = (data) => {
  console.log(data, "data");
  return async (dispatch) => {
    dispatch(isSubmittingAutoAssigns(true));

    try {
      const res = await patchRequest({
        url: `/firms/auto-assign`,
        data,
        token: true,
      });

      dispatch(isSubmittingAutoAssigns(false));
      return res?.data;
    } catch (error) {
      dispatch(isSubmittingAutoAssigns(false));

      const message = getRequestError(error);
      dispatch(autoAssignResponse("error", null, message));
    }
  };
};
