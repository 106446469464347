import React from "react";
import * as _ from "lodash";
import * as moment from "moment";

import Modal from "../../../components/Modal";
import { Button } from "../../../components/FormElements";

const AuditTrail = ({ close, auditTrailData }) => {
  const allTrailData = auditTrailData
    ?.map((trail, key) => ({
      convoId: Object.keys(trail)[0],
      trail: Object.values(trail)[0].map((item) => item),
    }))
    ?.reverse();

  const groupedData = (data) => {
    return _.chain(
      data?.map((trail) => ({
        ...trail,
        formattedDate: moment(trail?.createdAt).format("MMM DD, YYYY"),
      }))
    )
      .groupBy("formattedDate")
      .map((value, key) => ({
        groupDate: key,
        data: value,
      }))
      .value();
  };

  const textToDisplay = (item) => {
    if (item.event === "CREATED") return "This conversation was started.";
    if (item.event === "ASSIGNED")
      return `${item?.oldData || item?.newData} assigned this conversation to ${
        item?.newData
      }.`;
    if (item.event === "STATUS_CHANGED")
      return `${item?.User?.firstName} ${item?.User?.lastName} changed the status of this conversation from ${item?.oldData} to ${item?.newData}.`;
    if (item.event === "OPENED")
      return `${item?.User?.firstName} ${item?.User?.lastName} opened this conversation.`;
    if (item.event === "CLOSED")
      return `${item?.User?.firstName} ${item?.User?.lastName} closed this conversation.`;
    return "";
  };

  return (
    <Modal closeModal={() => close()}>
      <div className="modal-content audit-trail-container">
        <div className="modal-title">
          <span>Conversation Activity Log</span>
        </div>
        <div className="trail-container">
          {allTrailData?.map((trail, index) => (
            <div key={index} className="convo">
              <p className="convo_id">
                CONVO ID: <span>{trail.convoId}</span>
              </p>

              <div className="header d-grid">
                <p>Date</p>
                <p>Activity</p>
              </div>

              {groupedData(trail.trail)?.map((item, key) => (
                <div key={key} className="trail-item">
                  <div className="date">{item.groupDate}</div>
                  <div className="list">
                    {item.data?.map((row, i) => (
                      <div key={i} className="list-item d-grid">
                        <h6>{moment(row.createdAt).format("hh:mm a")}</h6>
                        <p className="text">{textToDisplay(row)}</p>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className="modal-form-action">
          <Button
            type="button"
            className="secondary-button"
            onClick={() => close()}
          >
            Close
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AuditTrail;
