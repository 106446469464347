import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

import {
  createAutoResponseAction,
  updateAutoResponseAction,
} from "../../../redux/actions/AutoResponses";

import { Button, TextArea } from "../../../components/FormElements";
import Modal from "../../../components/Modal";
import FormNotifications from "../../../components/Notifications";

const AutoResponseForm = ({
  firmChannelsData,
  autoResponseData,
  toggleAutoResponseForm,
  getAutoResponsesAction,
}) => {
  const dispatch = useDispatch();

  const defaultChannels = autoResponseData?.Channels?.map((x) => ({
    label: `(${x?.medium}) - ${x?.name}`,
    value: x?.id,
  }));

  const {
    createAutoResponseOutcome,
    createAutoResponseMessage,
    updateAutoResponseOutcome,
    updateAutoResponseMessage,
    isCreatingAutoResponse,
    isUpdatingAutoResponse,
  } = useSelector((state) => state.autoResponses);

  const [formData, setFormData] = useState({
    message: autoResponseData?.message || "",
    channels: defaultChannels || [],
  });

  const handleChange = ({ target }) => {
    setFormData({
      ...formData,
      [target.name]: target.value,
    });
  };

  const checkFormValidity = () => {
    let value = true;
    if (
      !formData?.message ||
      formData?.channels?.length < 1 ||
      isCreatingAutoResponse ||
      isUpdatingAutoResponse
    ) {
      return false;
    }

    return value;
  };

  const submitForm = (e) => {
    e.preventDefault();

    const payload = {
      message: formData?.message,
      channels: formData?.channels?.map((x) => x?.value),
    };

    if (autoResponseData) {
      dispatch(updateAutoResponseAction(payload, autoResponseData?.id)).then(
        (res) => {
          if (res === 200) {
            getAutoResponsesAction();
            toggleAutoResponseForm();
            setFormData({});
          }
        }
      );
    } else {
      dispatch(createAutoResponseAction(payload)).then((res) => {
        if (res === 200) {
          getAutoResponsesAction();
          toggleAutoResponseForm();
          setFormData({});
        }
      });
    }
  };

  const channelOptions = firmChannelsData
    ?.filter((x) => x.status === "ACTIVE")
    ?.map((x) => ({
      label: `(${x?.medium}) - ${x?.name}`,
      value: x?.id,
    }));

  return (
    <Modal closeModal={() => toggleAutoResponseForm()}>
      <div className="modal-content">
        <div className="modal-title">
          {autoResponseData ? "Edit" : "New"} auto response
        </div>
        <div className="modal-form saved-response-form">
          <form onSubmit={submitForm}>
            <div className="input-container mb-25">
              <label>Channels</label>
              <Select
                classNamePrefix="select_container"
                value={formData?.channels}
                options={channelOptions}
                onChange={(selections) =>
                  setFormData({ ...formData, channels: selections })
                }
                isDisabled={isCreatingAutoResponse || isUpdatingAutoResponse}
                isClearable
                isMulti
              />
            </div>
            <TextArea
              label="Message"
              name="message"
              className="mb-30"
              value={formData?.message}
              onChange={(e, valid) => handleChange(e, valid)}
              showEmoji
              onFileLoad={() => {}}
              onFileError={() => {}}
            />

            <div className="modal-form-action">
              <Button
                type="button"
                className="secondary-button"
                onClick={() => toggleAutoResponseForm()}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="primary-button"
                disabled={!checkFormValidity()}
              >
                {isCreatingAutoResponse || isUpdatingAutoResponse
                  ? "Processing..."
                  : autoResponseData
                  ? "Save Changes"
                  : "Add auto response"}
              </Button>
            </div>

            {createAutoResponseOutcome && (
              <FormNotifications
                type={createAutoResponseOutcome}
                message={createAutoResponseMessage}
              />
            )}
            {updateAutoResponseOutcome && (
              <FormNotifications
                type={updateAutoResponseOutcome}
                message={updateAutoResponseMessage}
              />
            )}
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default AutoResponseForm;
