import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as moment from "moment";

import {
  getBroadcastAction,
  deleteBroadcastAction,
} from "../../redux/actions/Broadcast";

import { broadcastPermissions } from "../../utils/permissions";

import { Button, TextField } from "../../components/FormElements";
import Table from "../../components/Table";
import Modal from "../../components/Modal";
import OptionsDropdown from "../../components/OptionsDropdown";

import { CircularPlus, Search, Trash } from "../../assets/vectors";
import Toaster from "../../components/Toaster";
import { APP_CREATED_FIRST_BROADCAST_MSG } from "../../utils/constants";
import { getUserDetails } from "../../utils/functions";

const Broadcasts = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    isLoadingBroadcasts,
    isDeletingBroadcast,
    broadcastData,
    broadcastMeta,
  } = useSelector((state) => state.broadcast);

  const [showBroadcastPageInfo, setShowBroadcastPageInfo] = useState(true);
  const [selectedBroadcast, setSelectedBroadcast] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [toast, setToast] = useState({});
  const [query, setQuery] = useState({
    limit: 25,
    page: 1,
  });

  useEffect(() => {
    dispatch(getBroadcastAction({ params: query }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    const { firm_id } = getUserDetails();
    const hasFirmCreatedFirstBroadcastMsg =
      JSON.parse(
        window.localStorage.getItem(APP_CREATED_FIRST_BROADCAST_MSG)
      ) || null;

    if (
      broadcastData?.length > 0 &&
      !hasFirmCreatedFirstBroadcastMsg?.firm_id !== firm_id
    ) {
      const data = {
        firm_id,
        value: true,
      };

      localStorage.setItem(
        APP_CREATED_FIRST_BROADCAST_MSG,
        JSON.stringify(data)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [broadcastData]);

  const deleteBroadcast = () => {
    dispatch(deleteBroadcastAction(selectedBroadcast?.id)).then((res) => {
      if (res?.success === true) {
        dispatch(getBroadcastAction({ params: query }));
        setShowConfirmationModal(false);
        openToast("success", "Broadcast deleted successfully!");
      }
    });
  };

  const filterTable = (type, value) => {
    setQuery({
      ...query,
      [type]: value,
    });
  };

  const paginateTable = (pageNumber) => {
    setQuery({
      ...query,
      page: pageNumber,
    });
  };

  const moreOptions = () => {
    var options = [];

    if (broadcastPermissions?.delete) {
      options = [
        ...options,
        {
          label: (
            <div className="red">
              <span className="icon">
                <Trash />
              </span>{" "}
              <span className="text">Delete</span>
            </div>
          ),
          value: "delete",
        },
      ];
    }

    return options;
  };

  const optionsAction = (type, row) => {
    if (type === "delete") {
      setSelectedBroadcast(row);
      setShowConfirmationModal(!showConfirmationModal);
    }
  };

  const openToast = (status, content) => {
    setToast({
      open: "open",
      status,
      content,
    });
  };

  const closeToast = () => {
    setToast({
      ...toast,
      open: "closed",
    });
  };

  const columns = [
    {
      id: "",
      label: "Name",
      render: ({ id, name }) => (
        <div
          className="customer-name"
          onClick={() => history.push(`/broadcasts/${id}`)}
        >
          <span className="primary-link blue">{name}</span>
        </div>
      ),
    },
    {
      id: "",
      label: "Status",
      render: ({ status }) => <span>{status}</span>,
    },
    {
      id: "",
      label: "Message",
      render: ({ message }) => <span className="message">{message}</span>,
    },
    {
      id: "",
      label: "Created At",
      render: ({ createdAt }) => (
        <span>{moment(createdAt).format("MMM DD, YYYY")}</span>
      ),
    },
    {
      id: "",
      label: "Actions",
      thClassName: "text-center",
      render: (row) => (
        <div className="text-center">
          <OptionsDropdown
            onClick={(val) => {
              optionsAction(val, row);
            }}
            options={moreOptions()}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="page-content full">
      <div className="settings-page-container">
        <div className="page-header">
          <span className="title">Broadcasts</span>
        </div>
        {showBroadcastPageInfo && (
          <div className="settings-page-info">
            <div>
              <span className="title">What are Broadcasts?</span>
              <span className="description">
                Broadcasts allows you send messages to multiple customers at
                once.
              </span>
              <Button
                className="plain-button"
                onClick={() => setShowBroadcastPageInfo(false)}
              >
                Got it
              </Button>
            </div>
            <div className="image">
              <img
                src={require("../../assets/images/customer-girl.png").default}
                alt=""
              />
            </div>
          </div>
        )}
        <div className="table-container agents-table broadcast-table">
          <div className="table-filter">
            <div className="table-search-filter">
              <TextField
                placeholder="Search broadcasts"
                defaultValue={""}
                leftIcon={<Search />}
                onChange={(e) => filterTable("searchQuery", e.target.value)}
                disabled
              />
            </div>
            <div className="table-other-filters">
              {(broadcastPermissions.create ||
                broadcastPermissions.modifyAll) && (
                <Button
                  className="primary-button mr-15"
                  onClick={() => history.push(`/broadcasts/new`)}
                >
                  <span className="icon">
                    <CircularPlus />
                  </span>
                  <span>New Broadcast</span>
                </Button>
              )}
            </div>
          </div>

          <div className="table-actions">
            <h6 className="table-counter">{broadcastMeta?.count} Broadcasts</h6>
          </div>
          <Table
            loading={isLoadingBroadcasts}
            columns={columns}
            rows={broadcastData}
            meta={broadcastMeta}
            deleteRow={() => setShowConfirmationModal(!showConfirmationModal)}
            paginateTable={paginateTable}
          />
        </div>
      </div>

      {showConfirmationModal && (
        <Modal closeModal={() => setShowConfirmationModal(false)} small>
          <div className="modal-content confirmation-modal">
            <div className="modal-icon red">
              <Trash />
            </div>
            <div className="modal-title">
              Are you sure you want to delete this broadcast?
            </div>
            <div className="modal-text">This action cannot be reversed</div>
            <div className="modal-action">
              <Button
                className="secondary-button red"
                type="button"
                onClick={deleteBroadcast}
                disabled={isDeletingBroadcast}
              >
                Delete Broadcast
              </Button>
              <Button
                className="plain-button black"
                type="button"
                onClick={() => setShowConfirmationModal(false)}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      )}

      <Toaster {...toast} closeToaster={closeToast} />
    </div>
  );
};

export default Broadcasts;
