import React from "react";

export default () => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.33328 2.74197L0.441528 0.850807L1.26694 0.0253906L3.15811 1.91714H12.0493C12.1402 1.91713 12.2299 1.93838 12.3111 1.97919C12.3924 2.01999 12.463 2.07923 12.5172 2.15216C12.5715 2.2251 12.608 2.30971 12.6238 2.39926C12.6396 2.4888 12.6342 2.58079 12.6081 2.66789L11.2081 7.33456C11.1721 7.45477 11.0983 7.56017 10.9976 7.63509C10.8969 7.71002 10.7748 7.75048 10.6493 7.75047H3.49994V8.91714H9.91661V10.0838H2.91661C2.7619 10.0838 2.61353 10.0223 2.50413 9.91295C2.39474 9.80356 2.33328 9.65518 2.33328 9.50047V2.74197ZM3.20828 12.4171C2.97621 12.4171 2.75365 12.325 2.58956 12.1609C2.42547 11.9968 2.33328 11.7742 2.33328 11.5421C2.33328 11.3101 2.42547 11.0875 2.58956 10.9234C2.75365 10.7593 2.97621 10.6671 3.20828 10.6671C3.44034 10.6671 3.6629 10.7593 3.827 10.9234C3.99109 11.0875 4.08328 11.3101 4.08328 11.5421C4.08328 11.7742 3.99109 11.9968 3.827 12.1609C3.6629 12.325 3.44034 12.4171 3.20828 12.4171ZM10.2083 12.4171C9.97621 12.4171 9.75365 12.325 9.58956 12.1609C9.42547 11.9968 9.33328 11.7742 9.33328 11.5421C9.33328 11.3101 9.42547 11.0875 9.58956 10.9234C9.75365 10.7593 9.97621 10.6671 10.2083 10.6671C10.4403 10.6671 10.6629 10.7593 10.827 10.9234C10.9911 11.0875 11.0833 11.3101 11.0833 11.5421C11.0833 11.7742 10.9911 11.9968 10.827 12.1609C10.6629 12.325 10.4403 12.4171 10.2083 12.4171Z"
      fill="#757F8A"
    />
  </svg>
);
