import {
  GET_INTEGRATIONS_LOADER,
  GET_INTEGRATIONS_RESPONSE,
  GET_INTEGRATIONS_RESET,
  CREATE_INTEGRATION_LOADER,
  CREATE_INTEGRATION_RESPONSE,
} from "../constants";

const INIT_STATE = {
  integrationsData: [],
  integrationsMeta: {
    limit: 10,
    offset: 0,
    total: 0,
  },
  isLoadingIntegrations: true,
  integrationsOutcome: "",
  integrationsMessage: "",
  isCreatingIntegration: false,
  createIntegrationOutcome: "",
  createIntegrationMessage: "",
};

const Integrations = (state = INIT_STATE, action) => {
  const {
    type,
    integrationsOutcome,
    integrationsMessage,
    integrationsData,
    integrationsMeta,
    isLoadingIntegrations,
    isCreatingIntegration,
    createIntegrationOutcome,
    createIntegrationMessage,
  } = action;

  switch (type) {
    case GET_INTEGRATIONS_LOADER: {
      return {
        ...state,
        isLoadingIntegrations,
      };
    }

    case GET_INTEGRATIONS_RESPONSE: {
      return {
        ...state,
        integrationsOutcome,
        integrationsData,
        integrationsMeta,
        integrationsMessage,
      };
    }

    case GET_INTEGRATIONS_RESET: {
      return INIT_STATE;
    }

    case CREATE_INTEGRATION_LOADER: {
      return {
        ...state,
        isCreatingIntegration,
      };
    }

    case CREATE_INTEGRATION_RESPONSE: {
      return {
        ...state,
        createIntegrationOutcome,
        createIntegrationMessage,
      };
    }

    default:
      return state;
  }
};

export default Integrations;
