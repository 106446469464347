import React from "react";

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    fill="none"
    viewBox="0 0 18 18"
  >
    <path
      fill="#757F8A"
      stroke="#757F8A"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M9.75 8.25a.75.75 0 10-1.5 0 .75.75 0 001.5 0zM4.5 8.25a.75.75 0 10-1.5 0 .75.75 0 001.5 0zM15 8.25a.75.75 0 10-1.5 0 .75.75 0 001.5 0z"
    ></path>
  </svg>
);
