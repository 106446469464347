import {
  GET_CONVERSATIONS_INSIGHTS_LOADER,
  GET_CONVERSATIONS_INSIGHTS_RESPONSE,
  GET_AGENTS_INSIGHTS_LOADER,
  GET_AGENTS_INSIGHTS_RESPONSE,
  GET_CUSTOMERS_INSIGHTS_LOADER,
  GET_CUSTOMERS_INSIGHTS_RESPONSE,
  GET_CHATBOT_INSIGHTS_LOADER,
  GET_CHATBOT_INSIGHTS_RESPONSE,
  GET_MESSAGE_LOGS_LOADER,
  GET_MESSAGE_LOGS_RESPONSE,
} from "../constants";

const INIT_STATE = {
  conversationsInsightsData: {},
  isLoadingConversationsInsights: false,
  conversationsInsightsOutcome: "",
  conversationsInsightsMessage: "",
  allConversations: {},
  openedConversations: {},
  closedConversations: {},
  convosInProgress: {},
  convosRepliedTo: {},
  awaitingResponse: {},
  busiestPeriod: {},
  csatRating: {},
  newConvosData: {},
  channelConvosData: {},
  dailyStatsConvoData: {},
  newVsRepliedConvosData: {},
  convosStatusData: {},
  convosTagsData: [],
  responsesData: {},
  resolutionData: {},
  csatData: {},
  agentsInsightsData: {},
  isLoadingAgentsInsights: false,
  agentsInsightsOutcome: "",
  agentsInsightsMessage: "",
  customersInsightsData: {},
  isLoadingCustomersInsights: false,
  customersInsightsOutcome: "",
  customersInsightsMessage: "",
  totalStatsData: {},
  newCustomersData: {},
  customersByChannelData: [],
  isLoadingChatbotInsights: false,
  chatbotInsightsOutcome: "",
  chatbotInsightsData: {},
  chatbotInsightsMessage: "",
  isLoadingMessageLogs: false,
  messageLogsOutcome: "",
  messageLogsData: [],
  messageLogsMessage: "",
};

const Insights = (state = INIT_STATE, action) => {
  const {
    type,
    conversationsInsightsOutcome,
    conversationsInsightsMessage,
    conversationsInsightsData,
    conversationsInsightsSection,
    isLoadingConversationsInsights,
    agentsInsightsOutcome,
    agentsInsightsMessage,
    agentsInsightsData,
    isLoadingAgentsInsights,
    customersInsightsOutcome,
    customersInsightsMessage,
    customersInsightsData,
    customersInsightsSection,
    isLoadingCustomersInsights,
    isLoadingChatbotInsights,
    chatbotInsightsOutcome,
    chatbotInsightsData,
    chatbotInsightsMessage,
    isLoadingMessageLogs,
    messageLogsOutcome,
    messageLogsData,
    messageLogsMessage,
  } = action;

  switch (type) {
    case GET_CONVERSATIONS_INSIGHTS_LOADER: {
      return {
        ...state,
        isLoadingConversationsInsights,
      };
    }

    case GET_CONVERSATIONS_INSIGHTS_RESPONSE: {
      return {
        ...state,
        conversationsInsightsOutcome,
        [conversationsInsightsSection]: conversationsInsightsData,
        conversationsInsightsMessage,
      };
    }

    case GET_AGENTS_INSIGHTS_LOADER: {
      return {
        ...state,
        isLoadingAgentsInsights,
      };
    }

    case GET_AGENTS_INSIGHTS_RESPONSE: {
      return {
        ...state,
        agentsInsightsOutcome,
        agentsInsightsData,
        agentsInsightsMessage,
      };
    }

    case GET_CUSTOMERS_INSIGHTS_LOADER: {
      return {
        ...state,
        isLoadingCustomersInsights,
      };
    }

    case GET_CUSTOMERS_INSIGHTS_RESPONSE: {
      return {
        ...state,
        customersInsightsOutcome,
        [customersInsightsSection]: customersInsightsData,
        customersInsightsMessage,
      };
    }

    case GET_CHATBOT_INSIGHTS_LOADER: {
      return {
        ...state,
        isLoadingChatbotInsights,
      };
    }

    case GET_CHATBOT_INSIGHTS_RESPONSE: {
      return {
        ...state,
        chatbotInsightsOutcome,
        chatbotInsightsData,
        chatbotInsightsMessage,
      };
    }

    case GET_MESSAGE_LOGS_LOADER: {
      return {
        ...state,
        isLoadingMessageLogs,
      };
    }

    case GET_MESSAGE_LOGS_RESPONSE: {
      return {
        ...state,
        messageLogsOutcome,
        messageLogsData,
        messageLogsMessage,
      };
    }

    default:
      return state;
  }
};

export default Insights;
