import React from "react";

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    fill="none"
    viewBox="0 0 12 12"
  >
    <g
      stroke="#757F8A"
      strokeLinecap="round"
      strokeLinejoin="round"
      clipPath="url(#expandClip0)"
    >
      <path d="M4.875 7.124l-4.5 4.5M11.625 3.749V.374H8.25M.375 8.249v3.375H3.75M11.625.374l-4.5 4.5"></path>
    </g>
    <defs>
      <clipPath id="expandClip0">
        <path fill="#fff" d="M0 0H12V12H0z"></path>
      </clipPath>
    </defs>
  </svg>
);
