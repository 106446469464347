import React, { Component } from "react";
import classNames from "classnames";
import * as shallowequal from "shallowequal";
import { AngleRight } from "../../assets/vectors";

class Table extends Component {
  state = {
    currentPage: 0,
    totalPages: 0,
    paginationList: [],
    activeOptionsDropdown: false,
  };

  paginateTable = (page) => {
    if (page <= this.state.totalPages && page > 0) {
      this.props.paginateTable(page);
    }
  };

  componentDidUpdate(prevProps) {
    const { rows, meta } = this.props;

    if (
      !shallowequal(prevProps.rows, rows) &&
      meta &&
      Object.keys(meta)?.length
    ) {
      const { currentPage, totalPage } = meta || {};

      if (currentPage && totalPage) {
        const pageNumbers = Array(totalPage)
          .fill(0)
          .map((x, i) => i + 1);

        const paginationBase = Math.floor((currentPage - 1) / 5) * 5;
        const paginationStart = paginationBase + 1;
        const paginationEnd = paginationBase + 5;

        const paginationList = pageNumbers.filter(
          (page) => page >= paginationStart && page <= paginationEnd
        );

        this.setState({
          totalPages: totalPage,
          currentPage,
          paginationList,
        });
      }
    }
  }

  render() {
    const { currentPage, paginationList, totalPages } = this.state;
    const { columns, rows, loading, version2, noHeader, noDataText } =
      this.props;

    return (
      <div className={classNames("table", { version2 })}>
        <table>
          {!noHeader && (
            <thead>
              <tr>
                {columns?.map(({ label, thClassName }, index) => (
                  <th key={`th-${index}`} className={thClassName || ""}>
                    {label}
                  </th>
                ))}
              </tr>
            </thead>
          )}
          <tbody>
            {loading ? (
              <tr>
                <td className="text-center" colSpan={columns?.length}>
                  Loading...
                </td>
              </tr>
            ) : !!rows?.length ? (
              rows?.map((row, index) => (
                <tr key={`tr-${index}`}>
                  {columns?.map(({ id, render }, colIndex) => (
                    <td key={`td-${colIndex}`}>
                      <span>{render ? render(row) : row[id]}</span>
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td className="text-center" colSpan={columns?.length}>
                  {noDataText ? noDataText : "No data available"}
                </td>
              </tr>
            )}
          </tbody>
          {totalPages ? (
            <tfoot>
              <tr>
                <td className="pagination-td" colSpan={columns?.length + 1}>
                  <div className="pagination-container">
                    {totalPages ? (
                      <>
                        <div className="total-results">
                          Showing page {currentPage} of {totalPages}
                        </div>

                        <div className="pagination-actions">
                          <span
                            className={classNames("previous", {
                              disabled: currentPage === 1,
                            })}
                            onClick={() => this.paginateTable(currentPage - 1)}
                          >
                            <AngleRight />
                          </span>
                          {paginationList.map((p, idx) => (
                            <span
                              className={classNames("", {
                                current: currentPage === p,
                              })}
                              key={`paginate-${idx}`}
                              onClick={() => this.paginateTable(p)}
                            >
                              {p}
                            </span>
                          ))}
                          <span
                            className={classNames("next", {
                              disabled: currentPage === totalPages,
                            })}
                            onClick={() => this.paginateTable(currentPage + 1)}
                          >
                            <AngleRight />
                          </span>
                        </div>
                      </>
                    ) : null}
                  </div>
                </td>
              </tr>
            </tfoot>
          ) : null}
        </table>
      </div>
    );
  }
}

export default Table;
