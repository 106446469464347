import React from "react";
import DatePicker from "react-datepicker";
import Select from "react-select";
import * as moment from "moment";

import { Button } from "../../components/FormElements";
import Modal from "../../components/Modal";

import { Calendar, Close } from "../../assets/vectors";

import { ALL_CHANNELS } from "../../utils/constants";

const FilterCustomers = ({
  close,
  groups,
  groupOptions,
  mediums,
  startDate,
  endDate,
  onGroupsChange,
  onChannelsChange,
  onDateChange,
  clearDatePicker,
  filterCustomers,
}) => {
  const channelOptions = ALL_CHANNELS?.map((x) => ({
    label: x?.name,
    value: x?.name,
  }));

  const datePickerDisplayValue = (sDate, eDate) => {
    if (sDate || eDate) {
      return `${
        moment(sDate).format("MMM DD, YYYY") +
        " - " +
        `${
          eDate
            ? moment(eDate).format("MMM DD, YYYY")
            : moment(sDate).format("MMM DD, YYYY")
        }`
      }`;
    } else {
      return "Choose date range";
    }
  };

  const datePickerConfig = {
    className: "date_range_input",
    dateFormat: "yyyy-MM-dd",
    // minDate: new Date(moment().subtract(120, "days")),
    maxDate: new Date(),
    selectsRange: "selectsRange",
    shouldCloseOnSelect: false,
  };

  return (
    <Modal closeModal={close}>
      <div className="modal-content filter-customers-modal">
        <div className="modal-title">Filter customers</div>

        <div className="modal-form">
          <div className="input-container mb-30">
            <label>Groups</label>
            <Select
              classNamePrefix="select_container"
              value={groups}
              options={groupOptions}
              onChange={(selections) => onGroupsChange(selections)}
              isClearable
              isMulti
            />
          </div>
          <div className="input-container mb-30">
            <label>Channels</label>
            <Select
              classNamePrefix="select_container"
              value={mediums}
              options={channelOptions}
              onChange={(selections) => onChannelsChange(selections)}
              isClearable
              isMulti
            />
          </div>
          <div className="input-container">
            <label>Date Added</label>
            <div className="date_range_picker">
              <Calendar />
              <DatePicker
                onChange={([start, end]) => onDateChange(start, end)}
                startDate={startDate}
                endDate={endDate}
                value={datePickerDisplayValue(startDate, endDate)}
                {...datePickerConfig}
              />
              {(startDate || endDate) && (
                <span onClick={clearDatePicker}>
                  <Close />
                </span>
              )}
            </div>
          </div>
        </div>

        <div className="modal-action">
          <Button className="plain-button black" type="button" onClick={close}>
            Cancel
          </Button>
          <Button
            className="primary-button"
            onClick={() => {
              filterCustomers();
              close();
            }}
          >
            Update filter
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default FilterCustomers;
