import React, { Component, createRef } from "react";

import { Play, Pause } from "../../assets/vectors";
import { secondsToMinsSecs } from "../../utils/functions";

class AudioPlayer extends Component {
  audioRef = createRef();

  togglePlay = () => {
    const audioPlayer = this.audioRef.current;
    audioPlayer.paused ? audioPlayer.play() : audioPlayer.pause();
  };

  render() {
    const { src } = this.props;

    const { currentTime, duration, paused } = this.audioRef
      ? this.audioRef.current || {}
      : {};

    return (
      <div className="audio-player-container">
        <audio
          ref={this.audioRef}
          onTimeUpdate={(e) =>
            this.setState({ currentTime: e.target.currentTime })
          }
          onLoadedMetadata={() => this.setState({ ready: true })}
        >
          <source src={src} />
        </audio>
        <span className="play" onClick={this.togglePlay}>
          {paused ? <Play /> : <Pause />}
        </span>
        <div className="player-progress">
          <span
            className="player-position-line"
            style={{
              width:
                currentTime === duration || currentTime === 0
                  ? 0
                  : `${(currentTime / duration) * 100}%`,
            }}
          ></span>
          <span
            className="player-position"
            style={{
              left:
                currentTime === duration || currentTime === 0
                  ? 0
                  : `${(currentTime / duration) * 100}%`,
            }}
          />
        </div>
        <span className="player-duration">
          {secondsToMinsSecs(currentTime === 0 ? duration : currentTime)}
        </span>
      </div>
    );
  }
}

export default AudioPlayer;
