import React from "react";
import {
  conversationsPermissions,
  broadcastPermissions,
  customersPermissions,
  insightsPermissions,
} from "../../utils/permissions";

import {
  Home,
  At,
  Messages,
  Broadcast,
  People,
  Analytics,
  Notifications,
  Settings,
} from "../../assets/vectors";

export const mainMenu = [
  {
    label: "Home",
    value: "/home",
    icon: <Home />,
    show: true,
  },
  {
    label: "Conversations",
    value: "/conversations",
    icon: <Messages />,
    badge: true,
    show: conversationsPermissions.read ? true : false,
  },
  {
    label: "Activity",
    value: "/activity",
    icon: <At />,
    show: conversationsPermissions.read ? true : false,
  },
  {
    label: "Broadcasts",
    value: "/broadcasts",
    icon: <Broadcast />,
    show: broadcastPermissions.read ? true : false,
  },
  {
    label: "Customers",
    value: "/customers",
    icon: <People />,
    show: customersPermissions.read ? true : false,
  },
  {
    label: "Insights",
    value: "/insights",
    icon: <Analytics />,
    show: insightsPermissions.read ? true : false,
  },
];

export const footerMenu = [
  {
    label: "Notifications",
    value: "#",
    icon: <Notifications />,
  },
  {
    label: "Settings",
    value: "/settings",
    icon: <Settings />,
  },
  {
    label: "Profile",
    value: "#",
    profile: true,
  },
];
