import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import * as moment from "moment";
import * as _ from "lodash";

import { Button } from "../../../components/FormElements";
import LottieAnimation from "../../../components/LottieWeb";
import OptionsDropdown from "../../../components/OptionsDropdown";

import AssignToAgent from "../../Conversations/ConversationLists/AssignToAgent";

import {
  AngleLeft,
  AngleDown,
  People,
  Twitter,
  FacebookMessenger,
  Instagram,
} from "../../../assets/vectors";

import { getInitials, getUserDetails } from "../../../utils/functions";
import { conversationsPermissions } from "../../../utils/permissions";

const MentionsList = ({
  selectedSection,
  selectMention,
  selectedMention,
  unreadMessages,
  isSearchActive,
  getOpenMentions,
  getQueueMentions,
  getAllMentions,
  assignMention,
}) => {
  const {
    openMentions,
    queueMentions,
    allMentions,
    isLoadingMentions,
    isLoadingMoreMentions,
  } = useSelector((state) => state.mentions);
  const {
    agentsData,
    isAssigningToAgent,
    assignToAgentOutcome,
    assignToAgentMessage,
  } = useSelector((state) => state.agents);

  // const selectedSection = "Assigned to me";

  const [hideDisplay, setHideDisplay] = useState(false);
  const [selectedQueueMentions, setSelectedQueueMentions] = useState([]);
  const [agent, setAgent] = useState(null);
  const [isAssignToAgentModalActive, setIsAssignToAgentModalActive] =
    useState(false);
  const [sortQueueByOldest, setSortQueueByOldest] = useState(true);

  useEffect(() => {
    document
      .getElementById("conversation-lists-container")
      .addEventListener("scroll", loadMore);
    return () => {
      document
        .getElementById("conversation-lists-container")
        .removeEventListener("scroll", loadMore);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadMoreRef = useRef();

  const loadMore = () => {
    const mentionsData =
      selectedSection === "Assigned to me"
        ? openMentions
        : selectedSection === "Queue"
        ? queueMentions
        : allMentions;

    const { currentPage, totalPage, data } = mentionsData;
    const loadMoreTop = loadMoreRef.current.getBoundingClientRect().top;
    const windowInnerHeight = window.innerHeight;

    if (
      loadMoreTop - windowInnerHeight < -100 &&
      currentPage < totalPage &&
      data.length &&
      !isLoadingMentions
    ) {
      selectedSection === "Assigned to me"
        ? getOpenMentions(true, true)
        : selectedSection === "Queue"
        ? getQueueMentions(
            true,
            true,
            sortQueueByOldest ? "-updatedAt" : "updatedAt"
          )
        : getAllMentions(true, true);
    }
  };

  const getChannelMediumIcon = (medium) => {
    switch (medium) {
      case "TWITTER":
        return <Twitter />;
      case "FACEBOOK":
        return <FacebookMessenger />;
      case "INSTAGRAM":
        return <Instagram />;
      default:
        return "";
    }
  };

  const handleSelectAllQueueMentions = () => {
    if (selectedQueueMentions?.length > 0) {
      setSelectedQueueMentions([]);
    } else {
      setSelectedQueueMentions(queueMentions?.data?.map((x) => x?.id));
    }
  };

  const handleSelectQueueMention = ({ target }) => {
    const isSelected = queueMentions?.data?.find(
      (x) => x?.id === target?.value
    )?.id;

    const hasAlreadyBeenSelected = selectedQueueMentions?.find(
      (x) => x === target?.value
    )
      ? true
      : false;

    if (hasAlreadyBeenSelected) {
      setSelectedQueueMentions(
        selectedQueueMentions?.filter((x) => x !== target?.value)
      );
    } else {
      setSelectedQueueMentions([...(selectedQueueMentions || []), isSelected]);
    }
  };

  const bulkActionOptionsSelect = (type) => {
    if (type === "assign_to_agent") {
      setIsAssignToAgentModalActive(true);
    }
  };

  const sortByOptionsSelect = (type) => {
    if (type === "oldest") {
      setSortQueueByOldest(true);
    }
    if (type === "latest") {
      setSortQueueByOldest(false);
    }

    getQueueMentions(
      true,
      true,
      sortQueueByOldest ? "-updatedAt" : "updatedAt"
    );
  };

  const handleAssignToAgent = () => {
    assignMention(selectedQueueMentions, agent?.value).then((res) => {
      if (res?.status === 200) {
        setIsAssignToAgentModalActive(false);
      }
    });
  };

  const mentionsData =
    selectedSection === "Assigned to me"
      ? openMentions
      : selectedSection === "Queue"
      ? queueMentions
      : allMentions;

  const mentions = _.chain(
    mentionsData?.data?.map((convo) => ({
      ...convo,
      formattedDate: moment(convo?.lastMessageAt || convo?.updatedAt).format(
        "MMM DD, YYYY"
      ),
    }))
  )
    ?.groupBy("formattedDate")
    ?.map((value, key) => ({ date: key, messages: value }))
    ?.value();

  const sortedMentions = mentions?.sort((a, b) => {
    const dateA = new Date(a?.date);
    const dateB = new Date(b?.date);
    return selectedSection === "Queue" && sortQueueByOldest === true
      ? dateA - dateB
      : dateB - dateA;
  });

  const checkUnreadMessages = (id) => {
    return unreadMessages.filter((msg) => msg === id).length;
  };

  const bulkActionOptions = [
    {
      label: (
        <div>
          <span className="icon">
            <People />
          </span>{" "}
          <span className="text">Assign to agent</span>
        </div>
      ),
      value: "assign_to_agent",
    },
  ];

  const sortByOptions = [
    {
      label: (
        <div>
          <span className="text">Older mentions</span>
        </div>
      ),
      value: "oldest",
    },
    {
      label: (
        <div>
          <span className="text">Newer mentions</span>
        </div>
      ),
      value: "latest",
    },
  ];

  const canUserModifyMention =
    conversationsPermissions.update || conversationsPermissions.modifyAll;

  return (
    <div
      id="conversation-lists-container"
      className={classNames(
        "conversation-lists-container mention-lists-container",
        {
          hideDisplay,
          searchActive: isSearchActive,
        }
      )}
    >
      <div className="toggle-container">
        <div
          className={classNames("toggle-display", {
            active: hideDisplay,
          })}
          onClick={() => setHideDisplay(!hideDisplay)}
        >
          <AngleLeft />
        </div>
      </div>

      <div className="title">
        <p>{selectedSection}</p>
      </div>

      {selectedSection === "Queue" && (
        <div className="bulk-actions">
          {canUserModifyMention && (
            <div className="checkbox_container">
              <input
                name="recipients"
                className="input"
                onChange={(e) => handleSelectAllQueueMentions(e)}
                type="checkbox"
                checked={selectedQueueMentions?.length === queueMentions?.count}
                required
              />
            </div>
          )}
          {selectedQueueMentions?.length > 0 ? (
            <div className="bulk_actions_dropdown">
              <OptionsDropdown
                dropdownPlaceholder={
                  <>
                    Action <AngleDown />
                  </>
                }
                onClick={(val) => {
                  bulkActionOptionsSelect(val);
                }}
                options={bulkActionOptions}
              />
            </div>
          ) : (
            <div className="bulk_actions_dropdown">
              <OptionsDropdown
                dropdownPlaceholder={
                  <>
                    Sort by <AngleDown />
                  </>
                }
                onClick={(val) => {
                  sortByOptionsSelect(val);
                }}
                options={sortByOptions}
              />
            </div>
          )}
        </div>
      )}

      <div className="conversation-list-items">
        {isLoadingMentions ? (
          <div className="loader-container text-center">
            <LottieAnimation
              path={require("../../../assets/jsons/loader.json")}
              autoplay
            />
          </div>
        ) : (
          sortedMentions.map(({ date, messages }, index) => {
            // This removes duplicate conversations
            const filteredMentions = messages?.filter(
              (
                (s) => (o) =>
                  ((k) => !s.has(k) && s.add(k))(
                    ["id"].map((k) => o[k]).join("|")
                  )
              )(new Set())
            );

            const topSortedMessages = filteredMentions?.sort((a, b) => {
              const dateA = new Date(a.lastMessageAt);
              const dateB = new Date(b.lastMessageAt);
              return selectedSection === "Queue" && sortQueueByOldest === true
                ? dateA - dateB
                : dateB - dateA;
            });

            return (
              <div key={`conversation-list-${index}`}>
                <div className="conversation-date">{date}</div>
                {topSortedMessages.map((msg, index2) => {
                  const {
                    id,
                    eventType,
                    medium,
                    customerName,
                    customerUsername,
                    businessUsername,
                    textOfStatusReplied,
                    text,
                    lastMessageAt,
                    updatedAt,
                  } = msg;

                  return (
                    <div
                      key={`conversation-item-${index2}`}
                      className={classNames("conversation-item", {
                        active: selectedMention.id === id,
                        unread: checkUnreadMessages(id),
                      })}
                      onClick={() => selectMention(msg)}
                    >
                      <div className="conversation-item-type">
                        <span
                          className={classNames("conversation-type", {
                            reply: eventType === "comment",
                          })}
                        >
                          {eventType}
                        </span>
                        <span className="conversation-time">
                          {moment(lastMessageAt || updatedAt).format("HH:mm")}
                        </span>
                      </div>
                      <div className="d-flex">
                        {selectedSection !== "Queue" ||
                        !canUserModifyMention ? (
                          <div className="avatar">
                            {customerName
                              ? getInitials(customerName, 2)
                              : customerUsername
                              ? getInitials(customerUsername, 2)
                              : ""}
                          </div>
                        ) : (
                          <>
                            {canUserModifyMention && (
                              <div className="checkbox_container">
                                <input
                                  name="recipients"
                                  id={id}
                                  value={id}
                                  className="input"
                                  onChange={(e) => handleSelectQueueMention(e)}
                                  type="checkbox"
                                  checked={selectedQueueMentions?.find(
                                    (x) => x === id
                                  )}
                                  required
                                />
                              </div>
                            )}
                          </>
                        )}
                        <div className="content">
                          <div className="conversation-item-header">
                            <span className="conversation-author">
                              {customerName || customerUsername}
                            </span>
                          </div>
                          <div className="conversation-item-preview-container">
                            <div className="conversation-item-preview">
                              {text || textOfStatusReplied}
                            </div>
                            {!!checkUnreadMessages(id) && (
                              <span className="unread-count">
                                {msg?.read === false
                                  ? checkUnreadMessages(id)
                                  : "1"}
                              </span>
                            )}
                          </div>
                          {selectedSection !== "Queue" ? (
                            <div className="conversation-item-channel">
                              <span>Via: {getChannelMediumIcon(medium)}</span>
                              <span>@{businessUsername}</span>
                            </div>
                          ) : (
                            <div className="conversation-item-channel with-hover">
                              <span>Via: {getChannelMediumIcon(medium)}</span>
                              <span>@{businessUsername}</span>
                              {canUserModifyMention && (
                                <Button
                                  className="conversation-button small"
                                  onClick={() =>
                                    assignMention(msg?.id, getUserDetails()?.id)
                                  }
                                >
                                  Accept
                                </Button>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })
        )}
        <div
          ref={loadMoreRef}
          className={classNames("loader-container no-height load-more", {
            active: isLoadingMoreMentions,
          })}
        >
          <LottieAnimation
            path={require("../../../assets/jsons/loader.json")}
            autoplay
          />
        </div>
      </div>

      {isAssignToAgentModalActive && (
        <AssignToAgent
          close={() => setIsAssignToAgentModalActive(false)}
          agent={agent}
          allAgents={agentsData}
          onChange={(selected) => setAgent(selected)}
          assignOnClick={() => handleAssignToAgent()}
          isAssigningToAgent={isAssigningToAgent}
          assignToAgentOutcome={assignToAgentOutcome}
          assignToAgentMessage={assignToAgentMessage}
        />
      )}
    </div>
  );
};

export default MentionsList;
