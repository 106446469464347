import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import * as moment from "moment";

import {
  getIntegrationsAction,
  getFirmIntegrationsAction,
  deactivateIntegrationAction,
} from "../../../redux/actions/Integrations";

import { Button } from "../../../components/FormElements";
import Modal from "../../../components/Modal";

import ZohoDeskSetup from "./SetupForms/ZohoDesk";
import FreshDeskSetup from "./SetupForms/FreshDesk";

import { AngleLeft, Trash } from "../../../assets/vectors";

import { integrationsPermissions } from "../../../utils/permissions";

const IntegrationDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  let { id } = useParams();

  const { isLoadingIntegrations, isCreatingIntegration } = useSelector(
    (state) => state.integrations
  );

  const [integrationInfo, setIntegrationInfo] = useState([]);
  const [integrationIsConnected, setIntegrationIsConnected] = useState(false);
  const [showIntegrationForm, setShowIntegrationForm] = useState({});
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);

  useEffect(() => {
    if (id) {
      getIntegrationInfo();
    }

    if (location?.search) setupIntegration();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getIntegrationInfo = () => {
    dispatch(getFirmIntegrationsAction({ type: id })).then((res) => {
      if (res?.[0]?.id) {
        setIntegrationInfo(res?.[0]);
        setIntegrationIsConnected(true);
      } else {
        setIntegrationIsConnected(false);
        getIntegrationInfoFromAll();
      }
    });
  };

  const getIntegrationInfoFromAll = () => {
    dispatch(getIntegrationsAction({ type: id })).then((res) => {
      setIntegrationInfo(res?.[0]);
    });
  };

  const setupIntegration = () => {
    switch (id) {
      case "zoho":
        return setShowIntegrationForm({
          name: "zoho",
          show: true,
        });
      case "freshDesk":
        return setShowIntegrationForm({
          name: "freshDesk",
          show: true,
        });
      default:
        return;
    }
  };

  const deactivateIntegration = () => {
    dispatch(
      deactivateIntegrationAction({
        id: integrationInfo?.id,
        deactivatedIntegrationFor: integrationInfo?.config?.name,
      })
    ).then((res) => {
      if (res?.data?.success === true) {
        setShowDeactivateModal(false);
        getIntegrationInfo();
      }
    });
  };

  const integrationDetail = integrationIsConnected
    ? integrationInfo?.config
    : integrationInfo;

  return (
    <div className="settings-page-container integration-details-page">
      <div
        className="back"
        onClick={() => history.push("/settings/integrations")}
      >
        <span className="icon">
          <AngleLeft />
        </span>
        <span className="text">Back to Integrations</span>
      </div>
      {isLoadingIntegrations ? (
        <div className="integration-section">
          <br />
          <br />
          <br />
          <div className="integration-section-title text-center">
            Loading...
          </div>
        </div>
      ) : (
        <>
          <div className="integration-details-header">
            <div className="left">
              <div className="item-info">
                <div className="logo">
                  <img
                    src={integrationDetail?.imageUrl}
                    alt="Integration logo"
                  />
                </div>
                <div>
                  <div className="title">{integrationDetail?.name}</div>
                  <div className="type">{integrationDetail?.genre}</div>
                </div>
              </div>
              <div className="item-description">
                {integrationDetail?.description}
              </div>
            </div>
            <div className="actions">
              {integrationIsConnected &&
              integrationInfo?.status === "active" ? (
                <>
                  {integrationsPermissions.delete && (
                    <Button
                      className="secondary-button red"
                      onClick={() => setShowDeactivateModal(true)}
                    >
                      Deactivate integration
                    </Button>
                  )}
                </>
              ) : (
                <>
                  {(integrationsPermissions.create ||
                    integrationsPermissions.modifyAll) && (
                    <Button
                      className="primary-button"
                      onClick={setupIntegration}
                    >
                      Setup integration
                    </Button>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="integration-details-content">
            <div className="overview">
              <div className="title">Overview</div>
              <p className="section-content">{integrationDetail?.overview}</p>
            </div>

            <div className="details">
              <div className="title">App Details</div>

              <div className="info">
                <h6 className="section-title">Categories</h6>
                <div className="categories">
                  {integrationDetail?.length > 0 &&
                    integrationDetail?.categories?.map((item, i) => (
                      <p className="section-content" key={i}>
                        {item}
                      </p>
                    ))}
                </div>
              </div>
              <div className="info">
                <h6 className="section-title">Updated</h6>
                <p className="section-content">
                  {moment(integrationInfo?.updatedAt).format("LL")}
                </p>
              </div>
            </div>
          </div>
        </>
      )}

      {showIntegrationForm?.show && showIntegrationForm?.name === "zoho" && (
        <ZohoDeskSetup
          close={() => {
            history.push(window.location.pathname);
            setShowIntegrationForm({});
          }}
          onSetupSuccess={() => getIntegrationInfo()}
        />
      )}

      {showIntegrationForm?.show && showIntegrationForm?.name === "freshDesk" && (
        <FreshDeskSetup
          close={() => {
            history.push(window.location.pathname);
            setShowIntegrationForm({});
          }}
          onSetupSuccess={() => getIntegrationInfo()}
        />
      )}

      {showDeactivateModal && (
        <Modal closeModal={() => setShowDeactivateModal(false)} small>
          <div className="modal-content confirmation-modal">
            <div className="modal-icon red">
              <Trash />
            </div>
            <div className="modal-title">
              Are you sure you want to deactivate this integration?
            </div>
            <div className="modal-action">
              <Button
                className="secondary-button red"
                type="button"
                onClick={() => deactivateIntegration()}
                disabled={isCreatingIntegration}
              >
                {isCreatingIntegration
                  ? "Processing..."
                  : "Deactivate Integration"}
              </Button>
              <Button
                className="plain-button black"
                type="button"
                onClick={() => setShowDeactivateModal(false)}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default IntegrationDetails;
