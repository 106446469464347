import React, { useState, useEffect } from "react";
import { withRouter, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  Spinner,
  Redirect,
  LogoSolid,
  Instagram,
} from "../../../../assets/vectors";
import { Button } from "../../../../components/FormElements";
import Modal from "../../../../components/Modal";

import { getWindowBaseUrl } from "../../../../utils/functions";

import {
  getMessengerRequestAction,
  postSubscribeToMessengerPageAction,
  getAllChannelsAction,
  getFirmChannelsAction,
} from "../../../../redux/actions/Channels";

const InstagramForm = ({ history, close, dismissRoute }) => {
  const dispatch = useDispatch();
  const { isCreatingChannel } = useSelector((state) => state?.channels);
  let location = useLocation();

  const [currentStep, setCurrentStep] = useState(1);
  const [instagramPages, setInstagramPages] = useState([]);
  const [selectedPage, setSelectedPage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const hash_url = "?" + location?.hash;
  const no_hash_url = hash_url.replace("#", "");
  const access_token =
    new URLSearchParams(no_hash_url).get("access_token") || null;

  useEffect(() => {
    if (access_token) getInstagramPages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [access_token]);

  const getInstagramRequestToken = () => {
    const windowBaseUrl = getWindowBaseUrl() || "";

    dispatch(
      getMessengerRequestAction({
        url: "/channels/instagram/oauth/request_token",
        params: {
          callbackUrl: `${windowBaseUrl}/4`,
        },
      })
    ).then((res) => {
      if (res?.data?.auth_url) {
        window.location.assign(res?.data?.auth_url);
      }
    });
  };

  const getInstagramPages = () => {
    dispatch(
      getMessengerRequestAction({
        url: "/channels/messenger/pages",
        params: {
          access_token,
        },
        stop_loader: true,
      })
    ).then((res) => {
      if (res?.data?.data) {
        setInstagramPages(res?.data?.data);
        setCurrentStep(3);
      }
    });
  };

  const postSubscribeToInstagramPage = () => {
    const requestData = selectedPage;

    dispatch(
      postSubscribeToMessengerPageAction({
        url: "/channels/instagram/subscribe",
        name: "INSTAGRAM",
        requestData,
      })
    ).then((res) => {
      if (res?.status === 200) {
        dispatch(getAllChannelsAction());
        dispatch(getFirmChannelsAction());
        setCurrentStep(4);
      } else {
        setErrorMessage(res);
      }
    });
  };

  return (
    <React.Fragment>
      {currentStep === 1 && (
        <Modal className="channel-modal">
          <div className="modal-content new-conversation-container">
            <div className="modal-form new-conversation-form instagram_step_one">
              <div className="logos">
                <LogoSolid />
                <Redirect />
                <Instagram />
              </div>
              <h5>Requirements for setting up Instagram</h5>
              <h6>
                Please make sure you have the following set up in order to
                connect your instagram to OneRoute.
              </h6>
              <div className="instagram_requirements">
                <ul>
                  <li>
                    Have an instagram business account, if you dont have one{" "}
                    <a
                      href="https://help.instagram.com/502981923235522?fbclid=IwAR2_w-Ab0Ffo0etcZmP5gMfk70mIEODAr9prOnbbEMDZ_8LIOUCzu55NrRc"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Click here to see the steps
                    </a>
                  </li>
                  <li>
                    Your instagram account should be connected to a facebook
                    page.{" "}
                    <a
                      href="https://help.instagram.com/176235449218188?helpref=hc_fnav"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Click here to see the steps
                    </a>
                  </li>
                </ul>
              </div>
              <div className="actions">
                <Button className="secondary-button" onClick={close}>
                  Cancel
                </Button>
                <Button
                  className="primary-button"
                  onClick={() => setCurrentStep(2)}
                >
                  Continue
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}

      {currentStep === 2 && (
        <Modal className="channel-modal">
          <div className="modal-content new-conversation-container">
            <div className="modal-title">
              You are about to be redirected to Facebook
            </div>
            <div className="modal-form new-conversation-form">
              <h6>
                Clicking continue will redirect you to Facebook. From there, you
                would need to authorize OneRoute to properly connect to your
                Instagram and Facebook pages.
              </h6>
              <div className="logos">
                <LogoSolid />
                <Redirect />
                <Instagram />
              </div>
              <div className="actions">
                {!isCreatingChannel ? (
                  <>
                    <Button className="secondary-button" onClick={close}>
                      Cancel
                    </Button>
                    <Button
                      className="primary-button"
                      onClick={getInstagramRequestToken}
                    >
                      Continue with Facebook
                    </Button>
                  </>
                ) : (
                  <div className="spinner">
                    <Spinner />
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal>
      )}

      {currentStep === 3 && (
        <Modal className="channel-modal">
          <div className="modal-content new-conversation-container">
            <div className="modal-title">
              Connect Facebook page with an Instagram account
            </div>
            <div className="modal-form new-conversation-form">
              <h6>
                Choose the page you would like to use to communicate with your
                customers on OneRoute.
              </h6>
              <div className="facebook_pages">
                {instagramPages?.map((item, i) => (
                  <div
                    key={i}
                    className="page"
                    onClick={() => {
                      setSelectedPage(item);
                      setErrorMessage(null);
                    }}
                  >
                    <h6>{item?.name}</h6>
                    <div
                      className={`check ${
                        selectedPage?.id === item?.id ? "active" : ""
                      }`}
                    >
                      <span></span>
                    </div>
                  </div>
                ))}
                {errorMessage && (
                  <div className="error_message">{errorMessage}</div>
                )}
              </div>
              <div className="actions">
                <Button className="secondary-button" onClick={close}>
                  Cancel
                </Button>
                <Button
                  className="primary-button"
                  onClick={postSubscribeToInstagramPage}
                  disabled={!selectedPage || isCreatingChannel}
                >
                  Continue
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}

      {currentStep === 4 && (
        <Modal className="channel-modal">
          <div className="modal-content new-conversation-container">
            <div className="modal-title">You’re almost done!</div>
            <div className="modal-form new-conversation-form">
              <div className="d-flex">
                <div className="info">
                  <ul>
                    <li>
                      To receive messages on OneRoute, allow access to messages
                      in your privacy settings.
                    </li>
                  </ul>
                </div>
              </div>
              <img
                className="instagram_next_steps"
                src="https://s3.eu-west-3.amazonaws.com/oneroute.asb.ng/instagram_final_stage.png"
                alt=""
              />
              <div className="actions">
                <Button
                  className="tertiary-button for_instagram"
                  onClick={() => setCurrentStep(5)}
                >
                  I have completed this
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}

      {currentStep === 5 && (
        <Modal className="channel-modal">
          <div className="modal-content new-conversation-container">
            <div className="modal-title">Channel setup complete</div>
            <div className="modal-form new-conversation-form">
              <div className="d-flex">
                <div className="info">
                  <ul>
                    <li>
                      You can now send and receive messages via Instagram.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="actions">
                <Button
                  className="primary-button"
                  onClick={() => {
                    setCurrentStep(null);
                    history.push(
                      dismissRoute ? dismissRoute : "/settings/channels"
                    );
                  }}
                >
                  Dismiss
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default withRouter(InstagramForm);
