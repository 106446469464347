import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { Close, Pencil } from "../../../../assets/vectors";

import { accountSettingsPermissions } from "../../../../utils/permissions";

import {
  getFirmConfigAction,
  postFirmUnitsConfigAction,
} from "../../../../redux/actions/AccountSettings";

const Notifications = ({
  isLoadingAccountSettings,
  accountSettingsData,
  isSubmittingAccountSettings,
  openToaster,
}) => {
  const dispatch = useDispatch();

  const [walletBalanceForm, setWalletBalanceForm] = useState({
    open: false,
    value: {
      notify: false,
      threshold: 0,
    },
  });

  const submitWalletBalanceForm = () => {
    if (walletBalanceForm?.value) {
      dispatch(
        postFirmUnitsConfigAction({
          notify: walletBalanceForm?.value?.notify,
          threshold: walletBalanceForm?.value?.threshold,
        })
      ).then((res) => {
        if (res?.success === true) {
          dispatch(getFirmConfigAction());
          setWalletBalanceForm({ open: false, value: {} });
          openToaster("success", res?.message);
        }
      });
    }
  };

  return (
    <div className="nav_content">
      <div className="tab_content">
        <h6 className="header">
          {isLoadingAccountSettings ? "Loading..." : "Notification Alerts"}
        </h6>

        {!isLoadingAccountSettings && (
          <div className="content">
            <div className="item">
              <p className="label">Low Wallet Balance</p>
              <div className="input_container">
                <p>
                  {accountSettingsData?.units?.notify === true ? "ON" : "OFF"}
                </p>
                {(accountSettingsPermissions?.create ||
                  accountSettingsPermissions?.update ||
                  accountSettingsPermissions?.modifyAll) && (
                  <span
                    className="copy_btn"
                    onClick={() =>
                      setWalletBalanceForm({
                        open: !walletBalanceForm?.open,
                        value: {
                          notify: accountSettingsData?.units?.notify,
                          threshold: accountSettingsData?.units?.threshold,
                        },
                      })
                    }
                  >
                    {walletBalanceForm?.open ? <Close /> : <Pencil />}
                  </span>
                )}
              </div>
            </div>
            {walletBalanceForm?.open && (
              <div className="item new_config_container">
                <p className="label">
                  Notify me when my wallet balance falls below:
                </p>
                <div className="input_container">
                  <input
                    className="input"
                    value={walletBalanceForm?.value?.threshold}
                    onChange={(e) =>
                      setWalletBalanceForm({
                        ...walletBalanceForm,
                        value: {
                          notify:
                            walletBalanceForm?.open === true ? true : false,
                          threshold: e?.target?.value,
                        },
                      })
                    }
                  />
                  <button
                    className="copy_btn"
                    onClick={() => submitWalletBalanceForm()}
                    disabled={isSubmittingAccountSettings}
                  >
                    {isSubmittingAccountSettings ? "Updating..." : "Update"}
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Notifications;
