import React from "react";

export default () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="#474F58"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
        d="M18 19v-2a4 4 0 00-4-4H6a4 4 0 00-4 4v2M10 9a4 4 0 100-8 4 4 0 000 8z"
      ></path>
    </svg>
)