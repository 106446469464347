import React, { Component } from "react";
import { connect } from "react-redux";

import {
  createTemplateMessageAction,
  getTemplateMessageAction,
} from "../../../redux/actions/TemplateMessage";

import {
  Button,
  TextArea,
  Select,
  TextField,
} from "../../../components/FormElements";
import Modal from "../../../components/Modal";
import FormNotifications from "../../../components/Notifications";

import { Trash } from "../../../assets/vectors";

import { randomText } from "../../../utils/functions";
import ReactSelect from "react-select";

const initialFormData = {
  name: {
    value: "",
    valid: false,
  },
  category: {
    value: "",
    valid: false,
  },
  expiration: {
    value: "",
    valid: false,
  },
  header: {
    value: "text",
    valid: true,
  },
  body: {
    value: "",
    valid: false,
  },
  footer: {
    value: "",
    valid: true,
  },
};

const replyBtnOption = {
  text: "",
  type: "QUICK_REPLY",
};
const phoneBtnOption = {
  text: "",
  phoneNumber: "",
  type: "PHONE_NUMBER",
};
const urlBtnOption = {
  text: "",
  url: "",
  example: "",
  type: "URL",
};

class TemplateMessageForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      channel: "",
      formData: { ...JSON.parse(JSON.stringify(initialFormData)) },
      language: null,
      excessSpacingError: false,
      templateBtns: [],
    };
  }

  checkForExcessWhiteSpacing = () => {
    const { body } = this.state.formData;

    const regex = /\s{5,}/g;
    const result = regex.test(body?.value);

    this.setState({
      excessSpacingError: result,
    });
  };

  handleChange = ({ target }, valid) => {
    this.setState(
      {
        formData: {
          ...this.state.formData,
          [target.name]: {
            value: target.value,
            valid,
          },
        },
      },
      () => {
        if (target.name === "body") this.checkForExcessWhiteSpacing();
      }
    );
  };

  handleHeaderChange = ({ target }, valid) => {
    this.setState(
      {
        formData: {
          ...this.state.formData,
          header: {
            value: target.value,
            valid,
          },
        },
      },
      () => {
        this.setState({
          formData: {
            ...this.state.formData,
            headerInput: {
              value: "",
              valid: true,
            },
          },
        });
      }
    );
  };

  handleSelectLang = (selected) => {
    this.setState({
      language: selected,
    });
  };

  handleSelectChannel = ({ target }) => {
    this.setState({
      channel: target.value,
    });
  };

  handleAddTemplateBtn = (selected) => {
    const { templateBtns } = this.state;

    let value = null;
    if (selected?.label === replyBtnOption.type) {
      value = replyBtnOption;
    }
    if (selected?.label === phoneBtnOption.type) {
      value = phoneBtnOption;
    }
    if (selected?.label === urlBtnOption.type) {
      value = urlBtnOption;
    }

    this.setState({
      templateBtns: [...templateBtns, value],
    });
  };

  handleRemoveTemplateBtn = (index) => {
    const templateBtns = JSON.parse(JSON.stringify(this.state.templateBtns));
    templateBtns.splice(index, 1);

    this.setState({
      templateBtns: templateBtns,
    });
  };

  handleTemplateBtnTextChange = ({ target }, index) => {
    const templateBtns = JSON.parse(JSON.stringify(this.state.templateBtns));
    templateBtns[index].text = target.value;

    this.setState({
      templateBtns: templateBtns,
    });
  };

  handleTemplateBtnValueChange = ({ target }, index) => {
    const templateBtns = JSON.parse(JSON.stringify(this.state.templateBtns));

    if (templateBtns[index].type === phoneBtnOption.type) {
      templateBtns[index].phoneNumber = target.value;
    } else if (templateBtns[index].type === urlBtnOption.type) {
      templateBtns[index].url = target.value;
      templateBtns[index].example = target.value;
    }

    this.setState({
      templateBtns: templateBtns,
    });
  };

  handleSelectCategory = () => {
    const category = this.state.formData.category.value;

    if (category === "AUTHENTICATION") {
      this.setState({
        formData: {
          ...this.state.formData,
          body: {
            value: "",
            valid: true,
          },
          expiration: {
            value: "",
            valid: false,
          },
        },
      });
    } else {
      this.setState({
        formData: {
          ...this.state.formData,
          body: {
            value: "",
            valid: false,
          },
          expiration: {
            value: "",
            valid: true,
          },
        },
      });
    }
  };

  checkFormValidity = () => {
    const { language, excessSpacingError, templateBtns } = this.state;

    var valid = false;

    const invalidBtnText = templateBtns?.find((x) => x?.text === "");
    const invalidBtnPhone = templateBtns?.find(
      (x) => x?.type === phoneBtnOption.type && x?.phoneNumber === ""
    );
    const invalidBtnUrl = templateBtns?.find(
      (x) => x?.type === urlBtnOption.type && x?.url === ""
    );

    if (
      language &&
      !excessSpacingError &&
      !invalidBtnText &&
      !invalidBtnPhone &&
      !invalidBtnUrl &&
      Object.values(this.state.formData).every(({ valid }) => valid)
    ) {
      valid = true;
    }

    return valid;
  };

  submitForm = (e) => {
    e.preventDefault();
    const { createTemplateMessageAction, toggleTemplateMessageForm } =
      this.props;

    const { language, templateBtns } = this.state;
    const { name, category, expiration, header, body, footer } =
      this.state.formData;

    const bodyTextExamples =
      body?.value?.replace(/[^{{]/g, "")?.length / 2 || 0;

    var components = [
      {
        type: "BODY",
        text: body?.value,
        example:
          bodyTextExamples > 0
            ? {
                body_text: [
                  [...Array(bodyTextExamples)].map(() => randomText(4)),
                ],
              }
            : null,
      },
    ];
    if (bodyTextExamples < 1) delete components?.[0]?.example;

    if (header?.value) {
      components = [
        ...components,
        {
          format: header?.value?.toUpperCase(),
          type: "HEADER",
          example: {
            header_handle:
              header?.value === "image"
                ? [
                    "https://www.imgonline.com.ua/examples/rainbow-background-1-preview.jpg",
                  ]
                : header?.value === "document"
                ? ["http://www.africau.edu/images/default/sample.pdf"]
                : header?.value === "video"
                ? [
                    "https://res.cloudinary.com/emekamykael45/video/upload/v1644923815/Dinerstack/WITI_-_Fox_is_Six_Six_is_News_bumper_5_sec_1995_yd3vle.mp4",
                  ]
                : "",
          },
        },
      ];
    }

    const payload = {
      name: name?.value?.replace(/ /g, "_")?.toLowerCase(),
      language: language?.value,
      category: category?.value,
      expires: parseInt(expiration?.value),
      components,
      footer: footer?.value,
      buttons: templateBtns,
    };

    createTemplateMessageAction(
      payload,
      this.state.channel,
      toggleTemplateMessageForm
    );
  };

  componentDidUpdate(prevProps, prevState) {
    const { createTemplateMessageOutcome, getTemplateMessageAction } =
      this.props;
    const { formData } = this.state;

    if (
      prevProps.createTemplateMessageOutcome !== createTemplateMessageOutcome &&
      createTemplateMessageOutcome === "success"
    ) {
      getTemplateMessageAction({ channelId: this.state.channel });
      this.setState({
        formData: { ...initialFormData },
      });
    }

    if (prevState.formData.category !== formData.category) {
      this.handleSelectCategory();
    }
  }

  render() {
    const { formData, language, excessSpacingError, templateBtns } = this.state;
    const {
      channelOptions,
      templateCategoriesData,
      isCreatingTemplateMessage,
      toggleTemplateMessageForm,
      createTemplateMessageOutcome,
      createTemplateMessageMessage,
    } = this.props;

    const { name, category, expiration, header, body, footer } = formData;

    const categoryOptions =
      templateCategoriesData?.categories?.map((x) => ({
        label: x,
        value: x,
      })) || [];
    const languageOptions =
      templateCategoriesData?.languages?.map((x) => ({
        label: `${x?.name} - (${x?.code})`,
        value: x?.code,
      })) || [];

    const headerOptions = [
      { label: "None", value: "" },
      { label: "Image", value: "image" },
      { label: "Document", value: "document" },
      { label: "Video", value: "video" },
    ];

    const isReplyBtnDisabled = () => {
      const { templateBtns } = this.state;

      let value = false;

      const replyBtnIsSelected = templateBtns?.find(
        (x) => x?.type === replyBtnOption.type
      );
      if (
        (!replyBtnIsSelected && templateBtns?.length > 0) ||
        (replyBtnIsSelected && templateBtns?.length > 2)
      ) {
        value = true;
      }

      return value;
    };

    const isPhoneBtnDisabled = () => {
      const { templateBtns } = this.state;

      let value = false;

      const replyBtnIsSelected = templateBtns?.find(
        (x) => x?.type === replyBtnOption.type
      );
      if (replyBtnIsSelected || templateBtns?.length > 1) {
        value = true;
      }

      return value;
    };

    const categoryIsAuth = category.value === "AUTHENTICATION" ? true : false;

    return (
      <Modal closeModal={() => toggleTemplateMessageForm()}>
        <div className="modal-content">
          <h2 className="modal-name">Create template message</h2>
          <div className="modal-form saved-response-form">
            <form onSubmit={this.submitForm}>
              <div className="form-row mb-25">
                <TextField
                  label="Name"
                  name="name"
                  placeholder="new_conversation"
                  value={name.value}
                  onChange={(e, valid) => this.handleChange(e, valid)}
                />
                <Select
                  label="Channel"
                  name="channel"
                  options={channelOptions}
                  onChange={(e) => this.handleSelectChannel(e)}
                />
              </div>
              <div className="input-container mb-25">
                <label>Language</label>
                <ReactSelect
                  disabled={isCreatingTemplateMessage}
                  placeholder="---Select---"
                  value={language}
                  options={languageOptions}
                  onChange={(selection) => {
                    this.handleSelectLang(selection);
                  }}
                  classNamePrefix="template-options"
                />
              </div>
              <Select
                label="Category"
                name="category"
                className="mb-25"
                options={categoryOptions}
                value={category.value}
                onChange={(e, valid) => this.handleChange(e, valid)}
              />
              {categoryIsAuth ? (
                <TextField
                  label="Expiration time (in minutes)"
                  name="expiration"
                  placeholder="4"
                  className="mb-25"
                  type="number"
                  value={expiration.value}
                  onChange={(e, valid) => this.handleChange(e, valid)}
                />
              ) : (
                <>
                  <Select
                    label="Header element"
                    name="header"
                    className="mb-25"
                    options={headerOptions}
                    value={header.value}
                    onChange={(e, valid) => this.handleHeaderChange(e, valid)}
                  />
                  <TextArea
                    label="Body"
                    name="body"
                    className="mb-25"
                    placeholder="Hi {{1}}! Your order of {{2}} from {{3}} is on its way. Thanks"
                    value={body?.value}
                    onChange={(e, valid) => this.handleChange(e, valid)}
                    noAction
                  />
                  {body?.value?.length > 1024 && (
                    <p className="error_message">
                      Error! You have exceeded the maximum of 1024 characters.
                    </p>
                  )}
                  {excessSpacingError && (
                    <p className="error_message">
                      Error! You cannot have more than 4 consecutive spaces in
                      your message.
                    </p>
                  )}
                  <TextField
                    label="Footer"
                    name="footer"
                    placeholder="Enter footer text here"
                    onChange={(e, valid) => this.handleChange(e, valid)}
                    className="mb-25"
                  />
                  {footer?.value?.length > 60 && (
                    <p className="error_message">
                      Error! You have exceeded the maximum of 60 characters.
                    </p>
                  )}

                  <p className="buttons-label">Buttons</p>
                  <div className="template-button-fields">
                    {templateBtns?.map((item, index) => (
                      <div key={index} className="item">
                        <p className="type">{item?.type}:</p>

                        <div className="flex">
                          <div
                            className="trash"
                            onClick={() => this.handleRemoveTemplateBtn(index)}
                          >
                            <Trash />
                          </div>

                          <div className="form-row">
                            <TextField
                              label="Enter button text"
                              name="btn_text"
                              placeholder="Enter button text here"
                              onChange={(e) =>
                                this.handleTemplateBtnTextChange(e, index)
                              }
                            />

                            {item?.type !== "QUICK_REPLY" && (
                              <TextField
                                label={`Enter ${item?.type}`}
                                name="btn_value"
                                placeholder="Type here"
                                onChange={(e) =>
                                  this.handleTemplateBtnValueChange(e, index)
                                }
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="template-button-options">
                    <p>
                      Click a button type below to add buttons to your template
                      message.
                    </p>

                    <div className="all-buttons">
                      <Button
                        type="button"
                        className="secondary-button"
                        onClick={() =>
                          this.handleAddTemplateBtn({
                            label: replyBtnOption.type,
                            value: replyBtnOption.type,
                          })
                        }
                        disabled={isReplyBtnDisabled()}
                      >
                        + Reply
                      </Button>
                      <Button
                        type="button"
                        className="secondary-button"
                        onClick={() =>
                          this.handleAddTemplateBtn({
                            label: phoneBtnOption.type,
                            value: phoneBtnOption.type,
                          })
                        }
                        disabled={isPhoneBtnDisabled()}
                      >
                        + Phone
                      </Button>
                      <Button
                        type="button"
                        className="secondary-button"
                        onClick={() =>
                          this.handleAddTemplateBtn({
                            label: urlBtnOption.type,
                            value: urlBtnOption.type,
                          })
                        }
                        disabled={isPhoneBtnDisabled()}
                      >
                        + Link
                      </Button>
                    </div>
                  </div>
                </>
              )}

              <div className="modal-form-action">
                <Button
                  type="button"
                  className="secondary-button"
                  onClick={() => toggleTemplateMessageForm()}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  className="primary-button"
                  disabled={
                    !this.checkFormValidity() ||
                    !this.state.channel ||
                    isCreatingTemplateMessage ||
                    (categoryIsAuth === true && !expiration?.value) ||
                    (!categoryIsAuth === true && body?.value?.length > 1024)
                  }
                >
                  {isCreatingTemplateMessage
                    ? "Processing..."
                    : "Submit for approval"}
                </Button>
              </div>

              {createTemplateMessageOutcome === "error" && (
                <FormNotifications
                  type="error"
                  message={createTemplateMessageMessage}
                />
              )}
            </form>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = ({ templateMessage }) => ({
  ...templateMessage,
});

export default connect(mapStateToProps, {
  createTemplateMessageAction,
  getTemplateMessageAction,
})(TemplateMessageForm);
