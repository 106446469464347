import * as moment from "moment";

import {
  SUPPORTED_VIDEO_EXTENSIONS,
  SUPPORTED_DOCUMENT_EXTENSIONS,
  SUPPORTED_IMAGE_EXTENSIONS,
  ALL_CHANNELS,
  APP_ONEROUTE_USER,
  APP_NOTIFY_TOKEN,
  APP_SENT_FIRST_CONVO_REPLY,
  APP_CREATED_WIDGET,
  APP_CREATED_FIRST_BROADCAST_MSG,
  APP_INVITED_TEAM_MEMBER,
} from "./constants";

export const isClient = () => {
  return typeof window !== "undefined";
};

export const getUserDetails = () => {
  const user = window.localStorage.getItem("oneroute-user");

  return user ? JSON.parse(user).user : {};
};

export const slugify = (text) => {
  return (
    text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace spaces with -
      // eslint-disable-next-line
      .replace(/[^\w\-]+/g, "") // Remove all non-word chars
      // eslint-disable-next-line
      .replace(/\-\-+/g, "-") // Replace multiple - with single -
      .replace(/^-+/, "") // Trim - from start of text
      .replace(/-+$/, "")
  ); // Trim - from end of text
};

export const getFormValues = (formFields) => {
  if (!formFields || !(typeof formFields === "object") || formFields[0]) {
    return null;
  }

  let formValues = {};

  Object.keys(formFields).forEach((key) => {
    formValues[key] =
      typeof formFields[key] === "string"
        ? formFields[key].value.trim()
        : formFields[key].value;
  });

  return formValues;
};

export const patchFormValues = (formFields, data) => {
  if (
    !formFields ||
    !data ||
    !(typeof formFields === "object") ||
    !(typeof data === "object") ||
    !Object.keys(formFields).length ||
    !Object.keys(data).length ||
    formFields[0] ||
    data[0]
  ) {
    return null;
  }

  let formValues = {};

  Object.keys(formFields).forEach((key) => {
    formValues[key] = {
      value: data[key] || "",
      valid: !!data[key],
    };
  });

  return formValues;
};

export const getRequestError = (error) => {
  const { response } = error;
  if (response && (response.data.code === 401 || response.status === 401)) {
    if (response.data.message?.includes("incorrect")) {
      return response.data?.message;
    } else {
      logoutUser(true);
    }
  } else if (response && response.data.errors && response.data.errors[0]) {
    return response.data.errors[0].message;
  } else if (response && response.data.message) {
    return response.data.message;
  } else if (response && response.data.error) {
    return response.data.error;
  }
  return "There might be a problem with your internet connection. Please check and try again.";
};

export const logoutUser = (tokenExpired) => {
  const currentUrl = getWindowBaseUrl();

  tokenExpired && localStorage.setItem("last_visited_url", currentUrl);
  localStorage.removeItem(APP_ONEROUTE_USER);
  localStorage.removeItem(APP_NOTIFY_TOKEN);
  localStorage.removeItem(APP_SENT_FIRST_CONVO_REPLY);
  localStorage.removeItem(APP_CREATED_WIDGET);
  localStorage.removeItem(APP_CREATED_FIRST_BROADCAST_MSG);
  localStorage.removeItem(APP_INVITED_TEAM_MEMBER);

  window.scrollTo(0, 0);
  window.location.reload();
};

export const reduceArray = (array, reducer) => {
  return array.reduce((prev, curr) => prev + parseInt(curr[reducer]), 0);
};

export const reduceLinearArray = (array) => {
  return array.reduce((prev, curr) => prev + parseInt(curr), 0);
};

export const getDays = () => {
  const days = new Array(31).fill(0).map((_, index) => {
    const day = ("0" + (index + 1).toString()).slice(-2);

    return {
      label: day,
      value: day,
    };
  });

  return days;
};

export const getMonths = () => {
  const months = new Array(12).fill(0).map((_, index) => ({
    label: moment(index + 1, "M").format("MMMM"),
    value: moment(index + 1, "M").format("MM"),
  }));

  return months;
};

export const getYears = () => {
  const years = new Array(99).fill(0).map((_, index) => {
    const year = (moment().format("YYYY") - index).toString();

    return {
      label: year,
      value: year,
    };
  });

  return years;
};

export const getTimeDiffWords = (time) => {
  const now = moment();

  const timeDiffHours = now.diff(moment(time), "hours");
  const timeDiffDays = now.diff(moment(time), "days");
  const timeDiffWeeks = now.diff(moment(time), "weeks");
  const timeDiffMonths = now.diff(moment(time), "months");
  const timeDiffYears = now.diff(moment(time), "years");

  if (timeDiffHours <= 24) {
    return `${timeDiffHours} hours ago`;
  } else if (timeDiffDays <= 7) {
    return `${timeDiffDays} days ago`;
  } else if (timeDiffWeeks <= 4) {
    return `${timeDiffWeeks} days ago`;
  } else if (timeDiffMonths <= 12) {
    return `${timeDiffMonths} months ago`;
  } else {
    return `${timeDiffYears} years ago`;
  }
};

export const applyEllipsis = (text, limit) => {
  return text && text.length > limit ? `${text.substring(0, limit)}...` : text;
};

export const bytesToSize = (bytes) => {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "N/A";
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  if (i === 0) return `${bytes.toFixed(1)} ${sizes[i]}`;
  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
};

export const kilobytesToSize = (bytes) => {
  const sizes = ["KB", "MB", "GB", "TB"];
  if (bytes === 0) return "N/A";
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  if (i === 0) return `${bytes.toFixed(1)} ${sizes[i]}`;
  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
};

export const secondsToMinsSecs = (time) => {
  const minutes = Math.floor(time / 60);
  const seconds = Math.ceil(time) - minutes * 60;

  return `${minutes}:${("0" + seconds).slice(-2)}`;
};

export const getStateCodes = (name) => {
  return name ? name.slice(0, 3) : "";
};

export const getInitials = (name, length) => {
  return name
    ? name
        .split(" ", length)
        .map((part) => part.slice(0, 1))
        .join("")
    : "";
};

export const uploadData = ({ rawData, extension }) => {
  if (SUPPORTED_VIDEO_EXTENSIONS.includes(extension))
    return { rawData, contentType: "VIDEO" };
  else if (SUPPORTED_DOCUMENT_EXTENSIONS.includes(extension))
    return { rawData, contentType: "DOCUMENT" };
  else if (SUPPORTED_IMAGE_EXTENSIONS.includes(extension))
    return { rawData, contentType: "IMAGE" };
  else return { contentType: "TEXT" };
};

export const getExtensionType = (file) => {
  const arr = file?.split(".");
  return arr[arr?.length - 1];
};

export const getFileName = (file) => {
  const arr = file.split(".");
  const title = arr[arr.length - 2].split("/")[1];
  return title || "Document";
};

export const getTimeOfDay = () => {
  const currentHour = moment().format("HH");
  const dayTime =
    currentHour < 12 ? "Morning" : currentHour <= 17 ? "Afternoon" : "Evening";
  return dayTime;
};

export const determineColorFromBg = (bgColor) => {
  // If a leading # is provided, remove it
  if (bgColor) {
    if (bgColor.slice(0, 1) === "#") {
      bgColor = bgColor.slice(1);
    }

    // If a three-character hexcode, make six-character
    if (bgColor.length === 3) {
      bgColor = bgColor
        .split("")
        .map(function (hex) {
          return hex + hex;
        })
        .join("");
    }

    // Convert to RGB value
    var r = parseInt(bgColor.substr(0, 2), 16);
    var g = parseInt(bgColor.substr(2, 2), 16);
    var b = parseInt(bgColor.substr(4, 2), 16);

    // Get YIQ ratio
    var yiq = (r * 299 + g * 587 + b * 114) / 1000;

    // Check contrast
    return yiq >= 128 ? "#000000" : "#FFFFFF";
  } else {
    return "#FFFFFF";
  }
};

export const getChannelInfo = (channel) => {
  const channelInfo = ALL_CHANNELS.find(
    ({ name }) => name?.toLowerCase() === channel?.toLowerCase()
  );
  return channelInfo || "";
};

export const getYesterdayToday = (date) => {
  const formattedDate = moment(date).format("LL");
  const today = formattedDate === moment().format("LL");
  const yesterday = formattedDate === moment().subtract(1, "day").format("LL");

  if (today) {
    return "Today";
  } else if (yesterday) {
    return "Yesterday";
  } else {
    return formattedDate;
  }
};

export const urlify = (text) => {
  if (!text) return "";

  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, (url) => {
    return `<a href="${url}">${url}</a>`;
  });
};

export const capitalize = (str) => {
  if (str) {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(" ");
  } else {
    return "";
  }
};

export const ObjIsEmpty = (obj) => {
  if (Object.keys(obj).length === 0 && obj.constructor === Object) {
    return true;
  } else {
    return false;
  }
};

export const getWindowBaseUrl = () => {
  return window.location.origin + window.location.pathname;
};

export const getChannelIdentifierIcon = (medium) => {
  switch (medium?.toUpperCase()) {
    case "WHATSAPP":
      return "https://s3.eu-west-3.amazonaws.com/oneroute.asb.ng/whatsapp.png";
    case "FACEBOOK":
      return "https://s3.eu-west-3.amazonaws.com/oneroute.asb.ng/fbmessenger.png";
    case "FACEBOOK MESSENGER":
      return "https://s3.eu-west-3.amazonaws.com/oneroute.asb.ng/fbmessenger.png";
    case "EMAIL":
      return "https://s3.eu-west-3.amazonaws.com/oneroute.asb.ng/email.svg";
    case "TWITTER":
      return "https://s3.eu-west-3.amazonaws.com/oneroute.asb.ng/twitter-icon.png";
    case "SMS":
      return "https://s3.eu-west-3.amazonaws.com/oneroute.asb.ng/sms-icon.png";
    case "SMS-TW":
      return "https://s3.eu-west-3.amazonaws.com/oneroute.asb.ng/sms-icon.png";
    case "INSTAGRAM":
      return "https://s3.eu-west-3.amazonaws.com/oneroute.asb.ng/instagram-icon.png";
    case "GOOGLE":
      return require("../assets/images/icons/google.png").default;
    case "PHONE":
      return require("../assets/images/icons/phone.svg").default;
    case "WEBSITE":
      return require("../assets/images/icons/website.png").default;
    case "LIVECHAT":
      return "https://s3.eu-west-3.amazonaws.com/oneroute.asb.ng/oneroute-icon.png";
    case "WIDGET":
      return "https://s3.eu-west-3.amazonaws.com/oneroute.asb.ng/oneroute-icon.png";
    default:
      return;
  }
};

export const formatNumber = (n, decimal) => {
  return (
    n &&
    Number(n)
      .toFixed(decimal || 0)
      .replace(/./g, function (c, i, a) {
        return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
      })
  );
};

export const formatNumberII = (n, decimal) => {
  return (
    n &&
    Number(n)
      .toFixed(decimal || 0)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  );
};

export const getArrayDifference = (array1, array2) => {
  return array1.filter((object1) => {
    return !array2.some((object2) => {
      return object1.value === object2.value;
    });
  });
};

export const colorCodes = [
  "#4CAF50",
  "#18A0FB",
  "#7359ED",
  "#3C7BF6",
  "#F97272",
];

export const secondsToHms = (d) => {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  var hDisplay = h > 0 ? h + "h " : "";
  var mDisplay = m > 0 ? m + "m " : "";
  var sDisplay = s > 0 ? s + "s" : "";
  return hDisplay + mDisplay + sDisplay;
};

export const randomText = (length) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const getFieldProps = (fieldType) => {
  if (fieldType === "single_line_text") {
    return {};
  }
};

export const usdRateToday = () => {
  return 776;
};
