import React from "react";

export default () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#000"
        fillOpacity="0.24"
        d="M.492 19.533l1.34-4.871a9.338 9.338 0 01-1.258-4.7c0-5.187 4.238-9.404 9.445-9.404 2.527 0 4.9.979 6.679 2.756a9.307 9.307 0 012.762 6.653c0 5.187-4.238 9.404-9.446 9.404h-.004a9.479 9.479 0 01-4.514-1.146L.492 19.533zm5.239-3.013l.285.172a7.893 7.893 0 003.994 1.091h.005c4.324 0 7.846-3.504 7.846-7.816 0-2.089-.815-4.05-2.295-5.53a7.803 7.803 0 00-5.552-2.291c-4.328 0-7.851 3.504-7.851 7.816 0 1.475.412 2.914 1.2 4.159l.186.297-.793 2.883 2.975-.78z"
      ></path>
      <path
        fill="url(#paint0_linear)"
        d="M.92 19.03l1.28-4.646a8.94 8.94 0 01-1.204-4.48C.996 4.958 5.04.936 9.998.936a8.96 8.96 0 016.368 2.628A8.894 8.894 0 0119 9.908c0 4.944-4.043 8.966-9.002 8.966h-.004a9.034 9.034 0 01-4.302-1.091L.92 19.03z"
      ></path>
      <path
        fill="#fff"
        d="M.594 19.355l1.323-4.81A9.221 9.221 0 01.674 9.903C.674 4.781 4.86.616 10.002.616c2.495 0 4.839.967 6.596 2.722a9.192 9.192 0 012.728 6.57c0 5.122-4.186 9.287-9.328 9.287h-.005a9.36 9.36 0 01-4.458-1.131L.594 19.355zm5.173-2.975l.282.17a7.794 7.794 0 003.944 1.077h.005c4.27 0 7.75-3.46 7.75-7.72 0-2.062-.806-4-2.268-5.46a7.706 7.706 0 00-5.482-2.263c-4.275 0-7.754 3.46-7.754 7.72 0 1.456.407 2.877 1.185 4.106l.183.294-.782 2.846 2.938-.77z"
      ></path>
      <path
        fill="#fff"
        d="M7.669 6.015c-.175-.388-.358-.397-.524-.401-.134-.005-.29-.005-.447-.005a.859.859 0 00-.621.29c-.215.231-.814.793-.814 1.937 0 1.14.836 2.245.952 2.401.116.156 1.614 2.575 3.98 3.506 1.968.775 2.37.619 2.795.579.43-.04 1.377-.561 1.574-1.105.192-.543.192-1.006.134-1.104-.058-.098-.215-.156-.447-.272-.233-.116-1.377-.677-1.592-.757-.215-.08-.371-.116-.523.116-.157.231-.604.752-.738.908-.134.156-.273.174-.505.058-.233-.116-.984-.36-1.874-1.154-.693-.614-1.163-1.376-1.297-1.608-.134-.231-.013-.356.103-.472.103-.102.233-.272.349-.405.116-.134.156-.232.232-.388.076-.156.04-.29-.018-.405-.058-.116-.514-1.26-.72-1.72z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="9.96"
          x2="9.96"
          y1="19.029"
          y2="0.936"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#20B038"></stop>
          <stop offset="1" stopColor="#60D66A"></stop>
        </linearGradient>
      </defs>
    </svg>
)