/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import * as _ from "lodash";
import * as shallowequal from "shallowequal";

import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";

import { Select, FileInput, TextField } from ".";
import AudioPlayer from "../../components/AudioPlayer";

import {
  Emoji,
  Notes,
  Messenger,
  Gif,
  Images,
  Attachment,
  Bag,
  Send,
  File,
  Close,
  Search,
  AngleDown,
} from "../../assets/vectors";

import { getExtensionType, getChannelInfo } from "../../utils/functions";
import { ALL_CHANNELS } from "../../utils/constants";

class ChatInput extends Component {
  constructor(props) {
    super(props);

    const { savedResponsesData } = props;

    this.state = {
      active: false,
      isValid: false,
      isBlurred: false,
      rows: 1,
      minRows: 1,
      maxRows: 4,
      filteredResponses: savedResponsesData,
    };
  }

  textFieldRef = createRef();

  onChange = ({ target }, isBlurred) => {
    let { isValid, minRows, maxRows } = this.state;
    const { required, showEmoji } = this.props;
    let { value, scrollHeight, scrollTop } = target;
    console.log(scrollTop);

    isValid = required ? value !== "" : true;

    const lineHeight = 21;

    this.setState(
      {
        rows: minRows,
      },
      () => {
        const currentRows = ~~(target.scrollHeight / lineHeight);

        if (currentRows >= maxRows) {
          scrollTop = scrollHeight;
        }

        this.setState({
          isValid,
          isBlurred,
          isFocused: showEmoji ? true : !isBlurred,
          rows: currentRows < maxRows ? currentRows : maxRows,
        });

        this.props.onChange({ target: { value } }, isValid);
      }
    );
  };

  onFocus = () => {
    this.setState({
      isFocused: true,
    });
  };

  openEmojiBox = () => {
    this.setState(
      {
        isEmojiActive: true,
        isFocused: true,
      },
      () => {
        document.addEventListener("click", this.closeEmojiBox);
      }
    );
  };

  closeEmojiBox = (e) => {
    if (
      this.emojiSelectRef !== null &&
      !this.emojiSelectRef.contains(e.target)
    ) {
      this.setState(
        {
          isEmojiActive: false,
        },
        () => {
          document.removeEventListener("click", this.closeEmojiBox);
        }
      );
    }
  };

  addEmoji = ({ native }) => {
    let value = this.textFieldRef.current.value;
    value = `${value}${native}`;

    this.textFieldRef.current.value = value;
    this.props.onChange({ target: { value } }, true);

    this.setState(
      {
        isEmojiActive: false,
      },
      () => {
        document.removeEventListener("click", this.closeEmojiBox);
      }
    );

    this.textFieldRef.current.focus();
  };

  openSavedResponses = () => {
    this.setState(
      {
        isSavedResponsesActive: true,
      },
      () => document.addEventListener("click", this.closeSavedResponses)
    );
  };

  closeSavedResponses = (e) => {
    if (
      !e ||
      (this.savedResponsesRef && !this.savedResponsesRef.contains(e.target))
    ) {
      this.setState(
        {
          isSavedResponsesActive: false,
        },
        () => document.removeEventListener("click", this.closeSavedResponses)
      );
    }
  };

  selectSavedResponse = (response) => {
    this.setState(
      {
        isSavedResponsesActive: false,
      },
      () => document.removeEventListener("click", this.closeSavedResponses)
    );

    this.textFieldRef.current.value = response;
    this.props.onChange({ target: { value: response } }, true);
  };

  searchSavedResponses = (value) => {
    const filteredResponses = this.props.savedResponsesData.filter(
      ({ title }) => title.toLowerCase().includes(value.toLowerCase())
    );

    this.setState({
      filteredResponses,
    });
  };

  sendMessage = () => {
    this.props.sendMessage();
    this.textFieldRef.current.value = "";
  };

  componentDidUpdate(prevProps) {
    const { templateOnly, savedResponsesData } = this.props;

    if (prevProps.templateOnly !== templateOnly && !templateOnly) {
      this.textFieldRef.current.value = "";
    }

    if (
      !shallowequal(prevProps.savedResponsesData, savedResponsesData) &&
      savedResponsesData.length
    ) {
      this.setState({
        filteredResponses: savedResponsesData,
      });
    }
  }

  render() {
    const {
      isValid,
      isBlurred,
      isFocused,
      isEmojiActive,
      rows,
      isSavedResponsesActive,
      filteredResponses,
    } = this.state;
    const {
      required,
      className,
      label,
      onChange,
      expand,
      showEmoji,
      attachedButton,
      sendMessage,
      onFileLoad,
      onFileError,
      removeFile,
      media,
      rawData,
      conversationTemplatesMessage,
      openTemplateModal,
      templateOnly,
      reopenOnly,
      reopenConversation,
      savedResponsesData,
      disabled,
      Channel,
      openFilePreview,
      ...rest
    } = this.props;

    const channelOptions = ALL_CHANNELS.map((channelOpt) => ({
      label: channelOpt.name,
      value: channelOpt.name,
    }));

    return (
      <div className={classNames("chatinput-container", { disabled })}>
        <div className="chatinput-header">
          <img
            src={getChannelInfo(Channel ? Channel.medium : "").icon}
            alt=""
          />
          <Select
            className="no-border"
            options={channelOptions}
            fixedPlaceholder={Channel?.medium}
            disabled
          />
        </div>
        <div
          className={classNames("chatinput-content", {
            templateOnly,
            reopenOnly,
          })}
        >
          <div
            className={classNames("input-container no-border", {
              isFocused,
              error: !isValid && isBlurred,
              expand,
              attachedButton,
              showEmoji,
            })}
          >
            <textarea
              ref={this.textFieldRef}
              rows={templateOnly || reopenOnly ? 2 : rows}
              className="input textarea"
              {...rest}
              onBlur={(e) => this.onChange(e, true)}
              onChange={this.onChange}
              onFocus={this.onFocus}
              onKeyPress={(e) => {
                if (e.which === 13 && !e.shiftKey) {
                  this.sendMessage();
                  e.preventDefault();
                }
              }}
              disabled={disabled || templateOnly || reopenOnly}
              defaultValue={
                templateOnly
                  ? "You can only start a Whatsapp conversation by sending a pre-approved Whatsapp template message"
                  : reopenOnly
                  ? "You can only send a message to this closed conversation by reopening the conversation"
                  : ""
              }
            ></textarea>
            {!!Object.keys(media).length && (
              <div className="chatinput-media">
                <span className="close" onClick={removeFile}>
                  <Close />
                </span>
                {media.contentType === "IMAGE" && (
                  <span
                    className="message-media"
                    onClick={() =>
                      openFilePreview(media.contentType, rawData.rawData)
                    }
                  >
                    <img src={rawData.rawData} alt="" />
                  </span>
                )}
                {media.contentType === "VIDEO" && (
                  <span
                    className="message-media"
                    onClick={() =>
                      openFilePreview(media.contentType, rawData.rawData)
                    }
                  >
                    <video controls={false}>
                      <source
                        src={rawData.rawData}
                        type={rawData.mime}
                      ></source>
                    </video>
                  </span>
                )}
                {media.contentType === "DOCUMENT" &&
                  (getExtensionType(rawData.fileName) === "mp3" ? (
                    <div className="message-audio">
                      <AudioPlayer src={rawData.rawData} />
                    </div>
                  ) : (
                    <div className="message-file">
                      <span className="icon">
                        <File />
                      </span>
                      <a target="_blank">{rawData.fileName}</a>
                    </div>
                  ))}
              </div>
            )}
            {templateOnly ? (
              <div className="chatinput-actions">
                <span className="select-template" onClick={openTemplateModal}>
                  Select a template message
                </span>
              </div>
            ) : reopenOnly ? (
              <div className="chatinput-actions">
                <span className="select-template" onClick={reopenConversation}>
                  Reopen this conversation
                </span>
              </div>
            ) : (
              <div className="chatinput-actions">
                <div>
                  <span
                    className="icon"
                    title="Saved Responses"
                    onClick={this.openSavedResponses}
                  >
                    <Notes />

                    {isSavedResponsesActive && (
                      <SavedResponses
                        searchSavedResponses={this.searchSavedResponses}
                        filteredResponses={filteredResponses}
                        ref={(el) => (this.savedResponsesRef = el)}
                        selectSavedResponse={this.selectSavedResponse}
                      />
                    )}
                  </span>
                  <span
                    className="icon"
                    onClick={openTemplateModal}
                    title="Template Messages"
                  >
                    <Messenger />
                  </span>
                  <div className="icon" title="Emojis">
                    {isEmojiActive ? (
                      <div
                        className="emoji-container"
                        ref={(el) => (this.emojiSelectRef = el)}
                      >
                        <Picker onSelect={this.addEmoji} emojiTooltip={true} />
                      </div>
                    ) : (
                      <div className="emoji-select" onClick={this.openEmojiBox}>
                        <Emoji />
                      </div>
                    )}
                  </div>
                  <span className="icon" title="Add a GIF">
                    <Gif />
                  </span>
                  <span className="icon" title="Add an image">
                    <FileInput
                      icon={<Images />}
                      onFileLoad={onFileLoad}
                      onFileError={onFileError}
                    />
                  </span>
                  <span className="icon" title="Add an attachment">
                    <FileInput
                      icon={<Attachment />}
                      onFileLoad={onFileLoad}
                      onFileError={onFileError}
                    />
                  </span>
                  <span className="icon" title="Add a product">
                    <Bag />
                  </span>
                </div>
                <div title="Send Message">
                  <span
                    className={classNames("icon send", {
                      active:
                        this.textFieldRef.current &&
                        this.textFieldRef.current.value,
                    })}
                    onClick={this.sendMessage}
                  >
                    <Send />
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const SavedResponses = React.forwardRef(
  ({ filteredResponses, selectSavedResponse, searchSavedResponses }, ref) => {
    const responses = _.chain(
      filteredResponses.map((resp) => ({
        ...resp,
        category: resp.Category.name,
      }))
    )
      .groupBy("category")
      .map((value, key) => ({ category: key, data: value }))
      .value();

    return (
      <div
        className="saved-responses-container"
        ref={ref}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="title">Saved Responses</div>
        <div className="search">
          <TextField
            leftIcon={<Search />}
            placeholder="Search"
            onChange={(e) => searchSavedResponses(e.target.value)}
          />
        </div>
        {responses.map(({ category, data }, index) => (
          <div
            key={`responses-section-${index}`}
            className="saved-responses-section"
          >
            <div className="responses-category">
              {category}{" "}
              <span className="icon">
                <AngleDown />
              </span>
            </div>
            {data.map(({ title, content }, index2) => (
              <div
                key={`response-item-${index2}`}
                className="responses-item"
                onClick={() => selectSavedResponse(content)}
              >
                <span className="item-title">{title}</span>
                <span className="item-value">{content}</span>
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  }
);

const mapStateToProps = ({ conversations, savedResponses }) => {
  const { conversationTemplatesMessage } = conversations;
  const { savedResponsesData } = savedResponses;
  return { conversationTemplatesMessage, savedResponsesData };
};

export default connect(mapStateToProps, {})(ChatInput);
