import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import ReactSelect from "react-select";
import classNames from "classnames";

import { Button, Checkbox, TextField } from "../../components/FormElements";
import FormNotification from "../../components/Notifications/FormNotification";
import Carousel from "./Carousel";

import {
  signUpAction,
  createFirmAction,
  getUserLoginInfoAction,
} from "../../redux/actions/Auth";

import { CircularCheckGreen } from "../../assets/vectors";
import { getChannelIdentifierIcon } from "../../utils/functions";
import { APP_EVENT_START_TRIAL } from "../../utils/constants";

const StepsUI = ({ active }) => {
  const steps = [
    { id: 1, name: "CREATE ACCOUNT" },
    { id: 2, name: "BUSINESS INFORMATION" },
  ];

  return (
    <div className="steps_container">
      {steps?.map((item, i) => (
        <div key={i} className={`step${active === item?.id ? ` active` : ``}`}>
          0{item.id} <span>{item.name}</span>
        </div>
      ))}
    </div>
  );
};

const RegisterPage = ({ setActiveForm }) => {
  const search = useLocation().search;
  const history = useHistory();
  const dispatch = useDispatch();

  const { isSigningUp, signUpOutcome, signUpMessage } = useSelector(
    (state) => state.auth
  );

  const [formData, setFormData] = useState({});
  const [role, setRole] = useState(null);
  const [purpose, setPurpose] = useState(null);
  const [showFormRes, setShowFormRes] = useState(false);
  const [channels, setChannels] = useState([
    {
      icon: "TWITTER",
      name: "Twitter",
      selected: false,
    },
    { icon: "WHATSAPP", name: "WhatsApp", selected: false },
    { icon: "INSTAGRAM", name: "Instagram", selected: false },
    { icon: "GOOGLE", name: "Google my business", selected: false },
    { icon: "EMAIL", name: "Email", selected: false },
    { icon: "PHONE", name: "Phone", selected: false },
    { icon: "WEBSITE", name: "Website", selected: false },
    { icon: "FACEBOOK", name: "Messenger", selected: false },
    { icon: "SMS", name: "SMS", selected: false },
  ]);

  const queryEmail = new URLSearchParams(search).get("em") || "";
  var step = new URLSearchParams(search).get("st") || 1;
  step = parseInt(step);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === "email") hasDisallowedEmail(value);

    if (showFormRes) setShowFormRes(false);
  };

  const hasDisallowedEmail = () => {
    const email = formData?.email;

    const notAllowed = ["gmail", "yahoo", "hotmail", "qa.team", "email.com"];

    if (email && notAllowed.some((i) => email?.includes(i))) {
      return true;
    } else {
      return false;
    }
  };

  const hasUptoNineChar = formData?.password?.length > 8;
  const hasUppercaseChar = /[A-Z]/.test(formData?.password);
  // eslint-disable-next-line no-useless-escape
  const hasSpecialChar = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(
    formData?.password
  );

  const formIsValid = () => {
    if (
      formData?.firstName &&
      formData?.lastName &&
      formData?.email &&
      hasDisallowedEmail() === false &&
      hasUptoNineChar &&
      hasUppercaseChar &&
      hasSpecialChar
    ) {
      return true;
    }

    return false;
  };

  const submitSignUpForm = (e) => {
    e.preventDefault();
    setShowFormRes(false);

    const payload = {
      firstName: formData?.firstName,
      lastName: formData?.lastName,
      email: formData?.email,
      password: formData?.password,
    };

    dispatch(signUpAction(payload)).then(() => {
      setShowFormRes(true);
    });
  };

  const updateChannelSelections = (channel) => {
    const newArr = channels?.map((obj) => {
      if (obj?.name === channel?.name) {
        return { ...obj, selected: !obj.selected };
      }

      return obj;
    });

    setChannels(newArr);
  };

  const uptoThreeChannelsSelected =
    channels?.filter((x) => x?.selected === true)?.length > 2;

  const getStartedFormIsValid = () => {
    if (
      formData?.name &&
      (formData?.role || role?.value) &&
      (formData?.purpose || purpose?.value) &&
      uptoThreeChannelsSelected
    ) {
      return true;
    }

    return false;
  };

  const submitGetStartedForm = (e) => {
    e.preventDefault();

    setShowFormRes(false);

    const payload = {
      name: formData?.name,
      role: formData?.role || role?.value,
      purpose: formData?.purpose || purpose?.value,
      channels: channels
        ?.filter((x) => x?.selected)
        ?.map((x) => x?.name?.toLowerCase()),
    };

    dispatch(createFirmAction(payload)).then((res) => {
      if (res?.data?.success === true) {
        dispatch(getUserLoginInfoAction()).then((resp) => {
          if (resp?.data?.success === true) {
            const user = resp?.data?.data?.user;
            window.dataLayer.push({
              event: APP_EVENT_START_TRIAL,
              agent_name: `${user?.firstName} ${user?.lastName}`,
              firm_name: user?.firm_name,
              email: user?.email,
              eventCallback: function () {
                window.location = "/get-started";
              },
            });
          }
        });
      } else {
        setShowFormRes(true);
      }
    });
  };

  const roleOptions = [
    { label: "Business owner", value: "Business owner" },
    {
      label: "Marketing Lead / Representative",
      value: "Marketing Lead / Representative",
    },
    {
      label: "Customer Service Lead / Representative",
      value: "Customer Service Lead / Representative",
    },
    {
      label: "CTO / Engineering Representative",
      value: "CTO / Engineering Representative",
    },
    { label: "Other (Please specify)", value: "Other" },
  ];

  const purposeOptions = [
    { label: "WhatsApp Broadcast", value: "WhatsApp Broadcast" },
    {
      label: "Manage inbound conversations",
      value: "Manage inbound conversations",
    },
    {
      label: "Send promotional campaigns",
      value: "Send promotional campaigns",
    },
    { label: "Click-to-chat Ads", value: "Click-to-chat Ads" },
    { label: "Other", value: "Other" },
  ];

  return (
    <div className="register_page_container">
      <div className="side left_side">
        <Carousel />
      </div>

      <div className="side form_container">
        <p className="title">
          {step === 1 ? "Start free trial" : "Business Information"}
        </p>
        <p className="sub_title">
          {step === 1
            ? "Complete your registration and start your free trial"
            : "We will like to know more about you"}
        </p>

        <StepsUI active={step} />

        {step === 1 && (
          <form className="form" onSubmit={submitSignUpForm}>
            {signUpOutcome === "success" ? (
              <></>
            ) : (
              <>
                <TextField
                  label="First Name"
                  name="firstName"
                  type="text"
                  className="mb-20"
                  placeholder="Michael"
                  onChange={(e) => handleChange(e)}
                />
                <TextField
                  label="Last Name"
                  name="lastName"
                  type="text"
                  className="mb-20"
                  placeholder="Wole"
                  onChange={(e) => handleChange(e)}
                />
                <TextField
                  label="Work email"
                  name="email"
                  type="email"
                  className="mb-20"
                  placeholder="name@company.com"
                  defaultValue={queryEmail}
                  onChange={(e) => handleChange(e)}
                />
                {hasDisallowedEmail() && (
                  <p className="error_message">
                    Error! Please use a valid work email.
                  </p>
                )}
                <TextField
                  label="Password"
                  name="password"
                  type="password"
                  className="mb-20"
                  onChange={(e) => handleChange(e)}
                />
                <div className="password_validators">
                  <div className="item">
                    {hasUptoNineChar ? (
                      <CircularCheckGreen />
                    ) : (
                      <div className="icon"></div>
                    )}
                    <p>Minimum of 9 characters</p>
                  </div>
                  <div className="item">
                    {hasUppercaseChar ? (
                      <CircularCheckGreen />
                    ) : (
                      <div className="icon"></div>
                    )}
                    <p>One UPPERCASE character</p>
                  </div>
                  <div className="item">
                    {hasSpecialChar ? (
                      <CircularCheckGreen />
                    ) : (
                      <div className="icon"></div>
                    )}
                    <p>One unique character (e.g: !@#$%^&*?)</p>
                  </div>
                </div>

                <div className="agreement_check">
                  <Checkbox
                    label={
                      <>
                        I agree to OneRoute{" "}
                        <a
                          href="https://oneroute.io/terms"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Terms
                        </a>{" "}
                        &{" "}
                        <a
                          href="https://oneroute.io/privacy"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Privacy Policy
                        </a>
                      </>
                    }
                    className="black"
                    defaultChecked
                    required
                  />
                </div>

                <Button
                  disabled={formIsValid() === false || isSigningUp}
                  className="primary-button full small"
                >
                  Create Account
                </Button>

                <div className="sign-up-link">
                  Already have an account?{" "}
                  <span
                    className="primary-link"
                    onClick={() => {
                      setActiveForm("login");
                      history.push(`/login`);
                    }}
                  >
                    Sign in
                  </span>
                </div>
              </>
            )}

            {showFormRes && (
              <>
                <br />
                <FormNotification
                  type={signUpOutcome}
                  message={signUpMessage}
                />
              </>
            )}
          </form>
        )}

        {step === 2 && (
          <form className="form" onSubmit={submitGetStartedForm}>
            <TextField
              label="Business Name"
              name="name"
              type="text"
              className="mb-20"
              placeholder="ABC Limited"
              onChange={(e) => handleChange(e)}
            />

            <div className="input-container mb-20">
              <label>What is your business role?</label>
              <ReactSelect
                disabled={isSigningUp}
                placeholder="---Select---"
                value={role}
                options={roleOptions}
                onChange={(selection) => setRole(selection)}
                classNamePrefix="select_options"
              />
            </div>

            {role?.value === "Other" && (
              <TextField
                label="Please specify your business role"
                name="role"
                type="text"
                className="mb-20"
                placeholder="Enter role here"
                onChange={(e) => handleChange(e)}
              />
            )}

            <div className="input-container mb-20">
              <label>
                What is your primary purpose of using OneRoute? (Optional)
              </label>
              <ReactSelect
                disabled={isSigningUp}
                placeholder="---Select---"
                value={purpose}
                options={purposeOptions}
                onChange={(selection) => setPurpose(selection)}
                classNamePrefix="select_options"
              />
            </div>

            {purpose?.value === "Other" && (
              <TextField
                label="Please specify your primary purpose"
                name="purpose"
                type="text"
                className="mb-20"
                placeholder="Enter purpose here"
                onChange={(e) => handleChange(e)}
              />
            )}

            <div className="channels_container">
              <div className="title">
                What are your preferred communication channels?
              </div>
              <div className="sub_title">Select at least 3 channels</div>

              <div className="channels">
                {channels?.map((item, index) => (
                  <div
                    key={index}
                    className={classNames("item", {
                      selected: item?.selected === true,
                    })}
                    onClick={() => updateChannelSelections(item)}
                  >
                    <div className="flex">
                      <img
                        src={getChannelIdentifierIcon(item?.icon)}
                        alt={item?.name}
                      />
                      <p>{item?.name}</p>
                    </div>

                    <div className="circle"></div>
                  </div>
                ))}
              </div>
            </div>

            <Button
              disabled={getStartedFormIsValid() === false || isSigningUp}
              className="primary-button full small"
            >
              Get started
            </Button>

            {showFormRes && (
              <>
                <br />
                <FormNotification
                  type={signUpOutcome}
                  message={signUpMessage}
                />
              </>
            )}
          </form>
        )}
      </div>
    </div>
  );
};

export default RegisterPage;
