import React from "react";

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    fill="none"
    viewBox="0 0 21 21"
  >
    <path
      stroke="#474F58"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.25"
      d="M6.067 6.067c2.798 0 5.066-1.135 5.066-2.534C11.133 2.134 8.865 1 6.067 1 3.268 1 1 2.134 1 3.533c0 1.4 2.268 2.534 5.067 2.534zM11.133 6.067c0 1.4-2.268 2.533-5.066 2.533C3.268 8.6 1 7.466 1 6.067M11.134 8.6V3.533"
    ></path>
    <path
      stroke="#474F58"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.25"
      d="M1 3.533V16.2c0 1.4 2.268 2.533 5.067 2.533a9.53 9.53 0 001.9-.188"
    ></path>
    <path
      stroke="#474F58"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.25"
      d="M1 13.666c0 1.4 2.268 2.534 5.067 2.534a9.523 9.523 0 001.9-.188M1 11.133c0 1.4 2.268 2.534 5.067 2.534a9.53 9.53 0 001.9-.189M1 8.6c0 1.4 2.268 2.533 5.067 2.533a9.53 9.53 0 001.9-.188M14.933 14.933C17.73 14.933 20 13.8 20 12.4c0-1.4-2.269-2.533-5.067-2.533S9.866 11 9.866 12.4c0 1.4 2.269 2.533 5.067 2.533z"
    ></path>
    <path
      stroke="#474F58"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.25"
      d="M9.866 12.4v5.067c0 1.399 2.268 2.533 5.067 2.533C17.73 20 20 18.866 20 17.467V12.4"
    ></path>
    <path
      stroke="#474F58"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.25"
      d="M20 14.933c0 1.4-2.269 2.533-5.067 2.533-2.799 0-5.067-1.134-5.067-2.533"
    ></path>
  </svg>
);
