import React from "react";

export default () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.6667 11.8337H1.33333C1.17862 11.8337 1.03025 11.7722 0.920854 11.6628C0.811458 11.5534 0.75 11.405 0.75 11.2503V0.750326C0.75 0.595616 0.811458 0.447243 0.920854 0.337847C1.03025 0.22845 1.17862 0.166992 1.33333 0.166992H10.6667C10.8214 0.166992 10.9697 0.22845 11.0791 0.337847C11.1885 0.447243 11.25 0.595616 11.25 0.750326V11.2503C11.25 11.405 11.1885 11.5534 11.0791 11.6628C10.9697 11.7722 10.8214 11.8337 10.6667 11.8337ZM3.66667 3.08366V4.25033H8.33333V3.08366H3.66667ZM3.66667 5.41699V6.58366H8.33333V5.41699H3.66667ZM3.66667 7.75033V8.91699H8.33333V7.75033H3.66667Z"
      fill="#757F8A"
    />
  </svg>
);
