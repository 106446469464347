import React from "react";

export default () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.21772 5.09961L1.08272 6.19961C0.982456 6.25153 0.898391 6.33002 0.839713 6.42649C0.781035 6.52296 0.75 6.6337 0.75 6.74661C0.75 6.85952 0.781035 6.97026 0.839713 7.06673C0.898391 7.1632 0.982456 7.24168 1.08272 7.29361L7.12072 10.4196"
      stroke="#757F8A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.2003 11.289L22.9173 7.30002C23.0175 7.2481 23.1016 7.16961 23.1603 7.07314C23.219 6.97667 23.25 6.86593 23.25 6.75302C23.25 6.64011 23.219 6.52937 23.1603 6.4329C23.1016 6.33643 23.0175 6.25795 22.9173 6.20602L12.7083 0.92202C12.4894 0.808984 12.2466 0.75 12.0003 0.75C11.7539 0.75 11.5112 0.808984 11.2923 0.92202L8.61328 2.30802"
      stroke="#757F8A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.9912 15.1L22.9172 12.552C23.0175 12.5001 23.1015 12.4216 23.1602 12.3251C23.2189 12.2287 23.2499 12.1179 23.2499 12.005C23.2499 11.8921 23.2189 11.7813 23.1602 11.6849C23.1015 11.5884 23.0175 11.5099 22.9172 11.458L18.9002 9.375"
      stroke="#757F8A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.09972 9.375L1.08272 11.453C0.982456 11.5049 0.898391 11.5834 0.839713 11.6799C0.781035 11.7763 0.75 11.8871 0.75 12C0.75 12.1129 0.781035 12.2237 0.839713 12.3201C0.898391 12.4166 0.982456 12.4951 1.08272 12.547L11.2917 17.828C11.4914 17.9313 11.7112 17.9896 11.9358 17.9987C12.1604 18.0079 12.3843 17.9677 12.5917 17.881"
      stroke="#757F8A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.7824 18.9L22.9174 17.8C23.0177 17.7481 23.1017 17.6696 23.1604 17.5731C23.2191 17.4767 23.2501 17.3659 23.2501 17.253C23.2501 17.1401 23.2191 17.0293 23.1604 16.9329C23.1017 16.8364 23.0177 16.7579 22.9174 16.706L18.9004 14.625"
      stroke="#757F8A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.09972 14.625L1.08272 16.7C0.982456 16.7519 0.898391 16.8304 0.839713 16.9269C0.781035 17.0233 0.75 17.1341 0.75 17.247C0.75 17.3599 0.781035 17.4707 0.839713 17.5671C0.898391 17.6636 0.982456 17.7421 1.08272 17.794L11.2917 23.075C11.5106 23.188 11.7534 23.247 11.9997 23.247C12.2461 23.247 12.4888 23.188 12.7077 23.075L15.3867 21.689"
      stroke="#757F8A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.75 0.75L20.25 23.25"
      stroke="#757F8A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
