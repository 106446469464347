import {
  getRequest,
  postRequest,
  patchRequest,
  deleteRequest,
} from "../../api";

import {
  GET_AUTO_RESPONSES_LOADER,
  GET_AUTO_RESPONSES_RESPONSE,
  AUTO_RESPONSES_RESET,
  CREATE_AUTO_RESPONSE_LOADER,
  CREATE_AUTO_RESPONSE_RESPONSE,
  UPDATE_AUTO_RESPONSE_LOADER,
  UPDATE_AUTO_RESPONSE_RESPONSE,
  DELETE_AUTO_RESPONSE_LOADER,
  DELETE_AUTO_RESPONSE_RESPONSE,
} from "../constants";

import { getRequestError } from "../../utils/functions";

export const createAutoResponseLoader = (isCreatingAutoResponse) => ({
  type: CREATE_AUTO_RESPONSE_LOADER,
  isCreatingAutoResponse,
});

export const createAutoResponseResponse = (
  createAutoResponseOutcome,
  createAutoResponseMessage
) => ({
  type: CREATE_AUTO_RESPONSE_RESPONSE,
  createAutoResponseOutcome,
  createAutoResponseMessage,
});

export const updateAutoResponseLoader = (isUpdatingAutoResponse) => ({
  type: UPDATE_AUTO_RESPONSE_LOADER,
  isUpdatingAutoResponse,
});

export const updateAutoResponseResponse = (
  updateAutoResponseOutcome,
  updateAutoResponseMessage
) => ({
  type: UPDATE_AUTO_RESPONSE_RESPONSE,
  updateAutoResponseOutcome,
  updateAutoResponseMessage,
});

export const getAutoResponsesLoader = (isLoadingAutoResponses) => ({
  type: GET_AUTO_RESPONSES_LOADER,
  isLoadingAutoResponses,
});

export const getAutoResponsesResponse = (
  autoResponsesOutcome,
  autoResponsesData,
  autoResponsesMeta,
  autoResponsesMessage
) => ({
  type: GET_AUTO_RESPONSES_RESPONSE,
  autoResponsesOutcome,
  autoResponsesData,
  autoResponsesMeta,
  autoResponsesMessage,
});

export const autoResponsesReset = () => ({
  type: AUTO_RESPONSES_RESET,
});

export const deleteAutoResponseLoader = (isDeletingAutoResponse) => ({
  type: DELETE_AUTO_RESPONSE_LOADER,
  isDeletingAutoResponse,
});

export const deleteAutoResponseResponse = (
  deleteAutoResponseOutcome,
  deleteAutoResponseMessage
) => ({
  type: DELETE_AUTO_RESPONSE_RESPONSE,
  deleteAutoResponseOutcome,
  deleteAutoResponseMessage,
});

export const createAutoResponseAction = (data) => {
  return async (dispatch) => {
    dispatch(autoResponsesReset());
    dispatch(createAutoResponseLoader(true));

    try {
      const res = await postRequest({
        url: "/autoresponders",
        data,
        token: true,
      });

      if (res?.status === 200) {
        dispatch(
          createAutoResponseResponse(
            "success",
            "Auto-response created successfully!"
          )
        );
        dispatch(createAutoResponseLoader(false));
        return res?.status;
      }
    } catch (error) {
      const message = getRequestError(error);

      dispatch(createAutoResponseResponse("error", message));
      dispatch(createAutoResponseLoader(false));
    }
  };
};

export const getAutoResponsesAction = (params = {}) => {
  return async (dispatch) => {
    dispatch(autoResponsesReset());
    dispatch(getAutoResponsesLoader(true));

    try {
      const res = await getRequest({
        url: "/autoresponders",
        params,
        token: true,
      });

      const { data, offset, limit, total } = res.data;

      dispatch(
        getAutoResponsesResponse(
          "success",
          data || [],
          { offset, limit, total },
          ""
        )
      );
      dispatch(getAutoResponsesLoader(false));
    } catch (error) {
      const message = getRequestError(error);

      dispatch(getAutoResponsesResponse("error", [], {}, message));
      dispatch(getAutoResponsesLoader(false));
    }
  };
};

export const updateAutoResponseAction = (data, id) => {
  return async (dispatch) => {
    dispatch(autoResponsesReset());
    dispatch(updateAutoResponseLoader(true));

    try {
      const res = await patchRequest({
        url: `/autoresponders/${id}`,
        data,
        token: true,
      });

      if (res?.status === 200) {
        dispatch(
          updateAutoResponseResponse(
            "success",
            "Auto-response updated successfully!"
          )
        );
        dispatch(updateAutoResponseLoader(false));
        return res?.status;
      }
    } catch (error) {
      const message = getRequestError(error);

      dispatch(updateAutoResponseResponse("error", message));
      dispatch(updateAutoResponseLoader(false));
    }
  };
};

export const deleteAutoResponseAction = (id) => {
  return async (dispatch) => {
    dispatch(autoResponsesReset());
    dispatch(deleteAutoResponseLoader(true));

    try {
      const res = await deleteRequest({
        url: `/autoresponders/${id}`,
        token: true,
      });

      if (res?.status === 200) {
        dispatch(
          deleteAutoResponseResponse(
            "success",
            "Auto-response deleted successfully!"
          )
        );
        dispatch(deleteAutoResponseLoader(false));
        return res?.status;
      }
    } catch (error) {
      const message = getRequestError(error);

      dispatch(deleteAutoResponseResponse("error", message));
      dispatch(deleteAutoResponseLoader(false));
    }
  };
};
