/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import * as _ from "lodash";
import { Picker } from "emoji-mart";
import classNames from "classnames";

import useCloseElement from "../CustomHooks/useCloseElement";
import { FileInput, TextField } from ".";
import AudioPlayer from "../AudioPlayer";

import {
  WhatsApp,
  TemplateMessage,
  FacebookMessenger,
  Twitter,
  Instagram,
  Email,
  Notes,
  Emoji,
  Gif,
  Attachment,
  // Bag,
  Send,
  Close,
  File,
  AngleDown,
  Search,
} from "../../assets/vectors";
import { capitalize, getExtensionType } from "../../utils/functions";

const ChatInputNew = ({
  Channel,
  placeholder,
  value,
  onChange,
  onFileLoad,
  onFileError,
  removeFile,
  rawData,
  sendMessage,
  media,
  isAMetaChannel,
  openFilePreview,
  openTemplateModal,
  openEmailModal,
  templateOnly,
  reopenOnly,
  reopenConversation,
  disabled,
}) => {
  const { savedResponsesData } = useSelector((state) => state.savedResponses);
  const [isSavedResponsesActive, setIsSavedResponsesActive] = useState(false);
  const [filteredResponses, setFilteredResponses] =
    useState(savedResponsesData);
  const [isEmojiActive, setIsEmojiActive] = useState(false);

  const selectedChannel = {
    value: capitalize(Channel?.medium),
    label: capitalize(Channel?.medium),
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && e.shiftKey) return;
    if (e.key === "Enter") {
      e.preventDefault();
      sendMessage();
    }
  };

  const toggleSavedResponses = () => {
    setIsSavedResponsesActive(!isSavedResponsesActive);
  };

  const searchSavedResponses = (value) => {
    const filteredResponses = savedResponsesData?.filter(({ title }) =>
      title.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredResponses(filteredResponses);
  };

  const selectSavedResponse = (response) => {
    setIsSavedResponsesActive(false);
    onChange({ target: { value: response } }, true);
  };

  const toggleEmojiBox = () => {
    setIsEmojiActive(!isEmojiActive);
  };

  const addEmoji = ({ native }) => {
    let textfieldValue = value;
    textfieldValue = `${textfieldValue}${native}`;
    onChange({ target: { value: textfieldValue } }, true);
    toggleEmojiBox();
  };

  return (
    <div
      className={classNames("chat_input_container", {
        disabled:
          disabled || templateOnly || reopenOnly || Channel?.medium === "EMAIL",
      })}
    >
      <div className="chat_input_header">
        <div className="d_flex">
          {selectedChannel?.label === "Whatsapp" && <WhatsApp />}
          {selectedChannel?.label === "Facebook" && <FacebookMessenger />}
          {selectedChannel?.label === "Twitter" && <Twitter />}
          {selectedChannel?.label === "Instagram" && <Instagram />}
          {selectedChannel?.label === "Email" && <Email />}
          <Select
            menuPlacement="top"
            classNamePrefix="select_container"
            value={selectedChannel}
            options={[]}
            onChange={() => {}}
            isDisabled
          />
        </div>
        <div className="d_flex end">
          {Channel?.name && (
            <div className="customer_info">
              <span>From:</span> {Channel?.name}
            </div>
          )}
        </div>
      </div>
      {Channel?.medium === "EMAIL" && !disabled ? (
        <div className="chat_input_actions email disabled">
          <p
            className="select-template format-email-link"
            onClick={openEmailModal}
          >
            Click here to begin composing your email
          </p>
        </div>
      ) : (
        <>
          <div className="chat_input_content">
            <textarea
              id="messageTextArea"
              name="message"
              value={
                templateOnly
                  ? "You can only start a Whatsapp conversation by sending a pre-approved Whatsapp template message"
                  : reopenOnly
                  ? "You can only send a message to this closed conversation by reopening the conversation"
                  : disabled
                  ? "You are not assigned this conversation"
                  : Channel?.medium === "EMAIL"
                  ? "Click on the link below to compose a message"
                  : value
              }
              placeholder={placeholder}
              onChange={(e) => onChange(e)}
              onKeyDown={handleKeyDown}
              maxLength={isAMetaChannel ? "2000" : null}
              disabled={
                disabled ||
                templateOnly ||
                reopenOnly ||
                Channel?.medium === "EMAIL"
              }
            />
            {media && !!Object.keys(media).length && (
              <div className="chat_input_media">
                <span className="close" onClick={removeFile}>
                  <Close />
                </span>
                {media.contentType === "IMAGE" && (
                  <div
                    className="message_media"
                    onClick={() =>
                      openFilePreview(media.contentType, rawData.rawData)
                    }
                  >
                    <img src={rawData.rawData} alt="" />
                  </div>
                )}
                {media.contentType === "VIDEO" && (
                  <span
                    className="message_media"
                    onClick={() =>
                      openFilePreview(media.contentType, rawData.rawData)
                    }
                  >
                    <video controls={false}>
                      <source
                        src={rawData.rawData}
                        type={rawData.mime}
                      ></source>
                    </video>
                  </span>
                )}
                {media.contentType === "DOCUMENT" &&
                  (rawData?.fileName &&
                  getExtensionType(rawData?.fileName) === "mp3" ? (
                    <div className="message-audio">
                      <AudioPlayer src={rawData.rawData} />
                    </div>
                  ) : (
                    <div className="message-file">
                      <span className="icon">
                        <File />
                      </span>
                      <a target="_blank">{rawData.fileName}</a>
                    </div>
                  ))}
              </div>
            )}
          </div>
          {templateOnly ? (
            <div className="chat_input_actions">
              <span className="select-template" onClick={openTemplateModal}>
                Select a template message
              </span>
            </div>
          ) : reopenOnly ? (
            <div className="chat_input_actions">
              <span className="select-template" onClick={reopenConversation}>
                Reopen this conversation
              </span>
            </div>
          ) : (
            <div className="chat_input_actions">
              <span
                title="Saved responses"
                onClick={!disabled ? toggleSavedResponses : () => {}}
              >
                <Notes />
                {isSavedResponsesActive && (
                  <SavedResponses
                    searchSavedResponses={searchSavedResponses}
                    filteredResponses={filteredResponses}
                    selectSavedResponse={selectSavedResponse}
                    toggleSavedResponses={toggleSavedResponses}
                  />
                )}
              </span>
              <span
                onClick={!disabled ? openTemplateModal : () => {}}
                title="Template messages"
              >
                <TemplateMessage />
              </span>
              <span
                title="Emoji"
                onClick={!disabled ? toggleEmojiBox : () => {}}
              >
                {isEmojiActive && (
                  <EmojiBox
                    onSelect={addEmoji}
                    toggleEmojiBox={toggleEmojiBox}
                  />
                )}
                <Emoji />
              </span>
              <span title="Gif">
                <Gif />
              </span>
              <span title="Attachment">
                <FileInput
                  icon={<Attachment />}
                  onFileLoad={onFileLoad}
                  onFileError={onFileError}
                  disabled={disabled}
                />
              </span>
              {/* <span>
            <Bag />
          </span> */}
              <span
                title="Send message"
                className="send"
                onClick={!disabled ? sendMessage : () => {}}
              >
                <Send />
              </span>
            </div>
          )}
        </>
      )}
    </div>
  );
};

const SavedResponses = ({
  filteredResponses,
  selectSavedResponse,
  searchSavedResponses,
  toggleSavedResponses,
}) => {
  const responses = _.chain(
    filteredResponses?.map((resp) => ({
      ...resp,
      category: resp.Category.name,
    }))
  )
    .groupBy("category")
    .map((value, key) => ({ category: key, data: value }))
    .value();

  const savedResponsesRef = useRef(null);
  useCloseElement(savedResponsesRef, toggleSavedResponses);

  return (
    <div
      className="saved-responses-container"
      ref={savedResponsesRef}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="title">Saved Responses</div>
      <div className="search">
        <TextField
          leftIcon={<Search />}
          placeholder="Search"
          onChange={(e) => searchSavedResponses(e.target.value)}
        />
      </div>
      {responses.map(({ category, data }, index) => (
        <div
          key={`responses-section-${index}`}
          className="saved-responses-section"
        >
          <div className="responses-category">
            {category}{" "}
            <span className="icon">
              <AngleDown />
            </span>
          </div>
          {data.map(({ title, content }, index2) => (
            <div
              key={`response-item-${index2}`}
              className="responses-item"
              onClick={() => selectSavedResponse(content)}
            >
              <span className="item-title">{title}</span>
              <span className="item-value">{content}</span>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

const EmojiBox = ({ onSelect, toggleEmojiBox }) => {
  const emojiBoxRef = useRef(null);
  useCloseElement(emojiBoxRef, toggleEmojiBox);

  return (
    <div className="emoji-container" ref={emojiBoxRef}>
      <Picker onSelect={onSelect} emojiTooltip={true} />
    </div>
  );
};

export default ChatInputNew;
