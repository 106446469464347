import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { AngleDown, CircularPlus } from "../../assets/vectors";

class Select extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFocused: false,
      value: props.value || "",
      isValid: false,
      isBlurred: false,
      newItem: false,
      newItemValue: "",
    };
  }

  toggleDropdown = (e) => {
    e.stopPropagation();
    this.setState({
      isFocused: !this.state.isFocused,
    });
  };

  getLabel = () => {
    const { value } = this.state;
    const { options } = this.props;

    const item = options?.find((opt) => opt.value === value);
    return item ? item?.label : "";
  };

  onChange = (value, dropdown) => {
    let { isValid } = this.state;
    const { onChange, required, name } = this.props;

    isValid = required ? value !== "" && value !== null : true;

    this.setState({
      value: dropdown ? value : this.state.value,
      isFocused: false,
      isBlurred: true,
      isValid,
    });

    onChange({ target: { value, name } }, isValid);
  };

  closeDropdown = () => {
    const { value, isFocused } = this.state;
    const { required } = this.props;

    isFocused &&
      this.setState({
        isFocused: false,
        isBlurred: true,
        isValid: required ? !!value : true,
      });
  };

  addNewItem = (e) => {
    e.stopPropagation();

    this.setState({
      newItem: true,
    });
  };

  handleNewItemChange = ({ target }) => {
    this.setState({
      newItemValue: target.value,
    });
  };

  componentDidMount() {
    window.addEventListener("click", this.closeDropdown);
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.closeDropdown);
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (prevProps.value !== value) {
      this.setState({
        value,
      });
    }
  }

  render() {
    const { isFocused, isValid, isBlurred, newItem, value } = this.state;
    const {
      options,
      className,
      label,
      filter,
      leftIcon,
      fixedPlaceholder,
      placeholder,
      buttonFilter,
      noDropdown,
      allowNewItem,
      newItemValue,
      handleNewItemChange,
      newItemAction,
    } = this.props;

    return (
      <div
        className={classNames(
          `input-container select-container ${className || ""}`,
          {
            isFocused,
            error: !isValid && isBlurred,
            filter,
            buttonFilter,
          }
        )}
      >
        {label && <label>{label}</label>}
        <div
          className={classNames("input-icon-container", {
            rightIcon: !noDropdown,
            leftIcon,
          })}
        >
          <div
            className={classNames("input select-current", {
              active: !!this.getLabel() && !noDropdown,
            })}
            onClick={this.toggleDropdown}
          >
            {!value && fixedPlaceholder && (
              <span className="fixed-placeholder">
                {placeholder || fixedPlaceholder}
              </span>
            )}
            {buttonFilter ? (
              <span>
                {placeholder}
                <span className="input-icon">
                  <AngleDown />
                </span>
              </span>
            ) : (
              <span>{this.getLabel()}</span>
            )}
          </div>
          {!noDropdown && !buttonFilter && (
            <span className="input-icon right">
              <AngleDown />
            </span>
          )}
          {leftIcon && <span className="input-icon left">{leftIcon}</span>}
        </div>
        {isFocused && (
          <ul className="select-items">
            {allowNewItem &&
              (newItem ? (
                <li
                  className="add-new-item item-input"
                  onClick={(e) => e.stopPropagation()}
                >
                  <input
                    type="text"
                    value={newItemValue}
                    onChange={handleNewItemChange}
                  />
                  {newItemAction}
                </li>
              ) : (
                <li className="add-new-item" onClick={this.addNewItem}>
                  <span className="icon">
                    <CircularPlus />
                  </span>
                  <span className="text">Add New Category</span>
                </li>
              ))}
            {options.map(({ label, value }, index) => {
              return (
                <li key={index} onClick={() => this.onChange(value, true)}>
                  {label}
                </li>
              );
            })}
          </ul>
        )}
      </div>
    );
  }
}

Select.propTypes = {
  options: PropTypes.array,
};

export default Select;
