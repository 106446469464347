import React, { Component } from "react";
import { connect } from "react-redux";
import { decode } from "html-entities";

import {
  getSavedResponsesAction,
  createSavedResponsesAction,
  deleteSavedResponsesAction,
} from "../../../redux/actions/SavedResponses";
import {
  getCategoriesAction,
  createCategoriesAction,
  updateCategoriesAction,
} from "../../../redux/actions/Categories";

import { Button, TextField, Select } from "../../../components/FormElements";
import Table from "../../../components/Table";
import Modal from "../../../components/Modal";
import OptionsDropdown from "../../../components/OptionsDropdown";

import {
  Filter,
  CircularPlus,
  Search,
  Pencil,
  Trash,
  Settings,
  Close,
  Edit,
} from "../../../assets/vectors";
import { savedResponsesPermissions } from "../../../utils/permissions";

import SavedReponseForm from "./SavedReponseForm";
import SettingsEmptyState from "../../../components/SettingsEmptyState";

class SavedResponses extends Component {
  state = {
    isSavedReponseFormActive: false,
    selectedSavedResponse: null,
    isConfirmationModalActive: false,
    isManageCategoriesActive: false,
    query: {
      offset: 0,
      limit: 20,
    },
    savedResponsesInfo: true,
    newItem: false,
    newItemValue: "",
    currentEditCategory: "",
    editCategoryValue: "",
  };

  removeInfo = () => {
    this.setState({
      savedResponsesInfo: false,
    });
  };

  addNewItem = (e) => {
    e.stopPropagation();

    this.setState({
      newItem: true,
    });
  };

  handleNewItemChange = ({ target }) => {
    this.setState({
      newItemValue: target.value,
    });
  };

  editCategory = (currentEditCategory) => {
    this.setState({
      currentEditCategory,
      editCategoryValue: currentEditCategory,
    });
  };

  handleEditItemChange = ({ target }) => {
    this.setState({
      editCategoryValue: target.value,
    });
  };

  toggleSavedResponseForm = (selectedSavedResponse) => {
    this.setState({
      isSavedReponseFormActive: !this.state.isSavedReponseFormActive,
      selectedSavedResponse,
    });
  };

  optionsAction = (type, row) => {
    if (type === "edit") {
      this.toggleSavedResponseForm(row);
    } else if (type === "delete") {
      this.toggleConfirmationModal(row);
    }
  };

  handleChange = ({ target }, valid) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [target.name]: {
          value: target.value,
          valid,
        },
      },
    });
  };

  toggleConfirmationModal = (selectedForDeletion) => {
    this.setState({
      selectedForDeletion,
      isConfirmationModalActive: !this.state.isConfirmationModalActive,
    });
  };

  toggleManageCategories = () => {
    this.setState({
      isManageCategoriesActive: !this.state.isManageCategoriesActive,
    });
  };

  deleteSavedResponse = () => {
    const { selectedForDeletion } = this.state;
    const { deleteSavedResponsesAction, getSavedResponsesAction } = this.props;

    deleteSavedResponsesAction(selectedForDeletion.id).then((res) => {
      if (res === 202) {
        getSavedResponsesAction(this.state.query);
        this.toggleConfirmationModal();
      }
    });
  };

  filterTable = (type, value) => {
    this.setState(
      {
        query: {
          ...this.state.query,
          [type]: value,
        },
      },
      () => this.props.getSavedResponsesAction(this.state.query)
    );
  };

  paginateTable = (number) => {
    const { query } = this.state;
    const { limit } = query;

    this.setState(
      {
        query: {
          ...query,
          offset: number * limit,
        },
      },
      () => this.props.getSavedResponsesAction(this.state.query)
    );
  };

  moreOptions = () => {
    var options = [];

    if (
      savedResponsesPermissions?.update ||
      savedResponsesPermissions?.modifyAll
    ) {
      options = [
        ...options,
        {
          label: (
            <div>
              <span className="icon">
                <Pencil />
              </span>{" "}
              <span className="text">Edit</span>
            </div>
          ),
          value: "edit",
        },
      ];
    }

    if (
      savedResponsesPermissions?.delete ||
      savedResponsesPermissions?.modifyAll
    ) {
      options = [
        ...options,
        {
          label: (
            <div className="red">
              <span className="icon">
                <Trash />
              </span>{" "}
              <span className="text">Delete</span>
            </div>
          ),
          value: "delete",
        },
      ];
    }

    return options;
  };

  componentDidMount() {
    this.props.getCategoriesAction();
    this.props.getSavedResponsesAction(this.state.query);
  }

  render() {
    const {
      isSavedReponseFormActive,
      isConfirmationModalActive,
      isManageCategoriesActive,
      selectedSavedResponse,
      savedResponsesInfo,
      newItem,
      newItemValue,
      currentEditCategory,
      editCategoryValue,
      query,
    } = this.state;
    const {
      getSavedResponsesAction,
      isLoadingSavedResponses,
      isDeletingSavedReponse,
      savedResponsesData,
      savedResponsesMeta,
      getCategoriesAction,
      createCategoriesAction,
      updateCategoriesAction,
      isCreatingCategory,
      isUpdatingCategory,
      categoriesData,
    } = this.props;

    const categoryOptions = categoriesData.map(({ id, name }) => ({
      label: name,
      value: id,
    }));

    const columns = [
      {
        id: "message",
        label: "Message",
        render: ({ title, config, content }) => (
          <div className="saved-response-message message">
            <span className="name">{title}</span>
            {config?.formatted ? (
              <span
                className="content"
                dangerouslySetInnerHTML={{
                  __html: decode(content, { level: "html5" }),
                }}
              ></span>
            ) : (
              <span
                className="content"
                dangerouslySetInnerHTML={{ __html: content }}
              ></span>
            )}
          </div>
        ),
      },
      {
        id: "category",
        label: "Category",
        render: ({ Category }) => (
          <div className="saved-response-message">
            <span>{Category?.name}</span>
          </div>
        ),
      },
      {
        id: "",
        label: "Actions",
        thClassName: "text-center",
        render: (row) => (
          <div className="text-center">
            <OptionsDropdown
              onClick={(val) => {
                this.optionsAction(val, row);
              }}
              options={this.moreOptions()}
            />
          </div>
        ),
      },
    ];

    return (
      <div className="settings-page-container">
        <div className="page-header">
          <span className="title">Saved Responses</span>
        </div>
        {savedResponsesInfo && (
          <div className="settings-page-info">
            <div>
              <span className="title">What are Saved Responses?</span>
              <span className="description">
                Saved responses allow you to pre-write replies to frequently
                asked questions and common issues and reply quickly to
                conversations.
              </span>
              <Button className="plain-button" onClick={this.removeInfo}>
                Got it
              </Button>
            </div>
            <div className="image">
              <img
                src={require("../../../assets/images/documents.png").default}
                alt=""
              />
            </div>
          </div>
        )}
        <div className="table-container saved-responses-table">
          {!isLoadingSavedResponses &&
          !savedResponsesData.length &&
          !query.q &&
          !query.category_id ? (
            <SettingsEmptyState
              title="You have no saved responses yet"
              description="When you create a saved response, it'll show up here."
              action={
                <>
                  {(savedResponsesPermissions.create ||
                    savedResponsesPermissions.modifyAll) && (
                    <Button
                      className="primary-button"
                      onClick={() => this.toggleSavedResponseForm()}
                    >
                      <span className="icon">
                        <CircularPlus />
                      </span>
                      <span>New saved response</span>
                    </Button>
                  )}
                </>
              }
            />
          ) : (
            <>
              <div className="table-filter">
                <div className="table-search-filter">
                  <TextField
                    placeholder="Search saved responses"
                    leftIcon={<Search />}
                    onChange={(e) => this.filterTable("q", e.target.value)}
                  />
                </div>
                <div className="table-other-filters">
                  {(savedResponsesPermissions.create ||
                    savedResponsesPermissions.update ||
                    savedResponsesPermissions.modifyAll) && (
                    <Button
                      className="filter-button mr-15"
                      onClick={() => this.toggleManageCategories()}
                    >
                      <span className="icon">
                        <Settings />
                      </span>
                      <span>Manage Categories</span>
                    </Button>
                  )}
                  <Select
                    leftIcon={<Filter />}
                    options={[
                      { label: "Category", value: "" },
                      ...categoryOptions,
                    ]}
                    onChange={(e) =>
                      this.filterTable("category_id", e.target.value)
                    }
                    className="mr-15"
                    filter
                  />
                  {(savedResponsesPermissions.create ||
                    savedResponsesPermissions.modifyAll) && (
                    <Button
                      className="primary-button small"
                      onClick={() => this.toggleSavedResponseForm()}
                    >
                      <span className="icon">
                        <CircularPlus />
                      </span>
                      <span>New saved response</span>
                    </Button>
                  )}
                </div>
              </div>

              <Table
                loading={isLoadingSavedResponses}
                columns={columns}
                rows={savedResponsesData}
                meta={savedResponsesMeta}
                editRow={this.toggleSavedResponseForm}
                deleteRow={this.toggleConfirmationModal}
                paginateTable={this.paginateTable}
              />
            </>
          )}
        </div>

        {isManageCategoriesActive && (
          <Modal closeModal={this.toggleManageCategories} small>
            <div className="modal-content manage-categories">
              <div className="modal-header">
                <span className="modal-title">Manage categories</span>
                <span className="close" onClick={this.toggleManageCategories}>
                  <Close />
                </span>
              </div>
              <ul className="category-items">
                {newItem ? (
                  <li
                    className="add-new-item item-input"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <input
                      type="text"
                      value={newItemValue}
                      onChange={this.handleNewItemChange}
                    />
                    <Button
                      className="plain-button"
                      type="button"
                      disabled={!newItemValue || isCreatingCategory}
                      onClick={() =>
                        createCategoriesAction({ name: newItemValue }, () =>
                          getCategoriesAction()
                        )
                      }
                    >
                      Save
                    </Button>
                  </li>
                ) : (
                  <li className="add-new-item" onClick={this.addNewItem}>
                    <span className="icon">
                      <CircularPlus />
                    </span>
                    <span className="text">Add New Category</span>
                  </li>
                )}
                {categoriesData.map(({ id, name }, index) => {
                  return currentEditCategory === name ? (
                    <li
                      className="add-new-item item-input"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <input
                        type="text"
                        value={editCategoryValue}
                        onChange={this.handleEditItemChange}
                      />
                      <Button
                        className="plain-button"
                        disabled={!editCategoryValue || isUpdatingCategory}
                        onClick={() =>
                          updateCategoriesAction(
                            { name: editCategoryValue },
                            id,
                            () => {
                              getCategoriesAction();
                            }
                          )
                        }
                      >
                        Save
                      </Button>
                    </li>
                  ) : (
                    <li key={index}>
                      <span>{name}</span>
                      <span onClick={() => this.editCategory(name)}>
                        <Edit />
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </Modal>
        )}

        {isSavedReponseFormActive && (
          <SavedReponseForm
            savedResponseData={selectedSavedResponse}
            toggleSavedResponseForm={this.toggleSavedResponseForm}
            newItemValue={newItemValue}
            handleNewItemChange={this.handleNewItemChange}
            getSavedResponsesAction={() =>
              getSavedResponsesAction(this.state.query)
            }
          />
        )}

        {isConfirmationModalActive && (
          <Modal closeModal={this.toggleConfirmationModal} small>
            <div className="modal-content confirmation-modal">
              <div className="modal-icon red">
                <Trash />
              </div>
              <div className="modal-title">
                Are you sure you want to delete this saved response?
              </div>
              <div className="modal-text">This action cannot be reversed</div>
              <div className="modal-action">
                <Button
                  className="secondary-button red"
                  type="button"
                  onClick={this.deleteSavedResponse}
                  disabled={isDeletingSavedReponse}
                >
                  {isDeletingSavedReponse
                    ? "Processing..."
                    : "Delete Saved Response"}
                </Button>
                <Button
                  className="plain-button black"
                  type="button"
                  onClick={this.toggleConfirmationModal}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ savedResponses, categories }) => ({
  ...savedResponses,
  ...categories,
});

export default connect(mapStateToProps, {
  getSavedResponsesAction,
  createSavedResponsesAction,
  deleteSavedResponsesAction,
  getCategoriesAction,
  createCategoriesAction,
  updateCategoriesAction,
})(SavedResponses);
