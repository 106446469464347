import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Picker } from "emoji-mart";
import Select from "react-select";
import moment from "moment-timezone";

import Modal from "../../../components/Modal";
import { Button, TextField } from "../../../components/FormElements";
import FormNotifications from "../../../components/Notifications";
import useCloseElement from "../../../components/CustomHooks/useCloseElement";

import {
  getWorkingHoursAction,
  postWorkingHoursAction,
  patchWorkingHoursAction,
} from "../../../redux/actions/WorkingHours";

import { Trash } from "../../../assets/vectors";

const listOfDays = [
  { label: "Sunday", value: "Sunday" },
  { label: "Monday", value: "Monday" },
  { label: "Tuesday", value: "Tuesday" },
  { label: "Wednesday", value: "Wednesday" },
  { label: "Thursday", value: "Thursday" },
  { label: "Friday", value: "Friday" },
  { label: "Saturday", value: "Saturday" },
];

const timeOptions = [
  { label: "01:00", value: "01:00" },
  { label: "02:00", value: "02:00" },
  { label: "03:00", value: "03:00" },
  { label: "04:00", value: "04:00" },
  { label: "05:00", value: "05:00" },
  { label: "06:00", value: "06:00" },
  { label: "07:00", value: "07:00" },
  { label: "08:00", value: "08:00" },
  { label: "09:00", value: "09:00" },
  { label: "10:00", value: "10:00" },
  { label: "11:00", value: "11:00" },
  { label: "12:00", value: "12:00" },
  { label: "13:00", value: "13:00" },
  { label: "14:00", value: "14:00" },
  { label: "15:00", value: "15:00" },
  { label: "16:00", value: "16:00" },
  { label: "17:00", value: "17:00" },
  { label: "18:00", value: "18:00" },
  { label: "19:00", value: "19:00" },
  { label: "20:00", value: "20:00" },
  { label: "21:00", value: "21:00" },
  { label: "22:00", value: "22:00" },
  { label: "23:00", value: "23:00" },
];

const WorkingHoursForm = ({
  closeModal,
  convertHoursObjToArray,
  workingHourData,
}) => {
  const dispatch = useDispatch();
  const { isSubmittingWorkingHours, workingHourOutcome, workingHourMessage } =
    useSelector((state) => state.workingHours);
  const { firmChannelsData } = useSelector((state) => state.channels);

  const workingHoursToEdit = convertHoursObjToArray(
    workingHourData?.hours || []
  )?.map((x) => ({
    day: { label: x?.day, value: x?.day },
    from: { label: x?.from, value: x?.from },
    to: { label: x?.to, value: x?.to },
  }));

  const defaultChannels = workingHourData?.Channels?.map((x) => ({
    label: `(${x?.medium}) - ${x?.name}`,
    value: x?.id,
  }));

  const [isEmojiBoxActive, setIsEmojiBoxActive] = useState(false);
  const [formData, setFormData] = useState({
    name: workingHourData?.name || "",
    timezone: workingHourData?.timezone
      ? { value: workingHourData?.timezone, label: workingHourData?.timezone }
      : {},
    hours: workingHoursToEdit?.length
      ? workingHoursToEdit
      : [
          {
            day: listOfDays?.[0],
            from: timeOptions?.[0],
            to: timeOptions?.[1],
          },
        ],
    message: workingHourData?.message || "",
    channels: defaultChannels || [],
  });
  const [formSubmitted, setFormSubmitted] = useState(false);

  var timeZonesOptions = moment.tz.names();
  timeZonesOptions = timeZonesOptions?.map((x) => ({ label: x, value: x }));

  const getDaysOptions = () => {
    const selectedDays = formData?.hours?.map((x) => x?.day?.label);
    const unSelectedDays = listOfDays?.filter(
      (x) => !selectedDays?.includes(x?.label)
    );
    return unSelectedDays || [];
  };

  const handleInputChange = (e) => {
    const { name, value } = e?.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDayAndTimeSelect = (index, key, selected) => {
    const updatedObj = {
      ...formData?.hours[index],
      [key]: selected,
    };

    const updatedArray = [
      ...formData?.hours.slice(0, index),
      updatedObj,
      ...formData?.hours.slice(index + 1),
    ];

    setFormData({
      ...formData,
      hours: updatedArray,
    });
  };

  const addNewWorkingHour = () => {
    setFormData({
      ...formData,
      hours: [
        ...formData?.hours,
        {
          day: { label: "", value: "" },
          from: { label: "", value: "" },
          to: { label: "", value: "" },
        },
      ],
    });
  };

  const deleteWorkingHour = (index) => {
    const objToDelete = formData?.hours[index];

    const updatedArray = formData?.hours?.filter(
      (x) =>
        x?.day?.value !== objToDelete?.day?.value ||
        x?.from?.value !== objToDelete?.from?.value ||
        x?.to?.value !== objToDelete?.to?.value
    );

    setFormData({
      ...formData,
      hours: updatedArray,
    });
  };

  const toggleEmojiBox = () => {
    setIsEmojiBoxActive(!isEmojiBoxActive);
  };

  const mergeWithText = ({ native }) => {
    let textfieldValue = formData?.message || "";
    textfieldValue = `${textfieldValue}${native}`;
    handleInputChange({ target: { name: "message", value: textfieldValue } });
    toggleEmojiBox();
  };

  const submitForm = (e) => {
    e.preventDefault();
    var mappedHours = formData?.hours.map((item) => ({
      [item?.day?.value]: {
        from: item?.from?.value,
        to: item?.to?.value,
      },
    }));
    var hoursObject = Object.assign({}, ...mappedHours);

    const requestData = {
      name: formData?.name,
      timezone: formData?.timezone?.value,
      hours: hoursObject,
      message: formData?.message,
      queueMessages: true,
      channels: formData?.channels?.map((x) => x?.value),
    };

    if (workingHourData) {
      dispatch(
        patchWorkingHoursAction({ id: workingHourData?.id, data: requestData })
      ).then((res) => {
        setFormSubmitted(true);
        if (res?.success === true) {
          dispatch(getWorkingHoursAction());
          closeModal();
        }
      });
    } else {
      dispatch(postWorkingHoursAction(requestData)).then((res) => {
        setFormSubmitted(true);
        if (res?.success === true) {
          dispatch(getWorkingHoursAction());
          closeModal();
        }
      });
    }
  };

  const channelOptions = firmChannelsData
    ?.filter((x) => x.status === "ACTIVE")
    ?.map((x) => ({
      label: `(${x?.medium}) - ${x?.name}`,
      value: x?.id,
    }));

  return (
    <Modal closeModal={closeModal}>
      <div className="modal-content working-hour-form">
        <div className="modal-title">
          {workingHourData ? "Edit" : "Add"} working hour
        </div>
        <div className="modal-form">
          <form onSubmit={submitForm}>
            <div className="input-container mb-25">
              <label>Channels</label>
              <Select
                classNamePrefix="select_container"
                value={formData?.channels}
                options={channelOptions}
                onChange={(selections) =>
                  setFormData({ ...formData, channels: selections })
                }
                isDisabled={isSubmittingWorkingHours}
                isClearable
                isMulti
              />
            </div>
            <TextField
              label="Preset Title"
              name="name"
              value={formData?.name}
              onChange={handleInputChange}
              className="mb-25"
            />
            <div className="input-container mb-25">
              <label>Timezone</label>
              <Select
                classNamePrefix="select_container"
                value={formData?.timezone}
                options={timeZonesOptions}
                onChange={(selection) =>
                  setFormData({
                    ...formData,
                    timezone: selection,
                  })
                }
              />
            </div>
            <div className="hours_select_container">
              <p className="label">Set your working hours</p>

              {formData?.hours?.map((item, index) => (
                <div key={index} className="item">
                  <Select
                    classNamePrefix="select_container"
                    value={item?.day}
                    options={getDaysOptions()}
                    onChange={(selected) =>
                      handleDayAndTimeSelect(index, "day", selected)
                    }
                  />
                  <Select
                    classNamePrefix="select_container"
                    value={item?.from}
                    options={timeOptions}
                    onChange={(selected) =>
                      handleDayAndTimeSelect(index, "from", selected)
                    }
                  />
                  <p>to</p>
                  <Select
                    classNamePrefix="select_container"
                    value={item?.to}
                    options={timeOptions}
                    onChange={(selected) =>
                      handleDayAndTimeSelect(index, "to", selected)
                    }
                  />
                  <span
                    className="trash"
                    onClick={() => deleteWorkingHour(index)}
                  >
                    <Trash />
                  </span>
                </div>
              ))}

              <p className="add_button" onClick={addNewWorkingHour}>
                + Add hours
              </p>
            </div>
            <div className="message_input_container">
              <h6 className="label">Away message</h6>
              <p className="sub_label">
                This message will be sent when visitors or users start a
                conversation with you outside the working hours set above.
              </p>
              <div className="input_container">
                <textarea
                  name="message"
                  type="text"
                  className="input"
                  rows="3"
                  placeholder="Type a message here..."
                  value={formData?.message}
                  onChange={handleInputChange}
                />

                <div className="input_actions">
                  <div className="action" onClick={toggleEmojiBox}>
                    <img
                      src="https://s3.eu-west-3.amazonaws.com/oneroute.asb.ng/smile.svg"
                      alt=""
                    />
                    {isEmojiBoxActive && (
                      <EmojiBox
                        onSelect={mergeWithText}
                        toggleEmojiBox={toggleEmojiBox}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-form-action">
              <Button
                type="button"
                className="secondary-button"
                onClick={closeModal}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="primary-button"
                onClick={submitForm}
                disabled={
                  !formData?.name ||
                  formData?.hours?.length < 1 ||
                  isSubmittingWorkingHours
                }
              >
                {isSubmittingWorkingHours
                  ? "Processing..."
                  : workingHourData
                  ? "Save Changes"
                  : "Save Preset"}
              </Button>
            </div>

            {formSubmitted && workingHourOutcome && (
              <FormNotifications
                type={workingHourOutcome}
                message={workingHourMessage}
              />
            )}
          </form>
        </div>
      </div>
    </Modal>
  );
};

const EmojiBox = ({ onSelect, toggleEmojiBox }) => {
  const emojiBoxRef = useRef(null);
  useCloseElement(emojiBoxRef, toggleEmojiBox);

  return (
    <div className="emoji-container" ref={emojiBoxRef}>
      <Picker onSelect={onSelect} emojiTooltip={true} />
    </div>
  );
};

export default WorkingHoursForm;
