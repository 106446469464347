import React from "react";

export default () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_232_14361)">
      <path
        d="M12.8873 7.59247C12.8873 9.79914 10.7006 11.5925 7.99397 11.5925C7.41314 11.591 6.83575 11.5034 6.28063 11.3325L3.47397 12.6658L4.57397 10.4658C4.12974 10.1335 3.76687 9.70455 3.51278 9.21139C3.25869 8.71823 3.12004 8.17376 3.1073 7.61914C3.1073 5.41247 5.29397 3.61914 8.00063 3.61914C10.7073 3.61914 12.8873 5.38581 12.8873 7.59247Z"
        stroke="#757F8A"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.8233 0.5H1.1566C0.788406 0.5 0.489929 0.798477 0.489929 1.16667V14.8333C0.489929 15.2015 0.788406 15.5 1.1566 15.5H14.8233C15.1915 15.5 15.4899 15.2015 15.4899 14.8333V1.16667C15.4899 0.798477 15.1915 0.5 14.8233 0.5Z"
        stroke="#757F8A"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.87384 8.00098C5.83052 8.00098 5.78898 7.98378 5.75835 7.95314C5.72772 7.92251 5.71051 7.88097 5.71051 7.83765C5.71051 7.79433 5.72772 7.75279 5.75835 7.72216C5.78898 7.69152 5.83052 7.67432 5.87384 7.67432"
        stroke="#757F8A"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.87341 7.67432C5.91673 7.67432 5.95827 7.69152 5.9889 7.72216C6.01954 7.75279 6.03674 7.79433 6.03674 7.83765C6.03674 7.88097 6.01954 7.92251 5.9889 7.95314C5.95827 7.98378 5.91673 8.00098 5.87341 8.00098"
        stroke="#757F8A"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.16016 8.00098C8.11684 8.00098 8.0753 7.98378 8.04467 7.95314C8.01404 7.92251 7.99683 7.88097 7.99683 7.83765C7.99683 7.79433 8.01404 7.75279 8.04467 7.72216C8.0753 7.69152 8.11684 7.67432 8.16016 7.67432"
        stroke="#757F8A"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.16052 7.67432C8.20384 7.67432 8.24539 7.69152 8.27602 7.72216C8.30665 7.75279 8.32386 7.79433 8.32386 7.83765C8.32386 7.88097 8.30665 7.92251 8.27602 7.95314C8.24539 7.98378 8.20384 8.00098 8.16052 8.00098"
        stroke="#757F8A"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4399 8.00098C10.3966 8.00098 10.3551 7.98378 10.3245 7.95314C10.2938 7.92251 10.2766 7.88097 10.2766 7.83765C10.2766 7.79433 10.2938 7.75279 10.3245 7.72216C10.3551 7.69152 10.3966 7.67432 10.4399 7.67432"
        stroke="#757F8A"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4404 7.67432C10.4837 7.67432 10.5252 7.69152 10.5559 7.72216C10.5865 7.75279 10.6037 7.79433 10.6037 7.83765C10.6037 7.88097 10.5865 7.92251 10.5559 7.95314C10.5252 7.98378 10.4837 8.00098 10.4404 8.00098"
        stroke="#757F8A"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_232_14361">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
