import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import DatePicker from "react-datepicker";
import * as moment from "moment";

import { getMessageLogsAction } from "../../../redux/actions/Insights";

import { TextField, Button } from "../../../components/FormElements";
import Pagination from "../../../components/Pagination/Pagination";

import { Calendar, Close } from "../../../assets/vectors";

const MessageLogs = ({ analytics, channelsData }) => {
  const dispatch = useDispatch();
  const { isLoadingMessageLogs, messageLogsData } = useSelector(
    (state) => state.insights
  );

  const [recipient, setRecipient] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [channel, setChannel] = useState(null);
  const [event, setEvent] = useState(null);
  const [network, setNetwork] = useState(null);
  const [status, setStatus] = useState(null);

  const filterRecords = (pageNumber) => {
    dispatch(
      getMessageLogsAction({
        "page[size]": 15,
        "page[number]": pageNumber || 1,
        to: recipient || null,
        channel_id: channel?.value || null,
        event: event?.value || null,
        network: network?.value || null,
        status: status?.map((x) => x.value) || null,
        datefrom: startDate ? moment(startDate).format("YYYY-MM-DD") : null,
        dateto: endDate ? moment(endDate).format("YYYY-MM-DD") : null,
      })
    );
  };

  useEffect(() => {
    filterRecords();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const datePickerDisplayValue = (sDate, eDate) => {
    if (sDate) {
      return `${
        moment(sDate).format("MMM DD, YYYY") +
        " - " +
        `${
          eDate
            ? moment(eDate).format("MMM DD, YYYY")
            : moment(sDate).format("MMM DD, YYYY")
        }`
      }`;
    } else return `Filter by Date range...`;
  };

  const channelOptions =
    channelsData?.map((x) => ({
      label: x?.name,
      value: x?.id,
    })) || [];

  const eventOptions = [
    { label: "Inbound", value: "inbound" },
    { label: "Outbound", value: "outbound" },
  ];

  const networkOptions = [
    { label: "MTN", value: "mtn" },
    { label: "Airtel", value: "airtel" },
    { label: "Glo", value: "glo" },
    { label: "9mobile", value: "nineMobile" },
  ];

  const statusOptions = analytics?.map((x) => ({
    label: x.key,
    value: x.key,
  }));

  const datePickerConfig = {
    className: "date_range_input",
    dateFormat: "yyyy-MM-dd",
    // minDate: new Date(moment().subtract(120, "days")),
    maxDate: new Date(),
    selectsRange: "selectsRange",
    shouldCloseOnSelect: false,
    disabled: isLoadingMessageLogs,
  };

  return (
    <div>
      <span className="insights-section-title">Message Logs</span>
      <div className="insights-filter">
        <div className="date_range_picker">
          <Calendar />
          <DatePicker
            onChange={([start, end]) => {
              setStartDate(start);
              setEndDate(end);
            }}
            startDate={startDate}
            endDate={endDate}
            value={datePickerDisplayValue(startDate, endDate)}
            {...datePickerConfig}
          />
          {endDate && (
            <span
              className="close"
              onClick={() => {
                setStartDate(null);
                setEndDate(null);
              }}
            >
              <Close />
            </span>
          )}
        </div>
        <div className="d_flex">
          <TextField
            label=""
            type="number"
            name="to"
            placeholder="Recipient phone"
            value={recipient}
            onChange={(e) => setRecipient(e.target.value)}
            disabled={isLoadingMessageLogs}
          />
          {recipient && (
            <span
              className="close"
              onClick={() => {
                setRecipient("");
              }}
            >
              <Close />
            </span>
          )}
        </div>
        <div className="d_flex">
          <Select
            classNamePrefix="select_container"
            value={channel}
            options={channelOptions}
            onChange={(selected) => setChannel(selected)}
            placeholder="Filter by Channel..."
            isDisabled={isLoadingMessageLogs}
            isClearable
          />
        </div>
        <div className="d_flex">
          <Select
            classNamePrefix="select_container"
            value={event}
            options={eventOptions}
            onChange={(selected) => setEvent(selected)}
            placeholder="Filter by Event..."
            isDisabled={isLoadingMessageLogs}
            isClearable
          />
        </div>
        <div className="d_flex">
          <Select
            classNamePrefix="select_container"
            value={network}
            options={networkOptions}
            onChange={(selected) => setNetwork(selected)}
            placeholder="Filter by Network..."
            isDisabled={isLoadingMessageLogs}
            isClearable
          />
        </div>
        <div className="d_flex">
          <Select
            classNamePrefix="select_container"
            value={status}
            options={statusOptions}
            onChange={(selected) => setStatus(selected)}
            placeholder="Filter by Status..."
            isDisabled={isLoadingMessageLogs}
            isClearable
            isMulti
          />
        </div>

        <div className="insights-filter-action">
          <Button
            className="primary-button full small"
            onClick={() => filterRecords(null)}
            disabled={isLoadingMessageLogs}
          >
            Filter
          </Button>
        </div>
      </div>

      <div className="insights-card message-logs-container">
        <div className="header grid-view">
          <p>Message</p>
          <p>From</p>
          <p>To</p>
        </div>
        <div className="content">
          {isLoadingMessageLogs ? (
            <div className="loading-container">Loading...</div>
          ) : (
            messageLogsData?.data?.map((item, i) => (
              <div key={i} className="message grid-view">
                <div className="info">
                  <p className="type">
                    {item?.event} -{" "}
                    <span className={`status ${item?.status}`}>
                      {item?.status}
                    </span>
                  </p>
                  <p className="text">
                    ID: <span>{item?.id}</span>
                  </p>
                  {item?.status === "undelivered" && (
                    <p className="text">
                      Reason: <span>{item?.reason}</span>
                    </p>
                  )}
                  <p className="text">
                    Channel ID: <span>{item?.channel_id}</span>
                  </p>
                </div>
                <div className="info">
                  <p className="type">
                    {moment(item?.createdAt).format("DD-MM-YYYY HH:mm")}
                  </p>
                  <p className="text">
                    <span>{item?.from}</span>
                  </p>
                </div>
                <div className="info">
                  <p className="type">
                    {moment(item?.updatedAt).format("DD-MM-YYYY HH:mm")}
                  </p>
                  <p className="text">
                    <span>{item?.to}</span>
                  </p>
                </div>
              </div>
            ))
          )}
        </div>

        <Pagination
          meta={{
            currentPage: messageLogsData?.currentPage,
            totalPage: messageLogsData?.totalPage,
          }}
          paginateTableAction={filterRecords}
        />
      </div>
    </div>
  );
};

export default MessageLogs;
