import { getRequest } from "../../api";

import {
  GET_CONVERSATIONS_INSIGHTS_LOADER,
  GET_CONVERSATIONS_INSIGHTS_RESPONSE,
  GET_AGENTS_INSIGHTS_LOADER,
  GET_AGENTS_INSIGHTS_RESPONSE,
  GET_CUSTOMERS_INSIGHTS_LOADER,
  GET_CUSTOMERS_INSIGHTS_RESPONSE,
  GET_CHATBOT_INSIGHTS_LOADER,
  GET_CHATBOT_INSIGHTS_RESPONSE,
  GET_MESSAGE_LOGS_LOADER,
  GET_MESSAGE_LOGS_RESPONSE,
} from "../constants";

import { getRequestError } from "../../utils/functions";

export const getConversationsInsightsLoader = (
  isLoadingConversationsInsights
) => ({
  type: GET_CONVERSATIONS_INSIGHTS_LOADER,
  isLoadingConversationsInsights,
});

export const getConversationsInsightsResponse = (
  conversationsInsightsOutcome,
  conversationsInsightsData,
  conversationsInsightsSection,
  conversationsInsightsMessage
) => ({
  type: GET_CONVERSATIONS_INSIGHTS_RESPONSE,
  conversationsInsightsOutcome,
  conversationsInsightsData,
  conversationsInsightsSection,
  conversationsInsightsMessage,
});

export const getAgentsInsightsLoader = (isLoadingAgentsInsights) => ({
  type: GET_AGENTS_INSIGHTS_LOADER,
  isLoadingAgentsInsights,
});

export const getAgentsInsightsResponse = (
  agentsInsightsOutcome,
  agentsInsightsData,
  agentsInsightsMessage
) => ({
  type: GET_AGENTS_INSIGHTS_RESPONSE,
  agentsInsightsOutcome,
  agentsInsightsData,
  agentsInsightsMessage,
});

export const getCustomersInsightsLoader = (isLoadingCustomersInsights) => ({
  type: GET_CUSTOMERS_INSIGHTS_LOADER,
  isLoadingCustomersInsights,
});

export const getCustomersInsightsResponse = (
  customersInsightsOutcome,
  customersInsightsData,
  customersInsightsSection,
  customersInsightsMessage
) => ({
  type: GET_CUSTOMERS_INSIGHTS_RESPONSE,
  customersInsightsOutcome,
  customersInsightsData,
  customersInsightsSection,
  customersInsightsMessage,
});

export const getChatbotInsightsLoader = (isLoadingChatbotInsights) => ({
  type: GET_CHATBOT_INSIGHTS_LOADER,
  isLoadingChatbotInsights,
});

export const getChatbotInsightsResponse = (
  chatbotInsightsOutcome,
  chatbotInsightsData,
  chatbotInsightsMessage
) => ({
  type: GET_CHATBOT_INSIGHTS_RESPONSE,
  chatbotInsightsOutcome,
  chatbotInsightsData,
  chatbotInsightsMessage,
});

export const getMessageLogsLoader = (isLoadingMessageLogs) => ({
  type: GET_MESSAGE_LOGS_LOADER,
  isLoadingMessageLogs,
});

export const getMessageLogsResponse = (
  messageLogsOutcome,
  messageLogsData,
  messageLogsMessage
) => ({
  type: GET_MESSAGE_LOGS_RESPONSE,
  messageLogsOutcome,
  messageLogsData,
  messageLogsMessage,
});

export const getConversationsInsightsAction = (data) => {
  return async (dispatch) => {
    dispatch(getConversationsInsightsLoader(true));

    try {
      const res = await getRequest({
        url: `/analytics/${data?.url || ""}`,
        params: data?.params,
        token: true,
      });

      dispatch(getConversationsInsightsLoader(false));
      dispatch(
        getConversationsInsightsResponse(
          "success",
          res.data.data || {},
          data?.section,
          ""
        )
      );
    } catch (error) {
      const message = getRequestError(error);

      dispatch(getConversationsInsightsResponse("error", {}, "", message));
      dispatch(getConversationsInsightsLoader(false));
    }
  };
};

export const getConversationTagsInsightsAction = (data) => {
  return async () => {
    try {
      const res = await getRequest({
        url: `/analytics/conversations/insight/tags`,
        params: data?.params,
        token: true,
      });

      return res.data.data || {};
    } catch (error) {
      const message = getRequestError(error);
      console.log(message, "message");
    }
  };
};

export const getAgentsInsightsAction = (data) => {
  return async (dispatch) => {
    dispatch(getAgentsInsightsLoader(true));

    try {
      const res = await getRequest({
        url: `/analytics/agents/${data?.url || ""}`,
        params: data?.params,
        token: true,
      });

      dispatch(getAgentsInsightsLoader(false));
      dispatch(getAgentsInsightsResponse("error", res.data.data || {}, ""));
    } catch (error) {
      const message = getRequestError(error);

      dispatch(getAgentsInsightsResponse("error", {}, message));
      dispatch(getAgentsInsightsLoader(false));
    }
  };
};

export const getCustomersInsightsAction = (data) => {
  return async (dispatch) => {
    dispatch(getCustomersInsightsLoader(true));

    try {
      const res = await getRequest({
        url: `/analytics/customers/${data?.url || ""}`,
        params: data?.params,
        token: true,
      });

      dispatch(getCustomersInsightsLoader(false));
      dispatch(
        getCustomersInsightsResponse(
          "error",
          res.data.data || {},
          data?.section,
          ""
        )
      );
    } catch (error) {
      const message = getRequestError(error);

      dispatch(getCustomersInsightsResponse("error", {}, message));
      dispatch(getCustomersInsightsLoader(false));
    }
  };
};

export const getChatbotInsightsAction = (params = {}) => {
  return async (dispatch) => {
    dispatch(getChatbotInsightsLoader(true));

    try {
      const res = await getRequest({
        url: `/flow-executions/`,
        params: params,
        token: true,
      });

      dispatch(getChatbotInsightsLoader(false));
      dispatch(getChatbotInsightsResponse("error", res.data || {}, ""));
    } catch (error) {
      const message = getRequestError(error);

      dispatch(getChatbotInsightsResponse("error", {}, message));
      dispatch(getChatbotInsightsLoader(false));
    }
  };
};

export const getChatbotInsightsOptionsAction = (params = {}) => {
  return async (dispatch) => {
    try {
      const res = await getRequest({
        url: `/flow-executions/widgets`,
        params: params,
        token: true,
      });

      return res.data || {};
    } catch (error) {
      const message = getRequestError(error);
      console.log(message);
    }
  };
};

export const getMessageLogsAnalyticsAction = (params = {}) => {
  return async () => {
    try {
      const res = await getRequest({
        url: "/messagelogs/analysis",
        params,
        token: true,
      });

      return res.data || [];
    } catch (error) {
      const message = getRequestError(error);
      console.log(message);
    }
  };
};

export const getMessageLogsAction = (params = {}) => {
  return async (dispatch) => {
    dispatch(getMessageLogsLoader(true));

    try {
      const res = await getRequest({
        url: "/messagelogs",
        params,
        token: true,
      });

      dispatch(getMessageLogsResponse("success", res.data || [], ""));
      dispatch(getMessageLogsLoader(false));
    } catch (error) {
      const message = getRequestError(error);

      dispatch(getMessageLogsResponse("error", [], message));
      dispatch(getMessageLogsLoader(false));
    }
  };
};
