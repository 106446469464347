import React, { Component } from "react";
import { connect } from "react-redux";
import * as shallowequal from "shallowequal";
import * as moment from "moment";
import DatePicker from "react-datepicker";
import ReactSelect from "react-select";

import { postRequest } from "../../api";

import {
  createCustomersAction,
  updateCustomersAction,
  getDialCodesAction,
} from "../../redux/actions/Customers";

import {
  Button,
  TextField,
  Select,
  TextArea,
  FileInput,
} from "../../components/FormElements";
import Modal from "../../components/Modal";
import FormNotifications from "../../components/Notifications";

import {
  patchFormValues,
  getFormValues,
  getRequestError,
} from "../../utils/functions";
import { Calendar } from "../../assets/vectors";

const initialFormData = {
  name: {
    value: "",
    valid: false,
  },
  whatsappPhoneNumber: {
    value: "",
    valid: true,
  },
  twitterUsername: {
    value: "",
    valid: true,
  },
  facebookMessengerId: {
    value: "",
    valid: true,
  },
  email: {
    value: "",
    valid: true,
  },
  dialCode: {
    value: "",
    valid: false,
  },
  phone: {
    value: "",
    valid: false,
  },
  instagramUsername: {
    value: "",
    valid: true,
  },
};

class CustomerForm extends Component {
  constructor(props) {
    super(props);

    const { customerData } = props;

    const formData = patchFormValues(initialFormData, customerData);

    this.state = {
      formData: formData ? formData : { ...initialFormData },
      dialCodes: [],
      isUploadingFile: false,
    };
  }

  handleChange = ({ target }, valid) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [target.name]: {
          value: target.value,
          valid,
        },
      },
    });
  };

  handleFileUpload = async (uploadData, { file }) => {
    const { customFieldsData } = this.props;

    const fileFieldName = customFieldsData?.filter(
      (x) => x?.type === "attachment"
    )?.[0]?.slug;

    if (file) {
      var fileData = new FormData();
      fileData.append("media", file);
      let payload = {};
      payload = fileData;

      this.setState({
        isUploadingFile: true,
      });

      try {
        const res = await postRequest({
          url: "/utils/upload",
          data: payload,
          token: true,
        });
        if (res?.status === 200) {
          this.setState({
            formData: {
              ...this.state.formData,
              [fileFieldName]: {
                value: res?.data?.data,
                valid: true,
              },
            },
            isUploadingFile: false,
          });
        }
      } catch (error) {
        const message = getRequestError(error);
        console.log(message);

        this.setState({
          isUploadingFile: false,
        });
      }
    }
  };

  checkFormValidity = () => {
    return Object.values(this.state.formData).every(({ valid }) => valid);
  };

  submitForm = (e) => {
    e.preventDefault();
    const {
      customerData,
      createCustomersAction,
      updateCustomersAction,
      toggleCustomerForm,
    } = this.props;

    const formData = getFormValues(this.state.formData);

    var regExp = /^0[0-9].*$/;
    const phoneNumberHasALeadingZero = regExp.test(formData?.phone);

    const payload = {
      ...formData,
      phone: phoneNumberHasALeadingZero
        ? `${formData?.dialCode?.value + formData?.phone.substring(1)}`
        : `${formData?.dialCode?.value + formData?.phone}`,
    };

    customerData
      ? updateCustomersAction(payload, customerData.id, toggleCustomerForm)
      : createCustomersAction(payload, toggleCustomerForm);
  };

  componentDidMount() {
    const { getDialCodesAction } = this.props;

    getDialCodesAction().then((res) =>
      this.setState({
        dialCodes: res || [],
      })
    );
  }

  componentDidUpdate(prevProps) {
    const { customerData, createCustomersOutcome } = this.props;

    if (
      !shallowequal(prevProps.customerData, customerData) &&
      Object.keys(customerData).length
    ) {
      const formData = patchFormValues(initialFormData, customerData);

      this.setState({
        formData,
      });
    }

    if (
      prevProps.createCustomersOutcome !== createCustomersOutcome &&
      createCustomersOutcome === "success"
    ) {
      this.setState({
        formData: { ...initialFormData },
      });
    }
  }

  render() {
    const { formData, dialCodes } = this.state;
    const {
      isCreatingCustomer,
      isUpdatingCustomer,
      toggleCustomerForm,
      createCustomersOutcome,
      createCustomersMessage,
      updateCustomersOutcome,
      updateCustomersMessage,
      customerData,
      customFieldsData,
    } = this.props;

    const {
      name,
      whatsappPhoneNumber,
      twitterUsername,
      facebookMessengerId,
      email,
      phone,
      instagramUsername,
    } = formData;

    const dialCodeOptions =
      dialCodes?.map((x) => ({
        label: `${x?.unicodeFlag} ${x?.dialCode || ""}`,
        value: x?.dialCode || "",
      })) || [];

    return (
      <Modal closeModal={() => toggleCustomerForm()}>
        <div className="modal-content">
          <div className="modal-title">
            {customerData ? "Edit" : "Add"} New Customer
          </div>
          <div className="modal-form agent-form add-customer-form">
            <form onSubmit={this.submitForm}>
              <div className="top-fields">
                <TextField
                  label="Name"
                  name="name"
                  value={name.value}
                  onChange={(e, valid) => this.handleChange(e, valid)}
                  required
                />
                <div className="input-container">
                  <label>Dial code</label>
                  <ReactSelect
                    classNamePrefix="select-options"
                    placeholder="---Please select---"
                    value={formData?.dialCode?.value}
                    options={[
                      { value: "234", label: "🇳🇬 234" },
                      ...dialCodeOptions,
                    ]}
                    onChange={(selection) =>
                      this.handleChange(
                        {
                          target: { name: "dialCode", value: selection },
                        },
                        true
                      )
                    }
                    isDisabled={isCreatingCustomer}
                  />
                </div>
                <TextField
                  label="Phone Number"
                  name="phone"
                  value={phone.value}
                  onChange={(e, valid) => this.handleChange(e, valid)}
                  min={6}
                  max={11}
                  required
                />
                <TextField
                  label="Email Address"
                  name="email"
                  value={email.value}
                  onChange={(e, valid) => this.handleChange(e, valid)}
                />
                <TextField
                  label="WhatsApp Phone Number"
                  name="whatsappPhoneNumber"
                  value={whatsappPhoneNumber.value}
                  onChange={(e, valid) => this.handleChange(e, valid)}
                />
                <TextField
                  label="Twitter Username"
                  name="twitterUsername"
                  value={twitterUsername.value}
                  onChange={(e, valid) => this.handleChange(e, valid)}
                />
                <TextField
                  label="Facebook ID"
                  name="facebookMessengerId"
                  value={facebookMessengerId.value}
                  onChange={(e, valid) => this.handleChange(e, valid)}
                />
                <TextField
                  label="Instagram Username"
                  name="instagramUsername"
                  value={instagramUsername.value}
                  onChange={(e, valid) => this.handleChange(e, valid)}
                />
              </div>

              <div className="custom-fields">
                {Array.isArray(customFieldsData) &&
                  customFieldsData?.map(({ name, slug, type, options }, i) => (
                    <React.Fragment key={i}>
                      {(type === "single_line_text" || type === "number") && (
                        <TextField
                          type={type}
                          label={name}
                          name={slug}
                          onChange={(e, valid) => this.handleChange(e, valid)}
                        />
                      )}

                      {type === "multi_line_text" && (
                        <TextArea
                          type={type}
                          label={name}
                          name={slug}
                          onChange={(e, valid) => this.handleChange(e, valid)}
                          rows="3"
                          noAction
                        />
                      )}

                      {type === "attachment" && (
                        <>
                          {formData?.[slug]?.valid ? (
                            <TextField
                              label={name}
                              name={slug}
                              value={formData?.[slug]?.value}
                              onChange={() => {}}
                              disabled={true}
                            />
                          ) : (
                            <div className="input-container">
                              <label>{name}</label>

                              <FileInput
                                icon={
                                  <span className="secondary-button small">
                                    Upload File
                                  </span>
                                }
                                onFileLoad={this.handleFileUpload}
                                onFileError={() => {}}
                              />
                            </div>
                          )}
                        </>
                      )}

                      {type === "single_select" && (
                        <Select
                          label={name}
                          name={slug}
                          options={options || []}
                          onChange={(e, valid) => this.handleChange(e, valid)}
                        />
                      )}

                      {type === "multi_select" && (
                        <div className="input-container">
                          <label>{name}</label>

                          <ReactSelect
                            classNamePrefix="select-options"
                            placeholder="---Please select---"
                            value={formData?.[slug]?.value}
                            options={options || []}
                            onChange={(selections) =>
                              this.handleChange(
                                { target: { name: slug, value: selections } },
                                true
                              )
                            }
                            isMulti
                            isDisabled={isCreatingCustomer}
                          />
                        </div>
                      )}

                      {type === "date" && (
                        <div className="input-container">
                          <label>{name}</label>

                          <div className="date_range_picker">
                            <Calendar />
                            <DatePicker
                              className="date_range_input"
                              selected={formData?.[slug]?.value}
                              onChange={(date) =>
                                this.handleChange(
                                  { target: { name: slug, value: date } },
                                  true
                                )
                              }
                              value={moment(formData?.[slug]?.value).format(
                                "DD MMM, YYYY"
                              )}
                              maxDate={new Date()}
                              // showTimeSelect
                            />
                          </div>
                        </div>
                      )}
                    </React.Fragment>
                  ))}
              </div>

              <div className="modal-form-action">
                <Button
                  type="button"
                  className="secondary-button"
                  onClick={() => toggleCustomerForm()}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  className="primary-button"
                  disabled={
                    !this.checkFormValidity() ||
                    isCreatingCustomer ||
                    isUpdatingCustomer
                  }
                >
                  {isCreatingCustomer || isUpdatingCustomer
                    ? "Processing..."
                    : customerData
                    ? "Save Changes"
                    : "Add Customer"}
                </Button>
              </div>

              {createCustomersOutcome === "error" && (
                <FormNotifications
                  type="error"
                  message={createCustomersMessage}
                />
              )}
              {updateCustomersOutcome === "error" && (
                <FormNotifications
                  type="error"
                  message={updateCustomersMessage}
                />
              )}
            </form>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = ({ customers, customFields }) => ({
  ...customers,
  ...customFields,
});

export default connect(mapStateToProps, {
  createCustomersAction,
  updateCustomersAction,
  getDialCodesAction,
})(CustomerForm);
