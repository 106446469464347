import React from "react";

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      stroke="#E24660"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.25"
      d="M11.77 15H4.23c-.285 0-.56-.123-.761-.342a1.218 1.218 0 01-.316-.825v-10.5h9.693v10.5c0 .31-.114.607-.316.825a1.037 1.037 0 01-.761.342zM6.385 11.5V6.833M9.615 11.5V6.833M1 3.333h14M9.616 1h-3.23c-.286 0-.56.123-.762.342a1.218 1.218 0 00-.315.825v1.166h5.384V2.167c0-.31-.113-.606-.315-.825A1.037 1.037 0 009.616 1z"
    ></path>
  </svg>
);
