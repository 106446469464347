import React from "react";

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      stroke="#474F58"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.25"
      d="M1.25 10a8.75 8.75 0 1017.5 0 8.75 8.75 0 00-17.5 0v0z"
    ></path>
    <path
      stroke="#474F58"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.25"
      d="M8.75 10a1.25 1.25 0 102.5 0 1.25 1.25 0 00-2.5 0v0zM10 8.75V4.375M10.885 10.884l2.24 2.24"
    ></path>
  </svg>
);
