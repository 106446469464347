import React from "react";

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="18"
    fill="none"
    viewBox="0 0 20 18"
  >
    <path
      stroke="#8F99A6"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M4.375.878l-3.75 3.75 3.75 3.75"
    ></path>
    <path
      stroke="#8F99A6"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M.625 4.628h12.5a6.25 6.25 0 110 12.5h-5"
    ></path>
  </svg>
);
