import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { usePaystackPayment } from "react-paystack";
import classNames from "classnames";
import * as moment from "moment";

import InvoicePdfGenerator from "./Invoice-Pdf-Generator";
import WalletPdfGenerator from "./Wallet-Pdf-Generator";
import TopUpWallet from "./TopUpWallet";

import { Button } from "../../../components/FormElements";
import Table from "../../../components/Table";
import Pagination from "../../../components/Pagination/Pagination";
import Modal from "../../../components/Modal";

import { getUserProfileAction } from "../../../redux/actions/Auth";
import {
  getFirmActiveSubscriptionAction,
  getFirmCardsAction,
  getFirmInvoicesAction,
  getFirmWalletHistoryAction,
  postPayFromWalletAction,
  patchActivateCardAction,
  deleteCardAction,
  getPlansDataAction,
  getFirmFreeWhatsAppConversationsCount,
} from "../../../redux/actions/Billing";
import { getAgentsAction } from "../../../redux/actions/Agents";
import { getCustomersAction } from "../../../redux/actions/Customers";
import {
  getInitials,
  getUserDetails,
  formatNumber,
  formatNumberII,
} from "../../../utils/functions";
import { billingPermissions } from "../../../utils/permissions";
import { CircularCheckGreen } from "../../../assets/vectors";
import { APP_EVENT_TOPUP_WALLET } from "../../../utils/constants";

const Billing = () => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const dispatch = useDispatch();
  const {
    isLoadingBilling,
    subscriptionData,
    plansData,
    cardsData,
    invoicesData,
    walletHistoryData,
  } = useSelector((state) => state.billing);
  const { agentsData } = useSelector((state) => state.agents);
  const { firm_id, email, firstName, lastName, firm_name } = getUserDetails();

  const [userProfile, setUserProfile] = useState({});
  const [freeWhatsAppConversations, setFreeWhatsAppConversations] =
    useState(null);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [amount, setAmount] = useState(null);
  const [invoiceToDownload, setInvoiceToDownload] = useState(null);
  const [transactionToDownload, setTransactionToDownload] = useState(null);
  const [showTopUpWalletModal, setShowTopUpWalletModal] = useState(false);
  const [isConfirmationModalActive, setIsConfirmationModalActive] =
    useState(false);
  const [isPayingFromWallet, setIsPayingFromWallet] = useState(false);
  const [invoiceRecordsQuery, setInvoiceRecordsQuery] = useState({
    limit: 5,
    page: 1,
  });
  const [walletRecordsQuery, setWalletRecordsQuery] = useState({
    limit: 5,
    page: 1,
  });

  useEffect(() => {
    getUserProfile();
    dispatch(getFirmActiveSubscriptionAction());
    dispatch(getFirmCardsAction());
    dispatch(getAgentsAction({ status: "confirmed" }));
    dispatch(getCustomersAction());
    dispatch(getPlansDataAction());
    dispatch(getFirmFreeWhatsAppConversationsCount()).then((res) =>
      setFreeWhatsAppConversations(res?.count)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const paystackConfig = {
    reference: showTopUpWalletModal
      ? `topup.${firm_id}.${new Date().getTime()}`
      : selectedInvoice
      ? `invoice.${selectedInvoice}.${new Date().getTime()}`
      : `card.${firm_id}.${new Date().getTime()}`,
    email: email,
    amount: amount * 100 || 5000,
    currency: "NGN",
    publicKey: process.env.REACT_APP_PAYSTACK_KEY,
  };

  const onPaymentSuccess = (res) => {
    if (res?.message === "Approved") {
      dispatch(getFirmCardsAction());

      if (showTopUpWalletModal) {
        window.dataLayer.push({
          event: APP_EVENT_TOPUP_WALLET,
          agent_name: `${firstName} ${lastName}`,
          firm_name: firm_name,
          email: email,
          amount: amount,
          currency: "NGN",
        });
      }
    }
  };

  const onInvoicePaymentSuccess = (res) => {
    if (res?.message === "Approved") {
      dispatch(getFirmInvoicesAction());
      setSelectedInvoice(null);
      setAmount(null);
    }
  };

  const onPaymentClose = () => {
    console.log("closed");
    setSelectedInvoice(null);
    setAmount(null);
  };

  const getUserProfile = () => {
    dispatch(getUserProfileAction()).then((res) => {
      if (res?.success === true) {
        setUserProfile(res?.data);
      }
    });
  };

  const activateCard = (id) => {
    dispatch(patchActivateCardAction({ id })).then((res) => {
      if (res?.id) {
        dispatch(getFirmCardsAction());
      }
    });
  };

  const deleteCard = (id) => {
    dispatch(deleteCardAction({ id })).then((res) => {
      if (res?.success === true) {
        dispatch(getFirmCardsAction());
      }
    });
  };

  const paginateInvoiceRecords = (pageNumber) => {
    if (pageNumber) {
      setInvoiceRecordsQuery({
        ...invoiceRecordsQuery,
        page: pageNumber,
      });
      setInvoiceToDownload(null);
      setTransactionToDownload(null);
    }
  };
  useEffect(() => {
    dispatch(
      getFirmInvoicesAction({
        ...invoiceRecordsQuery,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceRecordsQuery]);

  const payFromWallet = () => {
    setIsPayingFromWallet(true);

    dispatch(postPayFromWalletAction(selectedInvoice)).then((res) => {
      if (res?.data.status === "paid") {
        getUserProfile();
        paginateInvoiceRecords(1);
        setIsConfirmationModalActive(false);
      }
      setIsPayingFromWallet(false);
      setSelectedInvoice(null);
    });
  };

  const invoiceTableColumns = [
    {
      id: "invoiceNumber",
      label: "Invoice number",
      render: ({ invoiceNumber }) => <>#{invoiceNumber}</>,
    },
    {
      id: "date",
      label: "Date",
      render: ({ issuedAt }) => moment(issuedAt).format("DD-MM-YYYY"),
    },
    {
      id: "dueDate",
      label: "Due date",
      render: ({ dueAt }) => moment(dueAt).format("DD-MM-YYYY"),
    },
    {
      id: "amount",
      label: "Amount",
      render: ({ total }) => <>NGN {total?.toFixed(2)}</>,
    },
    {
      id: "status",
      label: "Status",
      render: ({ status }) => (
        <span
          className={classNames(
            "status",
            status === "Paid" ? "success" : "error"
          )}
        >
          {status}
        </span>
      ),
    },
    {
      id: "pay",
      label: "",
      render: ({ id, status, total }) => (
        <>
          {(status === "overdue" || status === "pending") &&
            userCanModifyBilling && (
              <div className="invoice-actions">
                {userProfile?.units >= total && (
                  <Button
                    className="primary-button"
                    onClick={() => {
                      setSelectedInvoice(id);
                      setIsConfirmationModalActive(true);
                    }}
                  >
                    Pay from Wallet
                  </Button>
                )}
                <Button
                  className="primary-button"
                  onClick={() => {
                    setSelectedInvoice(id);
                    setAmount(total?.toFixed(2));
                  }}
                >
                  Pay online
                </Button>
              </div>
            )}
        </>
      ),
    },
    {
      id: "url",
      label: "",
      render: (invoice) => (
        <p
          className="plain-button"
          onClick={() => setInvoiceToDownload(invoice)}
        >
          Download pdf
        </p>
      ),
    },
  ];
  useEffect(() => {
    if (amount > 0) {
      initializePayment(onInvoicePaymentSuccess, onPaymentClose);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount]);

  const paginateWalletRecords = (pageNumber) => {
    if (pageNumber) {
      setWalletRecordsQuery({
        ...walletRecordsQuery,
        page: pageNumber,
      });
      setInvoiceToDownload(null);
      setTransactionToDownload(null);
    }
  };
  useEffect(() => {
    dispatch(
      getFirmWalletHistoryAction({
        ...walletRecordsQuery,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletRecordsQuery]);

  const replaceText = (text) => {
    return text.replace(`wallet.${firm_id}.`, "") || text;
  };

  const walletTableColumns = [
    {
      id: "transactionRef",
      label: "Transaction Ref",
      render: ({ transactionRef }) => <>{replaceText(transactionRef)}</>,
    },
    {
      id: "action",
      label: "Action",
      render: ({ action }) => <>{action}</>,
    },
    {
      id: "cost",
      label: "Amount",
      render: ({ quantity }) => <>NGN {formatNumber(quantity)}</>,
    },
    {
      id: "updatedAt",
      label: "Date",
      render: ({ updatedAt }) => moment(updatedAt).format("DD-MM-YYYY HH:MM"),
    },
    {
      id: "url",
      label: "",
      render: (transaction) => (
        <p
          className="plain-button"
          onClick={() => setTransactionToDownload(transaction)}
        >
          Download pdf
        </p>
      ),
    },
  ];

  const initializePayment = usePaystackPayment(paystackConfig);

  const progressBarValue =
    (subscriptionData?.customers?.count /
      (subscriptionData?.customers?.max || 10000000)) *
    100;

  const freeWhatsAppConversationsValue =
    (freeWhatsAppConversations / 1000) * 100;

  const maxCustomerSeat = !subscriptionData?.customers?.max
    ? 1000000000000
    : subscriptionData?.customers?.max;

  const userCanModifyBilling =
    billingPermissions.update || billingPermissions.modifyAll;

  return (
    <div className="settings-page-container billing-page-container">
      <div className="page-header">
        <span className="title">Billing</span>
      </div>
      {!isLoadingBilling ? (
        <>
          <div className="section_container">
            <div className="header">
              <p>Selected Plan</p>
              {subscriptionData?.currentPlan?.level !== 4 &&
                userCanModifyBilling && (
                  <Button
                    className="primary-button"
                    onClick={() => history.push(`${url}/upgrade`)}
                  >
                    Upgrade Plan
                  </Button>
                )}
            </div>

            <div className="content">
              <div className="info_container">
                <div className="left">
                  <h4 className="plan_name">
                    {subscriptionData?.currentPlan?.name ||
                      plansData?.[0]?.name}{" "}
                    Plan
                  </h4>
                  <div className="agents_container">
                    {agentsData?.length > 0 && (
                      <div className="agents">
                        {agentsData?.slice(0, 5)?.map((agent, i) => (
                          <div key={i} className="agent">
                            {getInitials(
                              `${agent?.firstName} ${agent?.lastName}`
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                    <p>
                      {subscriptionData?.users?.count > 0 ? (
                        <>{subscriptionData?.users?.count} agents added</>
                      ) : (
                        "You have no agents added yet"
                      )}
                    </p>
                  </div>
                </div>
                {subscriptionData?.currentPlan &&
                  subscriptionData?.currentPlan?.level !== 4 &&
                  subscriptionData?.users?.max ===
                    subscriptionData?.users?.count && (
                    <p className="warning">Upgrade plan to add more agents</p>
                  )}
              </div>
              <div className="info_container">
                <div className="left">
                  <h6>Customers</h6>
                  <div className="progress_bar_div">
                    <div className="progress_bar">
                      <span
                        style={{
                          width: `${
                            progressBarValue > 100 ? 100 : progressBarValue
                          }%`,
                        }}
                      ></span>
                    </div>
                    <p className="counter">
                      {subscriptionData?.customers?.count}
                      {subscriptionData?.customers?.max ? (
                        <>/{subscriptionData?.customers?.max}</>
                      ) : (
                        " customers"
                      )}
                    </p>
                  </div>
                </div>
                {subscriptionData?.customers?.count >= maxCustomerSeat && (
                  <p className="warning">Upgrade plan to add more customers</p>
                )}
              </div>
              <div className="info_container">
                <div className="left">
                  <h6>Free WhatsApp Conversations</h6>
                  <div className="progress_bar_div">
                    <div className="progress_bar">
                      <span
                        style={{
                          width: `${
                            freeWhatsAppConversationsValue > 100
                              ? 100
                              : freeWhatsAppConversationsValue
                          }%`,
                        }}
                      ></span>
                    </div>
                    <p className="counter">
                      {freeWhatsAppConversations} / 1,000
                    </p>
                  </div>
                </div>
              </div>
              <div className="info_container">
                <div className="left">
                  <h6>Next Invoice Amount</h6>
                  <div className="cost_div">
                    NGN {formatNumberII(subscriptionData?.total)}
                  </div>
                </div>
                <div className="left">
                  <h6>Next Invoice Date</h6>
                  <div className="cost_div">
                    {moment(subscriptionData?.endDate).format("DD-MM-YYYY")}
                  </div>
                </div>
                <div className="left"></div>
              </div>
              <div className="info_container">
                <div className="left">
                  <h6>Wallet Balance</h6>
                  <div className="cost_div">
                    NGN {formatNumberII(userProfile?.units)}
                  </div>
                </div>
                {userCanModifyBilling && (
                  <Button
                    className="secondary-button"
                    onClick={() => setShowTopUpWalletModal(true)}
                  >
                    Top-up Wallet
                  </Button>
                )}
              </div>
            </div>
          </div>

          <div className="section_container">
            <div className="header">
              <p>Payment Methods</p>
            </div>

            <div className="content">
              {subscriptionData?.currentPlan?.level !== 1 ? (
                <>
                  {cardsData?.length > 0 && (
                    <div className="cards">
                      {cardsData?.map((card, i) => (
                        <div key={i} className="card_info_container">
                          <div className="card_header">
                            {card?.brand === "mastercard" && (
                              <img
                                src="https://s3.eu-west-3.amazonaws.com/oneroute.asb.ng/mastercard.png"
                                alt="Mastercard"
                              />
                            )}
                            {card?.brand === "visa" && (
                              <img
                                src="https://s3.eu-west-3.amazonaws.com/oneroute.asb.ng/visa.png"
                                alt="Mastercard"
                              />
                            )}
                            {card?.brand === "verve" && (
                              <img
                                src="https://s3.eu-west-3.amazonaws.com/oneroute.asb.ng/verve.png"
                                alt="Mastercard"
                              />
                            )}
                            <p>{card?.account_name}</p>
                          </div>
                          <div className="card_body">
                            <p>
                              {card?.brand} <span>ending in</span> {card?.last4}
                            </p>
                            <p>
                              Expires{" "}
                              <span>
                                {card?.exp_month}/{card?.exp_year}
                              </span>
                            </p>
                          </div>
                          <div className="card_footer">
                            {!card?.active && userCanModifyBilling && (
                              <Button
                                className="primary-button"
                                onClick={() => activateCard(card?.id)}
                              >
                                Set as default
                              </Button>
                            )}
                            {(!subscriptionData?.currentPlan ||
                              !card?.active) &&
                              userCanModifyBilling && (
                                <Button
                                  className="tertiary-button"
                                  onClick={() => deleteCard(card?.id)}
                                >
                                  Remove
                                </Button>
                              )}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}

                  {userCanModifyBilling ? (
                    <>
                      <div className="actions">
                        <Button
                          className="secondary-button"
                          onClick={() =>
                            initializePayment(onPaymentSuccess, onPaymentClose)
                          }
                        >
                          Add card
                        </Button>
                      </div>
                      <p className="warning card_notification">
                        Your card will be charged NGN 50 and it will be
                        reversed.
                      </p>
                    </>
                  ) : (
                    <br />
                  )}
                </>
              ) : (
                <div className="no_data">No payment method added</div>
              )}
            </div>
          </div>

          <div className="section_container">
            <div className="header">
              <p>Invoices</p>
            </div>

            <div className="content invoice-table-container">
              {subscriptionData?.currentPlan?.level !== 1 ? (
                <>
                  <Table
                    columns={invoiceTableColumns}
                    rows={invoicesData?.data || []}
                  />
                  <Pagination
                    meta={{
                      currentPage: invoicesData?.currentPage,
                      totalPage: invoicesData?.totalPage,
                    }}
                    paginateTableAction={paginateInvoiceRecords}
                  />
                </>
              ) : (
                <div className="no_data">No invoices available</div>
              )}

              <div className="pdf_viewer">
                <InvoicePdfGenerator invoiceToDownload={invoiceToDownload} />
              </div>
            </div>
          </div>

          <div className="section_container">
            <div className="header">
              <p>Wallet History</p>
            </div>

            <div className="content">
              <Table
                columns={walletTableColumns}
                rows={walletHistoryData?.data || []}
              />
              <Pagination
                meta={{
                  currentPage: walletHistoryData?.currentPage,
                  totalPage: walletHistoryData?.totalPage,
                }}
                paginateTableAction={paginateWalletRecords}
              />

              <div className="pdf_viewer">
                <WalletPdfGenerator
                  transactionToDownload={transactionToDownload}
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="loading">Loading...</div>
      )}

      {showTopUpWalletModal && (
        <TopUpWallet
          close={() => setShowTopUpWalletModal(false)}
          getUserProfile={getUserProfile}
          getWalletHistory={() =>
            dispatch(
              getFirmWalletHistoryAction({
                ...walletRecordsQuery,
              })
            )
          }
        />
      )}

      {isConfirmationModalActive && (
        <Modal closeModal={() => setIsConfirmationModalActive(false)} small>
          <div className="modal-content confirmation-modal billing-confirmation-modal">
            <div className="modal-icon">
              <CircularCheckGreen />
            </div>
            <div className="modal-title">
              Are you sure you want to pay for this invoice from your wallet
              balance?
            </div>
            <div className="modal-action">
              <Button
                className="secondary-button"
                type="button"
                onClick={payFromWallet}
                disabled={isPayingFromWallet}
              >
                {isPayingFromWallet ? "Processing..." : "Pay from Wallet"}
              </Button>
              <Button
                className="plain-button black"
                type="button"
                onClick={() => setIsConfirmationModalActive(false)}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Billing;
