import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import classNames from "classnames";

import { Button } from "../../../components/FormElements";
import Toaster from "../../../components/Toaster";
import UpgradeInfoModal from "./UpgradeInfo";

import {
  getPlansDataAction,
  getFirmActiveSubscriptionAction,
  getFirmCardsAction,
} from "../../../redux/actions/Billing";

import { formatNumber, usdRateToday } from "../../../utils/functions";
import { ArrowUp, CircularCheckGreen } from "../../../assets/vectors";

const Upgrade = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isLoadingBilling, subscriptionData } = useSelector(
    (state) => state.billing
  );

  // const [cycle, setCycle] = useState("monthly");
  // const [currency, setCurrency] = useState("NGN");
  const [toast, setToast] = useState({});
  const [paygPlan, setPaygPlan] = useState({});
  const [plansData, setPlansData] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);

  const cycle = "monthly";
  const currency = "NGN";

  const currencyOptions = ["NGN", "USD"];
  const currencyCost = currency === currencyOptions[0] ? 1 : usdRateToday();
  const currencySymbol = currency === currencyOptions[0] ? "₦" : "$";

  const cycleOptions = ["monthly", "yearly"];
  const cycleCost = cycle === cycleOptions[0] ? 1 : 10;

  useEffect(() => {
    dispatch(getPlansDataAction()).then((res) => {
      const arr = res;
      setPaygPlan(arr[0]);

      arr?.shift();
      setPlansData(arr);
    });
    dispatch(getFirmActiveSubscriptionAction());
    dispatch(getFirmCardsAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const handleCurrencyChange = (e) => {
  //   const { value } = e?.target;
  //   setCurrency(value);
  // };

  const handleChoosePlan = (plan, payg) => {
    if (plan?.price) {
      setSelectedPlan({
        ...plan,
        amountToPay: parseFloat(
          (plan?.price * cycleCost) / currencyCost
        ).toFixed(0),
        currency,
        currencySymbol,
        cycle,
        payg: payg || false,
      });
    } else {
      window.open("https://www.oneroute.io/request-demo");
    }
  };

  const openToast = (status, content) => {
    setToast({
      open: "open",
      status,
      content,
    });
  };

  const closeToast = () => {
    setToast({
      ...toast,
      open: "closed",
    });
  };

  const usageData = [
    {
      channel: "WhatsApp",
      category: [
        "Utility (e.g Transaction updates",
        "Authentication (e.g OTPs)",
        "Marketing (e.g Promotional conversations)",
        "Service (Customer initiated conversations)",
      ],
      price: [
        { value: 34, type: "conversation" },
        { value: 31, type: "conversation" },
        { value: 50, type: "conversation" },
        { value: 31, type: "conversation" },
      ],
    },
    {
      channel: "Voice",
      category: ["Call Rate"],
      price: [{ value: 0.2, type: "second" }],
    },
    {
      channel: "Voice",
      category: ["Additional phone number"],
      price: [{ value: 3.0, type: "month" }],
    },
    {
      channel: "SMS",
      category: ["Promotional"],
      price: [{ value: 3.5, type: "message" }],
    },
    {
      channel: "SMS",
      category: ["Transactional"],
      price: [{ value: 2.5, type: "message" }],
    },
  ];

  const currentPlanLevel = subscriptionData?.currentPlan?.level;
  const currentPlanId = subscriptionData?.currentPlan?.id;
  const isTrailPlan = subscriptionData?.subscriptionType === "trial";

  return (
    <>
      <div className="settings-page-container upgrade-page-container">
        {isLoadingBilling ? (
          <div className="loading">Loading...</div>
        ) : (
          <div className="section_container">
            <div className="top_section">
              <div className="go_back" onClick={() => history.goBack()}>
                <ArrowUp /> Go back
              </div>

              <p className="title">Affordable and flexible pricing plans</p>

              {/* <div className="cycle_and_price">
                <div></div>

                <div className="cycle">
                  {cycleOptions?.map((item, i) => (
                    <p
                      key={i}
                      className={`item${cycle === item ? ` active` : ``}`}
                      onClick={() => setCycle(item)}
                    >
                      {item}
                    </p>
                  ))}
                </div>

                <select
                  className="price"
                  name="price"
                  onChange={handleCurrencyChange}
                >
                  {currencyOptions?.map((item, i) => (
                    <option key={i} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div> */}
            </div>

            <div className="content">
              <div className="plans_container">
                {plansData?.map((plan, i) => (
                  <div
                    key={i}
                    className={classNames("plan", {
                      recommended: currentPlanId ? null : i === 0,
                      current: currentPlanId === plan?.id && !isTrailPlan,
                    })}
                  >
                    <div className="top">
                      <p className="name">{plan?.name}</p>
                      <p className="desc">{plan?.description}</p>
                      <p className="cost">
                        {plan?.price
                          ? `${currencySymbol}${formatNumber(
                              (plan?.price * cycleCost) / currencyCost
                            )}`
                          : `Custom`}
                      </p>
                      <Button
                        className="primary-button"
                        onClick={
                          currentPlanId === plan?.id && !isTrailPlan
                            ? () => {}
                            : () => handleChoosePlan(plan)
                        }
                        disabled={
                          currentPlanLevel > plan?.level && !isTrailPlan
                        }
                      >
                        {currentPlanId === plan?.id && !isTrailPlan
                          ? "Current Plan"
                          : plan?.price
                          ? `Choose Plan`
                          : `Talk to sales`}
                      </Button>
                    </div>

                    <div className="features_div">
                      <h6>
                        {i === 0
                          ? `Features include:`
                          : i === 1
                          ? `Everything in starter plus:`
                          : `Everything in business plus:`}
                      </h6>
                      {plan?.features?.map((item, i) => (
                        <p key={i}>
                          <CircularCheckGreen /> <span>{item}</span>
                        </p>
                      ))}
                    </div>
                  </div>
                ))}
              </div>

              <div className="payg_container">
                <p className="title">
                  Would you prefer the Pay as you go plan?
                </p>
                <p className="sub_title">
                  Instead of paying a monthly recurring charge, you can buy
                  credits as needed through our Pay As You Go plan. It's best
                  suited for API users and you get the standard support.
                </p>
                <Button
                  className="primary-button"
                  onClick={() =>
                    handleChoosePlan({ ...paygPlan, price: 1 }, true)
                  }
                  disabled={currentPlanLevel > paygPlan?.level && !isTrailPlan}
                >
                  Buy Credits
                </Button>
              </div>

              <div className="usage_container">
                <div className="title_section">
                  {/* <div></div> */}

                  <p>Usage fees</p>

                  {/* <select
                    className="price"
                    name="price"
                    onChange={handleCurrencyChange}
                  >
                    {currencyOptions?.map((item, i) => (
                      <option key={i} value={item}>
                        {item}
                      </option>
                    ))}
                  </select> */}
                </div>

                <div className="table">
                  <div className="header">
                    <p>Channel</p>
                    <p>Category</p>
                    <p>Price ({currency})</p>
                  </div>

                  <div className="body">
                    {usageData?.map((item, index) => (
                      <div key={index} className="row">
                        <p>{item?.channel}</p>

                        <div className="all_categories">
                          {item?.category?.map((category, i) => (
                            <p key={i} className="category">
                              {category}
                            </p>
                          ))}
                        </div>

                        <div className="all_prices">
                          {item?.price?.map((price, i) => (
                            <p key={i} className="price">
                              {currencySymbol}
                              {parseFloat(price?.value / currencyCost).toFixed(
                                currencyCost === 1 ? 2 : 4
                              )}
                              /{price?.type}
                            </p>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {selectedPlan && (
        <UpgradeInfoModal
          close={() => setSelectedPlan(null)}
          planInfo={selectedPlan}
          openToast={openToast}
        />
      )}

      <Toaster {...toast} closeToaster={closeToast} />
    </>
  );
};

export default Upgrade;
