import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Toaster from "../../../components/Toaster";

import { Button, TextField } from "../../../components/FormElements";
import Modal from "../../../components/Modal";
import OptionsDropdown from "../../../components/OptionsDropdown";

import {
  getAutoAssignsAction,
  patchAutoAssignsAction,
} from "../../../redux/actions/AutoAssignment";

import { officeHoursPermissions } from "../../../utils/permissions";

import { Search } from "../../../assets/vectors";

const AutoAssignment = () => {
  const dispatch = useDispatch();
  const { isSubmittingAutoAssigns, autoAssignsData } = useSelector(
    (state) => state.autoAssigns
  );

  const [showActivateModal, setShowActivateModal] = useState(false);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const [toaster, setToaster] = useState({});
  const [query, setQuery] = useState({});
  const [assignList, setAssignList] = useState([
    {
      name: "Round Robin + Message",
      description:
        "Chats are evenly assigned amongst online agents. The round-robin ticket assignment focuses on distributing the tickets equitably among agents so that the workload is equally balanced.",
      active: false,
    },
  ]);

  useEffect(() => {
    dispatch(getAutoAssignsAction());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let item = assignList[0];

    if (
      (autoAssignsData?.auto_assign === true && item.active === false) ||
      (autoAssignsData?.auto_assign === false && item.active === true)
    ) {
      item.active = autoAssignsData?.auto_assign;

      setAssignList([item]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoAssignsData]);

  const openToaster = (status, content) => {
    setToaster({
      open: "open",
      status,
      content,
    });
  };

  const closeToaster = () => {
    setToaster({
      ...toaster,
      open: "closed",
    });
  };

  const filterTable = (type, value) => {
    setQuery({
      ...query,
      [type]: value,
    });
  };

  const activateAutoAssign = (value) => {
    dispatch(
      patchAutoAssignsAction({
        value,
      })
    ).then((res) => {
      if (res?.success === true) {
        dispatch(getAutoAssignsAction());
        setShowActivateModal(false);
        setShowDeactivateModal(false);
        openToaster("success", "Auto assignment update successful!");
      }
    });
  };

  const moreOptions = (item) => {
    var options = [];
    if (officeHoursPermissions?.update || officeHoursPermissions?.modifyAll) {
      options = [
        ...options,
        {
          label: (
            <div>
              {item?.active ? "Deactivate" : "Activate"} auto assignment
            </div>
          ),
          value: item?.active ? "deactivate" : "activate",
        },
      ];
    }

    return options;
  };

  const optionsAction = (type, row) => {
    if (type === "activate") {
      setShowActivateModal(true);
    }
    if (type === "deactivate") {
      setShowDeactivateModal(true);
    }
  };

  return (
    <div className="settings-page-container working-hours-container">
      <div className="page-header">
        <span className="title">Auto Assignment</span>
      </div>
      <div className="settings-page-info">
        <div className="table-container">
          <div className="table-filter">
            <div className="table-search-filter">
              <TextField
                placeholder="Search auto assignments"
                defaultValue={""}
                leftIcon={<Search />}
                onChange={(e) => filterTable("searchQuery", e.target.value)}
                disabled
              />
            </div>
          </div>

          <div className="hours-listing">
            {assignList?.map((row, i) => (
              <div key={i} className="item">
                <div className="left-side">
                  <p>{row?.name}</p>
                  <h6>{row?.description}</h6>
                </div>

                <div className="right-side">
                  <div className={`status ${row?.active ? "active" : ""}`}>
                    <div className="dot"></div>
                    <p>{row?.active ? "active" : ""}</p>
                  </div>
                  <OptionsDropdown
                    onClick={(val) => {
                      optionsAction(val, row);
                    }}
                    options={moreOptions(row)}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {showActivateModal && (
        <Modal closeModal={() => setShowActivateModal(false)} small>
          <div className="modal-content confirmation-modal">
            <div className="modal-title">Activate this auto assignment?</div>
            <div className="modal-text">
              Are you sure you want to activate this auto assignment?
            </div>
            <div className="modal-action">
              <Button
                className="secondary-button"
                type="button"
                disabled={isSubmittingAutoAssigns}
                onClick={() => activateAutoAssign(true)}
              >
                {isSubmittingAutoAssigns ? "Processing..." : "Activate"}
              </Button>
              <Button
                className="plain-button black"
                type="button"
                onClick={() => setShowActivateModal(false)}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      )}

      {showDeactivateModal && (
        <Modal closeModal={() => setShowDeactivateModal(false)} small>
          <div className="modal-content confirmation-modal">
            <div className="modal-title">Deactivate this auto assignment?</div>
            <div className="modal-text">
              Are you sure you want to deactivate this auto assignment?
            </div>
            <div className="modal-action">
              <Button
                className="secondary-button red"
                type="button"
                onClick={() => activateAutoAssign(false)}
                disabled={isSubmittingAutoAssigns}
              >
                {isSubmittingAutoAssigns ? "Processing..." : "Deactivate"}
              </Button>
              <Button
                className="plain-button black"
                type="button"
                onClick={() => setShowDeactivateModal(false)}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      )}

      <Toaster {...toaster} closeToaster={closeToaster} />
    </div>
  );
};

export default AutoAssignment;
