import React, { Component, createRef } from "react";
import * as moment from "moment";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import { AngleDown } from "../../../assets/vectors";

import { getYesterdayToday } from "../../../utils/functions";

class ChatDateFilter extends Component {
  state = {
    dateFilterOptions: false,
  };

  groupContainerRef = createRef();

  openDateFilterOptions = () => {
    this.setState(
      {
        dateFilterOptions: true,
      },
      () => document.addEventListener("click", this.closeDateFilterOptions)
    );
  };

  closeDateFilterOptions = (e) => {
    if (e.target.id !== "custom-date") {
      this.setState(
        {
          dateFilterOptions: false,
        },
        () => document.removeEventListener("click", this.closeDateFilterOptions)
      );
    }
  };

  openCustomDate = () => {
    this.setState(
      {
        isCustomDateActive: true,
        dateFilterOptions: false,
      },
      () => document.addEventListener("click", this.closeCustomDate)
    );
  };

  closeCustomDate = (e) => {
    const customDateEl = document.getElementById("custom-date");

    if (!e || (customDateEl && !customDateEl.contains(e.target))) {
      this.setState(
        {
          isCustomDateActive: false,
        },
        () => document.removeEventListener("click", this.closeCustomDate)
      );
    }
  };

  getGroupContainerTop = () => {
    return this.groupContainerRef
      ? this.groupContainerRef.current
        ? this.groupContainerRef.current.getBoundingClientRect().top
        : 0
      : 0;
  };

  render() {
    const { dateFilterOptions, isCustomDateActive } = this.state;
    const {
      children,
      groupDate,
      formTop,
      id,
      conversation_id,
      goToDate,
      goToFirstConversation,
    } = this.props;

    const topDifference = formTop - this.getGroupContainerTop();

    return (
      <div
        className="grouped-messages-container"
        id={id}
        ref={this.groupContainerRef}
      >
        <div className="grouped-messages-date" data-convoid={conversation_id}>
          <div
            className="grouped-messages-date-text"
            onClick={this.openDateFilterOptions}
          >
            {getYesterdayToday(groupDate)}
            <AngleDown />
            {isCustomDateActive && (
              <div id="custom-date" onClick={(e) => e.stopPropagation()}>
                <DatePicker
                  onChange={(date) => {
                    goToDate(moment(date).format("MMM-DD-YYYY"));
                    this.closeCustomDate();
                  }}
                  inline
                />
              </div>
            )}
            {dateFilterOptions && (
              <div
                className="message-options-menu"
                style={{
                  top: topDifference > 200 ? "50px" : "calc(-100% - 60px)",
                }}
              >
                <span
                  onClick={() =>
                    goToDate(moment().subtract(1, "d").format("MMM-DD-YYYY"))
                  }
                >
                  Yesterday
                </span>
                <span
                  onClick={() =>
                    goToDate(moment().subtract(1, "m").format("MMM-DD-YYYY"))
                  }
                >
                  Last Month
                </span>
                <span
                  onClick={() =>
                    goToDate(moment().subtract(1, "y").format("MMM-DD-YYYY"))
                  }
                >
                  Last Year
                </span>
                <span onClick={goToFirstConversation}>First Conversation</span>
                <span onClick={this.openCustomDate}>Pick a specific date</span>
              </div>
            )}
          </div>
        </div>
        {children}
      </div>
    );
  }
}

export default ChatDateFilter;
