import {
  GET_CSAT_LOADER,
  GET_CSAT_RESPONSE,
  CSAT_RESET,
  CREATE_CSAT_LOADER,
  CREATE_CSAT_RESPONSE,
  UPDATE_CSAT_LOADER,
  UPDATE_CSAT_RESPONSE,
  DELETE_CSAT_LOADER,
  DELETE_CSAT_RESPONSE,
} from "../constants";

const INIT_STATE = {
  csatData: [],
  csatMeta: {
    limit: 10,
    offset: 0,
    total: 0,
  },
  isCreatingCsat: false,
  isUpdatingCsat: false,
  isDeletingCsat: false,
  isLoadingCsat: true,
  createCsatOutcome: "",
  createCsatMessage: "",
  updateCsatOutcome: "",
  updateCsatMessage: "",
  deleteCsatOutcome: "",
  deleteCsatMessage: "",
  csatOutcome: "",
  csatMessage: "",
};

const Csat = (state = INIT_STATE, action) => {
  const {
    type,
    createCsatOutcome,
    createCsatMessage,
    updateCsatOutcome,
    updateCsatMessage,
    deleteCsatOutcome,
    deleteCsatMessage,
    csatOutcome,
    csatMessage,
    csatData,
    csatMeta,
    isCreatingCsat,
    isUpdatingCsat,
    isDeletingCsat,
    isLoadingCsat,
  } = action;

  switch (type) {
    case CSAT_RESET: {
      return {
        ...state,
        createCsatOutcome: "",
        createCsatMessage: "",
        updateCsatOutcome: "",
        updateCsatMessage: "",
        deleteCsatOutcome: "",
        deleteCsatMessage: "",
      };
    }

    case GET_CSAT_LOADER: {
      return {
        ...state,
        isLoadingCsat,
      };
    }

    case GET_CSAT_RESPONSE: {
      return {
        ...state,
        csatOutcome,
        csatData,
        csatMeta,
        csatMessage,
      };
    }

    case CREATE_CSAT_LOADER: {
      return {
        ...state,
        isCreatingCsat,
      };
    }

    case CREATE_CSAT_RESPONSE: {
      return {
        ...state,
        createCsatOutcome,
        createCsatMessage,
      };
    }

    case UPDATE_CSAT_LOADER: {
      return {
        ...state,
        isUpdatingCsat,
      };
    }

    case UPDATE_CSAT_RESPONSE: {
      return {
        ...state,
        updateCsatOutcome,
        updateCsatMessage,
      };
    }

    case DELETE_CSAT_LOADER: {
      return {
        ...state,
        isDeletingCsat,
      };
    }

    case DELETE_CSAT_RESPONSE: {
      return {
        ...state,
        deleteCsatOutcome,
        deleteCsatMessage,
      };
    }

    default:
      return state;
  }
};

export default Csat;
