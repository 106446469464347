import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Redirect, LogoSolid, SMS, Spinner } from "../../../../assets/vectors";
import { Button, TextField } from "../../../../components/FormElements";
import Modal from "../../../../components/Modal";

import {
  postSubscribeLiveChatAction,
  getAllChannelsAction,
  getFirmChannelsAction,
} from "../../../../redux/actions/Channels";

const SmsForm = ({ history, close, dismissRoute }) => {
  const dispatch = useDispatch();
  const { isCreatingChannel } = useSelector((state) => state?.channels);

  const [currentStep, setCurrentStep] = useState(1);
  const [provider, setProvider] = useState(null);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [webhookUrl, setWebhookUrl] = useState("");

  const subscribeToSmsOneWay = () => {
    dispatch(
      postSubscribeLiveChatAction({
        url: "/channels/sms/subscribe",
        name: "SMS",
        requestData: {
          name,
        },
      })
    ).then((res) => {
      if (res?.status === 200) {
        dispatch(getAllChannelsAction());
        dispatch(getFirmChannelsAction());
        setCurrentStep(3);
      }
    });
  };

  const subscribeToSmsTwoWays = () => {
    dispatch(
      postSubscribeLiveChatAction({
        url: "/channels/sms-tw/subscribe",
        name: "SMS",
        requestData: {
          name,
          phone,
        },
      })
    ).then((res) => {
      if (res?.status === 200) {
        setWebhookUrl(res?.data?.data?.credentials?.webhookUrl);
        dispatch(getAllChannelsAction());
        dispatch(getFirmChannelsAction());
        setCurrentStep(3);
      }
    });
  };

  const providers = [
    {
      id: 1,
      image: "https://cdn-icons-png.flaticon.com/512/567/567340.png",
      name: "One-way",
    },
    {
      id: 2,
      image: "https://cdn-icons-png.flaticon.com/512/803/803312.png",
      name: "Two-way",
    },
  ];

  return (
    <React.Fragment>
      {currentStep === 1 && (
        <Modal className="channel-modal">
          <div className="modal_content whatsapp_setup_container step_one">
            <div className="header">
              <div className="logos">
                <LogoSolid />
                <Redirect />
                <SMS />
              </div>
              <h6 className="modal-title">Set up SMS channel</h6>
            </div>

            <div className="content">
              <div className="provider_selection">
                <p>Choose SMS type</p>
                <div className="providers">
                  {providers?.map(({ id, image, name }, i) => (
                    <div
                      key={i}
                      className={`item ${provider === id ? "selected" : ""}`}
                      onClick={() => setProvider(id)}
                    >
                      <img src={image} className="provider_image" alt="" />
                      <h6 className="provider_name">{name}</h6>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="actions">
              <Button className="secondary-button" onClick={close}>
                Cancel
              </Button>
              <Button
                className="primary-button"
                onClick={() => setCurrentStep(2)}
                disabled={!provider}
              >
                Continue
              </Button>
            </div>
          </div>
        </Modal>
      )}

      {currentStep === 2 && provider === 1 && (
        <Modal className="channel-modal">
          <div className="modal-content live-chat-container">
            <div className="modal-title">Set-up your SMS channel</div>
            <div className="modal-form new-conversation-form">
              <h6>You need to start by setting up a OneRoute SMS channel.</h6>
              <div className="form_field">
                <TextField
                  label="Sender Name"
                  type="text"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  disabled={isCreatingChannel}
                />
              </div>

              <div className="actions">
                {!isCreatingChannel ? (
                  <>
                    <Button
                      className="secondary-button"
                      onClick={() => setCurrentStep(1)}
                    >
                      Back
                    </Button>
                    <Button
                      className="primary-button"
                      onClick={subscribeToSmsOneWay}
                      disabled={name?.length < 2 || phone?.length < 4}
                    >
                      Proceed
                    </Button>
                  </>
                ) : (
                  <div className="spinner">
                    <Spinner />
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal>
      )}

      {currentStep === 2 && provider === 2 && (
        <Modal className="channel-modal">
          <div className="modal-content live-chat-container">
            <div className="modal-title">Set-up your SMS channel</div>
            <div className="modal-form new-conversation-form">
              <h6>You need to start by setting up a OneRoute SMS channel.</h6>
              <div className="form_field">
                <TextField
                  label="Sender Name"
                  type="text"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  disabled={isCreatingChannel}
                />
                <br />
                <TextField
                  label="Sender Phone"
                  type="number"
                  name="phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  disabled={isCreatingChannel}
                />
              </div>

              <div className="actions">
                {!isCreatingChannel ? (
                  <>
                    <Button
                      className="secondary-button"
                      onClick={() => setCurrentStep(1)}
                    >
                      Back
                    </Button>
                    <Button
                      className="primary-button"
                      onClick={subscribeToSmsTwoWays}
                      disabled={name?.length < 2 || phone?.length < 4}
                    >
                      Proceed
                    </Button>
                  </>
                ) : (
                  <div className="spinner">
                    <Spinner />
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal>
      )}

      {currentStep === 3 && (
        <Modal className="channel-modal">
          <div className="modal-content new-conversation-container sms_setup_container">
            <div className="modal-title">Channel setup complete</div>
            <div className="modal-form new-conversation-form">
              {provider !== 1 && (
                <div className="copy_div">
                  <p>{webhookUrl}</p>
                  <Button
                    className="tertiary-button"
                    onClick={() => navigator.clipboard.writeText(webhookUrl)}
                  >
                    Copy
                  </Button>
                </div>
              )}

              <div className="d-flex">
                <div className="info">
                  <ul>
                    {provider !== 1 && (
                      <>
                        <li>
                          Copy and paste the Webhook Url above to your provider.
                        </li>
                        <br />
                      </>
                    )}
                    <li>You can now send and receive messages via SMS.</li>
                  </ul>
                </div>
              </div>

              <div className="actions">
                <Button
                  className="primary-button"
                  onClick={() => {
                    setCurrentStep(null);
                    history.push(
                      dismissRoute ? dismissRoute : "/settings/channels"
                    );
                  }}
                >
                  Dismiss
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default withRouter(SmsForm);
