import React from "react";

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      stroke="#757F8A"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M5.995 15.5H1.634a1 1 0 01-.991-1.13l1.238-9.5A1 1 0 012.873 4h.744a1 1 0 01.992.87L5.995 15.5zM9.495 4h3.622a1 1 0 01.991.87l1.24 9.5a.999.999 0 01-.992 1.13h-8.36M3.617 4h4.378"
    ></path>
    <path
      stroke="#757F8A"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M11.995 7V2.5a2 2 0 10-4 0V7M6.385.835a2 2 0 00-.89 1.667V4"
    ></path>
  </svg>
);
