import {
  CREATE_GROUPS_LOADER,
  CREATE_GROUPS_RESPONSE,
  CREATE_GROUPS_RESET,
  UPDATE_GROUPS_LOADER,
  UPDATE_GROUPS_RESPONSE,
  UPDATE_GROUPS_RESET,
  GET_GROUPS_LOADER,
  GET_GROUPS_RESPONSE,
  GET_GROUPS_RESET,
  DELETE_GROUPS_LOADER,
  DELETE_GROUPS_RESPONSE,
  DELETE_GROUPS_RESET,
} from "../constants";

const INIT_STATE = {
  groupsData: [],
  groupsMeta: {
    limit: 10,
    offset: 0,
    total: 0,
  },
  isCreatingGroup: false,
  isUpdatingGroup: false,
  isDeletingGroup: false,
  isLoadingGroups: true,
  createGroupsOutcome: "",
  createGroupsMessage: "",
  updateGroupsOutcome: "",
  updateGroupsMessage: "",
  deleteGroupsOutcome: "",
  deleteGroupsMessage: "",
  groupsOutcome: "",
  groupsMessage: "",
};

const Groups = (state = INIT_STATE, action) => {
  const {
    type,
    createGroupsOutcome,
    createGroupsMessage,
    updateGroupsOutcome,
    updateGroupsMessage,
    deleteGroupsOutcome,
    deleteGroupsMessage,
    groupsOutcome,
    groupsMessage,
    groupsData,
    groupsMeta,
    isCreatingGroup,
    isUpdatingGroup,
    isDeletingGroup,
    isLoadingGroups,
  } = action;

  switch (type) {
    case CREATE_GROUPS_LOADER: {
      return {
        ...state,
        isCreatingGroup,
      };
    }

    case CREATE_GROUPS_RESPONSE: {
      return {
        ...state,
        createGroupsOutcome,
        createGroupsMessage,
      };
    }

    case CREATE_GROUPS_RESET: {
      return {
        ...state,
        createGroupsOutcome: "",
        createGroupsMessage: "",
      };
    }

    case UPDATE_GROUPS_LOADER: {
      return {
        ...state,
        isUpdatingGroup,
      };
    }

    case UPDATE_GROUPS_RESPONSE: {
      return {
        ...state,
        updateGroupsOutcome,
        updateGroupsMessage,
      };
    }

    case UPDATE_GROUPS_RESET: {
      return {
        ...state,
        updateGroupsOutcome: "",
        updateGroupsMessage: "",
      };
    }

    case DELETE_GROUPS_LOADER: {
      return {
        ...state,
        isDeletingGroup,
      };
    }

    case DELETE_GROUPS_RESPONSE: {
      return {
        ...state,
        deleteGroupsOutcome,
        deleteGroupsMessage,
      };
    }

    case DELETE_GROUPS_RESET: {
      return {
        ...state,
        deleteGroupsOutcome: "",
        deleteGroupsMessage: "",
      };
    }

    case GET_GROUPS_LOADER: {
      return {
        ...state,
        isLoadingGroups,
      };
    }

    case GET_GROUPS_RESPONSE: {
      return {
        ...state,
        groupsOutcome,
        groupsData,
        groupsMeta,
        groupsMessage,
      };
    }

    case GET_GROUPS_RESET: {
      return INIT_STATE;
    }

    default:
      return state;
  }
};

export default Groups;
