import React from "react";
import { useHistory } from "react-router-dom";

import { ConversationHistory } from "../../Conversations/ChatContainer";

const Conversations = ({
  conversations,
  closeConversation,
  isClosingConversation,
  getCustomerDetails,
  customerId,
  allAgents,
  allUsers,
}) => {
  const history = useHistory();

  const openConversation = (customer_id, channel_id) => {
    history.push(`/conversations/${customer_id}/${channel_id}`);
  };

  return (
    <div>
      <span className="insights-section-title">Conversations</span>
      <div className="insights-card resolution-time-container">
        <ConversationHistory
          closeConversation={closeConversation}
          isClosingConversation={isClosingConversation}
          getCustomerDetails={getCustomerDetails}
          customerId={customerId}
          allAgents={allAgents}
          allUsers={allUsers}
          openConversation={openConversation}
        />
      </div>
    </div>
  );
};

export default Conversations;
