import mixpanel from "mixpanel-browser";
import {
  getRequest,
  postRequest,
  patchRequest,
  deleteRequest,
} from "../../api";

import {
  GET_MENTIONS_LOADER,
  GET_MORE_MENTIONS_LOADER,
  GET_MENTIONS_RESPONSE,
  GET_MENTION_LOADER,
  SET_MENTION_REPLY_SENDING_STATUS,
  CREATE_MENTIONS_UNREAD_COUNT,
  GET_MENTIONS_UNREAD_COUNT,
} from "../constants";
import { getRequestError } from "../../utils/functions";

export const getMentionsLoader = (isLoadingMentions) => ({
  type: GET_MENTIONS_LOADER,
  isLoadingMentions,
});

export const getMoreMentionsLoader = (isLoadingMoreMentions) => ({
  type: GET_MORE_MENTIONS_LOADER,
  isLoadingMoreMentions,
});

export const getMentionsResponse = (
  mentionsOutcome,
  mentionsData,
  mentionsMessage,
  mentionSection,
  paginate
) => ({
  type: GET_MENTIONS_RESPONSE,
  mentionsOutcome,
  mentionsData,
  mentionsMessage,
  mentionSection,
  paginate,
});

export const getMentionLoader = (isLoadingMention) => ({
  type: GET_MENTION_LOADER,
  isLoadingMention,
});

export const createMentionsUnreadCount = (mentionsUnreadCount) => ({
  type: CREATE_MENTIONS_UNREAD_COUNT,
  mentionsUnreadCount,
});

export const getMentionsUnreadCount = () => ({
  type: GET_MENTIONS_UNREAD_COUNT,
});

export const setMentionReplySendingStatus = (
  mentionReplySendingStatus,
  mentionReplySendingResponse
) => ({
  type: SET_MENTION_REPLY_SENDING_STATUS,
  mentionReplySendingStatus,
  mentionReplySendingResponse,
});

export const getMentionsAction = (
  params = {},
  mentionSection,
  noReset,
  paginate
) => {
  return async (dispatch) => {
    if (!noReset) {
      dispatch(getMentionsLoader(true));
    }

    if (paginate) {
      dispatch(getMoreMentionsLoader(true));
    }

    try {
      const res = await getRequest({
        url: "/mention/all",
        params,
        token: true,
      });

      dispatch(
        getMentionsResponse("success", res?.data, "", mentionSection, paginate)
      );

      dispatch(getMentionsLoader(false));
      dispatch(getMoreMentionsLoader(false));
    } catch (error) {
      const message = getRequestError(error);

      dispatch(
        getMentionsResponse("error", [], message, mentionSection, paginate)
      );
      dispatch(getMentionsLoader(false));
      dispatch(getMoreMentionsLoader(false));
    }
  };
};

export const getSingleMentionAction = ({ id, load }) => {
  return async (dispatch) => {
    load && dispatch(getMentionLoader(true));

    try {
      const res = await getRequest({
        url: `/mention/fetch/${id}`,
        token: true,
      });

      load && dispatch(getMentionLoader(false));
      return res?.data?.data;
    } catch (error) {
      load && dispatch(getMentionLoader(false));

      const message = getRequestError(error);
      console.log(message, "message");
    }
  };
};

export const getMentionRepliesAction = ({ id, load }) => {
  return async (dispatch) => {
    load && dispatch(getMentionLoader(true));

    try {
      const res = await getRequest({
        url: `/mention/replies/${id}`,
        token: true,
      });

      load && dispatch(getMentionLoader(false));
      return res?.data?.data;
    } catch (error) {
      load && dispatch(getMentionLoader(false));

      const message = getRequestError(error);
      console.log(message, "message");
    }
  };
};

export const postSendReplyAction = (data = {}) => {
  return async (dispatch) => {
    dispatch(setMentionReplySendingStatus("sending"));

    try {
      const res = await postRequest({
        url: `/mention/reply/${data?.id}`,
        token: true,
        data: data?.payload,
      });
      if (res?.data?.success === true) {
        dispatch(
          setMentionReplySendingStatus(
            res?.data?.success === true && null,
            null
          )
        );
        mixpanel.track("Responded to a Mention", {
          channel: data?.channel,
        });

        return res?.data;
      }
    } catch (error) {
      const message = getRequestError(error);
      dispatch(setMentionReplySendingStatus("failed", message));
    }
  };
};

export const patchMarkMentionAsRead = (data = {}) => {
  return async () => {
    try {
      await patchRequest({
        url: `/mention/${data?.id}`,
        token: true,
        data: { read: true },
      });
    } catch (error) {
      const message = getRequestError(error);
      console.log(message, "error message");
    }
  };
};

export const patchAssignMentionsToAgent = (data = {}) => {
  return async (dispatch) => {
    dispatch(getMentionsLoader(true));
    dispatch(getMentionLoader(true));

    try {
      const res = await patchRequest({
        url: "/mention/bulk-assign",
        data,
        token: true,
      });
      dispatch(getMentionsLoader(false));
      dispatch(getMentionLoader(false));

      return res;
    } catch (error) {
      dispatch(getMentionsLoader(false));
      dispatch(getMentionLoader(false));

      const message = getRequestError(error);
      console.log(message, "error message");
    }
  };
};

export const patchReturnMentionToQueueAction = (id) => {
  return async (dispatch) => {
    dispatch(getMentionsLoader(true));
    dispatch(getMentionLoader(true));

    try {
      await patchRequest({
        url: `/mention/${id}`,
        token: true,
        data: { status: "in-queue" },
      });

      dispatch(getMentionsLoader(false));
      dispatch(getMentionLoader(false));
    } catch (error) {
      dispatch(getMentionsLoader(false));
      dispatch(getMentionLoader(false));
      const message = getRequestError(error);
      console.log(message, "error message");
    }
  };
};

export const deleteMentionAction = (id) => {
  return async (dispatch) => {
    dispatch(getMentionsLoader(true));
    dispatch(getMentionLoader(true));

    try {
      const res = await deleteRequest({
        url: `/mention/${id}`,
        token: true,
      });
      dispatch(getMentionsLoader(false));
      dispatch(getMentionLoader(false));

      return res;
    } catch (error) {
      dispatch(getMentionsLoader(false));
      dispatch(getMentionLoader(false));
      const message = getRequestError(error);
      console.log(message, "error message");
    }
  };
};
