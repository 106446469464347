import React from "react";

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      stroke="#562CCA"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.333"
      d="M4.667 11.333l6.667-6.667M4.667 4.667h6.667v6.666"
    ></path>
  </svg>
);
