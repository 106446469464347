import React from "react";
import { LogoSolid } from "../../../assets/vectors";
import {
  determineColorFromBg,
  getChannelIdentifierIcon,
} from "../../../utils/functions";

const WidgetPreview = ({ formData }) => {
  const { color, logo, headText, subText, toolTip, channels } = formData;

  return (
    <div className="omnichannel-widget">
      <div className="omnichannel-widget-container">
        <div
          className="omnichannel-widget-header"
          style={{ background: color, color: determineColorFromBg(color) }}
        >
          <img className="omnichannel-widget-header__logo" src={logo} alt="" />
          <div className="omnichannel-widget-header__title">{headText}</div>
          <div className="omnichannel-widget-header__message">{subText}</div>
        </div>
        <div className="omnichannel-widget-content">
          {channels?.map(({ name }, index) => (
            <div
              key={`widget-item-${index}`}
              className="omnichannel-widget-content__item"
            >
              <div className="omnichannel-widget-content__item-icon">
                <img src={getChannelIdentifierIcon(name)} alt="" />
              </div>
              <div className="omnichannel-widget-content__item-name">
                {name}
              </div>
            </div>
          ))}
        </div>
        <div className="omnichannel-widget-copyright">
          <span className="omnichannel-widget-copyright__logo">
            <LogoSolid />
          </span>
          Powered by {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a href="#">OneRoute</a>
        </div>
      </div>
      <span className="omnichannel-widget-border-caret omnichannel-widget-border-caret__left"></span>

      <div className="omnichannel-widget-toggler">
        <div className="omnichannel-widget-toggler__message">
          {toolTip}
          <span className="omnichannel-widget-border-caret omnichannel-widget-border-caret__right"></span>
        </div>
        <div
          className="omnichannel-widget-toggler__button"
          style={{ background: color }}
        >
          <img
            src={require("../../../assets/svgs/close-white.svg").default}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default WidgetPreview;
