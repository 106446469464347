import React, { Component, createRef } from "react";
import classNames from "classnames";

import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";

import { FileInput } from ".";

import { Emoji, Gif, Images, Attachment } from "../../assets/vectors";

class TextArea extends Component {
  state = {
    active: false,
    isValid: false,
    isBlurred: false,
  };

  textFieldRef = createRef();

  onChange = ({ target }, isBlurred) => {
    let { isValid } = this.state;
    const { required, showEmoji, name } = this.props;
    const { value } = target;

    isValid = required ? value !== "" : true;

    this.setState({
      isValid,
      isBlurred,
      isFocused: showEmoji ? true : !isBlurred,
    });

    this.props.onChange({ target: { value, name } }, isValid);
  };

  onFocus = () => {
    this.setState({
      isFocused: true,
    });
  };

  openEmojiBox = () => {
    this.setState(
      {
        isEmojiActive: true,
        isFocused: true,
      },
      () => {
        document.addEventListener("click", this.closeEmojiBox);
      }
    );
  };

  closeEmojiBox = (e) => {
    if (
      this.emojiSelectRef !== null &&
      !this.emojiSelectRef.contains(e.target)
    ) {
      this.setState(
        {
          isEmojiActive: false,
        },
        () => {
          document.removeEventListener("click", this.closeEmojiBox);
        }
      );
    }
  };

  addEmoji = ({ native }) => {
    const { onChange, name } = this.props;

    let value = this.textFieldRef.current.value;
    value = `${value}${native}`;

    this.textFieldRef.current.value = value;
    onChange({ target: { value, name } }, true);

    this.setState(
      {
        isEmojiActive: false,
      },
      () => {
        document.removeEventListener("click", this.closeEmojiBox);
      }
    );

    this.textFieldRef.current.focus();
  };

  render() {
    const { isValid, isBlurred, isFocused, isEmojiActive } = this.state;
    const {
      required,
      className,
      label,
      onChange,
      expand,
      showEmoji,
      hideGif,
      hideImageUpload,
      hideAttach,
      attachedButton,
      onFileLoad,
      onFileError,
      noAction,
      ...rest
    } = this.props;

    return (
      <div
        className={classNames(`input-container ${className || ""}`, {
          isFocused,
          error: !isValid && isBlurred,
          expand,
          attachedButton,
          showEmoji,
        })}
      >
        {label && <label>{label}</label>}
        <div>
          <textarea
            ref={this.textFieldRef}
            className="input textarea"
            {...rest}
            onBlur={(e) => this.onChange(e, true)}
            onChange={this.onChange}
            onFocus={this.onFocus}
          ></textarea>
          {!noAction && (
            <div className="textarea-actions">
              <div>
                <div className="icon" title="Emojis">
                  {isEmojiActive ? (
                    <div
                      className="emoji-container"
                      ref={(el) => (this.emojiSelectRef = el)}
                    >
                      <Picker onSelect={this.addEmoji} emojiTooltip={true} />
                    </div>
                  ) : (
                    <div className="emoji-select" onClick={this.openEmojiBox}>
                      <Emoji />
                    </div>
                  )}
                </div>
                {!hideGif && (
                  <span className="icon" title="Add a GIF">
                    <Gif />
                  </span>
                )}
                {!hideImageUpload && (
                  <span className="icon" title="Add an image">
                    <FileInput
                      icon={<Images />}
                      onFileLoad={onFileLoad}
                      onFileError={onFileError}
                    />
                  </span>
                )}
                {!hideAttach && (
                  <span className="icon" title="Add an attachment">
                    <FileInput
                      icon={<Attachment />}
                      onFileLoad={onFileLoad}
                      onFileError={onFileError}
                    />
                  </span>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default TextArea;
