import React from "react";

export default () => (
  <svg
    width="14"
    height="10"
    viewBox="0 0 14 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.91671 3.36634L12.9576 1.23717C13.0014 1.20652 13.0527 1.18846 13.1059 1.18497C13.1592 1.18147 13.2124 1.19267 13.2598 1.21734C13.3072 1.24201 13.3468 1.27921 13.3745 1.32488C13.4022 1.37055 13.4168 1.42294 13.4167 1.47634V8.52301C13.4168 8.57641 13.4022 8.6288 13.3745 8.67447C13.3468 8.72014 13.3072 8.75734 13.2598 8.78201C13.2124 8.80668 13.1592 8.81788 13.1059 8.81438C13.0527 8.81089 13.0014 8.79283 12.9576 8.76218L9.91671 6.63301V9.08301C9.91671 9.23772 9.85525 9.38609 9.74585 9.49549C9.63646 9.60488 9.48808 9.66634 9.33337 9.66634H1.16671C1.012 9.66634 0.863625 9.60488 0.754228 9.49549C0.644832 9.38609 0.583374 9.23772 0.583374 9.08301V0.916341C0.583374 0.761632 0.644832 0.613259 0.754228 0.503862C0.863625 0.394466 1.012 0.333008 1.16671 0.333008H9.33337C9.48808 0.333008 9.63646 0.394466 9.74585 0.503862C9.85525 0.613259 9.91671 0.761632 9.91671 0.916341V3.36634ZM2.91671 2.66634V3.83301H4.08337V2.66634H2.91671Z"
      fill="#757F8A"
    />
  </svg>
);
