import React, { useState } from "react";
import { decode } from "html-entities";
import * as moment from "moment";

import AudioPlayer from "../../../components/AudioPlayer";

import { File } from "../../../assets/vectors";

import { capitalize } from "../../../utils/functions";

const EmailMessage = ({
  attachments,
  openFilePreview,
  customer,
  sender,
  subject,
  content,
  createdAt,
}) => {
  const [showCc, setShowCc] = useState(false);
  const [showBcc, setShowBcc] = useState(false);

  const { name, authUser, cc, bcc } = sender || {};

  const ccIsAnArray = Array.isArray(cc);
  const bccIsAnArray = Array.isArray(bcc);

  var ccConvertedArray = null;
  if (ccIsAnArray) {
    ccConvertedArray = cc;
  } else if (cc?.includes("[")) {
    const arr = JSON.parse(cc);
    ccConvertedArray = arr;
  }

  var bccConvertedArray = null;
  if (bccIsAnArray) {
    bccConvertedArray = bcc;
  } else if (bcc && cc?.includes("[")) {
    const arr = JSON.parse(bcc);
    bccConvertedArray = arr;
  }

  return (
    <div className="email-message-content">
      <div className="header">
        <div className="sender-info">
          <h6>
            {capitalize(name)}{" "}
            <span>{moment(createdAt).format("ddd, D MMM | HH:mm")}</span>
          </h6>
          <p>
            {authUser ? "To: " : "From: "}
            <span>{customer?.email}</span>
          </p>
          {ccConvertedArray?.length > 0 ? (
            <p
              title="Click to expand"
              className="cc_preview"
              onClick={() => setShowCc(!showCc)}
            >
              Cc:{" "}
              <span>
                ({ccConvertedArray?.length}){" - "}
              </span>
              {ccConvertedArray?.map((item, i) => (
                <span key={i} className="cc">
                  {item},{" "}
                </span>
              ))}
            </p>
          ) : (
            ""
          )}
          {showCc && (
            <div className="all_ccs">
              {ccConvertedArray?.map((item, i) => (
                <span key={i} className="cc">
                  {item},{" "}
                </span>
              ))}
            </div>
          )}
          {bccConvertedArray?.length > 0 ? (
            <p
              title="Click to expand"
              className="cc_preview"
              onClick={() => setShowBcc(!showBcc)}
            >
              Bcc:{" "}
              <span>
                ({bccConvertedArray?.length}){" - "}
              </span>
              {bccConvertedArray?.map((item, i) => (
                <span key={i} className="cc">
                  {item},{" "}
                </span>
              ))}
            </p>
          ) : (
            ""
          )}
          {showBcc && (
            <div className="all_ccs">
              {bccConvertedArray?.map((item, i) => (
                <span key={i} className="cc">
                  {item},{" "}
                </span>
              ))}
            </div>
          )}
          <p>
            Subject: <span>{subject}</span>
          </p>
        </div>
      </div>

      <div className="body">
        <div
          dangerouslySetInnerHTML={{
            __html: decode(content, { level: "html5" }),
          }}
        ></div>
        {attachments?.length > 0 ? (
          <div className="attachments">
            {attachments?.map((item, i) => (
              <React.Fragment key={i}>
                {item?.contentType === "IMAGE" && (
                  <span
                    className="message-media"
                    onClick={() =>
                      openFilePreview(item?.contentType, item?.fileUrl)
                    }
                  >
                    <img src={item?.fileUrl} alt="" />
                  </span>
                )}
                {item?.contentType === "VIDEO" && (
                  <span
                    className="message-media"
                    onClick={() =>
                      openFilePreview(item?.contentType, item?.fileUrl)
                    }
                  >
                    <video controls={false}>
                      <source src={item?.fileUrl}></source>
                    </video>
                  </span>
                )}
                {item?.contentType === "DOCUMENT" && (
                  <div className="message-file">
                    <span className="icon">
                      <File />
                    </span>
                    <a
                      href={item?.fileUrl || "#"}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item?.fileUrl ? item.name : ""}
                    </a>
                  </div>
                )}
                {(item?.contentType === "VOICE" ||
                  item?.contentType === "AUDIO") && (
                  <div className="message-audio">
                    <AudioPlayer src={item?.fileUrl} />
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default EmailMessage;
