import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import * as moment from "moment";
import Papa from "papaparse";

import Modal from "../../../components/Modal";
import { Button } from "../../../components/FormElements";

import { getOrderInfoAction } from "../../../redux/actions/Conversations";

import { CircularClose } from "../../../assets/vectors";
import { formatNumber } from "../../../utils/functions";

const OrderDetails = ({ close, customer, msgId }) => {
  const dispatch = useDispatch();

  // const [formData, setFormData] = useState({});
  const [orderInfo, setOrderInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    dispatch(
      getOrderInfoAction({
        url: `/order/${msgId}/details`,
      })
    ).then((res) => {
      setIsLoading(false);
      setOrderInfo(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const handleChange = (e) => {
  //   const { name, value } = e.target;

  //   setFormData({
  //     ...formData,
  //     [name]: value,
  //   });
  // };

  // const countOptions = [
  //   { label: "1", value: "1" },
  //   { label: "2", value: "2" },
  //   { label: "3", value: "3" },
  //   { label: "4", value: "4" },
  //   { label: "5", value: "5" },
  //   { label: "10", value: "10" },
  //   { label: "12", value: "12" },
  //   { label: "16", value: "16" },
  //   { label: "18", value: "18" },
  //   { label: "20", value: "20" },
  // ];

  const productItems = orderInfo?.product_items || [];

  const getSubTotal = () => {
    let array = productItems;
    let sum = 0;

    array.map((x) => (sum += x?.item_price * x?.quantity));

    return sum;
  };

  const exportJsonToCsv = () => {
    const dataToExport = productItems?.map((item) => ({
      product_retailer_id: item?.product_retailer_id,
      name: item?.name,
      quantity: item?.quantity,
      price: item?.item_price,
      currency: item?.currency,
    }));

    const data = Papa.unparse(dataToExport);
    let csvContent = "data:text/csv;charset=utf-8," + data;

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute(
      "download",
      `Order_${customer}_${moment().format("YYYY-MM-DD")}.csv`
    );
    document.body.appendChild(link);

    link.click();
    // window.open(encodedUri);
  };

  return (
    <Modal halfScreen>
      <div className="modal-content order-details-container">
        <div className="top">
          <div className="modal-title">
            <p>Received cart</p>

            <span onClick={close}>
              <CircularClose />
            </span>
          </div>

          {isLoading ? (
            <p className="loading">Loading... Please wait</p>
          ) : (
            <>
              <div className="selected">
                <p>{productItems?.length} items selected</p>

                {/* <button className="secondary-button">Edit cart</button> */}
              </div>

              <div className="details-headers">
                <p>Image</p>
                <p>Name</p>
                <p className="center">Qty</p>
                <p className="end">Price</p>
              </div>

              <div className="details-container">
                {productItems?.map((item, i) => (
                  <div key={i} className="item">
                    <img src={item?.image_url} alt="" />
                    <p className="bold">{item?.name}</p>
                    <p className="quantity">{item?.quantity}</p>
                    <p className="price">
                      {item?.currency}{" "}
                      {formatNumber(item?.item_price * item?.quantity, 2)}
                    </p>

                    {/* <Select
                      name="quantity"
                      options={countOptions}
                      fixedPlaceholder={item?.quantity}
                      value={formData.count}
                      onChange={(e) => handleChange(e)}
                    /> */}
                  </div>
                ))}

                <div className="sub_total">
                  <p>Sub-total</p>

                  <p>
                    {productItems?.[0]?.currency}{" "}
                    {formatNumber(getSubTotal(), 2)}
                  </p>
                </div>
              </div>
            </>
          )}
        </div>

        <div className="modal-form-action">
          <Button
            type="button"
            className="secondary-button"
            onClick={() => close()}
          >
            Close
          </Button>

          <Button
            type="button"
            className="primary-button"
            onClick={() => exportJsonToCsv()}
          >
            Export
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default OrderDetails;
