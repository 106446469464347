import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Toaster from "../../../components/Toaster";

import { Button, TextField } from "../../../components/FormElements";
import WorkingHoursForm from "./WorkingHoursForm";
import Modal from "../../../components/Modal";
import OptionsDropdown from "../../../components/OptionsDropdown";

import {
  getWorkingHoursAction,
  patchWorkingHoursAction,
  deleteWorkingHoursAction,
} from "../../../redux/actions/WorkingHours";
import { getFirmChannelsAction } from "../../../redux/actions/Channels";

import { officeHoursPermissions } from "../../../utils/permissions";

import { CircularPlus, Search, Trash } from "../../../assets/vectors";

const WorkingHours = () => {
  const dispatch = useDispatch();
  const { isLoadingWorkingHours, isSubmittingWorkingHours, workingHoursData } =
    useSelector((state) => state.workingHours);

  const [selectedRow, setSelectedRow] = useState(null);
  const [showWorkingHourModal, setShowWorkingHourModal] = useState(false);
  const [showActivateModal, setShowActivateModal] = useState(false);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [toaster, setToaster] = useState({});
  const [query, setQuery] = useState({});

  useEffect(() => {
    dispatch(getWorkingHoursAction());
    dispatch(getFirmChannelsAction());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openToaster = (status, content) => {
    setToaster({
      open: "open",
      status,
      content,
    });
  };

  const closeToaster = () => {
    setToaster({
      ...toaster,
      open: "closed",
    });
  };

  const filterTable = (type, value) => {
    setQuery({
      ...query,
      [type]: value,
    });
  };

  const activateWorkingHour = (value) => {
    dispatch(
      patchWorkingHoursAction({
        id: selectedRow?.id,
        data: { ...selectedRow, active: value },
      })
    ).then((res) => {
      if (res?.success === true) {
        dispatch(getWorkingHoursAction());
        setSelectedRow(null);
        setShowActivateModal(false);
        setShowDeactivateModal(false);
        openToaster("success", "Working hour update successful!");
      }
    });
  };

  const deleteWorkingHour = () => {
    dispatch(
      deleteWorkingHoursAction({
        id: selectedRow?.id,
      })
    ).then((res) => {
      if (res?.success === true) {
        dispatch(getWorkingHoursAction());
        setSelectedRow(null);
        setShowDeleteModal(false);
        openToaster("success", "Working hour deleted successfully!");
      }
    });
  };

  const moreOptions = (item) => {
    var options = [];

    if (officeHoursPermissions?.update || officeHoursPermissions?.modifyAll) {
      options = [
        ...options,
        {
          label: (
            <div>{item?.active ? "Deactivate" : "Activate"} working hour</div>
          ),
          value: item?.active ? "deactivate" : "activate",
        },
        {
          label: <div>Edit working hour</div>,
          value: "edit",
        },
      ];
    }

    if (officeHoursPermissions?.delete) {
      options = [
        ...options,
        {
          label: <div className="red">Delete working hour</div>,
          value: "delete",
        },
      ];
    }

    return options;
  };

  const optionsAction = (type, row) => {
    setSelectedRow(row);

    if (type === "activate") {
      setShowActivateModal(true);
    }
    if (type === "deactivate") {
      setShowDeactivateModal(true);
    }
    if (type === "edit") {
      setShowWorkingHourModal(true);
    }
    if (type === "delete") {
      setShowDeleteModal(true);
    }
  };

  const convertHoursObjToArray = (obj) => {
    let result =
      Object.entries(obj)?.map((e) => ({
        day: e[0],
        ...e[1],
      })) || [];

    const sorter = {
      sunday: 0,
      monday: 1,
      tuesday: 2,
      wednesday: 3,
      thursday: 4,
      friday: 5,
      saturday: 6,
    };

    result.sort(function sortByDay(a, b) {
      let day1 = a.day.toLowerCase();
      let day2 = b.day.toLowerCase();
      return sorter[day1] - sorter[day2];
    });

    return result;
  };

  return (
    <div className="settings-page-container working-hours-container">
      <div className="page-header">
        <span className="title">Working Hours</span>
      </div>
      <div className="settings-page-info">
        {!isLoadingWorkingHours && workingHoursData?.length > 0 ? (
          <div className="table-container">
            <div className="table-filter">
              <div className="table-search-filter">
                <TextField
                  placeholder="Search working hours"
                  defaultValue={""}
                  leftIcon={<Search />}
                  onChange={(e) => filterTable("searchQuery", e.target.value)}
                  disabled
                />
              </div>
              <div className="table-other-filters">
                {(officeHoursPermissions.create ||
                  officeHoursPermissions.modifyAll) && (
                  <Button
                    className="primary-button"
                    onClick={() => {
                      setSelectedRow(null);
                      setShowWorkingHourModal(true);
                    }}
                  >
                    <span className="icon">
                      <CircularPlus />
                    </span>
                    <span>Add working hour</span>
                  </Button>
                )}
              </div>
            </div>

            <div className="hours-listing">
              {workingHoursData?.map((row, i) => (
                <div key={i} className="item">
                  <div className="left-side">
                    <p>{row?.name}</p>
                    <h6>
                      {convertHoursObjToArray(row?.hours)?.map(
                        ({ day, from, to }, i) => (
                          <React.Fragment key={i}>
                            {day} {from} - {to},{" "}
                          </React.Fragment>
                        )
                      )}
                    </h6>
                  </div>

                  <div className="right-side">
                    <div className={`status ${row?.active ? "active" : ""}`}>
                      <div className="dot"></div>
                      <p>{row?.active ? "active" : ""}</p>
                    </div>
                    <OptionsDropdown
                      onClick={(val) => {
                        optionsAction(val, row);
                      }}
                      options={moreOptions()}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : isLoadingWorkingHours ? (
          <div className="no-record">
            <p>Loading...</p>
          </div>
        ) : (
          <div className="no-record">
            <h6>You have not set your working hours yet.</h6>
            <p>When you add an working hour, it’ll show up here.</p>
            {(officeHoursPermissions.create ||
              officeHoursPermissions.modifyAll) && (
              <Button
                className="primary-button"
                onClick={() => setShowWorkingHourModal(true)}
              >
                <span className="icon">
                  <CircularPlus />
                </span>
                <span>Add working hour</span>
              </Button>
            )}
          </div>
        )}
      </div>

      {showWorkingHourModal && (
        <WorkingHoursForm
          closeModal={() => setShowWorkingHourModal(false)}
          convertHoursObjToArray={convertHoursObjToArray}
          workingHourData={selectedRow}
          openToaster={openToaster}
        />
      )}

      {showActivateModal && (
        <Modal closeModal={() => setShowActivateModal(false)} small>
          <div className="modal-content confirmation-modal">
            <div className="modal-title">Activate this working hour?</div>
            <div className="modal-text">
              Activating the "{selectedRow?.name}" working hour will deactivate
              the current active working hour.
            </div>
            <div className="modal-action">
              <Button
                className="secondary-button"
                type="button"
                disabled={isSubmittingWorkingHours}
                onClick={() => activateWorkingHour(true)}
              >
                {isSubmittingWorkingHours ? "Processing..." : "Activate"}
              </Button>
              <Button
                className="plain-button black"
                type="button"
                onClick={() => setShowActivateModal(false)}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      )}

      {showDeactivateModal && (
        <Modal closeModal={() => setShowDeactivateModal(false)} small>
          <div className="modal-content confirmation-modal">
            <div className="modal-title">Deactivate this working hour?</div>
            <div className="modal-text">
              Are you sure you want to deactivate the "{selectedRow?.name}"
              working hour?
            </div>
            <div className="modal-action">
              <Button
                className="secondary-button red"
                type="button"
                onClick={() => activateWorkingHour(false)}
                disabled={isSubmittingWorkingHours}
              >
                {isSubmittingWorkingHours ? "Processing..." : "Deactivate"}
              </Button>
              <Button
                className="plain-button black"
                type="button"
                onClick={() => setShowDeactivateModal(false)}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      )}

      {showDeleteModal && (
        <Modal closeModal={() => setShowDeleteModal(false)} small>
          <div className="modal-content confirmation-modal">
            <div className="modal-icon red">
              <Trash />
            </div>
            <div className="modal-title">
              Are you sure you want to delete this working hour?
            </div>
            <div className="modal-text">This action cannot be reversed</div>
            <div className="modal-action">
              <Button
                className="secondary-button red"
                type="button"
                disabled={isSubmittingWorkingHours}
                onClick={deleteWorkingHour}
              >
                {isSubmittingWorkingHours
                  ? "Processing..."
                  : "Delete working hour"}
              </Button>
              <Button
                className="plain-button black"
                type="button"
                onClick={() => setShowDeleteModal(false)}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      )}

      <Toaster {...toaster} closeToaster={closeToaster} />
    </div>
  );
};

export default WorkingHours;
