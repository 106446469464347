import React from "react";

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <g
      stroke="#C6C1D0"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.25"
      clipPath="url(#homeclip0)"
    >
      <path d="M3.128 11.602v6.875h5v-5a1.25 1.25 0 011.25-1.25h1.25a1.25 1.25 0 011.25 1.25v5h5v-6.875M.628 10.352l8.492-8.49a1.25 1.25 0 011.767 0l8.49 8.49"></path>
      <path d="M13.753 4.728v-1.25h3.125v4.375"></path>
    </g>
    <defs>
      <clipPath id="homeclip0">
        <path fill="#fff" d="M0 0H20V20H0z"></path>
      </clipPath>
    </defs>
  </svg>
);
