import React, { Component, createRef } from "react";
import classNames from "classnames";
import { ChromePicker } from "react-color";

class ColorPicker extends Component {
  state = {
    active: false,
    isValid: false,
    isBlurred: false,
    displayColorPicker: false,
  };

  textFieldRef = createRef();

  onChange = ({ target }, isBlurred) => {
    let { isValid } = this.state;
    const { required, name, onBlur, onChange } = this.props;
    const { value } = target;

    isValid = required ? value !== "" : true;

    this.setState({
      isValid,
      isBlurred,
      isFocused: !isBlurred,
    });

    onChange({ target: { name, value } }, isValid);

    isBlurred && onBlur && onBlur();
  };

  onFocus = () => {
    const { onFocus } = this.props;

    this.setState(
      {
        isFocused: true,
      },
      () => onFocus && onFocus()
    );
  };

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleChange = (color) => {
    this.setState({ color: color.rgb });
  };

  render() {
    const { isValid, isBlurred, isFocused, displayColorPicker } = this.state;
    const {
      required,
      className,
      label,
      onChange,
      onFocus,
      onBlur,
      hint,
      name,
      value,
      ...rest
    } = this.props;

    const popover = {
      position: "absolute",
      zIndex: "2",
      width: "100%",
      top: "calc(100% + 5px)",
    };
    const cover = {
      position: "fixed",
      top: "0px",
      right: "0px",
      bottom: "0px",
      left: "0px",
    };

    return (
      <div
        className={classNames(
          `input-container color-picker-container ${className || ""}`,
          {
            isFocused,
            error: !isValid && isBlurred,
          }
        )}
      >
        {label && <label>{label}</label>}
        <div
          className="input-icon-container rightIcon"
          onClick={(e) => e.stopPropagation()}
        >
          <input
            ref={this.textFieldRef}
            className="input"
            {...rest}
            onBlur={(e) => {
              this.onChange(e, true);
            }}
            onChange={this.onChange}
            onFocus={(e) => {
              this.handleClick();
              this.onFocus(e);
            }}
            name={name}
            value={value}
          />
          <span
            className="input-icon color-picker-preview"
            style={{ background: `${value || "transparent"}` }}
          ></span>
        </div>
        {hint && <span className="input-hint">{hint}</span>}
        {displayColorPicker && (
          <div style={popover}>
            <div style={cover} onClick={this.handleClose} />
            <ChromePicker
              color={value}
              onChange={(e) =>
                this.onChange({ target: { value: e.hex, name } })
              }
            />
          </div>
        )}
      </div>
    );
  }
}

export default ColorPicker;
