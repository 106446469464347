import {
  getRequest,
  postRequest,
  patchRequest,
  deleteRequest,
} from "../../api";

import {
  CREATE_TAGS_LOADER,
  CREATE_TAGS_RESPONSE,
  CREATE_TAGS_RESET,
  UPDATE_TAGS_LOADER,
  UPDATE_TAGS_RESPONSE,
  UPDATE_TAGS_RESET,
  GET_TAGS_LOADER,
  GET_TAGS_RESPONSE,
  GET_TAGS_RESET,
  DELETE_TAGS_LOADER,
  DELETE_TAGS_RESPONSE,
  DELETE_TAGS_RESET,
} from "../constants";

import { getRequestError } from "../../utils/functions";

export const createTagsLoader = (isCreatingTag) => ({
  type: CREATE_TAGS_LOADER,
  isCreatingTag,
});

export const createTagsResponse = (createTagsOutcome, createTagsMessage) => ({
  type: CREATE_TAGS_RESPONSE,
  createTagsOutcome,
  createTagsMessage,
});

export const createTagsReset = () => ({
  type: CREATE_TAGS_RESET,
});

export const updateTagsLoader = (isUpdatingTag) => ({
  type: UPDATE_TAGS_LOADER,
  isUpdatingTag,
});

export const updateTagsResponse = (updateTagsOutcome, updateTagsMessage) => ({
  type: UPDATE_TAGS_RESPONSE,
  updateTagsOutcome,
  updateTagsMessage,
});

export const updateTagsReset = () => ({
  type: UPDATE_TAGS_RESET,
});

export const getTagsLoader = (isLoadingTags) => ({
  type: GET_TAGS_LOADER,
  isLoadingTags,
});

export const getTagsResponse = (
  tagsOutcome,
  tagsData,
  tagsMeta,
  tagsMessage
) => ({
  type: GET_TAGS_RESPONSE,
  tagsOutcome,
  tagsData,
  tagsMeta,
  tagsMessage,
});

export const getTagsReset = () => ({
  type: GET_TAGS_RESET,
});

export const deleteTagsLoader = (isDeletingTag) => ({
  type: DELETE_TAGS_LOADER,
  isDeletingTag,
});

export const deleteTagsResponse = (deleteTagsOutcome, deleteTagsMessage) => ({
  type: DELETE_TAGS_RESPONSE,
  deleteTagsOutcome,
  deleteTagsMessage,
});

export const deleteTagsReset = () => ({
  type: DELETE_TAGS_RESET,
});

export const createTagsAction = (data, success) => {
  return async (dispatch) => {
    dispatch(createTagsReset());
    dispatch(createTagsLoader(true));

    try {
      const res = await postRequest({
        url: "/firms/tags",
        data,
        token: true,
      });

      dispatch(createTagsResponse("success", ""));
      dispatch(createTagsLoader(false));

      success && success();
      return res?.data?.data;
    } catch (error) {
      const message = getRequestError(error);

      dispatch(createTagsResponse("error", message));
      dispatch(createTagsLoader(false));
    }
  };
};

export const updateTagsAction = (data, id, success) => {
  return async (dispatch) => {
    dispatch(updateTagsReset());
    dispatch(updateTagsLoader(true));

    try {
      await patchRequest({
        url: `/firms/tags/${id}`,
        data,
        token: true,
      });

      dispatch(updateTagsResponse("success", ""));
      dispatch(updateTagsLoader(false));

      success && success();
    } catch (error) {
      const message = getRequestError(error);

      dispatch(updateTagsResponse("error", message));
      dispatch(updateTagsLoader(false));
    }
  };
};

export const getTagsAction = (params = {}) => {
  return async (dispatch) => {
    dispatch(getTagsReset());
    dispatch(getTagsLoader(true));

    try {
      const res = await getRequest({
        url: "/firms/tags",
        params,
        token: true,
      });

      const { data, offset, limit, total } = res.data;

      dispatch(
        getTagsResponse(
          "success",
          data.length ? data : [],
          { offset, limit, total },
          ""
        )
      );
      dispatch(getTagsLoader(false));
      return data;
    } catch (error) {
      const message = getRequestError(error);

      dispatch(getTagsResponse("error", [], {}, message));
      dispatch(getTagsLoader(false));
    }
  };
};

export const deleteTagsAction = (id) => {
  return async (dispatch) => {
    dispatch(deleteTagsReset());
    dispatch(deleteTagsLoader(true));

    try {
      await deleteRequest({
        url: `/firms/tags/${id}`,
        token: true,
      });

      dispatch(deleteTagsResponse("success", ""));
      dispatch(deleteTagsLoader(false));
    } catch (error) {
      const message = getRequestError(error);

      dispatch(deleteTagsResponse("error", message));
      dispatch(deleteTagsLoader(false));
    }
  };
};
