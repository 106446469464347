import {
  CREATE_TEMPLATE_MESSAGE_LOADER,
  CREATE_TEMPLATE_MESSAGE_RESPONSE,
  CREATE_TEMPLATE_MESSAGE_RESET,
  UPDATE_TEMPLATE_MESSAGE_LOADER,
  UPDATE_TEMPLATE_MESSAGE_RESPONSE,
  UPDATE_TEMPLATE_MESSAGE_RESET,
  GET_TEMPLATE_CATEGORIES_LOADER,
  GET_TEMPLATE_MESSAGE_LOADER,
  GET_TEMPLATE_MESSAGE_RESPONSE,
  GET_TEMPLATE_MESSAGE_RESET,
  DELETE_TEMPLATE_MESSAGE_LOADER,
  DELETE_TEMPLATE_MESSAGE_RESPONSE,
  DELETE_TEMPLATE_MESSAGE_RESET,
} from "../constants";

const INIT_STATE = {
  templateMessageData: [],
  templateMessageMeta: {
    limit: 10,
    offset: 0,
    total: 0,
  },
  isCreatingTemplateMessage: false,
  isUpdatingTemplateMessage: false,
  isDeletingTemplateMessage: false,
  isLoadingTemplateCategories: false,
  isLoadingTemplateMessage: false,
  createTemplateMessageOutcome: "",
  createTemplateMessageMessage: "",
  updateTemplateMessageOutcome: "",
  updateTemplateMessageMessage: "",
  deleteTemplateMessageOutcome: "",
  deleteTemplateMessageMessage: "",
  templateMessageOutcome: "",
  templateMessageMessage: "",
};

const TemplateMessage = (state = INIT_STATE, action) => {
  const {
    type,
    createTemplateMessageOutcome,
    createTemplateMessageMessage,
    updateTemplateMessageOutcome,
    updateTemplateMessageMessage,
    deleteTemplateMessageOutcome,
    deleteTemplateMessageMessage,
    templateMessageOutcome,
    templateMessageMessage,
    templateMessageData,
    templateMessageMeta,
    isCreatingTemplateMessage,
    isUpdatingTemplateMessage,
    isDeletingTemplateMessage,
    isLoadingTemplateCategories,
    isLoadingTemplateMessage,
  } = action;

  switch (type) {
    case CREATE_TEMPLATE_MESSAGE_LOADER: {
      return {
        ...state,
        isCreatingTemplateMessage,
      };
    }

    case CREATE_TEMPLATE_MESSAGE_RESPONSE: {
      return {
        ...state,
        createTemplateMessageOutcome,
        createTemplateMessageMessage,
      };
    }

    case CREATE_TEMPLATE_MESSAGE_RESET: {
      return {
        ...state,
        createTemplateMessageOutcome: "",
        createTemplateMessageMessage: "",
      };
    }

    case UPDATE_TEMPLATE_MESSAGE_LOADER: {
      return {
        ...state,
        isUpdatingTemplateMessage,
      };
    }

    case UPDATE_TEMPLATE_MESSAGE_RESPONSE: {
      return {
        ...state,
        updateTemplateMessageOutcome,
        updateTemplateMessageMessage,
      };
    }

    case UPDATE_TEMPLATE_MESSAGE_RESET: {
      return {
        ...state,
        updateTemplateMessageOutcome: "",
        updateTemplateMessageMessage: "",
      };
    }

    case DELETE_TEMPLATE_MESSAGE_LOADER: {
      return {
        ...state,
        isDeletingTemplateMessage,
      };
    }

    case DELETE_TEMPLATE_MESSAGE_RESPONSE: {
      return {
        ...state,
        deleteTemplateMessageOutcome,
        deleteTemplateMessageMessage,
      };
    }

    case DELETE_TEMPLATE_MESSAGE_RESET: {
      return {
        ...state,
        deleteTemplateMessageOutcome: "",
        deleteTemplateMessageMessage: "",
      };
    }

    case GET_TEMPLATE_CATEGORIES_LOADER: {
      return {
        ...state,
        isLoadingTemplateCategories,
      };
    }

    case GET_TEMPLATE_MESSAGE_LOADER: {
      return {
        ...state,
        isLoadingTemplateMessage,
      };
    }

    case GET_TEMPLATE_MESSAGE_RESPONSE: {
      return {
        ...state,
        templateMessageOutcome,
        templateMessageData,
        templateMessageMeta,
        templateMessageMessage,
      };
    }

    case GET_TEMPLATE_MESSAGE_RESET: {
      return INIT_STATE;
    }

    default:
      return state;
  }
};

export default TemplateMessage;
