import React from "react";

import { Avatar, Settings } from "../../../assets/vectors";

import {
  customersPermissions,
  integrationsPermissions,
} from "../../../utils/permissions";

const ConvoSidebar = ({ sidebarAction, setSidebarAction }) => {
  const allActions = [];

  if (customersPermissions.read || customersPermissions.viewAll) {
    allActions?.push({
      id: "customer",
      title: "View customer profile",
      icon: <Avatar />,
    });
  }
  if (integrationsPermissions.read || integrationsPermissions.viewAll) {
    allActions?.push({
      id: "integration",
      title: "View integratons",
      icon: <Settings />,
    });
  }

  return (
    <>
      {allActions?.length > 0 && (
        <div className="convo_sidebar_container">
          {allActions?.map((item, i) => (
            <div
              key={i}
              title={item?.title}
              className={`item${sidebarAction === item?.id ? ` selected` : ``}`}
              onClick={() => setSidebarAction(item?.id)}
            >
              {item?.icon}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default ConvoSidebar;
