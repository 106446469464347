export const SIGNUP_LOADER = "SIGNUP_LOADER";
export const SIGNUP_RESPONSE = "SIGNUP_RESPONSE";

export const LOGIN_RESET = "LOGIN_RESET";
export const LOGIN_LOADER = "LOGIN_LOADER";
export const LOGIN_RESPONSE = "LOGIN_RESPONSE";

export const FORGOT_PASSWORD_RESET = "FORGOT_PASSWORD_RESET";
export const FORGOT_PASSWORD_LOADER = "FORGOT_PASSWORD_LOADER";
export const FORGOT_PASSWORD_RESPONSE = "FORGOT_PASSWORD_RESPONSE";

export const RESET_PASSWORD_RESET = "RESET_PASSWORD_RESET";
export const RESET_PASSWORD_LOADER = "RESET_PASSWORD_LOADER";
export const RESET_PASSWORD_RESPONSE = "RESET_PASSWORD_RESPONSE";

export const SET_PASSWORD_RESET = "SET_PASSWORD_RESET";
export const SET_PASSWORD_LOADER = "SET_PASSWORD_LOADER";
export const SET_PASSWORD_RESPONSE = "SET_PASSWORD_RESPONSE";

export const SET_ONBOARDING_STEP = "SET_ONBOARDING_STEP";

export const LOGOUT = "LOGOUT";

export const GET_CONVERSATIONS_COUNT_LOADER = "GET_CONVERSATIONS_COUNT_LOADER";
export const GET_CONVERSATIONS_COUNT_RESPONSE =
  "GET_CONVERSATIONS_COUNT_RESPONSE";

export const GET_CONVERSATIONS_RESET = "GET_CONVERSATIONS_RESET";
export const GET_CONVERSATIONS_LOADER = "GET_CONVERSATIONS_LOADER";
export const GET_MORE_CONVERSATIONS_LOADER = "GET_MORE_CONVERSATIONS_LOADER";
export const GET_CONVERSATIONS_RESPONSE = "GET_CONVERSATIONS_RESPONSE";

export const SET_IS_SENDING_MESSAGE = "SET_IS_SENDING_MESSAGE";
export const SET_MESSAGE_SENDING_STATUS = "SET_MESSAGE_SENDING_STATUS";

export const GET_CONVERSATION_TEMPLATES_RESET =
  "GET_CONVERSATION_TEMPLATES_RESET";
export const GET_CONVERSATION_TEMPLATES_LOADER =
  "GET_CONVERSATION_TEMPLATES_LOADER";
export const GET_CONVERSATION_TEMPLATES_RESPONSE =
  "GET_CONVERSATION_TEMPLATES_RESPONSE";
export const CREATE_CONVERSATIONS_UNREAD_COUNT =
  "CREATE_CONVERSATIONS_UNREAD_COUNT";
export const GET_CONVERSATIONS_UNREAD_COUNT = "GET_CONVERSATIONS_UNREAD_COUNT";
export const CREATE_CONVERSATIONS_UNREAD_MSGS =
  "CREATE_CONVERSATIONS_UNREAD_MSGS";
export const GET_CONVERSATIONS_UNREAD_MSGS = "GET_CONVERSATIONS_UNREAD_MSGS";
export const GET_SEARCH_LOADER = "GET_SEARCH_LOADER";
export const GET_SEARCH_RESPONSE = "GET_SEARCH_RESPONSE";

export const ASSIGN_CONVERSATION_RESPONSE = "ASSIGN_CONVERSATION_RESPONSE";

export const GET_MENTIONS_LOADER = "GET_MENTIONS_LOADER";
export const GET_MORE_MENTIONS_LOADER = "GET_MORE_MENTIONS_LOADER";
export const GET_MENTIONS_RESPONSE = "GET_MENTIONS_RESPONSE";

export const GET_MENTION_LOADER = "GET_MENTION_LOADER";
export const SET_MENTION_REPLY_SENDING_STATUS =
  "SET_MENTION_REPLY_SENDING_STATUS";

export const CREATE_MENTIONS_UNREAD_COUNT = "CREATE_MENTIONS_UNREAD_COUNT";
export const GET_MENTIONS_UNREAD_COUNT = "GET_MENTIONS_UNREAD_COUNT";

export const GET_ROLES_RESPONSE = "GET_ROLES_RESPONSE";

export const CREATE_AGENTS_RESET = "CREATE_AGENTS_RESET";
export const CREATE_AGENTS_LOADER = "CREATE_AGENTS_LOADER";
export const CREATE_AGENTS_RESPONSE = "CREATE_AGENTS_RESPONSE";

export const CONFIRM_AGENTS_RESET = "CONFIRM_AGENTS_RESET";
export const CONFIRM_AGENTS_LOADER = "CONFIRM_AGENTS_LOADER";
export const CONFIRM_AGENTS_RESPONSE = "CONFIRM_AGENTS_RESPONSE";

export const UPDATE_AGENTS_RESET = "UPDATE_AGENTS_RESET";
export const UPDATE_AGENTS_LOADER = "UPDATE_AGENTS_LOADER";
export const UPDATE_AGENTS_RESPONSE = "UPDATE_AGENTS_RESPONSE";

export const DELETE_AGENTS_RESET = "DELETE_AGENTS_RESET";
export const DELETE_AGENTS_LOADER = "DELETE_AGENTS_LOADER";
export const DELETE_AGENTS_RESPONSE = "DELETE_AGENTS_RESPONSE";

export const GET_AGENTS_LOADER = "GET_AGENTS_LOADER";
export const GET_AGENTS_RESPONSE = "GET_AGENTS_RESPONSE";
export const GET_SINGLE_AGENT_RESET = "GET_SINGLE_AGENT_RESET";
export const GET_SINGLE_AGENT_LOADER = "GET_SINGLE_AGENT_LOADER";
export const GET_SINGLE_AGENT_RESPONSE = "GET_SINGLE_AGENT_RESPONSE";

export const CREATE_TEAM_RESET = "CREATE_TEAM_RESET";
export const CREATE_TEAM_LOADER = "CREATE_TEAM_LOADER";
export const CREATE_TEAM_RESPONSE = "CREATE_TEAM_RESPONSE";

export const UPDATE_TEAM_RESET = "UPDATE_TEAM_RESET";
export const UPDATE_TEAM_LOADER = "UPDATE_TEAM_LOADER";
export const UPDATE_TEAM_RESPONSE = "UPDATE_TEAM_RESPONSE";

export const DELETE_TEAM_RESET = "DELETE_TEAM_RESET";
export const DELETE_TEAM_LOADER = "DELETE_TEAM_LOADER";
export const DELETE_TEAM_RESPONSE = "DELETE_TEAM_RESPONSE";

export const GET_TEAM_RESET = "GET_TEAM_RESET";
export const GET_TEAM_LOADER = "GET_TEAM_LOADER";
export const GET_TEAM_RESPONSE = "GET_TEAM_RESPONSE";

export const CREATE_CUSTOMERS_RESET = "CREATE_CUSTOMERS_RESET";
export const CREATE_CUSTOMERS_LOADER = "CREATE_CUSTOMERS_LOADER";
export const CREATE_CUSTOMERS_RESPONSE = "CREATE_CUSTOMERS_RESPONSE";

export const UPDATE_CUSTOMERS_RESET = "UPDATE_CUSTOMERS_RESET";
export const UPDATE_CUSTOMERS_LOADER = "UPDATE_CUSTOMERS_LOADER";
export const UPDATE_CUSTOMERS_RESPONSE = "UPDATE_CUSTOMERS_RESPONSE";

export const DELETE_CUSTOMERS_RESET = "DELETE_CUSTOMERS_RESET";
export const DELETE_CUSTOMERS_LOADER = "DELETE_CUSTOMERS_LOADER";
export const DELETE_CUSTOMERS_RESPONSE = "DELETE_CUSTOMERS_RESPONSE";

export const IMPORT_CUSTOMERS_LOADER = "IMPORT_CUSTOMERS_LOADER";
export const IMPORT_CUSTOMERS_RESPONSE = "IMPORT_CUSTOMERS_RESPONSE";

export const GET_CUSTOMERS_RESET = "GET_CUSTOMERS_RESET";
export const GET_CUSTOMERS_LOADER = "GET_CUSTOMERS_LOADER";
export const GET_CUSTOMERS_RESPONSE = "GET_CUSTOMERS_RESPONSE";
export const GET_SINGLE_CUSTOMER_RESET = "GET_SINGLE_CUSTOMER_RESET";
export const GET_SINGLE_CUSTOMER_LOADER = "GET_SINGLE_CUSTOMER_LOADER";
export const GET_SINGLE_CUSTOMER_RESPONSE = "GET_SINGLE_CUSTOMER_RESPONSE";

export const CREATE_BROADCAST_LOADER = "CREATE_BROADCAST_LOADER";
export const CREATE_BROADCAST_RESPONSE = "CREATE_BROADCAST_RESPONSE";
export const CREATE_BROADCAST_RESET = "CREATE_BROADCAST_RESET";

export const GET_BROADCAST_LOADER = "GET_BROADCAST_LOADER";
export const GET_BROADCAST_RESPONSE = "GET_BROADCAST_RESPONSE";
export const GET_BROADCAST_RESET = "GET_BROADCAST_RESET";

export const DELETE_BROADCAST_LOADER = "DELETE_BROADCAST_LOADER";
export const DELETE_BROADCAST_RESPONSE = "DELETE_BROADCAST_RESPONSE";
export const DELETE_BROADCAST_RESET = "DELETE_BROADCAST_RESET";

export const CREATE_CHANNELS_RESET = "CREATE_CHANNELS_RESET";
export const CREATE_CHANNELS_LOADER = "CREATE_CHANNELS_LOADER";
export const CREATE_CHANNELS_RESPONSE = "CREATE_CHANNELS_RESPONSE";

export const UPDATE_CHANNELS_RESET = "UPDATE_CHANNELS_RESET";
export const UPDATE_CHANNELS_LOADER = "UPDATE_CHANNELS_LOADER";
export const UPDATE_CHANNELS_RESPONSE = "UPDATE_CHANNELS_RESPONSE";

export const DELETE_CHANNELS_RESET = "DELETE_CHANNELS_RESET";
export const DELETE_CHANNELS_LOADER = "DELETE_CHANNELS_LOADER";
export const DELETE_CHANNELS_RESPONSE = "DELETE_CHANNELS_RESPONSE";

export const GET_CHANNELS_RESET = "GET_CHANNELS_RESET";
export const GET_CHANNELS_LOADER = "GET_CHANNELS_LOADER";
export const GET_ALL_CHANNELS_RESPONSE = "GET_ALL_CHANNELS_RESPONSE";
export const GET_FIRM_CHANNELS_RESPONSE = "GET_FIRM_CHANNELS_RESPONSE";
export const GET_SINGLE_CHANNEL_RESET = "GET_SINGLE_CHANNEL_RESET";
export const GET_SINGLE_CHANNEL_LOADER = "GET_SINGLE_CHANNEL_LOADER";
export const GET_SINGLE_CHANNEL_RESPONSE = "GET_SINGLE_CHANNEL_RESPONSE";

export const CREATE_SAVED_RESPONSES_RESET = "CREATE_SAVED_RESPONSES_RESET";
export const CREATE_SAVED_RESPONSES_LOADER = "CREATE_SAVED_RESPONSES_LOADER";
export const CREATE_SAVED_RESPONSES_RESPONSE =
  "CREATE_SAVED_RESPONSES_RESPONSE";

export const UPDATE_SAVED_RESPONSES_RESET = "UPDATE_SAVED_RESPONSES_RESET";
export const UPDATE_SAVED_RESPONSES_LOADER = "UPDATE_SAVED_RESPONSES_LOADER";
export const UPDATE_SAVED_RESPONSES_RESPONSE =
  "UPDATE_SAVED_RESPONSES_RESPONSE";

export const DELETE_SAVED_RESPONSES_RESET = "DELETE_SAVED_RESPONSES_RESET";
export const DELETE_SAVED_RESPONSES_LOADER = "DELETE_SAVED_RESPONSES_LOADER";
export const DELETE_SAVED_RESPONSES_RESPONSE =
  "DELETE_SAVED_RESPONSES_RESPONSE";

export const GET_SAVED_RESPONSES_RESET = "GET_SAVED_RESPONSES_RESET";
export const GET_SAVED_RESPONSES_LOADER = "GET_SAVED_RESPONSES_LOADER";
export const GET_SAVED_RESPONSES_RESPONSE = "GET_SAVED_RESPONSES_RESPONSE";
export const GET_SINGLE_SAVED_RESPONSE_RESET =
  "GET_SINGLE_SAVED_RESPONSE_RESET";
export const GET_SINGLE_SAVED_RESPONSE_LOADER =
  "GET_SINGLE_SAVED_RESPONSE_LOADER";
export const GET_SINGLE_SAVED_RESPONSE_RESPONSE =
  "GET_SINGLE_SAVED_RESPONSE_RESPONSE";

export const CREATE_AUTO_RESPONSE_LOADER = "CREATE_AUTO_RESPONSE_LOADER";
export const CREATE_AUTO_RESPONSE_RESPONSE = "CREATE_AUTO_RESPONSE_RESPONSE";

export const UPDATE_AUTO_RESPONSE_LOADER = "UPDATE_AUTO_RESPONSE_LOADER";
export const UPDATE_AUTO_RESPONSE_RESPONSE = "UPDATE_AUTO_RESPONSE_RESPONSE";
export const AUTO_RESPONSES_RESET = "AUTO_RESPONSES_RESET";

export const DELETE_AUTO_RESPONSE_LOADER = "DELETE_AUTO_RESPONSE_LOADER";
export const DELETE_AUTO_RESPONSE_RESPONSE = "DELETE_AUTO_RESPONSE_RESPONSE";

export const GET_AUTO_RESPONSES_LOADER = "GET_AUTO_RESPONSES_LOADER";
export const GET_AUTO_RESPONSES_RESPONSE = "GET_AUTO_RESPONSES_RESPONSE";

export const CREATE_CSAT_LOADER = "CREATE_CSAT_LOADER";
export const CREATE_CSAT_RESPONSE = "CREATE_CSAT_RESPONSE";

export const GET_CSAT_LOADER = "GET_CSAT_LOADER";
export const GET_CSAT_RESPONSE = "GET_CSAT_RESPONSE";
export const CSAT_RESET = "CSAT_RESET";

export const UPDATE_CSAT_LOADER = "UPDATE_CSAT_LOADER";
export const UPDATE_CSAT_RESPONSE = "UPDATE_CSAT_RESPONSE";

export const DELETE_CSAT_LOADER = "DELETE_CSAT_LOADER";
export const DELETE_CSAT_RESPONSE = "DELETE_CSAT_RESPONSE";

export const CREATE_CUSTOM_FIELDS_LOADER = "CREATE_CUSTOM_FIELDS_LOADER";
export const CREATE_CUSTOM_FIELDS_RESPONSE = "CREATE_CUSTOM_FIELDS_RESPONSE";

export const GET_CUSTOM_FIELDS_LOADER = "GET_CUSTOM_FIELDS_LOADER";
export const GET_CUSTOM_FIELDS_RESPONSE = "GET_CUSTOM_FIELDS_RESPONSE";
export const CUSTOM_FIELDS_RESET = "CUSTOM_FIELDS_RESET";

export const UPDATE_CUSTOM_FIELDS_LOADER = "UPDATE_CUSTOM_FIELDS_LOADER";
export const UPDATE_CUSTOM_FIELDS_RESPONSE = "UPDATE_CUSTOM_FIELDS_RESPONSE";

export const DELETE_CUSTOM_FIELDS_LOADER = "DELETE_CUSTOM_FIELDS_LOADER";
export const DELETE_CUSTOM_FIELDS_RESPONSE = "DELETE_CUSTOM_FIELDS_RESPONSE";

export const CREATE_SIGNATURE_LOADER = "CREATE_SIGNATURE_LOADER";
export const CREATE_SIGNATURE_RESPONSE = "CREATE_SIGNATURE_RESPONSE";

export const GET_SIGNATURE_LOADER = "GET_SIGNATURE_LOADER";
export const GET_SIGNATURE_RESPONSE = "GET_SIGNATURE_RESPONSE";
export const SIGNATURE_RESET = "SIGNATURE_RESET";

export const UPDATE_SIGNATURE_LOADER = "UPDATE_SIGNATURE_LOADER";
export const UPDATE_SIGNATURE_RESPONSE = "UPDATE_SIGNATURE_RESPONSE";

export const DELETE_SIGNATURE_LOADER = "DELETE_SIGNATURE_LOADER";
export const DELETE_SIGNATURE_RESPONSE = "DELETE_SIGNATURE_RESPONSE";

export const GET_TEMPLATE_CATEGORIES_LOADER = "GET_TEMPLATE_CATEGORIES_LOADER";
export const GET_TEMPLATE_CATEGORIES_RESPONSE =
  "GET_TEMPLATE_CATEGORIES_RESPONSE";
export const GET_TEMPLATE_MESSAGE_LOADER = "GET_TEMPLATE_MESSAGE_LOADER";
export const GET_TEMPLATE_MESSAGE_RESPONSE = "GET_TEMPLATE_MESSAGE_RESPONSE";
export const GET_TEMPLATE_MESSAGE_RESET = "GET_TEMPLATE_MESSAGE_RESET";
export const CREATE_TEMPLATE_MESSAGE_LOADER = "CREATE_TEMPLATE_MESSAGE_LOADER";
export const CREATE_TEMPLATE_MESSAGE_RESPONSE =
  "CREATE_TEMPLATE_MESSAGE_RESPONSE";
export const CREATE_TEMPLATE_MESSAGE_RESET = "CREATE_TEMPLATE_MESSAGE_RESET";
export const UPDATE_TEMPLATE_MESSAGE_LOADER = "UPDATE_TEMPLATE_MESSAGE_LOADER";
export const UPDATE_TEMPLATE_MESSAGE_RESPONSE =
  "UPDATE_TEMPLATE_MESSAGE_RESPONSE";
export const UPDATE_TEMPLATE_MESSAGE_RESET = "UPDATE_TEMPLATE_MESSAGE_RESET";
export const DELETE_TEMPLATE_MESSAGE_LOADER = "DELETE_TEMPLATE_MESSAGE_LOADER";
export const DELETE_TEMPLATE_MESSAGE_RESPONSE =
  "DELETE_TEMPLATE_MESSAGE_RESPONSE";
export const DELETE_TEMPLATE_MESSAGE_RESET = "DELETE_TEMPLATE_MESSAGE_RESET";

export const CREATE_TAGS_RESET = "CREATE_TAGS_RESET";
export const CREATE_TAGS_LOADER = "CREATE_TAGS_LOADER";
export const CREATE_TAGS_RESPONSE = "CREATE_TAGS_RESPONSE";

export const UPDATE_TAGS_RESET = "UPDATE_TAGS_RESET";
export const UPDATE_TAGS_LOADER = "UPDATE_TAGS_LOADER";
export const UPDATE_TAGS_RESPONSE = "UPDATE_TAGS_RESPONSE";

export const DELETE_TAGS_RESET = "DELETE_TAGS_RESET";
export const DELETE_TAGS_LOADER = "DELETE_TAGS_LOADER";
export const DELETE_TAGS_RESPONSE = "DELETE_TAGS_RESPONSE";

export const GET_TAGS_RESET = "GET_TAGS_RESET";
export const GET_TAGS_LOADER = "GET_TAGS_LOADER";
export const GET_TAGS_RESPONSE = "GET_TAGS_RESPONSE";
export const GET_SINGLE_TAG_RESET = "GET_SINGLE_TAG_RESET";
export const GET_SINGLE_TAG_LOADER = "GET_SINGLE_TAG_LOADER";
export const GET_SINGLE_TAG_RESPONSE = "GET_SINGLE_TAG_RESPONSE";

export const CREATE_GROUPS_RESET = "CREATE_GROUPS_RESET";
export const CREATE_GROUPS_LOADER = "CREATE_GROUPS_LOADER";
export const CREATE_GROUPS_RESPONSE = "CREATE_GROUPS_RESPONSE";

export const UPDATE_GROUPS_RESET = "UPDATE_GROUPS_RESET";
export const UPDATE_GROUPS_LOADER = "UPDATE_GROUPS_LOADER";
export const UPDATE_GROUPS_RESPONSE = "UPDATE_GROUPS_RESPONSE";

export const DELETE_GROUPS_RESET = "DELETE_GROUPS_RESET";
export const DELETE_GROUPS_LOADER = "DELETE_GROUPS_LOADER";
export const DELETE_GROUPS_RESPONSE = "DELETE_GROUPS_RESPONSE";

export const GET_GROUPS_RESET = "GET_GROUPS_RESET";
export const GET_GROUPS_LOADER = "GET_GROUPS_LOADER";
export const GET_GROUPS_RESPONSE = "GET_GROUPS_RESPONSE";

export const CREATE_CATEGORIES_RESET = "CREATE_CATEGORIES_RESET";
export const CREATE_CATEGORIES_LOADER = "CREATE_CATEGORIES_LOADER";
export const CREATE_CATEGORIES_RESPONSE = "CREATE_CATEGORIES_RESPONSE";

export const UPDATE_CATEGORIES_RESET = "UPDATE_CATEGORIES_RESET";
export const UPDATE_CATEGORIES_LOADER = "UPDATE_CATEGORIES_LOADER";
export const UPDATE_CATEGORIES_RESPONSE = "UPDATE_CATEGORIES_RESPONSE";

export const DELETE_CATEGORIES_RESET = "DELETE_CATEGORIES_RESET";
export const DELETE_CATEGORIES_LOADER = "DELETE_CATEGORIES_LOADER";
export const DELETE_CATEGORIES_RESPONSE = "DELETE_CATEGORIES_RESPONSE";

export const GET_CATEGORIES_RESET = "GET_CATEGORIES_RESET";
export const GET_CATEGORIES_LOADER = "GET_CATEGORIES_LOADER";
export const GET_CATEGORIES_RESPONSE = "GET_CATEGORIES_RESPONSE";
export const GET_SINGLE_CATEGORY_RESET = "GET_SINGLE_CATEGORY_RESET";
export const GET_SINGLE_CATEGORY_LOADER = "GET_SINGLE_CATEGORY_LOADER";
export const GET_SINGLE_CATEGORY_RESPONSE = "GET_SINGLE_CATEGORY_RESPONSE";

export const GET_CONVERSATIONS_INSIGHTS_LOADER =
  "GET_CONVERSATIONS_INSIGHTS_LOADER";
export const GET_CONVERSATIONS_INSIGHTS_RESPONSE =
  "GET_CONVERSATIONS_INSIGHTS_RESPONSE";

export const GET_AGENTS_INSIGHTS_LOADER = "GET_AGENTS_INSIGHTS_LOADER";
export const GET_AGENTS_INSIGHTS_RESPONSE = "GET_AGENTS_INSIGHTS_RESPONSE";

export const GET_CUSTOMERS_INSIGHTS_LOADER = "GET_CUSTOMERS_INSIGHTS_LOADER";
export const GET_CUSTOMERS_INSIGHTS_RESPONSE =
  "GET_CUSTOMERS_INSIGHTS_RESPONSE";

export const GET_CHATBOT_INSIGHTS_LOADER = "GET_CHATBOT_INSIGHTS_LOADER";
export const GET_CHATBOT_INSIGHTS_RESPONSE = "GET_CHATBOT_INSIGHTS_RESPONSE";

export const GET_MESSAGE_LOGS_LOADER = "GET_MESSAGE_LOGS_LOADER";
export const GET_MESSAGE_LOGS_RESPONSE = "GET_MESSAGE_LOGS_RESPONSE";

export const GET_INTEGRATIONS_RESET = "GET_INTEGRATIONS_RESET";
export const GET_INTEGRATIONS_LOADER = "GET_INTEGRATIONS_LOADER";
export const GET_INTEGRATIONS_RESPONSE = "GET_INTEGRATIONS_RESPONSE";
export const GET_SINGLE_INTEGRATION_RESET = "GET_SINGLE_INTEGRATION_RESET";
export const GET_SINGLE_INTEGRATION_LOADER = "GET_SINGLE_INTEGRATION_LOADER";
export const GET_SINGLE_INTEGRATION_RESPONSE =
  "GET_SINGLE_INTEGRATION_RESPONSE";
export const CREATE_INTEGRATION_LOADER = "CREATE_INTEGRATION_LOADER";
export const CREATE_INTEGRATION_RESPONSE = "CREATE_INTEGRATION_RESPONSE";

export const GET_BILLING_LOADER = "GET_BILLING_LOADER";
export const GET_BILLING_SUBMITTER = "GET_BILLING_SUBMITTER";
export const GET_INVOICES_RESPONSE = "GET_INVOICES_RESPONSE";
export const GET_WALLET_HISTORY_RESPONSE = "GET_WALLET_HISTORY_RESPONSE";
export const GET_CARDS_RESPONSE = "GET_CARDS_RESPONSE";
export const GET_SUBSCRIPTIONS_RESPONSE = "GET_SUBSCRIPTIONS_RESPONSE";
export const GET_BILLING_RESPONSE = "GET_BILLING_RESPONSE";
export const GET_PLANS_RESPONSE = "GET_PLANS_RESPONSE";
export const GET_FIRM_PLAN_RESPONSE = "GET_FIRM_PLAN_RESPONSE";

export const ACCOUNT_SETTINGS_LOADER = "ACCOUNT_SETTINGS_LOADER";
export const ACCOUNT_SETTINGS_SUBMITTING = "ACCOUNT_SETTINGS_SUBMITTING";
export const ACCOUNT_SETTINGS_RESPONSE = "ACCOUNT_SETTINGS_RESPONSE";
export const ACCOUNT_INFO_RESPONSE = "ACCOUNT_INFO_RESPONSE";

export const WORKING_HOURS_LOADER = "WORKING_HOURS_LOADER";
export const WORKING_HOURS_SUBMITTING = "WORKING_HOURS_SUBMITTING";
export const WORKING_HOURS_RESPONSE = "WORKING_HOURS_RESPONSE";
export const WORKING_HOUR_RESPONSE = "WORKING_HOUR_RESPONSE";

export const AUTO_ASSIGNS_LOADER = "AUTO_ASSIGNS_LOADER";
export const AUTO_ASSIGNS_SUBMITTING = "AUTO_ASSIGNS_SUBMITTING";
export const AUTO_ASSIGNS_RESPONSE = "AUTO_ASSIGNS_RESPONSE";
export const AUTO_ASSIGN_RESPONSE = "AUTO_ASSIGN_RESPONSE";

export const WIDGETS_LOADER = "WIDGETS_LOADER";
export const WIDGETS_SUBMITTING = "WIDGETS_SUBMITTING";
export const WIDGETS_RESPONSE = "WIDGETS_RESPONSE";
export const WIDGET_RESPONSE = "WIDGET_RESPONSE";
