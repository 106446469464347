import React, { Component } from "react";
import classNames from "classnames";

import { Close } from "../../assets/vectors";

class Toaster extends Component {
  componentDidUpdate(prevProps) {
    const { open, closeToaster } = this.props;

    if (prevProps.open !== open && open === "open") {
      this.toasterTimeout = setTimeout(() => {
        open === "open" && closeToaster();
      }, 5000);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.toasterTimeout);
  }

  render() {
    const { status, content, open, closeToaster } = this.props;

    return (
      <div
        className={classNames(`toaster-container ${status}`, {
          open: open === "open",
          closed: open === "closed",
        })}
      >
        <div>
          <div
            className="toaster-message"
            dangerouslySetInnerHTML={{ __html: content }}
          ></div>
        </div>
        <div className="toaster-close" onClick={closeToaster}>
          <Close />
        </div>
      </div>
    );
  }
}

export default Toaster;
