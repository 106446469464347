import React from "react";
import * as moment from "moment";

const AgentInfo = ({ agentInsightDetails }) => {
  const {
    firstName,
    lastName,
    email,
    phone,
    location,
    category,
    department,
    id,
    createdAt,
  } = agentInsightDetails;

  return (
    <div className="agent-info">
      <span className="insights-section-title">Agent Info</span>
      <div className="insights-card">
        <div className="agent-info-item">
          <span className="title">Name:</span>
          <span className="value">
            {firstName} {lastName}
          </span>
        </div>
        <div className="agent-info-item">
          <span className="title">Email:</span>
          <span className="value">{email}</span>
        </div>
        <div className="agent-info-item">
          <span className="title">Phone:</span>
          <span className="value">{phone || "N/A"}</span>
        </div>
        <div className="agent-info-item">
          <span className="title">Location:</span>
          <span className="value">{location || "N/A"}</span>
        </div>
        <div className="agent-info-item">
          <span className="title">Agent Category:</span>
          <span className="value">{category || "N/A"}</span>
        </div>
        <div className="agent-info-item">
          <span className="title">Department:</span>
          <span className="value">{department || "N/A"}</span>
        </div>
        <div className="agent-info-item">
          <span className="title">Agent ID:</span>
          <span className="value">{id}</span>
        </div>
        <div className="agent-info-item">
          <span className="title">Date Added:</span>
          <span className="value">
            {createdAt ? moment(createdAt).format("DD MMMM, YYYY") : "N/A"}
          </span>
        </div>
      </div>
    </div>
  );
};

export default AgentInfo;
