import {
  GET_CONVERSATIONS_COUNT_LOADER,
  GET_CONVERSATIONS_COUNT_RESPONSE,
  GET_CONVERSATIONS_LOADER,
  GET_MORE_CONVERSATIONS_LOADER,
  GET_CONVERSATIONS_RESPONSE,
  GET_CONVERSATIONS_RESET,
  SET_MESSAGE_SENDING_STATUS,
  GET_CONVERSATION_TEMPLATES_LOADER,
  GET_CONVERSATION_TEMPLATES_RESPONSE,
  GET_CONVERSATION_TEMPLATES_RESET,
  CREATE_CONVERSATIONS_UNREAD_COUNT,
  CREATE_CONVERSATIONS_UNREAD_MSGS,
  GET_CONVERSATIONS_UNREAD_COUNT,
  GET_CONVERSATIONS_UNREAD_MSGS,
  GET_SEARCH_LOADER,
  GET_SEARCH_RESPONSE,
  ASSIGN_CONVERSATION_RESPONSE,
} from "../constants";

const INIT_STATE = {
  isLoadingConversationsCount: true,
  conversationsCountOutcome: "",
  conversationsCountData: {},
  conversationsCountMessage: "",
  isLoadingConversations: true,
  isLoadingMoreConversations: false,
  isLoadingConversationTemplates: true,
  isSearching: false,
  openConversations: {},
  inProgressConversations: {},
  awaitingResponseConversations: {},
  queueConversations: {},
  resolvedConversations: {},
  allConversations: {},
  messageSendingStatus: null,
  messageSendingResponse: null,
  conversationsOutcome: "",
  conversationsMessage: "",
  conversationsUnreadCount: {
    open: 0,
    "in-progress": 0,
    "awaiting-customer-response": 0,
    "in-queue": 0,
    closed: 0,
    all: 0,
  },
  conversationsUnreadMsgs: [],
  conversationTemplatesData: [],
  conversationTemplatesOutcome: "",
  conversationTemplatesMessage: "",
  searchOutcome: "",
  searchData: null,
  searchMessage: "",
  assignConversationOutcome: "",
  assignConversationMessage: "",
};

const Conversations = (state = INIT_STATE, action) => {
  const {
    type,
    isLoadingConversationsCount,
    conversationsCountOutcome,
    conversationsCountData,
    conversationsCountMessage,
    conversationsData,
    conversationsOutcome,
    conversationsMessage,
    conversationSection,
    paginate,
    isLoadingConversations,
    isLoadingMoreConversations,
    messageSendingStatus,
    messageSendingResponse,
    conversationsUnreadCount,
    conversationsUnreadMsgs,
    conversationTemplatesData,
    conversationTemplatesOutcome,
    conversationTemplatesMessage,
    isLoadingConversationTemplates,
    isSearching,
    searchOutcome,
    searchData,
    searchMessage,
    assignConversationOutcome,
    assignConversationMessage,
  } = action;

  switch (type) {
    case GET_CONVERSATIONS_COUNT_LOADER: {
      return {
        ...state,
        isLoadingConversationsCount,
      };
    }

    case GET_CONVERSATIONS_COUNT_RESPONSE: {
      return {
        ...state,
        conversationsCountOutcome,
        conversationsCountData,
        conversationsCountMessage,
      };
    }

    case GET_CONVERSATIONS_LOADER: {
      return {
        ...state,
        isLoadingConversations,
      };
    }

    case GET_MORE_CONVERSATIONS_LOADER: {
      return {
        ...state,
        isLoadingMoreConversations,
      };
    }

    case GET_CONVERSATIONS_RESPONSE: {
      const oldResponse = JSON.parse(
        JSON.stringify(state[conversationSection])
      );

      const newResponse = paginate
        ? {
            ...conversationsData,
            data: [...oldResponse.data, ...conversationsData.data],
          }
        : conversationsData;

      return {
        ...state,
        conversationsOutcome,
        [conversationSection]: newResponse,
        conversationsMessage,
      };
    }

    case GET_CONVERSATIONS_RESET: {
      return INIT_STATE;
    }

    case SET_MESSAGE_SENDING_STATUS: {
      return {
        ...state,
        messageSendingStatus,
        messageSendingResponse,
      };
    }

    case GET_CONVERSATION_TEMPLATES_LOADER: {
      return {
        ...state,
        isLoadingConversationTemplates,
      };
    }

    case GET_CONVERSATION_TEMPLATES_RESPONSE: {
      return {
        ...state,
        conversationTemplatesOutcome,
        conversationTemplatesData,
        conversationTemplatesMessage,
      };
    }

    case GET_CONVERSATION_TEMPLATES_RESET: {
      return INIT_STATE;
    }

    case CREATE_CONVERSATIONS_UNREAD_COUNT: {
      return {
        ...state,
        conversationsUnreadCount,
      };
    }

    case CREATE_CONVERSATIONS_UNREAD_MSGS: {
      return {
        ...state,
        conversationsUnreadMsgs,
      };
    }

    case GET_CONVERSATIONS_UNREAD_COUNT: {
      return {
        ...state,
        conversationsUnreadCount,
      };
    }

    case GET_CONVERSATIONS_UNREAD_MSGS: {
      return {
        ...state,
        conversationsUnreadMsgs,
      };
    }

    case GET_SEARCH_LOADER: {
      return {
        ...state,
        isSearching,
      };
    }

    case GET_SEARCH_RESPONSE: {
      return {
        ...state,
        searchOutcome,
        searchData,
        searchMessage,
      };
    }

    case ASSIGN_CONVERSATION_RESPONSE: {
      return {
        ...state,
        assignConversationOutcome,
        assignConversationMessage,
      };
    }

    default:
      return state;
  }
};

export default Conversations;
