import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

import { CircularCheckGreen, ArrowUp } from "../../../../assets/vectors";
import { Button, TextField } from "../../../../components/FormElements";
import Modal from "../../../../components/Modal";

import {
  postEmailChannelSetup,
  getEmailChannelSetup,
  getAllChannelsAction,
  getFirmChannelsAction,
} from "../../../../redux/actions/Channels";

const EmailProForm = ({ close, selected_account }) => {
  const dispatch = useDispatch();
  const { isCreatingChannel } = useSelector((state) => state?.channels);

  const [currentStep, setCurrentStep] = useState(1);
  const [stepOneView, setStepOneView] = useState(1);
  const [stepTwoView, setStepTwoView] = useState(1);
  const [countryOptions, setCountryOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [name, setName] = useState("--");
  const [availability, setAvailability] = useState(null);
  const [email, setEmail] = useState("");
  const [emailProvider, setEmailProvider] = useState({
    value: "",
    label: "Select email provider",
  });
  const [generatedEmail, setGeneratedEmail] = useState(null);
  const [generatedId, setGeneratedId] = useState(null);
  const [isWhatToKnowOpen, setIsWhatToKnowOpen] = useState(true);
  const [emailForwaded, setEmailForwaded] = useState(false);
  const [showHoldText, setShowHoldText] = useState(false);
  const [emailForwadingVerified, setEmailForwadingVerified] = useState(null);
  const [formData, setFormData] = useState({
    from_name: "",
    from_email: "",
    from_address_one: "",
  });
  const [sendingCountry, setSendingCountry] = useState({
    value: "",
    label: "Select country",
  });
  const [sendingCity, setSendingCity] = useState({
    value: "",
    label: "Select city",
  });

  const credentials = selected_account?.credentials || null;

  useEffect(() => {
    var step = 1;

    if (
      credentials?.forwarding?.verified === true &&
      credentials?.sending?.verified === true
    ) {
      step = 5;
    } else if (credentials?.sending) {
      step = 4;
    } else if (
      credentials?.forwarding?.verified === true ||
      credentials?.domain
    ) {
      step = 3;
      setGeneratedId(selected_account?.id);
      setGeneratedEmail(credentials?.email);
      setStepTwoView(2);
      setEmail(credentials?.forwarding?.email);
      setEmailForwaded(true);
      getCountries();
    } else if (credentials?.emailDomain) {
      step = 2;
      setGeneratedId(selected_account?.id);
      setGeneratedEmail(credentials?.email);
    }

    setCurrentStep(step);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCountries = () => {
    dispatch(
      getEmailChannelSetup({
        url: `/utils/countries`,
      })
    ).then((res) => {
      const countries = res?.data?.data?.map((x) => ({
        value: x?.name,
        label: x?.name,
      }));
      if (res?.data?.success === true) {
        setCountryOptions(countries);
      }
    });
  };

  useEffect(() => {
    if (sendingCountry?.value) {
      getCountryCities();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendingCountry]);

  const getCountryCities = () => {
    dispatch(
      getEmailChannelSetup({
        url: `/utils/cities?country=${sendingCountry?.value}`,
      })
    ).then((res) => {
      const cities = res?.data?.data?.map((x) => ({
        value: x,
        label: x,
      }));
      if (res?.data?.success === true) {
        setCityOptions(cities);
      }
    });
  };

  const checkEmailAvailability = () => {
    const requestData = {
      name: name.toLowerCase(),
    };
    dispatch(
      postEmailChannelSetup({
        url: "/channels/email/name",
        requestData,
      })
    ).then((res) => {
      if (res?.data?.message === "email is available") {
        setAvailability(true);
      } else {
        setAvailability(false);
      }
    });
  };
  useEffect(() => {
    if (name?.length > 1 && name !== "--") {
      checkEmailAvailability();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  const subscribeToEmailChannel = () => {
    if (name?.length > 1 && name !== "--") {
      const requestData = {
        name: name.toLowerCase(),
      };
      dispatch(
        postEmailChannelSetup({
          url: "/channels/email/subscribe",
          requestData,
        })
      ).then((res) => {
        if (res?.data?.success === true) {
          setGeneratedEmail(res?.data?.data?.name);
          setGeneratedId(res?.data?.data?.id);
          dispatch(getAllChannelsAction());
          dispatch(getFirmChannelsAction());
          setStepOneView(2);
        }
      });
    }
  };

  const setupEmailForwarding = () => {
    if (email?.length > 5) {
      const requestData = {
        email,
      };
      dispatch(
        postEmailChannelSetup({
          url: `/channels/email/forwarding/${generatedId}`,
          requestData,
        })
      ).then((res) => {
        if (res?.data?.success === true) {
          setEmailForwaded(true);
          dispatch(getAllChannelsAction());
          dispatch(getFirmChannelsAction());
        }
      });
    }
  };

  const verifyEmailForwardingSend = () => {
    dispatch(
      postEmailChannelSetup({
        url: `/channels/email/forwarding/test/${generatedId}`,
      })
    ).then((res) => {
      if (res?.data?.success === true) {
        setShowHoldText(true);
        setTimeout(() => {
          verifyEmailForwardingCheck();
        }, 5000);
        clearTimeout();
      }
    });
  };

  const verifyEmailForwardingCheck = () => {
    dispatch(
      getEmailChannelSetup({
        url: `/channels/email/forwarding/test/${generatedId}`,
      })
    ).then((res) => {
      setShowHoldText(false);
      if (res?.data?.data?.verified === true) {
        setEmailForwadingVerified(true);
        setStepTwoView(2);
      } else {
        setEmailForwadingVerified(false);
      }
    });
  };

  const setupEmailSender = () => {
    const { from_name, from_address_one } = formData;

    if (
      from_name &&
      from_address_one &&
      sendingCountry?.value &&
      sendingCity?.value
    ) {
      const requestData = {
        from_name,
        from_email: email,
        address: from_address_one,
        city: sendingCity?.value,
        country: sendingCountry?.value,
      };
      dispatch(
        postEmailChannelSetup({
          url: `/channels/email/sending/${generatedId}`,
          requestData,
        })
      ).then((res) => {
        if (res?.data?.success === true) {
          dispatch(getFirmChannelsAction());
          setCurrentStep(4);
        }
      });
    }
  };

  const sendingFormOnChange = (e) => {
    const { name, value } = e?.target;
    setFormData({ ...formData, [name]: value });
  };

  const whatToKnow = [
    "Emails sent directly to your OneRoute email appear in conversations.",
    "To receive emails from your current mailbox directly in OneRoute you would need to Setup Email forwarding.",
    "Unless you set up Sending email from custom domain, responses to your customers are sent via your OneRoute email address.",
  ];

  const channelOptions = [
    { label: "Google G suite", value: "google" },
    { label: "Microsoft Outlook", value: "outlook" },
  ];

  const fowardersLinks = [
    {
      label: "google",
      image:
        "https://s3.eu-west-3.amazonaws.com/oneroute.asb.ng/gmail-logo.svg",
      to: "https://support.google.com/mail/answer/10957?hl=en#zippy=%2Cturn-automatic-fowarding-on-or-off",
    },
    {
      label: "outlook",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSP__VuTkawlW4TRekuXvrVa2NQDiSQoSL5U8gO-Yvt8zt26S6Gg22K0n8Y5UhLmTFyIA&usqp=CAU",
      to: "https://support.microsoft.com/en-us/office/turn-on-automatic-forwarding-in-outlook-on-the-web-7f2670a1-7fff-4475-8a3c-5822d63b0c8e",
    },
  ];

  const setupNavigation = [
    { id: 1, name: "Setup email channel" },
    { id: 2, name: "Setup email recieving" },
    { id: 3, name: "Setup email sending" },
  ];

  const mailAddress = "mail.oneroute.io";

  return (
    <React.Fragment>
      <Modal className="channel-modal">
        <div className="email_setup_modal">
          <div className="navigation">
            {setupNavigation?.map(({ id, name }) => (
              <div
                key={id}
                className={`nav-item ${currentStep === id ? "active" : ""} ${
                  currentStep > id ? "done" : ""
                }`}
              >
                <span>{currentStep > id ? <CircularCheckGreen /> : id}</span>
                {name}
              </div>
            ))}
          </div>

          {currentStep === 1 && (
            <>
              {stepOneView === 1 ? (
                <div className="modal_content email_setup_container">
                  <div className="header">
                    <h5>Setting up your Email channel</h5>
                    <p>
                      You need to start by setting up a OneRoute email address.
                    </p>
                  </div>

                  <div className="form">
                    <p>
                      Please enter the email prefix you would like to use with
                      your OneRoute email
                    </p>
                    <div className="d-flex">
                      <TextField
                        type="text"
                        name="name"
                        placeholder="Enter email prefix here"
                        onChange={(e) => setName(e.target.value)}
                      />
                      <p>@</p>
                      <h6>{mailAddress}</h6>
                    </div>

                    {availability === true && (
                      <span className="status success">
                        The prefix is available
                      </span>
                    )}
                    {availability === false && (
                      <span className="status error">
                        The prefix is not available
                      </span>
                    )}

                    <p className="warning">
                      You cannot change this email once it is set up so we
                      advise you choose a prefix carefully.
                    </p>

                    <div className="actions">
                      <p className="secondary-button" onClick={close}>
                        Cancel
                      </p>
                      <Button
                        className="primary-button"
                        disabled={
                          isCreatingChannel ||
                          availability === false ||
                          availability === null
                        }
                        onClick={() => subscribeToEmailChannel()}
                      >
                        Continue
                      </Button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="modal_content email_setup_container step_one_view_two">
                  <div className="header">
                    <h5>We’ve Created an Email for you!</h5>
                    <p>
                      Receive and send emails seamlessly with your brand new
                      OneRoute email address.
                    </p>
                  </div>

                  <div className="copy_div">
                    <p>{generatedEmail || `${name}@${mailAddress}`}</p>
                    <Button
                      className="tertiary-button"
                      onClick={() =>
                        navigator.clipboard.writeText(
                          `${generatedEmail ? generatedEmail : ""}`
                        )
                      }
                    >
                      Copy
                    </Button>
                  </div>

                  <div className="what_to_know">
                    <div
                      className="header"
                      onClick={() => setIsWhatToKnowOpen(!isWhatToKnowOpen)}
                    >
                      <h6>What you should know</h6>
                      <p>{isWhatToKnowOpen ? "Collapse" : "Expand"}</p>
                    </div>
                    {isWhatToKnowOpen && (
                      <div className="content">
                        {whatToKnow?.map((item, i) => (
                          <div key={i} className="list">
                            <ArrowUp />
                            <span>{item}</span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>

                  <div className="actions">
                    <p className="secondary-button" onClick={close}>
                      Continue without email forwarding
                    </p>
                    <Button
                      className="primary-button"
                      onClick={() => setCurrentStep(2)}
                    >
                      Next step
                    </Button>
                  </div>
                </div>
              )}
            </>
          )}

          {currentStep === 2 && (
            <div className="modal_content email_setup_container step_two">
              <div className="header">
                <h5>Setup email forwarding</h5>
                <p>
                  Setup email forwarding to receive emails from your current
                  work mailbox directly in OneRoute.
                </p>
              </div>

              <div className="content">
                <p>Please enter the email you want to forward emails from</p>
                <div className="d-flex">
                  <TextField
                    type="email"
                    name="email"
                    value={email}
                    placeholder="example@gmail.com"
                    onChange={(e) => setEmail(e.target.value)}
                    disabled={emailForwaded}
                  />
                  <Select
                    classNamePrefix="select_container"
                    value={emailProvider}
                    options={channelOptions}
                    onChange={(e) =>
                      setEmailProvider({ value: e.value, label: e.label })
                    }
                    isDisabled={emailForwaded}
                  />
                  <Button
                    className={`tertiary-button ${emailForwaded && "success"}`}
                    disabled={
                      email?.length < 5 ||
                      !emailProvider?.value ||
                      isCreatingChannel ||
                      emailForwaded
                    }
                    onClick={() => setupEmailForwarding()}
                  >
                    {emailForwaded ? <CircularCheckGreen /> : "Submit"}
                  </Button>
                </div>

                {emailForwaded && (
                  <>
                    <p>
                      Click on the image/link below and follow the instructions
                      to set up email forwarding
                    </p>
                    <div className="images">
                      {fowardersLinks
                        ?.filter((x) => x.label === emailProvider?.value)
                        ?.map(({ image, to }, i) => (
                          <a
                            key={i}
                            href={to}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img src={image} alt="" />
                          </a>
                        ))}
                    </div>
                  </>
                )}

                {emailForwaded && (
                  <div className="actions">
                    {stepTwoView === 1 ? (
                      <>
                        {emailForwadingVerified === null && (
                          <Button
                            className="tertiary-button"
                            onClick={() => {
                              verifyEmailForwardingSend();
                            }}
                            disabled={isCreatingChannel}
                          >
                            I have set up email forwarding
                          </Button>
                        )}
                        {showHoldText && (
                          <div className="hold_text">Please wait...</div>
                        )}
                        {emailForwadingVerified === false && (
                          <Button
                            className="tertiary-button"
                            onClick={() => {
                              verifyEmailForwardingCheck();
                            }}
                            disabled={isCreatingChannel}
                          >
                            Re-verify setup
                          </Button>
                        )}
                        {emailForwadingVerified === false &&
                          !isCreatingChannel && (
                            <div className="error_response">
                              Unable to verify Email forwarding, kindly ensure
                              that you've set it up correctly
                            </div>
                          )}
                      </>
                    ) : (
                      <p className="success">
                        <CircularCheckGreen /> Email forwarding complete
                      </p>
                    )}
                  </div>
                )}
              </div>

              <div className="actions">
                <p className="secondary-button" onClick={close}>
                  Skip without forwarding
                </p>
                <Button
                  className="primary-button"
                  disabled={isCreatingChannel || stepTwoView !== 2}
                  onClick={() => {
                    getCountries();
                    setCurrentStep(3);
                  }}
                >
                  Next step
                </Button>
              </div>
            </div>
          )}

          {currentStep === 3 && (
            <div className="modal_content email_setup_container step_three">
              <div className="header">
                <h5>Setup email sending</h5>
                <p>
                  You are required to include your contact information,
                  including a physical mailing address, inside every promotional
                  email you send in order to comply with the anti-spam laws such
                  as CAN-SPAM and CASL.
                </p>
              </div>

              <div className="content">
                <TextField
                  label="From name"
                  type="text"
                  name="from_name"
                  value={formData?.from_name}
                  placeholder="Imran"
                  onChange={sendingFormOnChange}
                  disabled={isCreatingChannel}
                />
                <TextField
                  label="From email"
                  type="email"
                  name="from_email"
                  value={email}
                  disabled={true}
                />
                <TextField
                  label="Company address line 1"
                  type="text"
                  name="from_address_one"
                  value={formData?.from_address_one}
                  placeholder="124, Address Road"
                  onChange={sendingFormOnChange}
                  disabled={isCreatingChannel}
                />

                <div className="d-flex">
                  <Select
                    classNamePrefix="select_container"
                    value={sendingCountry}
                    options={countryOptions}
                    onChange={(e) =>
                      setSendingCountry({ value: e.value, label: e.label })
                    }
                    isDisabled={isCreatingChannel}
                  />
                  <Select
                    classNamePrefix="select_container"
                    value={sendingCity}
                    options={cityOptions}
                    onChange={(e) =>
                      setSendingCity({ value: e.value, label: e.label })
                    }
                    isDisabled={isCreatingChannel}
                  />
                </div>
              </div>

              <div className="actions">
                <p className="secondary-button" onClick={close}>
                  Continue without email sending
                </p>
                <Button
                  className="primary-button"
                  disabled={isCreatingChannel}
                  onClick={() => setupEmailSender()}
                >
                  Finish setup
                </Button>
              </div>
            </div>
          )}

          {currentStep === 4 && (
            <div className="modal_content email_setup_container step_three">
              <div className="header">
                <h5>Setup in progress</h5>
                <p>
                  Your email channel set up is in progress. We’ll notify you
                  once it’s completed. Please allow up to 1 hour.
                </p>
              </div>

              <div className="actions">
                <div></div>
                <Button className="tertiary-button" onClick={close}>
                  Got it
                </Button>
              </div>
            </div>
          )}

          {currentStep === 5 && (
            <div className="modal_content email_setup_container step_three">
              <div className="header">
                <h5>Setup complete</h5>
                <p>Congratulations! Your email channel set up is complete.</p>
              </div>

              <div className="actions">
                <div></div>
                <Button className="tertiary-button" onClick={close}>
                  Got it
                </Button>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default withRouter(EmailProForm);
