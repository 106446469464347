import React from "react";

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      stroke="#474F58"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.25"
      d="M14.375 10a4.375 4.375 0 11-8.75 0 4.375 4.375 0 018.75 0z"
    ></path>
    <path
      stroke="#474F58"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.25"
      d="M14.375 10v1.875a2.5 2.5 0 005 0V10a9.374 9.374 0 10-3.75 7.5"
    ></path>
  </svg>
);
