import React from "react";

import {
  RankingPeople,
  Star,
  Conversations,
  ResponseTime
} from "../../../assets/vectors";

const PerformanceOverview = ({ performanceOverview }) => {
  const {
    averageResponseTime,
    conversationsCount,
    rank,
    rating,
    salesClosedCount
  } = performanceOverview || {};

  return (
    <div className="performance-overview">
      <span className="insights-section-title">Performance Overview</span>
      <div className="insights-card">
        <div className="performance-item agent-rank">
          <div className="icon">
            <RankingPeople />
          </div>
          <div>
            <span className="title">Agent Rank</span>
            <span className="value">
              {rank ? (
                <>
                  {rank} <span className="small">nd</span>
                </>
              ) : (
                "N/A"
              )}
            </span>
          </div>
        </div>
        <div className="performance-item agent-rating">
          <div className="icon">
            <Star />
          </div>
          <div>
            <span className="title">Agent Rating</span>
            <span className="value">{rating ? `${rating}/5` : "N/A"}</span>
          </div>
        </div>
        <div className="performance-item conversations">
          <div className="icon">
            <Conversations />
          </div>
          <div>
            <span className="title">Conversations</span>
            <span className="value">{conversationsCount || 0}</span>
          </div>
        </div>
        <div className="performance-item avg-response-time">
          <div className="icon">
            <ResponseTime />
          </div>
          <div>
            <span className="title">Avg Response Time</span>
            <span className="value">{averageResponseTime || 0}</span>
          </div>
        </div>
        <div className="performance-item agent-rating">
          <div className="icon">
            <Star />
          </div>
          <div>
            <span className="title">No of Sales closed</span>
            <span className="value">{salesClosedCount || 0}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PerformanceOverview;
