import {
  CREATE_TEAM_LOADER,
  CREATE_TEAM_RESPONSE,
  CREATE_TEAM_RESET,
  UPDATE_TEAM_LOADER,
  UPDATE_TEAM_RESPONSE,
  UPDATE_TEAM_RESET,
  GET_TEAM_LOADER,
  GET_TEAM_RESPONSE,
  GET_TEAM_RESET,
  DELETE_TEAM_LOADER,
  DELETE_TEAM_RESPONSE,
  DELETE_TEAM_RESET,
} from "../constants";

const INIT_STATE = {
  teamsData: [],
  teamsMeta: {
    limit: 10,
    offset: 0,
    total: 0,
  },
  isCreatingTeam: false,
  isUpdatingTeam: false,
  isDeletingTeam: false,
  isLoadingTeam: true,
  createTeamOutcome: "",
  createTeamMessage: "",
  updateTeamOutcome: "",
  updateTeamMessage: "",
  deleteTeamOutcome: "",
  deleteTeamMessage: "",
  teamsOutcome: "",
  teamsMessage: "",
};

const Teams = (state = INIT_STATE, action) => {
  const {
    type,
    createTeamOutcome,
    createTeamMessage,
    updateTeamOutcome,
    updateTeamMessage,
    deleteTeamOutcome,
    deleteTeamMessage,
    teamsOutcome,
    teamsMessage,
    teamsData,
    teamsMeta,
    isCreatingTeam,
    isUpdatingTeam,
    isDeletingTeam,
    isLoadingTeam,
  } = action;

  switch (type) {
    case CREATE_TEAM_LOADER: {
      return {
        ...state,
        isCreatingTeam,
      };
    }

    case CREATE_TEAM_RESPONSE: {
      return {
        ...state,
        createTeamOutcome,
        createTeamMessage,
      };
    }

    case CREATE_TEAM_RESET: {
      return {
        ...state,
        createTeamOutcome: "",
        createTeamMessage: "",
      };
    }

    case UPDATE_TEAM_LOADER: {
      return {
        ...state,
        isUpdatingTeam,
      };
    }

    case UPDATE_TEAM_RESPONSE: {
      return {
        ...state,
        updateTeamOutcome,
        updateTeamMessage,
      };
    }

    case UPDATE_TEAM_RESET: {
      return {
        ...state,
        updateTeamOutcome: "",
        updateTeamMessage: "",
      };
    }

    case DELETE_TEAM_LOADER: {
      return {
        ...state,
        isDeletingTeam,
      };
    }

    case DELETE_TEAM_RESPONSE: {
      return {
        ...state,
        deleteTeamOutcome,
        deleteTeamMessage,
      };
    }

    case DELETE_TEAM_RESET: {
      return {
        ...state,
        deleteTeamOutcome: "",
        deleteTeamMessage: "",
      };
    }

    case GET_TEAM_LOADER: {
      return {
        ...state,
        isLoadingTeam,
      };
    }

    case GET_TEAM_RESPONSE: {
      return {
        ...state,
        teamsOutcome,
        teamsData,
        teamsMeta,
        teamsMessage,
      };
    }

    case GET_TEAM_RESET: {
      return INIT_STATE;
    }

    default:
      return state;
  }
};

export default Teams;
