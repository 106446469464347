import React from "react";

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    fill="none"
    viewBox="0 0 21 21"
  >
    <path stroke="#474F58" strokeLinecap="round" d="M20.5 2.5L20.5 17.5"></path>
    <path
      stroke="#474F58"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.25"
      d="M9.75 10.25a2.625 2.625 0 100-5.25 2.625 2.625 0 000 5.25zM5.25 15.5a4.5 4.5 0 119 0h-9z"
    ></path>
    <path
      stroke="#474F58"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.25"
      d="M15 1H4.5c-1.657 0-3 1.506-3 3.364v11.772c0 1.858 1.343 3.364 3 3.364H15c1.657 0 3-1.506 3-3.364V4.364C18 2.506 16.657 1 15 1z"
    ></path>
  </svg>
);
