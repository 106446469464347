import React from "react";

export default () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <g
        stroke="#8F99A6"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        clipPath="url(#collapseClip0)"
      >
        <path d="M8.125 17.498v-5.625H2.5M8.125 11.873l-7.5 7.5M19.375.623l-7.5 7.5M11.875 2.498v5.625H17.5"></path>
      </g>
      <defs>
        <clipPath id="collapseClip0">
          <path fill="#fff" d="M0 0H20V20H0z"></path>
        </clipPath>
      </defs>
    </svg>
)