import React from "react";
import Select from "react-select";

import { Button } from "../../../components/FormElements";
import Modal from "../../../components/Modal";
import FormNotifications from "../../../components/Notifications";

const AssignToAgent = ({
  close,
  isLoadingAgents,
  agent,
  allAgents,
  onChange,
  isAssigningToAgent,
  assignOnClick,
  assignConversationOutcome,
  assignConversationMessage,
}) => {
  const agentOptions = allAgents?.map((x) => ({
    label: `${x?.firstName} ${x?.lastName}`,
    value: x?.id,
  }));

  return (
    <Modal closeModal={close} small>
      <div className="modal-content">
        <div className="modal-title">Assign Conversation(s) to Agent</div>
        <div className="modal-form agent-form">
          {isLoadingAgents && (
            <span className="loading-users">Loading users... Please wait</span>
          )}
          <div className="input-container mb-30">
            <label>Select agent</label>
            <Select
              classNamePrefix="select_container"
              value={agent}
              options={agentOptions}
              onChange={onChange}
            />
          </div>
          <div className="modal-form-action mt-50">
            <Button type="button" className="secondary-button" onClick={close}>
              Cancel
            </Button>
            <Button
              className="primary-button"
              disabled={!agentOptions || isAssigningToAgent}
              onClick={assignOnClick}
            >
              {isAssigningToAgent
                ? "Assigning... Please wait"
                : "Assign to Agent"}
            </Button>
          </div>

          {assignConversationOutcome && (
            <>
              <br />
              <FormNotifications
                type={assignConversationOutcome}
                message={assignConversationMessage}
              />
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default AssignToAgent;
