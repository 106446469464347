import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { decode } from "html-entities";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

import {
  createSignatureAction,
  updateSignatureAction,
} from "../../../redux/actions/Signature";

import { Close } from "../../../assets/vectors";
import { TextField, Button } from "../../../components/FormElements";
import FormNotifications from "../../../components/Notifications";

const SignatureForm = ({
  selectedSignature,
  closeSelection,
  getSignatureAction,
}) => {
  const dispatch = useDispatch();

  const {
    createSignatureOutcome,
    createSignatureMessage,
    updateSignatureOutcome,
    updateSignatureMessage,
    deleteSignatureOutcome,
    deleteSignatureMessage,
    isCreatingSignature,
    isUpdatingSignature,
  } = useSelector((state) => state.signature);

  const [formData, setFormData] = useState({
    name: selectedSignature?.name || "",
    active: selectedSignature?.active || false,
  });
  const [signature, setSignature] = useState(
    decode(selectedSignature?.content, { level: "html5" }) || ""
  );
  const [placeholders, setPlaceholders] = useState([
    { name: "{{companyName}}", value: "{{companyName}}", active: true },
    { name: "{{agentName}}", value: "{{agentName}}", active: true },
  ]);

  const editor = useRef(null);

  const getSunEditorInstance = (sunEditor) => {
    editor.current = sunEditor;
  };

  const handleToggleSetAsDefault = () => {
    setFormData({
      ...formData,
      active: !formData?.active,
    });
  };

  const handleChange = ({ target }) => {
    setFormData({
      ...formData,
      [target.name]: target.value,
    });
  };

  const addToContent = (text) => {
    editor.current.insertHTML(text);
  };

  const handleRichTextChange = (content) => {
    setSignature(content);
    checkContentForPlaceholders(content);
  };

  const checkContentForPlaceholders = (content) => {
    let arrToUpdate = placeholders;

    if (content?.includes(arrToUpdate?.[0]?.value)) {
      arrToUpdate[0].active = false;
    } else {
      arrToUpdate[0].active = true;
    }

    if (content?.includes(arrToUpdate?.[1]?.value)) {
      arrToUpdate[1].active = false;
    } else {
      arrToUpdate[1].active = true;
    }

    setPlaceholders(arrToUpdate);
  };

  const checkFormValidity = () => {
    let value = true;
    if (
      !formData?.name ||
      !signature ||
      isCreatingSignature ||
      isUpdatingSignature
    ) {
      return false;
    }

    return value;
  };

  const submitForm = (e) => {
    e.preventDefault();

    const payload = {
      name: formData?.name,
      content: signature,
      active: formData?.active,
    };

    if (selectedSignature) {
      dispatch(updateSignatureAction(payload, selectedSignature?.id)).then(
        (res) => {
          if (res === 200) {
            closeSelection();
            getSignatureAction();
          }
        }
      );
    } else {
      dispatch(createSignatureAction(payload)).then((res) => {
        if (res === 201) {
          closeSelection();
          getSignatureAction();
        }
      });
    }
  };

  return (
    <div className="signature-form">
      <div className="header">
        <div className="start">
          <span className="close-signature" onClick={closeSelection}>
            <Close />
          </span>
          <p>{selectedSignature ? "Edit" : "New"} Signature</p>
        </div>

        <div className="toggle-switch">
          <span onClick={handleToggleSetAsDefault}>Set as default</span>
          <label className="switch">
            <input
              type="checkbox"
              checked={formData?.active}
              onChange={handleToggleSetAsDefault}
            />
            <span className="slider round"></span>
          </label>
        </div>
      </div>

      <div className="content">
        <TextField
          label="Signature Name"
          name="name"
          value={formData?.name}
          onChange={(e, valid) => handleChange(e, valid)}
          className="mb-25"
        />
        <div className="input-container mb-25">
          <label>Placeholders</label>

          <div className="input placeholders">
            {placeholders?.map((item, i) => (
              <span
                key={i}
                title="Click to add"
                onClick={
                  item?.active ? () => addToContent(item?.value) : () => {}
                }
                className={item?.active ? "active" : ""}
              >
                {item?.name}
              </span>
            ))}
          </div>
        </div>

        <SunEditor
          getSunEditorInstance={getSunEditorInstance}
          name="content"
          placeholder="Type your response here..."
          setOptions={{
            fontSize: [14, 16, 20, 24, 26],
            imageUrlInput: false,
            imageWidth: "50",
            imageHeight: "50",
            buttonList: [
              [
                "fontSize",
                "bold",
                "italic",
                "underline",
                "align",
                "list",
                "link",
                "image",
              ],
            ],
          }}
          defaultValue={signature}
          onChange={handleRichTextChange}
          // onLoad={() => checkContentForPlaceholders(editorDefaultValue)}
        />

        <div className="form-action">
          <Button
            type="submit"
            className="primary-button"
            disabled={
              !checkFormValidity() || isCreatingSignature || isUpdatingSignature
            }
            onClick={submitForm}
          >
            {isCreatingSignature || isUpdatingSignature
              ? "Processing..."
              : selectedSignature
              ? "Save Changes"
              : "Add Signature"}
          </Button>
        </div>

        {createSignatureOutcome && (
          <FormNotifications
            type={createSignatureOutcome}
            message={createSignatureMessage}
          />
        )}
        {updateSignatureOutcome && (
          <FormNotifications
            type={updateSignatureOutcome}
            message={updateSignatureMessage}
          />
        )}
        {deleteSignatureOutcome && (
          <FormNotifications
            type={deleteSignatureOutcome}
            message={deleteSignatureMessage}
          />
        )}
      </div>
    </div>
  );
};

export default SignatureForm;
