import { getRequest, patchRequest, deleteRequest } from "../../api";

import {
  GET_CUSTOM_FIELDS_LOADER,
  GET_CUSTOM_FIELDS_RESPONSE,
  CREATE_CUSTOM_FIELDS_LOADER,
  CREATE_CUSTOM_FIELDS_RESPONSE,
  CUSTOM_FIELDS_RESET,
  UPDATE_CUSTOM_FIELDS_LOADER,
  UPDATE_CUSTOM_FIELDS_RESPONSE,
  DELETE_CUSTOM_FIELDS_LOADER,
  DELETE_CUSTOM_FIELDS_RESPONSE,
} from "../constants";

import { getRequestError } from "../../utils/functions";

export const createCustomFieldsLoader = (isCreatingCustomFields) => ({
  type: CREATE_CUSTOM_FIELDS_LOADER,
  isCreatingCustomFields,
});

export const createCustomFieldsResponse = (
  createCustomFieldsOutcome,
  createCustomFieldsMessage
) => ({
  type: CREATE_CUSTOM_FIELDS_RESPONSE,
  createCustomFieldsOutcome,
  createCustomFieldsMessage,
});

export const getCustomFieldsLoader = (isLoadingCustomFields) => ({
  type: GET_CUSTOM_FIELDS_LOADER,
  isLoadingCustomFields,
});

export const getCustomFieldsResponse = (
  customFieldsOutcome,
  customFieldsData,
  customFieldsMeta,
  customFieldsMessage
) => ({
  type: GET_CUSTOM_FIELDS_RESPONSE,
  customFieldsOutcome,
  customFieldsData,
  customFieldsMeta,
  customFieldsMessage,
});

export const customFieldsReset = () => ({
  type: CUSTOM_FIELDS_RESET,
});

export const updateCustomFieldsLoader = (isUpdatingCustomFields) => ({
  type: UPDATE_CUSTOM_FIELDS_LOADER,
  isUpdatingCustomFields,
});

export const updateCustomFieldsResponse = (
  updateCustomFieldsOutcome,
  updateCustomFieldsMessage
) => ({
  type: UPDATE_CUSTOM_FIELDS_RESPONSE,
  updateCustomFieldsOutcome,
  updateCustomFieldsMessage,
});

export const deleteCustomFieldsLoader = (isDeletingCustomFields) => ({
  type: DELETE_CUSTOM_FIELDS_LOADER,
  isDeletingCustomFields,
});

export const deleteCustomFieldsResponse = (
  deleteCustomFieldsOutcome,
  deleteCustomFieldsMessage
) => ({
  type: DELETE_CUSTOM_FIELDS_RESPONSE,
  deleteCustomFieldsOutcome,
  deleteCustomFieldsMessage,
});

export const createCustomFieldsAction = (data, category) => {
  return async (dispatch) => {
    dispatch(customFieldsReset());
    dispatch(createCustomFieldsLoader(true));

    try {
      const res = await patchRequest({
        url: `/firms/${category}/custom-fields`,
        data,
        token: true,
      });

      if (res?.status === 200) {
        dispatch(createCustomFieldsResponse("success", res?.data?.message));
        dispatch(createCustomFieldsLoader(false));
        return res?.status;
      }
    } catch (error) {
      const message = getRequestError(error);

      dispatch(createCustomFieldsResponse("error", message));
      dispatch(createCustomFieldsLoader(false));
    }
  };
};

export const getCustomFieldsAction = (category = "", params = {}) => {
  return async (dispatch) => {
    dispatch(customFieldsReset());
    dispatch(getCustomFieldsLoader(true));

    try {
      const res = await getRequest({
        url: `/firms/${category}/custom-fields`,
        params,
        token: true,
      });

      const { data } = res.data;

      dispatch(getCustomFieldsResponse("success", data || {}, {}, ""));
      dispatch(getCustomFieldsLoader(false));
    } catch (error) {
      const message = getRequestError(error);

      dispatch(getCustomFieldsResponse("error", [], {}, message));
      dispatch(getCustomFieldsLoader(false));
    }
  };
};

export const updateCustomFieldsAction = (data, category, id) => {
  return async (dispatch) => {
    dispatch(customFieldsReset());
    dispatch(updateCustomFieldsLoader(true));

    try {
      const res = await patchRequest({
        url: `/firms/${category}/custom-field/${id}`,
        data,
        token: true,
      });

      if (res?.status === 200) {
        dispatch(updateCustomFieldsResponse("success", res?.data?.message));
        dispatch(updateCustomFieldsLoader(false));
        return res?.status;
      }
    } catch (error) {
      const message = getRequestError(error);

      dispatch(updateCustomFieldsResponse("error", message));
      dispatch(updateCustomFieldsLoader(false));
    }
  };
};

export const deleteCustomFieldsAction = (category, id) => {
  return async (dispatch) => {
    dispatch(customFieldsReset());
    dispatch(deleteCustomFieldsLoader(true));

    try {
      const res = await deleteRequest({
        url: `/firms/${category}/custom-field/${id}`,
        token: true,
      });

      if (res?.status === 200) {
        dispatch(deleteCustomFieldsResponse("success", res?.data?.message));
        dispatch(deleteCustomFieldsLoader(false));
        return res?.status;
      }
    } catch (error) {
      const message = getRequestError(error);

      dispatch(deleteCustomFieldsResponse("error", message));
      dispatch(deleteCustomFieldsLoader(false));
    }
  };
};
