import {
  getRequest,
  postRequest,
  patchRequest,
  deleteRequest,
} from "../../api";

import {
  CREATE_SAVED_RESPONSES_LOADER,
  CREATE_SAVED_RESPONSES_RESPONSE,
  CREATE_SAVED_RESPONSES_RESET,
  UPDATE_SAVED_RESPONSES_LOADER,
  UPDATE_SAVED_RESPONSES_RESPONSE,
  UPDATE_SAVED_RESPONSES_RESET,
  GET_SAVED_RESPONSES_LOADER,
  GET_SAVED_RESPONSES_RESPONSE,
  GET_SAVED_RESPONSES_RESET,
  DELETE_SAVED_RESPONSES_LOADER,
  DELETE_SAVED_RESPONSES_RESPONSE,
  DELETE_SAVED_RESPONSES_RESET,
} from "../constants";

import { getRequestError } from "../../utils/functions";

export const createSavedResponsesLoader = (isCreatingSavedResponse) => ({
  type: CREATE_SAVED_RESPONSES_LOADER,
  isCreatingSavedResponse,
});

export const createSavedResponsesResponse = (
  createSavedResponsesOutcome,
  createSavedResponsesMessage
) => ({
  type: CREATE_SAVED_RESPONSES_RESPONSE,
  createSavedResponsesOutcome,
  createSavedResponsesMessage,
});

export const createSavedResponsesReset = () => ({
  type: CREATE_SAVED_RESPONSES_RESET,
});

export const updateSavedResponsesLoader = (isUpdatingSavedResponse) => ({
  type: UPDATE_SAVED_RESPONSES_LOADER,
  isUpdatingSavedResponse,
});

export const updateSavedResponsesResponse = (
  updateSavedResponsesOutcome,
  updateSavedResponsesMessage
) => ({
  type: UPDATE_SAVED_RESPONSES_RESPONSE,
  updateSavedResponsesOutcome,
  updateSavedResponsesMessage,
});

export const updateSavedResponsesReset = () => ({
  type: UPDATE_SAVED_RESPONSES_RESET,
});

export const getSavedResponsesLoader = (isLoadingSavedResponses) => ({
  type: GET_SAVED_RESPONSES_LOADER,
  isLoadingSavedResponses,
});

export const getSavedResponsesResponse = (
  savedResponsesOutcome,
  savedResponsesData,
  savedResponsesMeta,
  savedResponsesMessage
) => ({
  type: GET_SAVED_RESPONSES_RESPONSE,
  savedResponsesOutcome,
  savedResponsesData,
  savedResponsesMeta,
  savedResponsesMessage,
});

export const getSavedResponsesReset = () => ({
  type: GET_SAVED_RESPONSES_RESET,
});

export const deleteSavedResponsesLoader = (isDeletingSavedResponse) => ({
  type: DELETE_SAVED_RESPONSES_LOADER,
  isDeletingSavedResponse,
});

export const deleteSavedResponsesResponse = (
  deleteSavedResponsesOutcome,
  deleteSavedResponsesMessage
) => ({
  type: DELETE_SAVED_RESPONSES_RESPONSE,
  deleteSavedResponsesOutcome,
  deleteSavedResponsesMessage,
});

export const deleteSavedResponsesReset = () => ({
  type: DELETE_SAVED_RESPONSES_RESET,
});

export const createSavedResponsesAction = (data) => {
  return async (dispatch) => {
    dispatch(createSavedResponsesReset());
    dispatch(createSavedResponsesLoader(true));

    try {
      const res = await postRequest({
        url: "/firms/saved-replies",
        data,
        token: true,
      });

      if (res?.status === 201) {
        dispatch(createSavedResponsesResponse("success", ""));
        dispatch(createSavedResponsesLoader(false));
        return res?.status;
      }
    } catch (error) {
      const message = getRequestError(error);

      dispatch(createSavedResponsesResponse("error", message));
      dispatch(createSavedResponsesLoader(false));
    }
  };
};

export const updateSavedResponsesAction = (data, id) => {
  return async (dispatch) => {
    dispatch(updateSavedResponsesReset());
    dispatch(updateSavedResponsesLoader(true));

    try {
      const res = await patchRequest({
        url: `/firms/saved-replies/${id}`,
        data,
        token: true,
      });

      if (res?.status === 200) {
        dispatch(updateSavedResponsesResponse("success", ""));
        dispatch(updateSavedResponsesLoader(false));
        return res?.status;
      }
    } catch (error) {
      const message = getRequestError(error);

      dispatch(updateSavedResponsesResponse("error", message));
      dispatch(updateSavedResponsesLoader(false));
    }
  };
};

export const getSavedResponsesAction = (params = {}) => {
  return async (dispatch) => {
    dispatch(getSavedResponsesReset());
    dispatch(getSavedResponsesLoader(true));

    try {
      const res = await getRequest({
        url: "/firms/saved-replies",
        params,
        token: true,
      });

      const { data, offset, limit, total } = res.data;

      dispatch(
        getSavedResponsesResponse("success", data, { offset, limit, total }, "")
      );
      dispatch(getSavedResponsesLoader(false));
    } catch (error) {
      const message = getRequestError(error);

      dispatch(getSavedResponsesResponse("error", [], {}, message));
      dispatch(getSavedResponsesLoader(false));
    }
  };
};

export const deleteSavedResponsesAction = (id) => {
  return async (dispatch) => {
    dispatch(deleteSavedResponsesReset());
    dispatch(deleteSavedResponsesLoader(true));

    try {
      const res = await deleteRequest({
        url: `/firms/saved-replies/${id}`,
        token: true,
      });

      if (res?.status === 202) {
        dispatch(deleteSavedResponsesResponse("success", ""));
        dispatch(deleteSavedResponsesLoader(false));
        return res?.status;
      }
    } catch (error) {
      const message = getRequestError(error);

      dispatch(deleteSavedResponsesResponse("error", message));
      dispatch(deleteSavedResponsesLoader(false));
    }
  };
};
