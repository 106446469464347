import mixpanel from "mixpanel-browser";
import { getRequest, postRequest, deleteRequest } from "../../api";

import {
  GET_INTEGRATIONS_LOADER,
  GET_INTEGRATIONS_RESPONSE,
  GET_INTEGRATIONS_RESET,
  CREATE_INTEGRATION_LOADER,
  CREATE_INTEGRATION_RESPONSE,
} from "../constants";

import { getRequestError } from "../../utils/functions";

export const getIntegrationsLoader = (isLoadingIntegrations) => ({
  type: GET_INTEGRATIONS_LOADER,
  isLoadingIntegrations,
});

export const createIntegrationLoader = (isCreatingIntegration) => ({
  type: CREATE_INTEGRATION_LOADER,
  isCreatingIntegration,
});

export const getIntegrationsResponse = (
  integrationsOutcome,
  integrationsData,
  integrationsMeta,
  integrationsMessage
) => ({
  type: GET_INTEGRATIONS_RESPONSE,
  integrationsOutcome,
  integrationsData,
  integrationsMeta,
  integrationsMessage,
});

export const createIntegrationResponse = (
  createIntegrationOutcome,
  createIntegrationMessage
) => ({
  type: CREATE_INTEGRATION_RESPONSE,
  createIntegrationOutcome,
  createIntegrationMessage,
});

export const getIntegrationsReset = () => ({
  type: GET_INTEGRATIONS_RESET,
});

export const getIntegrationsAction = (params = {}) => {
  return async (dispatch) => {
    dispatch(getIntegrationsReset());
    dispatch(getIntegrationsLoader(true));

    try {
      const res = await getRequest({
        url: `/integrations/all`,
        params,
        token: true,
      });

      const { data } = res.data;

      dispatch(
        getIntegrationsResponse("success", data.length ? data : [], {}, "")
      );
      dispatch(getIntegrationsLoader(false));

      return data;
    } catch (error) {
      // const message = getRequestError(error);

      dispatch(getIntegrationsResponse("error", [], {}, ""));
      dispatch(getIntegrationsLoader(false));
    }
  };
};

export const getFirmIntegrationsAction = (params = {}) => {
  return async (dispatch) => {
    dispatch(getIntegrationsReset());
    dispatch(getIntegrationsLoader(true));

    try {
      const res = await getRequest({
        url: `/integrations`,
        params,
        token: true,
      });

      const { data } = res.data;

      dispatch(getIntegrationsLoader(false));
      return data || [];
    } catch (error) {
      const message = getRequestError(error);
      console.log(message);

      dispatch(getIntegrationsLoader(false));
    }
  };
};

export const getIntegrationRequestAction = (data) => {
  return async (dispatch) => {
    dispatch(createIntegrationLoader(true));

    try {
      const res = await getRequest({
        url: data?.url,
        token: true,
        params: data?.params,
      });

      dispatch(createIntegrationLoader(false));
      return res;
    } catch (error) {
      const message = getRequestError(error);

      dispatch(createIntegrationResponse("error", message));
      dispatch(createIntegrationLoader(false));
    }
  };
};

export const getCustomerGroupsAction = (params = {}) => {
  return async () => {
    try {
      const res = await getRequest({
        url: `/customers/groups/${params?.id}/customer`,
        token: true,
      });

      const { data } = res.data;

      return data || [];
    } catch (error) {
      const message = getRequestError(error);
      console.log(message);
    }
  };
};

export const postSubscribeIntegrationAction = (data) => {
  return async (dispatch) => {
    dispatch(createIntegrationLoader(true));

    try {
      const res = await postRequest({
        url: data?.url,
        data: data?.requestData,
        token: true,
      });

      data?.completedSetupFor &&
        mixpanel.track("New Integration", {
          integration: data?.completedSetupFor,
        });

      dispatch(createIntegrationResponse("success", ""));
      dispatch(createIntegrationLoader(false));
      return res;
    } catch (error) {
      const message = getRequestError(error);

      dispatch(createIntegrationResponse("error", message));
      dispatch(createIntegrationLoader(false));
    }
  };
};

export const deactivateIntegrationAction = (data) => {
  return async (dispatch) => {
    dispatch(createIntegrationLoader(true));

    try {
      const res = await deleteRequest({
        url: `/integrations/${data?.id}`,
        token: true,
      });

      data?.deactivatedIntegrationFor &&
        mixpanel.track("Deactivate Integration", {
          integration: data?.deactivatedIntegrationFor,
        });

      dispatch(createIntegrationResponse("success", ""));
      dispatch(createIntegrationLoader(false));
      return res;
    } catch (error) {
      const message = getRequestError(error);

      dispatch(createIntegrationResponse("error", message));
      dispatch(createIntegrationLoader(false));
    }
  };
};

export const getTicketsAction = (data = {}) => {
  return async () => {
    try {
      const res = await getRequest({
        url: data?.url,
        token: true,
        params: data?.params,
      });

      return res?.data?.data;
    } catch (error) {
      const message = getRequestError(error);

      return message || "";
    }
  };
};
