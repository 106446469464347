import React, { useState, useEffect, Suspense } from "react";
import ErrorBoundary from "../ErrorBoundary";
import { useParams, useLocation } from "react-router-dom";

import {
  LoginForm,
  SignupForm,
  VerifyForm,
  ForgotPassword,
  ResetPassword,
  SetPassword,
} from "./";
import classNames from "classnames";

const Auth = () => {
  let { token } = useParams();
  let { pathname } = useLocation();
  const [activeForm, setActiveForm] = useState("login");
  const [resetPasswordToken, setResetPasswordToken] = useState("");
  const [passwordToken, setPasswordToken] = useState("");

  useEffect(() => {
    if (pathname.includes("sign-up")) setActiveForm("sign-up");
    if (pathname.includes("verify") && token) {
      setActiveForm("verify");
    }
    if (pathname.includes("auth/confirmation") && token) {
      setPasswordToken(token);
      setActiveForm("set-password");
    }
    if (pathname.includes("auth/reset-password") && token) {
      setResetPasswordToken(token);
      setActiveForm("reset-password");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleActiveForm = (value) => {
    setActiveForm(value);
  };

  return (
    <>
      <ErrorBoundary>
        <Suspense fallback={<div>...</div>}>
          <div
            className={classNames("auth-container", {
              "sign-up": pathname === "/sign-up",
              verify: pathname.includes("verify"),
            })}
          >
            {pathname.includes("sign-up") || pathname.includes("verify") ? (
              <>
                {activeForm === "sign-up" && (
                  <SignupForm setActiveForm={handleActiveForm} />
                )}
                {activeForm === "verify" && (
                  <VerifyForm token={token} setActiveForm={handleActiveForm} />
                )}
              </>
            ) : (
              <>
                {activeForm === "login" ? (
                  <LoginForm setActiveForm={handleActiveForm} />
                ) : activeForm === "forgot-password" ? (
                  <ForgotPassword setActiveForm={handleActiveForm} />
                ) : activeForm === "reset-password" ? (
                  <ResetPassword
                    token={resetPasswordToken}
                    setActiveForm={handleActiveForm}
                  />
                ) : activeForm === "set-password" ? (
                  <SetPassword
                    token={passwordToken}
                    setActiveForm={handleActiveForm}
                  />
                ) : (
                  ""
                )}
              </>
            )}
          </div>
        </Suspense>
      </ErrorBoundary>
    </>
  );
};

export default Auth;
