import React from "react";

export default () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="#474F58"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
        d="M17.783.75H1.967C1.295.75.75 1.295.75 1.967v14.6c0 .672.545 1.216 1.217 1.216h15.816c.672 0 1.217-.544 1.217-1.216v-14.6C19 1.295 18.455.75 17.783.75z"
      ></path>
      <path
        stroke="#474F58"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
        d="M15.35 3.183H5.617c-.672 0-1.217.545-1.217 1.217v9.733c0 .672.545 1.217 1.217 1.217h9.733c.672 0 1.217-.545 1.217-1.217V4.4c0-.672-.545-1.217-1.217-1.217zM3.184 17.783V19M16.566 17.783V19M3.184 6.833h2.433M3.184 11.7h2.433"
      ></path>
      <path
        stroke="#474F58"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
        d="M10.483 11.457a2.19 2.19 0 100-4.38 2.19 2.19 0 000 4.38zM10.483 7.077v-1.46M10.483 12.917v-1.46M12.674 9.267h1.46M6.833 9.267h1.46M8.241 11.848l.872-.872M8.241 6.686l.872.871M12.726 11.848l-.873-.872M12.726 6.686l-.873.871"
      ></path>
    </svg>
)