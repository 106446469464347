import React, { useEffect, useState, createRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";

import { postRequest } from "../../../api";
import {
  getWidgetsAction,
  postWidgetAction,
  patchWidgetAction,
} from "../../../redux/actions/Widget";
import { getFirmChannelsAction } from "../../../redux/actions/Channels";

import {
  ColorPicker,
  Button,
  FileInput,
  TextField,
  Select,
} from "../../../components/FormElements";
import Toaster from "../../../components/Toaster";

import { WidgetPreview } from ".";

import { Copy, Spinner, AngleLeft, Close } from "../../../assets/vectors";

import {
  getChannelIdentifierIcon,
  getRequestError,
} from "../../../utils/functions";

const WidgetForm = ({ closeForm, widgetData }) => {
  const dispatch = useDispatch();
  const { isSubmittingWidget } = useSelector((state) => state.widget);
  const { firmChannelsData } = useSelector((state) => state.channels);

  const [isUploadingAvatar, setIsUploadingAvatar] = useState(false);
  const [allChannelsList, setAllChannelsList] = useState([]);
  const [toaster, setToaster] = useState({});
  const [formData, setFormData] = useState({
    color: "#39206A",
    logo: "https://s3.eu-west-3.amazonaws.com/oneroute.asb.ng/logo-solid.svg",
    headText: "Hello!",
    subText: "Send us a message via any of the platforms below.",
    toolTip: "Hi, how can we be of help?",
    channels: [],
  });

  let codeInput = createRef();

  useEffect(() => {
    dispatch(getFirmChannelsAction());

    if (widgetData) setFormData(widgetData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const activeChannelsData = firmChannelsData?.filter(
    (x) => x?.status === "ACTIVE"
  );

  useEffect(() => {
    const channels = [
      {
        name: "Livechat",
        options:
          activeChannelsData?.filter((x) => x?.medium === "WIDGET") || [],
      },
      {
        name: "Whatsapp",
        options:
          activeChannelsData?.filter((x) => x?.medium === "WHATSAPP") || [],
      },
      {
        name: "Facebook",
        options:
          activeChannelsData?.filter((x) => x?.medium === "FACEBOOK") || [],
      },
      {
        name: "Twitter",
        options:
          activeChannelsData?.filter((x) => x?.medium === "TWITTER") || [],
      },
      {
        name: "Email",
        options: activeChannelsData?.filter((x) => x?.medium === "EMAIL") || [],
      },
      {
        name: "Instagram",
        options:
          activeChannelsData?.filter((x) => x?.medium === "INSTAGRAM") || [],
      },
    ];

    setAllChannelsList(channels);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firmChannelsData]);

  const handleChange = ({ target }) => {
    setFormData({ ...formData, [target.name]: target.value });
  };

  const handleAvatarChange = async (uploadData, { file }) => {
    if (file) {
      var fileData = new FormData();
      fileData.append("media", file);
      let payload = {};
      payload = fileData;

      setIsUploadingAvatar(true);

      try {
        const res = await postRequest({
          url: "/utils/upload",
          data: payload,
          token: true,
        });
        if (res?.status === 200) {
          setFormData({ ...formData, logo: res?.data?.data });
          openToaster("success", res?.data?.message);
          setIsUploadingAvatar(false);
        }
      } catch (error) {
        const message = getRequestError(error);
        openToaster("error", message);
        setIsUploadingAvatar(false);
      }
    }
  };

  const handleSelectChannel = ({ target }) => {
    var channels = formData?.channels;

    const selected = activeChannelsData?.find(({ id }) => id === target?.value);

    if (selected?.medium === "WIDGET") {
      const objIndex = channels.findIndex((obj) => obj.name === "Livechat");
      const objToAdd = {
        id: selected?.id,
        name: "Livechat",
        identifier: selected?.name,
        to: selected?.id,
      };

      if (objIndex >= 0) {
        channels[objIndex] = objToAdd;
      } else {
        channels = [...channels, objToAdd];
      }
    }
    if (selected?.medium === "WHATSAPP") {
      const objIndex = channels.findIndex((obj) => obj.name === "Whatsapp");
      const objToAdd = {
        id: selected?.id,
        name: "Whatsapp",
        to: `https://wa.me/${selected?.phone}`,
      };

      if (objIndex >= 0) {
        channels[objIndex] = objToAdd;
      } else {
        channels = [...channels, objToAdd];
      }
    }
    if (selected?.medium === "FACEBOOK") {
      const objIndex = channels.findIndex((obj) => obj.name === "Facebook");
      const objToAdd = {
        id: selected?.id,
        name: "Facebook",
        to: `https://facebook.com/messages/t/${selected?.identifier}`,
      };

      if (objIndex >= 0) {
        channels[objIndex] = objToAdd;
      } else {
        channels = [...channels, objToAdd];
      }
    }
    if (selected?.medium === "TWITTER") {
      const objIndex = channels.findIndex((obj) => obj.name === "Twitter");
      const objToAdd = {
        id: selected?.id,
        name: "Twitter",
        to: `https://twitter.com/${selected?.name}`,
      };

      if (objIndex >= 0) {
        channels[objIndex] = objToAdd;
      } else {
        channels = [...channels, objToAdd];
      }
    }
    if (selected?.medium === "EMAIL") {
      const objIndex = channels.findIndex((obj) => obj.name === "Email");
      const objToAdd = {
        id: selected?.id,
        name: "Email",
        to: `mailto:${selected?.credentials?.email}?subject=Hi&body=Hello`,
      };

      if (objIndex >= 0) {
        channels[objIndex] = objToAdd;
      } else {
        channels = [...channels, objToAdd];
      }
    }
    if (selected?.medium === "INSTAGRAM") {
      const objIndex = channels.findIndex((obj) => obj.name === "Instagram");
      const objToAdd = {
        id: selected?.id,
        name: "Instagram",
        to: `https://instagram.com/${selected?.name}`,
      };

      if (objIndex >= 0) {
        channels[objIndex] = objToAdd;
      } else {
        channels = [...channels, objToAdd];
      }
    }

    setFormData({ ...formData, channels });
  };

  const handleRemoveChannel = (id) => {
    var channels = formData?.channels;
    channels = channels.filter((x) => x.id !== id);

    setFormData({ ...formData, channels });
  };

  const saveWidgetConfig = async () => {
    const { color, logo, headText, subText, toolTip, channels } = formData;

    const requestData = {
      color,
      logo,
      headText: headText,
      subText: subText,
      toolTip: toolTip,
      channels,
    };

    if (widgetData) {
      dispatch(
        patchWidgetAction({ id: widgetData?.id, data: requestData })
      ).then((res) => {
        if (res?.success === true) {
          dispatch(getWidgetsAction());
          closeForm();
        }
      });
    } else {
      dispatch(postWidgetAction(requestData)).then((res) => {
        if (res?.success === true) {
          dispatch(getWidgetsAction());
          closeForm();
        }
      });
    }
  };

  const codeSnippet = () => {
    const { id } = formData;

    return `
    <div class="oneroute_widget" data-widgetid=${
      "" || JSON.stringify(id)
    }></div><link href="https://bit.ly/3Qq6CbZ" rel="stylesheet" />
    <script src="https://bit.ly/3rY6K8k"></script>`;
  };

  const copyCode = () => {
    navigator.clipboard.writeText(codeSnippet());
    openToaster("success", "Code copied to clipboard!");
  };

  const openToaster = (status, content) => {
    setToaster({
      open: "open",
      status,
      content,
    });
  };

  const closeToaster = () => {
    setToaster({
      ...toaster,
      open: "closed",
    });
  };

  const { color, logo, headText, subText, toolTip, channels } = formData;

  return (
    <div className="settings-page-container omnichannel-container">
      <div className="page-header">
        <span className="back" onClick={() => closeForm()}>
          <AngleLeft />
        </span>
        <span className="title">{widgetData ? "Edit" : "New"} Widget</span>
      </div>
      <div className="integrations-container">
        <div className="integration-section widget-details">
          <div className="integration-section-title">Customise Widget</div>
          <div>
            <div>
              <div className="input-container mb-30">
                <label>Bot Avatar</label>
                <div className="logo-container">
                  <div className="logo-uploader">
                    {!isUploadingAvatar ? (
                      <img
                        src={logo}
                        alt=""
                        className="logo-preview"
                        style={{ background: color }}
                      />
                    ) : (
                      <div className="logo-preview">
                        <Spinner />
                      </div>
                    )}
                    <p>
                      Image should be 100px by 100px. JPG, GIF or PNG. Max size
                      of 800K
                    </p>
                  </div>
                  <FileInput
                    icon={
                      <span className="secondary-button small">
                        Upload Image
                      </span>
                    }
                    onFileLoad={handleAvatarChange}
                    onFileError={() => {}}
                  />
                </div>
              </div>
              <ColorPicker
                label="Widget color"
                onChange={handleChange}
                className="mb-30"
                value={color}
                name="color"
              />
              <div className="widget-channels no-bottom-border">
                <div className="integration-section-title">Introduction</div>
                <TextField
                  label="Greeting"
                  type="text"
                  name="headText"
                  className="mb-30"
                  value={headText}
                  onChange={handleChange}
                />
                <TextField
                  label="Welcome message"
                  type="text"
                  name="subText"
                  className="mb-30"
                  value={subText}
                  onChange={handleChange}
                />
                <TextField
                  label="Tooltip"
                  type="text"
                  name="toolTip"
                  value={toolTip}
                  onChange={handleChange}
                />
              </div>
              <div className="widget-channels">
                <div className="integration-section-title">Channels</div>
                {allChannelsList?.map(({ name, options }, index) => {
                  var defaultSelection = channels?.find(
                    (channel) => channel?.name === name
                  )?.id;

                  var selectedOption = options?.find(
                    (option) => option?.id === defaultSelection
                  )?.id;

                  return (
                    <div
                      key={`channel-item-${index}`}
                      className={classNames("widget-channel-item", {
                        disabled: options?.length < 1,
                      })}
                    >
                      <div>
                        <div className="channel-details">
                          <span className="icon">
                            <img src={getChannelIdentifierIcon(name)} alt="" />
                          </span>
                          <span className="text">{name}</span>
                        </div>
                      </div>
                      <div>
                        <Select
                          placeholder="Select"
                          name="channel"
                          options={options?.map((x) => ({
                            label: x?.name,
                            value: x?.id,
                          }))}
                          value={selectedOption || ""}
                          onChange={(e) => handleSelectChannel(e)}
                          className="mb-35"
                          disabled={options?.length < 1}
                        />
                        {selectedOption && (
                          <span
                            className="clear-selection"
                            onClick={() => handleRemoveChannel(selectedOption)}
                          >
                            <Close />
                          </span>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
              {widgetData && (
                <div className="widget-code-snippet">
                  <div className="integration-section-title">Code Snippet</div>
                  <div className="description">
                    Copy and paste this code before the {"</body>"} tag to add
                    this widget to your website.{" "}
                    <span className="primary-link blue">Show me how</span>
                  </div>
                  <div className="code-snippet">
                    <div className="copy-code" onClick={copyCode}>
                      <span className="icon">
                        <Copy />
                      </span>
                      <span className="text">Copy code</span>
                    </div>
                    <code className="language-javascript">{codeSnippet()}</code>
                    <input
                      type="text"
                      defaultValue={codeSnippet()}
                      ref={codeInput}
                      style={{
                        opacity: 0,
                        position: "absolute",
                        pointerEvents: "none",
                      }}
                    />
                  </div>
                </div>
              )}
              <div className="widget-code-snippet save-configs">
                <div className="integration-section-title">
                  Save Widget Configuration
                </div>
                <div className="description">
                  Click on {widgetData ? "Save changes" : "Add widget"} below to
                  save your widget configuration.
                </div>
                <Button
                  className="primary-button"
                  onClick={() => saveWidgetConfig()}
                  disabled={
                    formData?.channels?.length < 1 || isSubmittingWidget
                  }
                >
                  {isSubmittingWidget
                    ? "Processing..."
                    : widgetData
                    ? "Save changes"
                    : "Add widget"}
                </Button>
              </div>
            </div>
          </div>
        </div>

        <WidgetPreview formData={formData} />
      </div>

      <Toaster {...toaster} closeToaster={closeToaster} />
    </div>
  );
};

export default WidgetForm;
