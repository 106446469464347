import {
  getRequest,
  postRequest,
  patchRequest,
  deleteRequest,
} from "../../api";

import {
  WORKING_HOURS_LOADER,
  WORKING_HOURS_SUBMITTING,
  WORKING_HOURS_RESPONSE,
  WORKING_HOUR_RESPONSE,
} from "../constants";
import { getRequestError } from "../../utils/functions";

export const isLoadingWorkingHours = (isLoadingWorkingHours) => ({
  type: WORKING_HOURS_LOADER,
  isLoadingWorkingHours,
});

export const isSubmittingWorkingHours = (isSubmittingWorkingHours) => ({
  type: WORKING_HOURS_SUBMITTING,
  isSubmittingWorkingHours,
});

export const workingHoursResponse = (
  workingHoursOutcome,
  workingHoursData,
  workingHoursMessage
) => ({
  type: WORKING_HOURS_RESPONSE,
  workingHoursOutcome,
  workingHoursData,
  workingHoursMessage,
});

export const workingHourResponse = (
  workingHourOutcome,
  workingHourData,
  workingHourMessage
) => ({
  type: WORKING_HOUR_RESPONSE,
  workingHourOutcome,
  workingHourData,
  workingHourMessage,
});

export const getWorkingHoursAction = (params = {}) => {
  return async (dispatch) => {
    dispatch(isLoadingWorkingHours(true));

    try {
      const res = await getRequest({
        url: "/firms/officehours",
        params: params?.params,
        token: true,
      });
      dispatch(workingHoursResponse("success", res.data?.data, ""));
      dispatch(isLoadingWorkingHours(false));
    } catch (error) {
      dispatch(isLoadingWorkingHours(false));

      const message = getRequestError(error);
      dispatch(workingHoursResponse("error", null, message));
    }
  };
};

export const postWorkingHoursAction = (data) => {
  return async (dispatch) => {
    dispatch(isSubmittingWorkingHours(true));

    try {
      const res = await postRequest({
        url: "/firms/officehours",
        data,
        token: true,
      });

      dispatch(isSubmittingWorkingHours(false));
      return res?.data;
    } catch (error) {
      dispatch(isSubmittingWorkingHours(false));

      const message = getRequestError(error);
      dispatch(workingHourResponse("error", null, message));
    }
  };
};

export const patchWorkingHoursAction = (data) => {
  return async (dispatch) => {
    dispatch(isSubmittingWorkingHours(true));

    try {
      const res = await patchRequest({
        url: `/firms/officehours/${data?.id}`,
        data: data?.data,
        token: true,
      });

      dispatch(isSubmittingWorkingHours(false));
      return res?.data;
    } catch (error) {
      dispatch(isSubmittingWorkingHours(false));

      const message = getRequestError(error);
      dispatch(workingHourResponse("error", null, message));
    }
  };
};

export const deleteWorkingHoursAction = (data) => {
  return async (dispatch) => {
    dispatch(isSubmittingWorkingHours(true));

    try {
      const res = await deleteRequest({
        url: `/firms/officehours/${data?.id}`,
        token: true,
      });

      dispatch(isSubmittingWorkingHours(false));
      return res?.data;
    } catch (error) {
      dispatch(isSubmittingWorkingHours(false));

      const message = getRequestError(error);
      dispatch(workingHourResponse("error", null, message));
    }
  };
};
