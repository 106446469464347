import React, { Component } from "react";
import { connect } from "react-redux";
// import * as moment from "moment";
import { withRouter } from "react-router-dom";
import Select from "react-select";

import {
  getGroupsAction,
  addToGroupAction,
  createGroupsReset,
} from "../../redux/actions/Groups";

import {
  getCustomersAction,
  createCustomersAction,
  deleteCustomersAction,
} from "../../redux/actions/Customers";

import { getAllChannelsAction } from "../../redux/actions/Channels";

import { getCustomFieldsAction } from "../../redux/actions/CustomFields";

import { Button, TextField } from "../../components/FormElements";
import Table from "../../components/Table";
import Modal from "../../components/Modal";
import ImportCustomers from "./ImportCustomers";
import FilterCustomers from "./FilterCustomers";
import OptionsDropdown from "../../components/OptionsDropdown";
import FormNotifications from "../../components/Notifications";

import GroupForm from "../Settings/Groups/GroupForm";

import {
  CircularPlus,
  Search,
  Pencil,
  Trash,
  File,
  Filter,
  People,
  AngleDown,
} from "../../assets/vectors";
import { getRequestError, getInitials } from "../../utils/functions";
import { customersPermissions } from "../../utils/permissions";

import CustomerForm from "./CustomerForm";
import { getRequest, postRequest } from "../../api";
import Toaster from "../../components/Toaster";
import Avatar from "../../components/Avatar";

class Customers extends Component {
  state = {
    currentTab: "confirmed",
    isCustomerFormActive: false,
    selectedCustomer: null,
    isConfirmationModalActive: false,
    isImportContactModalActive: false,
    isFilterModalActive: false,
    isNewGroupFormActive: false,
    isAddToGroupModalActive: false,
    currentStep: 1,
    searchValue: "",
    selectedGroups: null,
    selectedCustomers: [],
    query: {
      "page[size]": 25,
      "page[number]": 1,
      groups: [],
      mediums: [],
      startDate: null,
      endDate: null,
    },
    customerInfo: true,
  };

  removeCustomerInfo = () => {
    this.setState({
      customerInfo: false,
    });
  };

  switchTab = (currentTab) => {
    this.setState(
      {
        currentTab,
        query: {
          ...this.state.query,
          status: currentTab,
        },
      },
      () => this.props.getCustomersAction(this.state.query)
    );
  };

  toggleCustomerForm = (selectedCustomer) => {
    this.setState({
      isCustomerFormActive: !this.state.isCustomerFormActive,
      selectedCustomer,
    });
  };

  optionsAction = (type, row) => {
    if (type === "edit") {
      this.props.history.push(`/customers/${row.id}`);
    } else if (type === "delete") {
      this.toggleConfirmationModal(row);
    } else if (type === "resend-invite") {
      this.resendInvite(row);
    }
  };

  actionOptionsSelect = (type) => {
    if (type === "add_to_group") {
      this.toggleAddToGroupModal();
    }
  };

  handleChange = ({ target }, valid) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [target.name]: {
          value: target.value,
          valid,
        },
      },
    });
  };

  handleSelectAllCustomers = () => {
    const { customersData } = this.props;
    const { selectedCustomers } = this.state;

    if (selectedCustomers?.length > 0) {
      this.setState({
        selectedCustomers: [],
      });
    } else {
      this.setState({
        selectedCustomers: customersData?.map((x) => x?.id),
      });
    }
  };

  handleSelectCustomer = ({ target }) => {
    const { customersData } = this.props;
    const { selectedCustomers } = this.state;

    const isSelected = customersData?.find((x) => x?.id === target?.value)?.id;

    const hasAlreadyBeenSelected = selectedCustomers?.find(
      (x) => x === target?.value
    )
      ? true
      : false;

    if (hasAlreadyBeenSelected) {
      this.setState({
        selectedCustomers: selectedCustomers?.filter(
          (x) => x !== target?.value
        ),
      });
    } else {
      this.setState({
        selectedCustomers: [...(selectedCustomers || []), isSelected],
      });
    }
  };

  toggleAddToGroupModal = () => {
    this.setState({
      isAddToGroupModalActive: !this.state.isAddToGroupModalActive,
    });
  };

  addCustomersToGroup = () => {
    const { selectedGroups, selectedCustomers } = this.state;

    this.props.addToGroupAction(
      {
        groups: selectedGroups?.map((x) => x?.value),
        customers: selectedCustomers,
      },
      () => {
        setTimeout(() => {
          this.toggleAddToGroupModal();
          this.props.createGroupsReset();
          this.setState({
            selectedGroups: [],
            selectedCustomers: [],
          });
        }, 1000);
      }
    );
  };

  resendInvite = async ({ id }) => {
    this.setState({
      isResendingInvite: true,
    });

    try {
      await getRequest({
        url: `auth/resend-invite/${id}`,
        token: true,
      });

      this.openToaster("success", "Invitation resent successfully!");

      this.setState({
        isResendingInvite: true,
      });

      document.body.click();
    } catch (error) {
      console.log(error);

      const message = getRequestError(error);

      this.openToaster("error", message);

      this.setState({
        isResendingInvite: true,
      });
    }
  };

  toggleConfirmationModal = (selectedForDeletion) => {
    this.setState({
      selectedForDeletion,
      isConfirmationModalActive: !this.state.isConfirmationModalActive,
    });
  };

  deleteCustomer = () => {
    const { selectedForDeletion } = this.state;
    const { deleteCustomersAction } = this.props;

    deleteCustomersAction(selectedForDeletion.id);
  };

  filterTable = (type, value) => {
    const { query, searchValue } = this.state;

    const payload = {
      ...query,
      groups: query?.groups?.map((x) => x?.value),
      mediums: query?.mediums?.map((x) => x?.label),
      "dateRange[from]": query?.startDate,
      "dateRange[to]": query?.endDate,
      "page[number]": 1,
      searchQuery: searchValue,
      [type]: value,
    };

    delete payload?.startDate;
    delete payload?.endDate;

    this.props.getCustomersAction(payload);
  };

  paginateTable = (pageNumber) => {
    const { query } = this.state;

    this.setState({
      query: {
        ...query,
        "page[number]": pageNumber,
      },
    });

    const payload = {
      ...query,
      groups: query?.groups?.map((x) => x?.value),
      mediums: query?.mediums?.map((x) => x?.label),
      "dateRange[from]": query?.startDate,
      "dateRange[to]": query?.endDate,
      "page[number]": pageNumber,
    };

    delete payload?.startDate;
    delete payload?.endDate;

    this.props.getCustomersAction(payload);
  };

  exportCustomers = async () => {
    this.setState({
      isExporting: true,
    });

    try {
      await postRequest({
        url: "/customers/export",
        token: true,
      });

      this.openToaster(
        "success",
        "Kindly check your mail for the exported data"
      );

      this.setState({
        isExporting: false,
      });
    } catch (error) {
      const message = getRequestError();
      console.log(error, message);

      this.setState({
        isExporting: false,
      });
    }
  };

  openToaster = (status, content) => {
    this.setState({
      toaster: {
        open: "open",
        status,
        content,
      },
    });
  };

  closeToaster = () => {
    this.setState({
      toaster: {
        ...this.state.toaster,
        open: "closed",
      },
    });
  };

  moreOptions = () => {
    var options = [];

    if (customersPermissions?.update || customersPermissions?.modifyAll) {
      options = [
        ...options,
        {
          label: (
            <div>
              <span className="icon">
                <Pencil />
              </span>{" "}
              <span className="text">Edit</span>
            </div>
          ),
          value: "edit",
        },
      ];
    }

    if (customersPermissions?.delete) {
      options = [
        ...options,
        {
          label: (
            <div className="red">
              <span className="icon">
                <Trash />
              </span>{" "}
              <span className="text">Delete</span>
            </div>
          ),
          value: "delete",
        },
      ];
    }

    return options;
  };

  actionOptions = () => {
    var options = [];

    if (customersPermissions?.update || customersPermissions?.modifyAll) {
      options = [
        ...options,
        {
          label: (
            <div>
              <span className="icon">
                <People />
              </span>{" "}
              <span className="text">Add to group</span>
            </div>
          ),
          value: "add_to_group",
        },
      ];
    }

    return options;
  };

  componentDidMount() {
    const customer_id = this.props.location?.state?.customer_id;
    if (customer_id) {
      this.setState({
        searchValue: customer_id,
      });
      this.filterTable("searchQuery", customer_id);
    } else {
      this.props.getCustomersAction(this.state.query);
    }

    this.props.getGroupsAction();
    this.props.getCustomFieldsAction("customer");
  }

  componentDidUpdate(prevProps) {
    const {
      createCustomersOutcome,
      updateCustomersOutcome,
      deleteCustomersOutcome,
      getCustomersAction,
    } = this.props;

    if (
      prevProps.createCustomersOutcome !== createCustomersOutcome &&
      createCustomersOutcome === "success"
    ) {
      getCustomersAction(this.state.query);
    }

    if (
      prevProps.updateCustomersOutcome !== updateCustomersOutcome &&
      updateCustomersOutcome === "success"
    ) {
      this.toggleCustomerForm();
      getCustomersAction(this.state.query);
    }

    if (
      prevProps.deleteCustomersOutcome !== deleteCustomersOutcome &&
      deleteCustomersOutcome === "success"
    ) {
      this.toggleConfirmationModal();
      getCustomersAction(this.state.query);
    }
  }

  render() {
    const {
      isCustomerFormActive,
      isConfirmationModalActive,
      isImportContactModalActive,
      isFilterModalActive,
      isNewGroupFormActive,
      isAddToGroupModalActive,
      selectedGroups,
      selectedCustomer,
      customerInfo,
      searchValue,
      selectedCustomers,
      toaster,
      isExporting,
      query,
    } = this.state;
    const {
      isLoadingCustomers,
      isDeletingCustomer,
      customersData,
      customersMeta,
      groupsData,
      isCreatingGroup,
      createGroupsOutcome,
      createGroupsMessage,
      firmChannelsData,
      history,
    } = this.props;

    const groupOptions = groupsData?.map((x) => ({
      label: x?.name,
      value: x?.id,
    }));

    const columns = [
      {
        id: "",
        label:
          customersPermissions?.update || customersPermissions?.modifyAll ? (
            <div className="checkbox_container">
              <input
                name="recipients"
                className="input"
                onChange={(e) => this.handleSelectAllCustomers(e)}
                type="checkbox"
                checked={selectedCustomers?.length === customersData?.length}
                required
              />
            </div>
          ) : (
            ""
          ),
        render: ({ id }) =>
          customersPermissions?.update || customersPermissions?.modifyAll ? (
            <div className="checkbox_container">
              <input
                name="recipients"
                id={id}
                value={id}
                className="input"
                onChange={(e) => this.handleSelectCustomer(e)}
                type="checkbox"
                checked={selectedCustomers?.find((x) => x === id)}
                required
              />
            </div>
          ) : (
            ""
          ),
      },
      {
        id: "",
        label: "Name",
        render: ({ id, name }) => (
          <div
            className="customer-name"
            onClick={() => history.push(`/customers/${id}`)}
          >
            <Avatar text={getInitials(`${name}`)} solid />
            <span className="primary-link blue">{name}</span>
          </div>
        ),
      },
      {
        id: "phone",
        label: "Phone number",
      },
      {
        id: "",
        label: "Email",
        render: ({ email }) => (
          <span className="text-lowercase">{email ? email : "N/A"}</span>
        ),
      },
      {
        id: "",
        label: "Actions",
        thClassName: "text-center",
        render: (row) => (
          <div className="text-center">
            <OptionsDropdown
              onClick={(val) => {
                this.optionsAction(val, row);
              }}
              options={this.moreOptions()}
            />
          </div>
        ),
      },
    ];

    return (
      <div className="page-content full">
        <div className="settings-page-container">
          <div className="page-header">
            <span className="title">Customers</span>
          </div>
          {customerInfo && (
            <div className="settings-page-info">
              <div>
                <span className="title">Who are Customers?</span>
                <span className="description">
                  Customers are the users of your organization’s products or
                  service.
                </span>
                <Button
                  className="plain-button"
                  onClick={this.removeCustomerInfo}
                >
                  Got it
                </Button>
              </div>
              <div className="image">
                <img
                  src={require("../../assets/images/customer-girl.png").default}
                  alt=""
                />
              </div>
            </div>
          )}

          <div className="table-container agents-table customers-table">
            <div className="table-filter">
              <div className="table-search-filter">
                <Button
                  className="filter-button"
                  onClick={() =>
                    this.setState({
                      isFilterModalActive: true,
                    })
                  }
                >
                  <span className="icon fill">
                    <Filter />
                  </span>
                  <span>Filter</span>
                </Button>
                <TextField
                  placeholder="Search customers"
                  defaultValue={searchValue}
                  leftIcon={<Search />}
                  onChange={(e) =>
                    this.setState({
                      searchValue: e?.target?.value,
                    })
                  }
                  onKeyDown={(e) => {
                    this.filterTable("searchQuery", e.target.value);
                  }}
                />
              </div>
              <div className="table-other-filters">
                {(customersPermissions.create ||
                  customersPermissions.update ||
                  customersPermissions.modifyAll) && (
                  <Button
                    className="filter-button"
                    onClick={() =>
                      this.setState({
                        isImportContactModalActive: true,
                      })
                    }
                    disabled={isExporting}
                  >
                    <span className="icon fill">
                      <File />
                    </span>
                    <span>Import customers</span>
                  </Button>
                )}
                <Button
                  className="filter-button"
                  onClick={() => this.exportCustomers()}
                  disabled={isExporting}
                >
                  <span className="icon fill">
                    <File />
                  </span>
                  <span>Export as CSV</span>
                </Button>
                {(customersPermissions.create ||
                  customersPermissions.update ||
                  customersPermissions.modifyAll) && (
                  <Button
                    className="primary-button mr-15"
                    onClick={() => this.toggleCustomerForm()}
                  >
                    <span className="icon">
                      <CircularPlus />
                    </span>
                    <span>Add Customer</span>
                  </Button>
                )}
              </div>
            </div>

            <div className="table-actions">
              {selectedCustomers?.length > 0 && (
                <div className="bulk_actions_dropdown">
                  <OptionsDropdown
                    dropdownPlaceholder={
                      <>
                        Action <AngleDown />
                      </>
                    }
                    onClick={(val) => {
                      this.actionOptionsSelect(val);
                    }}
                    options={this.actionOptions()}
                  />
                </div>
              )}

              <h6 className="table-counter">
                {customersMeta?.count} Customers
              </h6>
            </div>
            <Table
              loading={isLoadingCustomers}
              columns={columns}
              rows={customersData}
              meta={customersMeta}
              editRow={this.toggleCustomerForm}
              deleteRow={this.toggleConfirmationModal}
              paginateTable={this.paginateTable}
            />
          </div>

          {isFilterModalActive && (
            <FilterCustomers
              close={() =>
                this.setState({
                  isFilterModalActive: false,
                })
              }
              firmChannelsData={firmChannelsData}
              groupOptions={groupOptions}
              groups={query?.groups}
              mediums={query?.mediums}
              startDate={query?.startDate}
              endDate={query?.endDate}
              onGroupsChange={(groups) => {
                this.setState({
                  query: {
                    ...query,
                    groups,
                  },
                });
              }}
              onChannelsChange={(mediums) => {
                this.setState({
                  query: {
                    ...query,
                    mediums,
                  },
                });
              }}
              onDateChange={(start, end) => {
                this.setState({
                  query: {
                    ...query,
                    startDate: start,
                    endDate: end,
                  },
                });
              }}
              clearDatePicker={() => {
                this.setState({
                  query: {
                    ...query,
                    startDate: null,
                    endDate: null,
                  },
                });
              }}
              filterCustomers={this.filterTable}
            />
          )}

          {isImportContactModalActive && (
            <ImportCustomers
              close={() =>
                this.setState({
                  isImportContactModalActive: false,
                })
              }
            />
          )}

          {isCustomerFormActive && (
            <CustomerForm
              customerData={selectedCustomer}
              toggleCustomerForm={this.toggleCustomerForm}
            />
          )}

          {isAddToGroupModalActive && (
            <Modal closeModal={this.toggleAddToGroupModal} small>
              <div className="modal-content">
                <div className="modal-title">Add Customer(s) to Group(s)</div>
                <div className="modal-form agent-form">
                  <div className="input-container mb-30">
                    <label>Select group(s)</label>
                    <Select
                      classNamePrefix="select_container"
                      value={selectedGroups}
                      options={[
                        ...(groupOptions || []),
                        { label: "+ Add new group", value: "add_new_group" },
                      ]}
                      onChange={(selections) => {
                        if (
                          selections?.find((x) => x?.value === "add_new_group")
                        ) {
                          this.setState({
                            isNewGroupFormActive: true,
                          });
                        } else {
                          this.setState({
                            selectedGroups: selections,
                          });
                        }
                      }}
                      isClearable
                      isMulti
                    />
                  </div>
                  <div className="modal-form-action mt-50">
                    <Button
                      type="button"
                      className="secondary-button"
                      onClick={this.toggleAddToGroupModal}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="primary-button"
                      disabled={!selectedGroups || isCreatingGroup}
                      onClick={this.addCustomersToGroup}
                    >
                      {isCreatingGroup ? "Processing..." : "Add to Group"}
                    </Button>
                  </div>

                  {createGroupsOutcome && (
                    <>
                      <br />
                      <FormNotifications
                        type={createGroupsOutcome}
                        message={createGroupsMessage}
                      />
                    </>
                  )}
                </div>
              </div>
            </Modal>
          )}

          {isNewGroupFormActive && (
            <GroupForm
              toggleGroupForm={() =>
                this.setState({
                  isNewGroupFormActive: false,
                })
              }
            />
          )}

          {isConfirmationModalActive && (
            <Modal closeModal={this.toggleConfirmationModal} small>
              <div className="modal-content confirmation-modal">
                <div className="modal-icon red">
                  <Trash />
                </div>
                <div className="modal-title">
                  Are you sure you want to delete this customer?
                </div>
                <div className="modal-text">This action cannot be reversed</div>
                <div className="modal-action">
                  <Button
                    className="secondary-button red"
                    type="button"
                    onClick={this.deleteCustomer}
                    disabled={isDeletingCustomer}
                  >
                    {isDeletingCustomer ? "Processing..." : "Delete Customer"}
                  </Button>
                  <Button
                    className="plain-button black"
                    type="button"
                    onClick={this.toggleConfirmationModal}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </Modal>
          )}

          <Toaster {...toaster} closeToaster={this.closeToaster} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ customers, groups, channels }) => ({
  ...customers,
  ...groups,
  ...channels,
});

export default withRouter(
  connect(mapStateToProps, {
    getCustomersAction,
    createCustomersAction,
    deleteCustomersAction,
    getGroupsAction,
    addToGroupAction,
    createGroupsReset,
    getCustomFieldsAction,
    getAllChannelsAction,
  })(Customers)
);
