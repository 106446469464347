import React, { Component } from "react";
import { connect } from "react-redux";
import * as moment from "moment";

import {
  getTagsAction,
  createTagsAction,
  deleteTagsAction,
} from "../../../redux/actions/Tags";

import { Button, TextField } from "../../../components/FormElements";
import Table from "../../../components/Table";
import Modal from "../../../components/Modal";
import OptionsDropdown from "../../../components/OptionsDropdown";

import {
  CircularPlus,
  Search,
  Pencil,
  Trash,
  Tag,
} from "../../../assets/vectors";
import { tagsPermissions } from "../../../utils/permissions";

import TagForm from "./TagForm";
import SettingsEmptyState from "../../../components/SettingsEmptyState";

class Tags extends Component {
  state = {
    isTagFormActive: false,
    selectedTag: null,
    isConfirmationModalActive: false,
    isManageCategoriesActive: false,
    query: {
      limit: 25,
      page: 1,
    },
    tagsInfo: true,
    newItem: false,
    newItemValue: "",
    currentEditCategory: "",
  };

  removeInfo = () => {
    this.setState({
      tagsInfo: false,
    });
  };

  addNewItem = (e) => {
    e.stopPropagation();

    this.setState({
      newItem: true,
    });
  };

  handleNewItemChange = ({ target }) => {
    this.setState({
      newItemValue: target.value,
    });
  };

  editCategory = (currentEditCategory) => {
    this.setState({
      currentEditCategory,
    });
  };

  handleEditItemChange = ({ target }) => {
    this.setState({
      currentEditCategory: target.value,
    });
  };

  toggleTagForm = (selectedTag) => {
    this.setState({
      isTagFormActive: !this.state.isTagFormActive,
      selectedTag,
    });
  };

  optionsAction = (type, row) => {
    if (type === "edit") {
      this.toggleTagForm(row);
    } else if (type === "delete") {
      this.toggleConfirmationModal(row);
    }
  };

  handleChange = ({ target }, valid) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [target.name]: {
          value: target.value,
          valid,
        },
      },
    });
  };

  toggleConfirmationModal = (selectedForDeletion) => {
    this.setState({
      selectedForDeletion,
      isConfirmationModalActive: !this.state.isConfirmationModalActive,
    });
  };

  toggleManageCategories = () => {
    this.setState({
      isManageCategoriesActive: !this.state.isManageCategoriesActive,
    });
  };

  deleteTag = () => {
    const { selectedForDeletion } = this.state;
    const { deleteTagsAction } = this.props;

    deleteTagsAction(selectedForDeletion.id);
  };

  filterTable = (type, value) => {
    this.setState(
      {
        query: {
          ...this.state.query,
          [type]: value,
        },
      },
      () => this.props.getTagsAction(this.state.query)
    );
  };

  paginateTable = (number) => {
    const { query } = this.state;

    this.setState(
      {
        query: {
          ...query,
          page: number,
        },
      },
      () => this.props.getTagsAction(this.state.query)
    );
  };

  moreOptions = () => {
    var options = [];

    if (tagsPermissions?.update || tagsPermissions?.modifyAll) {
      options = [
        ...options,
        {
          label: (
            <div>
              <span className="icon">
                <Pencil />
              </span>{" "}
              <span className="text">Edit</span>
            </div>
          ),
          value: "edit",
        },
      ];
    }

    if (tagsPermissions?.delete) {
      options = [
        ...options,
        {
          label: (
            <div className="red">
              <span className="icon">
                <Trash />
              </span>{" "}
              <span className="text">Delete</span>
            </div>
          ),
          value: "delete",
        },
      ];
    }

    return options;
  };

  componentDidMount() {
    this.props.getTagsAction(this.state.query);
  }

  componentDidUpdate(prevProps) {
    const {
      createTagsOutcome,
      updateTagsOutcome,
      deleteTagsOutcome,
      getTagsAction,
    } = this.props;

    if (
      prevProps.createTagsOutcome !== createTagsOutcome &&
      createTagsOutcome === "success"
    ) {
      getTagsAction(this.state.query);
    }

    if (
      prevProps.updateTagsOutcome !== updateTagsOutcome &&
      updateTagsOutcome === "success"
    ) {
      this.toggleTagForm();
      getTagsAction(this.state.query);
    }

    if (
      prevProps.deleteTagsOutcome !== deleteTagsOutcome &&
      deleteTagsOutcome === "success"
    ) {
      this.toggleConfirmationModal();
      getTagsAction(this.state.query);
    }
  }

  render() {
    const {
      isTagFormActive,
      isConfirmationModalActive,
      selectedTag,
      tagsInfo,
    } = this.state;
    const { isLoadingTags, isDeletingTag, tagsData, tagsMeta } = this.props;

    const columns = [
      {
        id: "name",
        label: "Name",
        render: ({ name, colorCode }) => (
          <div className="tag-name">
            <span className="tag-icon">
              <span
                className="tag-bg"
                style={{ background: `${colorCode}` }}
              ></span>
              <Tag color={colorCode} />
            </span>
            <span>{name}</span>
          </div>
        ),
      },
      {
        id: "createdAt",
        label: "Date Created",
        render: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY"),
      },
      {
        id: "",
        label: "Actions",
        thClassName: "text-center",
        render: (row) => (
          <div className="text-center">
            <OptionsDropdown
              onClick={(val) => {
                this.optionsAction(val, row);
              }}
              options={this.moreOptions()}
            />
          </div>
        ),
      },
    ];

    return (
      <div className="settings-page-container">
        <div className="page-header">
          <span className="title">Tags</span>
        </div>
        {tagsInfo && (
          <div className="settings-page-info">
            <div>
              <span className="title">What are Tags?</span>
              <span className="description">
                Tags allow you label conversations.
              </span>
              <Button className="plain-button" onClick={this.removeInfo}>
                Got it
              </Button>
            </div>
            <div className="image">
              <img
                src={require("../../../assets/images/stickers.png").default}
                alt=""
              />
            </div>
          </div>
        )}
        <div className="table-container tags-table">
          {!isLoadingTags && !tagsData.length ? (
            <SettingsEmptyState
              title="You have no tags yet"
              description="When you create a tag, it'll show up here."
              action={
                <>
                  {(tagsPermissions.create || tagsPermissions.modifyAll) && (
                    <Button
                      className="primary-button"
                      onClick={() => this.toggleTagForm()}
                    >
                      <span className="icon">
                        <CircularPlus />
                      </span>
                      <span>New tag</span>
                    </Button>
                  )}
                </>
              }
            />
          ) : (
            <>
              <div className="table-filter">
                <div className="table-search-filter">
                  <TextField
                    placeholder="Search tags"
                    leftIcon={<Search />}
                    onChange={(e) => this.filterTable("q", e.target.value)}
                  />
                </div>
                <div className="table-other-filters">
                  {(tagsPermissions.create || tagsPermissions.modifyAll) && (
                    <Button
                      className="primary-button small"
                      onClick={() => this.toggleTagForm()}
                    >
                      <span className="icon">
                        <CircularPlus />
                      </span>
                      <span>New tag</span>
                    </Button>
                  )}
                </div>
              </div>

              <Table
                loading={isLoadingTags}
                columns={columns}
                rows={tagsData}
                meta={tagsMeta}
                editRow={this.toggleTagForm}
                deleteRow={this.toggleConfirmationModal}
                paginateTable={this.paginateTable}
              />
            </>
          )}
        </div>

        {isTagFormActive && (
          <TagForm tagData={selectedTag} toggleTagForm={this.toggleTagForm} />
        )}

        {isConfirmationModalActive && (
          <Modal closeModal={this.toggleConfirmationModal} small>
            <div className="modal-content confirmation-modal">
              <div className="modal-icon red">
                <Trash />
              </div>
              <div className="modal-title">
                Are you sure you want to delete this tag?
              </div>
              <div className="modal-text">This action cannot be reversed</div>
              <div className="modal-action">
                <Button
                  className="secondary-button red"
                  type="button"
                  onClick={this.deleteTag}
                  disabled={isDeletingTag}
                >
                  {isDeletingTag ? "Processing..." : "Delete Tag"}
                </Button>
                <Button
                  className="plain-button black"
                  type="button"
                  onClick={this.toggleConfirmationModal}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ tags }) => tags;

export default connect(mapStateToProps, {
  getTagsAction,
  createTagsAction,
  deleteTagsAction,
})(Tags);
