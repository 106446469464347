import React from "react";

export default () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M18.125 15.625H9.375L4.375 19.375V15.625H1.875C1.54348 15.625 1.22554 15.4933 0.991117 15.2589C0.756696 15.0245 0.625 14.7065 0.625 14.375V1.875C0.625 1.54348 0.756696 1.22554 0.991117 0.991117C1.22554 0.756696 1.54348 0.625 1.875 0.625H18.125C18.4565 0.625 18.7745 0.756696 19.0089 0.991117C19.2433 1.22554 19.375 1.54348 19.375 1.875V14.375C19.375 14.7065 19.2433 15.0245 19.0089 15.2589C18.7745 15.4933 18.4565 15.625 18.125 15.625Z"
        stroke="black"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.125 6.5625H15.625"
        stroke="black"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.125 10.3125H15.625"
        stroke="black"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.375 6.25C4.31319 6.25 4.25277 6.26833 4.20138 6.30267C4.14999 6.337 4.10994 6.38581 4.08629 6.44291C4.06264 6.50001 4.05645 6.56285 4.0685 6.62347C4.08056 6.68408 4.11033 6.73977 4.15403 6.78347C4.19773 6.82718 4.25342 6.85694 4.31403 6.869C4.37465 6.88105 4.43749 6.87486 4.49459 6.85121C4.55169 6.82756 4.6005 6.78751 4.63483 6.73612C4.66917 6.68473 4.6875 6.62431 4.6875 6.5625C4.6875 6.47962 4.65458 6.40013 4.59597 6.34153C4.53737 6.28292 4.45788 6.25 4.375 6.25Z"
        stroke="black"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.375 10C4.31319 10 4.25277 10.0183 4.20138 10.0527C4.14999 10.087 4.10994 10.1358 4.08629 10.1929C4.06264 10.25 4.05645 10.3128 4.0685 10.3735C4.08056 10.4341 4.11033 10.4898 4.15403 10.5335C4.19773 10.5772 4.25342 10.6069 4.31403 10.619C4.37465 10.6311 4.43749 10.6249 4.49459 10.6012C4.55169 10.5776 4.6005 10.5375 4.63483 10.4861C4.66917 10.4347 4.6875 10.3743 4.6875 10.3125C4.6875 10.2296 4.65458 10.1501 4.59597 10.0915C4.53737 10.0329 4.45788 10 4.375 10Z"
        stroke="black"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
