import React from "react";

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="5"
    height="8"
    fill="none"
    viewBox="0 0 5 8"
  >
    <path
      fill="#686C73"
      d="M3.075 4.25L.231 6.794a.655.655 0 000 1 .857.857 0 001.117 0L4.782 4.72a.617.617 0 000-.942L1.348.707a.857.857 0 00-1.117 0 .655.655 0 000 .999L3.075 4.25z"
    ></path>
  </svg>
);