import React from "react";

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      fill="#8F99A6"
      d="M8 1.6a.531.531 0 00-.377.156L3.356 6.023a.533.533 0 00.378.91h8.533a.533.533 0 00.377-.91L8.377 1.756A.531.531 0 008 1.6zM3.734 9.067a.533.533 0 00-.378.91l4.267 4.267a.532.532 0 00.754 0l4.267-4.267a.533.533 0 00-.377-.91H3.734z"
    ></path>
  </svg>
);
