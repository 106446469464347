import { getRequest, patchRequest, postRequest } from "../../api";

import {
  ACCOUNT_SETTINGS_LOADER,
  ACCOUNT_SETTINGS_SUBMITTING,
  ACCOUNT_SETTINGS_RESPONSE,
  ACCOUNT_INFO_RESPONSE,
} from "../constants";
import { getRequestError } from "../../utils/functions";

export const isLoadingAccountSettings = (isLoadingAccountSettings) => ({
  type: ACCOUNT_SETTINGS_LOADER,
  isLoadingAccountSettings,
});

export const isSubmittingAccountSettings = (isSubmittingAccountSettings) => ({
  type: ACCOUNT_SETTINGS_SUBMITTING,
  isSubmittingAccountSettings,
});

export const accountSettingsResponse = (
  accountSettingsOutcome,
  accountSettingsData,
  accountSettingsMessage
) => ({
  type: ACCOUNT_SETTINGS_RESPONSE,
  accountSettingsOutcome,
  accountSettingsData,
  accountSettingsMessage,
});

export const accountInfoResponse = (
  accountInfoOutcome,
  accountInfoData,
  accountInfoMessage
) => ({
  type: ACCOUNT_INFO_RESPONSE,
  accountInfoOutcome,
  accountInfoData,
  accountInfoMessage,
});

export const getFirmConfigAction = (params = {}) => {
  return async (dispatch) => {
    dispatch(isLoadingAccountSettings(true));

    try {
      const res = await getRequest({
        url: "/firms/config",
        token: true,
      });
      dispatch(accountSettingsResponse("success", res.data?.data, ""));
      dispatch(isLoadingAccountSettings(false));
    } catch (error) {
      dispatch(isLoadingAccountSettings(false));

      const message = getRequestError(error);
      dispatch(accountSettingsResponse("error", null, message));
    }
  };
};

export const patchFirmConfigAction = (data) => {
  return async (dispatch) => {
    dispatch(isSubmittingAccountSettings(true));

    try {
      const res = await patchRequest({
        url: "/firms/config",
        data,
        token: true,
      });

      dispatch(isSubmittingAccountSettings(false));
      return res?.data;
    } catch (error) {
      const message = getRequestError(error);
      console.log(message);

      dispatch(isSubmittingAccountSettings(false));
    }
  };
};

export const patchFirmOnboardingAction = (data, success) => {
  return async (dispatch) => {
    dispatch(isSubmittingAccountSettings(true));

    try {
      await patchRequest({
        url: `/firms/onboarding-step`,
        data,
        token: true,
      });

      dispatch(isSubmittingAccountSettings(false));

      success && success();
    } catch (error) {
      const message = getRequestError(error);
      console.log(message);

      dispatch(isSubmittingAccountSettings(false));
    }
  };
};

export const getFirmAccountInfoAction = (params = {}) => {
  return async (dispatch) => {
    dispatch(isLoadingAccountSettings(true));

    try {
      const res = await getRequest({
        url: "/firms/info",
        token: true,
      });
      dispatch(accountInfoResponse("success", res.data?.data, ""));
      dispatch(isLoadingAccountSettings(false));
    } catch (error) {
      dispatch(isLoadingAccountSettings(false));

      const message = getRequestError(error);
      dispatch(accountInfoResponse("error", null, message));
    }
  };
};

export const patchFirmAccountInfoAction = (data) => {
  return async (dispatch) => {
    dispatch(isSubmittingAccountSettings(true));

    try {
      const res = await patchRequest({
        url: "/firms",
        data,
        token: true,
      });

      dispatch(isSubmittingAccountSettings(false));
      return res?.data;
    } catch (error) {
      const message = getRequestError(error);
      console.log(message);

      dispatch(isSubmittingAccountSettings(false));
    }
  };
};

export const postFirmUnitsConfigAction = (data) => {
  return async (dispatch) => {
    dispatch(isSubmittingAccountSettings(true));

    try {
      const res = await postRequest({
        url: "/units/config",
        data,
        token: true,
      });

      dispatch(isSubmittingAccountSettings(false));
      return res?.data;
    } catch (error) {
      const message = getRequestError(error);
      console.log(message);

      dispatch(isSubmittingAccountSettings(false));
    }
  };
};
