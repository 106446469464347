import React from "react";

import {
  Avatar,
  ProfileSettings,
  CustomerCare,
  Bot,
  AwaitingConvo,
} from "../../assets/vectors";

export const insightsMenu = [
  {
    label: "Conversations",
    icon: <Avatar />,
    value: "/insights/conversations",
  },
  {
    label: "Agents",
    icon: <ProfileSettings />,
    value: "/insights/agents",
  },
  {
    label: "Customers",
    icon: <CustomerCare />,
    value: "/insights/customers",
  },
  {
    label: "Chatbot",
    icon: <Bot />,
    value: "/insights/chatbot",
  },
  {
    label: "Logs",
    icon: <AwaitingConvo />,
    value: "/insights/logs",
  },
];
