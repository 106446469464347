import React, { Component } from "react";
import { connect } from "react-redux";

import { forgotPasswordAction } from "../../redux/actions/Auth";

import Carousel from "./Carousel";
import { TextField, Button } from "../../components/FormElements";
import FormNotifications from "../../components/Notifications";

import { getFormValues } from "../../utils/functions";

class ForgotPassword extends Component {
  state = {
    formData: {
      email: {
        value: "",
        valid: false,
      },
    },
  };

  onChange = ({ target }, valid) => {
    const { name, value } = target;

    this.setState({
      formData: {
        ...this.state.formData,
        [name]: { value, valid },
      },
    });
  };

  validateForm = () => {
    return Object.values(this.state.formData).every((val) => !!val);
  };

  forgotPassword = (e) => {
    e.preventDefault();
    const formData = getFormValues(this.state.formData);

    this.props.forgotPasswordAction(formData);
  };

  render() {
    const {
      isForgotPasswordLoading,
      setActiveForm,
      forgotPasswordOutcome,
      forgotPasswordMessage,
    } = this.props;

    return (
      <div className="register_page_container">
        <div className="side left_side">
          <Carousel />
        </div>

        <div className="side form_container">
          <div className="title">Forgot Password?</div>
          <div className="description">
            Please provide your email address. A reset link will be sent to your
            mail.
          </div>

          <form className="form" onSubmit={this.forgotPassword}>
            <TextField
              className="mb-25"
              type="email"
              name="email"
              label="Email Address"
              placeholder="david@oneroute.io"
              onChange={(e, valid) => this.onChange(e, valid)}
            />
            <div className="login-actions mb-25">
              <span
                className="primary-link"
                onClick={() => setActiveForm("login")}
              >
                Back to Login
              </span>
            </div>
            <div className="mb-25">
              <Button
                disabled={!this.validateForm() || isForgotPasswordLoading}
                className="primary-button full small"
              >
                Get Reset Link
              </Button>
            </div>
            <div className="sign-up-link">
              Don’t have an account yet?{" "}
              <span
                className="primary-link bold"
                onClick={() => window.location.assign("/sign-up")}
              >
                Register
              </span>
            </div>
            {forgotPasswordOutcome === "success" && (
              <>
                <br />
                <FormNotifications
                  type="success"
                  message={forgotPasswordMessage}
                />
              </>
            )}
            {forgotPasswordOutcome === "error" && (
              <>
                <br />
                <FormNotifications
                  type="error"
                  message={forgotPasswordMessage}
                />
              </>
            )}
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => auth;

export default connect(mapStateToProps, { forgotPasswordAction })(
  ForgotPassword
);
