import {
  GET_MENTIONS_LOADER,
  GET_MORE_MENTIONS_LOADER,
  GET_MENTIONS_RESPONSE,
  GET_MENTION_LOADER,
  SET_MENTION_REPLY_SENDING_STATUS,
  CREATE_MENTIONS_UNREAD_COUNT,
  GET_MENTIONS_UNREAD_COUNT,
} from "../constants";

const INIT_STATE = {
  isLoadingMentions: true,
  isLoadingMoreMentions: false,
  isLoadingMention: false,
  openMentions: {},
  queueMentions: {},
  allMentions: {},
  mentionReplySendingStatus: null,
  mentionReplySendingResponse: null,
  mentionsOutcome: "",
  mentionsMessage: "",
  mentionsUnreadCount: {},
};

const Mentions = (state = INIT_STATE, action) => {
  const {
    type,
    mentionsData,
    mentionsOutcome,
    mentionsMessage,
    mentionSection,
    paginate,
    isLoadingMentions,
    isLoadingMoreMentions,
    isLoadingMention,
    mentionReplySendingStatus,
    mentionReplySendingResponse,
    mentionsUnreadCount,
  } = action;

  switch (type) {
    case GET_MENTIONS_LOADER: {
      return {
        ...state,
        isLoadingMentions,
      };
    }

    case GET_MORE_MENTIONS_LOADER: {
      return {
        ...state,
        isLoadingMoreMentions,
      };
    }

    case GET_MENTIONS_RESPONSE: {
      const oldResponse = JSON.parse(JSON.stringify(state[mentionSection]));

      const newResponse = paginate
        ? {
            ...mentionsData,
            data: [...oldResponse.data, ...mentionsData.data],
          }
        : mentionsData;

      return {
        ...state,
        mentionsOutcome,
        [mentionSection]: newResponse,
        mentionsMessage,
      };
    }

    case GET_MENTION_LOADER: {
      return {
        ...state,
        isLoadingMention,
      };
    }

    case SET_MENTION_REPLY_SENDING_STATUS: {
      return {
        ...state,
        mentionReplySendingStatus,
        mentionReplySendingResponse,
      };
    }

    case CREATE_MENTIONS_UNREAD_COUNT: {
      return {
        ...state,
        mentionsUnreadCount,
      };
    }

    case GET_MENTIONS_UNREAD_COUNT: {
      return {
        ...state,
        mentionsUnreadCount,
      };
    }

    default:
      return state;
  }
};

export default Mentions;
