import React from "react";

export default () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      fill="none"
      viewBox="0 0 21 21"
    >
      <path
        stroke="#39206A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
        d="M3.958 15.508h-.641A2.566 2.566 0 01.75 12.941v-2.566a2.567 2.567 0 012.567-2.567h.641a.642.642 0 01.642.642v6.416a.642.642 0 01-.642.642zM17.434 15.508h-.642a.642.642 0 01-.642-.642V8.45a.642.642 0 01.642-.642h.642A2.567 2.567 0 0120 10.375v2.566a2.566 2.566 0 01-2.566 2.567z"
      ></path>
      <path
        stroke="#39206A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
        d="M3.316 7.808A7.058 7.058 0 0110.375.75v0a7.058 7.058 0 017.058 7.058M12.941 18.717h1.925a2.567 2.567 0 002.567-2.567v-.642"
      ></path>
      <path
        stroke="#39206A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
        d="M11.659 20h-1.284a1.283 1.283 0 010-2.567h1.284a1.283 1.283 0 010 2.567zM7.809 13.32a3.81 3.81 0 005.133 0M7.809 8.65v1.123M12.941 8.65v1.123"
      ></path>
    </svg>
)