import React from "react";

export default () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      fill="none"
      viewBox="0 0 21 21"
    >
      <path
        stroke="#474F58"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
        d="M17.433.75H3.317A2.567 2.567 0 00.75 3.317v14.116A2.567 2.567 0 003.317 20h14.116A2.567 2.567 0 0020 17.433V3.317A2.567 2.567 0 0017.433.75zM7.166 8.45v3.85M13.584 8.45v3.85"
      ></path>
      <path
        stroke="#474F58"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
        d="M10.375 17.434a7.058 7.058 0 100-14.117 7.058 7.058 0 000 14.117z"
      ></path>
    </svg>
)