import React from "react";

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <g
      stroke="#778699"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      clipPath="url(#searchClip0)"
    >
      <path d="M6.834 12.666a6 6 0 100-12 6 6 0 000 12zM15.335 15.166l-4.25-4.25"></path>
    </g>
    <defs>
      <clipPath id="searchClip0">
        <path fill="#fff" d="M0 0H16V16H0z"></path>
      </clipPath>
    </defs>
  </svg>
);
