import React from "react";

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      stroke="#C6C1D0"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.25"
      d="M9.384 1.875V.625M6.882 10a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5zM11.882 10a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5z"
    ></path>
    <path
      stroke="#C6C1D0"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.25"
      d="M15.632 11.875s.467 7.5-6.25 7.5c-6.718 0-6.25-7.5-6.25-7.5v-4.59 0a2.506 2.506 0 012.802-2.478h0c2.29.249 4.598.249 6.887 0h0a2.506 2.506 0 012.807 2.473l.004 4.595z"
    ></path>
    <path
      stroke="#C6C1D0"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.25"
      d="M3.125 12.5v0a2.5 2.5 0 010-5M15.625 12.5v0a2.5 2.5 0 000-5M3.125 12.5h12.5M5.237 4.825h0a4.377 4.377 0 018.263-.038"
    ></path>
  </svg>
);
