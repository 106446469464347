import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import * as moment from "moment";
import * as _ from "lodash";
import Select from "react-select";

import { Button } from "../../../components/FormElements";
import LottieAnimation from "../../../components/LottieWeb";
import OptionsDropdown from "../../../components/OptionsDropdown";

import AssignToAgent from "./AssignToAgent";

import { getAgentsAction } from "../../../redux/actions/Agents";
import {
  patchAssignConversationsToAgent,
  resetAssignConversationResponse,
} from "../../../redux/actions/Conversations";

import {
  AngleLeft,
  AngleDown,
  Audio,
  Cart,
  Contact,
  Document,
  Gif,
  Image,
  Sticker,
  Location,
  Video,
  People,
  Edit,
  WhatsApp,
  Email,
  Twitter,
  Messenger,
  Instagram,
  SMS,
  LogoSolid,
} from "../../../assets/vectors";

import { getInitials } from "../../../utils/functions";
import { conversationsPermissions } from "../../../utils/permissions";
import { APP_DLR_ORDER } from "../../../utils/constants";

class ConversationLists extends Component {
  state = {
    hideDisplay: false,
    selectedQueueConversations: [],
    isAssignToAgentModalActive: false,
    sortTypeSelection: null,
    sortQueueByOldest: true,
    channelSelection: null,
  };

  loadMoreRef = createRef();

  loadMore = () => {
    const {
      selectedSection,
      openConversations,
      inProgressConversations,
      awaitingResponseConversations,
      queueConversations,
      resolvedConversations,
      allConversations,
      isLoadingConversations,
      isLoadingMoreConversations,
      getOpenConversations,
      getInProgressConversations,
      getAwaitingResponseConversations,
      getQueueConversations,
      getResolvedConversations,
      getAllConversations,
    } = this.props;

    const conversationsData =
      selectedSection === "Assigned to me"
        ? openConversations
        : selectedSection === "Open"
        ? openConversations
        : selectedSection === "In-progress"
        ? inProgressConversations
        : selectedSection === "Awaiting Response"
        ? awaitingResponseConversations
        : selectedSection === "Queue"
        ? queueConversations
        : selectedSection === "Resolved"
        ? resolvedConversations
        : allConversations;

    const { currentPage, totalPage, data } = conversationsData;

    const scrollHeight = this.loadMoreRef.current?.scrollHeight;
    const clientHeight = this.loadMoreRef.current?.clientHeight;
    const scrollTop = this.loadMoreRef.current?.scrollTop;

    const scrollPostiton = scrollHeight - clientHeight - scrollTop;

    if (
      (scrollPostiton <= 15 || clientHeight === scrollHeight) &&
      currentPage < totalPage &&
      data.length &&
      !isLoadingConversations &&
      !isLoadingMoreConversations
    ) {
      selectedSection === "Assigned to me"
        ? getOpenConversations(true, true)
        : selectedSection === "Open"
        ? getOpenConversations(true, true)
        : selectedSection === "In-progress"
        ? getInProgressConversations(true, true)
        : selectedSection === "Awaiting Response"
        ? getAwaitingResponseConversations(true, true)
        : selectedSection === "Queue"
        ? getQueueConversations(
            true,
            true,
            this.state.sortQueueByOldest ? "asc" : "desc"
          )
        : selectedSection === "Resolved"
        ? getResolvedConversations(true, true)
        : getAllConversations(true, true);
    }
  };

  getChannelMediumIcon = (medium) => {
    switch (medium) {
      case "EMAIL":
        return <Email />;
      case "WHATSAPP":
        return <WhatsApp />;
      case "TWITTER":
        return <Twitter />;
      case "FACEBOOK":
        return <Messenger />;
      case "INSTAGRAM":
        return <Instagram />;
      case "SMS":
        return <SMS />;
      case "SMS-TW":
        return <SMS />;
      default:
        return <LogoSolid />;
    }
  };

  setHideDisplay = () => {
    this.setState({
      hideDisplay: !this.state.hideDisplay,
    });
  };

  handleSelectAllQueueConversations = () => {
    const { queueConversations } = this.props;
    const { selectedQueueConversations } = this.state;

    if (selectedQueueConversations?.length > 0) {
      this.setState({
        selectedQueueConversations: [],
      });
    } else {
      this.setState({
        selectedQueueConversations: queueConversations?.data?.map((x) => x?.id),
      });
    }
  };

  handleSelectQueueConversation = ({ target }) => {
    const { queueConversations } = this.props;
    const { selectedQueueConversations } = this.state;

    const isSelected = queueConversations?.data?.find(
      (x) => x?.id === target?.value
    )?.id;

    const hasAlreadyBeenSelected = selectedQueueConversations?.find(
      (x) => x === target?.value
    )
      ? true
      : false;

    if (hasAlreadyBeenSelected) {
      this.setState({
        selectedQueueConversations: selectedQueueConversations?.filter(
          (x) => x !== target?.value
        ),
      });
    } else {
      this.setState({
        selectedQueueConversations: [
          ...(selectedQueueConversations || []),
          isSelected,
        ],
      });
    }
  };

  bulkActionOptionsSelect = (type) => {
    if (type === "assign_to_agent") {
      this.setState({
        isAssignToAgentModalActive: true,
      });
    }
  };

  sortByOptionsSelect = (selection) => {
    const { getQueueConversations } = this.props;

    this.setState({
      sortTypeSelection: selection,
    });

    if (selection?.value === "oldest") {
      this.setState(
        {
          sortQueueByOldest: true,
        },
        () => {
          getQueueConversations(true, false, "asc");
        }
      );
    } else if (selection?.value === "latest") {
      this.setState(
        {
          sortQueueByOldest: false,
        },
        () => {
          getQueueConversations(true, false, "desc");
        }
      );
    }
  };

  sortByChannelSelect = (selection) => {
    const { sortQueueByOldest } = this.state;
    const { getQueueConversations } = this.props;

    if (selection) {
      this.setState({
        channelSelection: selection,
      });
    } else {
      this.setState({
        sortTypeSelection: null,
        channelSelection: null,
      });
    }

    getQueueConversations(
      false,
      false,
      sortQueueByOldest ? "asc" : "desc",
      selection?.value
    );
  };

  handleAssignToAgent = () => {
    this.props
      .patchAssignConversationsToAgent({
        conversations: this.state.selectedQueueConversations,
        agent_id: this.state.agent?.value,
      })
      .then((res) => {
        if (res?.data?.success === true) {
          this.setState({
            selectedQueueConversations: [],
          });
          this.closeAssignmentModal();
        }
      });
  };

  closeAssignmentModal = () => {
    this.setState({
      isAssignToAgentModalActive: false,
      agent: null,
    });

    if (this.props.assignConversationOutcome) {
      this.props.resetAssignConversationResponse();
    }
  };

  selectQueueConvo = (msg) => {
    const { selectedSection, selectConversation } = this.props;

    if (selectedSection === "Queue") {
      selectConversation(msg);
    }
  };

  getDlrIcon = (status) => {
    switch (status) {
      case "sending":
        return "https://res.cloudinary.com/dqhhhjr74/image/upload/v1699031532/or-mrkt/sending_jfoz4x.png";
      case "sent":
        return require("../../../assets/images/icons/check.png").default;
      case "delivered":
        return require("../../../assets/images/icons/read.png").default;
      case "read":
        return require("../../../assets/images/icons/blue-read.png").default;
      default:
        return require("../../../assets/images/icons/error.png").default;
    }
  };

  messageStatusIcon = (status) => {
    return (
      <span className="msg-dlr">
        <img src={this.getDlrIcon(status)} className={status} alt="" />
      </span>
    );
  };

  getContentTypePreview = (updatedContentType) => {
    let previewMsg = "";

    if (updatedContentType === "ORDER") {
      previewMsg = (
        <>
          <Cart /> ORDER
        </>
      );
    } else if (updatedContentType === "IMAGE") {
      previewMsg = (
        <>
          <Image /> IMAGE
        </>
      );
    } else if (updatedContentType === "VIDEO") {
      previewMsg = (
        <>
          <Video /> VIDEO
        </>
      );
    } else if (updatedContentType === "AUDIO") {
      previewMsg = (
        <>
          <Audio /> AUDIO
        </>
      );
    } else if (updatedContentType === "VOICE") {
      previewMsg = (
        <>
          <Audio /> VOICE
        </>
      );
    } else if (updatedContentType === "LOCATION") {
      previewMsg = (
        <>
          <Location /> LOCATION
        </>
      );
    } else if (updatedContentType === "STICKER") {
      previewMsg = (
        <>
          <Sticker /> STICKER
        </>
      );
    } else if (updatedContentType === "DOCUMENT") {
      previewMsg = (
        <>
          <Document /> DOCUMENT
        </>
      );
    } else if (updatedContentType === "TEMPLATE") {
      previewMsg = (
        <>
          <Document /> TEMPLATE
        </>
      );
    } else if (updatedContentType === "CONTACTS") {
      previewMsg = (
        <>
          <Contact /> CONTACT
        </>
      );
    } else if (updatedContentType === "GIF") {
      previewMsg = (
        <>
          <Gif /> GIF
        </>
      );
    }

    return previewMsg;
  };

  getMessagePreview = (id, msg) => {
    const { updatedPreview } = this.props;
    const { channel, message } = msg;

    let previewMsg = "";

    const savedPreview = updatedPreview?.find((x) => x?.conversationId === id);

    const updatedPreviewConvoId = savedPreview?.conversationId;
    const updatedPreviewContentType = savedPreview?.preview?.contentType;
    const updatedPreviewDlr = savedPreview?.preview?.dlr;
    var msgContentType = "";
    var msgSender = {};

    if (updatedPreviewConvoId === id) {
      msgContentType = savedPreview?.preview?.contentType;
      msgSender = savedPreview?.preview?.sender;
    } else {
      msgContentType = message[0]?.contentType;
      msgSender = message[0]?.sender;
    }

    const order = APP_DLR_ORDER;
    const allMsgDlr =
      updatedPreviewConvoId === id && updatedPreviewDlr?.length
        ? updatedPreviewDlr
        : message[0]?.dlr?.length
        ? message[0]?.dlr
        : [{ status: "sent", timestamp: new Date() }];
    allMsgDlr?.sort(
      (a, b) =>
        (order[a.status] || Number.MAX_VALUE) -
        (order[b.status] || Number.MAX_VALUE)
    );

    const msgDlr = allMsgDlr?.[0] || null;

    if (updatedPreview && updatedPreviewConvoId === id) {
      if (updatedPreviewContentType !== "TEXT") {
        previewMsg = this.getContentTypePreview(updatedPreviewContentType);
      } else {
        previewMsg = savedPreview?.preview?.content;
      }
    } else if (message?.length) {
      if (channel?.medium === "EMAIL") {
        previewMsg = message[0]?.clean_content;
      } else if (message[0]?.contentType !== "TEXT") {
        previewMsg = this.getContentTypePreview(msgContentType);
      } else {
        previewMsg = message[0]?.content;
      }
    }

    return (
      <>
        {msgSender?.authUser && this.messageStatusIcon(msgDlr?.status)}
        <p>{previewMsg}</p>
      </>
    );
  };

  triggerFetchList = () => {
    const { refetchConversationsByStatus } = this.props;
    const { fetchListTriggered } = this.state;

    if (!fetchListTriggered) {
      refetchConversationsByStatus();

      this.setState({
        fetchListTriggered: true,
      });
    }
  };

  updateNewDlrMsgPreview = (data) => {
    const { updatedPreview, updatePreview } = this.props;

    const savedPreview = updatedPreview?.find(
      (x) => x?.conversationId === data?.conversation_id
    );

    if (savedPreview?.conversationId) {
      const toUpdate = {
        conversationId: savedPreview?.conversationId,
        preview: {
          ...savedPreview?.preview,
          dlr: [data?.dlr],
        },
      };
      updatePreview(toUpdate);
    }
  };

  componentDidMount() {
    document
      .getElementById("conversation-lists-container")
      .addEventListener("scroll", this.loadMore);

    this.props.getAgentsAction({
      status: "confirmed",
    });
  }

  componentDidUpdate(prevProps) {
    const {
      newDlr,
      selectedConversation,
      resolvedConversations,
      refetchConversationsByStatus,
    } = this.props;

    if (
      selectedConversation?.status === "closed" &&
      !resolvedConversations?.data
    ) {
      refetchConversationsByStatus();
    }

    if (prevProps.newDlr !== newDlr) {
      this.updateNewDlrMsgPreview(newDlr);
    }
  }

  componentWillUnmount() {
    document
      .getElementById("conversation-lists-container")
      .removeEventListener("scroll", this.loadMore);
  }

  render() {
    const {
      isLoadingConversations,
      openConversations,
      inProgressConversations,
      awaitingResponseConversations,
      queueConversations,
      resolvedConversations,
      allConversations,
      selectedSection,
      selectConversation,
      selectedConversation,
      acceptConversation,
      acceptedConversation,
      openNewConversation,
      isSearchActive,
      channelsData,
      isLoadingMoreConversations,
      isLoadingAgents,
      agentsData,
      assignConversationOutcome,
      assignConversationMessage,
      conversationsUnreadMsgs,
    } = this.props;

    const {
      hideDisplay,
      selectedQueueConversations,
      isAssignToAgentModalActive,
      agent,
      sortTypeSelection,
      sortQueueByOldest,
      channelSelection,
    } = this.state;

    const conversationsData =
      selectedSection === "Assigned to me"
        ? openConversations
        : selectedSection === "Open"
        ? openConversations
        : selectedSection === "In-progress"
        ? inProgressConversations
        : selectedSection === "Awaiting Response"
        ? awaitingResponseConversations
        : selectedSection === "Queue"
        ? queueConversations
        : selectedSection === "Resolved"
        ? resolvedConversations
        : allConversations;

    let runOneTime = true;
    if (runOneTime === true && conversationsData?.data) {
      runOneTime = false;
      this.loadMore();
    }

    if (
      !isLoadingConversations &&
      selectedConversation?.id &&
      !conversationsData?.data
    ) {
      this.triggerFetchList();
    }

    const isAcceptedConversationExisting = (conversationsData?.data || []).some(
      ({ id }) => id === acceptedConversation.id
    );

    const newConversationsData =
      Object.keys(acceptedConversation).length &&
      !isAcceptedConversationExisting
        ? [acceptedConversation, ...(conversationsData?.data || [])]
        : conversationsData?.data || [];

    const conversations = _.chain(
      newConversationsData.map((convo) => ({
        ...convo,
        formattedDate: moment(convo.lastMessageAt || convo.updatedAt).format(
          "MMM DD, YYYY"
        ),
      }))
    )
      .groupBy("formattedDate")
      .map((value, key) => ({ date: key, messages: value }))
      .value();

    const sortedConversations = conversations.sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return selectedSection === "Queue" && sortQueueByOldest === true
        ? dateA - dateB
        : dateB - dateA;
    });

    const checkUnreadMessages = (id) => {
      return conversationsUnreadMsgs?.filter((msg) => msg?.id === id)?.length;
    };

    const bulkActionOptions = [
      {
        label: (
          <div>
            <span className="icon">
              <People />
            </span>{" "}
            <span className="text">Assign to agent</span>
          </div>
        ),
        value: "assign_to_agent",
      },
    ];

    const sortByOptions = [
      {
        label: "Older conversations",
        value: "oldest",
      },
      {
        label: "Newer conversations",
        value: "latest",
      },
      {
        label: "Channel",
        value: "sortByChannel",
      },
    ];

    const channelsOptions = channelsData
      ?.filter((x) => x?.status === "ACTIVE")
      ?.map((item) => ({
        label: item?.name,
        value: item?.id,
      }));

    const canUserCreateConversation =
      conversationsPermissions.create || conversationsPermissions.modifyAll;

    const canUserModifyConversation =
      conversationsPermissions.update || conversationsPermissions.modifyAll;

    return (
      <div
        id="conversation-lists-container"
        className={classNames("conversation-lists-container", {
          hideDisplay,
          searchActive: isSearchActive,
        })}
        ref={this.loadMoreRef}
      >
        <div className="toggle-container">
          <div
            className={classNames("toggle-display", {
              active: hideDisplay,
            })}
            onClick={() => this.setHideDisplay()}
          >
            <AngleLeft />
          </div>
        </div>

        <div className="title">
          <p>{selectedSection}</p>
          {canUserCreateConversation && selectedConversation?.id ? (
            ""
          ) : (
            <span className="icon" onClick={openNewConversation}>
              <span>New</span>
              <Edit />
            </span>
          )}
        </div>

        {selectedSection === "Queue" && (
          <div className="bulk-actions">
            {canUserModifyConversation && (
              <div className="checkbox_container">
                <input
                  name="recipients"
                  className="input"
                  onChange={(e) => this.handleSelectAllQueueConversations(e)}
                  type="checkbox"
                  checked={
                    selectedQueueConversations?.length ===
                    queueConversations?.data?.length
                  }
                  required
                />
              </div>
            )}
            {selectedQueueConversations?.length > 0 ? (
              <div className="bulk_actions_dropdown">
                <OptionsDropdown
                  dropdownPlaceholder={
                    <>
                      Action <AngleDown />
                    </>
                  }
                  onClick={(val) => {
                    this.bulkActionOptionsSelect(val);
                  }}
                  options={bulkActionOptions}
                />
              </div>
            ) : (
              <div className="bulk_actions_dropdown">
                {sortTypeSelection?.value !== "sortByChannel" ? (
                  <Select
                    classNamePrefix="select_container"
                    value={sortTypeSelection}
                    options={sortByOptions}
                    onChange={(selected) => this.sortByOptionsSelect(selected)}
                    placeholder="Sort by"
                  />
                ) : (
                  <Select
                    classNamePrefix="select_container"
                    value={channelSelection}
                    options={channelsOptions}
                    onChange={(selected) => this.sortByChannelSelect(selected)}
                    placeholder="Select channel"
                    isClearable
                  />
                )}

                {/* <OptionsDropdown
                  dropdownPlaceholder={
                    sortTypeSelection === "sortByChannel" ? (
                      <>
                        Select Channel <AngleDown />
                      </>
                    ) : (
                      <>
                        Sort by <AngleDown />
                      </>
                    )
                  }
                  onClick={(val) => {
                    this.sortByOptionsSelect(val);
                  }}
                  options={
                    sortTypeSelection === "sortByChannel"
                      ? channelsOptions
                      : sortByOptions
                  }
                /> */}
              </div>
            )}
          </div>
        )}

        <div className="conversation-list-items">
          {isLoadingConversations ? (
            <div className="loader-container text-center">
              <LottieAnimation
                path={require("../../../assets/jsons/loader.json")}
                autoplay
              />
            </div>
          ) : (
            sortedConversations.map(({ date, messages }, index) => {
              // This removes duplicate conversations
              const filteredConversations = messages?.filter(
                (
                  (s) => (o) =>
                    ((k) => !s.has(k) && s.add(k))(
                      ["id"].map((k) => o[k]).join("|")
                    )
                )(new Set())
              );

              const topSortedMessages = filteredConversations?.sort((a, b) => {
                const dateA = new Date(a.lastMessageAt);
                const dateB = new Date(b.lastMessageAt);
                return selectedSection === "Queue" && sortQueueByOldest === true
                  ? dateA - dateB
                  : dateB - dateA;
              });

              return (
                <div key={`conversation-list-${index}`}>
                  <div className="conversation-date">{date}</div>
                  {topSortedMessages.map((msg, index2) => {
                    const { id, channel, customer, message } = msg;

                    return (
                      <div
                        key={`conversation-item-${index2}`}
                        className={classNames("conversation-item", {
                          active:
                            selectedConversation.Customer?.id ===
                              msg?.customer_id &&
                            selectedConversation?.channel_id ===
                              msg?.channel?.id,
                          unread: checkUnreadMessages(id),
                        })}
                        onClick={
                          selectedSection !== "Queue"
                            ? () => selectConversation(msg)
                            : () => {}
                        }
                      >
                        {selectedSection !== "Queue" ||
                        !canUserModifyConversation ? (
                          <div className="avatar">
                            {customer.name ? getInitials(customer.name, 2) : ""}
                          </div>
                        ) : (
                          <>
                            {canUserModifyConversation && (
                              <div className="checkbox_container">
                                <input
                                  name="recipients"
                                  id={id}
                                  value={id}
                                  className="input"
                                  onChange={(e) =>
                                    this.handleSelectQueueConversation(e)
                                  }
                                  type="checkbox"
                                  checked={selectedQueueConversations?.find(
                                    (x) => x === id
                                  )}
                                  required
                                />
                              </div>
                            )}
                          </>
                        )}
                        <div className="content">
                          <div
                            className="conversation-item-header"
                            onClick={() => this.selectQueueConvo(msg)}
                          >
                            <span className="conversation-author">
                              {customer?.name ||
                                customer?.phone ||
                                customer?.facebookMessengerId}
                            </span>
                            <span className="conversation-time">
                              {moment(message?.[0]?.createdAt).format("HH:mm")}
                            </span>
                          </div>
                          <div
                            className="conversation-item-preview-container"
                            onClick={() => this.selectQueueConvo(msg)}
                          >
                            <div className="conversation-item-preview">
                              {this.getMessagePreview(id, msg)}
                            </div>
                            {!!checkUnreadMessages(id) && (
                              <span className="unread-count">
                                {checkUnreadMessages(id) || "1"}
                              </span>
                            )}
                          </div>
                          {selectedSection === "Queue" ? (
                            <div className="conversation-item-channel hover-effect">
                              <span>
                                Via:{" "}
                                {this.getChannelMediumIcon(channel?.medium)}
                              </span>
                              {canUserModifyConversation && (
                                <Button
                                  className="conversation-button small"
                                  onClick={() => acceptConversation(msg)}
                                >
                                  Accept
                                </Button>
                              )}
                            </div>
                          ) : (
                            <div className="conversation-item-channel">
                              <span>
                                Via:{" "}
                                {this.getChannelMediumIcon(channel?.medium)}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })
          )}
          {isLoadingMoreConversations && (
            <div className="loader-container no-height load-more active">
              <LottieAnimation
                path={require("../../../assets/jsons/loader.json")}
                autoplay
              />
            </div>
          )}
        </div>

        {isAssignToAgentModalActive && (
          <AssignToAgent
            close={this.closeAssignmentModal}
            isLoadingAgents={isLoadingAgents}
            agent={agent}
            allAgents={agentsData}
            onChange={(selected) =>
              this.setState({
                agent: selected,
              })
            }
            assignOnClick={() => this.handleAssignToAgent()}
            isAssigningToAgent={isLoadingConversations}
            assignConversationOutcome={assignConversationOutcome}
            assignConversationMessage={assignConversationMessage}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ conversations, agents }) => ({
  ...conversations,
  ...agents,
});

export default connect(mapStateToProps, {
  getAgentsAction,
  patchAssignConversationsToAgent,
  resetAssignConversationResponse,
})(ConversationLists);
