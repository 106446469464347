import React from "react";

export default () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.75 1.91375C0.75 1.27092 1.27092 0.75 1.91375 0.75H10.0862C10.7291 0.75 11.25 1.27092 11.25 1.91375V10.0862C11.25 10.3949 11.1274 10.6909 10.9091 10.9091C10.6909 11.1274 10.3949 11.25 10.0862 11.25H1.91375C1.6051 11.25 1.3091 11.1274 1.09085 10.9091C0.872609 10.6909 0.75 10.3949 0.75 10.0862V1.91375ZM2.70825 9.5H9.41075C9.03486 8.95949 8.53372 8.518 7.95014 8.21326C7.36655 7.90851 6.71786 7.74957 6.0595 7.75C5.40114 7.74957 4.75245 7.90851 4.16886 8.21326C3.58528 8.518 3.08413 8.95949 2.70825 9.5ZM6 6.58333C6.26812 6.58333 6.53361 6.53052 6.78131 6.42792C7.02902 6.32532 7.25409 6.17493 7.44368 5.98534C7.63326 5.79576 7.78365 5.57068 7.88625 5.32298C7.98886 5.07527 8.04167 4.80978 8.04167 4.54167C8.04167 4.27355 7.98886 4.00806 7.88625 3.76035C7.78365 3.51265 7.63326 3.28758 7.44368 3.09799C7.25409 2.9084 7.02902 2.75802 6.78131 2.65541C6.53361 2.55281 6.26812 2.5 6 2.5C5.45852 2.5 4.93921 2.7151 4.55632 3.09799C4.17344 3.48088 3.95833 4.00018 3.95833 4.54167C3.95833 5.08315 4.17344 5.60246 4.55632 5.98534C4.93921 6.36823 5.45852 6.58333 6 6.58333Z"
      fill="#757F8A"
    />
  </svg>
);
