import React from "react";

export default () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="7"
      fill="none"
      viewBox="0 0 10 7"
    >
      <path
        stroke="#757F8A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.333"
        d="M1 1.5l4 4 4-4"
      ></path>
    </svg>
)