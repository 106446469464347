import React from "react";

export default () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99999 1.66663L1.66666 5.83329L9.99999 9.99996L18.3333 5.83329L9.99999 1.66663Z"
      stroke="#8F99A6"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.66666 14.1666L9.99999 18.3333L18.3333 14.1666"
      stroke="#8F99A6"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.66666 10L9.99999 14.1667L18.3333 10"
      stroke="#8F99A6"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
