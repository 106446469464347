import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Toaster from "../../../components/Toaster";

import {
  getSignatureAction,
  deleteSignatureAction,
} from "../../../redux/actions/Signature";

import { Button } from "../../../components/FormElements";
import Modal from "../../../components/Modal";

import { CircularPlus, Trash } from "../../../assets/vectors";

import SignatureForm from "./SignatureForm";

const Signatures = () => {
  const dispatch = useDispatch();
  const {
    signatureData,
    isLoadingSignature,
    isDeletingSignature,
    createSignatureMessage,
    updateSignatureMessage,
    deleteSignatureMessage,
  } = useSelector((state) => state.signature);

  const [selectedSignature, setSelectedSignature] = useState(null);
  const [isSignatureFormActive, setIsSignatureFormActive] = useState(false);
  const [signatureInfo, setSignatureInfo] = useState(true);
  const [isConfirmationModalActive, setIsConfirmationModalActive] =
    useState(false);
  const [toaster, setToaster] = useState({});

  useEffect(() => {
    dispatch(getSignatureAction());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (createSignatureMessage) openToaster("success", createSignatureMessage);
    if (updateSignatureMessage) openToaster("success", updateSignatureMessage);
    if (deleteSignatureMessage) openToaster("success", deleteSignatureMessage);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createSignatureMessage, updateSignatureMessage, deleteSignatureMessage]);

  const openToaster = (status, content) => {
    setToaster({
      open: "open",
      status,
      content,
    });
  };

  const removeInfo = () => {
    setSignatureInfo(false);
  };

  const selectSignature = (signature) => {
    setIsSignatureFormActive(false);
    setSelectedSignature(signature);
  };

  useEffect(() => {
    if (selectedSignature) setIsSignatureFormActive(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSignature]);

  const closeSelection = () => {
    setSelectedSignature(null);
    setIsSignatureFormActive(false);
  };

  const deleteSignature = () => {
    dispatch(deleteSignatureAction(selectedSignature?.id)).then((res) => {
      setIsConfirmationModalActive(false);
      if (res === 200) {
        closeSelection();
        dispatch(getSignatureAction());
      } else {
        openToaster("error", res);
      }
    });
  };

  return (
    <div className="settings-page-container signature-page-container">
      <div className="page-header">
        <span className="title">Email Signatures</span>
      </div>

      {signatureInfo && (
        <div className="settings-page-info">
          <div>
            <span className="title">What is an Email Signature?</span>
            <span className="description">
              Email Signature is a customer satisfaction survey that
              automatically goes out to customers after completing a
              conversation.
            </span>
            <Button className="plain-button" onClick={removeInfo}>
              Got it
            </Button>
          </div>
          <div className="image">
            <img
              src={require("../../../assets/images/documents.png").default}
              alt=""
            />
          </div>
        </div>
      )}

      <div className="signature-page-content">
        <div className="signature-listing">
          <div className="header">
            <p>Signatures</p>

            {!selectedSignature && (
              <div
                className="create-new"
                onClick={() => setIsSignatureFormActive(true)}
              >
                Create New <CircularPlus />
              </div>
            )}
          </div>

          <div className="list">
            {signatureData?.map((item, i) => (
              <div
                key={i}
                className="item"
                onClick={() => selectSignature(item)}
              >
                <div className="left">
                  {item?.name}{" "}
                  {item.active ? (
                    <span className="active-indicator"></span>
                  ) : (
                    ""
                  )}
                </div>

                <span onClick={() => setIsConfirmationModalActive(true)}>
                  <Trash />
                </span>
              </div>
            ))}

            {signatureData?.length < 1 && !isLoadingSignature ? (
              <div className="no-signature">
                You haven't created a signature yet.
              </div>
            ) : isLoadingSignature ? (
              <div className="no-signature">Loading...</div>
            ) : (
              ""
            )}
          </div>
        </div>

        {isSignatureFormActive && (
          <SignatureForm
            selectedSignature={selectedSignature}
            closeSelection={closeSelection}
            getSignatureAction={() => dispatch(getSignatureAction())}
          />
        )}
      </div>

      {isConfirmationModalActive && (
        <Modal closeModal={() => setIsConfirmationModalActive(false)} small>
          <div className="modal-content confirmation-modal">
            <div className="modal-icon red">
              <Trash />
            </div>
            <div className="modal-title">
              Are you sure you want to delete this Signature?
            </div>
            <div className="modal-text">This action cannot be reversed</div>
            <div className="modal-action">
              <Button
                className="secondary-button red"
                type="button"
                onClick={deleteSignature}
                disabled={isDeletingSignature}
              >
                {isDeletingSignature ? "Processing..." : "Delete Signature"}
              </Button>
              <Button
                className="plain-button black"
                type="button"
                onClick={() => setIsConfirmationModalActive(false)}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      )}

      <Toaster
        {...toaster}
        closeToaster={() =>
          setToaster({
            ...toaster,
            open: "closed",
          })
        }
      />
    </div>
  );
};

export default Signatures;
