import React from "react";

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <g
      stroke="#39206A"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.25"
      clipPath="url(#messagesclip0)"
    >
      <path d="M18.125 15.625h-8.75l-5 3.75v-3.75h-2.5v0c-.69 0-1.25-.56-1.25-1.25v-12.5 0c0-.69.56-1.25 1.25-1.25h16.25v0c.69 0 1.25.56 1.25 1.25v12.5c0 .69-.56 1.25-1.25 1.25 0 0 0 0 0 0v0zM8.125 6.567h7.5M8.125 10.317h7.5"></path>
      <path d="M4.375 6.25v0a.312.312 0 10.313.313v0a.312.312 0 00-.313-.313M4.375 10v0a.312.312 0 10.313.313v0A.312.312 0 004.375 10"></path>
    </g>
    <defs>
      <clipPath id="messagesclip0">
        <path fill="#fff" d="M0 0H20V20H0z"></path>
      </clipPath>
    </defs>
  </svg>
);
