import React from "react";

export default () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.799805 12C0.799805 5.82469 5.82445 0.800049 11.9998 0.800049C18.1752 0.800049 23.1998 5.82469 23.1998 12C23.1998 18.1754 18.1752 23.2 11.9998 23.2H1.4998C1.11345 23.2 0.799805 22.8864 0.799805 22.5V12ZM16.0775 7.44551C16.2531 7.37453 16.4544 7.414 16.5886 7.54857C16.7227 7.68273 16.7631 7.88408 16.6917 8.05917L12.4567 18.4114C12.3837 18.5897 12.2101 18.7041 12.0209 18.7041C12.0049 18.7041 11.9884 18.7032 11.9719 18.7017C11.764 18.6796 11.5955 18.5234 11.5583 18.3178L10.6751 13.4621L5.81941 12.5793C5.61377 12.5417 5.45755 12.3732 5.43587 12.1653C5.41379 11.9572 5.53191 11.76 5.7253 11.6805L16.0775 7.44551Z"
      fill="white"
    />
  </svg>
);
