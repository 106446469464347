import {
  WIDGETS_LOADER,
  WIDGETS_SUBMITTING,
  WIDGETS_RESPONSE,
  WIDGET_RESPONSE,
} from "../constants";

const INIT_STATE = {
  isLoadingWidgets: false,
  isSubmittingWidget: false,
  widgetsOutcome: "",
  widgetsData: null,
  widgetsMessage: "",
  widgetOutcome: "",
  widgetData: null,
  widgetMessage: "",
};

const Widgets = (state = INIT_STATE, action) => {
  const {
    type,
    isLoadingWidgets,
    isSubmittingWidget,
    widgetsOutcome,
    widgetsData,
    widgetsMessage,
    widgetOutcome,
    widgetData,
    widgetMessage,
  } = action;

  switch (type) {
    case WIDGETS_LOADER: {
      return {
        ...state,
        isLoadingWidgets,
      };
    }

    case WIDGETS_SUBMITTING: {
      return {
        ...state,
        isSubmittingWidget,
      };
    }

    case WIDGETS_RESPONSE: {
      return {
        ...state,
        widgetsOutcome,
        widgetsData,
        widgetsMessage,
      };
    }

    case WIDGET_RESPONSE: {
      return {
        ...state,
        widgetOutcome,
        widgetData,
        widgetMessage,
      };
    }

    default:
      return state;
  }
};

export default Widgets;
