import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Toaster from "../../../components/Toaster";

import {
  getCustomFieldsAction,
  deleteCustomFieldsAction,
} from "../../../redux/actions/CustomFields";

import { Button } from "../../../components/FormElements";
import Modal from "../../../components/Modal";

import { ChevronDownW, CircularPlus, Trash } from "../../../assets/vectors";

import CustomFieldForm from "./CustomFieldForm";

const CustomFields = () => {
  const dispatch = useDispatch();
  const {
    customFieldsData,
    isLoadingCustomFields,
    isDeletingCustomFields,
    createCustomFieldsMessage,
    createCustomFieldsOutcome,
    updateCustomFieldsMessage,
    updateCustomFieldsOutcome,
    deleteCustomFieldsMessage,
    deleteCustomFieldsOutcome,
  } = useSelector((state) => state.customFields);

  const [fieldInfo, setFieldInfo] = useState(true);
  const [selectedFieldObject, setSelectedFieldObject] = useState(null);
  const [selectedField, setSelectedField] = useState(null);
  const [isFieldFormActive, setIsFieldFormActive] = useState(false);
  const [isConfirmationModalActive, setIsConfirmationModalActive] =
    useState(false);
  const [toaster, setToaster] = useState({});

  useEffect(() => {
    dispatch(getCustomFieldsAction("customer"));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (createCustomFieldsMessage)
      openToaster(createCustomFieldsOutcome, createCustomFieldsMessage);
    if (updateCustomFieldsMessage)
      openToaster(updateCustomFieldsOutcome, updateCustomFieldsMessage);
    if (deleteCustomFieldsMessage)
      openToaster(deleteCustomFieldsOutcome, deleteCustomFieldsMessage);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    updateCustomFieldsMessage,
    deleteCustomFieldsMessage,
    isConfirmationModalActive,
  ]);

  const openToaster = (status, content) => {
    setToaster({
      open: "open",
      status,
      content,
    });
  };

  const removeInfo = () => {
    setFieldInfo(false);
  };

  const selectField = (field) => {
    setIsFieldFormActive(false);
    setSelectedField(field);
  };

  useEffect(() => {
    if (selectedField) setIsFieldFormActive(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedField]);

  const closeSelection = () => {
    setSelectedField(null);
    setIsFieldFormActive(false);
  };

  const deleteCustomField = () => {
    dispatch(deleteCustomFieldsAction("customer", selectedField?.id)).then(
      (res) => {
        setIsConfirmationModalActive(false);
        if (res === 200) {
          closeSelection();
          dispatch(getCustomFieldsAction("customer"));
        } else {
          openToaster("error", res);
        }
      }
    );
  };

  const allFieldObjects = [
    {
      name: "customers",
      title: "Customers Fields",
      description: "Create custom fields for objects like customers, teams...",
    },
  ];

  return (
    <div className="settings-page-container field-page-container">
      <div className="page-header">
        <span className="title">Custom Fields</span>
      </div>

      {fieldInfo && (
        <div className="settings-page-info">
          <div>
            <span className="title">What are Custom Fields?</span>
            <span className="description">
              Custom fields allow you capture information that isn’t available
              on the default fields.
            </span>
            <Button className="plain-button" onClick={removeInfo}>
              Got it
            </Button>
          </div>
          <div className="image">
            <img
              src={require("../../../assets/images/documents.png").default}
              alt=""
            />
          </div>
        </div>
      )}

      <div className="field-page-content">
        <div className="object-listing">
          <div className="list">
            {allFieldObjects?.map((item, i) => (
              <div
                key={i}
                className={`item ${
                  item?.name === selectedFieldObject?.name ? "active" : ""
                }`}
                onClick={() => setSelectedFieldObject(item)}
              >
                <div className="left">
                  <h6>{item?.title}</h6>
                  <p>{item?.description}</p>
                </div>

                <ChevronDownW />
              </div>
            ))}
          </div>
        </div>

        {selectedFieldObject?.name ? (
          <div className="field-listing">
            <div className="header">
              <h6>{selectedFieldObject?.title}</h6>
              <p>{selectedFieldObject?.description}</p>
            </div>

            <div className="list">
              {Array.isArray(customFieldsData) &&
                customFieldsData?.map((item, i) => (
                  <div
                    key={i}
                    className="item"
                    onClick={() => selectField(item)}
                  >
                    {item?.name}

                    <span
                      className="delete"
                      onClick={() => setIsConfirmationModalActive(true)}
                    >
                      <Trash />
                    </span>
                  </div>
                ))}
              <div
                className="item add_new"
                onClick={() => setIsFieldFormActive(true)}
              >
                <CircularPlus />
                Add new field
              </div>

              {isLoadingCustomFields ? (
                <div className="no-field">Loading...</div>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>

      {isFieldFormActive && !isConfirmationModalActive && (
        <CustomFieldForm
          fieldData={selectedField}
          toggleForm={closeSelection}
          getFieldsAction={() => dispatch(getCustomFieldsAction("customer"))}
        />
      )}

      {isConfirmationModalActive && (
        <Modal closeModal={() => setIsConfirmationModalActive(false)} small>
          <div className="modal-content confirmation-modal">
            <div className="modal-icon red">
              <Trash />
            </div>
            <div className="modal-title">
              Are you sure you want to delete this Custom field?
            </div>
            <div className="modal-text">This action cannot be reversed</div>
            <div className="modal-action">
              <Button
                className="secondary-button red"
                type="button"
                onClick={deleteCustomField}
                disabled={isDeletingCustomFields}
              >
                {isDeletingCustomFields ? "Processing..." : "Delete Field"}
              </Button>
              <Button
                className="plain-button black"
                type="button"
                onClick={() => {
                  setIsFieldFormActive(false);
                  setIsConfirmationModalActive(false);
                  setSelectedField(null);
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      )}

      <Toaster
        {...toaster}
        closeToaster={() =>
          setToaster({
            ...toaster,
            open: "closed",
          })
        }
      />
    </div>
  );
};

export default CustomFields;
