import React from "react";

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      stroke="#757F8A"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M8 14.667A6.667 6.667 0 108 1.333a6.667 6.667 0 000 13.334z"
    ></path>
    <path
      stroke="#757F8A"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M5.333 9.333s1 1.334 2.667 1.334c1.667 0 2.667-1.334 2.667-1.334M6 6h.007M10 6h.007"
    ></path>
  </svg>
);
