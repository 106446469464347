import React from "react";

export default () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.625 12.7083H3.75C2.9212 12.7083 2.12634 12.3791 1.54029 11.793C0.95424 11.207 0.625 10.4121 0.625 9.58331C0.625 8.75451 0.95424 7.95966 1.54029 7.3736C2.12634 6.78755 2.9212 6.45831 3.75 6.45831H5.625V12.7083Z"
      stroke="#C6C1D0"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.625 12.7083C9.29262 12.7086 12.8781 13.7942 15.93 15.8283L16.875 16.4583V2.70831L15.93 3.33831C12.8781 5.37243 9.29262 6.458 5.625 6.45831V12.7083Z"
      stroke="#C6C1D0"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.375 8.33331V10.8333"
      stroke="#C6C1D0"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.625 12.7083C5.62447 13.5266 5.79042 14.3364 6.11276 15.0886C6.4351 15.8407 6.90708 16.5194 7.5 17.0833"
      stroke="#C6C1D0"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
