import React from "react";

const SettingsEmptyState = ({title, description, action}) => (
    <div className="settings-empty-state">
        <div className="title">{title}</div>
        <div className="description">{description}</div>
        {action && action}
    </div>
)

export default SettingsEmptyState