import {
  SIGNUP_LOADER,
  SIGNUP_RESPONSE,
  LOGIN_LOADER,
  LOGIN_RESPONSE,
  LOGIN_RESET,
  FORGOT_PASSWORD_LOADER,
  FORGOT_PASSWORD_RESPONSE,
  FORGOT_PASSWORD_RESET,
  RESET_PASSWORD_LOADER,
  RESET_PASSWORD_RESPONSE,
  RESET_PASSWORD_RESET,
  SET_PASSWORD_LOADER,
  SET_PASSWORD_RESPONSE,
  SET_PASSWORD_RESET,
  LOGOUT,
  SET_ONBOARDING_STEP,
} from "../constants";

const INIT_STATE = {
  isSigningUp: false,
  signUpOutcome: "",
  signUpData: null,
  signUpMessage: "",
  isLoggingIn: false,
  isForgotPasswordLoading: false,
  isResetPasswordLoading: false,
  isSetPasswordLoading: false,
  loginOutcome: "",
  loginData: null,
  loginMessage: "",
  forgotPasswordOutcome: "",
  forgotPasswordMessage: "",
  forgotPasswordEmail: "",
  resetPasswordOutcome: "",
  resetPasswordMessage: "",
  setPasswordOutcome: "",
  setPasswordMessage: "",
  onboardingStep: 0,
  authenticated: null,
};

const Auth = (state = INIT_STATE, action) => {
  const {
    type,
    isSigningUp,
    signUpOutcome,
    signUpData,
    signUpMessage,
    loginOutcome,
    loginData,
    loginMessage,
    isLoggingIn,
    isForgotPasswordLoading,
    isResetPasswordLoading,
    isSetPasswordLoading,
    forgotPasswordOutcome,
    forgotPasswordMessage,
    forgotPasswordEmail,
    resetPasswordOutcome,
    resetPasswordMessage,
    setPasswordOutcome,
    setPasswordMessage,
    onboardingStep,
  } = action;

  switch (type) {
    case SIGNUP_LOADER: {
      return {
        ...state,
        isSigningUp,
      };
    }

    case SIGNUP_RESPONSE: {
      return {
        ...state,
        signUpOutcome,
        signUpData,
        signUpMessage,
      };
    }

    case LOGIN_LOADER: {
      return {
        ...state,
        isLoggingIn,
      };
    }

    case LOGIN_RESPONSE: {
      return {
        ...state,
        loginOutcome,
        loginData,
        loginMessage,
        authenticated: loginOutcome === "success" && !!loginData,
      };
    }

    case LOGIN_RESET: {
      return INIT_STATE;
    }

    case FORGOT_PASSWORD_LOADER: {
      return {
        ...state,
        isForgotPasswordLoading,
      };
    }

    case FORGOT_PASSWORD_RESPONSE: {
      return {
        ...state,
        forgotPasswordOutcome,
        forgotPasswordMessage,
        forgotPasswordEmail,
      };
    }

    case FORGOT_PASSWORD_RESET: {
      return {
        ...state,
        forgotPasswordOutcome: "",
        forgotPasswordMessage: "",
      };
    }

    case RESET_PASSWORD_LOADER: {
      return {
        ...state,
        isResetPasswordLoading,
      };
    }

    case RESET_PASSWORD_RESPONSE: {
      return {
        ...state,
        resetPasswordOutcome,
        resetPasswordMessage,
      };
    }

    case RESET_PASSWORD_RESET: {
      return {
        ...state,
        resetPasswordOutcome: "",
        resetPasswordMessage: "",
      };
    }

    case SET_PASSWORD_LOADER: {
      return {
        ...state,
        isSetPasswordLoading,
      };
    }

    case SET_PASSWORD_RESPONSE: {
      return {
        ...state,
        setPasswordOutcome,
        setPasswordMessage,
      };
    }

    case SET_PASSWORD_RESET: {
      return {
        ...state,
        setPasswordOutcome: "",
        setPasswordMessage: "",
      };
    }

    case SET_ONBOARDING_STEP: {
      return {
        ...state,
        onboardingStep,
      };
    }

    case LOGOUT: {
      return {
        ...INIT_STATE,
        loginData: null,
        authenticated: false,
      };
    }

    default:
      return state;
  }
};

export default Auth;
