import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import classNames from "classnames";

import GlobalSearch from "../../../components/GlobalSearch";

import {
  Queue,
  SquareAvatar,
  AngleLeft,
  CircularCheckBlack,
  Conversations,
  AwaitingConvo,
  InProgressConvo,
  OpenConvo,
} from "../../../assets/vectors";

import { conversationsPermissions } from "../../../utils/permissions";

const ConversationSections = ({
  selectedConversation,
  selectedSection,
  selectSection,
  isSearchActive,
  toggleIsSearchActive,
}) => {
  const { conversationsCountData, conversationsUnreadCount } = useSelector(
    (state) => state.conversations
  );

  const [hideDisplay, setHideDisplay] = useState(false);
  const [hideSectionDropdown, setHideSectionDropdown] = useState(true);

  useEffect(() => {
    if (selectedSection === "Assigned to me") selectSection("Open");

    if (
      selectedSection === "Queue" ||
      selectedSection === "Resolved" ||
      selectedSection === "All Conversations"
    ) {
      setHideSectionDropdown(true);
    } else {
      setHideSectionDropdown(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSection]);

  const convoCount = conversationsCountData?.data;

  const sectionItems = [
    {
      label: "Assigned to me",
      icon: <SquareAvatar />,
      unread:
        conversationsUnreadCount["open"] +
        conversationsUnreadCount["in-progress"] +
        conversationsUnreadCount["awaiting-customer-response"],
      messages:
        convoCount?.openCount +
          convoCount?.progressCount +
          convoCount?.awaitingCustomerCount || "",
      dropdown: [
        {
          name: "open",
          label: "Open",
          icon: <OpenConvo />,
          unread: conversationsUnreadCount["open"],
          messages: convoCount?.openCount,
        },
        {
          name: "in-progress",
          label: "In-progress",
          icon: <InProgressConvo />,
          unread: conversationsUnreadCount["in-progress"],
          messages: convoCount?.progressCount,
        },
        {
          name: "awaiting-customer-response",
          label: "Awaiting Response",
          icon: <AwaitingConvo />,
          unread: conversationsUnreadCount["awaiting-customer-response"],
          messages: convoCount?.awaitingCustomerCount,
        },
      ],
    },
    {
      label: "Queue",
      icon: <Queue />,
      unread: conversationsUnreadCount["in-queue"],
      messages: convoCount?.queueCount,
    },
    {
      label: "Resolved",
      icon: <CircularCheckBlack />,
      noStroke: true,
      unread: conversationsUnreadCount["closed"],
      messages: convoCount?.closedCount,
    },
  ];

  if (conversationsPermissions?.viewAll) {
    sectionItems.push({
      label: "All Conversations",
      icon: <Conversations />,
      unread: conversationsUnreadCount["all"],
      messages: convoCount?.allConversationCount,
    });
  }

  return (
    <div
      className={classNames("secondary-menu conversation-sections", {
        hideDisplay,
        searchActive: isSearchActive,
      })}
    >
      {!isSearchActive && (
        <div
          className={classNames("toggle-display", { active: hideDisplay })}
          onClick={() => setHideDisplay(!hideDisplay)}
        >
          <AngleLeft />
        </div>
      )}
      <div className="title">Conversations</div>

      <div className="secondary-menu-items conversation-section-items">
        <GlobalSearch
          isSearchActive={isSearchActive}
          toggleIsSearchActive={toggleIsSearchActive}
          defaultSearchTypeValue={null}
          searchAgentInConversations={true}
        />
        {!isSearchActive &&
          sectionItems.map(
            ({ label, icon, unread, messages, noStroke, dropdown }, index) => (
              <React.Fragment key={`conversation-section-${index}`}>
                <div
                  className={classNames("item", {
                    active:
                      selectedSection === label ||
                      (dropdown && !hideSectionDropdown),
                  })}
                  onClick={() => selectSection(label)}
                >
                  <div>
                    <span className={classNames("icon", { noStroke })}>
                      {icon}
                    </span>
                    <span className="text">{label}</span>
                  </div>
                  <div className="counts">
                    {!!unread && unread > 0 && (
                      <span className="unread-count">{unread}</span>
                    )}
                    {messages && (
                      <span className="message-count">{messages}</span>
                    )}
                  </div>
                </div>
                {!hideSectionDropdown &&
                  dropdown?.map((item, i) => {
                    return (
                      <div
                        key={i}
                        className={classNames("item sub-item", {
                          active:
                            selectedConversation?.status === item?.name ||
                            selectedSection === item?.label,
                        })}
                        onClick={() => selectSection(item?.label)}
                      >
                        <div>
                          <span className="icon">{item?.icon}</span>
                          <span className="text">{item?.label}</span>
                        </div>
                        <div className="counts">
                          {!!item?.unread && item?.unread > 0 && (
                            <span className="unread-count">{item?.unread}</span>
                          )}
                          <span className="message-count">
                            {item?.messages}
                          </span>
                        </div>
                      </div>
                    );
                  })}
              </React.Fragment>
            )
          )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ conversations }) => conversations;

export default connect(mapStateToProps, {})(ConversationSections);
