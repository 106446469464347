import React from "react";

import Sidebar from "../../components/Sidebar";

const Main = ({ children }) => {
  return (
    <div className="body">
      <Sidebar />
      <div className="main-container">{children}</div>
    </div>
  );
};

export default Main;
