import React from "react";
import { withRouter, Switch, Route, Redirect } from "react-router-dom";

import {
  accountSettingsPermissions,
  agentsPermissions,
  autoRespondersPermissions,
  billingPermissions,
  channelsPermissions,
  csatPermissions,
  groupsPermissions,
  integrationsPermissions,
  officeHoursPermissions,
  savedResponsesPermissions,
  tagsPermissions,
  templatesPermissions,
} from "../../utils/permissions";

import {
  AccountSettings,
  Users,
  AutoResponses,
  Csat,
  Channels,
  ChannelSetupForm,
  Integrations,
  IntegrationDetails,
  ProfileSettings,
  SavedResponses,
  Tags,
  Teams,
  Groups,
  Billing,
  Upgrade,
  TemplateMessages,
  OmniChannel,
  CustomFields,
  AutoAssignment,
  WorkingHours,
  Signatures,
  SettingsMenu,
} from "./";

const Settings = ({ match }) => {
  return (
    <div className="page-container">
      <SettingsMenu />
      <div className="page-content">
        <Switch>
          {accountSettingsPermissions.read && (
            <Route
              exact
              path={`${match.url}/account-settings`}
              component={AccountSettings}
            />
          )}
          <Route
            exact
            path={`${match.url}/profile-settings`}
            component={ProfileSettings}
          />
          {agentsPermissions.read && (
            <Route exact path={`${match.url}/users`} component={Users} />
          )}
          {agentsPermissions.read && (
            <Route exact path={`${match.url}/teams`} component={Teams} />
          )}
          {savedResponsesPermissions.read && (
            <Route
              exact
              path={`${match.url}/saved-responses`}
              component={SavedResponses}
            />
          )}
          {tagsPermissions.read && (
            <Route exact path={`${match.url}/tags`} component={Tags} />
          )}
          {groupsPermissions.read && (
            <Route exact path={`${match.url}/groups`} component={Groups} />
          )}
          {billingPermissions.read && (
            <Route exact path={`${match.url}/billing`} component={Billing} />
          )}
          {billingPermissions.read && (
            <Route
              exact
              path={`${match.url}/billing/upgrade`}
              component={Upgrade}
            />
          )}
          {integrationsPermissions.read && (
            <Route
              exact
              path={`${match.url}/integrations`}
              component={Integrations}
            />
          )}
          {integrationsPermissions.read && (
            <Route
              exact
              path={`${match.url}/integrations/:id`}
              component={IntegrationDetails}
            />
          )}
          {autoRespondersPermissions.read && (
            <Route
              exact
              path={`${match.url}/auto-responses`}
              component={AutoResponses}
            />
          )}
          {csatPermissions.read && (
            <Route exact path={`${match.url}/csat`} component={Csat} />
          )}
          {templatesPermissions.read && (
            <Route
              exact
              path={`${match.url}/template-messages`}
              component={TemplateMessages}
            />
          )}
          {accountSettingsPermissions.read && (
            <Route
              exact
              path={`${match.url}/omnichannel-widgets`}
              component={OmniChannel}
            />
          )}
          {officeHoursPermissions.read && (
            <Route
              exact
              path={`${match.url}/custom-fields`}
              component={CustomFields}
            />
          )}
          {officeHoursPermissions.read && (
            <Route
              exact
              path={`${match.url}/auto-assignment`}
              component={AutoAssignment}
            />
          )}
          {officeHoursPermissions.read && (
            <Route
              exact
              path={`${match.url}/working-hours`}
              component={WorkingHours}
            />
          )}
          {channelsPermissions.read && (
            <Route exact path={`${match.url}/channels`} component={Channels} />
          )}
          {channelsPermissions.read && (
            <Route
              exact
              path={`${match.url}/channels/:id`}
              component={Channels}
            />
          )}
          {channelsPermissions.read && (
            <Route
              exact
              path={`${match.url}/channels/setup/:id`}
              component={ChannelSetupForm}
            />
          )}
          <Route
            exact
            path={`${match.url}/signatures`}
            component={Signatures}
          />
          <Route path="*" render={() => <Redirect to="/settings" />} />
        </Switch>
      </div>
    </div>
  );
};

export default withRouter(Settings);
