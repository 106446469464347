import {
  ACCOUNT_SETTINGS_LOADER,
  ACCOUNT_SETTINGS_SUBMITTING,
  ACCOUNT_SETTINGS_RESPONSE,
  ACCOUNT_INFO_RESPONSE,
} from "../constants";

const INIT_STATE = {
  isLoadingAccountSettings: false,
  isSubmittingAccountSettings: false,
  accountSettingsOutcome: "",
  accountSettingsData: null,
  accountSettingsMessage: "",
  accountInfoOutcome: "",
  accountInfoData: null,
  accountInfoMessage: "",
};

const AccountSettings = (state = INIT_STATE, action) => {
  const {
    type,
    isLoadingAccountSettings,
    isSubmittingAccountSettings,
    accountSettingsOutcome,
    accountSettingsData,
    accountSettingsMessage,
    accountInfoOutcome,
    accountInfoData,
    accountInfoMessage,
  } = action;

  switch (type) {
    case ACCOUNT_SETTINGS_LOADER: {
      return {
        ...state,
        isLoadingAccountSettings,
      };
    }

    case ACCOUNT_SETTINGS_SUBMITTING: {
      return {
        ...state,
        isSubmittingAccountSettings,
      };
    }

    case ACCOUNT_SETTINGS_RESPONSE: {
      return {
        ...state,
        accountSettingsOutcome,
        accountSettingsData,
        accountSettingsMessage,
      };
    }

    case ACCOUNT_INFO_RESPONSE: {
      return {
        ...state,
        accountInfoOutcome,
        accountInfoData,
        accountInfoMessage,
      };
    }

    default:
      return state;
  }
};

export default AccountSettings;
