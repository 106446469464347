import { useState, useEffect, useRef } from "react";
import classNames from "classnames";

import { OnerouteLogo } from "../../assets/vectors";

const Carousel = () => {
  const [activeSlide, setActiveSlide] = useState(0);

  var tick = useRef();

  const testimonials = [
    {
      title: "Traction Apps keeps response times under 5 minutes",
      text: "OneRoute allows us to receive, respond to, and create tickets for customer messages from WhatsApp, Facebook, Instagram, E-Mail, all from one interface. They also have a very available team that listens and does not stop until they have resolved or met our needs.",
      author: "Victoria Ekanem, Head of Business Operations.",
    },
    {
      title: "Ajocard reduces agent resolution time by 83%",
      text: "OneRoute gives our agents and customers the ability to reach us quicker and get answers faster. The solution is straightforward and helps us meet our customer interaction, retention and satisfaction needs.",
      author: "Folashade Buoro, Head of Customer Relationship Management",
    },
    {
      title: "Autochek sees 18% Increase in agent productivity",
      text: "OneRoute allows our agents to process 18% more customer enquiries and complaints daily. Thus enabling significant savings on staffing costs.",
      author: "Chetan Seth, Director at Technology.",
    },
  ];

  useEffect(() => {
    tick.current = setInterval(autoUpdateSlide, 5000);

    return () => {
      clearInterval(tick.current);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSlide]);

  const autoUpdateSlide = () => {
    const length = testimonials?.length - 1;
    const value = activeSlide === length ? 0 : activeSlide + 1;

    setActiveSlide(value);
  };

  return (
    <>
      <div
        className="logo"
        onClick={() => window.location.assign("https://oneroute.io")}
      >
        <OnerouteLogo />
      </div>

      <div className="carousel">
        {testimonials?.map((item, index) => (
          <div
            key={index}
            className={classNames("slide", { active: activeSlide === index })}
          >
            <p className="quotation_mark">“</p>
            <p className="title">{item.title}</p>
            <p className="text">{item.text}</p>
            <p className="author">{item.author}</p>
          </div>
        ))}

        <div className="controls">
          {testimonials?.map((item, index) => (
            <div
              key={index}
              className={classNames("item", {
                active: activeSlide === index,
              })}
              onClick={() => {
                clearInterval(tick.current);
                setActiveSlide(index);
              }}
            ></div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Carousel;
