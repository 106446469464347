import {
  AUTO_ASSIGNS_LOADER,
  AUTO_ASSIGNS_SUBMITTING,
  AUTO_ASSIGNS_RESPONSE,
  AUTO_ASSIGN_RESPONSE,
} from "../constants";

const INIT_STATE = {
  isLoadingAutoAssigns: false,
  isSubmittingAutoAssigns: false,
  autoAssignsOutcome: "",
  autoAssignsData: null,
  autoAssignsMessage: "",
  autoAssignOutcome: "",
  autoAssignData: null,
  autoAssignMessage: "",
};

const AutoAssigns = (state = INIT_STATE, action) => {
  const {
    type,
    isLoadingAutoAssigns,
    isSubmittingAutoAssigns,
    autoAssignsOutcome,
    autoAssignsData,
    autoAssignsMessage,
    autoAssignOutcome,
    autoAssignData,
    autoAssignMessage,
  } = action;

  switch (type) {
    case AUTO_ASSIGNS_LOADER: {
      return {
        ...state,
        isLoadingAutoAssigns,
      };
    }

    case AUTO_ASSIGNS_SUBMITTING: {
      return {
        ...state,
        isSubmittingAutoAssigns,
      };
    }

    case AUTO_ASSIGNS_RESPONSE: {
      return {
        ...state,
        autoAssignsOutcome,
        autoAssignsData,
        autoAssignsMessage,
      };
    }

    case AUTO_ASSIGN_RESPONSE: {
      return {
        ...state,
        autoAssignOutcome,
        autoAssignData,
        autoAssignMessage,
      };
    }

    default:
      return state;
  }
};

export default AutoAssigns;
