import {
  GET_BILLING_LOADER,
  GET_BILLING_SUBMITTER,
  GET_INVOICES_RESPONSE,
  GET_WALLET_HISTORY_RESPONSE,
  GET_CARDS_RESPONSE,
  GET_SUBSCRIPTIONS_RESPONSE,
  GET_BILLING_RESPONSE,
  GET_PLANS_RESPONSE,
  GET_FIRM_PLAN_RESPONSE,
} from "../constants";

const INIT_STATE = {
  isLoadingBilling: false,
  isSubmittingBilling: false,
  invoicesOutcome: "",
  invoicesData: {},
  invoicesMessage: "",
  walletHistoryOutcome: "",
  walletHistoryData: {},
  walletHistoryMessage: "",
  cardsOutcome: "",
  cardsData: [],
  cardsMessage: "",
  subscriptionOutcome: "",
  subscriptionData: {},
  subscriptionMessage: "",
  billingOutcome: "",
  billingData: {},
  billingMessage: "",
  plansOutcome: "",
  plansData: [],
  plansMessage: "",
  firmPlanOutcome: "",
  firmPlanData: {},
  firmPlanMessage: "",
};

const Billing = (state = INIT_STATE, action) => {
  const {
    type,
    isLoadingBilling,
    isSubmittingBilling,
    invoicesOutcome,
    invoicesData,
    invoicesMessage,
    walletHistoryOutcome,
    walletHistoryData,
    walletHistoryMessage,
    cardsOutcome,
    cardsData,
    cardsMessage,
    subscriptionOutcome,
    subscriptionData,
    subscriptionMessage,
    billingOutcome,
    billingData,
    billingMessage,
    plansOutcome,
    plansData,
    plansMessage,
    firmPlanOutcome,
    firmPlanData,
    firmPlanMessage,
  } = action;

  switch (type) {
    case GET_BILLING_LOADER: {
      return {
        ...state,
        isLoadingBilling,
      };
    }

    case GET_BILLING_SUBMITTER: {
      return {
        ...state,
        isSubmittingBilling,
      };
    }

    case GET_INVOICES_RESPONSE: {
      return {
        ...state,
        invoicesOutcome,
        invoicesData,
        invoicesMessage,
      };
    }

    case GET_WALLET_HISTORY_RESPONSE: {
      return {
        ...state,
        walletHistoryOutcome,
        walletHistoryData,
        walletHistoryMessage,
      };
    }

    case GET_CARDS_RESPONSE: {
      return {
        ...state,
        cardsOutcome,
        cardsData,
        cardsMessage,
      };
    }

    case GET_SUBSCRIPTIONS_RESPONSE: {
      return {
        ...state,
        subscriptionOutcome,
        subscriptionData,
        subscriptionMessage,
      };
    }

    case GET_BILLING_RESPONSE: {
      return {
        ...state,
        billingOutcome,
        billingData,
        billingMessage,
      };
    }

    case GET_PLANS_RESPONSE: {
      return {
        ...state,
        plansOutcome,
        plansData,
        plansMessage,
      };
    }

    case GET_FIRM_PLAN_RESPONSE: {
      return {
        ...state,
        firmPlanOutcome,
        firmPlanData,
        firmPlanMessage,
      };
    }

    default:
      return state;
  }
};

export default Billing;
