import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import MentionSections from "./MentionSections";
import MentionsList from "./MentionsList/MentionsList";
import ChatContainer from "./ChatContainer/ChatContainer";

import {
  getMentionsAction,
  getSingleMentionAction,
  getMentionRepliesAction,
  patchReturnMentionToQueueAction,
  patchAssignMentionsToAgent,
  deleteMentionAction,
  createMentionsUnreadCount,
} from "../../redux/actions/Mentions";
import { getSavedResponsesAction } from "../../redux/actions/SavedResponses";
import { getFirmChannelsAction } from "../../redux/actions/Channels";

import { getUserDetails } from "../../utils/functions";

const Mentions = () => {
  const { mentionId } = useParams();

  const history = useHistory();
  const dispatch = useDispatch();

  const [selectedSection, setSelectedSection] = useState("Assigned to me");
  const [selectedMention, setSelectedMention] = useState({});
  const [mentionReplies, setMentionReplies] = useState([]);
  const [updatedPreview, setUpdatedPreview] = useState(null);
  const [unreadCount, setUnreadCount] = useState({
    open: 0,
    "in-queue": 0,
  });
  const [unreadMessages, setUnreadMessages] = useState([]);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [query, setQuery] = useState({
    "page[size]": 10,
    "page[number]": 1,
  });

  useEffect(() => {
    getQueueMentions();
    getAllMentions();
    dispatch(getSavedResponsesAction());
    dispatch(getFirmChannelsAction());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (mentionId && selectedMention?.id) {
      getMentionReplies();
    } else if (mentionId && !selectedMention?.id) {
      setSelectedMention({ id: mentionId });
      getSingleMention();
      getMentionReplies();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mentionId]);

  useEffect(() => {
    if (unreadCount) {
      dispatch(createMentionsUnreadCount(unreadCount));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unreadCount]);

  const getOpenMentions = (noReset, paginate) => {
    const { id } = getUserDetails();

    setQuery({
      ...query,
      "page[number]": paginate ? query["page[number]"] + 1 : 1,
    });

    dispatch(
      getMentionsAction(
        {
          ...query,
          agent_id: id,
          status: "open",
        },
        "openMentions",
        noReset,
        paginate
      )
    );
  };

  const getQueueMentions = (noReset, paginate, sortBy) => {
    setQuery({
      ...query,
      "page[number]": paginate ? query["page[number]"] + 1 : 1,
    });

    dispatch(
      getMentionsAction(
        {
          ...query,
          status: "in-queue",
          sortBy: sortBy || "updatedAt",
        },
        "queueMentions",
        noReset,
        paginate
      )
    );
  };

  const getAllMentions = (noReset, paginate) => {
    const { roleName } = getUserDetails();

    if (roleName === "manager") {
      setQuery({
        ...query,
        "page[number]": paginate ? query["page[number]"] + 1 : 1,
      });

      dispatch(
        getMentionsAction(
          {
            ...query,
            status: null,
          },
          "allMentions",
          noReset,
          paginate
        )
      );
    }
  };

  const getSingleMention = async (noLoader, id) => {
    const currentUser = getUserDetails();

    dispatch(
      getSingleMentionAction({ id: mentionId || id, load: !noLoader })
    ).then((res) => {
      setSelectedMention(res);
      setSelectedSection(
        res?.agent_id !== currentUser?.id && selectedSection !== "Queue"
          ? "All Activity"
          : res.status === "open"
          ? "Assigned to me"
          : "Queue"
      );
    });
  };

  const getMentionReplies = async (noLoader) => {
    dispatch(getMentionRepliesAction({ id: mentionId, load: !noLoader })).then(
      (res) => setMentionReplies(res)
    );
  };

  const toggleIsSearchActive = (value) => {
    setIsSearchActive(value);
  };

  const selectSection = (selectedSection) => {
    setSelectedSection(selectedSection);
    setSelectedMention({});
    history.push("/activity");
  };
  useEffect(() => {
    if (selectedSection === "Assigned to me") {
      updateUnreadCount("open", true);
      getOpenMentions();
    } else if (selectedSection === "Queue") {
      updateUnreadCount("in-queue", true);
      getQueueMentions();
    } else if (selectedSection === "All Activity") {
      getAllMentions();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSection]);

  const selectMention = (mention) => {
    const { id } = getUserDetails();
    let newUnreadMessages = JSON.parse(JSON.stringify(unreadMessages));

    let section = "open";

    if (mention?.status === "open" && mention?.agent_id === id) {
      setSelectedSection("Assigned to me");
    } else if (mention?.status === "in-queue") {
      section = "in-queue";
      setSelectedSection("Queue");
    } else if (mention?.status === "closed") {
      setSelectedSection("All Activity");
    }

    const currentUnreadCount = newUnreadMessages.filter(
      (msg) => msg === mention?.id
    ).length;
    newUnreadMessages = newUnreadMessages.filter((msg) => msg !== mention?.id);

    if (selectedMention.id !== mention?.id) {
      history.push(`/activity/${mention?.id}`);
      setSelectedMention(mention);
    }

    setUnreadMessages(newUnreadMessages);
    setUnreadCount({
      ...unreadCount,
      [section]: unreadCount[section] - currentUnreadCount,
    });
  };

  const resolveMention = async (mentionId) => {
    dispatch(deleteMentionAction(mentionId)).then((res) => {
      if (res?.status === 200) {
        getOpenMentions();

        setSelectedMention({});
        selectSection("All Activity");

        history.push(`/activity`);
      }
    });
  };

  const returnToQueue = async (mentionId) => {
    dispatch(patchReturnMentionToQueueAction(mentionId)).then((res) => {
      if (res?.status === 200) {
        getOpenMentions();
        getQueueMentions();

        setSelectedMention({});
        setSelectedSection("Queue");

        history.push(`/activity`);
      }
    });
  };

  const assignMention = (mentions, agent_id) => {
    const { id } = getUserDetails();
    const isMentionsAnArray = Array.isArray(mentions);

    const requestData = {
      mentions: isMentionsAnArray ? mentions : [mentions],
      agent_id,
    };

    const res = dispatch(patchAssignMentionsToAgent(requestData)).then(
      (res) => {
        if (res?.status === 200 && id === agent_id) {
          getQueueMentions(true);
          selectSection("Assigned to me");
          !isMentionsAnArray && history.push(`/activity/${mentions}`);
        } else {
          getQueueMentions(true);

          setSelectedMention({});
          history.push(`/activity`);
        }
      }
    );

    return res;
  };

  const updatePreview = (updatedPreview) => {
    setUpdatedPreview(updatedPreview);
  };

  const updateUnreadCount = (section, clear) => {
    setUnreadCount({
      ...unreadCount,
      [section]: clear ? 0 : unreadCount[section] + 1,
    });
  };

  const updateMessageUnreadCount = (mentionId) => {
    const newUnreadMessages = JSON.parse(JSON.stringify(unreadMessages));

    newUnreadMessages.push(mentionId);

    setUnreadMessages(newUnreadMessages);
  };

  return (
    <div className="mentions-container">
      <MentionSections
        selectedSection={selectedSection}
        selectSection={selectSection}
        unreadCount={unreadCount}
        isSearchActive={isSearchActive}
        toggleIsSearchActive={toggleIsSearchActive}
      />
      <MentionsList
        selectedSection={selectedSection}
        selectMention={selectMention}
        selectedMention={selectedMention}
        updatedPreview={updatedPreview}
        unreadMessages={unreadMessages}
        isSearchActive={isSearchActive}
        getOpenMentions={getOpenMentions}
        getQueueMentions={getQueueMentions}
        getAllMentions={getAllMentions}
        assignMention={assignMention}
      />
      <ChatContainer
        selectedSection={selectedSection}
        selectedMention={selectedMention}
        mentionReplies={mentionReplies}
        closeMention={resolveMention}
        updatePreview={updatePreview}
        getMentionReplies={getMentionReplies}
        updateUnreadCount={updateUnreadCount}
        updateMessageUnreadCount={updateMessageUnreadCount}
        getOpenMentions={getOpenMentions}
        getQueueMentions={getQueueMentions}
        returnToQueue={returnToQueue}
        assignMention={assignMention}
      />
    </div>
  );
};

export default Mentions;
