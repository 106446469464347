import React, { Component } from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import * as shallowequal from "shallowequal";

import {
  getTagsAction,
  createTagsAction,
  updateTagsAction,
} from "../../../redux/actions/Tags";

import { Button, TextField } from "../../../components/FormElements";
import Modal from "../../../components/Modal";
import FormNotifications from "../../../components/Notifications";

import { patchFormValues, getFormValues } from "../../../utils/functions";
import { Check } from "../../../assets/vectors";

const initialFormData = {
  name: {
    value: "",
    valid: false,
  },
  colorCode: {
    value: "",
    valid: false,
  },
};

class TagForm extends Component {
  constructor(props) {
    super(props);

    const { tagData } = props;

    const formData = patchFormValues(initialFormData, tagData);

    this.state = {
      formData: formData
        ? formData
        : { ...JSON.parse(JSON.stringify(initialFormData)) },
    };
  }

  handleChange = ({ target }, valid) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [target.name]: {
          value: target.value,
          valid,
        },
      },
    });
  };

  checkFormValidity = () => {
    return Object.values(this.state.formData).every(({ valid }) => valid);
  };

  submitForm = (e) => {
    e.preventDefault();
    const {
      tagData,
      createTagsAction,
      updateTagsAction,
      toggleTagForm,
      assignTag,
    } = this.props;

    const formData = getFormValues(this.state.formData);
    tagData
      ? updateTagsAction(formData, tagData.id)
      : createTagsAction(formData, toggleTagForm).then((res) => {
          if (assignTag) assignTag(res?.id);
          this.props.getTagsAction({
            offset: 0,
            limit: 20,
          });
        });
  };

  componentDidUpdate(prevProps) {
    const { tagData, createTagsOutcome } = this.props;

    if (
      !shallowequal(prevProps.tagData, tagData) &&
      Object.keys(tagData).length
    ) {
      const formData = patchFormValues(initialFormData, tagData);

      this.setState({
        formData,
      });
    }

    if (
      prevProps.createTagsOutcome !== createTagsOutcome &&
      createTagsOutcome === "success"
    ) {
      this.setState({
        formData: { ...initialFormData },
      });
    }
  }

  render() {
    const { formData } = this.state;
    const {
      isCreatingTag,
      isUpdatingTag,
      toggleTagForm,
      createTagsOutcome,
      createTagsMessage,
      updateTagsOutcome,
      updateTagsMessage,
      tagData,
    } = this.props;

    const { name, colorCode } = formData;

    const colorCodes = [
      "#B26AF9",
      "#4C88FB",
      "#74C8F7",
      "#16C59B",
      "#F7BE1F",
      "#F6AA73",
      "#F97272",
      "#F686CE",
      "#8B8A8C",
    ];

    return (
      <Modal closeModal={() => toggleTagForm()} xsmall>
        <div className="modal-content">
          <div className="modal-title">{tagData ? "Edit" : "New"} Tag</div>
          <div className="modal-form tag-form">
            <form onSubmit={this.submitForm}>
              <TextField
                label="Name"
                name="name"
                value={name.value}
                onChange={(e, valid) => this.handleChange(e, valid)}
                className="mb-25"
              />
              <div className="input-container mb-25">
                <label>Highlight</label>
                <div className="tag-highlights">
                  {colorCodes.map((value, index) => (
                    <span
                      key={`highlight-${index}`}
                      style={{ background: value }}
                      onClick={() =>
                        this.handleChange(
                          { target: { value, name: "colorCode" } },
                          true
                        )
                      }
                      className={classNames("", {
                        active: value === colorCode.value,
                        inactive: colorCode.valid && value !== colorCode.value,
                      })}
                    >
                      <Check />
                    </span>
                  ))}
                </div>
              </div>
              <div className="modal-form-action">
                <Button
                  type="button"
                  className="secondary-button"
                  onClick={() => toggleTagForm()}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  className="primary-button"
                  disabled={
                    !this.checkFormValidity() || isCreatingTag || isUpdatingTag
                  }
                >
                  {isCreatingTag || isUpdatingTag
                    ? "Processing..."
                    : tagData
                    ? "Save Changes"
                    : "Add tag"}
                </Button>
              </div>

              {createTagsOutcome === "error" && (
                <FormNotifications type="error" message={createTagsMessage} />
              )}
              {updateTagsOutcome === "error" && (
                <FormNotifications type="error" message={updateTagsMessage} />
              )}
            </form>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = ({ tags }) => tags;

export default connect(mapStateToProps, {
  getTagsAction,
  createTagsAction,
  updateTagsAction,
})(TagForm);
